import React, { ChangeEvent, FormEvent, useState } from 'react';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { Cancel, Done, Edit, PlayArrow } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { Organization } from 'controllers/types';

const styles = {
  Card: {
    flexGrow: 1,
  },
};

type Props = {
  organization?: Organization;
  handleFormSubmit: (organization: Organization) => Promise<void>;
  handleChangeActiveOrganization?: () => void;
  startEditing?: boolean;
};

export function OrganizationCard({
  organization = {
    // Impossible ID so id can be a required type and the rest of our code
    // never has to think about an org that hasn't been created yet.
    id: 0,
    name: '',
    slug: '',
    member_count: 0,
    access_control: {},
  },
  handleFormSubmit,
  handleChangeActiveOrganization,
  startEditing = false,
}: Props) {
  const [editedOrganization, setEditedOrganization] = useState(organization);
  const [editing, setEditing] = useState(startEditing);
  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditedOrganization({
      ...editedOrganization,
      [name]: value,
    });
  };

  const handleFormCancel = () => {
    setEditing(false);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    handleFormSubmit(editedOrganization)
      .then(handleFormCancel)
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleVerifiedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    handleFormSubmit({ ...organization, verified: checked });
  };

  return (
    <Card style={styles.Card}>
      {editing && (
        <CardContent>
          <form data-testid='organization-form' onSubmit={handleSubmit}>
            <TextField
              variant='outlined'
              name='name'
              fullWidth
              margin='normal'
              label='Organization Name'
              type='text'
              onChange={handleInputChange}
              defaultValue={editedOrganization?.name}
              required
              helperText='Enter the name of the organization'
              data-testid='organization-name-input'
            />
            <TextField
              variant='outlined'
              name='slug'
              fullWidth
              margin='normal'
              label='Organization slug'
              type='text'
              onChange={handleInputChange}
              defaultValue={editedOrganization?.slug}
              required
              disabled={!startEditing}
              helperText='Enter the slug of the organization'
              data-testid='organization-slug-input'
            />
            <CardActions>
              <IconButton aria-label='submit organization' type='submit' data-testid='submit-button'>
                <Done />
              </IconButton>
              <IconButton aria-label='cancel edit oganization' onClick={handleFormCancel}>
                <Cancel />
              </IconButton>
            </CardActions>
          </form>
        </CardContent>
      )}
      {!editing && (
        <>
          <CardHeader
            action={
              <>
                <Tooltip title={`Set ${organization.name} as your active organization`}>
                  <IconButton aria-label='set as active organization' onClick={handleChangeActiveOrganization}>
                    <PlayArrow />
                  </IconButton>
                </Tooltip>
                <IconButton aria-label='edit organization' onClick={() => setEditing(true)}>
                  <Edit />
                </IconButton>
                <FormControlLabel
                  control={
                    <Switch
                      checked={organization.verified}
                      onChange={handleVerifiedChange}
                      data-testid='verified-switch'
                    />
                  }
                  label='Verified'
                />
              </>
            }
          />
          <CardContent>
            <Typography data-testid='organization-name' variant='headlineMedium' color='text.secondary'>
              {editedOrganization.name}
            </Typography>
            <Typography data-testid='organization-slug' variant='headlineSmall' color='text.secondary'>
              ID: {editedOrganization.slug}
            </Typography>
          </CardContent>
        </>
      )}
    </Card>
  );
}
