import React from 'react';
import { Dialog, Stack, Typography, Button, IconButton, TextField } from '@mui/material';
import { Close, ContentCopy } from '@mui/icons-material';
import { FacebookShare, TwitterShare, WhatsappShare } from 'react-share-lite';
import SocialEmail from '../assets/social-email.svg';
import { Row } from './Row/Row';
import { KyronEvents, EventNames } from './utils/KyronEvents';
import { UserContext } from './UserContext';

type Props = {
  open: boolean;
  onClose: () => void;
  quote: string;
  emailMessage: string;
  link: string;
  eventResource: object;
};

export const ShareDialog = ({ open, onClose, quote, emailMessage, link, eventResource }: Props) => {
  const { user } = React.useContext(UserContext);

  const sendAnalyticsEvent = (event: EventNames) => {
    KyronEvents.sendEvent(event, {
      ...eventResource,
      user_id: user?.id,
    });
  };

  const EmailShare = (
    <IconButton
      onClick={() => {
        window.open(`mailto:?subject=${quote}&body=${emailMessage} ${link}`);
        sendAnalyticsEvent(KyronEvents.names.EMAIL_SHARE);
      }}
      sx={{
        height: '48px',
        width: '48px',
        backgroundColor: 'primary.main',
        '&:hover': {
          backgroundColor: 'primary.main',
        },
      }}
    >
      <img src={SocialEmail} alt='Email' />
    </IconButton>
  );

  // FacebookShare will not work locally, but will work in staging/production
  // On Facebook: The link will work, but the preview will show an ActionController Error.
  // On LinkedIn: The website will allow links to app.kyronlearning.com, but not to
  // any of the additional routes.
  // TODO: Fix the link on Facebook & get LinkedIn to work
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: '480px', margin: 0 } }}>
      <Stack spacing={3} sx={{ alignItems: 'flex-start', padding: 3 }}>
        <Row justifyContent='space-between' width='100%'>
          <Typography variant='headlineSmall'>Share</Typography>
          <IconButton onClick={onClose} data-testid='close-share-dialog'>
            <Close />
          </IconButton>
        </Row>
        <Row gap={2}>
          <FacebookShare
            url={link}
            hashtag='#KyronLearning'
            round
            size={48}
            onClick={() => sendAnalyticsEvent(KyronEvents.names.FACEBOOK_SHARE)}
            data-testid='share-facebook'
            style={{ display: 'flex' }}
          />
          <TwitterShare
            url={link}
            title={quote}
            hashtags={['KyronLearning']}
            round
            size={48}
            onClick={() => sendAnalyticsEvent(KyronEvents.names.X_SHARE)}
            style={{ display: 'flex' }}
          />
          <WhatsappShare
            url={link}
            title={quote}
            round
            size={48}
            onClick={() => sendAnalyticsEvent(KyronEvents.names.WHATSAPP_SHARE)}
            style={{ display: 'flex' }}
          />
          {EmailShare}
        </Row>
        <Row gap={1} justifyContent='space-between' width='100%'>
          <TextField
            variant='outlined'
            size='small'
            fullWidth
            defaultValue={link}
            inputProps={{ readOnly: true }}
            data-testid='share-link'
          />
          <Button
            startIcon={<ContentCopy />}
            onClick={() => {
              navigator.clipboard.writeText(link);
              sendAnalyticsEvent(KyronEvents.names.LINK_SHARE);
            }}
          >
            Copy Link
          </Button>
        </Row>
      </Stack>
    </Dialog>
  );
};
