import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton, useMediaQuery, Theme, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { MultipleChoiceOption } from 'controllers/types';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&&&': {
    borderRadius: '100px',
    border: 'none',
    flex: 1,
    '&.Mui-selected': {
      background: theme.palette.secondary.dark,
    },
  },
}));

type LessonEquationMultipleChoiceProps = {
  handleMultipleChoice: (selected: string) => void;
  multipleChoiceOptions: MultipleChoiceOption[];
  prevSelected: { [key: string]: boolean } | null;
  segmentId: number;
};

export const MultipleChoice = ({
  multipleChoiceOptions,
  handleMultipleChoice,
  prevSelected,
  segmentId,
}: LessonEquationMultipleChoiceProps) => {
  const [selected, setSelected] = useState('');
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  const handleSelected = (_event: React.MouseEvent, selectedOption: '') => {
    handleMultipleChoice(selectedOption);
    setSelected(selectedOption);
  };

  // boolean value stored for each choice in parent to record what has been selected so far
  const wasPreviouslySelected = (choiceLabel: string) => prevSelected && prevSelected[`${segmentId}-${choiceLabel}`];

  function getButtonStyles(choiceLabel: string): SxProps {
    if (wasPreviouslySelected(choiceLabel)) {
      return {
        bgcolor: 'action.disabledBackground',
        color: 'action.disabled',
        '&:hover': { bgcolor: 'action.disabledBackground' },
      };
    }
    return {
      bgcolor: 'secondary.main',
      color: 'secondary.contrastText',
      '&:hover': { bgcolor: 'secondary.dark' },
    };
  }

  return (
    <ToggleButtonGroup
      value={selected}
      exclusive
      onChange={handleSelected}
      sx={{ display: 'grid', gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr', gridAutoRows: '1fr', gap: 2 }}
    >
      {multipleChoiceOptions.map(choice => (
        <StyledToggleButton
          fullWidth
          key={choice.key}
          value={choice.label}
          aria-label={choice.label}
          sx={getButtonStyles(choice.label)}
        >
          {choice.label}
        </StyledToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
