import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Grid, Pagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Lesson } from '../Lesson';
import { KyronClient } from '../utils/KyronClient';

const styles = {
  LessonGrid: {
    width: '100%',
  },
  PaginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
};

export const AllLessons = ({
  lessons,
  handleLessonDelete,
  handleLessonUpdate,
  handleLessonPublish,
  allTutors,
  noOfLessons,
  perPage,
  page,
  getLessonPage,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const allMisconceptions = useRef([]);
  const client = new KyronClient();

  const getMisconceptionPage = async (p, numPerPage) =>
    client
      .getDataWithError(`/api/v1/lesson_misconceptions.json?page=${p}&per_page=${numPerPage}`)
      .then(data => {
        data.misconceptions.forEach(mis => allMisconceptions.current.push(mis));
        return data.meta.total_pages;
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
        return 0;
      });

  const getAllMisconceptions = async () => {
    let pageMis = 1;
    const numPerPage = 25;
    let totalPages = 1;

    while (pageMis <= totalPages) {
      // eslint-disable-next-line no-await-in-loop
      totalPages = await getMisconceptionPage(pageMis, numPerPage);
      pageMis += 1;
    }
  };

  useEffect(() => {
    getAllMisconceptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid sx={styles.LessonGrid} container spacing={2}>
      {lessons.map(lesson => (
        <Grid xs={12} item key={lesson.id}>
          <Lesson
            lesson={lesson}
            handleLessonDelete={handleLessonDelete}
            handleLessonUpdate={handleLessonUpdate}
            handleLessonPublish={handleLessonPublish}
            allTutors={allTutors}
            allMisconceptions={allMisconceptions.current}
            segmentsPerPage={perPage}
          />
        </Grid>
      ))}
      <Grid xs={12} item>
        <Pagination
          style={styles.PaginationWrapper}
          count={Math.ceil(noOfLessons / perPage)}
          siblingCount={5}
          shape='rounded'
          page={page}
          onChange={(_e, v) => getLessonPage(v, perPage)}
        />
      </Grid>
    </Grid>
  );
};

/* eslint-disable react/forbid-prop-types */
AllLessons.propTypes = {
  lessons: PropTypes.array.isRequired,
  allTutors: PropTypes.array,
  handleLessonDelete: PropTypes.func,
  handleLessonUpdate: PropTypes.func,
  handleLessonPublish: PropTypes.func.isRequired,
  noOfLessons: PropTypes.number,
  page: PropTypes.number,
  perPage: PropTypes.number,
  getLessonPage: PropTypes.func,
};
