// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AdminLessonVariants generates the expected links 1`] = `
[
  "http://localhost/video_player/1",
  "http://localhost/video_player/1",
  "http://localhost/studio/internal_lesson_editor/1/edit",
  "http://localhost/video_player/2",
  "http://localhost/video_player/2",
  "http://localhost/studio/internal_lesson_editor/2/edit",
  "http://localhost/studio/internal_lesson_editor",
]
`;
