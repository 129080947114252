import React from 'react';
import { Stack, Typography, GlobalStyles, IconButton } from '@mui/material';
import { ArrowBack, AccountCircle, WarningAmber } from '@mui/icons-material';
import { useParams, Link } from 'react-router-dom';
import { useStudentWorkBreakdown } from 'controllers/react-query/classroomMisconceptionReportHooks';
import { StudentProgressView } from 'components/Teacher/StudentProgressView';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { AppBar } from 'components/AppBar/AppBar';
import { useLessonQuery } from 'controllers/react-query';
import { suffixForPlural } from 'components/utils/stringUtils';
import { Row } from 'components/Row/Row';

export const CourseAnalytics = () => {
  const { lessonId } = useParams<{ lessonId: string }>();
  const { error: lessonError, isFetching: isLoadingLesson, data: lesson } = useLessonQuery(lessonId);
  const lessonCollectionId = lesson?.lesson_collection.id?.toString();
  const {
    isFetching: isFetchingWork,
    isError: isErrorWork,
    data: studentWorkBreakdown,
  } = useStudentWorkBreakdown(lessonCollectionId);
  const learnerAttempts = studentWorkBreakdown?.length || 0;
  const learnerWithMisconceptions =
    studentWorkBreakdown?.filter(student => student.misconceptions.length > 0).length || 0;

  return (
    <>
      <GlobalStyles styles={theme => ({ body: { backgroundColor: theme.palette.background.paper } })} />
      <AppBar
        showLogo={false}
        navButton={
          <IconButton component={Link} to={`/studio/courses/${lessonId}`}>
            <ArrowBack />
          </IconButton>
        }
        mainToolBarSx={{ bgcolor: 'background.paper' }}
      />
      <Stack sx={{ maxWidth: '1260px', m: '0 auto', px: 6, py: 8, gap: 1 }}>
        <Typography variant='labelMedium'>Analytics</Typography>
        {isLoadingLesson && <LoadingIndicator message='Loading analytics...' />}
        {lessonError && <Typography>Error loading analytics</Typography>}
        {lesson && (
          <Stack spacing={4}>
            <Typography variant='headlineLarge'>{lesson.name}</Typography>
            <Row gap={4}>
              <Row gap={1}>
                <AccountCircle sx={{ color: 'text.secondary' }} />
                <Typography variant='bodyMedium' color='text.secondary'>
                  {learnerAttempts} learner attempt{suffixForPlural(learnerAttempts)}
                </Typography>
              </Row>
              <Row gap={1}>
                <WarningAmber sx={{ color: 'error.main' }} />
                <Typography variant='bodyMedium' color='error.main'>
                  {learnerWithMisconceptions} student{suffixForPlural(learnerWithMisconceptions)} struggled
                </Typography>
              </Row>
            </Row>
            <StudentProgressView
              studentWorkBreakdown={studentWorkBreakdown}
              isFetching={isFetchingWork}
              isError={isErrorWork}
              assignmentName={lesson.name}
            />
          </Stack>
        )}
      </Stack>
    </>
  );
};
