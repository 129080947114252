import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button, Stack, CircularProgress, Alert } from '@mui/material';
import { useLessonContainerQuery } from 'controllers/react-query';

export function AdminLessonUnits({ collectionId }: { collectionId: number }) {
  // NOTE(derick): multiple Units per lesson container is not supported yet, so just fetch one
  const { isLoading, isError, data: unit } = useLessonContainerQuery(collectionId);

  return (
    <Stack gap={3}>
      <Typography variant='headlineSmall'>Associated Units</Typography>
      <Button
        sx={{ width: 'fit-content', borderRadius: 2 }}
        variant='outlined'
        color='info'
        component={Link}
        to={`/k12/units/${unit?.id}`}
      >
        {isError ? <Alert severity='error'>Error loading associated units</Alert> : null}
        {isLoading ? <CircularProgress size={20} /> : unit?.name}
      </Button>
    </Stack>
  );
}
