export enum DisplayType {
  BOX = 'box',
  OPERATOR = 'operator',
  TEXT = 'text',
}

export enum OperatorType {
  PLUS = 'plus',
  SUBTRACT = 'subtract',
  MULTIPLY = 'multiply',
  DIVIDE = 'divide',
  EQUALS = 'equals',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqual',
  LESS_THAN = 'lessThan',
  LESS_THAN_OR_EQUAL = 'lessThanOrEqual',
}
