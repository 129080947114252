import { Menu } from '@mui/icons-material';
import { Button, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import { AccountMenu } from 'components/AccountMenu/AccountMenu';
import { KyronLogo } from 'components/KyronLogo';
import { KyronMenu } from 'components/KyronMenu';
import { Row } from 'components/Row/Row';
import { useUserContext } from 'components/UserContext';
import { KyronEvents } from 'components/utils/KyronEvents';
import React from 'react';
import { Link } from 'react-router-dom';

export function MarketingHeader() {
  const { user } = useUserContext();
  const theme = useTheme();
  const isMobile = useMediaQuery<Theme>(theme.breakpoints.down('sm'));

  const headerActions = user ? (
    <>
      <Button variant='text' component={Link} to='/pricing'>
        Pricing
      </Button>
      <Button variant='outlined' component={Link} to='/studio/courses'>
        Go to Studio
      </Button>
      <AccountMenu user={user} />
    </>
  ) : (
    <>
      <Button variant='text' component={Link} to='/pricing'>
        Pricing
      </Button>
      <Button
        variant='text'
        component={Link}
        to='/studio/courses'
        onClick={() => KyronEvents.sendEvent(KyronEvents.names.LOGIN)}
      >
        Login
      </Button>
      <Button component={Link} to='/create' onClick={() => KyronEvents.sendEvent(KyronEvents.names.CREATE_FREE_COURSE)}>
        Create a course for free
      </Button>
    </>
  );

  return (
    <Row
      component='header'
      sx={{
        height: { sm: '96px', xs: '64px' },
        bgcolor: '#FFFFFFF2',
        px: { sm: 3, xs: 2 },
        position: 'sticky',
        top: 0,
        zIndex: 3,
      }}
    >
      <Link to='/' aria-label='Home'>
        <KyronLogo fill={theme.palette.primary.main} />
      </Link>
      <Row sx={{ ml: 'auto', gap: 3 }}>
        {isMobile ? (
          <KyronMenu menuIcon={<Menu />}>
            <Stack py={1} px={3} gap={2}>
              {headerActions}
            </Stack>
          </KyronMenu>
        ) : (
          headerActions
        )}
      </Row>
    </Row>
  );
}
