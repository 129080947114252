import React, { useContext, useState } from 'react';
import { Avatar, IconButton, Tooltip, Divider } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Tutor } from 'controllers/types';
import Typography from '@mui/material/Typography';
import SwapIcon from '@mui/icons-material/SwapHoriz';
import { CatalogLessonSelectionDialog } from 'components/CatalogLessonSelectionDialog/CatalogLessonSelectionDialog';
import { KyronEvents } from 'components/utils/KyronEvents';
import { UserContext } from 'components/UserContext';
import { useLessonCollectionQuery } from 'controllers/react-query';

type Props = {
  teacher: Tutor;
  collectionId?: number;
  lessonId: number;
  changeLesson?: (id: number) => void;
};

export function TutorSwitcher({ teacher, collectionId, lessonId, changeLesson }: Props) {
  const { isTeacher } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const { data: lessonCollection } = useLessonCollectionQuery(collectionId);
  const collection = lessonCollection?.lessons;
  const noOtherOptions = (collection?.length || 0) < 2; // there are no other lesson options, don't render tutor switcher

  const closeLessonSelectionDialog = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleTutorSwitch = (destLessonId: number) => {
    KyronEvents.sendEvent(KyronEvents.names.SWITCH_TUTOR, {
      destination_lesson_id: destLessonId,
      current_lesson_id: lessonId,
      is_teacher: isTeacher,
    });
    if (changeLesson) {
      changeLesson(destLessonId);
    }
  };

  // If we don't have teacher information or no other lesson (tutor) options
  // we can't render this component. So, return null.
  if (!teacher || noOtherOptions) return null;

  // If we don't have changeLesson fn we can't change lesson so we shouldn't render
  if (!changeLesson) {
    console.error(`Cannot render ${TutorSwitcher.name}: "changeLesson" prop is missing.`);
    return null;
  }

  return (
    <>
      <Divider />
      <Grid container spacing={1} m={1} display='flex' justifyContent='center'>
        <Grid display='flex' alignItems='center'>
          <Avatar src={teacher?.picture} alt={teacher?.display_name} sx={{ mr: 1 }} />
          <Typography noWrap variant='labelLarge'>
            {teacher?.display_name}
          </Typography>
        </Grid>
        <Grid>
          <Tooltip title='Change Tutor'>
            <IconButton data-testid='switch-tutor-button' onClick={handleClick}>
              <SwapIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <CatalogLessonSelectionDialog
          lessonsInCollection={collection}
          open={open}
          onClose={closeLessonSelectionDialog}
          activeLessonId={lessonId}
          changeLesson={handleTutorSwitch}
        />
      </Grid>
    </>
  );
}
