import React from 'react';
import { Assignment } from 'controllers/types';
import { Card, Stack, CardActionArea, CardContent, CardMedia, Typography, Grid, Breadcrumbs } from '@mui/material';
import { History, AccountCircle, NavigateNext, WarningAmber } from '@mui/icons-material';
import KyronIcon from 'assets/kyronicon-small.svg';

type Props = {
  assignment: Assignment;
  classroomId: string;
};

export const AssignmentCard = ({ assignment, classroomId }: Props) => {
  const cardActionHref = `/studio/classrooms/${classroomId}/assignments/${assignment.id}`;
  const noMisconceptions = assignment.students_with_misconceptions === 0;
  const multipleMisconceptions = assignment.students_with_misconceptions > 1;

  const getMisconceptionNote = `${
    noMisconceptions
      ? 'No students with misconceptions'
      : `${assignment.students_with_misconceptions} student${multipleMisconceptions ? 's' : ''} with misconceptions`
  }`;

  const getParticipantNote = `${assignment.students_attempted} ${
    assignment.students_attempted === 1 ? 'student' : 'students'
  } attempted`;

  const getTimeSinceAssignedNote = `Assigned ${assignment.time_since_assigned} ${
    assignment.time_since_assigned === 1 ? 'day' : 'days'
  } ago`;

  return (
    <Card variant='outlined' sx={{ height: '142px', backgroundColor: 'background.default' }}>
      <CardActionArea href={cardActionHref} sx={{ height: '100%' }}>
        <Grid container>
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia component='img' image={assignment.thumbnail || KyronIcon} sx={{ height: '100%' }} />
          </Grid>
          <Grid item xs={8}>
            <CardContent>
              {
                // TODO: Add channel and/or playlist breadcrumbs
                assignment.course && assignment.unit && (
                  <Breadcrumbs separator={<NavigateNext fontSize='inherit' />} aria-label='breadcrumb'>
                    <Typography variant='bodySmall'>{assignment.course}</Typography>
                    <Typography variant='bodySmall'>{assignment.unit}</Typography>
                  </Breadcrumbs>
                )
              }
              <Typography variant='titleMedium' color='primary.main' noWrap gutterBottom>
                {assignment.name}
              </Typography>
              <Stack spacing={0.5}>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <History fontSize='small' />
                  <Typography variant='bodyMedium' noWrap color='text.secondary'>
                    {getTimeSinceAssignedNote}
                  </Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <AccountCircle fontSize='small' />
                  <Typography variant='bodyMedium' noWrap color='text.secondary'>
                    {getParticipantNote}
                  </Typography>
                </Stack>
                <Stack direction='row' spacing={1} alignItems='center'>
                  <WarningAmber fontSize='small' color={noMisconceptions ? 'inherit' : 'error'} />
                  <Typography variant='bodyMedium' noWrap color={noMisconceptions ? 'text.secondary' : 'error.main'}>
                    {getMisconceptionNote}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};
