import React, { useState } from 'react';
import { DeleteModal } from '../common/DeleteModal';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withDeleteModal = (WrappedComponent, handleDelete) => {
  const WithDeleteModal = props => {
    const [deleteModal, setDeleteModal] = useState({
      isOpen: false,
      isConfirm: false,
    });

    const openDeleteModal = () => {
      setDeleteModal({
        isOpen: true,
        isConfirm: false,
      });
    };

    const handleCancelDeleteModal = () => {
      setDeleteModal({
        isOpen: false,
        isConfirm: false,
      });
    };

    const handleConfirmDeleteModal = () => {
      handleDelete(props);
      setDeleteModal({
        isOpen: false,
        isConfirm: true,
      });
    };

    return (
      <>
        <WrappedComponent {...props} openDeleteModal={openDeleteModal} />
        <DeleteModal
          isOpen={deleteModal.isOpen}
          handleCancelDelete={handleCancelDeleteModal}
          handleConfirmDelete={handleConfirmDeleteModal}
        />
      </>
    );
  };

  WithDeleteModal.displayName = `WithDeleteModal(${getDisplayName(WrappedComponent)})`;
  return WithDeleteModal;
};
