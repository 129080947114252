import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid2 from '@mui/material/Unstable_Grid2';
import BoxOverBoxTemplate from 'assets/box-over-box.svg';
import { Popover } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { CheckBoxOutlineBlank } from '@mui/icons-material';
import { DisplayType, OperatorType } from 'components/Equation/constants';
import { cloneDeep } from 'lodash';
import { EquationData } from './types';

const Wrapper = styled('div')`
  display: flex;
  align-items: center;
`;

const GridWrapper = styled('div')`
  width: 250px;
  padding: 20px;
`;

const TemplateIconWrapper = styled('span')`
  font-size: 2rem;
`;

const StyledIconButton = styled(IconButton)`
  height: 40px;
  width: 40px;
  color: rgba(0, 0, 0, 1);
`;

type EquationTemplateType = {
  id: number;
  label: string;
  template: JSX.Element | string;
  value: EquationData;
};

const EQUATION_TEMPLATE_ICONS: EquationTemplateType[] = [
  {
    id: 1,
    label: 'text box template',
    template: <TextFieldsIcon />,
    value: {
      displayType: DisplayType.TEXT,
      value: '',
    },
  },
  {
    id: 2,
    label: 'box template',
    template: <CheckBoxOutlineBlank />,
    value: {
      displayType: DisplayType.BOX,
      value: '',
    },
  },
  {
    id: 3,
    label: 'box over box template',
    template: <img alt='box over box template' src={BoxOverBoxTemplate} height='27' />,
    value: {
      displayType: DisplayType.BOX,
      value: '',
      over: [
        {
          displayType: DisplayType.BOX,
          value: '',
        },
      ],
    },
  },
  {
    id: 4,
    label: 'plus',
    template: '\u002B',
    value: {
      displayType: DisplayType.OPERATOR,
      value: OperatorType.PLUS,
    },
  },
  {
    id: 5,
    label: 'subtract',
    template: '\u2212',
    value: {
      displayType: DisplayType.OPERATOR,
      value: OperatorType.SUBTRACT,
    },
  },
  {
    id: 6,
    label: 'multiply',
    template: '\u00D7',
    value: {
      displayType: DisplayType.OPERATOR,
      value: OperatorType.MULTIPLY,
    },
  },
  {
    id: 7,
    label: 'divide',
    template: '\u00F7',
    value: {
      displayType: DisplayType.OPERATOR,
      value: OperatorType.DIVIDE,
    },
  },
  {
    id: 8,
    label: 'equals',
    template: '\u003D',
    value: {
      displayType: DisplayType.OPERATOR,
      value: OperatorType.EQUALS,
    },
  },
  {
    id: 9,
    label: 'greaterThan',
    template: '\u003E',
    value: {
      displayType: DisplayType.OPERATOR,
      value: OperatorType.GREATER_THAN,
    },
  },
  {
    id: 10,
    label: 'greaterThanOrEqual',
    template: '\u2265',
    value: {
      displayType: DisplayType.OPERATOR,
      value: OperatorType.GREATER_THAN_OR_EQUAL,
    },
  },
  {
    id: 11,
    label: 'lessThan',
    template: '\u003C',
    value: {
      displayType: DisplayType.OPERATOR,
      value: OperatorType.LESS_THAN,
    },
  },
  {
    id: 12,
    label: 'lessThanOrEqual',
    template: '\u2264',
    value: {
      displayType: DisplayType.OPERATOR,
      value: OperatorType.LESS_THAN_OR_EQUAL,
    },
  },
];

type EquationTemplateButtonProps = {
  onSelect: (equationData: EquationData) => void;
};

// This component should show user the operator and in the future a dropdown to choose a specific operator
export const EquationTemplatePicker = ({ onSelect }: EquationTemplateButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Wrapper>
      <IconButton
        data-testid='student-fractions-input-button'
        aria-label='submit fractions input'
        type='submit'
        size='large'
        onClick={handleClick}
      >
        <AddIcon style={{ width: '20px', height: '20px' }} />
      </IconButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <GridWrapper>
          <Grid2 container spacing={3}>
            {EQUATION_TEMPLATE_ICONS.map(equation => (
              <Grid2 key={equation.id} xs={4}>
                <StyledIconButton aria-label={equation.label} onClick={() => onSelect(cloneDeep(equation.value))}>
                  <TemplateIconWrapper>{equation.template}</TemplateIconWrapper>
                </StyledIconButton>
              </Grid2>
            ))}
          </Grid2>
        </GridWrapper>
      </Popover>
    </Wrapper>
  );
};
