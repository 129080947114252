import React from 'react';
import { Button } from '@mui/material';
import { useCreateCustomerPortalSession } from 'controllers/react-query/paymentHooks';

export const ManagePaymentsButton = () => {
  const { mutate: createCustomerPortalSession } = useCreateCustomerPortalSession();
  const handleOpenPaymentSettings = () => {
    createCustomerPortalSession(
      {},
      {
        onSuccess: data => {
          window.open(data.url, '_blank');
        },
      },
    );
  };

  return (
    <Button onClick={handleOpenPaymentSettings} variant='contained' color='primary' sx={{ width: 'fit-content' }}>
      Open Payment Settings
    </Button>
  );
};
