import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DEFAULT_HEIGHT = 40;
export const RoundedTextField = styled(TextField, { shouldForwardProp: p => p !== 'height' })<{
  height?: number | string;
}>(({ height = DEFAULT_HEIGHT }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  '& .MuiOutlinedInput-root': { borderRadius: 30, padding: '0 8px 0 12px', height },
  '& .MuiInputLabel-root': { lineHeight: '1.3em' },
  // When auto fill kicks in, there is a blue highlight that seems awkward without this style override
  '& .MuiOutlinedInput-input:-webkit-autofill': { paddingLeft: '1em', height: '0.3em' },
  '& .MuiFormHelperText-root': { fontSize: '11px', marginTop: 0 },
}));
