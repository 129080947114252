/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  FormGroup,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

import { ExpandMore } from '@mui/icons-material';

export const AdvancedOptionsForm = props => (
  <Accordion data-testid='advanced-accordion'>
    <AccordionSummary expandIcon={<ExpandMore />} aria-controls='advanced-editor-content'>
      <Typography>Advanced Options</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Stack gap={2}>
        <TextField
          data-testid='wait-timeout-input'
          name='wait_timeout'
          type='text'
          fullWidth
          margin='normal'
          label='Wait Timeout'
          onChange={props.handleTime}
          value={props.state.wait_timeout}
        />
        <TextField
          data-testid='dialogflow-page-readonly'
          fullWidth
          margin='normal'
          label='Dialogflow Page'
          value={props.state.dialogflow_page || ' '}
          readOnly
          helperText='Read Only'
        />

        <FormGroup>
          <FormControl fullWidth>
            <InputLabel id='event_to_send-label'>Segment Start Options</InputLabel>
            <Select
              data-testid='event_to_send-input'
              name='event_to_send'
              labelId='event_to_send-label'
              label='Segment Start Options'
              value={props.state.event_to_send}
              onChange={props.handleInputChange}
            >
              <MenuItem value=''>None (default)</MenuItem>
              <MenuItem value='start'>Segment starts lesson</MenuItem>
              <MenuItem value='start_section'>Segment starts section</MenuItem>
            </Select>
          </FormControl>
        </FormGroup>
      </Stack>
    </AccordionDetails>
  </Accordion>
);

AdvancedOptionsForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleTime: PropTypes.func,
};
