import React from 'react';
import { usePaymentSessionQuery } from 'controllers/react-query/paymentHooks';
import { Typography } from '@mui/material';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useParams } from 'react-router-dom';

export const CompletePayment = () => {
  const params = useParams();
  const { isFetching, isError, data } = usePaymentSessionQuery(params.sessionId);

  const paidMessage = (
    <>
      <Typography variant='headlineSmall' sx={{ mb: 1, ml: 1 }}>
        Your payment was successful!
      </Typography>
      <Typography sx={{ mb: 1, ml: 1 }}>
        You paid ${data?.amount_total} for the {data?.product}.
      </Typography>
    </>
  );

  const unpaidMessage = (
    <Typography variant='headlineSmall' sx={{ mb: 1, ml: 1 }}>
      Your payment was not successful. Please contact support@kyronlearning.com for help.
    </Typography>
  );

  const success = data?.payment_status === 'paid' && data?.status === 'complete';

  return (
    <>
      {isFetching && <LoadingIndicator data-testid='loading-indicator' />}
      {isError && <Typography variant='headlineSmall'>There was an error processing your subscription.</Typography>}
      {data && success && paidMessage}
      {data && !success && unpaidMessage}
    </>
  );
};
