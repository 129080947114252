import React from 'react';
import { styled } from '@mui/material/styles';
import { getFontSize } from 'components/Equation/util';
import { BoxComponentProps, Size } from './types';

const Wrapper = styled('div')<{ size?: Size }>`
  display: inline-block;
  font-size: ${props => getFontSize(props.size)};

  > div {
    padding: 0 0.5rem;
    text-align: center;
  }
`;

const OPERATOR: { [key: string]: string } = {
  plus: '\u002B',
  subtract: '\u2212',
  multiply: '\u00D7',
  divide: '\u00F7',
  equals: '\u003D',
  greaterThan: '\u003E',
  greaterThanOrEqual: '\u2265',
  lessThan: '\u003C',
  lessThanOrEqual: '\u2264',
};

const getOperator = (value: string): string => {
  if (OPERATOR[value]) {
    return OPERATOR[value];
  }
  return value;
};

// This component should show user the operator and in the future a dropdown to choose a specific operator
export const BoxOperator = ({ value, size }: BoxComponentProps) => <Wrapper size={size}>{getOperator(value)}</Wrapper>;
