import { Typography } from '@mui/material';
import React from 'react';
import { StripePricingTable } from 'components/KyronStripeElements';

export const PaymentSubscriptions = () => (
  <>
    <Typography variant='headlineSmall' sx={{ mb: 1, ml: 1 }}>
      Pick the right plan for you.
    </Typography>
    <StripePricingTable />
  </>
);
