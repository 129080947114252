import React from 'react';
import { useParams } from 'react-router-dom';
import { LessonCollection } from 'controllers/types';
import { useSnackbar } from 'notistack';
import { useCreatePlaylistAssignment } from 'controllers/react-query/playlistsHooks';
import { LessonAssignmentListBase } from './LessonAssignmentListBase';

type Props = {
  closeDrawer: () => void;
};

export const LessonAssignmentListPlaylist = ({ closeDrawer }: Props) => {
  const { playlistId } = useParams();
  const { mutate: assignToLessonPlaylist, isPending } = useCreatePlaylistAssignment();
  const { enqueueSnackbar } = useSnackbar();

  const assignToPlaylist = (lessonCollection: LessonCollection) => {
    if (!playlistId) {
      enqueueSnackbar('Error assigning to playlist', { variant: 'error' });
      return;
    }
    assignToLessonPlaylist(
      {
        playlistId: parseInt(playlistId, 10),
        lessonId: lessonCollection.id,
      },
      {
        onSuccess: () => {
          closeDrawer();
          enqueueSnackbar('Lesson assigned successfully.', { variant: 'success' });
        },
        onError: () => {
          closeDrawer();
          enqueueSnackbar(`Failed to assign lesson.`, { variant: 'error' });
        },
      },
    );
  };

  return (
    <LessonAssignmentListBase
      closeDrawer={closeDrawer}
      assignLesson={assignToPlaylist}
      isPending={isPending}
      title='Add Lesson to Playlist'
      toolTipText='Assign to Playlist'
    />
  );
};
