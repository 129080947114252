import React, { useRef } from 'react';
import { CaptureData, EquationData } from 'components/Equation/types';
import { SimpleEquation } from 'components/Equation/SimpleEquation';
import { styled } from '@mui/material/styles';
import { EquationTemplatePicker } from 'components/Equation/EquationTemplatePicker';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import RemoveIcon from '@mui/icons-material/Remove';
import { InputLabel, Stack } from '@mui/material/';

const Wrapper = styled('div')`
  width: 100%;
  position: relative;
  display: inline-flex;
  padding: 0 8px;
  gap: 10px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  button:first-of-type {
    margin-left: auto;
  }
  '&:hover': {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }
`;

const StyledSimpleEquation = styled(SimpleEquation)`
  display: flex;
  margin: 8px 10px 8px 0;
  min-height: 42px;
  padding: 10px;
`;

const LabelWrapper = styled(InputLabel)(({ theme }) => ({
  position: 'absolute',
  top: '-10px',
  padding: '0 5px',
  fontSize: theme.palette.simpleEquationBuilder.labelFontSize,
  backgroundColor: theme.palette.simpleEquationBuilder.labelBackground,
}));

const IconButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
`;

export type SimpleFractionBuilderProps = {
  label?: string;
  data: EquationData[];
  onChanges: (equationInput: EquationData[] | undefined) => void;
};

/*
    This component helps to build a simple equation with text and boxes.This will be used in the TargetSegmentEdit component.
    As part of the workflow, we can use this to create options for equation style multiple choice questions,
    as well as create empty boxes for the student to fill in.
 */
export const SimpleEquationBuilder = ({ label, data, onChanges }: SimpleFractionBuilderProps) => {
  const [equationData, setEquationData] = React.useState(data ?? []);
  const [builderMode, setBuilderMode] = React.useState(false);
  const fractionRef = useRef<CaptureData>(null);

  const onSelect = (newData: EquationData) => setEquationData([...equationData, newData]);

  const handleDone = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (fractionRef.current) {
      setEquationData(fractionRef.current.getData());
      onChanges(fractionRef.current.getData());
      setBuilderMode(false);
    }
  };

  const handleRemove = () => {
    setEquationData(equationData.slice(0, -1));
  };

  if (!equationData) return null;

  return (
    <Wrapper>
      <LabelWrapper>{label}</LabelWrapper>
      <StyledSimpleEquation builderMode={builderMode} data={equationData} size='small' ref={fractionRef} />
      {!builderMode ? (
        <IconButtonWrapper>
          <IconButton
            data-testid='edit-equation-input-button'
            aria-label='edit equation input'
            type='submit'
            size='large'
            onClick={() => setBuilderMode(true)}
          >
            <EditIcon style={{ width: '20px', height: '20px' }} />
          </IconButton>
        </IconButtonWrapper>
      ) : (
        <>
          <Stack>
            <EquationTemplatePicker onSelect={onSelect} />
            <IconButtonWrapper>
              <IconButton
                data-testid='remove-equation-item-button'
                aria-label='remove equation item'
                size='large'
                onClick={handleRemove}
              >
                <RemoveIcon style={{ width: '20px', height: '20px' }} />
              </IconButton>
            </IconButtonWrapper>
          </Stack>
          <IconButtonWrapper>
            <IconButton
              data-testid='confirm-update-equation-input-button'
              aria-label='confirm update fractions input'
              size='large'
              onClick={handleDone}
            >
              <DoneIcon style={{ width: '20px', height: '20px' }} />
            </IconButton>
          </IconButtonWrapper>
        </>
      )}
    </Wrapper>
  );
};
