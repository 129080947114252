import React, { useState } from 'react';
import { useRedirectIfFeatureDisabled } from 'components/FeaturesContext';
import { CanvasListCourses } from './CanvasListCourses';
import { CanvasListModules } from './CanvasListModules';

export type Props = {
  onSelect: (courseId: string, moduleId: string) => void;
};

export function CanvasCourseSelector({ onSelect }: Props) {
  useRedirectIfFeatureDisabled({ feature: 'show_canvas_courses', redirectTo: '/' });
  const [courseId, setCourseId] = useState<string | null>(null);

  if (courseId === null) {
    return <CanvasListCourses onSelect={setCourseId} />;
  }

  return <CanvasListModules courseId={courseId} onSelect={moduleId => onSelect(courseId, moduleId)} />;
}
