import React from 'react';
import { Dialog, DialogTitle, DialogContent, Stack, Button, Divider, Typography, Link } from '@mui/material';
import googleLogo from 'assets/omniauth-google-logo.svg';
import microsoftLogo from 'assets/omniauth-microsoft-logo.svg';
import cleverLogo from 'assets/omniauth-clever-logo.svg';
import { useFeatures } from 'components/FeaturesContext';

type Props = {
  canCancel?: boolean;
  message?: string;
  title?: string;
};
export const SignInDialog = ({ canCancel, message, title = 'Welcome to Kyron Learning!' }: Props) => {
  const { enable_clever_sign_in: enableCleverSignIn } = useFeatures();
  const [isOpen, setIsOpen] = React.useState(true);
  const redirectTo = `?redirect_to=${window.location.pathname}`;

  return (
    <Dialog open={isOpen}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {message && <DialogContent>{message}</DialogContent>}
      <Stack m={3} gap={1}>
        <Button
          variant='outlined'
          startIcon={<LogoIcon src={googleLogo} alt='Login with Google' />}
          component='a'
          href={`/users/auth/google_sign_in${redirectTo}`}
          data-method='post'
        >
          Continue with Google
        </Button>
        <Button
          variant='outlined'
          startIcon={<LogoIcon src={microsoftLogo} alt='Login with Microsoft' />}
          component='a'
          href={`/users/auth/microsoft_office365${redirectTo}`}
          data-method='post'
        >
          Continue with Microsoft
        </Button>
        {enableCleverSignIn && (
          <Button
            variant='outlined'
            startIcon={<LogoIcon src={cleverLogo} alt='Login with Clever' />}
            component='a'
            href={`/users/auth/clever${redirectTo}`}
            data-method='post'
          >
            Continue with Clever
          </Button>
        )}
        {canCancel && (
          <>
            <Divider />
            <Button variant='outlined' onClick={() => setIsOpen(false)}>
              Continue as Guest
            </Button>
          </>
        )}
        <Divider />
        <Typography variant='bodySmall' color='text.secondary' data-testid='terms-of-service'>
          By signing up for Kyron Learning you acknowledge that you agree to Kyron Learning&apos;s{' '}
          <Link href='/terms_of_service'>Terms of Service</Link> and <Link href='/privacy_policy'>Privacy Policy</Link>,
          and you consent to receive occasional marketing emails. You can opt out at any time.
        </Typography>
      </Stack>
    </Dialog>
  );
};

function LogoIcon({ src, alt }: { src: string; alt: string }) {
  return <img style={{ height: 24, paddingRight: 4 }} src={src} alt={alt} />;
}
