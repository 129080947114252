/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useSnackbar } from 'notistack';
import dayjs, { Dayjs } from 'dayjs';
import { KyronLayoutContent } from 'components/KyronLayout/KyronLayout';
import { DataGrid } from '../common/DataGrid';
import { KyronClient } from '../utils/KyronClient';
import { DateRangePicker } from './DateRangePicker';

const today = dayjs();
const lessonInstanceColumns = [
  {
    field: 'id',
    headerName: 'ID',
  },
  {
    field: 'session_id',
    headerName: 'SessionID',
  },
  {
    field: 'user_id',
    headerName: 'UserID',
  },
  {
    field: 'lesson_guid',
    headerName: 'LessonGUID',
  },
  {
    field: 'lesson_title',
    headerName: 'LessonTitle',
  },
  {
    field: 'lesson_id',
    headerName: 'LessonID',
    minWidth: 200,
  },
  {
    field: 'segment_count',
    headerName: 'Count of LessonSegments',
  },
];
const segmentInstanceColumns = [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 40,
  },
  {
    field: 'segment_id',
    headerName: 'SegmentID',
    minWidth: 40,
  },
  {
    field: 'name',
    headerName: 'Segment Name',
    minWidth: 150,
  },
  {
    field: 'created_at',
    headerName: 'Created',
    minWidth: 150,
  },
  {
    field: 'segment_type',
    headerName: 'Segment Type',
    minWidth: 45,
  },
  {
    field: 'misconceptions',
    headerName: 'MisconceptionIDs',
    minWidth: 50,
  },
  {
    field: 'question',
    headerName: 'Question',
    minWidth: 200,
  },
  {
    field: 'student_text',
    headerName: 'Student Input',
    minWidth: 200,
  },
  {
    field: 'input_type',
    headerName: 'Input Type',
    minWidth: 50,
  },
  {
    field: 'confidence',
    headerName: 'Intent Detection Confidence',
    minWidth: 40,
  },
  {
    field: 'match_confidence',
    headerName: 'Match Confidence',
    minWidth: 40,
  },
  {
    field: 'match_type',
    headerName: 'Match Type',
    minWidth: 100,
  },
  {
    field: 'next_segment_name',
    headerName: 'Next Segment Name',
    minWidth: 150,
  },
];

const styles = {
  ReportTable: {
    width: '100%',
    height: '100vh',
  },
};

// TODO: should move type/API calls to controllers/lessonInstances
type LessonInstance = {
  id: number;
  session_id: string;
  user_id: string;
  lesson: {
    guid: string;
    name: string;
  };
  lesson_id: number;
  created_at: string;

  lesson_segment_instances: any[];
};

type LessonInstanceRow = {
  id: number;
  session_id: string;
  user_id: string;
  lesson_guid: string;
  lesson_title: string;
  lesson_id: number;
  created_at: string;
  segment_count: number;
};

type SegmentInstanceRow = {
  id: number;
  segment_id: number;
  name: string;
  created_at: string;
  segment_type: string;
  misconceptions: string[];
  question: string;
  student_text: string;
  input_type: string;
  confidence: number;
  match_confidence: number;
  match_type: string;
  next_segment_name: string;
};

export const LessonInstanceReport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [lessonInstances, setLessonInstances] = useState<LessonInstance[]>([]);
  const [startDate, setStartDate] = useState<Dayjs>(today);
  const [endDate, setEndDate] = useState<Dayjs>(today.subtract(1, 'day'));
  const [lessonInstanceRows, setLessonInstanceRows] = useState<LessonInstanceRow[]>([]);
  const [segmentInstanceRows, setSegmentInstanceRows] = useState<SegmentInstanceRow[]>([]);
  const [segmentVisible, setSegmentVisible] = useState<boolean>(false);
  const [lessonInstanceId, setLessonInstanceId] = useState<number>(0);
  const client = new KyronClient();

  useEffect(() => {
    // TODO: move client call into /controllers
    const url = reportUrl('json', startDate, endDate);
    client
      .getDataWithError(url)
      .then(data => {
        const instances = data as LessonInstance[];
        setLessonInstances(instances);
        setLessonInstanceRows(
          instances.map(instance => ({
            id: instance.id,
            session_id: instance.session_id,
            user_id: instance.user_id,
            lesson_guid: instance.lesson.guid,
            lesson_title: instance.lesson.name,
            lesson_id: instance.lesson_id,
            created_at: instance.created_at,
            segment_count: instance.lesson_segment_instances.length,
          })),
        );
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const handleInstanceRowClick = (e: { data: Record<string, any> }) => {
    setSegmentVisible(true);
    setLessonInstanceId(e.data.id);
    const lessonInstance = lessonInstances.filter(instance => instance.id === e.data.id)[0];
    setSegmentInstanceRows(
      lessonInstance.lesson_segment_instances.map((instance, i) => ({
        id: instance.id,
        segment_id: instance.lesson_segment_id,
        name: instance.lesson_segment !== undefined ? instance.lesson_segment.name : '',
        created_at: instance.created_at,
        segment_type: instance.lesson_segment !== undefined ? instance.lesson_segment.segment_type : '',
        misconceptions:
          instance.lesson_segment !== undefined
            ? instance.lesson_segment.lesson_misconceptions.map((concept: any) => concept.code)
            : '',
        question: instance.lesson_segment !== undefined ? instance.lesson_segment.question : '',
        student_text: instance.student_text_input,
        input_type: instance.input_type,
        confidence: instance.confidence,
        match_confidence: instance.match_confidence,
        match_type: instance.match_type,
        next_segment_name: nextSegmentName(lessonInstance.lesson_segment_instances, i),
      })),
    );
  };

  function nextSegmentName(allLessonSegments: any, currentInstanceIdx: number) {
    const nextInstance = allLessonSegments[currentInstanceIdx + 1];
    return nextInstance?.lesson_segment.name;
  }

  const handleBackToLessonInstance = () => {
    setSegmentVisible(false);
    setLessonInstanceId(0);
  };

  const handleDateRange = (sDate: Dayjs, eDate: Dayjs) => {
    setStartDate(sDate);
    setEndDate(eDate);
  };

  function reportUrl(format: string, start: Dayjs, end: Dayjs) {
    return `/api/v1/lesson_instances/report.${format}?${new URLSearchParams({
      start_time: start.toISOString(),
      end_time: end.toISOString(),
    })}`;
  }

  const testCSV = `/api/v1/lesson_instances/${lessonInstanceId}/generate_test.csv`;

  const columns = segmentVisible ? segmentInstanceColumns : lessonInstanceColumns;
  const rows: (LessonInstanceRow | SegmentInstanceRow)[] = segmentVisible ? segmentInstanceRows : lessonInstanceRows;
  const clickFunc = segmentVisible ? undefined : handleInstanceRowClick;
  const reportURL = reportUrl('csv', startDate, endDate);

  return (
    <KyronLayoutContent>
      <DateRangePicker timestampStart={startDate} timestampEnd={endDate} handleDateRange={handleDateRange} />
      {segmentVisible ? (
        <Button variant='outlined' startIcon={<DownloadIcon />}>
          <a href={testCSV}>Download Test CSV</a>
        </Button>
      ) : (
        <Button variant='outlined' startIcon={<DownloadIcon />}>
          <a href={reportURL}>Download CSV</a>
        </Button>
      )}
      <Box sx={styles.ReportTable}>
        {segmentVisible && (
          <IconButton data-testid='lesson-instance-button' onClick={handleBackToLessonInstance}>
            <KeyboardBackspaceIcon />
          </IconButton>
        )}
        {rows.length > 0 && <DataGrid rows={rows} columns={columns} onRowClicked={clickFunc} />}
      </Box>
    </KyronLayoutContent>
  );
};
