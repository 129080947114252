import React, { useState } from 'react';
import { Grid } from '@mui/material';

import { useRedirectIfFeatureDisabled } from 'components/FeaturesContext';
import { KyronLayoutContent } from 'components/KyronLayout/KyronLayout';
import { LessonCollection } from 'controllers/types';
import { AdminCollection } from './AdminCollection';
import { AdminCollectionList } from './AdminCollectionList';

export function AdminLessons(): React.JSX.Element {
  useRedirectIfFeatureDisabled({ feature: 'show_admin_lessons', redirectTo: '/studio/internal_lesson_editor' }); // TODO(derick): remove this when feature flag show_admin_lessons is removed

  const [selectedCollection, setSelectedCollection] = useState<LessonCollection>();

  return (
    <KyronLayoutContent>
      <Grid container spacing={2} sx={{ flexWrap: 'nowrap' }}>
        <Grid item xs={6} sm={3} sx={{ minWidth: 440, pt: 0, p: 2 }}>
          <AdminCollectionList selectedCollection={selectedCollection} setSelectedCollection={setSelectedCollection} />
        </Grid>
        <Grid item xs={12} sm={9} sx={{ maxHeight: '95vh', overflowY: 'auto' }}>
          <AdminCollection collection={selectedCollection} />
        </Grid>
      </Grid>
    </KyronLayoutContent>
  );
}
