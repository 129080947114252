import { useKyronMutationV2 } from './kyronMutation';
import { keepPreviousData, useKyronQuery } from './kyronQuery';
import { Pagination } from '../types';
import { LtiPlatform, LtiPlatformPayload } from '../ltiPlatforms';

export const useCreateLtiPlatform = () =>
  useKyronMutationV2<{ payload: LtiPlatformPayload }, LtiPlatform>(`/lti_platforms`, {
    invalidates: ['/lti_platforms'],
  });

export const useUpdateLtiPlatform = () =>
  useKyronMutationV2<{ platformId: number; payload: LtiPlatformPayload }, LtiPlatform>(`/lti_platforms/:platformId`, {
    method: 'PATCH',
    invalidates: ['/lti_platforms'],
  });

export const useGetLtiPlatforms = (page = 1, perPage = 9) =>
  useKyronQuery<{
    lti_platforms: LtiPlatform[];
    meta: Pagination;
  }>(`/lti_platforms?page=${page}&per_page=${perPage}`, {
    queryKey: ['/lti_platforms', page, perPage],
    placeholderData: keepPreviousData,
  });

export const useDeleteLtiPlatform = () =>
  useKyronMutationV2(`/lti_platforms/:platformId`, { method: 'DELETE', invalidates: ['/lti_platforms'] });

/**
 * Given a platform id, a lti launch param and as a request body the lesson collection ids, fetches the deep link response
 * as JWT to include in the form submission to redirect the user back to the LTI platform.
 *
 * This will return a JWT and a redirect URL.
 * `{ jwt: string, redirect_url: string }`
 */
export const useGetDeepLinkResponse = () =>
  useKyronMutationV2<
    { platformId: string; ltiLaunch: string; cancelProcess?: boolean; payload?: { lessonCollectionIds: number[] } },
    { jwt: string; redirect_url: string }
  >(`/lti_platforms/:platformId/deep_link_response?lti_launch=:ltiLaunch&cancel_process=:cancelProcess`);
