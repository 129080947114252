// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`NoResources renders correctly 1`] = `
<DocumentFragment>
  .emotion-0 {
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 200px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  width: 144px;
  height: 144px;
  background-color: #F1ECF4;
}

.emotion-3 {
  margin: 0;
}

.emotion-4 {
  margin-top: 8px;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <img
          alt=""
          src="SvgrURL"
          width="167"
        />
      </div>
      <span
        class="MuiTypography-root MuiTypography-body1 emotion-3"
      >
        Get started by adding a member
      </span>
      <div
        class="MuiBox-root emotion-4"
      >
        <button
          disabled=""
          type="button"
        >
          Add Member
        </button>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
