import React, { useState } from 'react';
import { Alert, AlertTitle, IconButton, Stack, Typography, Box, Pagination, Tooltip } from '@mui/material';
import { Close, AddCircle } from '@mui/icons-material';
import { Row } from 'components/Row/Row';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { LessonCollection } from 'controllers/types';
import { useGetAssignableCollections } from 'controllers/react-query/lessonCollectionHooks';
import { SearchField } from '../SearchField';
import { NoResources } from '../NoResources';
import { KyronImage } from '../KyronImage/KyronImage';
import { TypographyWithEllipsis } from '../TypographyWithEllipsis/TypographyWithEllipsis';
import { LoadingSkeletonItem } from './utils';

type Props = {
  closeDrawer: () => void;
  assignLesson: (lessonCollection: LessonCollection) => void;
  isPending: boolean;
  title: string;
  toolTipText: string;
};

const LIST_CONTENT_WIDTH = '94%';

const styles = {
  PaginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
};

export const LessonAssignmentListBase = ({ closeDrawer, assignLesson, isPending, title, toolTipText }: Props) => {
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const { isFetching, isRefetching, isError, error, data } = useGetAssignableCollections({
    page,
    perPage: 25,
    search: searchText,
  });
  const { lesson_collections: lessonCollections, meta } = data || {};
  const noLessonsFound = !isFetching && !lessonCollections?.length;
  const [assignedLessonId, setAssignedLessonId] = useState(0);

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Row justifyContent='space-between' alignItems='center'>
        <Typography variant='titleLarge'>{title}</Typography>
        <IconButton onClick={closeDrawer} data-testid='close-drawer'>
          <Close />
        </IconButton>
      </Row>

      <SearchField
        dataTestID='search-field'
        color={isError ? 'error' : 'primary'}
        placeholder='Search for courses'
        onSearch={setSearchText}
        isLoading={isFetching}
        searchError={isError ? 'Failed when searching for courses' : null}
      />

      {isError && (
        <Alert severity='error'>
          <AlertTitle>An error occurred while fetching courses</AlertTitle>
          {error.message}
        </Alert>
      )}

      {noLessonsFound && <NoResources message='No courses found' />}

      {/* LESSON LIST */}
      <Stack spacing={1} sx={{ py: 2, overflow: 'scroll', borderRadius: 1 }}>
        <LoadingSkeletonItem count={isFetching && !isRefetching ? 10 : 0} listContentWidth={LIST_CONTENT_WIDTH} />
        {lessonCollections?.map(lessonCollection => (
          <Box
            key={lessonCollection.slug}
            sx={{ display: 'grid', placeItems: 'center', gridTemplateColumns: '40px 1fr', gap: 1 }}
          >
            {isPending && assignedLessonId === lessonCollection.id ? (
              <LoadingIndicator data-testid='loading-indicator' />
            ) : (
              <Tooltip title={toolTipText} placement='top'>
                <IconButton
                  color='primary'
                  onClick={() => {
                    setAssignedLessonId(lessonCollection.id);
                    assignLesson(lessonCollection);
                  }}
                  data-testid='assign-lesson-button'
                  disabled={isPending && assignedLessonId !== lessonCollection.id}
                >
                  <AddCircle />
                </IconButton>
              </Tooltip>
            )}
            <Box sx={{ width: LIST_CONTENT_WIDTH, bgcolor: 'surfaceContainer.main', borderRadius: 1 }}>
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  padding: '12px',
                  display: 'grid',
                  gridTemplateColumns: '80px 1fr',
                  gridTemplateRows: '100%',
                  gap: 2,
                }}
              >
                <KyronImage
                  size={80}
                  objectFit='cover'
                  src={lessonCollection.collection_thumbnail}
                  alt={`collection-thumbnail-${lessonCollection.name}`}
                  dataTestID='collection-thumbnail'
                />
                <Stack spacing={0.5}>
                  <TypographyWithEllipsis variant='titleMedium' lineCount={2}>
                    {lessonCollection.name}
                  </TypographyWithEllipsis>
                  <TypographyWithEllipsis variant='bodySmall' lineCount={2}>
                    {lessonCollection.description}
                  </TypographyWithEllipsis>
                </Stack>
              </Box>
            </Box>
          </Box>
        ))}
      </Stack>
      <Pagination
        style={styles.PaginationWrapper}
        count={meta?.total_pages || 1}
        siblingCount={5}
        shape='rounded'
        page={page}
        onChange={(_e, newPage) => setPage(newPage)}
      />
    </Stack>
  );
};
