/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  TextField,
} from '@mui/material';
import { ExpandMore, Delete, Add } from '@mui/icons-material';

const styles = {
  editors: {
    marginTop: '10px',
  },
  markedForDeletion: {
    textDecoration: 'line-through',
  },
};

export const LessonEditors = props => {
  const [newLessonEditorEmail, setNewLessonEditorEmail] = useState('');

  const sortedEditors = editors =>
    // TODO: Sort on backend
    editors;
  const addLessonEditor = () => {
    // TODO: Add email address validation

    props.setEditors([
      ...props.editors,
      {
        email: newLessonEditorEmail,
        first_name: newLessonEditorEmail,
        last_name: '(save to see name)',
      },
    ]);
    setNewLessonEditorEmail('');
  };

  const deleteLessonEditor = email => {
    const editors = props.editors.slice();
    for (let i = 0; i < editors.length; i++) {
      if (editors[i].email !== email) {
        continue;
      }

      if (editors[i].id) {
        editors[i]._destroy = true;
      } else {
        editors.splice(i, 1);
      }

      break;
    }

    props.setEditors(editors);
  };

  const updateNewLessonEditorEmail = e => {
    setNewLessonEditorEmail(e.target.value);
  };

  // Pressing "Enter" while typing in the email of a lesson editor should
  // create a new lesson editor rather than submitting the larger lesson edit
  // form.
  const handleEnterKey = e => {
    if (e.key !== 'Enter') {
      return;
    }

    addLessonEditor();
    e.preventDefault();
    return false;
  };

  return (
    <Accordion style={styles.editors}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>Lesson Editors</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <List>
            {sortedEditors(props.editors).map(editor => (
              <ListItem
                key={editor.email}
                data-testid='editor-list-item'
                secondaryAction={
                  <IconButton
                    edge='end'
                    aria-label='delete lesson editor'
                    onClick={() => deleteLessonEditor(editor.email)}
                  >
                    <Delete />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={`${editor.first_name} ${editor.last_name}`}
                  secondary={editor.email}
                  style={editor._destroy ? styles.markedForDeletion : null}
                />
              </ListItem>
            ))}
            <ListItem
              key='new_email'
              secondaryAction={
                <IconButton
                  data-testid='new-lesson-editor-email-button'
                  edge='end'
                  aria-label='add new lesson editor'
                  onClick={addLessonEditor}
                >
                  <Add />
                </IconButton>
              }
            >
              <TextField
                data-testid='new-lesson-editor-email-text'
                variant='outlined'
                name='lesson_editor_email'
                fullWidth
                margin='normal'
                label='New Lesson Editor Email'
                type='text'
                onChange={updateNewLessonEditorEmail}
                onKeyDown={handleEnterKey}
                value={newLessonEditorEmail}
              />
            </ListItem>
          </List>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

LessonEditors.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editors: PropTypes.array.isRequired,
  setEditors: PropTypes.func.isRequired,
};
