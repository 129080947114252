import { Box, Button, CardActions, Stack, Drawer, TextField, Typography, MenuItem } from '@mui/material';
import { PostAddOutlined } from '@mui/icons-material';
import React, { useState } from 'react';
import { PlaylistPayload } from 'controllers/react-query/playlistsHooks';
import { MarketplaceLessonCard } from 'components/Marketplace/MarketplaceLessonCard/MarketplaceLessonCard';
import { LessonAssignmentListPlaylist } from '../../LessonAssignmentList/LessonAssignmentListPlaylist';

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '360px',
    gap: '16px',
  },
};

export const PlaylistForm = ({
  playlistEdit,
  onSubmit,
}: {
  playlistEdit?: PlaylistPayload;
  onSubmit: (playlistEdit: PlaylistPayload) => void;
}) => {
  const playlistInitialState = {
    name: playlistEdit?.name || '',
    description: playlistEdit?.description || '',
  };
  const [formPlaylist, setFormPlaylist] = useState(playlistInitialState);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormPlaylist(prevFormPlaylist => ({
      ...prevFormPlaylist,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formPlaylist);
  };

  const [showAssignmentDrawer, setShowAssignmentDrawer] = useState(false);
  function togglePanel() {
    setShowAssignmentDrawer(!showAssignmentDrawer);
  }
  const handleRemoveMenuItemClick = () => {
    // TODO(sabrina): Add logic to remove lesson from playlist
  };
  return (
    <Stack gap={3}>
      <Drawer
        anchor='right'
        open={showAssignmentDrawer}
        onClose={() => setShowAssignmentDrawer(false)}
        PaperProps={{
          sx: {
            width: '600px',
          },
        }}
        // This is a workaround to remove the backdrop from the drawer while
        // still allowing the drawer to close when the user clicks outside of it
        ModalProps={{
          slotProps: {
            backdrop: { sx: { background: 'none' } },
          },
        }}
      >
        <LessonAssignmentListPlaylist closeDrawer={() => setShowAssignmentDrawer(false)} />
      </Drawer>
      <form data-testid='playlist-form' onSubmit={handleSubmit} style={{ maxWidth: '640px' }}>
        <TextField
          fullWidth
          margin='normal'
          key='name'
          required
          data-testid='playlist-name'
          name='name'
          label='Name'
          type='text'
          value={formPlaylist.name}
          onChange={handleInputChange}
          variant='outlined'
        />

        <TextField
          fullWidth
          margin='normal'
          key='description'
          required
          data-testid='playlist-description'
          name='description'
          label='Description'
          type='text'
          value={formPlaylist.description}
          onChange={handleInputChange}
          variant='outlined'
          multiline
          rows={8}
        />
        <CardActions>
          <Button aria-label='save playlist' type='submit' data-testid='submit-button'>
            Save
          </Button>
        </CardActions>
      </form>
      {playlistEdit && (
        <>
          <Box sx={styles.wrapper}>
            <Typography variant='titleMedium'>Lessons</Typography>
            {playlistEdit?.playlist_items?.map(item => (
              <MarketplaceLessonCard
                key={item.lesson_collection.id}
                lessonCollection={item.lesson_collection}
                showChannel={false}
                customMenuItems={<MenuItem onClick={handleRemoveMenuItemClick}>Remove</MenuItem>}
              />
            ))}
          </Box>
          <Button
            onClick={togglePanel}
            variant='outlined'
            startIcon={<PostAddOutlined />}
            sx={{ width: 'auto', alignSelf: 'flex-start' }}
          >
            Add lessons
          </Button>
        </>
      )}
    </Stack>
  );
};
