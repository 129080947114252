import React, { useEffect, useRef } from 'react';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  GlobalStyles,
  IconButton,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ArrowBack, MoreVert } from '@mui/icons-material';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { AppBar } from 'components/AppBar/AppBar';
import { NoResources } from 'components/NoResources';
import { KyronMenu } from 'components/KyronMenu';
import { useLessonQuery, useLessonSectionsQuery, useLessonDetailsQuery } from 'controllers/react-query';
import { BackgroundTask } from 'controllers/types';
import { HelpButton } from 'components/HelpButton/HelpButton';
import { SectionListSkeleton, SegmentDetailSkeleton } from './EditorSkeleton';
import { EditorContext, useInitEditorContext } from './EditorContext';
import { generateSafeBGTaskErrorText } from '../../BackgroundTasks/useProgressBarChannel';
import { ErrorIndicator } from '../../ErrorIndicator';
import { LessonNameField } from '../components/LessonNameField';
import { PublishButton } from '../Publish/PublishButton';
import { LessonStatusTags } from './LessonStatusTags';
import { SectionList } from './SectionList';
import { useLessonBTWatcher } from '../../BackgroundTasks/backgroundTaskWatchers';
import { KyronEvents } from '../../utils/KyronEvents';
import { DismissBackgroundTaskButton } from '../../BackgroundTasks/DismissBackgroundTaskButton';
import { DevTools } from './DevTools';
import { useRedirectToLatestDraft } from '../../../controllers/react-query/lessonHooks';

// export for testing
export function useLessonAndSectionsQuery(lessonId: number) {
  const { error: lessonError, isFetching: isLoadingLesson, data: lesson } = useLessonQuery(lessonId);
  const {
    error: sectionError,
    isFetching: isLoadingSections,
    data: lessonSectionsData,
  } = useLessonSectionsQuery(lessonId);
  const {
    error: segmentsError,
    isFetching: isLoadingSegments,
    data: lessonDetailsData,
  } = useLessonDetailsQuery(lessonId);
  const { lesson_sections: lessonSections = [] } = lessonSectionsData || {};
  const { lesson_segment_background_tasks: lessonSegmentBackgroundTasks } = lessonDetailsData || {};
  const isLoading = isLoadingLesson || isLoadingSections || isLoadingSegments;
  const errors: Error[] = [lessonError, sectionError, segmentsError].filter((e): e is Error => !!e);

  return { isLoading, errors, lesson, lessonSections, lessonSegmentBackgroundTasks };
}

export function Editor() {
  useRedirectToLatestDraft();
  const isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));
  const { lessonId } = useParams<{ lessonId: string }>();
  const location = useLocation();
  const { lesson, lessonSections, errors: sectionErrors } = useLessonAndSectionsQuery(Number(lessonId));
  const editorContext = useInitEditorContext();
  const userMessageRef = useRef<HTMLDivElement>(null);

  const { active, failed, ...btStatus } = useLessonBTWatcher(lessonId);
  const isAnyActionBlockingTaskInProgress =
    btStatus.isLessonPlanningInProgress ||
    btStatus.isLessonPublishingInProgress ||
    btStatus.isVideoGenerationInProgress ||
    btStatus.isVideoRegenerationInProgress;

  const isCreatingLesson = lessonSections.length === 0 && btStatus.isLessonPlanningInProgress;

  const publishError = btStatus.isLessonPublishingFailed
    ? new Error(generateSafeBGTaskErrorText('publishing', failed.lessonPublishing[0]))
    : null;

  const createError = btStatus.isLessonPlanningFailed
    ? new Error(generateSafeBGTaskErrorText('course creation', failed.lessonPlanning[0]))
    : null;

  const videoGenerateError = btStatus.isVideoGenerationFailed
    ? new Error(generateSafeBGTaskErrorText('video generation', failed.videoGeneration[0]))
    : null;

  const videoRegenerateError = btStatus.isVideoRegenerationFailed
    ? new Error(generateSafeBGTaskErrorText('video generation', failed.videoRegeneration[0]))
    : null;

  const translateError = btStatus.isLessonTranslationFailed
    ? new Error(generateSafeBGTaskErrorText('translation', failed.lessonTranslation[0]))
    : null;

  const menuActions = (
    <>
      <Button component={Link} to='analytics' variant='outlined'>
        Analytics
      </Button>

      <Button
        component={Link}
        to='settings'
        state={{ previousLocation: location }}
        variant='outlined'
        disabled={isAnyActionBlockingTaskInProgress}
      >
        Settings
      </Button>

      <Button
        variant='outlined'
        disabled={isAnyActionBlockingTaskInProgress}
        component={Link}
        target='_blank'
        to={`/studio/courses/${lessonId}/preview`}
        onClick={() => {
          if (!lessonId) return;
          KyronEvents.sendEvent(KyronEvents.names.PREVIEW_CREATED_COURSE, { lesson_id: lessonId });
        }}
      >
        Preview
      </Button>

      <PublishButton lesson={lesson} disabled={isAnyActionBlockingTaskInProgress} />
      <ErrorIndicator error={createError || videoGenerateError} />
      <ErrorIndicator error={publishError} description='Unable to publish course. Please try again.' />
    </>
  );

  const lessonNameField = lesson ? <LessonNameField lesson={lesson} /> : 'Loading...';

  const taskLoadingMessage = (message: string | undefined) =>
    message ? (
      <Stack direction='row' alignItems='center' gap={1}>
        <CircularProgress size={16} />
        {message}
      </Stack>
    ) : null;

  const taskErrorMessage = (err: Error | null, message?: string, backgroundTask?: BackgroundTask) =>
    err ? (
      <Stack direction='row' alignItems='center' gap={1}>
        <ErrorIndicator error={err} />
        {message}
        {backgroundTask ? <DismissBackgroundTaskButton backgroundTask={backgroundTask} /> : null}
      </Stack>
    ) : null;

  const shouldShowUserMessage = btStatus.isAnyFailed || btStatus.isAnyInProgress;

  const alertBox = (
    <Alert severity='info' data-testid='pending-task-info' ref={userMessageRef}>
      <AlertTitle>Processes in motion:</AlertTitle>
      {/* Progress messages */}
      {btStatus.isLessonPlanningInProgress && taskLoadingMessage(active.lessonPlanning?.[0]?.message)}
      {btStatus.isVideoGenerationInProgress && taskLoadingMessage(active.videoGeneration?.[0]?.message)}
      {btStatus.isLessonPublishingInProgress && taskLoadingMessage('Publishing is in progress.')}
      {btStatus.isLessonTranslationInProgress && taskLoadingMessage('Translation is in progress.')}
      {btStatus.isVideoRegenerationInProgress && taskLoadingMessage('Lesson videos are being generated')}

      {/* Errors */}
      {taskErrorMessage(createError, 'There was an error creating your course.', failed.lessonPlanning[0])}
      {taskErrorMessage(publishError, 'There was an error publishing your course.', failed.lessonPublishing[0])}
      {taskErrorMessage(translateError, 'There was an error translating your course.', failed.lessonTranslation[0])}
      {taskErrorMessage(videoGenerateError, 'There was an error generating your video.', failed.videoGeneration[0])}
      {taskErrorMessage(videoRegenerateError, 'There was an error generating videos', failed.videoRegeneration[0])}
    </Alert>
  );

  // This effect will scroll the user message into view when it is shown
  useEffect(scrollUserMessageInView, [shouldShowUserMessage]);
  function scrollUserMessageInView() {
    if (shouldShowUserMessage && userMessageRef.current) {
      // optional chaining with ?. is NOT for here, they already have a check for current and scrollIntoView
      // is on the prototype of the current object. However, it is impossible to mock this logic here and for
      // testing purposes, I need to use optional chaining. If you remove them, you will realize that the tests
      // will fail.
      userMessageRef.current?.scrollIntoView?.({ behavior: 'smooth', inline: 'start', block: 'center' });
    }
  }

  if (!lessonId) return <NoResources message='No course selected' />;

  return (
    <EditorContext.Provider value={editorContext}>
      <GlobalStyles styles={theme => ({ body: { backgroundColor: theme.palette.background.paper } })} />
      <AppBar
        showLogo={false}
        title={isMobileView && lessonNameField}
        navButton={
          <>
            <IconButton to='/studio/courses' component={Link}>
              <ArrowBack />
            </IconButton>
            <DevTools />
          </>
        }
        actions={
          isMobileView ? (
            <KyronMenu menuIcon={<MoreVert />}>
              <Stack m={1} gap={1}>
                {menuActions}
              </Stack>
            </KyronMenu>
          ) : (
            menuActions
          )
        }
        mainToolBarSx={{ bgcolor: 'background.paper' }}
      />

      <Box px={2}>
        {shouldShowUserMessage && alertBox}

        {sectionErrors.length > 0 ? (
          <Stack gap={1} ml={2} mr={2}>
            {sectionErrors.map(error => (
              <Alert key={error.message} severity='error'>
                {error.message}
              </Alert>
            ))}
          </Stack>
        ) : null}
        <Stack sx={{ maxWidth: '1380px', m: '0 auto', px: 6, py: 8, gap: 1 }}>
          <LessonStatusTags lesson={lesson} />
          <Box>
            <Typography variant='displayMedium'>{lesson?.name}</Typography>
          </Box>
          <Box height={64} />
          <Stack direction={{ xs: 'column', sm: 'row' }} gap={8}>
            <Stack flex='0 0 280px' gap={4} position={{ xs: 'static', sm: 'sticky' }} top={64} height='100%'>
              {isCreatingLesson ? <SectionListSkeleton /> : <SectionList lessonSections={lessonSections} />}
            </Stack>
            {isCreatingLesson ? <SegmentDetailSkeleton /> : <Outlet />}
          </Stack>
        </Stack>
      </Box>
      <HelpButton />
    </EditorContext.Provider>
  );
}
