import { SxProps, Box, Button, ThemeProvider, Stack } from '@mui/material';
import { AppBar } from 'components/AppBar/AppBar';
import { getTheme } from 'components/theme/kyronTheme';
import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { NavItemButton } from 'components/NavItemButton/NavItemButton';
import { CorporateFare, ManageAccounts, Summarize, SummarizeOutlined } from '@mui/icons-material';
import KyronLogo from '../../../assets/kyron_logo.svg';

const NAV_WIDTH = 200;

const styles: Record<string, SxProps> = {
  nav: {
    backgroundColor: 'background.paper',
    bottom: 0,
    left: 0,
    position: 'fixed',
    top: 0,
    width: NAV_WIDTH,
  },
};

export function AdminLayout() {
  return (
    <Box sx={{ paddingLeft: `${NAV_WIDTH}px` }}>
      <AppBar
        showLogo={false}
        sx={{ left: NAV_WIDTH }}
        actions={
          <Button to='/' component={Link} variant='outlined'>
            Back home
          </Button>
        }
      />

      <ThemeProvider theme={getTheme('dark')}>
        <Stack gap={5} padding='22px 8px' sx={styles.nav}>
          <Link to='/' style={{ display: 'flex' }} data-testid='app-bar-logo'>
            <img alt='Kyron Learning' src={KyronLogo} height='24' />
          </Link>

          <Stack spacing={1} component='nav' sx={{ typography: 'labelMedium', color: 'text.secondary' }}>
            <NavItemButton to='/admin/users' label='Users' icon={<ManageAccounts fontSize='small' />} dense />
            <NavItemButton
              to='/admin/organizations'
              label='Organizations'
              icon={<CorporateFare fontSize='small' />}
              dense
            />
            <NavItemButton
              to='/admin/reports'
              label='Lesson Instances Report'
              icon={<Summarize fontSize='small' />}
              dense
            />
            <NavItemButton
              to='/admin/training'
              label='Training Logs Report'
              icon={<SummarizeOutlined fontSize='small' />}
              dense
            />
          </Stack>
        </Stack>
      </ThemeProvider>

      <Box sx={{ px: 3, py: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
}
