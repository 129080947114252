import React, { useState } from 'react';
import { Collapse, Button, Pagination } from '@mui/material';
import { useSnackbar } from 'notistack';
import AddIcon from '@mui/icons-material/Add';
import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { createTutor } from 'controllers/tutors';
import { useTutorsQuery } from 'controllers/react-query';
import { NoResources } from 'components/NoResources';
import { SearchField } from 'components/SearchField';
import { TutorCard } from './TutorCard';
import { TutorForm } from './TutorForm';

export function Tutor() {
  useTitle('Tutors');
  const action = (
    <Button aria-label='new tutor' variant='contained' onClick={handleNewTutorExpandClick} icon={AddIcon}>
      New Tutor
    </Button>
  );
  useActions(action);

  const [page, setPage] = useState(1);
  const { data, isFetching, isError, refetch } = useTutorsQuery({ page, perPage: 25 });
  const { tutors, meta } = data || {};
  const noTutorsToShow = !tutors?.length && !isFetching && !isError;
  const totalPages = meta?.total_pages;

  const { enqueueSnackbar } = useSnackbar();
  const [newTutorExpanded, setNewTutorExpanded] = useState(false);
  const [newAdded, setNewAdded] = useState(false);

  function handleNewTutorExpandClick() {
    setNewTutorExpanded(!newTutorExpanded);
  }

  const handleNewTutorFormSubmit = tutor => {
    createTutor(tutor)
      .then(response => {
        setNewAdded(true);
        handleNewTutorExpandClick();
        refetch();
        return response;
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
        handleNewTutorExpandClick();
      });
  };

  return (
    <>
      <SearchField
        placeholder='Search for tutors'
        sx={{ maxWidth: 400 }}
        disabled // TODO: Enable search
        searchTerm=''
        onSearch={() => null}
        isLoading={isFetching}
        searchError={isError ? 'Failed when searching for tutors' : null}
      />
      <Collapse in={newTutorExpanded} timeout='auto' mountOnEnter>
        <TutorForm handleFormSubmit={handleNewTutorFormSubmit} handleExpand={handleNewTutorExpandClick} />
      </Collapse>
      {noTutorsToShow && <NoResources message='Start by adding a new tutor.' action={action} />}
      {!noTutorsToShow && (
        <TutorCard tutors={tutors} newAdded={newAdded} setNewAdded={setNewAdded} refetchTutors={refetch} />
      )}
      {isFetching || noTutorsToShow ? null : (
        <Pagination
          sx={{ alignSelf: 'center', bottom: 0 }}
          count={totalPages}
          shape='rounded'
          page={page}
          onChange={(_, newPage) => setPage(newPage)}
        />
      )}
    </>
  );
}
