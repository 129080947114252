import { KyronClient } from 'components/utils/KyronClient';
import { MutationFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { keepPreviousData, useKyronInfiniteQuery, useKyronQuery } from './kyronQuery';
import { useKyronMutation } from './kyronMutation';
import {
  Organization,
  Pagination,
  Invitation,
  OrganizationPayload,
  OrganizationOverallAnalyticsType,
  OrganizationLessonAnalyticsType,
  QueryPaginationProps,
} from '../types';
import { API_V1 } from './constants';

type Args = {
  enabled?: boolean;
  page?: number;
  perPage?: number;
};

export const useGetOrganizations = (args?: Args) => {
  const { enabled = true, page = 1, perPage = 50 } = args || {};
  const { data, ...rest } = useKyronQuery<{
    organizations: Organization[];
    meta: Pagination;
  }>(`/organizations?page=${page}&per_page=${perPage}`, {
    queryKey: ['/organizations', page, perPage],
    placeholderData: keepPreviousData,
    enabled,
  });

  return { data, ...rest };
};

export const useGetOrganizationsInfinite = ({
  perPage = 10,
  searchText,
}: { searchText?: string } & QueryPaginationProps) => {
  type TResponse = { organizations: Organization[]; meta: Pagination };
  const searchQueryStr = searchText ? `&q=${searchText}` : '';

  return useKyronInfiniteQuery<TResponse>(`/organizations?per_page=${perPage}${searchQueryStr}`, {
    queryKey: ['/organizations', perPage, searchText],
    placeholderData: keepPreviousData,
  });
};

export const useUpdateOrganization = (organizationId?: number) =>
  useKyronMutation<OrganizationPayload, Organization>(`/organizations/${organizationId}`, {
    method: 'PATCH',
    invalidatesMultiple: [['/organizations'], ['/users/current']],
  });

const client = new KyronClient();
/**
 * Sets the active org of the current user
 */
export const useSetActiveOrg = () => {
  const queryClient = useQueryClient();
  const mutationFn: MutationFunction<unknown, number> = async (orgID: number) =>
    client.submitJsonDataWithError(`${API_V1}/organizations/${orgID}/set_active_organization`, 'PUT', new FormData());

  return useMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(); // invalidate all cache on active organization change
    },
    onError: () => {
      enqueueSnackbar('Active organization could not be changed', { variant: 'error' });
    },
  });
};

export const useRemoveInvitationFromOrganization = (invitation: Invitation, organization?: Organization) =>
  useKyronMutation(`/organizations/${organization?.id}/invitations/${invitation.id}`, {
    method: 'DELETE',
    invalidates: [`/organizations/${organization?.id}/invitations`],
  });

export const useGetOrganizationInvitations = (
  organization?: Organization,
  { page = 1, perPage = 25, search = '' } = {},
) => {
  const searchQueryStr = search ? `&q=${search}` : '';
  return useKyronQuery<{ invitations: Invitation[]; meta: Pagination }>(
    `/organizations/${organization?.id}/invitations?page=${page}&per_page=${perPage}${searchQueryStr}`,
    {
      queryKey: [`/organizations/${organization?.id}/invitations`, { page, perPage, search }],
      placeholderData: keepPreviousData,
    },
  );
};

export const useOrganizationOverallAnalytics = (
  organizationId?: number,
  startDate?: string,
  endDate?: string,
  lessonId?: number | null,
) => {
  const query = new URLSearchParams();
  if (startDate) query.set('start_date', startDate);
  if (endDate) query.set('end_date', endDate);
  if (lessonId) query.set('lesson_id', lessonId.toString());

  return useKyronQuery<OrganizationOverallAnalyticsType | null>(
    `/organizations//${organizationId}/overall_analytics?${query.toString()}`,
    {
      enabled: !!organizationId,
    },
  );
};

export const useOrganizationLessonAnalytics = (
  organizationId?: number,
  startDate?: string,
  endDate?: string,
  lessonId?: number | null,
) => {
  const query = new URLSearchParams();
  if (startDate) query.set('start_date', startDate);
  if (endDate) query.set('end_date', endDate);
  if (lessonId) query.set('lesson_id', lessonId.toString());
  return useKyronQuery<OrganizationLessonAnalyticsType | null>(
    `/organizations/${organizationId}/lesson_analytics?${query.toString()}`,
    { enabled: !!organizationId },
  );
};
