import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type CustomProps = { lineCount?: number };

/**
 * Typography component that truncates text with an ellipsis after a certain number of lines.
 * @param lineCount
 */

export const TypographyWithEllipsis = styled(Typography, { shouldForwardProp: p => p !== 'lineCount' })<CustomProps>`
  overflow: hidden;
  -webkit-line-clamp: ${p => String(p.lineCount || 1)};
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;
