import { Classroom, User } from '../types';
import { useKyronQuery } from './kyronQuery';
import { useKyronMutation } from './kyronMutation';

export const useClassroomQuery = (classroomId?: string) =>
  useKyronQuery<Classroom>(`/teacher_dashboards/course?classroom_id=${classroomId}`);

export const useClassrooms = ({ enabled }: { enabled?: boolean } = {}) =>
  useKyronQuery<Classroom[]>('/teacher_dashboards/courses', { enabled });

export const useTeachersQuery = (classroomId?: string) =>
  useKyronQuery<User[]>(`/teacher_dashboards/teachers?classroom_id=${classroomId}`);

export const useGoogleClassrooms = () => useKyronQuery<Classroom[]>('/teacher_dashboards/google_classroom_courses');

export const useCreateGoogleClassrooms = () =>
  useKyronMutation<{ course_ids: number[] }>(`/teacher_dashboards/google_classrooms`, {
    invalidatesMultiple: [['/teacher_dashboards/courses'], ['/teacher_dashboards/google_classroom_courses']],
  });

export const useCreateClassroom = () =>
  useKyronMutation<{ name: string }>('/teacher_dashboards/create_classroom', {
    invalidates: [`/teacher_dashboards/courses`],
  });

export const useDeleteClassroom = () =>
  useKyronMutation<{ classroom_id?: string }>('/teacher_dashboards/delete_classroom', {
    method: 'DELETE',
    invalidatesMultiple: [['/teacher_dashboards/courses'], ['/teacher_dashboards/google_classroom_courses']],
  });
