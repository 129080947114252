import { ArrowBack } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useNavigateBack } from 'components/utils/routeUtils';
import React from 'react';

type BackButtonProps = {
  /**
   * If the user is not able to navigate back, this will be the fallback route.
   * @example '/studio/library'
   */
  fallback?: string;
};

/**
 * A component that renders an arrow back icon button that navigates back in the
 * history stack with an optioanl fallback route that defaults to the root route.
 */
export function BackButton({ fallback = '/' }: BackButtonProps) {
  const navigateBack = useNavigateBack();

  return (
    <IconButton aria-label='Navigate back' onClick={() => navigateBack({ fallback })}>
      <ArrowBack data-testid='arrow-back' />
    </IconButton>
  );
}
