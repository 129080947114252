import PropTypes from 'prop-types';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Chip,
  Box,
  Switch,
  Stack,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { PromptSelection } from './PromptSelection';
import { SimpleEquationBuilder } from '../Equation';
import { handleJsonChange } from './handleJsonChange';

const styles = {
  LessonSegmentAcceptableInputs: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },
};

const FormEquationStyleControl = styled('div')`
  && {
    margin: 16px 0 18px 0;
  }
`;

export const WaitSegmentForm = props => {
  const { state, handleInputChange } = props;
  const isQuestionJson = state.question_json && !isEmpty(state.question_json);
  const [isEquationStyleQuestion, setIsEquationStyleQuestion] = useState(isQuestionJson || false);

  return (
    <>
      <FormEquationStyleControl>
        <Stack direction='row' marginLeft='10px' spacing={1} alignItems='center'>
          <Typography variant='body2'>Equation style question</Typography>
          <Switch
            color='primary'
            checked={isEquationStyleQuestion}
            onChange={event => setIsEquationStyleQuestion(event.target.checked)}
          />
        </Stack>
        {isEquationStyleQuestion ? (
          <Box marginTop='16px'>
            <SimpleEquationBuilder
              label='Question'
              size='small'
              data={state.question_json}
              onChanges={handleJsonChange(state, 'question_json')}
            />
          </Box>
        ) : (
          <TextField
            data-testid='question-input'
            name='question'
            label='Question'
            fullWidth
            margin='normal'
            type='text'
            onChange={handleInputChange}
            helperText='Enter a question'
            value={state.question}
          />
        )}
      </FormEquationStyleControl>
      <FormControl>
        <InputLabel>{state.edgeRows.length > 1 ? 'Acceptable Inputs (Read only)' : 'Acceptable Inputs'}</InputLabel>
        <Select
          data-testid='acceptable-input-select'
          multiple
          input={
            <OutlinedInput label={state.edgeRows.length > 1 ? 'Acceptable Inputs (Read only)' : 'Acceptable Inputs'} />
          }
          renderValue={selected => (
            <Box sx={styles.LessonSegmentAcceptableInputs}>
              {selected.map(value => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          name='acceptable_inputs'
          value={state.acceptable_inputs}
          onChange={handleInputChange}
          inputProps={state.edgeRows.length > 1 ? { readOnly: true } : {}}
        >
          {state.allAcceptableInputs.map(input => (
            <MenuItem key={input} value={input}>
              <Checkbox checked={state.acceptable_inputs.indexOf(input) > -1} />
              <ListItemText primary={input} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {state.acceptable_inputs.includes('open_ended') && (
        <PromptSelection handleInputChange={handleInputChange} promptSelection={state.prompt_selection} />
      )}
      {state.acceptable_inputs?.includes('equation') ? (
        <FormEquationStyleControl>
          <SimpleEquationBuilder
            label='Equation Display Input'
            size='small'
            data={state.equation_display_input}
            onChanges={handleJsonChange(state, 'equation_display_input')}
          />
        </FormEquationStyleControl>
      ) : null}
    </>
  );
};

WaitSegmentForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object,
  handleInputChange: PropTypes.func,
};
