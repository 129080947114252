import { Button, styled } from '@mui/material';

export const SpecialButton = styled(Button)({
  background: 'linear-gradient(to bottom right, #4B35FF, #A911DF, #FF1F00)',
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  '&.Mui-disabled': {
    background: 'linear-gradient(to bottom right, #4B35FF, #A911DF, #FF1F00)',
    boxShadow: 'none',
    color: '#FFFFFF99',
  },
}) as typeof Button;
