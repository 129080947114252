import { Box, GlobalStyles, Stack, Typography, useTheme } from '@mui/material';
import { MarketingHeader } from 'components/MarketingHome/components/MarketingHeader';
import React from 'react';
import rudy from 'assets/rudy.jpg';
import enis from 'assets/enis.png';
import rajen from 'assets/rajen.jpg';
import mike from 'assets/mike.jpg';
import array from 'assets/array.png';
import cambiar from 'assets/cambiar.svg';
import charter from 'assets/charter.png';
import commonsense from 'assets/commonsense.png';
import ecmc from 'assets/ecmc.png';
import gsv from 'assets/gsv.svg';
import imaginglearning from 'assets/imaginelearning.png';
import learnerstudio from 'assets/learnerstudio.png';
import owl from 'assets/owl.png';
import { Link } from 'react-router-dom';
import { Footer } from 'components/MarketingHome/components/Footer';
import { LeadershipBlock, LeadershipBlockProps } from './LeadershipBlock';

export function AboutKyron() {
  const theme = useTheme();

  const leaders: LeadershipBlockProps[] = [
    {
      name: 'Rajen Sheth',
      title: 'Chief Executive Officer & Co-Founder',
      bio: 'Rajen left Google after a 17-year career because he was inspired by the power that one teacher (6th grade teacher Mr. Robert Thomasson in his case!) has to change a student’s learning journey and life. As the former VP of Google Cloud AI and Industry Solutions and founder of Google Apps for Work and Education (now Google Workspace), Rajen has built products that have transformed work and learning for millions of people. He is on a mission to take his experiences and knowledge to help educators leverage the best technological advances to benefit all students.',
      image: rajen,
    },
    {
      name: 'Enis Konuk',
      title: 'Chief Technology Officer & Co-Founder',
      bio: 'Enis has devoted four decades to building leading edge technologies, most recently as founder of Qwiklabs. Qwiklabs is a hands-on software learning platform used by lab creators, trainers, and students and acquired by Google in 2016.',
      image: enis,
    },
    {
      name: 'Rudy Valdez',
      title: 'Chief Operating Officer',
      bio: "Rudy is an executive with over three decades of experience building technology businesses. He spent 16 years helping build Amazon's cloud computing business from the ground up, and has also worked at NASA/JPL, IBM, Xerox, and Docebo. Rudy is passionate about the cloud, SaaS, and learning platform spaces, and is thrilled to be part of Kyron Learning.",
      image: rudy,
    },
    {
      name: 'Mike Doust',
      title: 'Chief Customer Officer',
      bio: 'Mike is an execution-oriented executive with experience leading all aspects of Go-To-Market (GTM) in early stage and high-growth organizations. He most recently spent 15+ years with Google where he helped build and scale GTM teams for Google Workspace, Google Maps, Android & Chrome solutions. Mike is passionate about bringing equitable access to great educators and ensuring customers and partners have a positive experience working with the Kyron Platform and team.',
      image: mike,
    },
  ];

  const investors = [
    {
      name: 'Array Education',
      path: 'https://www.arrayeducation.org/',
      image: array,
    },
    {
      name: 'Cambiar Education',
      path: 'https://www.cambiareducation.org/',
      image: cambiar,
    },
    {
      name: 'Charter School Growth Fund',
      path: 'https://chartergrowthfund.org/',
      image: charter,
    },
    {
      name: 'Common Sense Education',
      path: 'https://www.commonsense.org/education',
      image: commonsense,
    },
    {
      name: 'ECMC Foundation',
      path: 'https://www.ecmcfoundation.org/',
      image: ecmc,
    },
    {
      name: 'GSV Ventures',
      path: 'https://gsv.ventures/',
      image: gsv,
    },
    {
      name: 'Imagine Learning',
      path: 'https://www.imaginelearning.com/',
      image: imaginglearning,
    },
    {
      name: 'LearnerStudio',
      path: 'https://thelearnerstudio.org/',
      image: learnerstudio,
    },
    {
      name: 'Owl Ventures',
      path: 'https://owlvc.com/',
      image: owl,
    },
  ];

  return (
    <>
      <GlobalStyles styles={{ body: { background: theme.palette.surface.main } }} />
      <MarketingHeader />
      <Stack sx={{ padding: '64px 0' }} gap={8}>
        <Typography variant='displayLarge' textAlign='center'>
          About Kyron
        </Typography>
        <Stack
          gap={3}
          sx={{
            maxWidth: '800px',
            margin: '0 auto',
            borderBottom: '6px solid purple',
            borderBottomColor: theme.palette.primary.main,
            paddingBottom: 8,
          }}
        >
          <Typography variant='headlineSmall' fontWeight='normal'>
            Kyron Learning is creating equitable access to great education. We help anyone create engaging and
            pedagogically-sound courses in a fraction of the time and cost.
          </Typography>
          <Typography variant='bodyLarge'>
            Kyron Learning helps you create and deliver high-quality interactive courses on any subject in a fraction of
            the cost and time, utilizing AI. These discussion-based courses – including videos, visuals, and voice
            interactivity – are pedagogically sound, provide unique education based on individual needs and are
            available 24/7. From office hours and test prep to full interactive course development, Kyron empowers
            anyone with something to teach or with something to learn.
          </Typography>
        </Stack>
        <Stack
          gap={8}
          sx={{
            maxWidth: '960px',
            margin: '0 auto',
            borderBottom: '6px solid purple',
            borderBottomColor: theme.palette.primary.main,
            paddingBottom: 8,
          }}
        >
          <Typography variant='displaySmall' textAlign='center'>
            Leadership
          </Typography>
          {leaders.map(leader => (
            <LeadershipBlock key={leader.name} {...leader} />
          ))}
        </Stack>
        <Stack gap={8} sx={{ maxWidth: '960px', margin: '0 auto', paddingBottom: 8 }}>
          <Typography variant='displaySmall' textAlign='center'>
            Investors
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '56px', justifyContent: 'center', alignItems: 'center' }}>
            {investors.map(investor => (
              <Link key={investor.name} to={investor.path} target='_blank'>
                <img src={investor.image} alt={investor.name} width='120' />
              </Link>
            ))}
          </Box>
        </Stack>
      </Stack>
      <Footer />
    </>
  );
}
