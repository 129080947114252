import { EnqueueSnackbar, useSnackbar } from 'notistack';
import { useKyronQuery } from './kyronQuery';
import { useUserContext } from '../../components/UserContext';

export const enum StudentEnrollmentStatus {
  Enrolled = 'enrolled',
  NotEnrolled = 'not enrolled',
  Unauthorized = 'unauthorized',
}

export type ClassroomCredentials = {
  status: StudentEnrollmentStatus | null;
  classroomId: string | null;
};

enum Provider {
  KyronLearning = 'kyron_learning',
}

export type ClassroomCredentialsResult = {
  googleClassroomData: ClassroomCredentials;
  isFetchingGoogleClassroomData: boolean;
  isGoogleClassStudentAuthenticated: boolean | null;
  isKyronClassStudentAuthenticated: boolean | null;
  isStudentEnrolled: boolean;
  isKyronClassroom: boolean;
  googleClassroomId: string | null;
  provider: Provider | null;
};

/**
 * When rendered in a component, this hook will do few things depending on the URL params in an assignment link:
 * For Google Classroom Assignment URL:
 * - If it determines that the assignment was for a Google Classroom (presence of google_classroom_id param), it will
 * try to validate user's Google authentication. If user is not logged in, result will be updated accordingly for parent
 * component to act on it. If user is logged it, it will return student's classroom enrollment status and various helper
 * states for parent component to use when building business logic. (See, result object)
 *
 * For Kyron Classroom Assignment URL:
 * - If the assignment was for a Kyron Classroom -which is determined by looking at the provide=kyron_learning param-,
 * then, it won't do any Google Classroom validation. Instead, it will check if the user is logged in. If not, it will
 * redirect the user to common login page. If user is logged in, it will return student's classroom enrollment status
 * and various helper states for parent component to use when building business logic. (See, result object)
 *
 * @returns result object with various states and data to be used in the parent component
 * ```
 * result = {
 *  // --- Google Classroom specific states
 *  googleClassroomData: { status: StudentEnrollmentStatus | null, classroomId: string | null },
 *  isFetchingGoogleClassroomData: boolean,
 *  isGoogleClassStudentUnauthorized: boolean | null, (null if it is a Kyron Classroom)
 *
 *  // --- Kyron Classroom specific states
 *  isKyronClassStudentUnauthorized: boolean | null, (null if it is a Google Classroom)
 *
 *  // --- Common states
 *  isStudentEnrolled: boolean,
 *  isStudentNotEnrolled: boolean,
 *  isKyronClassroom: boolean,
 * }
 * ```
 */
export const useClassroomCredentials = () => {
  const { enqueueSnackbar } = useSnackbar();
  const urlParams = new URLSearchParams(window.location.search);
  const classroomId = urlParams.get('classroom_id');
  const googleClassroomId = urlParams.get('google_classroom_id');
  const provider = urlParams.get('provider') as Provider | null;
  const path = encodeURIComponent(window.location.pathname + window.location.search);
  const isKyronClassroom = provider === Provider.KyronLearning;
  const isGoogleClassroom = !!googleClassroomId;

  const { user } = useUserContext();

  const {
    data: googleClassroomData,
    error,
    isFetching: isFetchingGoogleClassroomData,
    isError,
  } = useKyronQuery<ClassroomCredentials>(
    `/students/check_credentials?classroom_id=${classroomId}&google_classroom_id=${googleClassroomId}`,
    {
      // skip if user is logged in already
      enabled: !!googleClassroomId && !!classroomId && !isKyronClassroom,
    },
  );

  // construct the result object that'll  be returned
  const result: ClassroomCredentialsResult = {
    googleClassroomData: constructGoogleClassroomData(isKyronClassroom, googleClassroomData, classroomId),
    isKyronClassroom,
    isFetchingGoogleClassroomData,
    isGoogleClassStudentAuthenticated: null,
    isKyronClassStudentAuthenticated: null,
    isStudentEnrolled: false,
    googleClassroomId,
    provider,
  };

  if (!classroomId) return result;

  if (isError) {
    handleError(error, enqueueSnackbar);
    return { ...result, isGoogleClassStudentAuthenticated: false };
  }

  if (isGoogleClassroom) {
    return {
      ...result,
      isGoogleClassStudentAuthenticated: googleClassroomData?.status !== StudentEnrollmentStatus.Unauthorized,
      isStudentEnrolled: googleClassroomData?.status === StudentEnrollmentStatus.Enrolled,
    };
  }

  if (isKyronClassroom) {
    // redirect to the login page for authentication
    if (!user) window.location.href = `/login?redirect_to=${path}`;

    return {
      ...result,
      isKyronClassStudentAuthenticated: !!user,
      isStudentEnrolled: !!user,
    };
  }

  return result;
};

function constructGoogleClassroomData(
  isKyronClassroom: boolean,
  googleClassroomData: ClassroomCredentials | undefined,
  classroomId: ClassroomCredentials['classroomId'],
): ClassroomCredentials {
  return isKyronClassroom
    ? { classroomId, status: null }
    : {
        ...(googleClassroomData || {}),
        classroomId,
        status: googleClassroomData?.status || StudentEnrollmentStatus.Unauthorized,
      };
}

function handleError(error: Error, enqueueSnackbar: EnqueueSnackbar) {
  // This whole hook is to check if the student is unauthorized or not. So, we don't want to error out
  // if the error status is 401, instead we want to redirect the user to login.
  /* TODO(ege):
      Checking a substring is not a good way to handle this. This requires a refactor in KyronClient that I better not
      handle in this PR. I will come back to this later. */
  if (error.toString().includes('401 Unauthorized')) return;

  // For all other errors:
  enqueueSnackbar('An error occurred when fetching classroom credentials.');
  console.error('Classroom credentials fetching error', error);
}
