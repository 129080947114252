export function convertSecondsToMmSs(timeInSeconds: number) {
  // Calculate the number of minutes and seconds from the given number of seconds
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds - minutes * 60;

  // Format the time values as strings and add leading zeros if necessary
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toFixed(0).toString().padStart(2, '0');

  // Concatenate the time values into a single string and return it
  return `${formattedMinutes}:${formattedSeconds}`;
}
