/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ShowCollection } from './ShowCollection';
import { withDeleteModal } from '../utils/DeleteModalHOC';

const styles = {
  Accordion: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '20px',
  },
  collectionCard: {
    width: '90%',
    margin: '10px 0px',
    border: '1px solid #ccc',
  },
};

const ShowCollectionDM = withDeleteModal(ShowCollection, props => {
  props.handleCollectionDelete(props.collection);
});

export const ShowLessons = props => {
  const orderCollections = collections => collections?.sort((a, b) => a.position - b.position);

  return (
    <Accordion style={styles.Accordion}>
      <AccordionSummary aria-controls='lessons-list' id='lessons-header' expandIcon={<ExpandMoreIcon />}>
        <Typography color='text.secondary'>View Lesson Collections</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {orderCollections(props.unit.lesson_collections)?.map(collection => (
          <div key={collection.id} data-testid='collections-list'>
            <ShowCollectionDM
              collection={collection}
              units={props.units}
              lessons={props.lessons}
              handleLessonAdd={props.handleLessonAdd}
              handleLessonUpdate={props.handleLessonUpdate}
              handleLessonRemove={props.handleLessonRemove}
              unit={props.unit}
              handleCollectionDelete={props.handleCollectionDelete}
              handleAddCollection={props.handleAddCollection}
              handleCollectionUpdate={props.handleCollectionUpdate}
              reorderCollection={props.reorderCollection}
              reorderLesson={props.reorderLesson}
            />
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

/* eslint-disable react/forbid-prop-types */

ShowLessons.propTypes = {
  unit: PropTypes.object,
  units: PropTypes.array,
  handleLessonRemove: PropTypes.func,
  handleLessonUpdate: PropTypes.func,
  handleCollectionDelete: PropTypes.func,
  handleAddCollection: PropTypes.func,
  handleCollectionUpdate: PropTypes.func,
  reorderCollection: PropTypes.func,
  reorderLesson: PropTypes.func,
};
