import React from 'react';
import { CircularProgress, SxProps } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Row } from './Row/Row';

type Props = {
  spinnerSize?: number | string;
  message?: string;
  sx?: SxProps;
  loading?: boolean;
  overlay?: boolean;
  fontSize?: number | string;
};

const overlayFullScreenStyles: SxProps = {
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  pointerEvents: 'none',
};

const getStyles = (overlay: Props['overlay'], sx: SxProps = {}): SxProps => ({
  ...(overlay ? overlayFullScreenStyles : {}),
  ...sx,
});

const getText = (message: Props['message'], overlay: Props['overlay'], fontSize: Props['fontSize'] = '1em') => {
  let text = message;
  if (overlay && !message) text = 'Loading...';
  return (
    <Typography variant='inherit' sx={{ fontSize }} component='span'>
      {text}
    </Typography>
  );
};

/**
 * Loading indicator component
 * Depending on the overlay prop, it will show a full screen overlay with a loading indicator
 */
export const LoadingIndicator = ({ spinnerSize, fontSize, message, sx, loading = true, overlay = false }: Props) =>
  !loading ? null : (
    <Row sx={getStyles(overlay, sx)} gap={2} data-testid='loading-indicator'>
      <CircularProgress size={spinnerSize || '1.2em'} aria-label='progress indicator' />
      {getText(message, overlay, fontSize)}
    </Row>
  );
