/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Done as DoneIcon, Cancel as CancelIcon } from '@mui/icons-material';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Modal,
} from '@mui/material';
import { ACCEPT_VIDEO_TYPES } from '../Uploaders/FileUploader';
import { DirectUploader } from '../Uploaders/DirectUploader';
import { KyronClient } from '../utils/KyronClient';
import { TableOfContentsBuilder } from './TableOfContentsBuilder';
import { LessonEditors } from './LessonEditors';
import { sortByPosition } from '../utils/arrayUtils';

const styles = {
  LessonCard: {
    borderRadius: '20px',
    margin: '10px 0px',
    justifyContent: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '28px',
  },
  modalBody: {
    fontWeight: 'bold',
    fontSize: '24px',
    marginBottom: '5px',
    marginTop: '7px',
  },
  modalCloseButton: {
    marginLeft: '91%',
  },
};

export const LessonForm = props => {
  const [lesson, setLesson] = useState({
    id: props.lesson?.id || 0,
    name: props.lesson?.name || '',
    tutor_id: props.lesson?.tutor_id || '',
    description: props.lesson?.description || '',
    miro_url: props.lesson?.miro_url || '',
    locale: props.lesson?.locale || 'en',
    active: props.lesson?.active || false,
    video: null,
    custom_thumbnail: null,
    universal_wait_video: null,
    gcp_project: props.lesson?.gcp_project || '',
    gcp_df_agent: props.lesson?.gcp_df_agent || '',
    table_of_contents: props.lesson?.table_of_contents || [],
    editors: props.lesson?.editors || [],
  });
  const [unmatchedVideos, setUnmatchedVideos] = useState([]);
  const [lessonSegmentsWithoutVideo, setLessonSegmentsWithoutVideo] = useState([]);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);
  const [tableOfContents, setTableOfContents] = useState(
    props.lesson?.table_of_contents?.length > 0
      ? props.lesson.table_of_contents
      : [{ id: 1, position: 1.0, label: '', lesson_segment_id: '', lesson_id: lesson.id }],
  );

  const handleInputChange = e => {
    setLesson(prevLesson => ({
      ...prevLesson,
      [e.target.name]: e.target.value,
    }));
  };

  const updateTOC = newTOC => {
    sortByPosition(newTOC);
    setTableOfContents(newTOC);
    const newLesson = lesson;
    newLesson.table_of_contents = newTOC;
    setLesson(prevLesson => ({ ...prevLesson, ...newLesson }));
  };

  const handleDirectUploadUniversalWait = signedIds => {
    setLesson(prevState => ({
      ...prevState,
      universal_wait_video: signedIds[0],
    }));
  };

  const handleDirectUploadThumbnail = signedIDs => {
    setLesson(prevLesson => ({
      ...prevLesson,
      custom_thumbnail: signedIDs[0],
    }));
  };

  const handleBulkDirectUploadFile = signedIds => {
    const formData = new FormData();
    formData.append('lesson_id', lesson.id);
    signedIds.map(signedId => formData.append('signed_ids', signedId));
    const client = new KyronClient();
    client
      .submitJsonDataWithError('/api/v1/lesson_segment_uploads', 'POST', formData)
      .then(lessonUpload => {
        setIsUploaded(true);
        setLesson(lessonUpload.lesson);
        setUnmatchedVideos(lessonUpload.unmatched_videos);
        setLessonSegmentsWithoutVideo(lessonUpload.lesson_segments_without_video);
        setisModalOpen(true);
      })
      .catch(error => {
        console.error(error.message);
      });
  };

  const closeModal = () => {
    setisModalOpen(false);
  };

  const cleanTOC = () => {
    let newTOC = tableOfContents;
    newTOC = newTOC.filter(toc => toc.label !== '');
    newTOC.forEach(toc => {
      delete toc.created_at;
      delete toc.updated_at;
    });
    updateTOC(newTOC);
  };

  const setEditors = editors => {
    setLesson(prevLesson => ({ ...prevLesson, editors }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.handleExpand();
    cleanTOC();
    props.handleFormSubmit(lesson);
    e.target.reset();
  };

  return (
    <Card sx={styles.LessonCard}>
      <CardHeader title='Add/Update Lesson' />
      <CardContent>
        <form data-testid='lesson-form' onSubmit={handleSubmit}>
          <TextField
            data-testid='lesson-name'
            variant='outlined'
            name='name'
            fullWidth
            margin='normal'
            label='Lesson Name'
            type='text'
            onChange={handleInputChange}
            required
            value={lesson.name}
            helperText='Enter the name of new lesson'
          />

          <TextField
            data-testid='lesson-tutor'
            name='tutor_id'
            select
            label='Tutor'
            onChange={handleInputChange}
            fullWidth
            margin='normal'
            value={lesson.tutor_id}
          >
            {props.allTutors?.map(tutor => {
              if (tutor != null) {
                return (
                  <MenuItem key={tutor.id} value={tutor.id}>
                    {tutor.display_name}
                  </MenuItem>
                );
              }
              return (
                <MenuItem key={tutor.id} value=' '>
                  None
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            data-testid='lesson-description'
            variant='outlined'
            name='description'
            fullWidth
            margin='normal'
            label='Description'
            type='text'
            onChange={handleInputChange}
            required
            value={lesson.description}
            helperText='Enter a description'
          />
          <TextField
            data-testid='lesson-mirourl'
            variant='outlined'
            name='miro_url'
            fullWidth
            margin='normal'
            label='Miro URL'
            type='text'
            onChange={handleInputChange}
            value={lesson.miro_url}
            helperText='Enter the URL for the Miro Board'
          />
          {lesson.gcp_project !== '' && (
            <>
              <TextField
                data-testid='lesson-gcpproject'
                variant='outlined'
                name='gcp_project'
                fullWidth
                margin='normal'
                label='GCP Project'
                type='text'
                onChange={handleInputChange}
                value={lesson.gcp_project}
                helperText='GCP project ID for agents'
                inputProps={{ readOnly: true }}
              />
              <TextField
                data-testid='lesson-dfagent'
                variant='outlined'
                name='gcp_df_agent'
                fullWidth
                margin='normal'
                label='GCP Dialogflow Agent'
                type='text'
                onChange={handleInputChange}
                value={lesson.gcp_df_agent}
                helperText='The Dialogflow Agent ID'
                inputProps={{ readOnly: true }}
              />
            </>
          )}
          <Select
            data-testid='lesson-locale'
            name='locale'
            variant='outlined'
            value={lesson.locale}
            fullWidth
            onChange={handleInputChange}
          >
            <MenuItem value='en'>English</MenuItem>
            <MenuItem value='es'>Spanish</MenuItem>
          </Select>
          <TableOfContentsBuilder
            showTOC={props.showTOC}
            lesson={lesson}
            tableOfContents={tableOfContents}
            setTableOfContents={setTableOfContents}
            segmentNamesIds={props.segmentNamesIds}
            updateTOC={updateTOC}
          />
          <LessonEditors editors={lesson.editors} setEditors={setEditors} />
          <Typography>Upload Video Thumbnail</Typography>
          <DirectUploader afterUpload={handleDirectUploadThumbnail} multiple={false} acceptTypes={'image/*'} />
          <Typography>Upload Universal wait video</Typography>
          <DirectUploader
            afterUpload={handleDirectUploadUniversalWait}
            multiple={false}
            acceptTypes={ACCEPT_VIDEO_TYPES}
          />
          {lesson.id !== 0 && (
            <>
              <Typography>Upload Videos for Segments</Typography>
              <DirectUploader afterUpload={handleBulkDirectUploadFile} multiple acceptTypes={ACCEPT_VIDEO_TYPES} />
            </>
          )}
          {isUploaded && (
            <Modal open={isModalOpen} onClose={closeModal} style={styles.modal}>
              <div style={styles.modalContent}>
                <div style={styles.modalCloseButton}>
                  <IconButton
                    aria-label='cancel'
                    onClick={closeModal}
                    sx={{
                      color: 'blue',
                    }}
                  >
                    <CancelIcon />
                  </IconButton>
                </div>
                <Typography style={styles.modalBody}>Unmatched videos:</Typography>
                {unmatchedVideos.map(unmatchedVideo => (
                  <Typography key={unmatchedVideo.signed_id}>{unmatchedVideo.filename}</Typography>
                ))}
                <Typography style={styles.modalBody}>Lesson Segments without video:</Typography>
                {lessonSegmentsWithoutVideo.map(lessonSegment => (
                  <Typography key={lessonSegment.id}>{lessonSegment.name}</Typography>
                ))}
              </div>
            </Modal>
          )}
          <CardActions>
            <IconButton aria-label='submit lesson' type='submit'>
              <DoneIcon />
            </IconButton>
            <IconButton aria-label='cancel edit lesson' onClick={props.handleExpand}>
              <CancelIcon />
            </IconButton>
          </CardActions>
        </form>
      </CardContent>
    </Card>
  );
};

/* eslint-disable react/forbid-prop-types */
LessonForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleExpand: PropTypes.func.isRequired,
  allTutors: PropTypes.array.isRequired,
  lesson: PropTypes.object,
  showTOC: PropTypes.bool,
  segmentNamesIds: PropTypes.array,
};
