import React, { useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import AutosizeInput from 'react-18-input-autosize';
import { getFontSize } from 'components/Equation/util';
import { BoxComponentProps, Size } from './types';

const Wrapper = styled('span')<{ size?: Size }>`
  font-size: ${props => getFontSize(props.size)};
`;
const EmptyWrapper = styled('div')<{ size?: Size }>(({ theme }) => ({
  border: `0.15rem solid ${theme.palette.fractionBorder.main}`,
  padding: '0.2em 0.40em 0.2em 0.35em',
  input: {
    fontSize: (props: { size?: Size }) => getFontSize(props.size),
    minWidth: '30px',
    maxHeight: '40px',
    border: 'none',
    outline: 'none',
    background: 'transparent',
  },
}));

const wrapWordsIndividually = (value: string, size?: Size) => {
  const words = value.split(' ');
  return words.map(word => (
    // Create a unique key for each word so that react can differentiate between them as a sentence.
    <Wrapper size={size} key={`word-${word}-${crypto.randomUUID()}`}>
      {word}
    </Wrapper>
  ));
};

export const BoxText = ({ value, size, onChange, builderMode }: BoxComponentProps) => {
  const [inputValue, setInputValue] = useState(value);
  const renderedInputValue = useMemo(() => wrapWordsIndividually(value, size), [value, size]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    setInputValue(targetValue);
    onChange(targetValue);
  };

  if (!value || builderMode) {
    return (
      <EmptyWrapper size={size}>
        <AutosizeInput aria-label='Enter text' value={inputValue} onChange={handleChange} />
      </EmptyWrapper>
    );
  }
  return renderedInputValue;
};
