import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import yellowPlane from '../assets/plane-6-color.svg';

type Props = {
  message: string | JSX.Element;
  action?: JSX.Element;
  width?: number | string;
  svgImage?: unknown;
};

export function NoResources({ message, action, width = 200, svgImage }: Props): React.JSX.Element {
  return (
    <Box width='100%' textAlign='center' display='flex' justifyContent='center' sx={{ py: 6 }}>
      <Stack width={width} gap={2} alignItems='center'>
        <Stack
          justifyContent='center'
          alignItems='center'
          sx={{ borderRadius: '50%', width: 144, height: 144, bgcolor: 'surfaceContainer.main' }}
        >
          <img src={svgImage || yellowPlane} width='167' alt='' />
        </Stack>
        <Typography component='span'>{message}</Typography>
        <Box sx={{ mt: 1 }}>{action}</Box>
      </Stack>
    </Box>
  );
}
