import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  FormControl,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { useModal } from 'components/utils/ModalContext';

export const OptionLabel = ({ option, index }: { option: string; index: number }) => {
  const labelText = () => {
    if (index === 0) {
      return 'Original';
    }
    return `Option ${index}`;
  };
  return (
    <Box>
      <Typography variant='labelSmall'>{labelText()}</Typography>
      <Typography
        variant='bodySmall'
        sx={{
          bgcolor: 'surfaceContainer.main',
          borderRadius: 0.5,
          p: 1,
        }}
      >
        {option}
      </Typography>
    </Box>
  );
};

type ChatFeedbackModalContentStep2Props = {
  messageOptions: Array<string>;
  submitFunction: () => void;
  isPending: boolean;
  retryStep1: () => void;
  selectOption: (option: string) => void;
  selectedOption: string | null;
};

export const ChatFeedbackModalContentStep2 = ({
  messageOptions,
  submitFunction,
  isPending,
  retryStep1,
  selectOption,
  selectedOption,
}: ChatFeedbackModalContentStep2Props) => {
  const { closeModal } = useModal();

  return (
    <Box display='flex' flexDirection='column' data-testid='ai-feedback-content-step2'>
      <DialogTitle id='dialog-title-for-ai-feedback-modal-1' sx={{ paddingLeft: 0 }}>
        Which option is better?
      </DialogTitle>

      <FormControl>
        <Typography id='message-options-group-label'>
          Please select the best message to replace the existing one.
        </Typography>
        <RadioGroup
          aria-labelledby='message-options-group'
          name='radio-buttons-group'
          value={selectedOption}
          onChange={event => selectOption(event.target.value)}
        >
          {messageOptions.map((option, index) => (
            <Box key={option}>
              <FormControlLabel
                value={option}
                control={<Radio />}
                data-testid='ai-feedback-option'
                label={<OptionLabel option={option} index={index} />}
              />
            </Box>
          ))}
        </RadioGroup>
      </FormControl>

      <DialogActions sx={{ paddingRight: 0 }}>
        <Button variant='text' onClick={closeModal} data-testid='ai-feedback-cancel-button'>
          Cancel
        </Button>

        <Button variant='outlined' onClick={retryStep1} data-testid='ai-feedback-cancel-button'>
          Go Back
        </Button>

        <Button onClick={submitFunction} data-testid='ai-feedback-next-button' disabled={!selectedOption || isPending}>
          {isPending ? 'Loading' : 'Submit'}
        </Button>
      </DialogActions>
    </Box>
  );
};
