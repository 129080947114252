import React, { useState } from 'react';
import { List, ListItem, ListItemButton, ListItemText, Divider, Button, IconButton, Stack } from '@mui/material';
import { Add, MoreHoriz } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import {
  useClassrooms,
  useCreateGoogleClassrooms,
  useCreateClassroom,
  useGoogleClassrooms,
} from 'controllers/react-query/classroomHooks';
import { KyronMenu, KyronMenuItem } from 'components/Studio/components/KyronMenu';
import { usePaywall, NavigateToPricingButton } from 'components/Pricing/PaymentContext';
import { NoResources } from '../NoResources';
import { CreateClassroomDialog } from './CreateClassroomDialog';
import { SyncGoogleClassroom } from './SyncGoogleClassroom';
import GoogleClassroomLogo from '../../assets/google_classroom_logo.png';
import { useActions, useTitle } from '../StudioLayout/StudioLayout';
import { ListItemSkeleton } from '../Studio/components/ListItemSkeleton';

const styles = {
  stack: {
    margin: 'auto',
    width: '100%',
    minHeight: '60vh',
  },
};

export const TeacherDashboard = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: allClassrooms, isLoading } = useClassrooms();
  const { data: googleClassrooms, isFetching } = useGoogleClassrooms();
  const { canShareCourse } = usePaywall();
  const [searchParams] = useSearchParams();
  const isParamSyncClassroom = searchParams.get('syncClassroom') === 'true';
  const [syncDialogOpen, setSyncDialogOpen] = useState<boolean>(isParamSyncClassroom);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [createClassroomDialogOpen, setCreateClassroomDialogOpen] = useState(false);
  const [numberOfNewClassrooms, setNumberOfNewClassrooms] = useState(3);
  const { mutate: createGoogleClassrooms, isPending: isNewClassroomsLoading } = useCreateGoogleClassrooms();
  const { mutate: createClassrooms } = useCreateClassroom();
  const showNoResources = !allClassrooms?.length && !isLoading && !isNewClassroomsLoading;

  const handleCreateGoogleClassroom = (classroomIds: number[]) => {
    // Remove the query param after creating the classroom
    // without re-rendering the page
    window.history.replaceState({}, '', '/studio/classrooms');
    setNumberOfNewClassrooms(classroomIds.length);
    createGoogleClassrooms(
      {
        course_ids: classroomIds,
      },
      {
        onError: () => {
          enqueueSnackbar('Error creating classroom', { variant: 'error' });
        },
      },
    );
  };

  const handleCreateKyronClassroom = (groupName: string) => {
    createClassrooms(
      {
        name: groupName,
      },
      {
        onSuccess: () => {
          setCreateClassroomDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar('Error creating classroom', { variant: 'error' });
        },
      },
    );
  };

  const isAnyGoogleClassrooms = allClassrooms?.some(
    classroom => classroom.external_classroom_provider === 'google_classroom',
  );

  const openMoreMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMoreMenu = () => {
    setAnchorEl(null);
  };

  // No need to open the dialog if there are no google classrooms as it will open after authenticating with Google
  const handleSyncClassroomClick = () => {
    if (!isAnyGoogleClassrooms) return;
    setSyncDialogOpen(true);
  };

  const firstClassroomsButtons = (
    <Stack sx={{ display: 'flex', flexDirection: 'row', gap: 1 }} key='default-buttons' data-testid='default-buttons'>
      <Button
        href='/users/auth/google_classroom'
        onClick={handleSyncClassroomClick}
        data-method='post'
        variant='outlined'
        sx={{ whiteSpace: 'nowrap' }}
      >
        Connect Google Classroom
      </Button>
      <Button
        startIcon={<Add />}
        sx={{ whiteSpace: 'nowrap' }}
        onClick={() => setCreateClassroomDialogOpen(!createClassroomDialogOpen)}
      >
        Create Kyron Classroom
      </Button>
    </Stack>
  );

  const moreActionsButton = (
    <IconButton aria-label='More Icon' onClick={openMoreMenu} key='more-actions-buttons'>
      <MoreHoriz />
    </IconButton>
  );

  const classroomButtons = isAnyGoogleClassrooms ? moreActionsButton : firstClassroomsButtons;

  useTitle('Classrooms');
  useActions(
    canShareCourse ? (
      classroomButtons
    ) : (
      <NavigateToPricingButton>Subscribe to create a classroom</NavigateToPricingButton>
    ),
  );

  const menuItems: KyronMenuItem[] = [
    {
      label: 'Create Kyron Classroom',
      onClick: () => {
        setCreateClassroomDialogOpen(!createClassroomDialogOpen);
        closeMoreMenu();
      },
      color: 'default',
    },
    {
      label: 'Sync Google Classrooms',
      onClick: handleSyncClassroomClick,
    },
  ];

  return (
    <>
      <Stack spacing={3} sx={styles.stack}>
        {showNoResources ? (
          <NoResources
            message='You don’t have any classrooms! Get started by connecting your LMS or creating a classroom.'
            action={firstClassroomsButtons}
            width={240}
          />
        ) : (
          <List>
            <Divider />
            {allClassrooms?.map(classroom => (
              <div key={classroom.id}>
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ justifyContent: 'space-between', paddingY: '16px', minHeight: '72px' }}
                    href={`/studio/classrooms/${classroom.id}`}
                  >
                    <Stack direction='row' alignItems='center' spacing={1}>
                      <ListItemText primary={classroom.name} />
                      {classroom.external_classroom_provider === 'google_classroom' && (
                        <IconButton aria-label='Google Classroom logo'>
                          <img
                            src={GoogleClassroomLogo}
                            alt='google classroom'
                            style={{ width: '20px', height: '20px' }}
                          />
                        </IconButton>
                      )}
                    </Stack>
                  </ListItemButton>
                </ListItem>
                <Divider />
              </div>
            ))}
            <ListItemSkeleton
              rows={numberOfNewClassrooms}
              height={72}
              secondaryButton={false}
              loading={isNewClassroomsLoading || isLoading}
            />
          </List>
        )}
      </Stack>
      <CreateClassroomDialog
        handleAddClassroom={handleCreateKyronClassroom}
        onClose={() => {
          setCreateClassroomDialogOpen(false);
        }}
        open={createClassroomDialogOpen}
      />
      <SyncGoogleClassroom
        handleCreateGoogleClassroom={handleCreateGoogleClassroom}
        onClose={() => {
          setSyncDialogOpen(false);
        }}
        open={syncDialogOpen}
        googleClassrooms={googleClassrooms || []}
        isFetching={isFetching}
      />
      <KyronMenu anchorEl={anchorEl} onClose={closeMoreMenu} items={menuItems} />
    </>
  );
};
