import React from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import { Row } from 'components/Row/Row';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { DataGrid } from 'components/common/DataGrid';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { StudentsInClassroom } from 'controllers/types';
import dayjs from 'dayjs';

type Props = {
  isFetching: boolean;
  isError: boolean;
  data: StudentsInClassroom[] | undefined;
};

export const StudentsInClassroomGrid = ({ isFetching, isError, data }: Props) => {
  const pluralLessons = (count: number) => (count === 1 ? 'lesson' : 'lessons');

  const studentValueGetter = (params: { data: StudentsInClassroom }) => {
    const student = params.data?.student;
    if (!student) return '';
    return `${student.first_name} ${student.last_name}`;
  };

  const studentCellRenderer = (params: { data: StudentsInClassroom }) => {
    const student = params.data?.student;
    if (!student) return '';
    return (
      <Row sx={{ marginTop: 1, marginBottom: 1 }} gap={1}>
        <KyronAvatar size={40} label={`${student.first_name} ${student.last_name}`} image={student.avatar_url} />
        <Stack>
          <Typography variant='bodyMedium'>{`${student.first_name} ${student.last_name}`}</Typography>
          <Typography variant='bodySmall' color='textSecondary'>
            {student.email}
          </Typography>
        </Stack>
      </Row>
    );
  };

  const lastActiveRenderer = (params: { value: string }) => (
    <Typography variant='bodyMedium' color='textSecondary'>
      {`Active ${dayjs(params.value).fromNow()}`}
    </Typography>
  );

  const lessonsAttemptedRenderer = (params: { value: number }) => (
    <Typography variant='bodyMedium' color='textSecondary'>
      {`${params.value} ${pluralLessons(params.value)} attempted`}
    </Typography>
  );

  const lessonsWithMisconceptionsRenderer = (params: { value: number }) => {
    const hasMisconceptions = params.value > 0;
    return (
      <Row gap={1}>
        {hasMisconceptions ? <WarningAmber color='error' fontSize='small' /> : null}
        <Typography variant='bodyMedium' color={hasMisconceptions ? 'error' : 'textSecondary'}>
          {`${params.value} ${pluralLessons(params.value)} with misconceptions`}
        </Typography>
      </Row>
    );
  };

  const cellStyle = {
    alignItems: 'center',
    display: 'flex',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const columns = [
    {
      field: 'student',
      headerName: 'Student',
      minWidth: 256,
      autoHeight: true,
      valueGetter: studentValueGetter,
      cellRenderer: studentCellRenderer,
    },
    {
      field: 'last_active',
      headerName: 'Last Active',
      width: 200,
      cellRenderer: lastActiveRenderer,
      cellStyle,
    },
    {
      field: 'lessons_attempted',
      headerName: 'Lessons Attempted',
      width: 200,
      cellRenderer: lessonsAttemptedRenderer,
      cellStyle,
    },
    {
      field: 'lessons_with_misconceptions',
      headerName: 'Lessons with Misconceptions',
      width: 200,
      cellRenderer: lessonsWithMisconceptionsRenderer,
      cellStyle,
    },
  ];

  const rows = data?.map(student => ({
    student: student.student,
    last_active: student.last_active,
    lessons_attempted: student.lessons_attempted,
    lessons_with_misconceptions: student.lessons_with_misconceptions,
  }));

  return (
    <>
      {isFetching && <LoadingIndicator message='Loading...' />}
      {isError && <Typography variant='bodyMedium'>There was an error loading students.</Typography>}
      {data && (
        <Box sx={{ height: '75vh' }}>
          <DataGrid columns={columns} rows={rows} headerHeight={0} />
        </Box>
      )}
    </>
  );
};
