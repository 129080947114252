import React from 'react';
import { Alert, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { useCanvasCourse } from 'controllers/react-query/canvasHooks';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Add } from '@mui/icons-material';

export type Props = {
  courseId: string;
  onSelect: (moduleId: string) => void;
};

export function CanvasListModules({ courseId, onSelect }: Props) {
  const { data: course, isError, isLoading } = useCanvasCourse(courseId!);

  if (isLoading) {
    return <LoadingIndicator spinnerSize={20} sx={{ px: 1 }} message='Fetching course...' />;
  }
  if (isError || !course) {
    return <Alert severity='error'>Error fetching Canvas course modules</Alert>;
  }

  return (
    <>
      <h1>{course.name}</h1>
      <Table sx={{ typography: 'bodyMedium' }}>
        <TableHead>
          <TableRow>
            <TableCell>Module Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {course.modules.map(module => (
            <TableRow>
              <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <Tooltip title={`Enhance ${module.name}`}>
                  <IconButton aria-label={`Enhance ${module.name}`} onClick={() => onSelect(module.id)}>
                    <Add />
                  </IconButton>
                </Tooltip>
                {module.name}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}
