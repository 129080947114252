// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`CreateMisconceptionLessonButton renders the button correctly 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 10px 12px;
}

.emotion-0::-moz-focus-inner {
  border-style: none;
}

.emotion-0.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-0 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-0:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
}

@media (hover: none) {
  .emotion-0:hover {
    background-color: transparent;
  }
}

.emotion-0.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-0:hover {
  box-shadow: none;
}

.emotion-0.Mui-focusVisible {
  box-shadow: none;
}

.emotion-0:active {
  box-shadow: none;
}

.emotion-0.Mui-disabled {
  box-shadow: none;
}

.emotion-1 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-1>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-2 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-3 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<button
    aria-label="Create a Follow-up lesson"
    class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-0"
    tabindex="0"
    type="button"
  >
    <span
      class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-1"
    >
      <svg
        aria-hidden="true"
        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-2"
        data-testid="EditIcon"
        focusable="false"
        viewBox="0 0 24 24"
      >
        <path
          d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"
        />
      </svg>
    </span>
    Create a Follow-up Lesson
    <span
      class="MuiTouchRipple-root emotion-3"
    />
  </button>
</DocumentFragment>
`;
