import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Pause, PlayArrow } from '@mui/icons-material';
import { Box, Chip, CircularProgress, Stack } from '@mui/material';

export function KyronSimpleAudioPlayer({ src, style }: { src: string; style?: CSSProperties }) {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [durationDisplay, setDurationDisplay] = useState<string | null>(null);
  const metaDataReceived = Boolean(durationDisplay);

  const handlePause = () => {
    setIsPlaying(false);
    if (audioRef.current) {
      audioRef.current.currentTime = 0;
    }
  };

  useEffect(setDurationOnMetadataLoad, [audioRef]);
  function setDurationOnMetadataLoad() {
    if (audioRef.current !== null) {
      const audioEl = audioRef.current;
      audioEl.addEventListener('loadedmetadata', () => {
        setDurationDisplay(formatDuration(audioEl.duration));
      });
    }
  }

  useEffect(updateRemainingTime, [audioRef]);
  function updateRemainingTime() {
    if (audioRef.current !== null) {
      const audioEl = audioRef.current;
      audioEl.addEventListener('timeupdate', () => {
        setDurationDisplay(formatDuration(audioEl.duration - audioEl.currentTime));
      });
    }
  }

  const getIcon = () =>
    isPlaying ? (
      <Pause />
    ) : (
      <Stack justifyContent='center' alignItems='center' sx={{ position: 'relative', height: '100%' }}>
        <PlayArrow />
        {!metaDataReceived && (
          <CircularProgress size={24} color='info' sx={{ position: 'absolute', left: 0, opacity: 0.8 }} />
        )}
      </Stack>
    );

  return (
    <Box style={style}>
      <audio
        controls
        preload='metadata'
        style={{ display: 'none' }}
        ref={audioRef}
        onPlay={() => setIsPlaying(true)}
        onPause={handlePause}
      >
        <source src={src} type='audio/mp3' />
        <track kind='captions' srcLang='en' label='English' />
      </audio>
      <Chip
        label={durationDisplay || '00:00'}
        variant='outlined'
        icon={getIcon()}
        disabled={!metaDataReceived}
        onClick={() => (audioRef.current?.paused ? audioRef.current?.play?.() : audioRef.current?.pause?.())}
      />
    </Box>
  );
}

export function formatDuration(durationInSeconds?: number) {
  if (durationInSeconds === undefined) return '00:00';
  const minutes = Math.floor(durationInSeconds / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}
