import React, { useState, Profiler } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import { LessonSegmentEditor } from './LessonSegmentEditor/LessonSegmentEditor';

export const Segment = ({
  isLoading,
  segment,
  lessonId,
  allMisconceptions,
  handleLessonSegmentDelete,
  handleLessonSegmentUpdate,
  allSegmentNames,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const handleProfiler = (id, phase, actualDuration) => {
    if (actualDuration > 10) {
      console.debug(`The ${id} took ${actualDuration}ms to render (${phase})`);
    }
  };

  return (
    <Profiler id='LessonSegmentEditor' onRender={handleProfiler}>
      <div key={segment.id}>
        <Accordion onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={segment.name} id={segment.id}>
            <Typography>{segment.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {expanded && (
              <LessonSegmentEditor
                isLoading={isLoading}
                segment={segment}
                lessonId={lessonId}
                allSegmentNames={allSegmentNames}
                allMisconceptions={allMisconceptions}
                handleLessonSegmentDelete={handleLessonSegmentDelete}
                handleLessonSegmentUpdate={handleLessonSegmentUpdate}
                handleExpand={setExpanded}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </Profiler>
  );
};

/* eslint-disable react/forbid-prop-types */
Segment.propTypes = {
  isLoading: PropTypes.bool,
  segment: PropTypes.object.isRequired,
  lessonId: PropTypes.number,
  allMisconceptions: PropTypes.array,
  handleLessonSegmentDelete: PropTypes.func.isRequired,
  handleLessonSegmentUpdate: PropTypes.func.isRequired,
  allSegmentNames: PropTypes.array.isRequired,
};
