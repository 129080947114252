import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import { MinimalLesson } from 'controllers/types';
import { LessonSelectionCard } from '../LessonSelectionCard/LessonSelectionCard';

type Props = {
  lessonsInCollection?: MinimalLesson[];
  open: boolean;
  onClose?: () => void;
  activeLessonId?: number | null;
  changeLesson?: (id: number) => void;
};

export function CatalogLessonSelectionDialog({
  lessonsInCollection,
  changeLesson,
  open,
  onClose,
  activeLessonId,
}: Props) {
  const renderCollection = () => {
    if (!lessonsInCollection) return <DialogContentText>No lessons available</DialogContentText>;
    return lessonsInCollection.map(lesson => {
      if (lesson.id === activeLessonId) return null;
      return <LessonSelectionCard onClose={onClose} key={lesson.id} lesson={lesson} changeLesson={changeLesson} />;
    });
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth='xl' data-testid='teacher-selection-dialog'>
      <DialogTitle>Choose a teacher</DialogTitle>
      <DialogContent>
        <Stack direction='row' gap={2}>
          {renderCollection()}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='text' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
