import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

window.intercomUserHash = null;
window.intercomUserId = null;

/* eslint-disable */
export const KyronIntercom = {
  init: (intercomHash) => {
    console.debug('Initializing Intercom');
    window.intercomUserHash = intercomHash.intercom_user_hash;
    window.intercomUserId = intercomHash.intercom_user_id;
  },
  getUserHash: () => {
    return window.intercomUserHash;
  },
  getUserId: () => {
    return window.intercomUserId;
  },
  off: () => {
    window?.Intercom?.('update', {
      "hide_default_launcher": true
    });
  },
  on: () => {
    window?.Intercom?.('update', {
      "hide_default_launcher": false
    });
  },

  /**
   * Show article in Intercom interface within the Kyron Learning App
   * @param article 'lti'
   */
  showArticle: (article) => {
    const articleLibrary = {
      lti: '9174784',
    };

    let articleId = articleLibrary[article];

    window?.Intercom?.('showArticle', articleId);
  }
};

/**
 * Updates Intercom with the user hash and user id when necessary
 */
export const useHandleUpdateIntercom = () => {
  const location = useLocation();
  const { update } = useIntercom();
  
  // update intercom
  useEffect(() => {
    update({
      userHash: KyronIntercom.getUserHash(),
      email: KyronIntercom.getUserId(),
      alignment: 'right'
    });
    // Change from null to a value in window.intercomUserId triggers an update.
    // I don't see any reason to re-render this hook on every location change
    // but will keep it since it was there already.
  }, [update, location.pathname, window.intercomUserId]);
}

/**
 * Removes Intercom interface (button) from the UI.
 * Also, it turns it on when the component this hook is called in is unmounted.
 */
export const useRemoveIntercom = () => {
  useEffect(() => {
    KyronIntercom.off();

    // make sure to turn it on when the component is unmounted
    return () => KyronIntercom.on();
  }, []);
}
