import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const FeaturesContext = React.createContext<Features>({});

export type Features = Record<string, boolean>;

type FeatureProviderProps = {
  children: React.ReactNode;
  features: Features;
};
export const FeaturesProvider = ({ children, features }: FeatureProviderProps) => {
  // All of our feature flags are defined in snake_case because we define them in Ruby which uses snake_case. However,
  // all of our TypeScript code would prefer that they were in camelCase so we expose them in both formats on the
  // frontend.
  Object.keys(features).forEach((feature: string) => {
    const camelCaseKey = feature.replace(/_(\w)/g, (_, letter) => letter.toUpperCase());
    features[camelCaseKey] = features[feature];
  });
  return <FeaturesContext.Provider value={features}>{children}</FeaturesContext.Provider>;
};

export const useFeatures = <T extends Features>(): T => {
  const features = useContext(FeaturesContext);
  return features as T;
};

export function useRedirectIfFeatureDisabled({ feature, redirectTo }: { feature: string; redirectTo: string }) {
  const navigate = useNavigate();
  const features = useFeatures();
  const featureEnabled = features[feature];
  if (redirectTo && navigate && !featureEnabled) {
    navigate(redirectTo);
  }
}
