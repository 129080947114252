import React from 'react';
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { KyronNavLink } from '../KyronNavLink';

export type NavDrawerItemProps = {
  icon: JSX.Element;
  label: string;
  url: string;
  setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  autoFocus?: boolean;
};

export function NavDrawerItem({ icon, label, url, setDrawerOpen, autoFocus = false }: NavDrawerItemProps) {
  return (
    <ListItem>
      <ListItemButton
        component={KyronNavLink}
        to={url}
        activeClassName='Mui-selected'
        autoFocus={autoFocus}
        onClick={() => setDrawerOpen?.(false)}
        sx={{
          borderRadius: '100px',
          height: '56px',
          '&.Mui-selected': {
            backgroundColor: 'secondaryContainer.main',
            color: 'secondaryContainer.contrastText',
          },
          '&.Mui-selected:hover, &.Mui-selected:focus': {
            backgroundColor: 'secondaryContainer.dark',
          },
          '&:focus, &:hover': {
            backgroundColor: 'surfaceContainer.main',
          },
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={label} primaryTypographyProps={{ variant: 'labelLarge' }} />
      </ListItemButton>
    </ListItem>
  );
}
