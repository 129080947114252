/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from '@mui/material';
import { UnitForm } from './UnitForm';

const styles = {
  Unitcardfield: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export const NewUnit = props => (
  <Card sx={styles.Unitcardfield}>
    <CardHeader title='New Unit' />
    <UnitForm
      handleFormSubmit={props.handleFormSubmit}
      handleFormCancel={props.handleFormCancel}
      handleUnitUpdate={props.handleUnitUpdate}
      addOrUpdateUnit={props.addUnit}
      courses={props.courses}
    />
  </Card>
);

/* eslint-disable react/forbid-prop-types */

NewUnit.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleFormCancel: PropTypes.func.isRequired,
  handleUnitUpdate: PropTypes.func.isRequired,
  addUnit: PropTypes.func.isRequired,
  courses: PropTypes.array.isRequired,
};
