import { EnqueueSnackbar } from 'notistack';

const MAX_INITIAL_TEXT_BUFFER_DELAY = 5000; // ms

interface Payload {
  event: string;
  data: string;
  status: string;
}

export class OpenEndedAssistantController {
  chatId: number;

  appendToLastMessageIgnoreBuffer: (message: string) => void;

  setIsAssistantResponding: (isResponding: boolean) => void;

  enqueueSnackbar: EnqueueSnackbar;

  handleChatStatus: (status: string) => void;

  sourceBuffer: SourceBuffer | null = null;

  audioChunkQueue: BufferSource[] = [];

  // We don't want to show the user text immediately after it comes in;
  // we want to delay it a bit so that it lines up with the audio better.
  // Buffer the text for N seconds,
  textBuffer: string = '';

  canAppendToMessage: boolean = false;

  isStopped: boolean = false; // For use when the user interrupts the assistant's audio playback: stop playing audio if true

  constructor(
    chatId: number,
    appendToLastMessage: (message: string) => void,
    setIsAssistantResponding: (isResponding: boolean) => void,
    enqueueSnackbar: EnqueueSnackbar,
    handleChatStatus: (status: string) => void,
  ) {
    this.chatId = chatId;

    this.appendToLastMessageIgnoreBuffer = appendToLastMessage;
    this.setIsAssistantResponding = setIsAssistantResponding;
    this.enqueueSnackbar = enqueueSnackbar;
    this.handleChatStatus = handleChatStatus;

    this.sourceBuffer = null;
  }

  public stop(): void {
    if (this.sourceBuffer) {
      if (this.sourceBuffer.buffered?.length > 0) this.sourceBuffer.remove(0, 9999);
      this.sourceBuffer = null;
    }
    this.isStopped = true;
  }

  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  // Note(ege): For some reason my IDE shows as if this method is not used, but it is used in the OpenEnded.tsx in submitResponse method.
  public streamResponse(userInput: string): void {
    this.isStopped = false;
    const audioElement = new Audio();
    this.audioChunkQueue = [];
    const mediaSource = new MediaSource();

    mediaSource.addEventListener('sourceopen', () => {
      audioElement.play().catch(error => {
        console.error('Playback initiation error:', error);
      });

      const mime = 'audio/mpeg';
      if (MediaSource.isTypeSupported(mime)) {
        this.sourceBuffer = mediaSource.addSourceBuffer(mime);
        this.sourceBuffer.mode = 'sequence';
        this.sourceBuffer.addEventListener('update', () => {
          if (!this.sourceBuffer) return; // If we have removed the source buffer (e.g. on modal close)

          if (this.audioChunkQueue.length > 0 && !this.sourceBuffer.updating) {
            const audioChunk: BufferSource | undefined = this.audioChunkQueue.shift();
            if (!audioChunk) return;
            this.sourceBuffer.appendBuffer(audioChunk);
          }
        });
      }

      // preserve symbols like +
      const encodedResponse = encodeURIComponent(userInput);
      const eventSource = new EventSource(
        `/api/v1/chats/stream_add_message?id=${this.chatId}&message=${encodedResponse}`,
      );

      eventSource.onmessage = event => {
        this.handleEventSourceMessage(event, eventSource);
      };

      eventSource.onerror = error => {
        console.error('EventSource failed:', error);
        this.enqueueSnackbar('Something went wrong. Please refresh your browser or reopen the current lesson.', {
          variant: 'error',
        });
        eventSource.close();
      };

      // We don't want to show the user text immediately after it comes in;
      // we want to delay it a bit so that it lines up with the audio better.
      // Buffer the text for N seconds (or until the first audio chunk comes in)
      setTimeout(() => {
        this.canAppendToMessage = true;
      }, MAX_INITIAL_TEXT_BUFFER_DELAY);
    });

    audioElement.src = URL.createObjectURL(mediaSource);
  }

  private handleEventSourceMessage = (event: MessageEvent, eventSource: EventSource) => {
    if (this.isStopped) {
      console.debug('Stopping audio playback and closing SSE');
      eventSource.close();
      this.setIsAssistantResponding(false);
      return;
    }

    const payload: Payload = JSON.parse(event.data);

    if (payload.event === 'end_stream') {
      console.debug('Closing SSE');
      // If the conversation is over, keep the text input disabled. If the conversation if ongoing, enable text input.
      this.setIsAssistantResponding(false);
      eventSource.close();
      this.appendToLastMessageIgnoreBuffer(this.textBuffer);
      this.textBuffer = '';
      this.canAppendToMessage = false;
      return;
    }

    if (payload.event === 'audio_chunk') {
      this.canAppendToMessage = true;
      return this.handleAudioChunk(payload);
    }

    if (payload.status != null) {
      return this.handleNullStatus(payload);
    }
    // Add the text received onto the last message in the conversation
    if (payload.data) this.appendToLastMessage(payload.data);
  };

  private appendToLastMessage(message: string) {
    if (this.canAppendToMessage) {
      this.appendToLastMessageIgnoreBuffer(this.textBuffer + message);
      this.textBuffer = '';
    } else {
      this.textBuffer += message;
    }
  }

  private handleNullStatus(payload: Payload) {
    console.debug('Done with conversation - disabling text input. Status: ', payload.status);
    this.handleChatStatus(payload.status);
    if (payload.data) {
      // Server might not send data
      this.appendToLastMessage(payload.data);
    }
  }

  private handleAudioChunk(payload: Payload) {
    if (!this.sourceBuffer) return;
    const arrayBuffer: ArrayBuffer = this.base64ToArrayBuffer(payload.data);

    if (this.sourceBuffer.updating || this.audioChunkQueue.length > 0) {
      this.audioChunkQueue.push(arrayBuffer);
    } else {
      this.sourceBuffer.appendBuffer(arrayBuffer);
    }
  }
}
