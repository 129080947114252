export function anyInvalidFileSize(files: File[], fileSizeLimit: number): false | File[] {
  const invalidFiles = files.filter(file => {
    // Convert bytes to MB (1MB = 1024 * 1024 bytes)
    const fileSizeInMB = file.size / (1024 * 1024);
    return fileSizeInMB > fileSizeLimit;
  });

  if (invalidFiles.length > 0) {
    return invalidFiles;
  }
  return false;
}
