// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LessonSelectionCard should render as expected 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 12px;
  padding: 8px;
  width: 256px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-text-decoration: none;
  text-decoration: none;
  cursor: pointer;
  background-color: #F1ECF4;
  -webkit-transition: background-color 150ms linear;
  transition: background-color 150ms linear;
}

.emotion-0:hover {
  background-color: #D9D3DD;
}

@media (max-width:899.95px) {
  .emotion-0 {
    width: 166.4px;
  }
}

.emotion-1 {
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 16px 8px 8px;
  font-size: 16px;
  font-weight: 600;
}

.emotion-3 {
  margin: 0;
  font-size: 1em;
  line-height: 1rem;
}

.emotion-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 8px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-5 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 0.8em;
  line-height: 1.2em;
}

.emotion-6 {
  margin: 0;
  font-size: 0.8em;
  line-height: 1.5em;
}

<div
    class="MuiStack-root emotion-0"
    data-testid="teacher-selection-card-1"
  >
    <div
      class="MuiBox-root emotion-1"
      h="100%"
      w="100%"
    >
      <img
        alt="tutor-thumbnail-undefined"
        data-testid="thumbnail"
        src="SvgrURL"
        style="object-fit: fill; height: inherit; width: 100%;"
      />
    </div>
    <div
      class="MuiStack-root emotion-2"
    >
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-3"
      />
      <div
        class="MuiStack-root emotion-4"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-5"
          data-testid="LanguageIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2m6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56M12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96M4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56m2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8M12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96M14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2m.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56M16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2z"
          />
        </svg>
        <p
          class="MuiTypography-root MuiTypography-body1 emotion-6"
        />
      </div>
    </div>
  </div>
</DocumentFragment>
`;
