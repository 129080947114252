import React, { useContext, useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import { KyronEvents } from 'components/utils/KyronEvents';
import { AppBar } from 'components/AppBar/AppBar';
import { AppFooter } from 'components/AppFooter/AppFooter';
import { NavDrawer } from 'components/NavDrawer/NavDrawer';
import { UserContext } from 'components/UserContext';
import { NavDrawerItems } from 'components/NavDrawer/NavDrawerItems';
import { User } from 'controllers/types';
import { NavDrawerButton } from 'components/NavDrawerButton/NavDrawerButton';
import { Button } from '@mui/material';

export function KyronLayoutContent({ children }: { children: React.ReactNode }) {
  const theme = useTheme();
  return <div style={{ padding: 24, background: theme.palette.background.default }}>{children}</div>;
}

const Main = styled('main')(() => ({
  flexGrow: 1,
}));

const Layout = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export function KyronLayout({ user }: { user: User | null }) {
  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  const location = useLocation();
  const userContext = useContext(UserContext);

  useEffect(() => {
    KyronEvents.resetOptions();
  }, [location.pathname]);

  function toggleNavDrawer() {
    setNavDrawerOpen(!navDrawerOpen);
  }

  return (
    <Layout data-testid='kyron-layout'>
      <AppBar
        navButton={
          userContext.isPlatformAdmin && <NavDrawerButton setDrawerOpen={setNavDrawerOpen} drawerOpen={navDrawerOpen} />
        }
        actions={
          user ? (
            <Button to='/studio' component={Link} variant='outlined'>
              Kyron Studio
            </Button>
          ) : null
        }
      />

      <Main>
        <Outlet />
      </Main>

      {userContext.isPlatformAdmin && (
        <NavDrawer
          drawerOpen={navDrawerOpen}
          setDrawerOpen={toggleNavDrawer}
          navDrawerItems={NavDrawerItems(userContext)}
        />
      )}

      <AppFooter />
    </Layout>
  );
}
