import React from 'react';
import { CircularProgress, Checkbox, CheckboxProps, Box } from '@mui/material';

interface KyronCheckboxProps extends Omit<CheckboxProps, 'checkedIcon' | 'icon'> {
  loading?: boolean;
}

// MUIv5 Checkbox with a custom `loading` prop that will show a CircularProgress spinner when true
export const KyronCheckbox = ({ loading, disabled, size = 'medium', ...props }: KyronCheckboxProps) => {
  const spinnerSize = getSpinnerSize(size);
  const spinnerOffset = spinnerSize / 2;

  return (
    <Box position='relative' display='inline-flex' alignItems='center'>
      <Checkbox disabled={disabled || loading} indeterminate={loading} size={size} {...props} />
      {loading && (
        <CircularProgress
          size={spinnerSize}
          thickness={2}
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: `-${spinnerOffset}px`,
            marginTop: `-${spinnerOffset}px`,
            color: 'primary.main',
          }}
        />
      )}
    </Box>
  );
};

// Calculate spinner size based on checkbox size
function getSpinnerSize(size: CheckboxProps['size']) {
  switch (size) {
    case 'small':
      return 28; // Default small checkbox is 24px, add padding
    case 'medium':
      return 36; // Default medium checkbox is 32px, add padding
    case 'large':
      return 44; // Default large checkbox is 40px, add padding
    default:
      return 36; // Default to medium size
  }
}
