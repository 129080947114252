import * as React from 'react';
import { NavLink } from 'react-router-dom';

type KyronNavLinkProps = {
  to: string;
  onClick?: () => void;
  className?: string;
  activeClassName?: string;
  children: React.ReactNode;
};

export const KyronNavLink = React.forwardRef((props: KyronNavLinkProps, ref: React.Ref<HTMLAnchorElement>) => (
  <NavLink
    ref={ref}
    to={props.to}
    onClick={props.onClick}
    className={({ isActive }) => `${props.className} ${isActive ? props.activeClassName : ''}`}
    end
  >
    {props.children}
  </NavLink>
));
