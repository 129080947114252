import React from 'react';
import { styled } from '@mui/material/styles';
import AutosizeInput from 'react-18-input-autosize';
import { getFontSize } from 'components/Equation/util';
import { isEmpty } from 'lodash';
import { BoxComponentProps, Size } from './types';

const Wrapper = styled('div')<{ size?: Size }>`
  font-size: ${props => getFontSize(props.size)};
  text-align: center;
`;

const EmptyWrapper = styled('div')<{ size?: Size }>`
  border: 0.25rem solid;
  margin: 5px 0;
  padding: 0.2em 0.4em 0.2em 0.35em;
  input {
    font-size: ${props => getFontSize(props.size)};
    max-height: 40px;
    border: none;
    outline: none;
    background: transparent;
    text-align: center;
  }
  &:hover {
    cursor: text;
  }
`;

const EmptyOrNumberOnlyRegex = /^$|^[0-9\b]+$/;

/*
 * This function is used to focus on the input when the user clicks on the EmptyWrapper box,
 * without this function, the user would have to click on the input to start typing, and sometimes
 * the input is too small to click on it.
 */
function handleFocusOnInput(event: React.MouseEvent<HTMLElement>) {
  const isInputElement = (event.target as HTMLElement).matches('input');
  if (isInputElement) return;

  const autosizeInput = event.currentTarget.querySelector('input');
  if (autosizeInput) {
    autosizeInput.focus();
  }
}
export const BoxSingle = ({ value, size, onChange, builderMode }: BoxComponentProps) => {
  const [inputValue, setInputValue] = React.useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetValue = event.target.value;
    if (!EmptyOrNumberOnlyRegex.test(targetValue)) {
      return;
    }
    setInputValue(targetValue);
    onChange(targetValue);
  };
  if (!value || isEmpty(inputValue) || builderMode) {
    return (
      <EmptyWrapper onClick={handleFocusOnInput} size={size}>
        <AutosizeInput
          required={!builderMode}
          aria-label='Enter the number for your fraction'
          value={inputValue}
          onChange={handleChange}
        />
      </EmptyWrapper>
    );
  }
  return <Wrapper size={size}>{inputValue}</Wrapper>;
};
