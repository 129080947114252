import React, { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  Box,
  Chip,
  MenuItem,
  ListItemText,
  OutlinedInput,
  SelectChangeEvent,
} from '@mui/material';
import { Prompt, getPrompts } from 'controllers/prompts';

const styles = {
  LessonSegmentPromptSelection: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },
};

type Props = {
  handleInputChange: (event: SelectChangeEvent<string>) => void;
  promptSelection: string;
};

export function PromptSelection({ handleInputChange, promptSelection }: Props) {
  const [prompts, setPrompts] = useState<Prompt[]>([]);

  async function fetchPrompts() {
    const data: Prompt[] = await getPrompts();
    setPrompts(data);
  }

  useEffect(() => {
    fetchPrompts();
  }, []);

  return (
    <FormControl>
      <InputLabel>Prompt Selection</InputLabel>
      <Select
        data-testid='prompt-select'
        input={<OutlinedInput label='Prompt Selection' />}
        renderValue={selected => (
          <Box sx={styles.LessonSegmentPromptSelection}>
            <Chip key={selected} label={selected} />
          </Box>
        )}
        name='prompt_selection'
        value={promptSelection || ''}
        onChange={handleInputChange}
      >
        {prompts?.map(prompt => (
          <MenuItem key={prompt.id} value={prompt.dict}>
            <ListItemText primary={prompt.dict} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
