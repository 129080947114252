import React from 'react';
import { Button, Card, CardContent, IconButton, IconButtonProps, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { LtiPlatform } from 'controllers/ltiPlatforms';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import CardActions from '@mui/material/CardActions';
import { useModal } from '../utils/ModalContext';
import { useDeleteLtiPlatform } from '../../controllers/react-query/ltiPlatformHooks';

type Props = {
  ltiPlatform: LtiPlatform;
  editPlatform?: (ltiPlatform: LtiPlatform) => void;
};

export function LtiPlatformCard({ ltiPlatform, editPlatform }: Props) {
  const { openModal, closeModal } = useModal();
  const { mutateAsync } = useDeleteLtiPlatform();
  const ltiInfo = {
    Issuer: ltiPlatform.issuer,
    'Client ID': ltiPlatform.client_id,
    'Keyset URL': ltiPlatform.keyset_url,
    'OIDC Auth URL': ltiPlatform.oidc_auth_url,
    'Access Token URL': ltiPlatform.access_token_url,
  };

  const deletePlatform = (platform: LtiPlatform) => () => {
    closeModal();
    mutateAsync({ platformId: platform.id })
      .then(() => {
        enqueueSnackbar('Deleted LTI Platform successfully', { variant: 'success' });
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar('Failed to delete LTI Platform', { variant: 'error' });
      });
  };

  const handleDelete = (platform: LtiPlatform) => {
    openModal({
      id: 'delete-platform-modal',
      title: 'Delete LTI Platform',
      content: `Are you sure you want to delete the LTI Platform "${platform.name}"?`,
      action: (
        <>
          <Button onClick={closeModal} variant='outlined'>
            Cancel
          </Button>
          <Button onClick={deletePlatform(platform)} color='error'>
            Delete
          </Button>
        </>
      ),
    });
  };

  const cardActions: (IconButtonProps & { icon: JSX.Element })[] = [
    {
      id: 'edit-platform',
      title: 'Edit Platform',
      icon: <EditIcon />,
      onClick: () => editPlatform?.(ltiPlatform),
    },
    {
      id: 'delete-platform',
      title: 'Delete Platform',
      icon: <DeleteIcon />,
      color: 'error',
      onClick: () => handleDelete(ltiPlatform),
    },
  ];

  return (
    <Card variant='outlined' sx={{ mr: 1, height: '100%', display: 'grid', gridTemplateRows: 'auto 64px' }}>
      <CardContent>
        <Typography variant='headlineSmall' mb={1} data-testid='lti-platform-name'>
          {ltiPlatform.name}
        </Typography>
        <Typography variant='bodyMedium' mb={2}>
          {ltiPlatform.description}
        </Typography>
        {Object.entries(ltiInfo).map(([k, v]) => (
          <Typography key={k} sx={{ overflowWrap: 'anywhere' }}>
            <b>{k}: </b>
            {v}
          </Typography>
        ))}
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        {cardActions.map(({ title, icon, onClick, color, id }) => (
          <Tooltip key={title} title={title} placement='top'>
            <IconButton data-testid={id} aria-label={title} onClick={onClick} color={color}>
              {icon}
            </IconButton>
          </Tooltip>
        ))}
      </CardActions>
    </Card>
  );
}
