/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Collapse } from '@mui/material/';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { TutorSelect } from 'components/TutorSelect/TutorSelect';
import { KyronClient } from '../utils/KyronClient';
import { LessonForm } from './LessonForm';
import { LessonImport } from './LessonImport';
import { AllLessons } from './AllLessons';
import { MiroCheck } from './MiroCheck';
import { JobProgressBar } from '../BackgroundTasks/JobProgressBar';
import { BackgroundTaskProgress } from '../BackgroundTaskProgress';

const styles = {
  LessonEditorDiv2: {
    display: 'flex',
    justifyContent: 'center',
  },
  LessonEditorCollapse: {
    width: '50%',
  },
};

export const LessonEditor = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { enqueueSnackbar } = useSnackbar();
  const [lessons, setLessons] = useState([]);
  const [allTutors, setAllTutors] = useState([]);
  const [page, setPage] = useState(parseInt(searchParams.get('page'), 10) || 1);
  const perPage = parseInt(searchParams.get('per_page'), 10) || 25;
  const noOfLessons = useRef(0);
  const [miroLesson, setMiroLesson] = useState([]);
  const [newLessonExpanded, setNewLessonExpanded] = useState(false);
  const [filterTutorId, setFilterTutorId] = useState(searchParams.get('tutor_id') || '');
  const client = new KyronClient();

  const handleNewLessonExpandClick = () => {
    setNewLessonExpanded(!newLessonExpanded);
  };

  const replaceLesson = (lessonId, newLesson) => {
    const newLessons = lessons.map(lesson => {
      if (lesson.id === lessonId) {
        return newLesson;
      }
      return lesson;
    });

    setLessons(newLessons);
  };

  const handleNewLessonFormSubmit = lesson => {
    const formData = new FormData();
    for (const property in lesson) {
      if (property !== 'id') {
        formData.append(property, lesson[property]);
      }
    }

    client
      .submitJsonDataWithError('/api/v1/lessons', 'POST', formData)
      .then(newLesson => {
        setMiroLesson(newLesson);
        enqueueSnackbar('Lesson Created.');
        setLessons(prevLessons => [newLesson, ...prevLessons]);
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleLessonPublish = lessonToPublish => {
    enqueueSnackbar('Publishing lesson (this will take a few seconds)...');
    client
      .submitJsonDataWithError(`/api/v1/lessons/${lessonToPublish.id}/publish`, 'POST', new FormData())
      .then(newLessonDraft => {
        enqueueSnackbar('Published lesson to students!');
        replaceLesson(lessonToPublish.id, newLessonDraft);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleLessonDelete = id => {
    client
      .deleteDataWithError(`/api/v1/lessons/${id}`)
      .then(() => {
        const newLessons = lessons.filter(lesson => lesson.id !== id);
        setLessons(newLessons);
        enqueueSnackbar('Lesson Deleted.');
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleLessonUpdate = lesson => {
    const formData = new FormData();
    for (const property in lesson) {
      if (property === 'table_of_contents' || property === 'editors') {
        lesson[property].forEach(item => {
          formData.append(`${property}[]`, JSON.stringify(item));
        });
      } else {
        formData.append(property, lesson[property]);
      }
    }

    return client
      .submitJsonDataWithError(`/api/v1/lessons/${lesson.id}`, 'PUT', formData)
      .then(updatedLesson => {
        replaceLesson(lesson.id, updatedLesson);
        enqueueSnackbar(`Updated lesson ${updatedLesson.name}.`);
        return updatedLesson;
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const getLessonPage = (pg, numPerPage) => {
    const data = {
      page: pg,
      per_page: numPerPage,
      tutor_id: filterTutorId || '',
    };
    setSearchParams(data);

    client
      .getDataWithError(`/api/v1/lessons.json?${new URLSearchParams(data).toString()}`)
      .then(resp => {
        setLessons(resp.lessons);
        noOfLessons.current = resp.meta.total_count;
        setPage(pg);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  useEffect(() => {
    getLessonPage(page, perPage);

    client
      .getDataWithError('/api/v1/tutors')
      .then(tutorResponse => {
        setAllTutors(tutorResponse.tutors);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLessonPage(page, perPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTutorId]);

  useTitle('Internal Lesson Editor');
  useActions(
    <>
      <Button aria-label='new lesson' variant='contained' onClick={handleNewLessonExpandClick} icon={AddIcon}>
        New Lesson
      </Button>
      <LessonImport />
      <MiroCheck />
    </>,
  );

  return (
    <>
      <div>
        <BackgroundTaskProgress />
        {miroLesson?.background_tasks?.length > 0 &&
          miroLesson.background_tasks[miroLesson.background_tasks.length - 1].broadcast && (
            <JobProgressBar
              task={miroLesson.background_tasks[miroLesson.background_tasks.length - 1]}
              key={miroLesson.background_tasks[miroLesson.background_tasks.length - 1].id}
            />
          )}
      </div>
      <div style={styles.LessonEditorDiv2}>
        <TutorSelect getSelected={tutor => setFilterTutorId(tutor?.id)} />
      </div>

      <div style={styles.LessonEditorDiv2}>
        <Collapse in={newLessonExpanded} sx={styles.LessonEditorCollapse} timeout='auto' mountOnEnter>
          <LessonForm
            handleFormSubmit={handleNewLessonFormSubmit}
            handleExpand={handleNewLessonExpandClick}
            allTutors={allTutors}
            lesson={{}}
            segmentNamesIds={[]}
            showTOC={false}
          />
        </Collapse>
      </div>
      <div style={styles.LessonEditorDiv2}>
        <AllLessons
          lessons={lessons}
          handleLessonDelete={handleLessonDelete}
          handleLessonUpdate={handleLessonUpdate}
          handleLessonPublish={handleLessonPublish}
          allTutors={allTutors}
          noOfLessons={noOfLessons.current}
          page={page}
          perPage={perPage}
          getLessonPage={getLessonPage}
        />
      </div>
    </>
  );
};
