import React from 'react';
import { Box, Button, TextField, Typography, DialogActions, DialogTitle } from '@mui/material';
import { useModal } from 'components/utils/ModalContext';

type ChatFeedbackModalContentStep1Props = {
  submitFunction: () => void;
  isPending: boolean;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userComment: string;
  handleEnterKey: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const ChatFeedbackModalContentStep1 = ({
  submitFunction,
  isPending,
  handleInputChange,
  userComment,
  handleEnterKey,
}: ChatFeedbackModalContentStep1Props) => {
  const { closeModal } = useModal();

  return (
    <Box display='flex' flexDirection='column' data-testid='ai-feedback-content-step1'>
      <DialogTitle id='dialog-title-for-ai-feedback-modal-1' sx={{ paddingLeft: 0 }}>
        Provide us more feedback
      </DialogTitle>
      <Typography>Please state the issue with this message to generate better options.</Typography>
      <TextField
        autoFocus
        data-testid='ai-feedback-input'
        fullWidth
        label='Issue description'
        onChange={handleInputChange}
        required
        onKeyDown={handleEnterKey}
        sx={{ marginTop: 2 }}
        value={userComment}
      />
      <DialogActions sx={{ paddingRight: 0 }}>
        <Button variant='text' onClick={closeModal} data-testid='ai-feedback-cancel-button'>
          Cancel
        </Button>
        <Button
          onClick={submitFunction}
          data-testid='ai-feedback-next-button'
          disabled={!userComment.length || isPending}
        >
          {isPending ? 'Loading' : 'Next'}
        </Button>
      </DialogActions>
    </Box>
  );
};
