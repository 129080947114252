import React, { useState, useContext } from 'react';
import {
  Button,
  Stack,
  Pagination,
  List,
  Divider,
  Select,
  MenuItem,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
  Typography,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useRolesQuery, useAddUserToOrganization, useGetOrganizationInvitations } from 'controllers/react-query';
import { SearchField } from 'components/SearchField';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { User, Role, Invitation, Organization } from 'controllers/types';
import { UserContext } from 'components/UserContext';
import { useSnackbar } from 'notistack';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { RoleSelect } from './components/RoleSelect';
import { MemberListItem } from './components/MemberListItem';
import { NoResources } from '../NoResources';
import { ListItemSkeleton } from './components/ListItemSkeleton';
import { SettingsNavigation } from './Settings/SettingsNavigation';

export function Members(): React.JSX.Element {
  const [page, setPage] = useState(1);
  const perPage = 25;
  const { user: currentUser } = useContext(UserContext);
  const [search, setSearch] = useState('');
  const {
    isFetching,
    isError,
    data: invitationsData,
  } = useGetOrganizationInvitations(currentUser?.active_organization, { page, perPage, search });
  const { isFetching: isFetchingRoles, data: allRoles } = useRolesQuery();
  const { invitations, meta } = invitationsData || {};
  const totalPages = meta?.total_pages;
  const noUsersToShow = !invitations?.length && !isFetching;
  const [openAddMember, setOpenAddMember] = useState(false);
  const { mutate: addUserToOrganization, isPending } = useAddUserToOrganization(currentUser?.active_organization);
  const { enqueueSnackbar } = useSnackbar();

  const addMemberToOrg = (email: string, onClose: () => void) => {
    addUserToOrganization(
      { email },
      {
        onSuccess: () => {
          enqueueSnackbar(`Invitation sent to ${email} successfully`);
          onClose();
        },
        onError: error => {
          enqueueSnackbar(`${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  return (
    <>
      <SettingsNavigation />
      <Typography variant='headlineMedium' mb={3}>
        Members
      </Typography>
      <Box mb={5}>
        <Button startIcon={<Add />} onClick={() => setOpenAddMember(true)}>
          Add Member
        </Button>
      </Box>
      <SearchField
        placeholder='Search for members'
        sx={{ maxWidth: 400 }}
        searchTerm={search}
        onSearch={setSearch}
        isLoading={isFetching}
        searchError={isError ? 'Failed when searching for members' : null}
      />
      <Stack gap={4}>
        {noUsersToShow ? (
          <NoResources
            message='Get started by adding a member'
            action={
              <Button startIcon={<Add />} onClick={() => setOpenAddMember(true)} data-testid='add-member-button'>
                Add Member
              </Button>
            }
          />
        ) : (
          <List sx={{ width: '100%' }}>
            <Divider />
            <ListItemSkeleton rows={3} loading={isFetching || isFetchingRoles} height={56} />
            {invitations?.map(invitation =>
              invitation.user ? (
                <UserListItem
                  key={invitation.id}
                  invitation={invitation}
                  allRoles={allRoles || []}
                  currentUser={currentUser || null}
                />
              ) : (
                <InvitationListItem
                  key={invitation.id}
                  invitation={invitation}
                  organization={currentUser?.active_organization}
                />
              ),
            )}
          </List>
        )}
      </Stack>
      {isFetching || noUsersToShow ? null : (
        <Pagination
          sx={{ alignSelf: 'center', bottom: 0 }}
          count={totalPages}
          shape='rounded'
          page={page}
          onChange={(_, newPage) => setPage(newPage)}
        />
      )}
      <AddMemberDialog
        open={openAddMember}
        onClose={() => setOpenAddMember(false)}
        addMemberToOrg={addMemberToOrg}
        isPending={isPending}
      />
    </>
  );
}

function AddMemberDialog({
  open,
  onClose,
  addMemberToOrg,
  isPending,
}: {
  open: boolean;
  onClose: () => void;
  addMemberToOrg: (email: string, onClose: () => void) => void;
  isPending?: boolean;
}) {
  const [email, setEmail] = useState('');

  return (
    <Dialog open={open} onClose={onClose} data-testid='add-member-dialog'>
      <Box
        sx={{
          display: 'flex',
          width: '400px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DialogTitle variant='titleLarge'>Add Member</DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <TextField
            autoFocus
            margin='dense'
            id='email'
            label='Email Address'
            type='email'
            fullWidth
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ width: '100%' }}>
          <Stack direction='row' justifyContent='flex-end' gap={1}>
            <Button onClick={onClose} variant='text'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                addMemberToOrg(email, onClose);
                setEmail('');
              }}
              variant='text'
              disabled={!email || isPending}
              data-testid={isPending ? 'submit-email-button-pending' : 'submit-email-button'}
              startIcon={isPending ? <LoadingIndicator /> : undefined}
            >
              Add Member
            </Button>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

function BlankAvatar() {
  return <Avatar sx={{ bgcolor: 'primaryContainer.main', width: 32, height: 32 }} />;
}

// Right now, all members are invited as a teacher. If we
// eventually allow members to be invited as an org_admin, we
// should modify this component to allow for that.
function FixedRoleSelect() {
  return (
    <Select
      labelId='fixed-role-select-label'
      data-testid='fixed-role-select'
      disabled
      value='teacher'
      variant='standard'
      sx={{ width: 120 }}
    >
      <MenuItem value='teacher'>Teacher</MenuItem>
    </Select>
  );
}

function UserListItem({
  invitation,
  allRoles,
  currentUser,
}: {
  invitation: Invitation;
  allRoles: Role[];
  currentUser: User | null;
}) {
  const user = invitation.user as User;

  return (
    <MemberListItem
      avatar={<KyronAvatar image={user.avatar_url} label={user.name} sx={{ width: 32, height: 32 }} />}
      displayName={user.name}
      roleSelect={
        <RoleSelect user={user} activeRoles={allRoles || []} organization={currentUser?.active_organization} />
      }
      invitation={invitation}
      organization={currentUser?.active_organization}
      deletable={currentUser?.id !== user.id}
    />
  );
}

function InvitationListItem({ invitation, organization }: { invitation: Invitation; organization?: Organization }) {
  return (
    <MemberListItem
      avatar={<BlankAvatar />}
      displayName={invitation.email}
      roleSelect={FixedRoleSelect()}
      invitation={invitation}
      organization={organization}
    />
  );
}
