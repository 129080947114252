import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

import { Stack, IconButton, Button, Typography, Alert, CircularProgress } from '@mui/material';

import { Close } from '@mui/icons-material';
import { BackgroundTask } from 'controllers/types';
import { KyronClient } from '../utils/KyronClient';
import { useProgressBarChannel } from './useProgressBarChannel';

export const JobProgressBar = ({ task }: { task: BackgroundTask }) => {
  const { enqueueSnackbar } = useSnackbar();
  const client = new KyronClient();
  const { status } = useProgressBarChannel(task);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleArchive = () => {
    client.getDataWithError(`/api/v1/background_task/${task.id}/archive`).catch(error => {
      enqueueSnackbar(error.message, { variant: 'error' });
    });
  };

  const isError = status === 'error';
  const isCompleted = status === 'done';
  const isArchived = status === 'archived';
  const statusHeading = status ? status.toUpperCase() : 'Processing';

  if (!task.id || isCompleted || isArchived) {
    return null;
  }

  return (
    <Alert
      data-testid='pending-task'
      severity={isError ? 'error' : 'warning'}
      action={
        <Stack direction='row' alignItems='center'>
          <Button onClick={toggleIsExpanded} variant='text'>
            {isExpanded ? 'Hide' : 'Show Details'}
          </Button>
          <IconButton onClick={handleArchive} aria-label='close-alert'>
            <Close />
          </IconButton>
        </Stack>
      }
    >
      <Stack direction='row' alignItems='center' justifyContent='space-between' gap={2}>
        <Typography>{`${statusHeading}: ${task.name}`}</Typography>
        {status !== 'error' && <CircularProgress aria-label='progress-bar-loading' size={20} />}
      </Stack>
      {isExpanded ? <Typography>{task.message}</Typography> : null}
    </Alert>
  );
};
