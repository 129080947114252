import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Grid, ButtonGroup, Button, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

type ShortcutItem = {
  label: string;
  startDate: Dayjs;
  endDate: Dayjs;
};

const today = dayjs();
const shortcutItems: ShortcutItem[] = [
  {
    label: 'Last Week',
    startDate: today.hour(0).minute(0).second(0).subtract(1, 'week'),
    endDate: today.hour(23).minute(59).second(59),
  },
  {
    label: 'This Month',
    startDate: today.startOf('month'),
    endDate: today.hour(23).minute(59).second(59),
  },
  {
    label: 'This Year',
    startDate: today.startOf('year'),
    endDate: today.hour(23).minute(59).second(59),
  },
  {
    label: 'All Time',
    startDate: dayjs('2021-01-01'),
    endDate: today.hour(23).minute(59).second(59),
  },
];

type Props = {
  timestampStart?: Dayjs;
  timestampEnd?: Dayjs;
  handleDateRange: (startDate: Dayjs, endDate: Dayjs) => void;
};

export const DateRangePickerAnalytics = ({
  timestampStart = shortcutItems[0].startDate,
  timestampEnd = shortcutItems[0].endDate,
  handleDateRange,
}: Props) => {
  const handleSetStart = (startValue: Dayjs | null) => {
    if (startValue !== null) handleDateRange(startValue, timestampEnd);
  };
  const handleSetEnd = (endValue: Dayjs | null) => {
    if (endValue !== null) handleDateRange(timestampStart, endValue);
  };

  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      <Grid item lg={3} md={3} xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker label='Start Date' value={timestampStart} onAccept={handleSetStart} />
        </LocalizationProvider>
      </Grid>
      <Grid item lg={3} md={3} xs={12}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker label='End Date' value={timestampEnd} onAccept={handleSetEnd} />
        </LocalizationProvider>
      </Grid>
      <Grid item lg={1}>
        <Box width='100%' />
      </Grid>
      <Grid item lg={4} md={12} xs={12}>
        <ButtonGroup data-testid='shortcut-buttons' disableElevation color='primary' variant='outlined'>
          {shortcutItems.map(shortcut => (
            <Button
              key={shortcut.label}
              size='small'
              name={shortcut.label}
              onClick={() => handleDateRange(shortcut.startDate, shortcut.endDate)}
            >
              {shortcut.label}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};
