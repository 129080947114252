import React from 'react';

import { ManageAccounts, Summarize, CorporateFare } from '@mui/icons-material';
import { UserCtx } from 'components/UserContext';
import { NavDrawerItemProps } from '../NavDrawerItem/NavDrawerItem';

export const NavDrawerItems = (userContext: UserCtx): NavDrawerItemProps[][] =>
  [
    [
      {
        icon: <Summarize />,
        label: 'Lesson Instances Report',
        url: '/admin/reports',
        shouldShow: userContext.isPlatformAdmin,
      },
      {
        icon: <Summarize />,
        label: 'Training Logs Report',
        url: '/admin/training',
        shouldShow: userContext.isPlatformAdmin,
      },
    ],
    [
      {
        icon: <ManageAccounts />,
        label: 'User Management',
        url: '/admin/users',
        shouldShow: userContext.isOrgAdmin,
      },
      {
        icon: <CorporateFare />,
        label: 'Organizations',
        url: '/admin/organizations',
        shouldShow: userContext.isOrgAdmin,
      },
    ],
  ]
    .map(section => section.filter(item => item.shouldShow))
    // Remove empty sections so we don't show extra horizontal lines to users.
    .filter(section => section.length > 0);
