import {
  GroupOutlined,
  IntegrationInstructionsOutlined,
  SettingsOutlined,
  AccountBalanceOutlined,
} from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import { useFeatures } from 'components/FeaturesContext';
import { Row } from 'components/Row/Row';
import React from 'react';
import { NavLink } from 'react-router-dom';

const SettingsNavButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&.active': {
    backgroundColor: theme.palette.primaryContainer.main,
    color: theme.palette.primaryContainer.contrastText,
  },
})) as typeof Button;

export function SettingsNavigation() {
  const { show_stripe_payments: showStripePayments } = useFeatures();
  return (
    <Row gap={3} mb={5}>
      <SettingsNavButton variant='text' component={NavLink} to='/studio/settings' startIcon={<SettingsOutlined />} end>
        General
      </SettingsNavButton>
      <SettingsNavButton
        variant='text'
        component={NavLink}
        to='/studio/settings/members'
        startIcon={<GroupOutlined />}
        end
      >
        Members
      </SettingsNavButton>
      <SettingsNavButton
        variant='text'
        component={NavLink}
        to='/studio/settings/integrations'
        startIcon={<IntegrationInstructionsOutlined />}
        end
      >
        Integrations
      </SettingsNavButton>
      {showStripePayments && (
        <SettingsNavButton
          variant='text'
          component={NavLink}
          to='/studio/settings/billing'
          startIcon={<AccountBalanceOutlined />}
          end
        >
          Billing
        </SettingsNavButton>
      )}
    </Row>
  );
}
