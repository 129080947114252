import { Stack, SxProps, Tab, Tabs, Typography } from '@mui/material';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { Row } from 'components/Row/Row';
import { useChannelQuery } from 'controllers/react-query/channelHooks';
import * as React from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

const styles: Record<string, SxProps> = {
  tab: {
    flexGrow: {
      xs: 1,
      sm: 0,
    },
  },
};

export function ChannelLayout() {
  const { channelId } = useParams();
  const { pathname } = useLocation();
  const pathRoot = `/marketplace/channels/${channelId}`;
  const { data: channel, isError, isLoading } = useChannelQuery(Number(channelId));

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    throw new Error('Error fetching channel');
  }

  if (!channel) {
    throw new Error('Channel not found');
  }

  return (
    <Stack gap={3}>
      <Row gap={2} sx={{ alignItems: 'flex-start' }}>
        <KyronAvatar image={channel.image_url} label={channel.name} size={120} />
        <Stack gap={1} maxWidth='720px'>
          <Typography variant='headlineLarge'>{channel.name}</Typography>
          <Typography variant='bodyLarge'>{channel.description}</Typography>
        </Stack>
      </Row>
      <Tabs value={pathname}>
        <Tab label='Lessons' to='' value={pathRoot} component={Link} replace sx={styles.tab} />
        <Tab
          label='Collections'
          to='collections'
          value={`${pathRoot}/collections`}
          component={Link}
          replace
          disabled
          sx={styles.tab}
        />
      </Tabs>
      <Outlet />
    </Stack>
  );
}
