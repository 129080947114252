// Keys for storing user preferences in browser storage
export const VIDEO_VOLUME_KEY = 'lesson__video-volume';
export const VIDEO_PLAYBACK_RATE_KEY = 'lesson__video-playback-rate';
export const VIDEO_INFO_VISIBLE_KEY = 'lesson__video-info-visible';
export const VIDEO_VOLUME_MUTED_KEY = 'lesson__video-volume-muted';

export const LESSON_EDITOR_VIDEO_PLAYBACK_RATE_KEY = 'lesson-editor__video-playback-rate';
export const LESSON_EDITOR_VIDEO_VOLUME = 'lesson-editor__video-volume';

export const STUDIO_NAV_COLLAPSED_KEY = 'studio-nav-collapsed';
