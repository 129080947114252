import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField } from '@mui/material';

type Props = {
  handleAddClassroom: (groupName: string) => void;
  open: boolean;
  onClose: () => void;
};

export function CreateClassroomDialog({ handleAddClassroom, open, onClose }: Props) {
  const [groupName, setGroupName] = useState('');
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleAddClassroom(groupName);
    setGroupName('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='dialog-title'>
      <DialogTitle id='dialog-title'>Create Kyron classroom</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText>
            Create a classroom directly on Kyron Learning and have your students complete assignments right in the app.
          </DialogContentText>
          <TextField
            autoFocus
            margin='normal'
            id='name'
            label='Classroom name'
            type='text'
            fullWidth
            value={groupName}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!groupName} type='submit' variant='text'>
            Create classroom
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
