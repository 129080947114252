import React from 'react';
import { Grid, Pagination } from '@mui/material';

import { Misconception } from 'controllers/types';
import { LessonMisconception } from './LessonMisconception';

const styles = {
  misconceptionDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
};

export function AllLessonMisconceptions({
  misconceptions,
  handleMisconceptionDelete,
  handleMisconceptionUpdate,
  getMisconceptionsPage,
  page,
  perPage,
  noOfMisconceptions,
}: {
  misconceptions: Misconception[];
  handleMisconceptionDelete: (signedId: number) => void;
  handleMisconceptionUpdate: (misconception: Misconception) => void;
  getMisconceptionsPage: (page: number, perPage: number) => void;
  page: number;
  perPage: number;
  noOfMisconceptions: number;
}) {
  return (
    <div style={styles.misconceptionDiv}>
      <Grid style={{ width: '82%' }} container spacing={2}>
        {misconceptions.map(misconception => (
          <Grid xs={12} item key={misconception.id}>
            <LessonMisconception
              misconception={misconception}
              handleMisconceptionDelete={handleMisconceptionDelete}
              handleMisconceptionUpdate={handleMisconceptionUpdate}
            />
          </Grid>
        ))}
        <Grid xs={12} item>
          <Pagination
            data-testid='misconception-pagination'
            style={styles.paginationWrapper}
            count={Math.ceil(noOfMisconceptions / perPage)}
            siblingCount={5}
            shape='rounded'
            page={page}
            onChange={(_e, v) => getMisconceptionsPage(v, perPage)}
          />
        </Grid>
      </Grid>
    </div>
  );
}
