import React from 'react';
import { Stack, Skeleton, ListItem } from '@mui/material';

/**
 * A Skeleton structure that mimics the ListItem structure when loading
 * @param loading boolean
 * @param row number of rows to show
 */
export function ListItemSkeleton({
  loading,
  rows = 1,
  height = 80,
  secondaryButton = true,
}: {
  loading?: boolean;
  rows?: number;
  height?: number;
  secondaryButton?: boolean;
}) {
  return (
    loading &&
    new Array(rows).fill(null).map((_, i) => (
      <ListItem
        key={`list-item-skeleton-${i}`} // eslint-disable-line react/no-array-index-key
        divider
        sx={{
          position: 'relative',
          width: '100%',
          height,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
        }}
      >
        <Skeleton variant='rectangular' height={16} width='40%' sx={{ borderRadius: 1 }} />
        <Stack direction='row' gap={1}>
          {secondaryButton && <Skeleton variant='rectangular' width={144} height={40} sx={{ borderRadius: 20 }} />}
          <Skeleton variant='circular' width={40} height={40} />
        </Stack>
      </ListItem>
    ))
  );
}
