/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, CardContent, CardActions, IconButton } from '@mui/material';
import { Done as DoneIcon, Cancel as CancelIcon } from '@mui/icons-material';

const styles = {
  courseCardField: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export const CourseForm = props => {
  const [course, setCourse] = useState(
    props.course
      ? {
          id: props.course.id,
          name: props.course.name,
          description: props.course.description,
          position: props.course.position,
        }
      : {
          id: null,
          name: '',
          description: '',
          position: null,
        },
  );

  const handleInputChange = e => {
    setCourse(prevCourse => ({
      ...prevCourse,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <CardContent style={styles.courseCardField}>
      <form
        data-testid='course-form'
        onSubmit={e => {
          e.preventDefault();
          props.handleFormSubmit(props.handleCourseUpdate, props.addOrUpdateCourse, course);
          props.handleFormCancel();
          e.target.reset();
        }}
      >
        <TextField
          variant='outlined'
          name='name'
          fullWidth
          margin='normal'
          label='Course Name'
          type='text'
          onChange={handleInputChange}
          defaultValue={course.name}
          required
          helperText='Enter the name of the course'
        />
        <TextField
          variant='outlined'
          name='description'
          fullWidth
          margin='normal'
          label='Course Description'
          type='text'
          onChange={handleInputChange}
          defaultValue={course.description}
          helperText='Enter the description of the course'
        />
        <CardActions>
          <IconButton aria-label='submit course' type='submit' data-testid='done-button'>
            <DoneIcon />
          </IconButton>
          <IconButton aria-label='cancel edit course' onClick={props.handleFormCancel}>
            <CancelIcon />
          </IconButton>
        </CardActions>
      </form>
    </CardContent>
  );
};

CourseForm.propTypes = {
  handleFormSubmit: PropTypes.func,
  handleFormCancel: PropTypes.func,
  addOrUpdateCourse: PropTypes.func,
  handleCourseUpdate: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  course: PropTypes.object,
};
