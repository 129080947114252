import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
} from '@mui/material';
import { Search as SearchIcon, Clear, KeyboardReturn } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import {
  getMarketPlaceSearchHistory,
  MarketPlaceSearchHistoryItem,
  saveMarketplaceSearchHistory,
} from './marketplaceSearchUtils';

const MARKETPLACE_SHOWN_HISTORY_LIMIT = 50; // shown on UI
const CLOSED_HEIGHT = 40;
const OPACITY_ANIM = 'opacity 0.1s ease-in-out';

const SearchBarPaper = styled(Paper)`
  padding: 0 8px;
  overflow: hidden;
  transition:
    box-shadow 0.2s,
    height 50ms;
  box-shadow: ${p => p.theme.shadows[1]};
  '&:focus-within': {
    box-shadow: ${p => p.theme.shadows[2]};
  }
`;

const SearchRow = styled(Box)`
  width: 100%;
  height: ${CLOSED_HEIGHT}px;
  display: flex;
  align-items: center;
`;

const DropdownContent = styled(Stack)`
  width: 100%;
  height: 100%;
  transition: ${OPACITY_ANIM};
`;

type Props = {
  placeholder?: string;
  isLoading?: boolean;
  onSearch: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void);
  /**
   * Extra actions to be displayed on the right side of the search bar
   */
  extraActions?: React.ReactNode;
  /**
   * If true, the search bar will not show the search history dropdown
   * @default false
   */
  disableDropdown?: boolean;
  /**
   * If true, the search bar will not update the URL search params when the user searches
   * @default false
   */
  disableDeepLinking?: boolean;
};

export function MarketplaceSearch({
  placeholder = 'Search',
  isLoading,
  onSearch,
  extraActions,
  disableDropdown = false,
  disableDeepLinking = false,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputWrapperRef = useRef<HTMLInputElement>(null);
  const paperRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [dropdownExpanded, setDropdownExpanded] = useState(false);
  const [searchHistory, setSearchHistory] = useState<MarketPlaceSearchHistoryItem[]>(getMarketPlaceSearchHistory());
  const [filteredSearchHistoryList, setFilteredSearchHistoryList] =
    useState<MarketPlaceSearchHistoryItem[]>(searchHistory);

  const [highlightedListItemIndex, setHighlightedListItemIndex] = useState<number | null>(null);

  /* activeSearch is the search term user has searched for most recently and the search bar is currently displaying results for it
   * We need this state to restore the search term when user exits the search bar after manipulating the last search without submitting a searching
   * For example, user types 'math' and then hits enter, UI shows results, then user changes search term to '4th grade math' and clicks out the search bar
   * In this case, we would restore the search term to be 'math' looking at the 'activeSearch' state
   */
  const [activeSearch, setActiveSearch] = useState('');

  function expandDropdown() {
    if (disableDropdown || !filteredSearchHistoryList.length) return;
    setDropdownExpanded(true);
  }

  function collapseDropdown() {
    if (disableDropdown) return;
    setDropdownExpanded(false);
  }

  function updateSearchQueryParam(searchVal: string) {
    if (disableDeepLinking) return;
    setSearchParams({ q: searchVal });
  }

  function removeSearchQueryParam() {
    if (disableDeepLinking) return;
    setSearchParams(prevSearchParams => {
      prevSearchParams.delete('q');
      return prevSearchParams;
    });
  }

  function exitSearchBar() {
    // If user exits the search bar before searching, restore the active search
    if (activeSearch !== inputValue) {
      handleInputChange(activeSearch);
    }

    inputWrapperRef.current?.querySelector('input')?.blur?.();
    paperRef.current?.blur();
    collapseDropdown();
    setHighlightedListItemIndex(null);
  }

  function handleSearch(searchVal: string) {
    exitSearchBar();

    onSearch(searchVal);
    setActiveSearch(searchVal);
    setInputValue(searchVal);

    if (!searchVal) return;

    // reflect search to URL search params
    updateSearchQueryParam(searchVal);

    // save search to history
    saveMarketplaceSearchHistory(searchVal, setSearchHistory);
  }

  function handleInputChange(newValue: string) {
    // set internal input state for history search actions
    setInputValue(newValue);
    setHighlightedListItemIndex(null);

    // expand dropdown for history if collapsed
    if (!dropdownExpanded) expandDropdown();

    // filter the search history based on the input value
    const filteredList = searchHistory.filter(s => s.text.includes(newValue)); // filter history based on search term
    // collapse dropdown if no history matches the search term
    if (filteredList.length === 0) {
      collapseDropdown();
      return null;
    }

    // set the filtered list if there are matches
    setFilteredSearchHistoryList(filteredList);
  }

  function handleHistoryClick(searchTextFromHistory: string) {
    setInputValue(searchTextFromHistory);
    handleSearch(searchTextFromHistory);
  }

  function handleListNavigation(key: string) {
    const listLength = filteredSearchHistoryList.length;
    const lastIndex = listLength - 1;

    const previousIndex = highlightedListItemIndex;
    let newIndex = previousIndex;

    if (key === 'ArrowDown') {
      if (previousIndex === null || previousIndex === lastIndex) {
        newIndex = 0;
      } else {
        newIndex = previousIndex + 1;
      }
    }

    if (key === 'ArrowUp') {
      if (previousIndex === null || previousIndex === 0) {
        newIndex = lastIndex;
      } else {
        newIndex = previousIndex - 1;
      }
    }

    setHighlightedListItemIndex(newIndex);

    return newIndex;
  }

  const handleClearInput = () => {
    inputWrapperRef.current?.querySelector('input')?.focus?.();
    handleInputChange('');
    onSearch(''); // if user is clearing the put, take them back to "no search" state
    removeSearchQueryParam(); // removes ?q=<search_term> from URL
    setActiveSearch(''); // clear active search state as well
  };

  useEffect(resetFilteredSearchHistory, [searchHistory]);
  function resetFilteredSearchHistory() {
    setFilteredSearchHistoryList(searchHistory);
  }

  useEffect(scrollListItemIntoView, [highlightedListItemIndex]);
  function scrollListItemIntoView() {
    if (highlightedListItemIndex === null) return;
    const list = listRef.current;
    const listItem = list?.children[highlightedListItemIndex] as HTMLElement;
    listItem?.scrollIntoView?.({ block: 'center', behavior: 'smooth' });
  }

  // only run once on mount, and/or when search param `q` changes. Hence, disable eslint rule
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleDeepLinkSearch, [searchParams.get('q')]);
  function handleDeepLinkSearch() {
    if (!disableDeepLinking) {
      const searchQuery = searchParams.get('q');
      if (searchQuery !== null) handleSearch(searchQuery);
    }
  }

  return (
    <ClickAwayListener onClickAway={exitSearchBar}>
      <SearchBarPaper
        data-testid='marketplace-search-paper'
        ref={paperRef}
        sx={{ height: dropdownExpanded ? 'fit-content' : CLOSED_HEIGHT, width: { xs: 280, sm: 400 } }}
      >
        <SearchRow
          role='presentation' /* [a11ly] read more about role="presentation": https://mui.com/base-ui/react-click-away-listener/#accessibility */
        >
          <InputBase
            data-testid='marketplace-search-input'
            ref={inputWrapperRef}
            fullWidth
            sx={{ minHeight: CLOSED_HEIGHT }}
            startAdornment={getStartAdornment(isLoading)}
            endAdornment={getEndAdornment(inputValue, handleClearInput, handleSearch)}
            placeholder={placeholder}
            inputProps={{ 'aria-label': 'search marketplace', autoComplete: 'off' }}
            value={inputValue}
            onFocus={expandDropdown}
            onChange={e => handleInputChange(e.target.value)}
            onKeyDown={e => {
              const newHighlightedListItemIndex = handleListNavigation(e.key);
              const highlightedListItemValue = filteredSearchHistoryList[newHighlightedListItemIndex ?? -1]?.text;

              // if enter, search
              const inputVal = (e.target as HTMLInputElement).value.trim();

              // try highlightedListItemValue first, if it is empty, try inputVal
              // otherwise, after first selection with the Enter button, it will always search with what's in the input
              if (e.key === 'Enter') handleSearch(highlightedListItemValue || inputVal);
              if (e.key === 'Escape') exitSearchBar();
            }}
          />
          {extraActions && (
            <>
              <Divider sx={{ height: 28, m: 0.5 }} orientation='vertical' />
              {extraActions}
            </>
          )}
        </SearchRow>
        <DropdownContent
          data-testid='marketplace-search-dropdown-content'
          aria-hidden={!dropdownExpanded}
          sx={{ opacity: dropdownExpanded ? 1 : 0, height: '100%', maxHeight: 360 }}
        >
          <Divider />
          {/* List and list item button elements are made not tabbable by tabIndex={-1} because list should only be focussed by pressing down button */}
          <List
            data-testid='marketplace-search-list'
            aria-hidden={!dropdownExpanded}
            sx={{
              mx: -1,
              height: `calc(100% - ${CLOSED_HEIGHT})`,
              visibility: !dropdownExpanded ? 'hidden' : 'visible',
              overflowY: 'scroll',
              mb: 1,
              pt: 0,
            }}
            tabIndex={-1}
            ref={listRef}
          >
            <ListSubheader sx={{ lineHeight: '36px' }}>Recent Searches</ListSubheader>
            {filteredSearchHistoryList.slice(0, MARKETPLACE_SHOWN_HISTORY_LIMIT - 1).map((search, i) => (
              <ListItemButton
                data-testid='marketplace-search-history-item'
                key={search.timestamp}
                onClick={() => handleHistoryClick(search.text)}
                sx={{ p: 0, px: 2 }}
                tabIndex={-1}
                selected={highlightedListItemIndex === i}
                onKeyDown={e => {
                  if (e.key === 'ArrowDown' || e.key === 'ArrowUp') return handleListNavigation(e.key);
                }}
              >
                <ListItemIcon>
                  <SearchIcon fontSize='small' sx={{ color: t => t.palette.text.secondary }} />
                </ListItemIcon>
                <ListItemText primary={search.text} />
              </ListItemButton>
            ))}
          </List>
        </DropdownContent>
      </SearchBarPaper>
    </ClickAwayListener>
  );
}

function getStartAdornment(loading?: boolean) {
  return (
    <InputAdornment position='start' sx={{ position: 'relative', height: '100%', ml: 0.5 }}>
      <>
        <SearchIcon fontSize='small' />
        {loading ? (
          <CircularProgress
            data-testid='marketplace-search-loading'
            size={28}
            sx={{
              position: 'absolute',
              top: -3,
              left: -4,
              opacity: loading ? 0.5 : 0,
              transition: OPACITY_ANIM,
            }}
          />
        ) : null}
      </>
    </InputAdornment>
  );
}

/**
 * A clear button that appears when there is text in the input
 * @param value text input value
 * @param clearInput a callback that clears the input state (will be called when the clear button is clicked)
 * @param handleSearch a callback that triggers the search with the current input value
 */
function getEndAdornment(value: string, clearInput: () => void, handleSearch: (value: string) => void) {
  return (
    <>
      <IconButton
        data-testid='marketplace-search-clear'
        size='small'
        tabIndex={value ? 0 : -1} // make clear button tabbable only when there is text in the input
        sx={{ opacity: value ? 1 : 0, transition: OPACITY_ANIM }}
        aria-label='Clear'
        onClick={clearInput}
      >
        <Clear fontSize='small' />
      </IconButton>
      <IconButton
        data-testid='marketplace-search-submit'
        size='small'
        tabIndex={value ? 0 : -1} // make clear button tabbable only when there is text in the input
        sx={{ opacity: value ? 1 : 0, transition: OPACITY_ANIM }}
        aria-label='Clear'
        onClick={() => handleSearch(value)}
      >
        <KeyboardReturn fontSize='small' />
      </IconButton>
    </>
  );
}
