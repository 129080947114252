import React from 'react';
import { Box, Skeleton } from '@mui/material';

export function LoadingSkeletonItem({ count, listContentWidth }: { count?: number; listContentWidth: string }) {
  const marginLeft = '24px';
  return !count
    ? null
    : new Array(count).fill(null).map((_, i) => (
        <Box
          data-testid='skeleton-item'
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          sx={{
            display: 'grid',
            alignItems: 'center',
            gridTemplateColumns: '40px 1fr',
            gap: 1,
            opacity: 1 - i / count, // to form a nice fade out effect as list goes down
          }}
        >
          <Skeleton variant='circular' height={24} width={24} sx={{ ml: marginLeft }} />
          <Box sx={{ position: 'relative', width: '100%', ml: marginLeft }}>
            <Box
              sx={{
                position: 'absolute',
                width: listContentWidth,
                height: '100%',
                top: 0,
                left: 0,
                padding: '12px',
                display: 'grid',
                gridTemplateColumns: '80px 1fr',
                gap: 2,
              }}
            >
              <Skeleton variant='rounded' width={80} height={80} sx={{ alignSelf: 'center' }} />
              <Skeleton variant='text' width='calc(100% - 88px)' height={24} />
            </Box>
            <Skeleton variant='rounded' width={listContentWidth} height={108} />
          </Box>
        </Box>
      ));
}
