import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const StatCard = styled(Card, {
  shouldForwardProp: prop => prop !== 'completed',
})<{ completed: boolean }>(({ theme, completed }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  backgroundColor: !completed ? theme.palette.surfaceContainer.main : theme.palette.successContainer.main,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: 'auto',
  height: 'auto',
}));

const CompletedStatus = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
});

type StudentDashboardCardProps = {
  lessonTitle: string | null;
  completed: boolean;
  dayStarted: string | null;
  misconceptionCount: number | null;
  misconceptionInstances: Array<{ id: number; name: string }> | null;
};

export const LessonInstanceCard: React.FC<StudentDashboardCardProps> = ({
  lessonTitle,
  completed = false,
  dayStarted,
  misconceptionCount,
  misconceptionInstances,
}) => (
  <StatCard completed={completed} data-testid='student-dashboard-card'>
    <CardContent>
      <Box>
        <Typography align='center' variant='titleLarge'>
          {lessonTitle}
        </Typography>
        <Box sx={{ height: '10px' }} />
        <CompletedStatus>
          <Typography>
            <b> Completed: </b>
          </Typography>
          {completed ? <CheckIcon fontSize='small' /> : <CloseIcon fontSize='small' />}
        </CompletedStatus>
        <Typography>
          <b>Start Date: </b> {dayStarted ? dayjs(dayStarted).format('MM/DD/YYYY') : 'N/A'}
        </Typography>
        <Typography>
          <b>Misconception Count: </b> {misconceptionCount}
        </Typography>
        <Typography>
          <b>Misconceptions: </b>
          {misconceptionInstances?.length ? (
            <i>
              {misconceptionInstances.map((misconception, index) => (
                <React.Fragment key={misconception.id}>
                  {index > 0 && ', '}
                  {misconception.name}
                </React.Fragment>
              ))}
            </i>
          ) : (
            'N/A'
          )}
        </Typography>
      </Box>
    </CardContent>
  </StatCard>
);
