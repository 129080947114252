import { FileConfig } from 'react-player/file';

export const getCaptionsConfig = (vttUrl: string, lang: 'en') => ({
  // the <track /> tag only works when the src attribute is a URL when the
  // enclosing <video /> tag has the crossOrigin attribute set to 'anonymous' (true for react-player)
  file: {
    attributes: {
      crossOrigin: 'anonymous',
      controlsList: 'nodownload',
    },
    tracks: [
      {
        kind: 'captions',
        src: vttUrl,
        label: 'caption',
        // mode: 'showing', leaving here as this is important. mode defaults to showing only when default is true
        default: true,
        srcLang: lang,
      },
    ],
  } as FileConfig,
});
