import React, { useState } from 'react';
import { Tab, Tabs, IconButton, Typography } from '@mui/material';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { useParams, useNavigate } from 'react-router-dom';
import { useClassroomQuery } from 'controllers/react-query/classroomHooks';
import {
  useGetLessonCollectionAssignments,
  useDeleteLessonCollectionAssignment,
} from 'controllers/react-query/assignmentHooks';
import { useStudentWorkBreakdown } from 'controllers/react-query/classroomMisconceptionReportHooks';
import { useTitle, useBreadcrumbs, useActions } from 'components/StudioLayout/StudioLayout';
import { MoreHoriz } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { KyronMenu, KyronMenuItem } from 'components/Studio/components/KyronMenu';
import { NavigateToPricingButton, usePaywall } from 'components/Pricing/PaymentContext';
import { StudentProgressView } from './StudentProgressView';
import { MisconceptionsView } from './MisconceptionsView';
import { Row } from '../Row/Row';
import { CopyButton } from '../CopyButton';
import { getLessonCollectionURL } from '../utils/urlUtils';
import { useModal } from '../utils/ModalContext';

enum TabOptions {
  PROGRESS,
  MISCONCEPTIONS,
}

export const AssignmentDetails = () => {
  const params = useParams();
  const { canShareCourse } = usePaywall();
  const {
    data: assignmentData,
    isFetching: isAssignmentFetching,
    isError: isAssignmentError,
  } = useGetLessonCollectionAssignments(params.classroomId, params.lessonCollectionId);
  const {
    data: classroomData,
    isFetching: isClassroomFetching,
    isError: isClassroomError,
  } = useClassroomQuery(params.classroomId);
  const { mutate: deleteLessonCollectionAssignment } = useDeleteLessonCollectionAssignment(params.classroomId);
  const {
    isFetching: isFetchingStudentWork,
    isError: isErrorStudentWork,
    data: studentWorkBreakdown,
  } = useStudentWorkBreakdown(params.lessonCollectionId, params.classroomId);
  const [currentTab, setCurrentTab] = useState(TabOptions.PROGRESS);
  const handleTabChange = (_: unknown, newValue: TabOptions) => {
    setCurrentTab(newValue);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { openModal } = useModal();
  const confirmDelete = (callback: () => void) => () => {
    openModal({
      id: 'delete-assignment-confirm',
      title: 'Delete Assignment',
      content: 'Are you sure you want to delete this assignment?',
      onConfirm: callback,
    });
  };

  const deleteAssignment = () => {
    deleteLessonCollectionAssignment(
      {
        classroomId: params.classroomId,
        lessonCollectionId: params.lessonCollectionId,
      },
      {
        onSuccess: () => {
          navigate(`/studio/classrooms/${params.classroomId}`);
        },
        onError: () => {
          enqueueSnackbar('Error deleting assignment', { variant: 'error' });
        },
      },
    );
  };

  const breadcrumbs = classroomData
    ? [
        { label: 'Classrooms', to: '/studio/classrooms' },
        { label: classroomData.name, to: `/studio/classrooms/${params.classroomId}` },
      ]
    : [{ label: 'Classrooms', to: '/studio/classrooms' }];

  useTitle(assignmentData ? assignmentData[0].name : '');
  useBreadcrumbs(breadcrumbs);

  const moreActionsButton = (
    <IconButton onClick={handleOpenMenu} aria-haspopup='true' data-testid='assignment-detail-menu'>
      <MoreHoriz />
    </IconButton>
  );

  useActions(moreActionsButton);

  const menuItems: KyronMenuItem[] = canShareCourse
    ? [
        {
          label: 'Preview Course',
          onClick: () => window.open(getLessonCollectionURL(params.lessonCollectionId), '_blank'),
          color: 'default',
        },
        {
          customComponent: (
            <CopyButton
              isMenuItem
              value={getLessonCollectionURL(params.lessonCollectionId, classroomData)}
              label='Copy Assignment Link'
              onAnimationEnd={handleCloseMenu}
            />
          ),
        },
        {
          label: 'Delete Assignment',
          onClick: confirmDelete(deleteAssignment),
          color: 'error',
        },
      ]
    : [
        {
          customComponent: (
            <NavigateToPricingButton variant='outlined'>Subscribe to assign to students</NavigateToPricingButton>
          ),
        },
        {
          label: 'Delete Assignment',
          onClick: confirmDelete(deleteAssignment),
          color: 'error',
        },
      ];

  return (
    <>
      <KyronMenu anchorEl={anchorEl} onClose={handleCloseMenu} items={menuItems} />

      {(isClassroomFetching || isAssignmentFetching) && (
        <Row justifyContent='center' width='100%'>
          <LoadingIndicator message='Loading...' />
        </Row>
      )}

      {(isClassroomError || isAssignmentError) && (
        <Row justifyContent='center' width='100%'>
          <Typography variant='bodyMedium'>There was an error loading this assignment.</Typography>
        </Row>
      )}

      {assignmentData && classroomData && (
        <>
          <Tabs value={currentTab} onChange={handleTabChange}>
            <Tab label='Student Progress' value={TabOptions.PROGRESS} />
            <Tab label='Misconceptions' value={TabOptions.MISCONCEPTIONS} />
          </Tabs>

          {currentTab === TabOptions.PROGRESS && (
            <StudentProgressView
              studentWorkBreakdown={studentWorkBreakdown}
              isFetching={isFetchingStudentWork}
              isError={isErrorStudentWork}
              assignmentName={assignmentData[0].name}
            />
          )}
          {currentTab === TabOptions.MISCONCEPTIONS && <MisconceptionsView />}
        </>
      )}
    </>
  );
};
