// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`KyronSimpleAudioPlayer KyronSimpleAudioPlayer renders 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animation-1 {
  0% {
    stroke-dasharray: 1px,200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -125px;
  }
}

.emotion-1 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  max-width: 100%;
  font-family: Open Sans;
  font-size: 0.8125rem;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 32px;
  color: #1C1B1F;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  white-space: nowrap;
  -webkit-transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: unset;
  outline: 0;
  -webkit-text-decoration: none;
  text-decoration: none;
  border: 0;
  padding: 0;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #bdbdbd;
}

.emotion-1::-moz-focus-inner {
  border-style: none;
}

.emotion-1.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-1 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-1.Mui-disabled {
  opacity: 0.38;
  pointer-events: none;
}

.emotion-1 .MuiChip-avatar {
  margin-left: 5px;
  margin-right: -6px;
  width: 24px;
  height: 24px;
  color: #616161;
  font-size: 0.75rem;
}

.emotion-1 .MuiChip-avatarColorPrimary {
  color: #FFFFFF;
  background-color: rgb(52, 37, 178);
}

.emotion-1 .MuiChip-avatarColorSecondary {
  color: #FFFFFF;
  background-color: rgb(111, 0, 135);
}

.emotion-1 .MuiChip-avatarSmall {
  margin-left: 4px;
  margin-right: -4px;
  width: 18px;
  height: 18px;
  font-size: 0.625rem;
}

.emotion-1 .MuiChip-icon {
  margin-left: 5px;
  margin-right: -6px;
  color: #616161;
}

.emotion-1 .MuiChip-deleteIcon {
  -webkit-tap-highlight-color: transparent;
  color: rgba(28, 27, 31, 0.26);
  font-size: 22px;
  cursor: pointer;
  margin: 0 5px 0 -6px;
}

.emotion-1 .MuiChip-deleteIcon:hover {
  color: rgba(28, 27, 31, 0.4);
}

.emotion-1:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-1.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.2);
}

.emotion-1:active {
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
}

.emotion-1.MuiChip-clickable:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.emotion-1.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-1 .MuiChip-avatar {
  margin-left: 4px;
}

.emotion-1 .MuiChip-avatarSmall {
  margin-left: 2px;
}

.emotion-1 .MuiChip-icon {
  margin-left: 4px;
}

.emotion-1 .MuiChip-iconSmall {
  margin-left: 2px;
}

.emotion-1 .MuiChip-deleteIcon {
  margin-right: 5px;
}

.emotion-1 .MuiChip-deleteIconSmall {
  margin-right: 3px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: 100%;
}

.emotion-3 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-4 {
  display: inline-block;
  color: #49474B;
  -webkit-animation: animation-0 1.4s linear infinite;
  animation: animation-0 1.4s linear infinite;
  position: absolute;
  left: 0;
  opacity: 0.8;
}

.emotion-5 {
  display: block;
}

.emotion-6 {
  stroke: currentColor;
  stroke-dasharray: 80px,200px;
  stroke-dashoffset: 0;
  -webkit-animation: animation-1 1.4s ease-in-out infinite;
  animation: animation-1 1.4s ease-in-out infinite;
}

.emotion-7 {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 11px;
  padding-right: 11px;
  white-space: nowrap;
}

<div
    class="MuiBox-root emotion-0"
  >
    <audio
      controls=""
      preload="metadata"
      style="display: none;"
    >
      <source
        src="test.mp3"
        type="audio/mp3"
      />
      <track
        kind="captions"
        label="English"
        srclang="en"
      />
    </audio>
    <div
      aria-disabled="true"
      class="MuiButtonBase-root Mui-disabled MuiChip-root MuiChip-outlined Mui-disabled MuiChip-sizeMedium MuiChip-colorDefault MuiChip-clickable MuiChip-clickableColorDefault MuiChip-outlinedDefault emotion-1"
      role="button"
      tabindex="-1"
    >
      <div
        class="MuiStack-root MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault emotion-2"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-3"
          data-testid="PlayArrowIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M8 5v14l11-7z"
          />
        </svg>
        <span
          class="MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorInfo emotion-4"
          role="progressbar"
          style="width: 24px; height: 24px;"
        >
          <svg
            class="MuiCircularProgress-svg emotion-5"
            viewBox="22 22 44 44"
          >
            <circle
              class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate emotion-6"
              cx="44"
              cy="44"
              fill="none"
              r="20.2"
              stroke-width="3.6"
            />
          </svg>
        </span>
      </div>
      <span
        class="MuiChip-label MuiChip-labelMedium emotion-7"
      >
        00:00
      </span>
    </div>
  </div>
</DocumentFragment>
`;
