import { CanvasCourseListing, CanvasCourse, Lesson } from 'controllers/types';
import { useKyronQuery } from './kyronQuery';
import { useKyronMutationV2 } from './kyronMutation';
import { GenerateQuestionsPayload } from './lessonGenerationHooks';

export const useCanvasCourses = () =>
  useKyronQuery<{ courses?: CanvasCourseListing[]; error?: string }>(`/canvas_courses`);

export const useCanvasCourse = (courseId: string) => useKyronQuery<CanvasCourse>(`/canvas_courses/${courseId}`);

export const useLinkCanvasCourseToLesson = () =>
  useKyronMutationV2<{ payload: { courseId: string; moduleId: string; lessonId: number } }, Lesson>(
    `/canvas_courses/link_lesson`,
    {
      method: 'POST',
      invalidates: data => [`/lessons/${data.payload.lessonId}`],
    },
  );

export const useRecommendedLessonValues = () =>
  useKyronMutationV2<{ courseId: string; moduleId: string }, GenerateQuestionsPayload>(
    `/canvas_courses/recommended_lesson_values?course_id=:courseId&module_id=:moduleId`,
    {
      method: 'GET',
    },
  );
