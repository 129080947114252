/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  IconButton,
} from '@mui/material';
import { ExpandMore, ArrowUpward, ArrowDownward } from '@mui/icons-material';

const styles = {
  cardContent: {
    boxShadow: 'none',
  },
};

export const LessonEdit = props => {
  const [lesson, setLesson] = useState(props.lesson);

  const handleInputChange = e => {
    const oldParentId = lesson.lesson_collection.id;
    const newParentId = e.target.value;
    lesson.lesson_collection.id = newParentId;
    setLesson(lesson);
    props.handleLessonUpdate(lesson, oldParentId, newParentId);
  };

  const handleRemoveLessonFromUnit = () => {
    const parentId = lesson.lesson_collection.id;
    lesson.lesson_collection_id = null;
    setLesson(lesson);
    props.handleLessonRemove(lesson, parentId);
  };

  const allUnitsAndCollections = () => {
    const allUnitAndCollectionList = [];
    props.units.forEach(unit => {
      allUnitAndCollectionList.push(
        <MenuItem key={unit.id} value={unit.id} disabled>
          {unit.name}
        </MenuItem>,
      );
      unit.lesson_collections?.forEach(collection => {
        allUnitAndCollectionList.push(
          <MenuItem sx={{ ml: 1 }} key={collection.id} value={collection.id}>
            {`- ${collection.name}`}
          </MenuItem>,
        );
      });
    });
    return allUnitAndCollectionList;
  };

  if (props.lesson.active) {
    return (
      <Card key={props.lesson.id} style={styles.cardContent}>
        <CardContent>
          <Stack direction='row' spacing={2} justifyContent='space-between'>
            <Stack direction='row' spacing={2} justifyContent='flex-start'>
              <Box display='flex' alignItems='center' xs={2}>
                <Stack direction='row'>
                  <IconButton
                    data-testid='move-lesson-up'
                    onClick={() => {
                      props.reorderLesson(props.lesson, props.lesson.lesson_collection.id, 'up');
                    }}
                  >
                    <ArrowUpward />
                  </IconButton>
                  <IconButton
                    data-testid='move-lesson-down'
                    onClick={() => {
                      props.reorderLesson(props.lesson, props.lesson.lesson_collection.id, 'down');
                    }}
                  >
                    <ArrowDownward />
                  </IconButton>
                </Stack>
              </Box>
              <Box display='flex' alignItems='center'>
                <Typography color='text.secondary' justify='center' data-testid='lesson-name'>
                  {`${props.lesson.name} (${props.lesson.tutor?.display_name})`}
                </Typography>
              </Box>
            </Stack>
            <Box maxWidth='40%'>
              <Accordion data-testid='more-options'>
                <AccordionSummary aria-controls='more-options' id='more-options-header' expandIcon={<ExpandMore />}>
                  <Typography color='text.secondary'>Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction='column' spacing={2}>
                    <FormControl fullWidth size='small'>
                      <InputLabel>Move Lesson</InputLabel>
                      <Select
                        labelId='move-lesson-select-label'
                        data-testid='move-lesson-select'
                        id='move-lesson-select'
                        name='lesson_collection_id'
                        label='Move Lesson'
                        displayEmpty
                        value=''
                        onChange={handleInputChange}
                      >
                        {allUnitsAndCollections()}
                      </Select>
                    </FormControl>
                    <Button
                      variant='contained'
                      data-testid='remove-lesson-from-unit'
                      onClick={e => {
                        e.preventDefault();
                        handleRemoveLessonFromUnit();
                      }}
                    >
                      Remove Lesson
                    </Button>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    );
  }
  return null;
};

/* eslint-disable react/forbid-prop-types */
LessonEdit.propTypes = {
  lesson: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    lesson_collection: PropTypes.object,
    active: PropTypes.bool,
    tutor: PropTypes.object,
  }),
  units: PropTypes.array,
  handleLessonUpdate: PropTypes.func,
  handleLessonRemove: PropTypes.func,
  reorderLesson: PropTypes.func,
};
