import React from 'react';
import { Menu, MenuItem, MenuItemProps } from '@mui/material';

export type KyronMenuItem =
  | { customComponent: JSX.Element }
  | (MenuItemProps & {
      label: string;
      color?: 'default' | 'error';
      to?: string;
      'data-method'?: string;
    });

const itemColorMap = {
  error: 'error.main',
  default: 'text.primary',
};

/**
 *
 * KyronMenu is a component that renders a menu with a list of items for Kyron Studio.
 * Usage example:
 * ```tsx
 * const menuItems = [
 *  {
 *   label: 'Unsync this classroom',
 *   onClick: handleDeleteClassroom,
 *   color: 'error',
 *  },
 * ];
 *
 * <KyronMenu anchorEl={anchorEl} onClose={handleCloseMenu} items={menuItems} />
 * ```
 */
export const KyronMenu = ({
  items,
  onClose,
  anchorEl,
}: {
  items: KyronMenuItem[];
  onClose: () => void;
  anchorEl: HTMLElement | null;
}) => {
  const renderMenuItem = (item: KyronMenuItem) => {
    if ('customComponent' in item) {
      return <MenuItem>{item.customComponent as JSX.Element}</MenuItem>;
    }
    const { label, color = 'default', ...menuProps } = item;
    return (
      <MenuItem sx={{ color: itemColorMap[color] }} {...menuProps} key={label}>
        {label}
      </MenuItem>
    );
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {items.map(item => renderMenuItem(item))}
    </Menu>
  );
};
