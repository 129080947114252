import { createContext, useContext } from 'react';
import { UserNotifications } from '../../channels/user_notifications';

export const AppContext = createContext({} as AppCtx);
export const useAppContext = () => useContext(AppContext);

export type AppState = {
  notifications: UserNotifications;
};

export type AppCtx = {
  appState: AppState;
  setAppState: (newState?: Partial<AppState> | ((prevState: AppState) => Partial<AppState>)) => void;
  notifications: AppState['notifications'];
};
