import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import { Stack, Card, CardHeader, CardContent, CardActions, IconButton } from '@mui/material';
import { Done, Cancel } from '@mui/icons-material';
import { ACCEPT_VIDEO_TYPES, FileUploader, UploadButton } from 'components/Uploaders/FileUploader';

import { CommonSegmentForm } from './CommonSegmentForm';
import { WaitSegmentForm } from './WaitSegmentForm';
import { StepSegmentForm } from './StepSegmentForm';
import { AdvancedOptionsForm } from './AdvancedOptionsForm';
import { SegmentEdgeEditor } from './SegmentEdgeEditor';

const styles = {
  LessonSegmentCard: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  LessonSegmentCardHeader: {
    textAlign: 'center',
  },
};

const getDefaultNewLessonSegment = (allSegmentNames, allMisconceptions) => ({
  name: '',
  description: '',
  segment_type: 'step',
  start_time: 0.0,
  end_time: 9999.0,
  next_segment: '',
  acceptable_inputs: [],
  misconceptions: [],
  event_to_send: '',
  wait_timeout: 0,
  video: null,
  dialogflow_page: '',
  question: '',
  prompt_selection: '',
  transcript: '',
  allAcceptableInputs: ['audio', 'text', 'multiple_choice', 'open_ended', 'equation'],
  edgeRows: [
    {
      id: shortid.generate(),
      target_segment_name: allSegmentNames[0],
      answer: '',
      training_phrases: [],
    },
  ],
  allSegmentNames,
  allMisconceptions,
  equation_display_input: [],
});

export const NewLessonSegment = ({ handleFormSubmit, handleExpand, lessonId, allMisconceptions, allSegmentNames }) => {
  const [state, setState] = useState({ ...getDefaultNewLessonSegment(allSegmentNames, allMisconceptions) });

  const resetState = () => {
    setState({ ...getDefaultNewLessonSegment(allSegmentNames, allMisconceptions) });
  };

  const handleInputChange = e => {
    const {
      target: { name, value, type, checked },
    } = e;
    setState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleTime = e => {
    const {
      target: { name, value },
    } = e;
    setState(prevState => ({
      ...prevState,
      [name]: value.replace(/\D/g, ''),
    }));
  };

  const handleDirectUploadFile = blobs => {
    setState(prevState => ({
      ...prevState,
      video: blobs[0].signed_id,
    }));
  };

  const setEdgeRows = v => {
    setState(prevState => ({
      ...prevState,
      edgeRows: v,
    }));
  };

  const cancelNewLessonSegment = () => {
    handleExpand();
  };

  const submitNewLessonSegment = e => {
    e.preventDefault();
    const lessonSegment = {
      lesson_id: lessonId,
      name: state.name,
      description: state.description,
      segment_type: state.segment_type,
      start_time: state.start_time,
      end_time: state.end_time,
      next_segment: state.next_segment,
      acceptable_inputs: state.acceptable_inputs,
      misconceptions: state.misconceptions,
      event_to_send: state.event_to_send,
      wait_timeout: state.wait_timeout,
      video: state.video,
      dialogflow_page: state.dialogflow_page,
      question: state.question,
      prompt_selection: state.prompt_selection,
      transcript: state.transcript,
      equation_display_input: state.equation_display_input,
    };
    // need to save the dialogflow information (training phrases, intents,
    // etc) to the db here once that's working but it needs to come after
    // the initial save because the segment id is needed to save the
    // dialogflow information but the segment id is not available until
    // the initial save is complete.
    handleFormSubmit(handleExpand, lessonSegment, state.segment_type === 'wait' ? state.edgeRows : []);
    resetState();
  };

  return (
    <Card sx={styles.LessonSegmentCard}>
      <CardHeader sx={styles.LessonSegmentCardHeader} title='Add new Lesson Segment' />
      <CardContent>
        <CommonSegmentForm
          state={state}
          handleInputChange={handleInputChange}
          handleTime={handleTime}
          editing={false}
        />
        <Stack direction='column' spacing={2}>
          {state.segment_type !== 'wait' && <StepSegmentForm state={state} handleInputChange={handleInputChange} />}
          {state.segment_type === 'wait' && (
            <>
              <WaitSegmentForm state={state} handleInputChange={handleInputChange} />
              <Card>
                <CardHeader title='Edge Editing' titleTypographyProps={{ variant: 'h8' }} />
                <CardContent>
                  <Stack direction='row'>
                    <SegmentEdgeEditor
                      allSegmentNames={state.allSegmentNames}
                      edgeRows={state.edgeRows}
                      setEdgeRows={setEdgeRows}
                      acceptableInputs={state.acceptable_inputs}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </>
          )}
        </Stack>
        <Stack direction='column' spacing={2}>
          <AdvancedOptionsForm state={state} handleInputChange={handleInputChange} handleTime={handleTime} />
        </Stack>
        <Stack direction='row'>
          <FileUploader
            fileTypeName='video'
            afterUpload={handleDirectUploadFile}
            acceptTypes={ACCEPT_VIDEO_TYPES}
            existingFile={state.video}
          >
            <UploadButton buttonLabel='Upload File' />
          </FileUploader>
        </Stack>
        <CardActions sx={styles.LessonSegmentCard}>
          <IconButton aria-label='submit new lesson segment' onClick={submitNewLessonSegment}>
            <Done />
          </IconButton>
          <IconButton aria-label='cancel new lesson segment' onClick={cancelNewLessonSegment}>
            <Cancel />
          </IconButton>
        </CardActions>
      </CardContent>
    </Card>
  );
};

/* eslint-disable react/forbid-prop-types */
NewLessonSegment.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleExpand: PropTypes.func.isRequired,
  lessonId: PropTypes.number.isRequired,
  allMisconceptions: PropTypes.array,
  allSegmentNames: PropTypes.array,
};
