import React from 'react';
import PropTypes from 'prop-types';
import { Select, FormControl, InputLabel } from '@mui/material';
import { useSnackbar } from 'notistack';

export const AddLessons = ({ collection, handleLessonAdd, unattachedLessonsList }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectLesson = e => {
    const l = e.target.value;
    if (!collection) {
      enqueueSnackbar('No collections available to add lesson', { variant: 'error' });
      return;
    }
    // First time adding lesson to the first collection of the unit
    const collectionId = collection.id;
    l.lesson_collection_id = collectionId;
    handleLessonAdd(l, collectionId);
  };

  return (
    <FormControl size='small' sx={{ minWidth: 200 }}>
      <InputLabel>Add a Lesson</InputLabel>
      <Select
        labelId='select-lesson'
        data-testid='select-lesson'
        id='select-lesson'
        label='Add a Lesson'
        value=''
        onChange={handleSelectLesson}
      >
        {unattachedLessonsList()}
      </Select>
    </FormControl>
  );
};

AddLessons.propTypes = {
  handleLessonAdd: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  collection: PropTypes.object,
  unattachedLessonsList: PropTypes.func,
};
