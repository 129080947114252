import { KyronClient } from './KyronClient';

export function logOut() {
  // TODO(SR): I keep getting intermitent errors with logging out. Sometimes it works,
  // sometimes it doesn't. Testing if the sendEvent is causing the issue.
  // KyronEvents.sendEvent(KyronEvents.names.LOGOUT);
  const client = new KyronClient();
  client.deleteData('/users/sign_out').then(() => {
    window.location.replace('/');
  });
}
