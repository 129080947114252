import React from 'react';
import { Box } from '@mui/material';
import { TableOfContentsItem } from 'components/Player/TableOfContents/TableOfContentsItem';
import { Lesson, TOCItem } from 'controllers/types';
import { TutorSwitcher } from 'components/Player/TableOfContents/TutorSwitcher';
import { PanelAreaContainer } from 'components/Player/PanelAreaContainer';

type Props = {
  tableOfContents: TOCItem[] | undefined;
  lesson: Lesson;
  handleTOCItemClick: (tableOfContent: TOCItem) => Promise<unknown>;
  changeLesson?: (id: number) => void;
  runningLessonSegmentId?: number;
};

export const TableOfContents = ({
  tableOfContents,
  lesson,
  handleTOCItemClick,
  changeLesson,
  runningLessonSegmentId,
}: Props) => {
  const sortedTableOfContents = tableOfContents?.sort((a, b) => a.position - b.position);

  const getChildren = (content: TOCItem) => {
    const children: TOCItem[] = [];

    if (!sortedTableOfContents) return children;

    const nextUnindentedTOC =
      sortedTableOfContents.find(toc => toc.position % 1 === 0 && toc.position > content.position) ||
      ({ position: 1000 } as TOCItem);
    sortedTableOfContents.forEach(toc => {
      if (toc.position > content.position && toc.position < nextUnindentedTOC?.position) {
        children.push(toc);
      }
    });
    return children;
  };

  const makeTOCItem = (item: TOCItem) => {
    if (item.position % 1 === 0) {
      return (
        <TableOfContentsItem
          key={item.id}
          tableOfContent={item}
          handleTOCClick={handleTOCItemClick}
          runningLessonSegmentId={runningLessonSegmentId}
        >
          {getChildren(item)}
        </TableOfContentsItem>
      );
    }
  };

  return !sortedTableOfContents ? null : (
    <PanelAreaContainer
      title={lesson?.name}
      Body={!sortedTableOfContents ? null : <Box>{sortedTableOfContents.map(item => makeTOCItem(item))}</Box>}
      Footer={
        !lesson || !lesson.tutor || !lesson.lesson_collection ? null : (
          <TutorSwitcher
            lessonId={lesson.id}
            collectionId={lesson.lesson_collection.id}
            teacher={lesson.tutor}
            changeLesson={changeLesson}
          />
        )
      }
    />
  );
};
