import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useUpdateSegment } from 'controllers/react-query';
import { LessonSegment, SegmentMedia } from 'controllers/types';
import { KyronEvents } from 'components/utils/KyronEvents';
import { SegmentMultipleChoice } from '../components/SegmentMultipleChoice';
import { SegmentField } from '../components/SegmentField';
import { isUsingSegmentDescription, getTooltipNote } from '../helpers';
import { devConsole } from '../../utils/logUtils';

type SegmentFieldsProps = {
  segment: LessonSegment;
  isLoading?: boolean;
  selectedMediaType: SegmentMedia;
};

export const StepSegmentFields = ({ segment, isLoading = false, selectedMediaType }: SegmentFieldsProps) => {
  const updateSegmentMutation = useUpdateSegment({ segmentId: segment.id });
  const isParentSegmentInProgress = isLoading || updateSegmentMutation.isPending;

  const [description, setDescription] = useState(segment.description);
  useEffect(updateStateWhenSegmentChanges, [segment, segment.id]);
  function updateStateWhenSegmentChanges() {
    setDescription(segment.description);
  }

  async function handleSaveDescription(param: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = param.target.value.trim();
    if (!value || value === segment.description) return;
    KyronEvents.sendEvent(KyronEvents.names.EDIT_STEP_TEXT, { segment_id: segment?.id });
    await updateSegmentMutation.mutateAsync({
      lessonId: segment.lesson_id,
      payload: { ...segment, description: value },
    });
  }

  return (
    <SegmentField
      title='Video Transcript'
      info=''
      value={description}
      onChange={e => setDescription(e.target.value)}
      onBlur={handleSaveDescription}
      disabled={isParentSegmentInProgress || !isUsingSegmentDescription(selectedMediaType)}
      tooltipNote={getTooltipNote(selectedMediaType, isParentSegmentInProgress, true)}
    />
  );
};

export const WaitSegmentFields = ({ segment, isLoading = false, selectedMediaType }: SegmentFieldsProps) => {
  const updateSegmentMutation = useUpdateSegment({ segmentId: segment.id });
  const isParentSegmentInProgress = isLoading || updateSegmentMutation.isPending;

  const [question, setQuestion] = useState(segment.question);
  const [answer, setAnswer] = useState(segment.answer);

  useEffect(updateStateOnSegmentChange, [segment, segment.id]);
  function updateStateOnSegmentChange() {
    setQuestion(segment.question);
    setAnswer(segment.answer);
  }

  const isMultipleChoice = Boolean(segment?.multiplechoices?.length);
  const answerWarning =
    segment?.label !== 'conversation' && segment?.label !== 'exploratory' ? '' : segment?.answer_warning;

  async function handleSaveQuestion(param: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = param.target.value.trim();
    if (!value || value === segment.question) return;
    KyronEvents.sendEvent(KyronEvents.names.EDIT_QUESTION_TEXT, { segment_id: segment?.id });
    await updateSegmentMutation.mutateAsync({
      lessonId: segment.lesson_id,
      payload: { ...segment, question: value },
    });
  }

  async function handleSaveAnswer(param: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const value = param.target.value.trim();
    if (!value || value === segment.answer) return;
    KyronEvents.sendEvent(KyronEvents.names.EDIT_ANSWER_TEXT, { segment_id: segment?.id });
    const resp = await updateSegmentMutation.mutateAsync({
      lessonId: segment.lesson_id,
      payload: { ...segment, answer: value },
    });
    // this warning only applies to guided discussion ATM
    if (segment.label !== 'conversation' && segment.label !== 'exploratory') return;
    if (resp.answer_warning?.length === 0) return;
    devConsole.debug('handleSaveAnswer has warnings');
  }

  return (
    <Stack gap={2}>
      <SegmentField
        title='Question'
        info=''
        value={question}
        onChange={e => setQuestion(e.target.value)}
        onBlur={handleSaveQuestion}
        disabled={isParentSegmentInProgress}
        tooltipNote={getTooltipNote(selectedMediaType, isParentSegmentInProgress, false)}
      />

      {isMultipleChoice ? (
        <SegmentMultipleChoice waitSegment={segment} />
      ) : (
        <SegmentField
          title='Answer'
          info=''
          value={answer}
          onChange={e => setAnswer(e.target.value)}
          onBlur={handleSaveAnswer}
          disabled={isParentSegmentInProgress}
          tooltipNote={getTooltipNote(selectedMediaType, isParentSegmentInProgress, false)}
          warning={answerWarning}
        />
      )}
    </Stack>
  );
};
