import React from 'react';
import { Box, Alert, AlertTitle, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Error } from '@mui/icons-material';
import { KyronTooltip } from './KyronTooltip';

const StyledDiv = styled(Box)(() => ({
  maxWidth: 500,
}));

export function ErrorIndicator({
  error,
  description,
  severity = 'error',
}: {
  error: Error | null;
  description?: string;
  severity?: 'error' | 'warning';
}) {
  if (!error) return null;
  return (
    <Box display='flex' justifyContent='center' alignItems='center' data-testid='error-indicator'>
      <KyronTooltip
        components={{ Tooltip: StyledDiv }}
        title={
          <Alert severity={severity} sx={{ m: 2 }}>
            <AlertTitle>{error.message}</AlertTitle>
            {description ? (
              <Stack>
                <Typography>{description}</Typography>
              </Stack>
            ) : null}
          </Alert>
        }
        inactive={!error.message}
      >
        <Error color={severity} />
      </KyronTooltip>
    </Box>
  );
}
