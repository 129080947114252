import React from 'react';
import { Stack, Box, Skeleton } from '@mui/material';
import { Row } from 'components/Row/Row';
import { AddSectionDialog } from './AddToLesson/AddSectionDialog';

export function SectionListSkeleton() {
  return (
    <Stack gap={4} sx={{ opacity: 0.8 }}>
      <Stack gap={1}>
        <Skeleton variant='rectangular' height={20} width='80%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
      </Stack>
      <Stack gap={1}>
        <Skeleton variant='rectangular' height={20} width='80%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
      </Stack>
      <Stack gap={1}>
        <Skeleton variant='rectangular' height={20} width='80%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
        <Skeleton variant='rectangular' height={20} width='30%' />
      </Stack>
    </Stack>
  );
}

export function SegmentDetailSkeleton() {
  return (
    <Stack gap={3} sx={{ alignItems: 'stretch', flex: 1, padding: 2 }} data-testid='segment-detail-skeleton'>
      {/* Header and Title */}
      <Stack>
        <Skeleton variant='text' width='50%' />
        <Skeleton variant='text' height={40} width='40%' />
      </Stack>

      <Stack direction={{ xs: 'column', sm: 'row' }} gap={{ xs: 4, md: 2 }} sx={{ alignItems: 'stretch' }}>
        {/* Video preview */}
        <Box
          sx={{
            position: 'relative',
            flex: '1',
            aspectRatio: '16/9',
            borderRadius: 3,
            overflow: 'hidden',
            minWidth: '200px',
          }}
        >
          <Skeleton variant='rectangular' sx={{ borderRadius: 1, height: 'auto', aspectRatio: '1280/720' }} />
        </Box>

        {/* Media selection */}
        <Stack gap={2} sx={{ flex: '0 0 200px', width: '200px' }}>
          <Row gap={2} alignItems='center'>
            <Skeleton variant='circular' width={30} height={30} />
            <Skeleton variant='text' width='40%' />
          </Row>
          <Row gap={2} alignItems='center'>
            <Skeleton variant='circular' width={30} height={30} />
            <Skeleton variant='text' width='40%' />
          </Row>
          <Row gap={2} alignItems='center'>
            <Skeleton variant='circular' width={30} height={30} />
            <Skeleton variant='text' width='40%' />
          </Row>
        </Stack>
      </Stack>

      {/* Segment Detail fields */}
      <Stack gap={2} sx={{ alignItems: 'stretch' }}>
        <Box>
          <Skeleton variant='text' width='40%' />
          <Skeleton variant='text' width='80%' />
          <Skeleton variant='text' width='80%' />
          <Skeleton variant='text' width='80%' />
          <Skeleton variant='text' width='80%' />
        </Box>
      </Stack>
    </Stack>
  );
}

export function EditorSkeleton() {
  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} gap={8}>
      <Stack flex='0 0 280px' gap={4} position={{ xs: 'static', sm: 'sticky' }} top={64} height='100%'>
        <SectionListSkeleton />
        <Box>
          <AddSectionDialog disabled />
        </Box>
      </Stack>
      <SegmentDetailSkeleton />
    </Stack>
  );
}
