import { Box, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import { KyronTooltip } from '../../KyronTooltip';

export const SlideImageIngestionDetailTooltip = () => (
  <KyronTooltip
    htmlContent
    placement='top'
    title={
      <Box>
        <Typography variant='labelMedium'>Do I need to upload images?</Typography>
        <Typography variant='bodySmall'>
          No. Simply explaining your audience and objectives is sufficient for generating quality courses.
        </Typography>

        <Typography variant='labelMedium'>What happens when I upload images?</Typography>
        <Typography variant='bodySmall'>
          Our course generator will look at and attempt to understand the content of your images. If it detects than an
          image is appropriate to what is being taught in a specific lecture video, the image will be added to that
          video.
        </Typography>
      </Box>
    }
  >
    <HelpOutlineIcon />
  </KyronTooltip>
);
