// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`Lesson Student AI Feedback Renders unselected thumbsdown button 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
  color: #1C1B1FB3;
}

.emotion-0::-moz-focus-inner {
  border-style: none;
}

.emotion-0.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-0 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-0:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-0:hover {
    background-color: transparent;
  }
}

.emotion-0.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-1 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: inherit;
}

.emotion-2 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<button
    aria-label="bad-response"
    class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-0"
    data-testid="thumbsdown"
    tabindex="0"
    type="button"
  >
    <svg
      aria-hidden="true"
      aria-pressed="false"
      class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit emotion-1"
      data-testid="ThumbDownOutlinedIcon"
      focusable="false"
      viewBox="0 0 24 24"
    >
      <path
        d="M15 3H6c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v2c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06L9.83 23l6.59-6.59c.36-.36.58-.86.58-1.41V5c0-1.1-.9-2-2-2m0 12-4.34 4.34L12 14H3v-2l3-7h9zm4-12h4v12h-4z"
      />
    </svg>
    <span
      class="MuiTouchRipple-root emotion-2"
    />
  </button>
</DocumentFragment>
`;
