import React, { useContext } from 'react';
import { Typography, Alert, Stack } from '@mui/material';
import { ManagePaymentsButton } from 'components/ManagePaymentsButton';
import { useSubscriptionsQuery } from 'controllers/react-query/subscriptionHooks';
import { LoadingIndicator } from 'components/LoadingIndicator';
import dayjs from 'dayjs';
import { UserContext } from 'components/UserContext';
import { NavigateToPricingButton } from 'components/Pricing/PaymentContext';
import { SettingsNavigation } from './SettingsNavigation';
import { AdminBillingForm } from './AdminBillingForm';

export function BillingSettings() {
  const { isFetching, data } = useSubscriptionsQuery();
  const { isOrgAdmin, isFinanceOps } = useContext(UserContext);
  const failedSubscriptions = data?.filter(sub => sub.fulfillment_status === 'failed');
  const activeSubscription = data?.find(
    sub => sub.fulfillment_status === 'fulfilled' && dayjs(sub.expires_at).isAfter(dayjs()),
  );
  const isStripeSubscription = activeSubscription && activeSubscription.stripe_customer_id !== null;

  const failedSubscriptionAlert = failedSubscriptions?.length ? (
    <Alert severity='error' sx={{ mb: 3 }}>
      Your payment failed to be processed correctly by Kyron. Please reach out to{' '}
      <a href='mailto:support@kyronlearning.com'>support@kyronlearning.com</a> for assistance.
    </Alert>
  ) : null;

  return (
    <>
      <SettingsNavigation />
      <Typography variant='headlineMedium' mb={3}>
        Billing Settings
      </Typography>
      {isOrgAdmin && (
        <>
          <LoadingIndicator loading={isFetching} message='Loading your subscription information...' />
          {failedSubscriptionAlert}
          {activeSubscription ? (
            <Stack spacing={1}>
              <Typography>You currently have an active subscription with Kyron Learning.</Typography>
              <Typography>Number of seats: {activeSubscription.number_of_seats}</Typography>
              <Typography>Current period end: {dayjs(activeSubscription.expires_at).format('MMMM D, YYYY')}</Typography>
              {isStripeSubscription && <ManagePaymentsButton />}
            </Stack>
          ) : (
            <Stack spacing={1}>
              <Typography>You do not currently have an active subscription with Kyron Learning.</Typography>
              <NavigateToPricingButton sx={{ width: 'fit-content' }}>View Pricing to Subscribe</NavigateToPricingButton>
            </Stack>
          )}
        </>
      )}
      {isFinanceOps && isStripeSubscription && (
        <Alert severity='info'>This subscription is managed through Stripe, and is not editable in our app.</Alert>
      )}
      {isFinanceOps && !isStripeSubscription && <AdminBillingForm activeSubscription={activeSubscription} />}
    </>
  );
}
