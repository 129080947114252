import { EquationData } from 'components/Equation/types';

interface AllowedProps {
  answer_json: EquationData[];
  question_json: EquationData[];
  equation_display_input: EquationData[];
}

// This closured method will be used with the corresponding edge row or segment.
// It is executed when the answer_json or question_json is updated using the SimpleEquationBuilder.
export function handleJsonChange(jsonObject: AllowedProps, attribute: keyof AllowedProps) {
  const object = jsonObject;

  return function fnToHandleUpdatedValue(updatedJsonValue: EquationData[]) {
    if (object) {
      object[attribute] = updatedJsonValue;
    }
  };
}
