import React, { useState, useEffect, useMemo } from 'react';

import { Button, Card, CardContent, CardActions, Collapse, Stack } from '@mui/material';
import { Add } from '@mui/icons-material';

import { useSnackbar } from 'notistack';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import {
  getLesson,
  getLessonBlob,
  getLessonDetails,
  finalizeLesson,
  publishLesson,
  updateLesson,
  deleteLesson,
} from 'controllers/lessons';
import { Lesson, LessonDetails, Tutor, LessonSegment, Edge, Misconception } from 'controllers/types';
import { getAllMisconceptions } from 'controllers/misconceptions';
import { getAllTutors } from 'controllers/tutors';
import { useCreateLessonSegment } from 'controllers/react-query';
import { NewLessonSegment } from 'components/LessonSegmentEditor/NewLessonSegment';

import { useBreadcrumbs, useTitle } from 'components/StudioLayout/StudioLayout';
import { JobProgressBar } from '../BackgroundTasks/JobProgressBar';
import { withDeleteModal } from '../utils/DeleteModalHOC';
import { LessonEditorDetails } from './LessonEditorDetails';
import { LessonForm } from './LessonForm';
import { LessonSegmentsShow } from '../LessonSegmentEditor/LessonSegmentsShow';

export async function loader({ params }: LoaderFunctionArgs): Promise<{
  initialLesson: Lesson;
  allTutors: Tutor[];
  misconceptions: Misconception[];
  details: LessonDetails;
}> {
  if (!params.lessonId) return Promise.reject(new Error('No lesson id provided'));
  const lessonId = Number(params.lessonId);
  if (!lessonId) return Promise.reject(new Error('Invalid lesson id provided'));
  const [initialLesson, allTutors, misconceptions, details] = await Promise.all([
    getLesson(lessonId),
    getAllTutors(),
    getAllMisconceptions(),
    getLessonDetails(lessonId),
  ]);
  return { initialLesson, allTutors, misconceptions, details };
}

type Props = {
  lesson: Lesson;
  handleLessonDelete: (id: number) => void;
};

const LessonEditorDetailsDM = withDeleteModal(LessonEditorDetails, (props: Props) =>
  props.handleLessonDelete(props.lesson.id),
);

const styles = {
  lessonCard: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export function LessonEdit() {
  const { enqueueSnackbar } = useSnackbar();
  const { initialLesson, allTutors, misconceptions, details } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const {
    lesson_segment_names_ids: segmentNamesIds,
    lesson_segment_names: allSegmentNames,
    table_of_contents: tableOfContents,
  } = details;
  const { mutate: createLessonSegment } = useCreateLessonSegment();

  const [newSegmentExpanded, setNewSegmentExpanded] = useState<boolean>(false);
  const [editable, setEditable] = useState<boolean>(false);
  const [currentLesson, setCurrentLesson] = useState<Lesson>(initialLesson);

  useEffect(() => {
    setCurrentLesson({ ...currentLesson, table_of_contents: tableOfContents });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableOfContents]);

  const handleLessonPublish = async (lessonId: number) => {
    enqueueSnackbar('Publishing lesson (this will take a few seconds)...');
    try {
      const updatedLesson = await publishLesson(lessonId);
      enqueueSnackbar('Published lesson to students!');
      setCurrentLesson({ ...currentLesson, ...updatedLesson });
    } catch (e) {
      const err = e as Error | null;
      enqueueSnackbar(err?.message, { variant: 'error' });
    }
  };

  const handleLessonUpdate = async (lesson: Lesson) => {
    try {
      const updatedLesson = await updateLesson(lesson);
      enqueueSnackbar(`Updated lesson ${updatedLesson.name}.`);
      return updatedLesson;
    } catch (e) {
      const err = e as Error | null;
      enqueueSnackbar(err?.message, { variant: 'error' });
    }
  };

  const handleLessonDelete = async (lessonId: number) => {
    try {
      await deleteLesson(lessonId);
      enqueueSnackbar('Lesson Deleted.');
    } catch (e) {
      const err = e as Error | null;
      enqueueSnackbar(err?.message, { variant: 'error' });
    }
  };

  const handleLessonExport = async (lessonId: number) => {
    try {
      const { fileName, blob } = await getLessonBlob(lessonId);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      const err = e as Error | null;
      enqueueSnackbar(err?.message, { variant: 'error' });
    }
  };

  const handleNewSegmentExpandClick = () => {
    setNewSegmentExpanded(!newSegmentExpanded);
  };

  const handleLessonEdit = () => {
    setEditable(true);
  };

  const handleLessonEditCancel = () => {
    setEditable(false);
  };

  const handleLessonFinalize = async () => {
    try {
      const finalizedLesson = await finalizeLesson(currentLesson.id);
      setCurrentLesson(finalizedLesson);
      enqueueSnackbar('Finalizing lesson video. This may take a few minutes.');
    } catch (e) {
      const err = e as Error | null;
      enqueueSnackbar(err?.message, { variant: 'error' });
    }
  };

  const handleNewLessonSegmentFormSubmit = (
    handleSegmentClick: () => void,
    lessonSegment: LessonSegment,
    edgeRows: Edge[],
  ) => {
    createLessonSegment(
      { lessonId: currentLesson.id, payload: { ...lessonSegment, lesson_id: currentLesson.id, edges: edgeRows } },
      {
        onSuccess: () => {
          handleSegmentClick();
        },
        onError: err => {
          enqueueSnackbar(err?.message, { variant: 'error' });
        },
      },
    );
  };

  const optimisticUpdateLesson = async (locallyUpdatedLesson: Lesson) => {
    setCurrentLesson({ ...currentLesson, ...locallyUpdatedLesson });
    const serverUpdatedLesson = await handleLessonUpdate(locallyUpdatedLesson);
    if (!serverUpdatedLesson) return null;
    setCurrentLesson(serverUpdatedLesson);
    setEditable(false);
  };

  const backgroundTasks = currentLesson.background_tasks.filter(task => task.broadcast);

  useTitle(currentLesson.name);
  useBreadcrumbs(useMemo(() => [{ to: '/studio/internal_lesson_editor', label: 'Internal Lesson Editor' }], []));

  return (
    <div data-testid='lesson-edit'>
      <Stack gap={1}>
        {backgroundTasks.map(task => (
          <JobProgressBar task={task} key={task.id} />
        ))}
      </Stack>
      <Card sx={styles.lessonCard}>
        {editable ? (
          <LessonForm
            handleFormSubmit={optimisticUpdateLesson}
            handleExpand={handleLessonEditCancel}
            lesson={currentLesson}
            showTOC
            segmentNamesIds={segmentNamesIds}
            allTutors={allTutors}
          />
        ) : (
          <LessonEditorDetailsDM
            lesson={currentLesson}
            handleLessonEdit={handleLessonEdit}
            handleLessonExport={() => handleLessonExport(currentLesson.id)}
            handleLessonDelete={handleLessonDelete}
            handleLessonFinalize={handleLessonFinalize}
            handleLessonPublish={() => handleLessonPublish(currentLesson.id)}
            hideEditLessonPageButton
          />
        )}
        <CardActions>
          <Button variant='contained' onClick={handleNewSegmentExpandClick} startIcon={<Add />}>
            Add Segment
          </Button>
        </CardActions>
        <Collapse in={newSegmentExpanded} timeout='auto' mountOnEnter>
          <div style={{ background: 'white' }} data-testid='lesson-edit-segments'>
            <NewLessonSegment
              lessonId={currentLesson.id}
              handleExpand={handleNewSegmentExpandClick}
              handleFormSubmit={handleNewLessonSegmentFormSubmit}
              allMisconceptions={misconceptions}
              allSegmentNames={allSegmentNames}
            />
          </div>
        </Collapse>
        <CardContent>
          <LessonSegmentsShow lessonId={currentLesson.id} allMisconceptions={misconceptions} />
        </CardContent>
      </Card>
    </div>
  );
}
