// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`ViewLessonCard renders the lesson card 1`] = `
<DocumentFragment>
  .emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
}

.emotion-1 {
  padding: 16px;
}

.emotion-1:last-child {
  padding-bottom: 24px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 32px;
}

.emotion-3 {
  min-width: 200px;
  max-width: 200px;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
}

.emotion-4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.emotion-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.emotion-6 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.emotion-7 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #9F00C2;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 10px 12px;
}

.emotion-7::-moz-focus-inner {
  border-style: none;
}

.emotion-7.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-7 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-7:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(159, 0, 194, 0.04);
}

@media (hover: none) {
  .emotion-7:hover {
    background-color: transparent;
  }
}

.emotion-7.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-7:hover {
  box-shadow: none;
}

.emotion-7.Mui-focusVisible {
  box-shadow: none;
}

.emotion-7:active {
  box-shadow: none;
}

.emotion-7.Mui-disabled {
  box-shadow: none;
}

.emotion-8 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-8>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-9 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-10 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-11 {
  margin: 0;
}

.emotion-12 {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.emotion-13 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.emotion-14 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(75, 53, 255, 0.5);
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-14::-moz-focus-inner {
  border-style: none;
}

.emotion-14.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-14 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-14:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
  border: 1px solid #4B35FF;
}

@media (hover: none) {
  .emotion-14:hover {
    background-color: transparent;
  }
}

.emotion-14.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-14:hover {
  box-shadow: none;
}

.emotion-14.Mui-focusVisible {
  box-shadow: none;
}

.emotion-14:active {
  box-shadow: none;
}

.emotion-14.Mui-disabled {
  box-shadow: none;
}

.emotion-18 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-18::-moz-focus-inner {
  border-style: none;
}

.emotion-18.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-18 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-18:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-18:hover {
    background-color: #4B35FF;
  }
}

.emotion-18:active {
  box-shadow: none;
}

.emotion-18.Mui-focusVisible {
  box-shadow: none;
}

.emotion-18.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-18:hover {
  box-shadow: none;
}

.emotion-18.Mui-focusVisible {
  box-shadow: none;
}

.emotion-18:active {
  box-shadow: none;
}

.emotion-18.Mui-disabled {
  box-shadow: none;
}

<div
    class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-0"
  >
    <div
      class="MuiCardContent-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <div
          class="MuiBox-root emotion-3"
          h="200"
          w="200"
        >
          <img
            alt="lesson collection thumbnail"
            data-testid="thumbnail"
            src="https://example.com/test-thumbnail.jpg"
            style="object-fit: fill; height: inherit; width: 100%;"
          />
        </div>
        <div
          class="MuiStack-root emotion-4"
        >
          <div
            class="MuiStack-root emotion-5"
          >
            <h2
              class="MuiTypography-root MuiTypography-titleLarge emotion-6"
            >
              Test Lesson
            </h2>
            <a
              class="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textSecondary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorSecondary MuiButton-disableElevation MuiButton-root MuiButton-text MuiButton-textSecondary MuiButton-sizeMedium MuiButton-textSizeMedium MuiButton-colorSecondary MuiButton-disableElevation emotion-7"
              data-testid="lesson-edit-button"
              href="/studio/courses/1"
              tabindex="0"
            >
              <span
                class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-8"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                  data-testid="EditIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"
                  />
                </svg>
              </span>
              Edit
              <span
                class="MuiTouchRipple-root emotion-10"
              />
            </a>
          </div>
          <p
            class="MuiTypography-root MuiTypography-body1 emotion-11"
          >
            This is a test lesson
          </p>
          <div
            class="MuiBox-root emotion-12"
          >
            <div
              class="MuiStack-root emotion-13"
            >
              <button
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-14"
                data-testid="lesson-copy-link-button"
                tabindex="0"
                type="button"
              >
                <span
                  class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-8"
                >
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                    data-testid="ContentCopyIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2m0 16H8V7h11z"
                    />
                  </svg>
                </span>
                Copy Lesson Link
                <span
                  class="MuiTouchRipple-root emotion-10"
                />
              </button>
              <a
                class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-18"
                data-testid="lesson-start-button"
                href="/video_player/1"
                tabindex="0"
                target="_blank"
              >
                <span
                  class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-8"
                >
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                    data-testid="PlayCircleOutlineIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="m10 16.5 6-4.5-6-4.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8"
                    />
                  </svg>
                </span>
                Start Lesson
                <span
                  class="MuiTouchRipple-root emotion-10"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
