import { useBreadcrumbs, useTitle } from 'components/StudioLayout/StudioLayout';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlaylistPayload, useCreatePlaylist } from 'controllers/react-query/playlistsHooks';
import { useSnackbar } from 'notistack';
import { PlaylistForm } from './PlaylistForm';

export const PlaylistNew = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate: createPlaylist } = useCreatePlaylist();

  useTitle('New playlist');
  useBreadcrumbs(useMemo(() => [{ to: '/studio/playlists', label: 'Playlists' }], []));

  const onSubmit = (playlistPayload: PlaylistPayload) => {
    createPlaylist(
      { payload: playlistPayload },
      {
        onSuccess: newPlaylist => {
          enqueueSnackbar(`${newPlaylist.name} was successfully created`, { variant: 'success' });
          navigate(`/studio/playlists`);
        },
        onError: error => {
          enqueueSnackbar(`Failed to create playlist: ${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  return <PlaylistForm onSubmit={onSubmit} />;
};
