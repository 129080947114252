import React from 'react';
import { Tabs, Tab, Stack, Button, Theme, useMediaQuery } from '@mui/material';
import { Add, CollectionsBookmarkOutlined } from '@mui/icons-material';
import { Link, Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { Features, useFeatures } from 'components/FeaturesContext';
import { KyronMenu } from 'components/KyronMenu';
import { KyronMenuItem } from 'components/KyronMenuItem';
import { KyronEvents } from 'components/utils/KyronEvents';

const title = (
  <>
    <CollectionsBookmarkOutlined /> Courses
  </>
);

function getActions(features: Features) {
  const { show_playlist: showPlaylist } = features;
  if (!showPlaylist) {
    return (
      <Button
        to='/create'
        component={Link}
        startIcon={<Add />}
        onClick={() => KyronEvents.sendEvent(KyronEvents.names.OPEN_COURSE_CREATOR)}
      >
        Create new course
      </Button>
    );
  }

  return (
    <KyronMenu menuIcon={<Button startIcon={<Add />}>Create</Button>}>
      <KyronMenuItem label='New Course' component={Link} to='/create' />
      {showPlaylist && <KyronMenuItem label='New Playlist' component={Link} to='/studio/playlists/new' />}
    </KyronMenu>
  );
}

type HeaderContextType = {
  setTitle: (title: string) => void;
  setActions: (actions: React.ReactNode) => void;
};

export function LibraryLayout() {
  const { pathname } = useLocation();
  const pathRoot = '/studio';
  const { setTitle, setActions } = useOutletContext<HeaderContextType>();
  const { showPlaylist } = useFeatures();

  if (showPlaylist) {
    return (
      <Stack gap={4} mt={3}>
        <Tabs value={pathname}>
          <Tab label='Courses' to='courses' value={`${pathRoot}/courses`} component={Link} replace />
          <Tab
            label='Playlists'
            to='playlists'
            value={`${pathRoot}/playlists`}
            component={Link}
            replace
            disabled={!showPlaylist}
          />
        </Tabs>
        <Outlet context={{ setTitle, setActions }} />
      </Stack>
    );
  }

  return <Outlet context={{ setTitle, setActions }} />;
}

export const useHeader = () => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  useTitle(isMobile ? null : title);
  useActions(getActions(useFeatures()));
};
