import React from 'react';
import { styled, keyframes } from '@mui/material/styles';

const bounce = keyframes`
  0%, 80%, 100% { transform: scale(0.6); }
  40% { transform: scale(1.0); }
`;

const Dot = styled('div')<{
  size: number;
  duration: number;
  delay: string;
  color: string;
}>(({ size, duration, delay, color }) => ({
  width: size,
  height: size,
  backgroundColor: color,
  borderRadius: '50%',
  display: 'inline-block',
  animation: `${bounce} ${duration}ms infinite ease-in-out both`,
  animationDelay: delay,
}));

const paceToDuration = (pace: string) => {
  switch (pace) {
    case 'slow':
      return 7500;
    case 'medium':
      return 5000;
    case 'fast':
      return 2500;
    default:
      return 7500;
  }
};

export const AnimatedThreeDots: React.FC<{ size?: number; pace?: string; color?: string }> = ({
  size = 25,
  pace = 'fast',
  color = '#e0cdbd',
}) => (
  <div data-testid='ai-thinking-indicator'>
    <Dot size={size} duration={paceToDuration(pace)} delay='0ms' color={color} />
    <Dot size={size} duration={paceToDuration(pace)} delay={`${paceToDuration(pace) / 3}ms`} color={color} />
    <Dot size={size} duration={paceToDuration(pace)} delay={`${(2 * paceToDuration(pace)) / 3}ms`} color={color} />
  </div>
);
