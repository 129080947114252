import React, { useState, useRef } from 'react';
import {
  IconButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Tooltip,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import {
  CloudDownload,
  Delete,
  Edit,
  FactCheck,
  MoreVert,
  Publish,
  Receipt,
  PlayArrow,
  Preview,
  VideoSettings,
  OpenInNew,
} from '@mui/icons-material';

import { Link } from 'react-router-dom';
import { Lesson } from 'controllers/types';
import { getLessonRoute } from '../utils/routeUtils';
import { useGetReadyForFinalizeQuery } from '../../controllers/react-query/lessonHooks';

const styles = {
  tooltipDisable: {
    color: 'darkgray',
    cursor: 'default',
  },
};
export function LessonEditActions({
  lesson,
  handleDelete,
  handleEdit,
  handleExport,
  handleFinalize,
  handlePublish,
  handleValidate,
  hideEditLessonPageButton = false,
}: LessonEditActionsProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<null | HTMLElement>(null);
  const { data: isReadyForFinalize } = useGetReadyForFinalizeQuery(lesson.id);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    anchorRef.current = event.currentTarget;
    setOpen(prevOpen => !prevOpen);
  };

  const isStudio = lesson.nlp_type === 'openai';

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  function publishTooltip() {
    let tooltipTitle: string;
    if (isStudio) {
      tooltipTitle = '[STUDIO] Publish to students! (will behave different then Studio publish)';
    } else {
      tooltipTitle = 'Publish to students!';
    }
    return (
      <Tooltip title={tooltipTitle}>
        <IconButton data-testid='publish-lesson' aria-label='publish lesson' onClick={handlePublish}>
          <Publish />
        </IconButton>
      </Tooltip>
    );
  }

  const finalizeTooltip = () => {
    if (isReadyForFinalize) {
      return (
        <Tooltip title='Finalize lesson video'>
          <IconButton data-testid='finalize-lesson' aria-label='finalize lesson' onClick={handleFinalize}>
            <Receipt />
          </IconButton>
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title='All segments need to be attached to a video before finalizing the lesson'
        style={styles.tooltipDisable}
      >
        <IconButton data-testid='hide-finalize-lesson' aria-label='hide-finalize lesson'>
          <Receipt />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <>
      {!hideEditLessonPageButton && (
        <Tooltip title='View Lesson on Separate Page'>
          <span>
            <IconButton
              data-testid='edit-lesson-page'
              aria-label='View Lesson on Separate Page'
              component={Link}
              to={`/studio/internal_lesson_editor/${lesson.id}/edit`}
            >
              <OpenInNew />
            </IconButton>
          </span>
        </Tooltip>
      )}

      <Tooltip title='Preview unpublished lesson'>
        <span>
          <IconButton
            data-testid='start-lesson'
            aria-label='preview lesson that is about to get published'
            disabled={lesson.finalized_at <= lesson.video_updated_at}
            component={Link}
            to={getLessonRoute(lesson.id, false, false)}
          >
            <Preview />
          </IconButton>
        </span>
      </Tooltip>

      <Tooltip title='Edit lesson'>
        <IconButton data-testid='edit-lesson' aria-label='edit lesson' onClick={handleEdit}>
          <Edit />
        </IconButton>
      </Tooltip>

      <Tooltip title='Validate lesson'>
        <IconButton data-testid='validate-lesson' aria-label='validate lesson' onClick={handleValidate}>
          <FactCheck />
        </IconButton>
      </Tooltip>
      {lesson.requires_finalization && (!lesson.finalized_at || lesson.finalized_at < lesson.video_updated_at) ? (
        <>{finalizeTooltip()}</>
      ) : (
        <>{publishTooltip()}</>
      )}
      <IconButton
        data-testid='toggle-menu'
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label='select version of lesson to play'
        aria-haspopup='menu'
        onClick={handleToggle}
      >
        <MoreVert />
      </IconButton>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  <MenuItem
                    data-testid='live-lesson'
                    aria-label='Start live lesson that students currently see'
                    component={Link}
                    to={getLessonRoute(lesson.published_lesson_id, false, false)}
                    disabled={!lesson.published_lesson_id}
                  >
                    <ListItemIcon>
                      <PlayArrow />
                    </ListItemIcon>
                    <ListItemText>Play Live</ListItemText>
                  </MenuItem>
                  <MenuItem
                    data-testid='start-lesson-draft'
                    aria-label='start lesson with latest videos'
                    component={Link}
                    to={getLessonRoute(lesson.id, false, true)}
                  >
                    <ListItemIcon>
                      <VideoSettings />
                    </ListItemIcon>
                    <ListItemText>Separate Videos</ListItemText>
                  </MenuItem>
                  <MenuItem data-testid='export-lesson' aria-label='export lesson' onClick={() => handleExport()}>
                    <ListItemIcon>
                      <CloudDownload />
                    </ListItemIcon>
                    <ListItemText>Export Lesson</ListItemText>
                  </MenuItem>
                  <MenuItem data-testid='delete-lesson' aria-label='delete lesson' onClick={() => handleDelete()}>
                    <ListItemIcon>
                      <Delete />
                    </ListItemIcon>
                    <ListItemText>Delete Lesson</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

type LessonEditActionsProps = {
  handleDelete: () => void;
  handleEdit: () => void;
  handleExport: () => void;
  handleFinalize: () => void;
  handlePublish: () => void;
  handleValidate: () => void;
  lesson: Lesson;
  hideEditLessonPageButton?: boolean;
};
