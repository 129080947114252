import React from 'react';
import { Grid, Skeleton } from '@mui/material';

export function StudentDashboardAnalyticsCardSkeleton() {
  return (
    <Grid container spacing={2} justifyContent='flex-start' sx={{ width: '100%' }}>
      <Grid item xs={12} sm={6}>
        <Skeleton variant='rounded' width='100%' height='150px' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton variant='rounded' width='100%' height='150px' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton variant='rounded' width='100%' height='150px' />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Skeleton variant='rounded' width='100%' height='150px' />
      </Grid>
    </Grid>
  );
}
