/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@mui/material/Autocomplete';

import {
  Grid,
  Box,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';

import { isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';
import { AddDeleteFab } from './AddDeleteFab';
import { SimpleEquationBuilder } from '../Equation';

const EquationMCAddDeleteFabWrapper = styled('div')`
  gap: 10px;
  margin-left: 10px;
  display: flex;
  height: 100%;
  align-items: center;
`;

export const TargetSegmentEdit = props => {
  const [dfEvent, setDfEvent] = useState(
    props.edgeRow.answer.includes('sys.no-input-default') || props.edgeRow.answer.includes('sys.no-match-default')
      ? props.edgeRow.answer
      : '',
  );

  const AUTOCOMPLETE_TYPE = 'autocomplete';

  const getInputLabel = p => {
    if (p.acceptableInputs?.includes('open_ended')) {
      return 'OpenEnded';
    }
    if (p.acceptableInputs?.includes('multiple_choice')) {
      return 'Multiplechoice';
    }
    if (p.acceptableInputs?.includes('equation')) {
      return 'Equation Answer(s)';
    }
  };

  // a bunch of variables to use between a multiple_choice and a DF
  // (audio/text) segment edge editing
  let label = 'Training Phrases';
  let maxRows = 5;
  let minRows = 2;
  let showEvents = true;
  let xsAnswerOrTraining = 6;
  let answerOrTrainingValue = props.edgeRow.training_phrases?.join('\n');
  const answerJson = props.edgeRow.answer_json;
  const isEquationMultipleChoice = props.acceptableInputs?.includes('equation_multiple_choice');
  const isDfEvent = !isEmpty(dfEvent);

  const setupNonDialogFlowInputs = () => {
    label = getInputLabel(props);
    minRows = 1;
    maxRows = 1;
    answerOrTrainingValue = props.edgeRow.answer;
  };
  if (props.acceptableInputs?.includes('multiple_choice') || props.acceptableInputs?.includes('open_ended')) {
    showEvents = false;
    setupNonDialogFlowInputs();
    xsAnswerOrTraining = 8;
  }

  if (props.acceptableInputs?.includes('equation') || props.acceptableInputs?.includes('equation_multiple_choice')) {
    setupNonDialogFlowInputs();
    xsAnswerOrTraining = 6;
  }
  if (isEquationMultipleChoice) {
    showEvents = false;
    setupNonDialogFlowInputs();
    xsAnswerOrTraining = 8;
    answerOrTrainingValue = undefined;
  }

  const handleRadioChange = e => {
    let newValue = e.target.value;
    if (newValue === dfEvent) newValue = '';
    setDfEvent(newValue);
    props.handleEventChange(e.target.labels[0].id, newValue);
  };

  const handleInputChange = (event, value) => {
    props?.handleTargetSegmentsChange?.({
      target: {
        name: props?.edgeRow?.id?.toString(),
        value,
        type: AUTOCOMPLETE_TYPE,
      },
    });
  };

  return (
    <>
      <Grid item xs={4}>
        <Autocomplete
          data-testid='target-segment-select'
          id={props?.edgeRow?.id?.toString()}
          value={props.edgeRow.target_segment_name}
          onChange={handleInputChange}
          options={props.allSegmentNames}
          renderInput={params => <TextField {...params} label='Select a Target Segment' margin='normal' fullWidth />}
        />
      </Grid>
      {showEvents && (
        <Grid item xs={2}>
          <FormControl data-testid='df-events-radios' variant='standard'>
            <FormLabel component='legend'>Events</FormLabel>
            <RadioGroup value={dfEvent}>
              <FormControlLabel
                control={
                  <Radio
                    size='small'
                    checked={!!dfEvent.includes('sys.no-input-default')}
                    onClick={handleRadioChange}
                  />
                }
                value='sys.no-input-default'
                label='No Input'
                id={props.edgeRow?.id?.toString()}
              />
              <FormControlLabel
                control={
                  <Radio
                    size='small'
                    checked={!!dfEvent.includes('sys.no-match-default')}
                    onClick={handleRadioChange}
                  />
                }
                label='No Match'
                value='sys.no-match-default'
                id={props.edgeRow?.id?.toString()}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      {isDfEvent && (
        <Grid item xs={xsAnswerOrTraining}>
          <Box sx={{ '& > :not(style)': { mr: 1 } }}>
            <AddDeleteFab
              lastSegment={props.lastSegment}
              firstSegment={props.firstSegment}
              dataId={props.edgeRow?.id?.toString()}
              addHandle={props.addHandle}
              deleteHandle={props.deleteHandle}
              moveHandle={props.moveHandle}
            />
          </Box>
        </Grid>
      )}
      {!isDfEvent && !isEquationMultipleChoice && (
        <Grid item xs={xsAnswerOrTraining}>
          <TextField
            data-testid='answer-or-training-phrase-input'
            name={props.edgeRow?.id?.toString()}
            type='text'
            label={label}
            margin='normal'
            fullWidth
            multiline
            maxRows={maxRows}
            minRows={minRows}
            onChange={props.handleAnswerOrTrainingPhrasesChange}
            value={answerOrTrainingValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position='start' sx={{ '& > :not(style)': { ml: 1 } }}>
                  <AddDeleteFab
                    lastSegment={props.lastSegment}
                    firstSegment={props.firstSegment}
                    dataId={props.edgeRow?.id?.toString()}
                    addHandle={props.addHandle}
                    deleteHandle={props.deleteHandle}
                    moveHandle={props.moveHandle}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      )}
      {!isDfEvent && isEquationMultipleChoice && (
        <Grid item xs={xsAnswerOrTraining}>
          <Grid container>
            <Grid item xs={10}>
              <SimpleEquationBuilder
                size='small'
                label='Equation Mulitple Choice Option'
                data={answerJson}
                onChanges={props.handleAnswerJsonChange}
              />
            </Grid>
            <Grid item alignItems='center' xs={2}>
              <EquationMCAddDeleteFabWrapper>
                <AddDeleteFab
                  lastSegment={props.lastSegment}
                  firstSegment={props.firstSegment}
                  dataId={props.edgeRow?.id?.toString()}
                  addHandle={props.addHandle}
                  deleteHandle={props.deleteHandle}
                  moveHandle={props.moveHandle}
                />
              </EquationMCAddDeleteFabWrapper>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

/* eslint-disable react/forbid-prop-types */
TargetSegmentEdit.propTypes = {
  addHandle: PropTypes.func,
  deleteHandle: PropTypes.func,
  moveHandle: PropTypes.func,
  lastSegment: PropTypes.bool,
  firstSegment: PropTypes.bool,
  edgeRow: PropTypes.object,
  allSegmentNames: PropTypes.array,
  handleTargetSegmentsChange: PropTypes.func,
  handleAnswerOrTrainingPhrasesChange: PropTypes.func,
  handleAnswerJsonChange: PropTypes.func,
  handleEventChange: PropTypes.func,
  acceptableInputs: PropTypes.array,
};
