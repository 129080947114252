import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SubmitIcon from 'assets/submit_arrow.svg';

type LessonEquationProps = {
  equationDisplayInput: [];
  handleEquationInputChange: (parsedEquationInput: NonNullable<unknown>) => void;
};

export const Equation = ({ equationDisplayInput, handleEquationInputChange }: LessonEquationProps) => {
  const [equationInput, setEquationInput] = useState('');

  useEffect(() => {
    setEquationInput(JSON.stringify(equationDisplayInput));
  }, [equationDisplayInput]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEquationInput(e.target.value);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleEquationInputChange(JSON.parse(equationInput));
  };

  return (
    <Box sx={{ width: '100%', display: 'flex' }}>
      {/* TODO: Update with UI component for equation input  */}
      <TextField
        id='equation-display-input-field'
        name='equation_display_input'
        sx={{
          '& .MuiOutlinedInput-root .MuiInputBase-input': {
            border: '4px solid #235E83',
            borderRadius: '30px',
          },
          '& .MuiOutlinedInput-root': {
            '& > fieldset': { border: 'none' },
          },
        }}
        variant='outlined'
        autoFocus
        type='text'
        fullWidth
        onChange={handleInputChange}
        required
        placeholder='Enter Text Response'
        value={equationInput}
        inputProps={{
          'aria-label': 'student response',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                data-testid='student-input-button'
                aria-label='submit input button'
                onClick={handleClick}
                type='submit'
                size='large'
              >
                <img alt='Submit' src={SubmitIcon} style={{ width: '30px', height: 'auto' }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
