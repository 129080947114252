import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CardHeader, CardMedia, CardContent, Avatar, Typography, Link } from '@mui/material';

import { LessonEditActions } from './LessonEditActions';
import { LessonValidate } from './LessonValidate';

const styles = {
  lessonThumbnail: {
    paddingLeft: '2vh',
    maxHeight: '12vh',
    width: 'auto',
  },
  lessonAvatar: {
    color: '#7DB8DD',
    backgroundColor: '#235E83',
  },
  header: {
    alignItems: 'center',
  },
};

export const LessonEditorDetails = ({
  lesson,
  handleLessonEdit,
  handleLessonExport,
  handleLessonFinalize,
  handleLessonPublish,
  openDeleteModal,
  hideEditLessonPageButton,
}) => {
  const [validateModalOpen, setValidateModalOpen] = useState(false);
  const fullName = lesson.teacher?.display_name?.split(' ') || [' '];
  let initials = fullName[0].charAt(0);
  if (fullName.length > 1) {
    initials += fullName[1].charAt(0);
  }

  const isStudio = lesson.nlp_type === 'openai';

  const dialogflowURL =
    'https://dialogflow.cloud.google.com/cx/projects/' +
    `${lesson?.gcp_project}/locations/us-central1/agents/` +
    `${lesson?.gcp_df_agent}` +
    '/flows/00000000-0000-0000-0000-000000000000/flow_creation';

  return (
    <>
      <LessonValidate
        lessonId={lesson?.id}
        validateModalOpen={validateModalOpen}
        setValidateModalOpen={setValidateModalOpen}
      />
      <CardHeader
        avatar={
          <Avatar sx={styles.lessonAvatar} aria-label='lesson teacher'>
            {initials.toUpperCase()}
          </Avatar>
        }
        action={
          <LessonEditActions
            lesson={lesson}
            handleDelete={openDeleteModal}
            handleEdit={handleLessonEdit}
            handleExport={handleLessonExport}
            handleFinalize={handleLessonFinalize}
            handlePublish={handleLessonPublish}
            handleValidate={() => setValidateModalOpen(true)}
            hideEditLessonPageButton={hideEditLessonPageButton}
          />
        }
        title={`${lesson.name} [${lesson.locale}]`}
      />
      <CardMedia sx={styles.lessonThumbnail} component='img' image={lesson.video_thumbnail} alt='lesson thumbnail' />
      <CardContent>
        <Typography variant='body2'>
          [{lesson.lesson_collection?.name}] {isStudio ? '[STUDIO]' : ''}
        </Typography>
        <Typography variant='body2' color='text.secondary'>
          {lesson.description}
        </Typography>
        {!!lesson.gcp_df_agent && (
          <Typography variant='body2'>
            <Link href={dialogflowURL} target='_blank'>
              Dialogflow CX Agent
            </Link>
          </Typography>
        )}
      </CardContent>
    </>
  );
};

LessonEditorDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  lesson: PropTypes.object.isRequired,
  handleLessonEdit: PropTypes.func.isRequired,
  handleLessonExport: PropTypes.func.isRequired,
  handleLessonFinalize: PropTypes.func.isRequired,
  handleLessonPublish: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func,
  hideEditLessonPageButton: PropTypes.bool,
};
