import React from 'react';
import { Stack, Button, ButtonProps, Tooltip } from '@mui/material';
import { NavLink, useLocation } from 'react-router-dom';
import { useFeatures } from 'components/FeaturesContext';
import { useUserContext } from 'components/UserContext';
import { useCreateCheckoutSession } from 'controllers/react-query/paymentHooks';
import { useSubscriptionsQuery } from 'controllers/react-query/subscriptionHooks';
import { ManagePaymentsButton } from 'components/ManagePaymentsButton';

export type SubscriptionType = 'annual' | 'monthly';

function TooltipWithWrapper({ children, title }: { children: React.ReactNode; title: string }) {
  return (
    <Tooltip title={title}>
      {/* wrap the child elements with Stack to make the hover events work when the elements are disabled */}
      <Stack>{children}</Stack>
    </Tooltip>
  );
}

type SubscribeButtonProps = {
  subscription: SubscriptionType;
};

/**
 * Component to create a checkout session
 */
export const SubscribeButton = ({ subscription }: SubscribeButtonProps) => {
  const { isOrgAdmin } = useUserContext();
  const { isFetching: isCheckingForActiveSubscription, data: activeSubscriptions } = useSubscriptionsQuery('active');
  const isSubscribed = (activeSubscriptions?.length ?? 0) > 0;
  const isStripeSubscription = activeSubscriptions?.some(sub => sub.stripe_customer_id !== null);

  const { mutate: createCheckoutSession } = useCreateCheckoutSession();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get('redirectUrl') || '/';

  const handleSubscribe = () => {
    createCheckoutSession(
      { payload: { subscription, redirectUrl } },
      {
        onSuccess: data => {
          window.open(data.url, '_blank');
        },
      },
    );
  };

  if (isSubscribed) {
    return isStripeSubscription ? <ManagePaymentsButton /> : null;
  }

  return (
    <TooltipWithWrapper title={!isOrgAdmin ? 'Please contact an administrator to subscribe your organization.' : ''}>
      <Button
        onClick={handleSubscribe}
        variant='contained'
        color='primary'
        disabled={isCheckingForActiveSubscription || !isOrgAdmin}
      >
        Subscribe Now
      </Button>
    </TooltipWithWrapper>
  );
};

/**
 * Component to navigate to the pricing page with a redirect URL for checkout
 */
export const NavigateToPricingButton = ({ children, ...props }: { children?: React.ReactNode } & ButtonProps) => {
  const { isOrgAdmin } = useUserContext();
  const location = useLocation();
  return (
    <TooltipWithWrapper title={!isOrgAdmin ? 'Please contact an administrator to subscribe your organization.' : ''}>
      <Button
        component={NavLink}
        {...props}
        to={`/pricing?redirectUrl=${encodeURIComponent(location.pathname)}`}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          props.onClick?.(e);
        }}
        disabled={!isOrgAdmin}
      >
        {children}
      </Button>
    </TooltipWithWrapper>
  );
};

/**
 * Hook to determine if a user requires a subscription
 */
export const usePaywall = () => {
  const { user } = useUserContext();
  const { test_mode__always_show_paywall: testAlwaysShowPaywall, show_stripe_payments: showStripePayments } =
    useFeatures();

  // If stripe payments are disabled, show everyone everything
  if (!showStripePayments) {
    return {
      canCreateCourse: true,
      canShareCourse: true,
    };
  }

  const { can_create_course: canCreateCourse, can_share_course: canShareCourse } =
    user?.active_organization?.access_control || {};

  return {
    canCreateCourse: canCreateCourse && !testAlwaysShowPaywall,
    canShareCourse: canShareCourse && !testAlwaysShowPaywall,
  };
};
