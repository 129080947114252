import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useCreateChatMessageOptions, useUpdateChatMessage } from 'controllers/react-query';
import { AiChatMessageOptions } from 'controllers/types';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useModal } from 'components/utils/ModalContext';
import { ChatFeedbackModalContentStep1 } from './ChatFeedbackModalContentStep1';
import { ChatFeedbackModalContentStep2 } from './ChatFeedbackModalContentStep2';

type ChatFeedbackModalContentProps = {
  chatId: number;
  rejectedMessage: string;
  replaceLastMessage: (text: string) => void;
  question: string | null;
};

export const ChatFeedbackModalContent = ({
  chatId,
  rejectedMessage,
  replaceLastMessage,
  question,
}: ChatFeedbackModalContentProps) => {
  const [userComment, setUserComment] = useState('');
  const [chatMessageId, setChatMessageId] = useState(0);
  const [newMessages, setNewMessages] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState('');

  const { mutate: createMessageOptions, isPending: isPendingCreateMessageOptions } = useCreateChatMessageOptions();
  const { mutate: updateChatMessage, isPending: isPendingUpdateChatMessage } = useUpdateChatMessage(
    chatId,
    chatMessageId,
  );
  const { enqueueSnackbar } = useSnackbar();
  const { closeModal } = useModal();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserComment(e.target.value);
  };

  const submitChatFeedbackModalStep1 = () => {
    const messageOptionsPayload = {
      chat_instance_id: chatId,
      user_comment: userComment,
      chat_message_id: null,
      selected_message_option: null,
    };

    createMessageOptions(
      { payload: messageOptionsPayload },
      {
        onSuccess: (resp: AiChatMessageOptions) => {
          setChatMessageId(resp.id);
          const arr = [rejectedMessage as string, ...(resp.messages as string[])];
          setNewMessages(arr);
        },
        onError: error => {
          enqueueSnackbar(`Error generating message options: ${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  const submitChatFeedbackModalStep2 = () => {
    const messageUpdatePayload = {
      chat_instance_id: chatId,
      selected_message_option: selectedOption,
      chat_message_id: chatMessageId,
      user_comment: null,
    };
    updateChatMessage(
      { payload: messageUpdatePayload },
      {
        onSuccess: updatedChatMessages => {
          const rejectedOptions = newMessages.filter(message => message !== selectedOption);
          KyronEvents.sendEvent(KyronEvents.names.UPDATE_CHAT_MESSAGE, {
            userComment,
            chatMessageId,
            originalMessage: rejectedMessage,
            replacementMessage: selectedOption,
            rejectedOptions,
            updatedChatMessages,
            question,
          });
          replaceLastMessage(selectedOption);
          closeModal();
        },
        onError: error => {
          enqueueSnackbar(`Error updating chat message: ${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  const handleEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }

    submitChatFeedbackModalStep1();
  };

  const retryStep1 = () => {
    setNewMessages([]);
  };

  if (newMessages.length === 0) {
    return (
      <ChatFeedbackModalContentStep1
        submitFunction={submitChatFeedbackModalStep1}
        isPending={isPendingCreateMessageOptions}
        handleInputChange={handleInputChange}
        userComment={userComment}
        handleEnterKey={handleEnterKey}
      />
    );
  }
  return (
    <ChatFeedbackModalContentStep2
      messageOptions={newMessages}
      submitFunction={submitChatFeedbackModalStep2}
      isPending={isPendingUpdateChatMessage}
      retryStep1={retryStep1}
      selectedOption={selectedOption}
      selectOption={setSelectedOption}
    />
  );
};
