import React, { useState } from 'react';
import { ToggleButtonGroup, ToggleButton, Box, useMediaQuery } from '@mui/material';
import { SimpleEquation } from 'components/Equation';
import { EquationData } from 'components/Equation/types';
import { styled } from '@mui/material/styles';
import { getFontSize } from 'components/Equation/util';
import { sortByKey } from 'components/utils/arrayUtils';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&&&': {
    lineHeight: 'normal',
    boxShadow: '0px 0.8px 1.5px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.2)',
    margin: '10px',
    paddingLeft: '10px',
    background: theme.palette.lessonMultipleChoiceToggleButton.main,
    borderRadius: '5px',
    color: theme.palette.lessonMultipleChoiceToggleButton.contrastText,
    '&:hover': {
      background: theme.palette.lessonMultipleChoiceToggleButton.hovered,
    },
    '&.Mui-selected': {
      background: theme.palette.lessonMultipleChoiceToggleButton.selected,
    },
  },
}));

const equationSize = 'medium';

const OptionWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
  > div {
    font-size: ${() => getFontSize(equationSize)};
  }
`;

// Use this method to get the alphabet option for the multiple choice
// The key is returned as order of option from the backend (0, 1, 2, 3, ...)
const getAlphabetOption = (choiceKey: number) => {
  if (choiceKey >= 0 && choiceKey < 26) {
    return `${String.fromCharCode(choiceKey + 64)}.`;
  }
};

type EquationMultipleChoiceOption = { key: number; value: EquationData[] };

type LessonEquationMultipleChoiceProps = {
  handleEquationMultipleChoice: (alignment: string) => void;
  equationMultipleChoiceOptions: EquationMultipleChoiceOption[];
};

export const EquationMultipleChoice = ({
  equationMultipleChoiceOptions,
  handleEquationMultipleChoice,
}: LessonEquationMultipleChoiceProps) => {
  const [alignment, setAlignment] = useState('');
  const matches = useMediaQuery('(min-width:600px)');

  const handleAlignment = (_event: React.MouseEvent, newAlignment: '') => {
    handleEquationMultipleChoice(newAlignment);
    setAlignment(newAlignment);
  };

  return (
    <Box display='flex' justifyContent='center'>
      <ToggleButtonGroup
        value={alignment}
        orientation={matches ? 'horizontal' : 'vertical'}
        exclusive
        onChange={handleAlignment}
        aria-label='text alignment'
      >
        {sortByKey(equationMultipleChoiceOptions).map((choice: EquationMultipleChoiceOption) => (
          <StyledToggleButton fullWidth key={choice.key} value={choice.value} aria-label='left aligned'>
            <OptionWrapper>
              <Box>{getAlphabetOption(choice.key)}</Box>
              <SimpleEquation data={choice.value} size={equationSize} />
            </OptionWrapper>
          </StyledToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};
