import React, { useState } from 'react';
import {
  TextField,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
  TypographyProps,
} from '@mui/material';
import { Edit, Check, Cancel } from '@mui/icons-material';

type Props = {
  value: string;
  onSave: (newValue: string) => void;
  onReset?: () => void;
  label: string;
  isRequired?: boolean;
  placeholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  error?: Error | null;
  width?: number | string;
  textVariant?: TypographyProps['variant'];
};

/**
 * A component that allows for inline editing of a field.
 * When not editing, it displays the field's value and an edit button.
 * When editing, it displays an input field and a save button.
 */
export function EditableInputField({
  value,
  onSave,
  onReset = () => {},
  label,
  isRequired,
  placeholder = '',
  isLoading = false,
  error,
  disabled = false,
  width = '100%',
  textVariant = 'bodyLarge',
}: Props) {
  const [text, setText] = useState(value);
  const [isEditing, setIsEditing] = useState(false);

  const handleSetIsEditing = () => {
    if (!disabled) setIsEditing(true);
  };

  function handleSave() {
    if (!text && isRequired) return;
    if (text !== value) {
      onSave(text);
    }
    setIsEditing(false);
  }

  function handleReset() {
    setIsEditing(false);
    onReset();
    setText(value);
  }

  const missingTextError = !text && isRequired;
  const helperText = {
    error: error ? error?.message : '',
    required: missingTextError ? 'This field is required' : '',
  };

  const showEditField = isEditing || isLoading || error;

  return showEditField ? (
    <TextField
      value={text}
      autoFocus
      required={isRequired}
      error={missingTextError || Boolean(error)}
      variant='standard'
      onFocus={e => e.target.select()}
      onChange={e => setText(e.target.value)}
      placeholder={placeholder || label}
      sx={{ fontSize: 'large', width, bgcolor: 'surfaceContainer.lowest' }}
      InputProps={{
        endAdornment: (
          <>
            <InputAdornment position='end'>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton onClick={handleSave} aria-label={`Save ${label}`}>
                  <Check />
                </IconButton>
              )}
            </InputAdornment>
            <InputAdornment position='end'>
              <IconButton onClick={handleReset} aria-label={`Reset ${label}`}>
                <Cancel />
              </IconButton>
            </InputAdornment>
          </>
        ),
      }}
      helperText={missingTextError ? helperText.required : helperText.error}
      FormHelperTextProps={{ sx: { fontSize: 'small' } }}
    />
  ) : (
    <Stack direction='row' alignItems='center'>
      <Typography onClick={handleSetIsEditing} variant={textVariant}>
        {text}
      </Typography>
      <IconButton onClick={handleSetIsEditing} aria-label={`Edit ${label}`} disabled={disabled}>
        <Edit />
      </IconButton>
    </Stack>
  );
}
