import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { Button, Dialog } from '@mui/material';
import { Videocam } from '@mui/icons-material';
import { VideoRecorder } from 'components/VideoRecorder';
import { KyronEvents } from 'components/utils/KyronEvents';
import { useParams } from 'react-router-dom';
import { useUserContext } from 'components/UserContext';
import { UploaderElementProps } from './FileUploader';

function VideoRecorderDialog({
  open,
  onClose,
  onSave,
  recordingScript,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (blob: Blob) => void;
  recordingScript?: string;
}) {
  function handleSave(blob: Blob) {
    onSave(blob);
    onClose();
  }

  return (
    <Dialog fullWidth maxWidth='xl' open={open} onClose={onClose}>
      <VideoRecorder isOpen={open} onSave={handleSave} onCancel={onClose} recordingScript={recordingScript} />
    </Dialog>
  );
}

type VideoRecorderButtonProps = {
  buttonLabel: string;
  recordingScript?: string;
};
export function VideoRecorderButton({
  buttonLabel,
  recordingScript,
  handleUpload,
  disabled,
}: UploaderElementProps & VideoRecorderButtonProps) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const { user } = useUserContext();
  const { enqueueSnackbar } = useSnackbar();
  async function handleSave(blob: Blob) {
    const file = new File([blob], 'video.webm', { type: 'video/webm' });
    try {
      await handleUpload?.([file]);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Something went wrong when uploading the file. Please try again.', { variant: 'error' });
    }
  }

  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Button
        sx={{ maxWidth: 156 }}
        startIcon={<Videocam />}
        onClick={() => {
          KyronEvents.sendEvent(KyronEvents.names.RECORD_VIDEO, {
            recording_script: recordingScript,
            lesson_id: Number(lessonId),
            user_id: user?.id || 'anonymous',
          });
          setShowDialog(true);
        }}
        disabled={disabled}
        variant='outlined'
      >
        {buttonLabel}
      </Button>
      <VideoRecorderDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onSave={handleSave}
        recordingScript={recordingScript}
      />
    </>
  );
}
