import React, { useState } from 'react';
import { Stack, Pagination, List, Table, TableHead, TableRow, TableCell, TableBody, Link } from '@mui/material';
import { useGetLibraryPlaylists } from 'controllers/react-query/playlistsHooks';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { SearchField } from '../../SearchField';
import { NoResources } from '../../NoResources';
import { ListItemSkeleton } from '../components/ListItemSkeleton';
import { useHeader } from './LibraryLayout';

export function Playlists(): React.JSX.Element {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { isFetching, data, isError } = useGetLibraryPlaylists({ page, perPage: 25, search });
  const { playlists, meta } = data || {};
  const totalPages = meta?.total_pages;
  useHeader();

  const searchField = (
    <SearchField
      placeholder='Search for playlists'
      sx={{ maxWidth: 400 }}
      searchTerm={search}
      onSearch={setSearch}
      isLoading={isFetching}
      searchError={isError ? 'Failed when searching for playlists' : null}
    />
  );

  const playlistList = (
    <>
      <Table sx={{ typography: 'bodyMedium' }}>
        <TableHead>
          <TableRow>
            <TableCell>Playlist</TableCell>
            <TableCell>Last updated</TableCell>
            <TableCell>Lessons</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {playlists?.map(playlist => (
            <TableRow>
              <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                <Link
                  component={RouterLink}
                  to={`/studio/playlists/${playlist.id}`}
                  sx={{ color: 'primary.main' }}
                  data-testid='playlist-edit-link'
                >
                  {playlist.name}
                </Link>
              </TableCell>
              <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {dayjs(playlist.updated_at).fromNow()}
              </TableCell>
              <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {playlist.playlist_items?.length ?? 0}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Pagination
        sx={{ alignSelf: 'center', mt: 2 }}
        count={totalPages}
        shape='rounded'
        page={page}
        onChange={(_, newPage) => setPage(newPage)}
      />
    </>
  );

  const renderMainContent = () => {
    if (isFetching && !search) {
      return (
        <List sx={{ width: '100%' }}>
          <ListItemSkeleton rows={3} secondaryButton={false} loading />
        </List>
      );
    }

    if (isError) {
      throw new Error('Error fetching playlists');
    }

    if (!playlists?.length) {
      return <NoResources message='No playlists found' />;
    }

    return playlistList;
  };

  return (
    <Stack gap={4} mt={3}>
      {searchField}
      {renderMainContent()}
    </Stack>
  );
}
