import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useUserContext } from 'components/UserContext';
import { SignInDialog } from 'components/SignInDialog';
import { useLessonCollection } from '../LessonLayout/LessonLayout';
import { LessonSelectionDialog } from '../LessonSelectionDialog/LessonSelectionDialog';

export function LessonNavigator() {
  const location = useLocation();
  const { lessonCollection } = useLessonCollection();
  const { user } = useUserContext();
  const { lessons } = lessonCollection;
  const { lesson_instances: lessonInstances } = lessonCollection;
  const { enqueueSnackbar } = useSnackbar();

  // If there are no lessons, display an error message and navigate to the homepage.
  if (!lessons?.length) {
    enqueueSnackbar('Sorry, we encountered an error while loading this lesson.', { variant: 'error' });
    return <Navigate to='/' replace />;
  }

  // NOTE(derick): it would be nice to always show this prompt on initial page load for logged-out users, then hide if they cancel
  // but this would require some state management to track whether the dialog has been shown/canceled
  if (!user && !lessonCollection?.allow_anonymous_learners) {
    return (
      <SignInDialog
        canCancel={Boolean(lessonCollection?.allow_anonymous_learners)}
        message='Please sign in to begin this lesson.'
      />
    );
  }

  if (lessonInstances?.length) {
    const path = `${lessonInstances[0].lesson_id.toString()}${location.search}`;
    return <Navigate to={path} replace />;
  }

  // If there is only one lesson, navigate to it directly.
  if (lessons.length === 1) {
    const path = `${lessons[0].id.toString()}${location.search}`;
    return <Navigate to={path} replace />;
  }

  // If there are multiple lessons, display a list to choose from.
  return LessonSelectionDialog({ lessons: lessons! });
}
