/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, IconButton, Typography, CardHeader } from '@mui/material';
import { ArrowUpward, ArrowDownward, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const styles = {
  courseCardField: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export const Course = props => (
  <>
    <CardHeader
      action={
        <>
          <IconButton
            aria-label='move course up'
            data-testid='move-course-up'
            onClick={() => {
              props.reorderCourses(props.course, 'up');
            }}
          >
            <ArrowUpward />
          </IconButton>
          <IconButton
            aria-label='move course down'
            data-testid='move-course-down'
            onClick={() => {
              props.reorderCourses(props.course, 'down');
            }}
          >
            <ArrowDownward />
          </IconButton>
          <IconButton
            aria-label='edit course'
            data-testid='edit-course'
            onClick={() => props.handleCourseEdit(props.course)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            aria-label='delete course'
            data-testid={`delete-course-${props.course.id}`}
            onClick={props.openDeleteModal}
          >
            <DeleteIcon />
          </IconButton>
        </>
      }
    />
    <CardContent style={styles.courseCardField}>
      <Typography variant='h5' color='text.secondary' data-testid='course-title' marginBottom='10px'>
        {props.course.name}
      </Typography>
      <Typography variant='body1' color='text.secondary'>
        Units:
      </Typography>
    </CardContent>
  </>
);

Course.propTypes = {
  handleCourseEdit: PropTypes.func,
  course: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    position: PropTypes.number,
  }),
  openDeleteModal: PropTypes.func,
  reorderCourses: PropTypes.func,
};
