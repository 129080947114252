import React from 'react';
import { Box, Stack, Typography, styled, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const activityTypes = [
  { description: 'Create a video lecture' },
  { description: 'Create a discussion' },
  { description: 'Create an assessment' },
];

const ActivityCard = styled(Box)(({ theme }) => ({
  borderRadius: 16,
  padding: 16,
  background: theme.palette.background.default,
  boxShadow: theme.shadows[2],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  cursor: 'pointer',
}));

export function ActivitySelector() {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      maxWidth='md'
    >
      <Stack gap={15}>
        <ActivityCard onClick={() => navigate('/create')} data-testid='create-course'>
          <Typography variant='bodyLarge'>Create a course</Typography>
        </ActivityCard>

        <Stack gap={3} display='flex' alignItems='center'>
          <Typography variant='bodyLarge'>Create a course activity</Typography>

          <Stack direction={{ xs: 'column', sm: 'row' }} gap={3} justifyContent='space-between' width='100%'>
            {activityTypes.map(activity => (
              <ActivityCard>
                <Typography variant='bodyLarge'>{activity.description}</Typography>
              </ActivityCard>
            ))}
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
