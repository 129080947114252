import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CreateLessonForm } from './CreateLessonForm';

export function LessonBuilder() {
  const navigate = useNavigate();
  return (
    <Container maxWidth='md' sx={{ py: { xs: 2, sm: 2 } }}>
      <Stack gap={3} sx={{ bgcolor: 'background.default', borderRadius: 3, p: 3, boxShadow: 3 }}>
        <Typography variant='headlineSmall'>Create new course</Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          Use AI to generate a new interactive Kyron course. Fill in the fields below and we&apos;ll take care of the
          rest.
        </Typography>

        <CreateLessonForm onCancel={() => navigate(-1) /* go back */} />
      </Stack>
    </Container>
  );
}
