import React from 'react';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { TOCItem, DetailedSegmentType } from 'controllers/types';
import { SEGMENT_TYPE_LABEL } from 'enums/segmentTypeLabel';

type TableOfContentsItemProps = {
  tableOfContent: TOCItem;
  children: TOCItem[];
  handleTOCClick: (tableOfContent: TOCItem) => Promise<unknown>;
  runningLessonSegmentId?: number;
};
export const TableOfContentsItem = ({
  handleTOCClick,
  tableOfContent,
  runningLessonSegmentId,
  children,
}: TableOfContentsItemProps) => {
  const makeItem = (item: TOCItem) => {
    const isActiveChild = isChild(item) && item.lesson_segment_id === runningLessonSegmentId;
    const isActiveParent = !isChild(item) && item.lesson_segment_id === runningLessonSegmentId;
    const isActiveButton = isActiveChild || isActiveParent;
    const isParentWithChildren = !isChild(item) && hasChildren();
    const isParentWithActiveChild = () => children.some(child => child.lesson_segment_id === runningLessonSegmentId);

    // Parents are sections, children are segments. Sections have readable labels, but segments do not.
    const label = isChild(item) ? SEGMENT_TYPE_LABEL[item.label as DetailedSegmentType] || item.label : item.label;

    if (isParentWithChildren) {
      return (
        <ListItemText
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            // This is to blur the button after user clicks on it
            // If we don't have this, after selecting a segment in TOC,
            // focus will stay on that button and when user hits space bar
            // to answer a question, they will be taken to that segment again
            (e.target as HTMLElement).blur(); // DON'T REMOVE THIS, READ ABOVE COMMENT
            handleTOCClick(children[0]);
          }}
          sx={{
            m: 0,
            px: 2,
            py: 1,
            cursor: 'pointer',
            '&:hover': {
              opacity: 0.7,
            },
            opacity: isParentWithActiveChild() ? 0.7 : 1,
          }}
          data-testid='toc-item-parent'
        >
          <Typography
            variant='bodyMedium'
            component='p'
            // This tab index is to make this element not focusable
            // If we don't have this, p element will receive focus and
            // when student hits space bar for answering a question, they
            // will unintentionally click on this TOC item and be taken to
            // the beginning of this segment.
            tabIndex={-1} // DON'T REMOVE THIS, READ ABOVE COMMENT
            sx={{
              color: isParentWithActiveChild() ? 'secondaryContainer.contrastText' : 'surface.contrastText',
            }}
          >
            {label}
          </Typography>
        </ListItemText>
      );
    }
    return (
      <ListItemButton
        aria-label={label}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          // This is to blur the button after user clicks on it
          // If we don't have this, after selecting a segment in TOC,
          // focus will stay on that button and when user hits space bar
          // to answer a question, they will be taken to that segment again
          (e.target as HTMLElement).blur(); // DON'T REMOVE THIS, READ ABOVE COMMENT
          if (hasSegment(item)) {
            handleTOCClick(item);
          }
        }}
        sx={{
          py: 2,
          width: isChild(item) ? 'calc(100% - 32px)' : '100%',
          borderRadius: '100px',
          ml: isChild(item) ? 4 : 0,
          '&&&&.Mui-selected': {
            backgroundColor: 'secondaryContainer.main',
          },
          '&&&&.Mui-active': {
            color: isActiveButton ? 'secondaryContainer.contrastText' : 'surface.contrastText',
          },
        }}
        selected={isActiveButton}
        data-testid='toc-item-child'
      >
        <Typography
          variant={isChild(item) ? 'titleSmall' : 'bodyMedium'}
          component='p'
          // This tab index is to make this element not focusable
          // If we don't have this, p element will receive focus and
          // when student hits space bar for answering a question, they
          // will unintentionally click on this TOC item and be taken to
          // the beginning of this segment.
          tabIndex={-1} // DON'T REMOVE THIS, READ ABOVE COMMENT
          sx={{
            color: isActiveButton ? 'secondaryContainer.contrastText' : 'surface.contrastText',
          }}
        >
          {label}
        </Typography>
      </ListItemButton>
    );
  };

  function hasChildren() {
    return children.length > 0;
  }
  function hasSegment(toc: TOCItem) {
    return toc.lesson_segment_id !== null;
  }
  function isChild(toc: TOCItem) {
    return toc.position % 1 !== 0;
  }

  return (
    <List sx={{ '&&': { padding: '0px 0px' } }}>
      <li>{makeItem(tableOfContent)}</li>
      {children.map(child => (
        <li key={child.id}>{makeItem(child)}</li>
      ))}
    </List>
  );
};
