import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useGetCanvasInstance, useUpdateCanvasInstance } from 'controllers/react-query/canvasInstanceHooks';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Row } from 'components/Row/Row';
import { useSnackbar } from 'notistack';
import { CanvasInstance } from 'controllers/types';

export function OauthSection(): React.JSX.Element {
  const { data: canvasInstance, isLoading } = useGetCanvasInstance();
  const { mutate: updateCanvasInstance } = useUpdateCanvasInstance();
  const [formInstance, setFormInstance] = useState<CanvasInstance>({ host: '', client_id: '' });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(setInitialFormValue, [canvasInstance]);
  function setInitialFormValue() {
    if (!canvasInstance) return;

    setFormInstance(canvasInstance);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!formInstance) {
      return console.error('payload to updateCanvasInstance mutation is empty');
    }

    updateCanvasInstance(
      { payload: formInstance },
      {
        onSuccess: () => {
          enqueueSnackbar('Update Canvas OAuth settings successfully.', { variant: 'success' });
        },
        onError: err => {
          enqueueSnackbar(err?.message, { variant: 'error' });
        },
      },
    );
  };

  const updateFormInstance = (e: ChangeEvent<HTMLInputElement>) => {
    setFormInstance(prevInstance => ({
      ...prevInstance,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <>
      <Typography variant='headlineMedium' mb={3}>
        Canvas OAuth
      </Typography>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <form data-testid='canvas-instance-form' onSubmit={handleSubmit}>
          <Stack gap={1}>
            <TextField
              data-testid='canvas-instance-host'
              name='host'
              label='Canvas Host'
              value={formInstance?.host}
              onChange={updateFormInstance}
              placeholder='canvas.institution.edu'
              fullWidth
              margin='normal'
              type='text'
              inputProps={{ 'data-testid': 'canvas-instance-host-input' }}
              required
            />
            <TextField
              data-testid='canvas-instance-client-id'
              name='client_id'
              label='Canvas Client ID (Developer Key ID)'
              value={formInstance?.client_id}
              onChange={updateFormInstance}
              placeholder='2498XXXXXXXXXXXXXX'
              fullWidth
              margin='normal'
              type='text'
              inputProps={{ 'data-testid': 'canvas-instance-client-id-input' }}
              required
            />
            <TextField
              data-testid='canvas-instance-client-secret'
              name='client_secret'
              label='Canvas Client Secret (Developer Key)'
              onChange={updateFormInstance}
              placeholder='Hidden for security - Fill in to update value'
              fullWidth
              margin='normal'
              type='password'
              inputProps={{ 'data-testid': 'canvas-instance-client-secret-input' }}
            />

            <Row>
              <Button type='submit' sx={{ mt: 2 }}>
                Save
              </Button>
            </Row>
          </Stack>
        </form>
      )}
    </>
  );
}
