import { ChatMessagePayload, AiChatMessageOptions } from 'controllers/types';
import { useKyronMutationV2 } from './kyronMutation';

export const useCreateChatMessageOptions = () =>
  useKyronMutationV2<{ payload: ChatMessagePayload }, AiChatMessageOptions>(`/message_options`);

export const useUpdateChatMessage = (id: number, chatId: number) =>
  useKyronMutationV2(`/chat_messages/${id}`, {
    method: 'PUT',
    invalidates: [`/api/v1/chats/stream_add_message?id=${chatId}`],
  });

export const useStopStreaming = () =>
  useKyronMutationV2<{ chatId: number }>(`/chats/stop_streaming?id=:chatId`, {
    method: 'POST',
    invalidates: ({ chatId }) => [`/api/v1/chats/stream_add_message?id=${chatId}`],
  });
