import React from 'react';
import { Card, CardHeader } from '@mui/material';

import { Misconception } from 'controllers/types';
import { SingleMisconception } from './SingleMisconception';

const styles = {
  misconceptionCardField: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export function NewLessonMisconception({
  handleFormSubmit,
  handleFormCancel,
}: {
  handleFormSubmit: (misconception: Misconception) => void;
  handleFormCancel: () => void;
}) {
  return (
    <Card sx={styles.misconceptionCardField}>
      <CardHeader title='New Lesson Misconception' />
      <SingleMisconception handleFormSubmit={handleFormSubmit} handleFormCancel={handleFormCancel} edit />
    </Card>
  );
}
