import { Delete } from '@mui/icons-material';
import { Button, Stack, TextField } from '@mui/material';
import { DirectUploader } from 'components/Uploaders/DirectUploader';
import { Row } from 'components/Row/Row';
import { TutorPayload } from 'controllers/react-query/tutorHooks';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const blankTutor: TutorPayload = {
  display_name: '',
  description: '',
};

type TutorFormProps = {
  tutor?: TutorPayload;
  onSubmit: (tutor: TutorPayload) => void;
};

export const TutorForm = ({ tutor = blankTutor, onSubmit }: TutorFormProps) => {
  const navigate = useNavigate();
  const [formTutor, setFormTutor] = useState(tutor);
  const [image, setImage] = useState<string | null>(tutor.picture || null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormTutor(prevFormTutor => ({
      ...prevFormTutor,
      [name]: value,
    }));
  };

  const handleProfilePictureInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => setImage(e.target?.result as string);
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleDirectUploadProfilePicture = (signedIds: string[]) => {
    setFormTutor(prevFormTutor => ({
      ...prevFormTutor,
      profile_picture: signedIds[0],
    }));
  };

  const handleRemoveProfilePicture = () => {
    setFormTutor(prevFormTutor => ({
      ...prevFormTutor,
      profile_picture: '',
    }));
    setImage(null);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formTutor);
  };

  return (
    <form data-testid='tutor-form' onSubmit={handleSubmit}>
      <TextField
        data-testid='tutor-display-name'
        name='display_name'
        fullWidth
        margin='normal'
        label='Tutor Display Name'
        type='text'
        onChange={handleInputChange}
        required
        value={formTutor.display_name}
      />

      <TextField
        data-testid='tutor-description'
        name='description'
        fullWidth
        margin='normal'
        label='Description'
        type='text'
        onChange={handleInputChange}
        required
        value={formTutor.description}
        multiline
        rows={2}
      />

      <Row gap={2} mt={2} alignItems='center'>
        {image ? <img src={image} alt='profile' style={{ display: 'block', height: '100px' }} /> : null}
        <Stack gap={1} alignItems='flex-start'>
          <DirectUploader
            afterUpload={handleDirectUploadProfilePicture}
            onInputChange={handleProfilePictureInputChange}
            multiple={false}
            acceptTypes='image/*'
            buttonLabel={image ? 'Upload different picture' : 'Upload picture'}
          />
          {image ? (
            <Button color='error' variant='outlined' startIcon={<Delete />} onClick={handleRemoveProfilePicture}>
              Remove picture
            </Button>
          ) : null}
        </Stack>
      </Row>

      <Row gap={1} mt={3}>
        <Button type='submit'>Save</Button>
        <Button variant='outlined' color='error' onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </Row>
    </form>
  );
};
