import React, { useContext, useState } from 'react';
import { useTitle } from 'components/StudioLayout/StudioLayout';
import { UserContext } from 'components/UserContext';
import {
  useOrganizationLessonAnalytics,
  useOrganizationOverallAnalytics,
} from 'controllers/react-query/organizationHooks';
import { Box, Grid, MenuItem, Paper, SelectChangeEvent, Typography, Select } from '@mui/material';
import { LineChart } from '@mui/x-charts';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { NoResources } from 'components/NoResources';
import { AnalyticsCard } from './OrganizationAnalyticsCard/AnalyticsCard';
import { DateRangePickerAnalytics } from './DateRangePickerAnalytics/DateRangePickerAnalytics';
import { OverallAnalyticsSkeleton } from './OverallAnalyticsSkeleton';
import { GraphSkeleton } from './GraphSkeleton';

dayjs.extend(utc);
dayjs.extend(timezone);

const generateDateRange = (startDate: string, endDate: string) => {
  const dates: string[] = [];
  let currentDate = dayjs(startDate).startOf('day');
  const endDateDayjs = dayjs(endDate).startOf('day');
  if (currentDate.isSame(endDateDayjs)) {
    return dates;
  }
  while (!currentDate.isAfter(endDateDayjs)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }
  return dates;
};

const mergeDataByDate = (
  dates: string[],
  instancesData: Record<string, number> = {},
  completionsData: Record<string, number> = {},
) =>
  dates.map(date => ({
    date: dayjs(date).valueOf(),
    instanceCount: instancesData[date] || 0,
    completionCount: completionsData[date] || 0,
  }));

export const OrganizationAnalytics = ({ firstDate = dayjs() }) => {
  const { user } = useContext(UserContext);
  const firstDayOfCurrentMonth = dayjs().startOf('month');
  const endOfToday = firstDate.hour(23).minute(59).second(59);
  const [startDate, setStartDate] = useState<Dayjs>(firstDayOfCurrentMonth);
  const [endDate, setEndDate] = useState<Dayjs>(endOfToday);
  const [selectedLesson, setSelectedLesson] = useState<number | null>(null);

  const {
    data: overallAnalyticsData,
    isFetching: isFetchingOverallAnalytics,
    isError: isErrorOverallAnalytics,
  } = useOrganizationOverallAnalytics(
    user?.active_organization?.id,
    startDate.toISOString(),
    endDate.toISOString(),
    selectedLesson,
  );

  const {
    data: lessonAnalyticsData,
    isFetching: isFetchingLessonAnalytics,
    isError: isErrorLessonAnalytics,
  } = useOrganizationLessonAnalytics(
    user?.active_organization?.id,
    startDate.toISOString(),
    endDate.toISOString(),
    selectedLesson,
  );

  useTitle(overallAnalyticsData ? `${overallAnalyticsData?.name} Analytics` : '');

  const firstLessonDate =
    lessonAnalyticsData && Object.keys(lessonAnalyticsData.all_lesson_instances_by_date).length > 0
      ? Object.keys(lessonAnalyticsData.all_lesson_instances_by_date).sort()[0]
      : dayjs().format('YYYY-MM-DD');
  const currentDate = dayjs().format('YYYY-MM-DD');
  const allDates = generateDateRange(firstLessonDate, currentDate);

  const mergedData = mergeDataByDate(
    allDates,
    lessonAnalyticsData?.all_lesson_instances_by_date,
    lessonAnalyticsData?.all_lesson_completions_by_date,
  );

  const handleDateRange = (startDate2: Dayjs, endDate2: Dayjs) => {
    setStartDate(startDate2);
    setEndDate(endDate2);
  };

  const hours = Array.from({ length: 24 }, (_, i) => i);
  const countsHours = hours.map(hour => lessonAnalyticsData?.all_lesson_instances_by_hour[hour] || 0);

  const utcToLocalTimeString = (utcHour: number) => dayjs.utc().hour(utcHour).local().minute(0).format('h:mm A');

  const handleLessonChange = (event: SelectChangeEvent<number | ''>) => {
    const { value } = event.target;
    setSelectedLesson(value === '' ? null : Number(value));
  };

  const analyticsCards = () => {
    if (isFetchingOverallAnalytics) {
      return <OverallAnalyticsSkeleton />;
    }
    if (isErrorOverallAnalytics) {
      return <NoResources message='Error loading analytics data' />;
    }
    return (
      <Grid container spacing={2} justifyContent='flex-start' sx={{ width: '100%' }}>
        <Grid item sm={12} xs={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePickerAnalytics
              timestampStart={startDate}
              timestampEnd={endDate}
              handleDateRange={handleDateRange}
            />
          </LocalizationProvider>
        </Grid>
        <Box width='100%' />
        <Grid item md={6} xs={12}>
          <Select value={selectedLesson ?? ''} onChange={handleLessonChange} displayEmpty fullWidth>
            <MenuItem value=''>All lessons</MenuItem>
            {overallAnalyticsData?.lesson_names?.map(lesson => (
              <MenuItem key={`${lesson.id}`} value={lesson.id}>
                {lesson.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Box width='100%' />
        <Grid item sm={4} xs={12}>
          <AnalyticsCard number={overallAnalyticsData?.member_count} label='Members' />
        </Grid>
        <Grid item sm={4} xs={12}>
          <AnalyticsCard number={overallAnalyticsData?.lesson_collections_count} label='Active Lessons' />
        </Grid>
        <Grid item sm={4} xs={12}>
          <AnalyticsCard number={overallAnalyticsData?.lesson_creators_count} label='Lesson Creators' />
        </Grid>
        <Box width='100%' />
        <Grid item sm={4} xs={12}>
          <AnalyticsCard number={overallAnalyticsData?.lesson_completions_count} label='Lessons Completed' />
        </Grid>
        <Grid item sm={4} xs={12}>
          <AnalyticsCard number={overallAnalyticsData?.lesson_instances_count} label='Lessons Attempted' />
        </Grid>
        <Grid item sm={4} xs={12}>
          <AnalyticsCard number={overallAnalyticsData?.completion_rate} label='Lesson Completion %' />
        </Grid>
        <Box width='100%' />
        <Grid item sm={2} />
        <Grid item sm={4} xs={12}>
          <AnalyticsCard number={overallAnalyticsData?.unique_lti_players} label='Unique LTI Players' />
        </Grid>
        <Grid item sm={4} xs={12}>
          <AnalyticsCard number={overallAnalyticsData?.unique_players} label='Unique Kyron Players' />
        </Grid>
      </Grid>
    );
  };

  const analyticsGraphs = () => {
    if (isFetchingLessonAnalytics) {
      return <GraphSkeleton />;
    }
    if (isErrorLessonAnalytics) {
      return <NoResources message='Error loading tables data' />;
    }
    return (
      <>
        <Paper sx={{ p: 3, mt: 4 }} variant='outlined'>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>
            Lessons Taken and Completed Over Time
          </Typography>
          <Box sx={{ width: '100%' }}>
            <LineChart
              xAxis={[
                {
                  data: mergedData.map(item => item.date),
                  scaleType: 'time',
                  valueFormatter: value => dayjs(value).format('MM/DD/YYYY'),
                  min: startDate.valueOf(),
                  max: endDate.valueOf(),
                },
              ]}
              series={[
                {
                  data: mergedData.map(item => item.instanceCount),
                  label: 'Lessons Taken Count',
                  connectNulls: true,
                },
                {
                  data: mergedData.map(item => item.completionCount),
                  label: 'Lessons Completed Count',
                  connectNulls: true,
                },
              ]}
              height={425}
            />
          </Box>
        </Paper>
        <Paper sx={{ p: 3, mt: 4 }} variant='outlined'>
          <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', textAlign: 'center' }}>
            Usage Throughout the Day
          </Typography>
          <Box sx={{ width: '100%' }}>
            <LineChart
              xAxis={[
                {
                  data: hours,
                  scaleType: 'linear',
                  label: 'Hour of Day',
                  valueFormatter: value => utcToLocalTimeString(value),
                },
              ]}
              series={[
                {
                  data: countsHours,
                  label: 'Lesson Instances Count',
                  connectNulls: true,
                  area: true,
                },
              ]}
              height={425}
            />
          </Box>
        </Paper>
      </>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      {analyticsCards()}
      {analyticsGraphs()}
    </Box>
  );
};
