import { Size } from 'components/Equation/types';

export const getFontSize = (size?: Size) => {
  switch (size) {
    case 'small':
      return '0.85rem';
    case 'medium':
      return '1.25rem';
    case 'large':
      return '3rem';
    default:
      return '3rem';
  }
};
