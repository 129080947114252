import React from 'react';
import { Menu } from '@mui/icons-material';
import { Box, IconButton, SxProps } from '@mui/material';

const styles = {
  navDrawerButton: {
    alignItems: 'center',
    display: 'flex',
    gap: '24px',
  },
};

type Props = {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sx?: SxProps;
};

export function NavDrawerButton({ drawerOpen, setDrawerOpen, sx }: Props) {
  return (
    <Box sx={{ ...styles.navDrawerButton, ...sx }}>
      <IconButton
        onClick={() => setDrawerOpen(ps => !ps)}
        data-testid='toggle-nav-drawer-button'
        aria-controls={drawerOpen ? 'nav-drawer' : undefined}
        aria-haspopup='true'
        aria-expanded={drawerOpen ? 'true' : undefined}
        aria-label='Toggle navigation drawer'
      >
        <Menu />
      </IconButton>
    </Box>
  );
}
