import React, { useState, useEffect } from 'react';
import { LoaderFunctionArgs, useLoaderData, useRevalidator, useSearchParams } from 'react-router-dom';

import { Collapse, Grid, IconButton, Pagination, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Add } from '@mui/icons-material';

import { KyronLayoutContent } from 'components/KyronLayout/KyronLayout';
import { getRoles } from 'controllers/roles';
import { addUserToOrganization, getUsers, updateUser } from 'controllers/users';
import { Role, User } from 'controllers/types';
import { UserCard } from './UserCard';
import { AddUserCard } from './AddUserCard';

export const loader = async (args: LoaderFunctionArgs) => {
  const roles = await getRoles();
  const { users, meta } = await getUsers(args);
  return { roles, users, meta };
};

const styles = {
  PaginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
  ListWrapper: {
    listStyle: 'none',
  },
  ListItem: {
    display: 'flex',
    margin: '10px 0',
  },
};

export function AdminUsers(): React.JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { users: originalUsers, roles, meta } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const loaderState = useRevalidator();
  const [users, setUsers] = useState(originalUsers);
  const [showAddUser, setShowAddUser] = useState(false);

  useEffect(() => setUsers(originalUsers), [originalUsers]);

  const handleUpdateUser = async (user: User) => {
    const updatedUser = await updateUser(user);
    const stillInOrg = updatedUser.roles.some(r => r.name !== 'admin');
    if (stillInOrg) {
      setUsers(userList => userList.map(u => (u.id === updatedUser.id ? updatedUser : u)));
    } else {
      setUsers(userList => userList.filter(u => u.id !== updatedUser.id));
    }
    enqueueSnackbar(`Changed roles for ${updatedUser.name} (${updatedUser.email})`);
  };

  const handleAddUser = async (email: string, roleList: Role[]) => {
    const response = await addUserToOrganization(email, roleList);
    enqueueSnackbar(response.message);
    if (response.user) {
      loaderState.revalidate(); // To get the updated user list
    }
    setShowAddUser(false);
  };

  const pg = searchParams.get('page');
  return (
    <KyronLayoutContent>
      <h1>
        Users
        <Tooltip title='Grant roles to an existing user'>
          <IconButton onClick={() => setShowAddUser(!showAddUser)}>
            <Add />
          </IconButton>
        </Tooltip>
      </h1>
      <div>
        <Collapse in={showAddUser} timeout='auto' unmountOnExit>
          <AddUserCard
            allRoles={roles}
            handleFormSubmit={handleAddUser}
            handleFormCancel={() => setShowAddUser(false)}
          />
        </Collapse>
      </div>

      <ul data-testid='user-list' style={styles.ListWrapper}>
        {users?.map(user => (
          <li key={user.id} style={styles.ListItem}>
            <UserCard user={user} allRoles={roles} handleFormSubmit={editedUser => handleUpdateUser(editedUser)} />
          </li>
        ))}
      </ul>

      <Grid xs={12} item>
        <Pagination
          style={styles.PaginationWrapper}
          count={meta.total_pages || 1}
          siblingCount={5}
          shape='rounded'
          page={pg ? parseInt(pg, 10) || 1 : 1}
          onChange={(_e, page) => setSearchParams({ page: page.toString() })}
        />
      </Grid>
    </KyronLayoutContent>
  );
}
