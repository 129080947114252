import React from 'react';
import { InputAdornment, TextField, TextFieldProps, Typography } from '@mui/material';

type SizedTextFieldProps = TextFieldProps & { characterSize: number; value: string; alwaysShowCounter?: boolean };

/**
 * Lightweight wrapper around TextField that enforces a character limit and
 * shows a counter when the user reaches 80% of the limit.
 */
export const SizedTextField = (props: SizedTextFieldProps) => {
  const { characterSize, alwaysShowCounter = false, ...restProps } = props;
  const valLength = restProps.value.length;
  const showCounter = alwaysShowCounter || valLength >= characterSize * 0.8;

  return (
    <TextField
      {...restProps}
      inputProps={{ ...(restProps.inputProps || {}), maxLength: characterSize }} // maxLength cannot be overridden
      InputProps={{
        endAdornment: showCounter && (
          <InputAdornment
            sx={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              mb: '12px',
              mr: '8px',
              color: valLength >= characterSize ? 'error.main' : 'text.secondary',
            }}
            position='end'
          >
            <Typography variant='bodySmall' sx={{}}>{`${valLength}/${characterSize}`}</Typography>
          </InputAdornment>
        ),
      }}
    />
  );
};
