import React from 'react';

import { Button, Stack, Divider, Typography, Box, CircularProgress } from '@mui/material';
import { Row } from 'components/Row/Row';
import { useNavigate, useLocation } from 'react-router-dom';
import { LESSON_SETTINGS_NAV_WIDTH } from './LessonSettingsDialog';

type Props = {
  isPending: boolean;
  onUpdate: () => void;
  unsavedChanges: boolean;
};

export const UpdateBar = ({ onUpdate, isPending, unsavedChanges }: Props) => {
  const location = useLocation();
  const background = location.state?.background;
  const navigate = useNavigate();

  // close the dialog
  const onCancel = () => {
    navigate(background || '../..');
  };

  return (
    <Box sx={{ position: 'absolute', bottom: 0, width: `calc(100% - ${LESSON_SETTINGS_NAV_WIDTH}px)`, zIndex: 1 }}>
      <Stack sx={{ backgroundColor: 'background.paper' }}>
        <Divider />
        <Row gap={1} p={1} justifyContent='flex-end'>
          {unsavedChanges && (
            <Typography variant='bodySmall' color='textSecondary' ml={1}>
              There are unsaved changes.
            </Typography>
          )}

          <Box flexGrow={1} />
          <Button variant='text' onClick={onCancel} disabled={isPending}>
            Cancel
          </Button>
          <Button
            onClick={onUpdate}
            disabled={isPending || !unsavedChanges}
            data-testid='update-button'
            startIcon={isPending && <CircularProgress size={16} color='info' />}
          >
            {isPending ? 'Saving' : 'Save'}
          </Button>
        </Row>
      </Stack>
    </Box>
  );
};
