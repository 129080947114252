import { Widget } from '@typeform/embed-react';
import React from 'react';
import { User, Lesson } from '../../controllers/types';

// TODO: This is a hack and needs to be cleaned up once we figure out
// how we want to do surveys. In order to get WGU pilot started we are
// doing a special update.
const defaultSurveyId: string = 'SbxMXhUf';
const wguSurveyId: string = 'a3pYGBJA';
const wguOrganizationId: number = 3;
const wguOrientationSurveyId: string = 'bnXbyCRQ';
const wguOrientationOrganizationId: number = 2540;
const wguOrientationCollectionName: string = 'Orientation Part 2';

export const LessonSurvey = ({
  user,
  sessionId,
  lesson,
  instanceId,
}: {
  user: User | null;
  sessionId: string;
  lesson: Lesson | null;
  instanceId: string;
}) => {
  let surveyId = defaultSurveyId;
  if (lesson?.organization?.id === wguOrganizationId) {
    surveyId = wguSurveyId;
  }
  if (
    lesson?.organization?.id === wguOrientationOrganizationId &&
    lesson?.lesson_collection?.name === wguOrientationCollectionName
  ) {
    surveyId = wguOrientationSurveyId;
  }
  return (
    <Widget
      id={surveyId}
      style={{ height: '100%', width: '100%' }}
      hidden={{
        session_id: sessionId,
        lesson_id: lesson ? lesson.id?.toString() : '',
        instance_id: instanceId,
        user_email: user?.email || '',
      }}
    />
  );
};
