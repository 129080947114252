import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useContext } from 'react';
import { useUpdateUser } from 'controllers/react-query';
import { User, Role, RoleNameMap, Organization } from 'controllers/types';
import { UserContext } from 'components/UserContext';
import { useSnackbar } from 'notistack';

type Props = {
  user: User;
  activeRoles: Role[];
  organization?: Organization;
};

export function RoleSelect({ user, activeRoles, organization }: Props): React.JSX.Element {
  const { updateUserRole } = useUpdateUser(user, organization);
  const { user: currentUser } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  // TODO: Remove this logic when we switch to two non-overlapping roles
  const getRole = () => {
    const { roles } = user;
    if (roles.some(role => role.name === 'org_admin' && role.resource_id === organization?.id)) {
      return 'org_admin';
    }
    return 'teacher';
  };

  const getDisplayName = (roleName: string) => {
    const displayName = RoleNameMap[roleName as keyof typeof RoleNameMap];
    if (displayName) {
      return displayName;
    }
    throw new Error(`No display name found for role ${roleName}`);
  };

  const handleRoleChange = (roleName: string) => {
    const role = activeRoles?.find(r => r.name === roleName);
    if (!role) return;
    updateUserRole(role, {
      onSuccess: () => {
        enqueueSnackbar(`${user.name}'s role has been updated to ${getDisplayName(roleName)}`, {
          variant: 'success',
        });
      },
      onError: error => {
        enqueueSnackbar(`${error.message}`, { variant: 'error' });
      },
    });
  };

  return (
    <FormControl sx={{ m: 1 }} variant='standard'>
      <Select
        labelId='role-select-label'
        data-testid='role-select'
        disabled={currentUser?.id === user.id}
        value={getRole()}
        sx={{ width: 120 }}
        onChange={e => handleRoleChange(e.target.value)}
      >
        {activeRoles?.map(role => (
          <MenuItem key={role.name} value={role.name}>
            {getDisplayName(role.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
