import React, { useState } from 'react';
import { IconButton, Modal, Card, CardHeader, CardContent, TextField } from '@mui/material';
import { Bolt } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { KyronClient } from '../utils/KyronClient';

const styles = {
  miroModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
};

export const MiroCheck = () => {
  const { enqueueSnackbar } = useSnackbar();
  const client = new KyronClient();
  const [miroModalOpen, setMiroModalOpen] = useState(false);
  const [miroUrl, setMiroUrl] = useState('');

  const handleMiroPopup = () => {
    setMiroModalOpen(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMiroUrl(e.target.value);
  };

  const handleMiroCheck = () => {
    setMiroModalOpen(false);
    const formData = new FormData();
    formData.append('miro_url', miroUrl);
    client
      .submitJsonDataWithError('/api/v1/lessons/export_miro', 'POST', formData)
      // TODO: type this any
      // eslint-disable-next-line
      .then((data: any) => {
        const blob = new Blob([JSON.stringify(data)], {
          type: 'application/json',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${data.name}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <>
      <IconButton data-testid='miro-export' onClick={handleMiroPopup}>
        <Bolt />
      </IconButton>
      <Modal
        data-testid='miro-modal'
        open={miroModalOpen}
        onClose={() => setMiroModalOpen(false)}
        aria-labelledby='modal-miro-url'
      >
        <Card sx={styles.miroModal}>
          <CardHeader title='Generate Miro json' />
          <CardContent>
            <form data-testid='miro-url-form' onSubmit={handleMiroCheck}>
              <TextField
                data-testid='miro-url-input'
                variant='outlined'
                name='miroUrl'
                fullWidth
                margin='normal'
                label='Miro Url'
                type='text'
                onChange={handleInputChange}
                required
                value={miroUrl}
              />
            </form>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
};
