import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { Stack, Box, Typography, Button, useMediaQuery, Theme } from '@mui/material';
import { OpenEndedAssistantController } from 'controllers/openEndedAssistant';
import { useStopStreaming } from 'controllers/react-query/chatMessageHooks';
import { UserContext } from 'components/UserContext';
import { ConversationRole } from 'enums/conversationRole';
import { Row } from 'components/Row/Row';
import { ConversationItem } from 'controllers/types';
import { ArrowForward } from '@mui/icons-material';
import { ChatInputField } from './ChatInputField';
import { ChatMessageList } from './ChatMessageList';

export const ROLE_USER = 'You';
export const CHAT_DONE_STATES = ['done_finished', 'done_max_turns', 'done_moderation'];

type StudentChatProps = {
  chatId: number;
  finishChat: (chatStatus: string) => void;
  question: string | null;
};
export const StudentChat = ({ chatId, finishChat, question }: StudentChatProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const { user } = useContext(UserContext);
  const { mutate: handleStopAssistant } = useStopStreaming();

  const textToSpeechControllerRef = useRef<OpenEndedAssistantController | null>(null);
  const [chatStatus, setChatStatus] = useState(''); // managed by OpenEndedAssistantController

  const [isAssistantResponding, setIsAssistantResponding] = useState(false);

  const [messages, setMessages] = useState<ConversationItem[]>([]);
  const [currentMessage, setCurrentMessage] = useState('');

  const appendToLastMessage = (text: string) => {
    setMessages(prevMessages => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      lastMessage.text += text;
      return [...prevMessages];
    });
  };

  const replaceLastMessage = (text: string) => {
    setMessages(prevMessages => {
      const lastMessage = prevMessages[prevMessages.length - 1];
      lastMessage.text = text;
      return [...prevMessages];
    });
  };

  const stopAssistant = () => {
    handleStopAssistant({ chatId });

    // Stop the audio playback
    if (textToSpeechControllerRef.current) {
      textToSpeechControllerRef.current.stop();
    }
  };

  // This function takes an input string as the current conversation turn.
  const submitResponse = (chat: string) => {
    if (isAssistantResponding) return;
    if (!chat) return;

    setMessages(prevMessages => [...prevMessages, { role: user?.first_name || ROLE_USER, text: chat }]);
    setIsAssistantResponding(true);

    // Add an empty message to the conversation from the assistant, to be filled in by the assistant's streaming response.
    setMessages(prevMessages => [...prevMessages, { role: ConversationRole.AI, text: '' }]);
    if (textToSpeechControllerRef.current) {
      textToSpeechControllerRef.current.stop(); // If the user is trying to interrupt the assistant, stop the current audio playback
      textToSpeechControllerRef.current.streamResponse(chat);
    }
  };

  useEffect(resetStateAndCleanup, [chatId, enqueueSnackbar]);
  function resetStateAndCleanup() {
    setMessages([]);
    setChatStatus('');
    setIsAssistantResponding(false);
    textToSpeechControllerRef.current = new OpenEndedAssistantController(
      chatId,
      appendToLastMessage,
      setIsAssistantResponding,
      enqueueSnackbar,
      setChatStatus, // manages chatStatus in a callback
    );
    return () => {
      if (textToSpeechControllerRef.current) textToSpeechControllerRef.current.stop(); // stop audio playback
    };
  }

  function handleReadyToMoveOn() {
    setChatStatus('skipped_ahead');
    finishChat('skipped_ahead');
  }

  const isConversationDone = CHAT_DONE_STATES.includes(chatStatus);
  const readyToMoveOnDisabled = messages.length < 4;

  return (
    <Stack gap={2} height='100%'>
      {/* Chat Area */}
      <Box minHeight={0} height='100%' overflow='auto'>
        <ChatMessageList
          messages={messages}
          chatId={chatId}
          question={question}
          replaceLastMessage={replaceLastMessage}
          isAssistantResponding={isAssistantResponding}
        />
      </Box>

      {/* Input area */}
      {isConversationDone ? (
        <>
          <Box display='flex' justifyContent='center'>
            <Button
              aria-label='Continue Lesson'
              onClick={() => finishChat(chatStatus)}
              variant='text'
              endIcon={<ArrowForward />}
            >
              Continue forward
            </Button>
          </Box>
          <Typography variant='bodySmall' textAlign='center' color='text.secondary'>
            All responses are generated by AI.
          </Typography>
        </>
      ) : (
        <>
          <Box display='flex' justifyContent='center'>
            <Row flex={1} gap={1}>
              <ChatInputField
                chatId={chatId}
                isAssistantResponding={isAssistantResponding}
                handleStopAssistant={stopAssistant}
                currentMessage={currentMessage}
                setCurrentMessage={setCurrentMessage}
                sendMessage={submitResponse}
              />
            </Row>
          </Box>
          <Stack
            alignItems='center'
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent={isMobile ? 'center' : 'space-between'}
            mt={-1}
          >
            <Typography pl={isMobile ? 0 : 1.5} variant='bodySmall' textAlign='center' color='text.secondary'>
              All responses are generated by AI.
            </Typography>
            <Button
              onClick={() => handleReadyToMoveOn()}
              endIcon={<ArrowForward />}
              disabled={readyToMoveOnDisabled}
              variant='text'
            >
              I want to jump forward
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};
