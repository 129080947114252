import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import { a11yLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { useEditorContext } from '../EditorContext';
import { useKyronQuery } from '../../../../controllers/react-query/kyronQuery';
import { KyronTooltip } from '../../../KyronTooltip';
import { useModal } from '../../../utils/ModalContext';
import { CopyButton } from '../../../CopyButton';

SyntaxHighlighter.registerLanguage('json', json);

export const LessonJSONModalButton = () => {
  const { editorState } = useEditorContext();
  const { openModal } = useModal();

  const handleOpen = () =>
    editorState.lessonId &&
    openModal({
      id: 'jsonViewer',
      title: 'Lesson Content JSON',
      content: <LessonJSONModalContent lessonId={editorState.lessonId} />,
      fullWidth: true,
    });

  return (
    <KyronTooltip title='Lesson Content' placement='right'>
      <IconButton onClick={handleOpen} size='small'>
        <DataObjectIcon />
      </IconButton>
    </KyronTooltip>
  );
};

function LessonJSONModalContent({ lessonId }: { lessonId: string }) {
  const { data, isFetching } = useKyronQuery(`/lessons/${lessonId}/lesson_contents`);

  const jsonString = data ? JSON.stringify(data, null, 2) : 'No lesson content found.';

  return (
    <Box sx={{ height: '100vh', width: '100%' }}>
      <Box sx={{ position: 'absolute', right: 84, top: 15 }}>
        <CopyButton label='Copy' value={jsonString} />
      </Box>
      <SyntaxHighlighter language='json' style={a11yLight} customStyle={{ fontSize: '0.8em' }} wrapLines wrapLongLines>
        {isFetching ? 'Loading lesson content...' : jsonString}
      </SyntaxHighlighter>
    </Box>
  );
}
