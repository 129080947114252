import {
  useQuery,
  useInfiniteQuery,
  UseQueryOptions,
  keepPreviousData,
  QueryKey,
  UseInfiniteQueryOptions,
  UndefinedInitialDataInfiniteOptions,
} from '@tanstack/react-query';
import { KyronClient } from '../../components/utils/KyronClient';
import { API_V1, DEFAULT_KYRON_CONFIG } from './constants';
import { Pagination } from '../types';

export type KyronQueryOptions<TData, TError = Error> = Omit<UseQueryOptions<TData, TError, TData>, 'queryKey'> & {
  queryKey?: QueryKey;
  /**
   * If there are search params in the URL, do not include them in the HTTP request
   */
  excludeSearchParams?: boolean;
};

const client = new KyronClient();
export function queryFn<TData>(apiPath: string, excludeSearchParams?: boolean) {
  return () => client.getDataWithError(API_V1 + apiPath, { excludeSearchParams }) as Promise<TData>;
}

/**
 * @param apiPath - API path to fetch data from
 * @param options - KyronQueryOptions configuration object. See https://tanstack.com/query/latest/docs/react/reference/useQuery
 * @returns see https://tanstack.com/query/latest/docs/react/reference/useQuery
 */
export const useKyronQuery = <TData, TError = Error>(
  apiPath: string,
  options?: KyronQueryOptions<TData, TError>,
): ReturnType<typeof useQuery<TData, TError>> => {
  const { queryKey, excludeSearchParams, ...optionsRest } = options || {};

  // remove search params in apiPath if excludeSearchParams is true
  let modifiedApiPath = apiPath;
  if (excludeSearchParams) {
    modifiedApiPath = removeSearchParams(apiPath);
  }

  return useQuery<TData, TError>({
    queryKey: queryKey || [modifiedApiPath],
    queryFn: queryFn<TData>(apiPath, excludeSearchParams),
    ...DEFAULT_KYRON_CONFIG,
    ...optionsRest,
  });
};

export function useKyronInfiniteQuery<TResponse, TError = Error>(
  apiPath: string,
  options?: Partial<UseInfiniteQueryOptions<TResponse, TError>> & { excludeSearchParams?: boolean },
) {
  const { queryKey, excludeSearchParams, ...optionsRest } = options || {};

  // remove search params in apiPath if excludeSearchParams is true
  let modifiedApiPath = apiPath;
  if (excludeSearchParams) {
    modifiedApiPath = removeSearchParams(apiPath);
  }

  type OptionsRest = Omit<
    UndefinedInitialDataInfiniteOptions<TResponse, TError>,
    'queryFn' | 'queryKey' | 'initialPageParam' | 'getNextPageParam' | 'refetchOnWindowFocus'
  >;

  return useInfiniteQuery<TResponse, TError>({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: queryKey || [modifiedApiPath],
    initialPageParam: 1,
    queryFn: ({ pageParam }) => client.getDataWithError(getApiPathForInfiniteQuery(apiPath, pageParam)),
    getNextPageParam: handleGetNextPageParam<TResponse>,
    ...(optionsRest as OptionsRest),
  });
}

export function removeSearchParams(path: string) {
  // remove search params in apiPath
  const [modifiedPath] = path.split('?');
  return modifiedPath;
}

export function getApiPathForInfiniteQuery(apiPath: string, pageParam: unknown) {
  const path = API_V1 + apiPath;
  const pageQuery = `page=${pageParam}`;
  const conjunction = path.includes('?') ? '&' : '?';

  return path + conjunction + pageQuery;
}

export function handleGetNextPageParam<TResponse>(lastPage: TResponse, _allPages: TResponse[]) {
  return (lastPage as { meta: Pagination })?.meta?.next_page;
}

// re-export for easy access
export { keepPreviousData };
