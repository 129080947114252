import React from 'react';
import { Box, Container, Theme, Typography, useMediaQuery } from '@mui/material';
import { useLoaderData as autoRedirectToVideoIfSingleLesson, useParams } from 'react-router-dom';
import { PageTitle } from 'components/seo/PageTitle';
import { useLessonContainerCollectionQuery } from 'controllers/react-query/lessonContainerHooks';
import { LessonSelectionCard } from '../LessonSelectionCard/LessonSelectionCard';
import { Row } from '../Row/Row';
import { LoadingIndicator } from '../LoadingIndicator';
import { NoResources } from '../NoResources';
import pinkPlane from '../../assets/plane-14-color.svg';
import redPlane from '../../assets/plane-2-color.svg';
import { useRemoveIntercom } from '../utils/KyronIntercom';
import { useClassroomStudentValidator } from '../GoogleClassroomStudentValidator';

export function CatalogLesson() {
  useRemoveIntercom();
  const autoRedirectResponse = autoRedirectToVideoIfSingleLesson() as { error?: string } | null;

  const downMediumScreen = useMediaQuery((t: Theme) => t.breakpoints.down('md'));
  // TODO(Harish): Double check and rename lessonId to lessonCollectionId,
  // We can reroute all old links with units/:unitId/lessons/:lessonId to units/:unitId/lesson_collections/{id}
  const { lessonId: lessonContainerId, lessonCollectionId } = useParams<{
    lessonId?: string;
    lessonCollectionId?: string;
  }>();

  const { signInDialog, isFetchingGoogleClassroomData, shouldRequireGoogleLogin } = useClassroomStudentValidator();

  const {
    data: lessonCollection,
    isLoading,
    isError,
  } = useLessonContainerCollectionQuery(Number(lessonContainerId), Number(lessonCollectionId));

  // For private lesson, we have to authenticate the user at this step to account for links that takes user to
  // /lesson_collections path. This logic is duplicated in Player.tsx as well to cover direct linking case (if ever
  // happens) to /video_player
  if (shouldRequireGoogleLogin) return signInDialog;

  if (autoRedirectResponse?.error && !isFetchingGoogleClassroomData) {
    return <NoResources message={autoRedirectResponse?.error} width={400} svgImage={pinkPlane} />;
  }

  if (isLoading || isError) {
    return (
      <Box sx={{ display: 'grid', placeContent: 'center', height: '100%' }}>
        {isLoading && <LoadingIndicator overlay message='Hang tight! Loading your lesson...' />}
        {isError && (
          <NoResources
            message={
              <>
                <Typography variant='titleLarge'>Something went wrong while loading your lesson.</Typography>
                <Typography variant='bodyLarge'>Please refresh your browser.</Typography>
              </>
            }
            width={400}
            svgImage={redPlane}
          />
        )}
      </Box>
    );
  }

  return !lessonCollection ? null : (
    <Container data-testid='lesson' sx={{ py: downMediumScreen ? 2 : 6, px: downMediumScreen ? 6 : 18 }}>
      <PageTitle titleString={`Kyron Learning | ${lessonCollection.name}`} />
      <Typography variant='headlineMedium' component='h1'>
        {lessonCollection.name}
      </Typography>
      <Typography variant='titleLarge'>Choose your teacher to start learning!</Typography>
      <Typography variant='bodyLarge'>{lessonCollection.description}</Typography>
      <Row gap={2} data-testid='lesson-variants' flexWrap='wrap' sx={{ mt: 2 }}>
        {lessonCollection.lessons?.map(lesson => <LessonSelectionCard key={lesson.id} lesson={lesson} />)}
      </Row>
    </Container>
  );
}
