import { Box, Button, SxProps, styled } from '@mui/material';
import React from 'react';

const togglePositioning: object = {
  position: 'absolute',
  top: '4px',
  bottom: '4px',
  left: '4px',
  width: 'calc(50% - 4px)',
};

const ToggleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  flexDirection: 'column',
  ...togglePositioning,
}));

const Indicator = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.surfaceContainer.dark,
  borderRadius: '100px',
  transition: 'left 0.2s ease-in-out',
  ...togglePositioning,
}));

type Props = {
  onLabel: string | React.ReactNode;
  offLabel: string | React.ReactNode;
  handleOff: () => void;
  handleOn: () => void;
  sx?: SxProps;
};

export function ToggleSwitch({ onLabel, offLabel, handleOff, handleOn, sx }: Props) {
  const [switchOn, setSwitchOn] = React.useState(false);

  function handleSwitchOn() {
    setSwitchOn(true);
    handleOn();
  }

  function handleSwitchOff() {
    setSwitchOn(false);
    handleOff();
  }

  return (
    <Box
      sx={{
        flexShrink: 0,
        borderRadius: '100px',
        height: '56px',
        border: 1,
        borderColor: 'surfaceContainer.dark',
        bgcolor: 'surfaceContainer.main',
        position: 'relative',
        width: '320px',
        ...sx,
      }}
    >
      <Indicator sx={{ left: switchOn ? '50%' : '4px' }} />
      <ToggleButton variant='text' onClick={handleSwitchOff} tabIndex={switchOn ? 0 : -1}>
        {offLabel}
      </ToggleButton>
      <ToggleButton variant='text' onClick={handleSwitchOn} tabIndex={switchOn ? -1 : 0} sx={{ left: '50%' }}>
        {onLabel}
      </ToggleButton>
    </Box>
  );
}
