import React from 'react';
import { Box, Stack } from '@mui/material';
import { PanelAreaContainer } from 'components/Player/PanelAreaContainer';

type Props = {
  transcript: string;
};

export const Transcripts = ({ transcript }: Props) => (
  <PanelAreaContainer
    title='Transcript'
    Body={
      <Stack>
        <Box tabIndex={0}>{transcript || 'Not available'}</Box>
      </Stack>
    }
  />
);
