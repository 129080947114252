import * as React from 'react';
import { ItemList, ListItem } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Helmet } from 'react-helmet';
import { LessonCollection } from 'controllers/types';
import { getLessonCollectionURL } from 'components/utils/urlUtils';

// This component is used to generate structured data json-ld for lesson collections
// that gets injected into the script tag of the page.
// The structured data is used by search engines to understand the content of the page.
// This particular component is used on the catalog page.
export function StructuredDataLessonCollections({
  lessonCollections,
}: {
  lessonCollections: LessonCollection[] | undefined;
}) {
  if (lessonCollections === undefined) return;

  // Course type is a reference to the schema.org Course type, it would map to our LessonCollection.
  function generateListItems(collections: LessonCollection[]): ListItem[] {
    return collections.map((lessonCollection: LessonCollection, index: number) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@type': 'Course',
        name: lessonCollection.name,
        description: lessonCollection.description,
        url: getLessonCollectionURL(lessonCollection.id),
        provider: {
          '@type': 'Organization',
          name: 'KyronLearning PBC',
          sameAs: `https://${window.location.origin}`,
        },
        offers: [
          {
            '@type': 'Offer',
            category: 'Free',
          },
        ],
        hasCourseInstance: [
          {
            '@type': 'CourseInstance',
            courseMode: 'Online',
            courseWorkload: 'P1H',
          },
        ],
      },
    }));
  }

  return (
    <Helmet
      script={[
        helmetJsonLdProp<ItemList>({
          '@context': 'https://schema.org',
          '@type': 'ItemList',
          itemListElement: generateListItems(lessonCollections),
        }),
      ]}
    />
  );
}
