import { useKyronMutationV2 } from './kyronMutation';
import { useKyronQuery } from './kyronQuery';
import { CanvasInstance } from '../types';

export const useGetCanvasInstance = () => useKyronQuery<CanvasInstance>(`/canvas_instance`);

export const useUpdateCanvasInstance = () =>
  useKyronMutationV2<{ payload: CanvasInstance }, CanvasInstance>(`/canvas_instance`, {
    method: 'PATCH',
  });
