import { Box } from '@mui/material';
import arrowDrawing from 'assets/arrowDrawing.svg';
import React from 'react';

type FlowchartArrowProps = {
  top: string;
  left: string;
  isMobile: boolean;
  scaleY: number;
};

export function FlowchartArrow({ top, left, isMobile, scaleY }: FlowchartArrowProps) {
  return (
    <Box
      visibility={isMobile ? 'hidden' : 'visible'}
      component='img'
      src={arrowDrawing}
      alt='arrow drawing'
      sx={{
        width: { xs: '80px', sm: '100px', md: '160px' },
        objectFit: 'cover',
        position: 'absolute',
        top,
        left,
        transform: `translate(-50%, -50%) scaleY(${scaleY})`,
        zIndex: 2,
      }}
    />
  );
}
