import { MoreVert } from '@mui/icons-material';
import { SxProps, Box, Stack, Typography, IconButton, Menu, MenuItem, Link, useMediaQuery, Theme } from '@mui/material';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { Row } from 'components/Row/Row';
import { ShareDialog } from 'components/ShareDialog';
import { useModal } from 'components/utils/ModalContext';
import { LessonCollection } from 'controllers/types';
import dayjs from 'dayjs';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { KyronImage } from '../../KyronImage/KyronImage';
import { DurationBox } from './DurationBox';

type MarketplaceLessonCardProps = {
  lessonCollection: LessonCollection;
  showChannel?: boolean;
  customMenuItems?: React.ReactElement;
  sx?: SxProps;
};

const styles: Record<string, SxProps> = {
  title: {
    display: '-webkit-box',
    lineClamp: 2,
    overflow: 'hidden',
    textDecoration: 'none',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  cardImage: {
    aspectRatio: '16 / 9',
    display: 'flex',
    position: 'relative',
    '& img': { width: '100%', height: '100%', objectFit: 'cover', borderRadius: 2 },
  },
  channelImage: {
    flexShrink: 0,
    width: 40,
    height: 40,
    textDecoration: 'none',
    '& img': { width: '100%', height: '100%', objectFit: 'cover', borderRadius: '40px' },
  },
};

export function MarketplaceLessonCard({
  lessonCollection,
  showChannel = true,
  customMenuItems,
  sx,
}: MarketplaceLessonCardProps) {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
  const [isHovered, setIsHovered] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);
  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const { openModal } = useModal();

  const menuIsOpen = Boolean(menuAnchor);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const handleShareMenuItemClick = () => {
    setOpenShareDialog(true);
    closeMenu();
  };

  const handleMoreInfoMenuItemClick = () => {
    openModal({ id: 'lesson-more-info', title: lessonCollection.name, content: lessonCollection.description });
    closeMenu();
  };

  const channelLink = `/marketplace/channels/${lessonCollection.channel?.id}`;
  const lessonLink = `/courses/${lessonCollection.id}`;
  const displayChannel = showChannel && lessonCollection.channel;

  return (
    <>
      <Box onMouseOver={() => setIsHovered(true)} onMouseOut={() => setIsHovered(false)} sx={sx}>
        <Box
          component={RouterLink}
          to={lessonLink}
          sx={styles.cardImage}
          tabIndex={-1}
          data-testid='lesson-collection-image'
        >
          <KyronImage stretch src={lessonCollection.collection_thumbnail} alt={lessonCollection.name} loading='lazy' />
          <DurationBox lessonCollection={lessonCollection} />
        </Box>
        <Row sx={{ alignItems: 'flex-start', gap: 1, mt: 0.5, position: 'relative' }}>
          {displayChannel ? (
            <Box
              component={RouterLink}
              to={channelLink}
              sx={styles.channelImage}
              tabIndex={-1}
              data-testid='channel-avatar'
            >
              <KyronAvatar image={displayChannel.image_url} label={displayChannel.name} />
            </Box>
          ) : null}
          <Stack pr={3} alignItems='flex-start'>
            <Link
              component={RouterLink}
              to={lessonLink}
              variant='titleSmall'
              color='text.primary'
              sx={styles.title}
              tabIndex={0}
              data-testid='lesson-collection-name'
            >
              {lessonCollection.name}
            </Link>
            {displayChannel ? (
              <Link
                component={RouterLink}
                to={channelLink}
                variant='bodySmall'
                color='text.secondary'
                tabIndex={0}
                data-testid='channel-name'
              >
                {displayChannel.name}
              </Link>
            ) : null}
            <Typography variant='bodySmall' color='text.secondary' data-testid='lesson-collection-published'>
              Published {dayjs(lessonCollection.published_at).fromNow()}
            </Typography>
          </Stack>
          <IconButton
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onClick={openMenu}
            aria-label='Open more lesson menu options'
            aria-controls={menuIsOpen ? 'lesson-card-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={menuIsOpen ? 'true' : undefined}
            sx={{
              position: 'absolute',
              top: '-4px',
              right: '-8px',
              opacity: isMobile || isHovered || isFocused || menuIsOpen ? 1 : 0,
            }}
            size='small'
          >
            <MoreVert />
          </IconButton>
        </Row>
      </Box>
      <Menu anchorEl={menuAnchor} id='lesson-card-menu' open={menuIsOpen} onClose={closeMenu} onClick={closeMenu}>
        {customMenuItems || (
          <>
            <MenuItem onClick={handleShareMenuItemClick}>Share</MenuItem>
            <MenuItem onClick={handleMoreInfoMenuItemClick}>Learn more</MenuItem>
            <MenuItem onClick={closeMenu} disabled>
              Watch later
            </MenuItem>
          </>
        )}
      </Menu>
      <ShareDialog
        open={openShareDialog}
        onClose={() => setOpenShareDialog(false)}
        quote={`Check out this lesson on Kyron Learning, ${lessonCollection.name}!`}
        emailMessage={`I found a lesson on Kyron Learning called ${lessonCollection.name}, and I wanted to share it with you. Click the link to check it out!`}
        link={`${window.location.origin}${lessonLink}`}
        eventResource={{ lesson_collection_id: lessonCollection.id, lesson_collection_name: lessonCollection.name }}
      />
    </>
  );
}
