import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import googleClassroomLogo from '../assets/Google_Classroom_Logo.svg';

const styles = {
  button: {
    fontFamily: 'Roboto',
    fontFamilyStyle: 'bold',
  },
};

type Props = {
  open: boolean;
  onClose: () => void;
  currentLocation?: string;
};

export const ReauthenticateDialog = ({ open, onClose, currentLocation }: Props) => {
  const authLink = `/users/auth/google_classroom${currentLocation ? `?redirect_to=${currentLocation}` : ''}`;
  const logo = <img height='24px' src={googleClassroomLogo} alt='Google Classroom Logo' />;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Your authentication with Google Classroom has expired.</DialogTitle>
      <DialogContent>
        <p>Please link your Google Classroom below to continue.</p>
      </DialogContent>
      <DialogActions>
        <Button
          component='a'
          sx={styles.button}
          href={authLink}
          data-method='post'
          data-testid='sign-in-with-google-classroom'
          startIcon={logo}
        >
          Google Classroom
        </Button>
      </DialogActions>
    </Dialog>
  );
};
