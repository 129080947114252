import React, { useState } from 'react';
import {
  Box,
  Button,
  Stack,
  Pagination,
  List,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useGetLibraryLessonCollections } from 'controllers/react-query';
import { SearchField } from '../../SearchField';
import { NoResources } from '../../NoResources';
import { ListItemSkeleton } from '../components/ListItemSkeleton';
import { LibraryListItem } from './LibraryListItem/LibraryListItem';
import { useHeader } from './LibraryLayout';

export function Library(): React.JSX.Element {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const { isFetching, data, isError, isRefetching } = useGetLibraryLessonCollections({ page, perPage: 25, search });
  const { lesson_collections: lessonCollections, meta } = data || {};
  const totalPages = meta?.total_pages;

  useHeader();
  const searchField = (
    <SearchField
      placeholder='Search for courses'
      sx={{ maxWidth: 400 }}
      searchTerm={search}
      onSearch={setSearch}
      isLoading={isFetching}
      searchError={isError ? 'Failed when searching for courses' : null}
    />
  );

  const lessonsList = (
    <>
      {isMobile ? (
        <Stack sx={{ width: '100%' }} gap={2}>
          {lessonCollections?.map(lessonCollection => (
            <LibraryListItem key={lessonCollection.id} lessonCollection={lessonCollection} variant='card' />
          ))}
        </Stack>
      ) : (
        <Table sx={{ typography: 'bodyMedium' }} data-testid='library-table'>
          <TableHead>
            <TableRow>
              <TableCell aria-label='Loading State'>
                <Box sx={visuallyHidden}>Loading State</Box>
              </TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Last updated</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lessonCollections?.map(lessonCollection => (
              <LibraryListItem key={lessonCollection.id} lessonCollection={lessonCollection} />
            ))}
          </TableBody>
        </Table>
      )}

      <Pagination
        sx={{ alignSelf: 'center', mt: 2 }}
        count={totalPages}
        shape='rounded'
        page={page}
        onChange={(_, newPage) => setPage(newPage)}
      />
    </>
  );

  const renderMainContent = () => {
    if (isFetching && !isRefetching && !search) {
      return (
        <List sx={{ width: '100%' }}>
          <ListItemSkeleton rows={3} loading />
        </List>
      );
    }

    if (isError) {
      throw new Error('Error fetching lessons');
    }

    if (!lessonCollections?.length) {
      return (
        <NoResources
          message='Get started by creating your first course'
          action={
            <Button to='/studio/lesson-builder' component={Link} startIcon={<Add />}>
              Create new Course
            </Button>
          }
        />
      );
    }

    return lessonsList;
  };

  return (
    <Stack gap={4} mt={isMobile ? 0 : 3}>
      {searchField}
      {renderMainContent()}
    </Stack>
  );
}
