import React, { ChangeEvent, createContext, useContext, useEffect, useState } from 'react';
import { DirectUploadProvider } from '@quorak/react-activestorage-provider';
import { UploadFile } from '@mui/icons-material';
import { CircularProgress, Button, ButtonProps, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { VisuallyHiddenInput } from '../VisuallyHiddenInput';
import { Row } from '../Row/Row';
import { ActiveStorageBlob, DerivedRenderProps, DirectUploaderRenderProps } from './types';
import { anyInvalidFileSize } from './helpers';
import { KyronTooltip } from '../KyronTooltip';
import { FileError, useDeleteAttachment } from '../../controllers/react-query/attachmentHooks';

export const ACCEPT_ALL_TEXT_FILES = '.doc, .docx, .txt, .pdf';
export const ACCEPT_VIDEO_TYPES = 'video/mp4, video/quicktime, video/webm'; // .mp4, .mov, .webm
export const ACCEPT_IMAGE_TYPES = 'image/png, image/jpeg, image/jpg';
export const DEFAULT_FILE_SIZE_LIMIT = 250; // MB

type AfterUploadCallback = ((blobs: ActiveStorageBlob[]) => void) | ((blobs: ActiveStorageBlob[]) => Promise<void>);

export type FileUploaderProps = {
  children: React.ReactNode;
  multiple?: number; // number for max files limit
  acceptTypes: string;
  disabled?: boolean;
  afterUpload: AfterUploadCallback;
  onUploadStateChange?: (isUploadInProgress: boolean) => void | React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * If defined, uploader will look at this to determine whether to show file name and remove button
   */
  isAttachmentBeingProcessed?: boolean;
  /**
   * **Exclusively for FileUploader internal state, children should not derive from these**
   */
  existingFile?: ActiveStorageBlob | null;
  /**
   * **Exclusively for FileUploader internal state, children should not derive from these**
   */
  existingFiles?: ActiveStorageBlob[] | null;
  /**
   * When defined, this function will be called to disable the form that this uploader is part of during uploads
   * Note that it will only make a call to disable the form. It is intentionally setup this way because enabling
   * the form might be wanted to be done after afterUpload callback.
   */
  setFormDisabled?: () => void;
  /**
   * The type of file being uploaded. Will be used in the UI to label the buttons and form the ids.
   * Defaults to 'file'
   */
  fileTypeName?: 'file' | 'video' | 'image';
  /**
   * If true, the file will be purged from the system when replaced with a new file (replace is an option for single file upload)
   */
  sendDeleteFileRequestWhenReplaced?: boolean;
  fileSizeLimit?: number; // Size in MB
  /**
   * Function to validate files after uploading. It should return an array of FileError objects. FileList will list
   * files with errors and show the error message.
   */
  fileValidation?: (uploadedFileBlobs: ActiveStorageBlob[]) => Promise<FileError[] | undefined>;
}; // The props that are passed to all children uploader elements

export type UploaderElementProps = Partial<FileUploaderProps & DirectUploaderRenderProps & DerivedRenderProps>;

export type FileUploaderContextValue = Partial<
  Omit<
    FileUploaderProps,
    // following props are exclusively for FileUploader internal state,
    // children should not derive from these
    'children' | 'existingFiles' | 'existingFile'
  >
> & {
  /**
   * Files state in FileUploader
   */
  files: ActiveStorageBlob[] | null;
  /**
   * Files state setter in FileUploader
   */
  setFiles: React.Dispatch<React.SetStateAction<ActiveStorageBlob[] | null>>;
  removeFile: (signedId: string) => void;
  isThereAnExistingFile: boolean;
  remainingUploads: number;
  fileErrors: FileError[];
};

// FileUploaderContext is used to pass props to children elements
export const FileUploaderContext = createContext<FileUploaderContextValue | null>(null);

/**
 * FileUploader is a component that allows the user to upload a files. Gives them the ability to preview the file uploaded and remove it.
 *
 * @example
 * The component is used like this:
 * ```
 * <FileUploader {...somePropsThatConcernsMainFunctionality}>
 *   <FilePreview />
 *   <UploadButton buttonLabel={...} />
 *   <RemoveButton  onRemove={...} />
 * </FileUploader>
 * ```
 */
export const FileUploader = (fileUploaderProps: FileUploaderProps) => {
  const {
    acceptTypes,
    disabled: $$disabled = false,
    afterUpload,
    isAttachmentBeingProcessed = false,
    existingFile,
    existingFiles,
    setFormDisabled,
    fileTypeName = 'file',
    multiple,
    children,
    onUploadStateChange,
    // default false because ideally this should be handled by the corresponding record controller when an update happens
    // but in some cases, we might need this to be true to avoid storing dangling files in our system.
    // For example, when UI accepts file uploads and stores uploaded file signed ids in the UI state to send in a request later
    // to be attached on a resource that will be created after the file upload. This was the case in CreateLessonForm
    // when it was only accepting one file upload.
    sendDeleteFileRequestWhenReplaced = false,
    fileSizeLimit = DEFAULT_FILE_SIZE_LIMIT, // Size in MB
    fileValidation,
  } = fileUploaderProps;
  const [$$files, $$setFiles] = useState<ActiveStorageBlob[] | null>(null);
  const [fileErrors, setFileErrors] = useState<FileError[]>([]);
  const [isValidationRequestFailed, setIsValidationRequestFailed] = useState(false);
  const isThereAnExistingFile = !!$$files?.length;
  const remainingUploads = (multiple || 1) - ($$files?.length || 0);
  const { enqueueSnackbar } = useSnackbar();

  const [$isAttachmentBeingProcessed, $setIsAttachmentBeingProcessed] = useState(false);
  // Fixing this eslint would cause wrong logic, so disabling it here
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(updateAttachmentProcessStatus, [isAttachmentBeingProcessed]);
  function updateAttachmentProcessStatus() {
    if (isAttachmentBeingProcessed !== $isAttachmentBeingProcessed) {
      $setIsAttachmentBeingProcessed(isAttachmentBeingProcessed);
    }
  }

  const { mutateAsync: deleteAttachment, isPending: deletionInProgress } = useDeleteAttachment();

  // When there is an existing file passed to FileUploader, update the internal file state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setExistingFiles, [JSON.stringify(existingFiles), JSON.stringify(existingFile)]);
  function setExistingFiles() {
    // If incoming existing file is empty, then clear the files
    if ($$files && !existingFiles && !existingFile) $$setFiles(null);
    // But if files are already set, and incoming existing file is not empty, don't override files state
    if ($$files) return;
    // If there are existing files, set them
    if (existingFiles) $$setFiles(existingFiles);
    else if (existingFile) $$setFiles([existingFile]);
  }

  const handleAttachment = (blobs: ActiveStorageBlob[]) => {
    // If the afterUpload callback is a Promise, and returns a rejected promise, do not try to set files
    // because that means something went wrong during the processes after upload
    Promise.resolve(afterUpload(blobs))
      .then(() => {
        $$setFiles(prevFiles => {
          const files = [...blobs, ...(prevFiles || [])];
          // Ensure we don't exceed the multiple limit if it exists
          const filesToSet = multiple ? files.splice(0, multiple) : files.splice(0, 1);

          if (sendDeleteFileRequestWhenReplaced) {
            // remove files replaced from our system
            // These are the files remaining from the .splice operation above. We need to remove them to not store them
            // in our system as they will be dangling
            files.forEach(file => (file.signed_id ? deleteAttachment({ signedId: file.signed_id }) : null));
          }

          return filesToSet;
        });
      })
      .catch(e => {
        console.error(e);
      });

    if (fileValidation) {
      // Reset the validation request state
      if (isValidationRequestFailed) setIsValidationRequestFailed(false);

      // Run validation on the uploaded files
      $setIsAttachmentBeingProcessed(true);
      fileValidation(blobs)
        .then(errors => {
          if (!errors) {
            setIsValidationRequestFailed(true);
          } else if (errors.length) {
            setFileErrors(p => [...p, ...errors]);
          }
        })
        .finally(() => {
          $setIsAttachmentBeingProcessed(false);
        });
    }
  };

  const removeFile = (signedId: string) => {
    // Remove the blob from active storage
    deleteAttachment({ signedId })
      .then(() => {
        // Remove file from the list of files to be attached on lesson for creation
        $$setFiles(p => p?.filter(file => file.signed_id !== signedId) || null);

        // Clean removed file from fileErrors if needed
        if (fileErrors.some(e => e.signed_id === signedId)) {
          setFileErrors(p => p.filter(error => error.signed_id !== signedId));
        }
      })
      .catch(e => {
        enqueueSnackbar(`Something went wrong when removing file. ${e}`, { variant: 'error' });
      });
  };

  return (
    <FileUploaderContext.Provider
      // Doing what ESLint suggest here is too verbose for not very much gain, so I'm disabling it
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        setFormDisabled,
        setFiles: $$setFiles,
        removeFile,
        isAttachmentBeingProcessed: $isAttachmentBeingProcessed || deletionInProgress,
        isThereAnExistingFile,
        acceptTypes,
        files: $$files,
        fileTypeName,
        multiple,
        remainingUploads,
        fileSizeLimit,
        fileErrors,
      }}
    >
      <DirectUploadProvider
        fullAttributes
        onSuccess={handleAttachment}
        render={(props: DirectUploaderRenderProps) => {
          const shouldBeDisabled =
            !props.ready ||
            $$disabled ||
            $isAttachmentBeingProcessed ||
            (multiple && remainingUploads <= 0) ||
            deletionInProgress;
          const isUploadInProgress = props?.uploads?.some(upload => upload.state === 'uploading');
          onUploadStateChange?.(isUploadInProgress);

          // Cloning all the children to pass props of FileUploader (parent)
          return (
            <Stack gap={0.5}>
              {React.Children.map(children, (child: React.ReactNode) => {
                if (React.isValidElement(child)) {
                  // Clone child with parent props, hence, all FileUploader props will be available to children
                  return React.cloneElement(child, {
                    ...props,
                    ...fileUploaderProps,
                    shouldBeDisabled,
                    isUploadInProgress,
                  } as UploaderElementProps);
                }

                return child;
              })}
            </Stack>
          );
        }}
      />
    </FileUploaderContext.Provider>
  );
};

type UploadButtonProps = {
  buttonLabel?: string;
  onInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  variant?: ButtonProps['variant'];
  sx?: ButtonProps['sx'];
};
export function UploadButton({
  // DirectUploaderRenderProps
  handleUpload,
  uploads,
  // DerivedRenderProps
  shouldBeDisabled,
  isUploadInProgress,
  buttonLabel = `Upload file`,
  onInputChange,
  variant = 'outlined',
  sx,
}: UploaderElementProps & UploadButtonProps) {
  const uploaderCtx = useContext(FileUploaderContext);
  const { enqueueSnackbar } = useSnackbar();
  const uploadingFileCount = uploads?.length || 1;
  // This is the cumulative progress of all uploads if there are multiple files being uploaded
  // This is just a step towards making the component handle multiple files when needed
  const cumulativeUploadProgress = uploads?.reduce((acc, upload) => acc + (upload?.progress || 0), 0) || 0;
  const cumulativeUploadPercentage = `${Math.round(cumulativeUploadProgress / uploadingFileCount)}%`;

  const handleInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);

    const maxSize = uploaderCtx?.fileSizeLimit || DEFAULT_FILE_SIZE_LIMIT;
    const tooLargeFiles = anyInvalidFileSize(selectedFiles, maxSize);
    if (tooLargeFiles && tooLargeFiles.length) {
      e.target.value = '';
      const fileNames = tooLargeFiles.map(f => f.name).join(', ');
      enqueueSnackbar(
        `File${tooLargeFiles.length > 1 ? 's' : ''} too large: ${fileNames}. Maximum acceptable size is ${maxSize}MB.`,
        { variant: 'error' },
      );
      return;
    }

    // Check if adding these files would exceed the limit
    if (uploaderCtx?.multiple && (uploaderCtx.files?.length || 0) + selectedFiles.length > uploaderCtx.multiple) {
      enqueueSnackbar(`You can only upload up to ${uploaderCtx.multiple} files`, { variant: 'warning' });
      e.target.value = '';
      return;
    }

    if (onInputChange) onInputChange(e);

    uploaderCtx?.setFormDisabled?.();
    try {
      await handleUpload?.(e.target.files!);
      e.target.value = ''; // without this, input won't trigger onChange event if the same file is selected again
    } catch (err) {
      console.error(err);
      uploaderCtx?.setFiles?.([]);
      enqueueSnackbar('Something went wrong when uploading file. Try again.', { variant: 'error' });
    }
  };
  const getLabel = () => {
    if (isUploadInProgress) return cumulativeUploadPercentage;
    if (uploaderCtx?.isAttachmentBeingProcessed) return 'Processing...';
    if (uploaderCtx?.isThereAnExistingFile && uploaderCtx?.multiple) return `Upload more ${uploaderCtx?.fileTypeName}s`;
    if (uploaderCtx?.isThereAnExistingFile && !uploaderCtx?.multiple) return `Replace ${uploaderCtx?.fileTypeName}`;
    if (uploaderCtx?.multiple) return `Upload files`;
    return buttonLabel;
  };

  const getIcon = () => {
    if (isUploadInProgress || uploaderCtx?.isAttachmentBeingProcessed) {
      return <CircularProgress size='1em' sx={{ color: t => t.palette.action.disabled }} />;
    }
    return <UploadFile />;
  };

  return (
    <Row maxWidth='600px' gap={2}>
      <Stack alignItems='center'>
        <KyronTooltip
          inactive={!uploaderCtx?.fileSizeLimit && !uploaderCtx?.multiple}
          placement='left'
          title={
            <Stack gap={0}>
              <Typography variant='labelSmall'>File limit:</Typography>
              <Typography variant='bodySmall'>
                {uploaderCtx?.multiple ? (
                  <>
                    {`• ${uploaderCtx?.multiple} files total`}
                    <br />
                  </>
                ) : null}
                {uploaderCtx?.fileSizeLimit
                  ? `• ${uploaderCtx?.fileSizeLimit}MB${uploaderCtx?.multiple ? ' each' : ''}`
                  : null}
              </Typography>
            </Stack>
          }
        >
          <Button
            component='label'
            sx={{
              minWidth: '156px',
              // we use this button on images sometimes and MUI removes the background when button is disabled
              // that doesn't work for us when button is rendered on an image because we lose the contrast greatly
              // therefore, I am overriding default disabled background color to be just the regular paper background
              '&.Mui-disabled': { backgroundColor: theme => theme.palette.background.paper },
              ...sx,
            }}
            startIcon={getIcon()}
            disabled={shouldBeDisabled}
            variant={variant}
            className='upload-button'
          >
            {getLabel()}
            <VisuallyHiddenInput
              type='file'
              multiple={!!uploaderCtx?.multiple}
              accept={uploaderCtx?.acceptTypes}
              disabled={shouldBeDisabled}
              onChange={handleInputChange}
              data-testid='file-uploader-input'
            />
          </Button>
        </KyronTooltip>
      </Stack>
    </Row>
  );
}
