import { Box, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { Row } from 'components/Row/Row';
import React from 'react';

type ValuePropBlockProps = {
  headline: string;
  subhead: string;
  children: React.ReactNode;
  image: React.ReactNode;
};

export function ValuePropBlock({ headline, subhead, image, children }: ValuePropBlockProps) {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));

  return (
    <Row sx={{ gap: { sm: 8, xs: 3 }, alignItems: 'flex-start', flexDirection: { sm: 'row', xs: 'column' } }}>
      <Stack flex={1} gap={isMobile ? 1 : 3}>
        <Typography variant='bodyLarge' sx={{ fontSize: { sm: '24px', xs: '18px' }, color: 'text.secondary' }}>
          {subhead}
        </Typography>
        <Typography variant={isMobile ? 'headlineLarge' : 'displayMedium'} component='h3'>
          {headline}
        </Typography>
        <Typography
          variant='bodyLarge'
          sx={{ fontSize: { sm: '20px', xs: '18px' }, lineHeight: { sm: '28px', xs: '24px' } }}
        >
          {children}
        </Typography>
      </Stack>
      <Box sx={{ flex: 1, width: { sm: 'auto', xs: '100%' } }}>{image}</Box>
    </Row>
  );
}
