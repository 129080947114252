import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import IconButton from '@mui/material/IconButton';
import Hark from 'hark';
import { useSnackbar } from 'notistack';
import { RecordRTCPromisesHandler, StereoAudioRecorder } from 'recordrtc';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

const styles = {
  mic: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    boxShadow: '0px 0.8px 1.5px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.2)',
    borderRadius: '20px',
    adding: 10,
    zindex: 100,
    color: 'white',
  },
  micOn: {
    background: '#28B446',
  },
  micOff: {
    background: '#C03838',
  },
};

// This is the sample rate it will convert for DialogFlow
const DESIRED_SAMPLE_RATE = 48000;
// This is the mimeType we would desire, but based on browser RecordRTC will opt for one that works with that browser.
const MIME_TYPE = 'audio/webm';
/*
 Force audio to be in mono. Dialogflow doesn't support stereo
 audio because it's passing the wrong default values to GCP Speech
 API and we can't specify stereo audio in RecognitionConfig.
 */
const AUDIO_CHANNEL = 1;

export const AudioRecorder = ({ handleUtterance, textSelected }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { enqueueSnackbar } = useSnackbar();
  const [recording, setRecording] = useState(false);
  const [streamState, setStreamState] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [spaceBarPressed, setSpaceBarPressed] = useState(null);
  const handleStopRecording = useCallback(
    async force => {
      const handleAudioData = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64String = reader.result;
          const audioString = base64String.split(',')[1];

          handleUtterance(audioString).then(response => {
            console.debug('handleAudioData: resp: ', response);
          });
        };
        if (recording) {
          mediaRecorder.stopRecording();
          setRecording(false);
        }
      };
      if (!mediaRecorder) {
        return;
      }
      const recorderState = await mediaRecorder.getState();
      if ((recording || force) && recorderState === 'recording') {
        await mediaRecorder.stopRecording();
        handleAudioData(await mediaRecorder.getBlob());
        setRecording(false);
      }
    },
    [handleUtterance, mediaRecorder, recording],
  );

  const handleStartRecording = useCallback(() => {
    if (!recording) {
      mediaRecorder.startRecording();
      setRecording(true);

      if (!spaceBarPressed) {
        let speechEvents = null;
        speechEvents = Hark(streamState, {});
        speechEvents.on('stopped_speaking', () => {
          handleStopRecording(true);
        });
      }
    }
  }, [handleStopRecording, mediaRecorder, recording, spaceBarPressed, streamState]);

  useEffect(() => {
    const loadStream = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new RecordRTCPromisesHandler(stream, {
        type: 'audio',
        mimeType: MIME_TYPE,
        desiredSampRate: DESIRED_SAMPLE_RATE,
        recorderType: StereoAudioRecorder,
        numberOfAudioChannels: AUDIO_CHANNEL,
      });
      if (localStorage.getItem('permissionsGranted') !== 'true') {
        if (!isMobile) {
          enqueueSnackbar('Hint: Try holding down the spacebar to unmute if you have a keyboard', { variant: 'info' });
        }
        localStorage.setItem('permissionsGranted', 'true');
      }
      setStreamState(stream);
      setMediaRecorder(recorder);
    };

    loadStream().catch(err => console.error('Could not create stream', err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (!textSelected && spaceBarPressed) {
      handleStartRecording();
    }
    if (!textSelected && spaceBarPressed === false) {
      handleStopRecording();
    }
  }, [handleStartRecording, handleStopRecording, spaceBarPressed, textSelected]);

  useEffect(() => {
    // Spacebar event listeners
    const handleKeyDown = e => {
      if (e.code === 'Space') {
        setSpaceBarPressed(true);
      }
    };

    const handleKeyUp = e => {
      if (e.code === 'Space') {
        setSpaceBarPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [setSpaceBarPressed]);

  const micButton = recording ? (
    <IconButton
      data-testid='student_audio_unmuted'
      style={{ ...styles.mic, ...styles.micOn }}
      aria-label='Microphone On'
      onClick={handleStopRecording}
    >
      <MicIcon fontSize='large' />
    </IconButton>
  ) : (
    <Tooltip key='audio' placement='bottom' title='Hold spacebar or click to unmute'>
      <IconButton
        data-testid='student_audio_muted'
        style={{ ...styles.mic, ...styles.micOff }}
        aria-label='Microphone Off'
        onClick={handleStartRecording}
      >
        <MicOffIcon fontSize='large' />
      </IconButton>
    </Tooltip>
  );

  return <div>{micButton}</div>;
};

AudioRecorder.propTypes = {
  handleUtterance: PropTypes.func.isRequired,
  textSelected: PropTypes.bool.isRequired,
};
