import React, { useState } from 'react';
import { Box, Stack, CardContent, Typography, Button, Card } from '@mui/material';
import { Edit, PlayCircleOutline, ContentCopy, Check } from '@mui/icons-material';
import { KyronImage } from '../../KyronImage/KyronImage';
import KyronIcon from '../../../assets/kyronicon-small.svg';
import { Lesson } from '../../../controllers/types';

function LessonCopyButton({ lessonId }: { lessonId: number }) {
  const [lessonLinkCopied, setLessonLinkCopied] = useState(false);
  return (
    <Button
      variant='outlined'
      startIcon={lessonLinkCopied ? <Check /> : <ContentCopy />}
      data-testid='lesson-copy-link-button'
      onClick={() => {
        navigator.clipboard.writeText(`${window.location.origin}/video_player/${lessonId}`);
        setLessonLinkCopied(true);
        setTimeout(() => setLessonLinkCopied(false), 2000);
      }}
    >
      {lessonLinkCopied ? 'Link Copied' : 'Copy Lesson Link'}
    </Button>
  );
}

export function LessonCard({ lesson }: { lesson: Lesson }) {
  return (
    <Card key={lesson.id}>
      <CardContent>
        <Stack direction='row' gap={4}>
          <KyronImage
            src={lesson.video_thumbnail}
            fallbackSrc={KyronIcon}
            alt='lesson collection thumbnail'
            dataTestID='thumbnail'
            size={200}
          />
          <Stack flexGrow={1} justifyContent='space-between'>
            <Stack direction='row' justifyContent='space-between'>
              <Typography variant='titleLarge'>{lesson.name}</Typography>
              <Button
                startIcon={<Edit />}
                data-testid='lesson-edit-button'
                href={`/studio/courses/${lesson.id}`}
                variant='text'
                color='secondary'
              >
                Edit
              </Button>
            </Stack>
            <Typography>{lesson.description}</Typography>
            <Box justifyContent='flex-end'>
              <Stack direction='row' gap={2} alignItems='center' justifyContent='flex-end'>
                <LessonCopyButton lessonId={lesson.id} />
                <Button
                  startIcon={<PlayCircleOutline />}
                  data-testid='lesson-start-button'
                  href={`/video_player/${lesson.id}`}
                  target='_blank'
                >
                  Start Lesson
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
