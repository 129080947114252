import { LoaderFunctionArgs } from 'react-router-dom';
import { KyronClient } from '../components/utils/KyronClient';
import { Role, User, InviteUserResponse } from './types';

export async function getCurrentUser(): Promise<User> {
  const client = new KyronClient();
  return client.getDataWithError('/api/v1/users/current');
}

export async function getUsers({ request }: LoaderFunctionArgs): Promise<{
  users: User[];
  meta: { page: number; total_pages: number };
}> {
  const url = new URL(request.url);
  const page = url.searchParams.get('page') || 1;
  const perPage = url.searchParams.get('perPage') || 25;
  const client = new KyronClient();
  return client.getDataWithError(`/api/v1/users?page=${page}&per_page=${perPage}`);
}

export async function updateUser(user: User): Promise<User> {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax
  for (const property in user) {
    if (property === 'roles') {
      continue;
    }

    const key = property as keyof User;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val = user[key] as any;

    formData.append(property, val);
  }
  user.roles.forEach(role => {
    formData.append('roles[]', JSON.stringify(role));
  });

  const client = new KyronClient();
  return client.submitJsonDataWithError(`/api/v1/users/${user.id}`, 'PUT', formData);
}

export async function addUserToOrganization(email: string, roles: Role[]): Promise<InviteUserResponse> {
  const formData = new FormData();
  formData.append('email', email);
  roles.forEach(role => {
    formData.append('roles[]', JSON.stringify(role));
  });

  const client = new KyronClient();
  return client.submitJsonDataWithError(`/api/v1/users/add_to_organization`, 'PUT', formData);
}
