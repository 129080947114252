import React, { useState } from 'react';
import { Card, CardHeader, CardMedia, Typography, CardContent, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { updateTutor, deleteTutor } from 'controllers/tutors';
import { DeleteModal } from 'components/common/DeleteModal';
import Teacher from '../../../assets/images/kyronlogo.svg';
import { TutorEditorDetails } from './TutorEditorDetails';

const styles = {
  tutorImage: {
    paddingLeft: '2vh',
    maxHeight: '20vh',
    width: 'auto',
  },
  tutorCard: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export function TutorCard({ tutors, refetchTutors }) {
  const { enqueueSnackbar } = useSnackbar();
  const [tutorIdToDelete, setTutorIdToDelete] = useState(null);

  const handleTutorDelete = async tutorId => {
    try {
      await deleteTutor(tutorId);
      refetchTutors();
      enqueueSnackbar('Deleted Successfully');
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    } finally {
      setTutorIdToDelete(null);
    }
  };

  const handleTutorEdit = async tutor => {
    try {
      const response = await updateTutor(tutor);
      refetchTutors();
      enqueueSnackbar('Edited successfully');
      return response;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const confirm = tutorId => () => setTutorIdToDelete(tutorId);

  return (
    <>
      <Grid container spacing={2}>
        {tutors?.map(tutor => (
          <Grid xs={12} item key={tutor.id}>
            <Card key={tutor.id} styles={styles.tutorCard}>
              <CardHeader title={`${tutor.display_name}`} />
              <TutorEditorDetails
                tutor={tutor}
                handleTutorEdit={handleTutorEdit}
                handleTutorDelete={confirm(tutor.id)}
              />
              <CardMedia sx={styles.tutorImage} alt='tutor picture' component='img' image={tutor.picture || Teacher} />
              <CardContent>
                <Typography>{tutor.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <DeleteModal
        isOpen={!!tutorIdToDelete}
        handleCancelDelete={() => setTutorIdToDelete(null)}
        handleConfirmDelete={() => handleTutorDelete(tutorIdToDelete)}
      />
    </>
  );
}
