import React from 'react';
import AIAvatar from 'assets/kyron_avatar.svg';
import { useUserContext } from 'components/UserContext';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';

export function ConversationChatAvatar({ isAi }: { isAi: boolean }) {
  const { user } = useUserContext();
  let label: string = 'You';
  let image: string | undefined;

  if (user) {
    label = user.name;
    image = user.avatar_url;
  }

  if (isAi) {
    label = 'Kyron AI';
    image = AIAvatar;
  }

  return <KyronAvatar label={label} image={image} size={32} />;
}
