import React, { useState, useEffect } from 'react';
import { Button, Stack, Card, CardContent, CardActions, TextField, Typography } from '@mui/material';
import { Subscription } from 'controllers/types';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useCreateSubscription, useUpdateSubscription } from 'controllers/react-query/subscriptionHooks';
import { useSnackbar } from 'notistack';

type AdminBillingFormProps = {
  activeSubscription: Subscription | undefined;
};

export const AdminBillingForm = ({ activeSubscription }: AdminBillingFormProps) => {
  const { isPending: isCreatingSubscription, mutate: createSubscription } = useCreateSubscription();
  const { isPending: isUpdatingSubscription, mutate: updateSubscription } = useUpdateSubscription();
  const { enqueueSnackbar } = useSnackbar();
  const [subscription, setSubscription] = useState({
    expires_at: dayjs().add(1, 'month').toISOString(),
    number_of_seats: 1,
  });

  useEffect(() => {
    if (activeSubscription) {
      setSubscription({
        expires_at: activeSubscription.expires_at,
        number_of_seats: activeSubscription.number_of_seats,
      });
    }
  }, [activeSubscription]);

  const handleCreate = () => {
    createSubscription(
      { payload: subscription },
      {
        onError: error => {
          enqueueSnackbar(`Failed to create subscription: ${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  const handleUpdate = (id: number) => {
    updateSubscription(
      { subscriptionId: id, payload: subscription },
      {
        onError: error => {
          enqueueSnackbar(`Failed to update subscription: ${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (activeSubscription) {
      handleUpdate(activeSubscription.id);
    } else {
      handleCreate();
    }
  };

  return (
    <Card variant='outlined' sx={{ my: 3 }} data-testid='admin-billing-form'>
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant='titleMedium'>{activeSubscription ? 'Update' : 'Create'} Subscription</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Subscription End Date'
                onChange={date => setSubscription(prev => ({ ...prev, expires_at: date?.toISOString() || '' }))}
                value={dayjs(subscription.expires_at)}
                disablePast
              />
            </LocalizationProvider>
            <TextField
              label='Number of Seats'
              required
              type='number'
              data-testid='number-of-seats-input'
              error={subscription.number_of_seats < 1}
              value={subscription.number_of_seats}
              onChange={e => setSubscription(prev => ({ ...prev, number_of_seats: parseInt(e.target.value, 10) }))}
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Button type='submit' disabled={isCreatingSubscription || isUpdatingSubscription} data-testid='submit-button'>
            Save
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};
