/* eslint-disable no-bitwise */
// This function takes a string and returns a color based on the string.
// The color is always the same for the same string.
// The color is usually a dark color that is suitable for backgrounds.
// The forBackground parameter can be set to false to get a lighter color that is more suitable for text.
export function stringToColor(string: string, forBackground = true) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & (forBackground ? 0x7f : 0xff);
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}
