import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function DeleteModal({
  isOpen,
  handleConfirmDelete,
  handleCancelDelete,
}: {
  isOpen: boolean;
  handleConfirmDelete: () => void;
  handleCancelDelete: () => void;
}) {
  return (
    <Dialog open={isOpen} onClose={handleCancelDelete} data-testid='deleteModal'>
      <DialogTitle>Confirm Deletion</DialogTitle>

      <DialogContent>
        <DialogContentText>Are you sure you want to delete this?</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCancelDelete} data-testid='cancelButton'>
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color='secondary' data-testid='confirmButton'>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

DeleteModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
  handleCancelDelete: PropTypes.func.isRequired,
};
