// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`MemberListItem renders as expected 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  border-bottom: 1px solid #D6D5DD;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 56px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.emotion-0.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-0.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-0.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-0.Mui-disabled {
  opacity: 0.38;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  gap: 8px;
  width: 30%;
}

.emotion-2 {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.emotion-2 .MuiListItemText-secondary {
  color: inherit;
}

.emotion-3 {
  margin: 0;
  display: block;
}

.emotion-4 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #BA1A1A;
}

.emotion-4::-moz-focus-inner {
  border-style: none;
}

.emotion-4.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-4 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-4:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-4:hover {
    background-color: transparent;
  }
}

.emotion-4.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-5 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-6 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<li
    class="MuiListItem-root MuiListItem-gutters MuiListItem-divider emotion-0"
  >
    <div
      class="MuiStack-root emotion-1"
    >
      <div
        class="MuiListItemText-root emotion-2"
      >
        <span
          class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary emotion-3"
        >
          Test User
        </span>
      </div>
    </div>
    <div>
      RoleSelect
    </div>
    <button
      class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-4"
      data-testid="delete-member-button"
      tabindex="0"
      type="button"
    >
      <svg
        aria-hidden="true"
        class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-5"
        data-testid="DeleteOutlineIcon"
        focusable="false"
        viewBox="0 0 24 24"
      >
        <path
          d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM8 9h8v10H8zm7.5-5-1-1h-5l-1 1H5v2h14V4z"
        />
      </svg>
      <span
        class="MuiTouchRipple-root emotion-6"
      />
    </button>
  </li>
</DocumentFragment>
`;
