/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Stack, Collapse, Card } from '@mui/material';
import { Unit } from './Unit';
import { UnitForm } from './UnitForm';
import { ShowLessons } from './ShowLessons';
import { withDeleteModal } from '../utils/DeleteModalHOC';
import { NewCollection } from './NewCollection';

const styles = {
  Unitcardfield: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

const UnitDM = withDeleteModal(Unit, props => {
  props.handleUnitDelete(props.unit.id);
});

export const UnitEdit = props => {
  const [editable, setEditable] = useState(false);
  const handleUnitEditCancel = () => {
    setEditable(false);
  };

  const [newCollectionExpanded, setNewCollectionExpanded] = useState(false);

  const handleNewCollectionExpandClick = () => {
    setNewCollectionExpanded(!newCollectionExpanded);
  };

  const handleUnitEdit = () => {
    setEditable(true);
  };

  const handleFormSubmit = (_func1, _func2, unit) => {
    if (editable) {
      _func1(_func2, unit);
    }
    setEditable(!editable);
  };

  const cardContent = editable ? (
    <UnitForm
      unit={props.unit}
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={handleUnitEditCancel}
      handleUnitUpdate={props.handleUnitUpdate}
      addOrUpdateUnit={props.updateUnit}
      courses={props.courses}
    />
  ) : (
    <>
      <UnitDM
        unit={props.unit}
        courses={props.courses}
        handleFormSubmit={handleFormSubmit}
        handleFormCancel={handleUnitEditCancel}
        handleUnitEdit={handleUnitEdit}
        addOrUpdateUnit={props.updateUnit}
        handleUnitUpdate={props.handleUnitUpdate}
        units={props.units}
        lessons={props.lessons}
        handleLessonAdd={props.handleLessonAdd}
        handleLessonUpdate={props.handleLessonUpdate}
        handleLessonRemove={props.handleLessonRemove}
        handleUnitDelete={props.handleUnitDelete}
      />
      <Box marginLeft='20px' marginRight='20px' marginBottom='20px'>
        <ShowLessons
          unit={props.unit}
          units={props.units}
          lessons={props.lessons}
          handleLessonAdd={props.handleLessonAdd}
          handleLessonUpdate={props.handleLessonUpdate}
          handleLessonRemove={props.handleLessonRemove}
          lessonCollections={props.lessonCollections}
          handleCollectionDelete={props.handleCollectionDelete}
          handleAddCollection={props.handleAddCollection}
          handleCollectionUpdate={props.handleCollectionUpdate}
          reorderCollection={props.reorderCollection}
          reorderLesson={props.reorderLesson}
        />
        <Stack direction='row' spacing={2} marginBottom='20px'>
          <div>
            <Button variant='contained' onClick={handleNewCollectionExpandClick} data-testid='add-collection-button'>
              Add Collection
            </Button>
          </div>
        </Stack>
        <div>
          <Collapse in={newCollectionExpanded} timeout='auto' unmountOnExit>
            <NewCollection
              unit={props.unit}
              handleFormSubmit={props.handleAddCollection}
              units={props.units}
              handleEditCollectionCancel={handleNewCollectionExpandClick}
            />
          </Collapse>
        </div>
      </Box>
    </>
  );

  return <Card style={styles.Unitcardfield}>{cardContent}</Card>;
};

/* eslint-disable react/forbid-prop-types */
UnitEdit.propTypes = {
  handleUnitDelete: PropTypes.func,
  handleUnitUpdate: PropTypes.func,
  updateUnit: PropTypes.func,
  unit: PropTypes.object,
  courses: PropTypes.array,
  units: PropTypes.array,
  lessons: PropTypes.array,
  handleLessonAdd: PropTypes.func,
  handleLessonUpdate: PropTypes.func,
  handleLessonRemove: PropTypes.func,
  handleAddCollection: PropTypes.func,
  lessonCollections: PropTypes.array,
  handleCollectionDelete: PropTypes.func,
  handleCollectionUpdate: PropTypes.func,
  reorderCollection: PropTypes.func,
  reorderLesson: PropTypes.func,
};
