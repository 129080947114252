import React from 'react';
import { Grid, Skeleton, Box } from '@mui/material';

export function OverallAnalyticsSkeleton() {
  return (
    <Grid container spacing={2} justifyContent='flex-start' sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <Skeleton variant='rounded' height={75} width='100%' />
      </Grid>
      <Box width='100%' />
      <Grid item xs={12}>
        <Skeleton variant='rounded' height={75} width='100%' />
      </Grid>
      <Box width='100%' />
      <Grid item sm={4} xs={12}>
        <Skeleton variant='rounded' height={150} width='100%' />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Skeleton variant='rounded' height={150} width='100%' />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Skeleton variant='rounded' height={150} width='100%' />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Skeleton variant='rounded' height={150} width='100%' />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Skeleton variant='rounded' height={150} width='100%' />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Skeleton variant='rounded' height={150} width='100%' />
      </Grid>
      <Box width='100%' />
      <Grid item sm={2} />
      <Grid item sm={4} xs={12}>
        <Skeleton variant='rounded' height={150} width='100%' />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Skeleton variant='rounded' height={150} width='100%' />
      </Grid>
    </Grid>
  );
}
