/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Grid, Box } from '@mui/material';
import { Course } from './Course';
import { CourseForm } from './CourseForm';
import { ShowUnit } from './ShowUnit';
import { withDeleteModal } from '../utils/DeleteModalHOC';

const styles = {
  Coursecardfield: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

const CourseDM = withDeleteModal(Course, props => {
  props.handleCourseDelete(props.course.id);
});

export const CourseEdit = props => {
  const [editable, setEditable] = useState(false);
  const handleCourseEditCancel = () => {
    setEditable(false);
  };

  const handleCourseEdit = () => {
    setEditable(true);
  };

  const handleFormSubmit = (_func1, _func2, course) => {
    if (editable) {
      _func1(_func2, course);
    }
    setEditable(!editable);
  };

  const cardContent = editable ? (
    <CourseForm
      course={props.course}
      handleFormSubmit={handleFormSubmit}
      handleFormCancel={handleCourseEditCancel}
      addOrUpdateCourse={props.updateCourse}
      handleCourseUpdate={props.handleCourseUpdate}
    />
  ) : (
    <>
      <CourseDM
        course={props.course}
        handleFormSubmit={handleFormSubmit}
        handleFormCancel={handleCourseEditCancel}
        handleCourseEdit={handleCourseEdit}
        addOrUpdateCourse={props.updateCourse}
        handleCourseUpdate={props.handleCourseUpdate}
        handleCourseDelete={props.handleCourseDelete}
        reorderCourses={props.reorderCourses}
      />
      <Box marginLeft='20px' marginRight='20px' marginBottom='20px'>
        {props.course.children?.map(unit => (
          <Grid item key={unit.id} marginBottom='20px'>
            <ShowUnit unit={unit} length={props.course.children?.length} reorderUnits={props.reorderUnits} />
          </Grid>
        ))}
      </Box>
    </>
  );

  return <Card style={styles.Coursecardfield}>{cardContent}</Card>;
};

CourseEdit.propTypes = {
  handleCourseDelete: PropTypes.func,
  handleCourseUpdate: PropTypes.func,
  updateCourse: PropTypes.func,
  reorderCourses: PropTypes.func,
  reorderUnits: PropTypes.func,
  course: PropTypes.object,
};
