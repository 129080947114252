/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Box, Grid, TextField, MenuItem, Tooltip, InputAdornment, Fab } from '@mui/material';
import { Delete, ArrowUpward, ArrowDownward, Add } from '@mui/icons-material';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';

const styles = {
  TOCRow: {
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
};

export const TOCRow = props => {
  const takenSegments = () => {
    const taken = [];
    props.tableOfContents.forEach(toc => {
      taken.push(toc.lesson_segment_id);
    });
    return taken;
  };

  const indentButton = () => {
    if (props.tableOfContent.position % 1 === 0) {
      return (
        <Grid item>
          <IconButton
            onClick={() => {
              if (props.tableOfContent.position > 1) {
                props.updateSegmentTOC('indent', props.tableOfContent.id);
              }
            }}
            fontSize='small'
          >
            <FormatIndentIncreaseIcon />
          </IconButton>
        </Grid>
      );
    }
    return (
      <Grid item>
        <Box paddingLeft='2em'>
          <IconButton onClick={() => props.updateSegmentTOC('unindent', props.tableOfContent.id)} fontSize='small'>
            <FormatIndentDecreaseIcon />
          </IconButton>
        </Box>
      </Grid>
    );
  };

  const allMenuItems = () => {
    const menuItems = [];
    props.allSegmentNamesIds.forEach(segment => {
      menuItems.push(
        <MenuItem value={segment[0]} key={segment[0]} disabled={takenSegments().includes(segment[0])}>
          {segment[1]}
        </MenuItem>,
      );
    });
    return menuItems;
  };

  return (
    <Grid container spacing={2} sx={styles.TOCRow} data-testid={`toc-row-${props.tableOfContent.id}`}>
      {indentButton()}
      <Grid item xs>
        <TextField
          width='100%'
          name='label'
          label='Label'
          data-testid='toc-label'
          fullWidth
          value={props.tableOfContent?.label || ''}
          onChange={event => props.updateSegmentTOC(event, props.tableOfContent.id)}
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          name='lesson_segment_id'
          fullWidth
          select
          label='Segment'
          data-testid='toc-segment'
          sx={{ borderColor: takenSegments() ? 'red' : 'black' }}
          value={props.tableOfContent?.lesson_segment_id ? props.tableOfContent?.lesson_segment_id : ''}
          onChange={event => props.updateSegmentTOC(event, props.tableOfContent.id)}
          InputProps={{
            endAdornment:
              props.tableOfContent === props.last ? (
                <>
                  <InputAdornment position='end'>
                    <Tooltip title='Delete'>
                      <Fab
                        size='small'
                        data-testid='delete-toc'
                        color='primary'
                        onClick={() => props.deleteRow(props.tableOfContent, props.tableOfContent.id)}
                      >
                        <Delete />
                      </Fab>
                    </Tooltip>
                  </InputAdornment>
                  <InputAdornment position='end'>
                    <Tooltip title='Add Row'>
                      <Fab size='small' color='primary' data-testid='add-toc' onClick={() => props.handleAddRow()}>
                        <Add />
                      </Fab>
                    </Tooltip>
                  </InputAdornment>
                </>
              ) : (
                <InputAdornment position='end'>
                  <Tooltip title='Delete'>
                    <Fab
                      size='small'
                      color='primary'
                      data-testid='delete-toc'
                      onClick={() => props.deleteRow(props.tableOfContent, props.tableOfContent.id)}
                    >
                      <Delete />
                    </Fab>
                  </Tooltip>
                </InputAdornment>
              ),
          }}
        >
          {allMenuItems()}
        </TextField>
      </Grid>
      <Grid item>
        <Grid container spacing={0.5}>
          <Grid item>
            <IconButton
              fontSize='small'
              data-testid='move-toc-up'
              onClick={() => {
                if (props.tableOfContent.position > 1) {
                  props.updateSegmentTOC('move up', props.tableOfContent.id);
                }
              }}
            >
              <ArrowUpward />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              fontSize='small'
              data-testid='move-toc-down'
              onClick={() => {
                if (props.tableOfContent !== props.last) {
                  props.updateSegmentTOC('move down', props.tableOfContent.id);
                }
              }}
            >
              <ArrowDownward />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

/* eslint-disable react/forbid-prop-types */
TOCRow.propTypes = {
  tableOfContent: PropTypes.object,
  tableOfContents: PropTypes.array,
  updateSegmentTOC: PropTypes.func,
  deleteRow: PropTypes.func,
  handleAddRow: PropTypes.func,
  last: PropTypes.object,
  allSegmentNamesIds: PropTypes.array,
};
