/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  CardActions,
  CardContent,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material';
import { KyronImage } from 'components/KyronImage/KyronImage';
import { DirectUploader } from '../Uploaders/DirectUploader';

export const CollectionForm = props => {
  const [collection, setCollection] = useState(
    props.collection
      ? props.collection
      : {
          id: null,
          name: '',
          description: '',
          parent_id: props.unit.id,
          lessons: [],
          collection_thumbnail: null,
          resource_link: '',
        },
  );

  const styles = {
    thumbnailImage: {
      marginRight: '10px',
      marginTop: '10px',
      maxHeight: '150px',
      maxWidth: '50vw',
    },
    thumbnailText: {
      marginTop: '10px',
    },
  };

  const handleInputChange = e => {
    if (e.target.name === 'unit') {
      e.target.value = props.units.find(unit => unit.id === parseInt(e.target.value, 10));
      setCollection(prevCollection => ({
        ...prevCollection,
        [e.target.name]: e.target.value,
        parent_id: e.target.value.id,
      }));
    } else {
      setCollection(prevCollection => ({
        ...prevCollection,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleThumbnailUpload = signedIds => {
    if (signedIds) {
      setCollection(prevCollection => ({
        ...prevCollection,
        custom_thumbnail: signedIds[0],
      }));
    }
  };

  return (
    <CardContent>
      <form
        data-testid='collection-form'
        onSubmit={e => {
          e.preventDefault();
          props.handleFormSubmit(collection, props.unit.id, null);
          props.handleEditCollectionCancel();
          e.target.reset();
        }}
      >
        <TextField
          variant='outlined'
          name='name'
          fullWidth
          margin='normal'
          label='Collection Name'
          type='text'
          onChange={handleInputChange}
          defaultValue={collection.name}
          required
          helperText='Enter the name of the collection'
        />
        <TextField
          variant='outlined'
          name='description'
          fullWidth
          margin='normal'
          label='Collection Description'
          type='text'
          onChange={handleInputChange}
          defaultValue={collection.description}
          helperText='Enter the description of the collection'
        />
        <TextField
          variant='outlined'
          name='resource_link'
          fullWidth
          margin='normal'
          label='Resource Link'
          type='text'
          onChange={handleInputChange}
          defaultValue={collection.resource_link}
          helperText="Enter the link to this collection's teacher resources"
        />
        <InputLabel id='unit-select-label'>Unit</InputLabel>
        <Select
          labelId='unit-select-label'
          id='unit-select'
          name='unit'
          label='Unit'
          defaultValue={props.collection ? props.collection.parent_id : props.unit.id}
          onChange={handleInputChange}
          required
          width='40%'
        >
          {props.units.map(unit => (
            <MenuItem key={unit.id} value={unit.id}>
              {unit.name}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ p: '16px 0 8px 0' }}>
          <KyronImage
            hide={!collection.collection_thumbnail}
            src={collection.collection_thumbnail}
            alt={`collection-thumbnail-${collection.name}`}
            dataTestID='thumbnail'
          />
        </Box>
        <Typography style={styles.thumbnailText}>Upload Thumbnail Image</Typography>
        <DirectUploader afterUpload={handleThumbnailUpload} multiple={false} acceptTypes={'image/*'} />
        <CardActions>
          <IconButton aria-label='submit collection' type='submit' data-testid='done-button'>
            <DoneIcon />
          </IconButton>
          <IconButton aria-label='cancel edit collection' onClick={() => props.handleEditCollectionCancel()}>
            <CancelIcon />
          </IconButton>
        </CardActions>
      </form>
    </CardContent>
  );
};

/* eslint-disable react/forbid-prop-types */
CollectionForm.propTypes = {
  collection: PropTypes.object,
  unit: PropTypes.object,
  handleEditCollectionCancel: PropTypes.func,
  handleFormSubmit: PropTypes.func,
  units: PropTypes.array,
};
