import { WhisperTranscriptionController } from './whisperTranscriptionController';
import { TranscriptionController, TranscriptionMessage, AudioMetadata } from './types';

export class TranscriptionControllerFactory {
  static create(
    handlePartialTranscript: (message: TranscriptionMessage) => void,
    handleFinalTranscript: (message: TranscriptionMessage) => void,
    handleAudioMetadata?: (data: AudioMetadata) => void,
  ): TranscriptionController {
    return new WhisperTranscriptionController(handlePartialTranscript, handleFinalTranscript, handleAudioMetadata);
  }
}
