import { createConsumer } from '@rails/actioncable';
import { useEffect, useState } from 'react';
import { useCurrentUser } from '../controllers/react-query/userHooks';
import { devConsole } from '../components/utils/logUtils';
import { BackgroundTask } from '../controllers/types';
import { runAsyncInvalidations } from '../controllers/react-query/kyronMutation';

export type CategorizedTasks = {
  lessonPlanning: BackgroundTask[];
  lessonPublishing: BackgroundTask[];
  lessonTranslation: BackgroundTask[];
  videoGeneration: BackgroundTask[];
  videoRegeneration: BackgroundTask[];
  all: BackgroundTask[];
};

export const emptyCategorizedTasks: CategorizedTasks = {
  lessonPlanning: [],
  lessonPublishing: [],
  lessonTranslation: [],
  videoGeneration: [],
  videoRegeneration: [],
  all: [],
};

export const initialBackgroundTasks = {
  active: emptyCategorizedTasks,
  failed: emptyCategorizedTasks,
};

export type UserNotifications = {
  backgroundTasks: {
    active: CategorizedTasks;
    failed: CategorizedTasks;
    done?: CategorizedTasks;
  };
};

/**
 * User notifications listener hook.
 */
export function useUserNotifications() {
  const { data: user } = useCurrentUser();
  const [notifications, setNotifications] = useState<UserNotifications>({ backgroundTasks: initialBackgroundTasks });

  useEffect(subscribeToChannel, [user]);
  function subscribeToChannel() {
    if (!user) devConsole.warn('Cannot subscribe to BTProgressChannel: User is not defined');

    if (user) {
      const subscription = subscribeToUserNotificationChannel(user.id, payload => {
        setNotifications(prevNotif => ({ ...prevNotif, ...payload }));
      });

      return () => {
        subscription.unsubscribe();
        // consumer.disconnect(); // this removes all subscriptions, not sure yet if we should have it here
      };
    }
  }

  // What's Async Invalidations?
  // When a mutation request starts a background task in the backend, we need to watch for that task so we can
  // invalidate the react-query cache for the resource when it is done to be able to show changes that background task
  // had on the resource. To do that, useKyronMutationV2 hook sets pendingAsynchronousInvalidations in the local storage.
  // Then, we watch for those pending invalidations here and invalidate the cache for the resource when the task is done.
  const stringifiedData = JSON.stringify(notifications); // for deep comparison for change detection
  useEffect(handleAsyncInvalidations, [notifications, stringifiedData]);
  function handleAsyncInvalidations() {
    // Consolidate "done" and "error"d tasks to a single array as they are both considered finished
    // And we want to run the async invalidation for them.
    const allDone = notifications.backgroundTasks?.done?.all || [];
    const allFailed = notifications.backgroundTasks?.failed?.all || [];

    // Not waiting the promise returned below on purpose, we don't need to wait for invalidations to finish.
    // UI will react regardless as that part is handled by react-query.
    runAsyncInvalidations([...allDone, ...allFailed]);
  }

  return notifications;
}

/** ***************************************************************************
 * ActionCable subscription helper
 **************************************************************************** */
type SubscriptionFnOnReceived = (d: UserNotifications) => void;

function subscribeToUserNotificationChannel(userId: number | string, onReceived: SubscriptionFnOnReceived) {
  const consumer = createConsumer('/cable');

  return consumer.subscriptions.create(
    { channel: 'UserNotificationChannel', userId },
    {
      // Note (finding): received callback has to be initialized here (even if it doesn't do anything) in order to be
      // able to call subscription.received to add more received callbacks after subscription.
      received: data => {
        if (data && typeof data !== 'function') {
          if (data.cable_error) {
            devConsole.error('Error in UserNotificationChannel:', JSON.stringify(data, null, 2));
          }
          // TODO(EGE): This is a weird behavior from ActiveCable subscription, it passes the received function as the
          //  argument on first render. Not sure why. So I am guarding against setting the received callback in tha state.
          onReceived?.(data);
        }
      },
    },
  );
}
