import React from 'react';
import { Button } from '@mui/material';
import { useModal } from 'components/utils/ModalContext';
import { Lesson } from 'controllers/types';
import { KyronEvents } from 'components/utils/KyronEvents';
import { ExportCourseDialog } from './ExportCourseDialog';

export function PublishButton({ lesson, disabled }: { lesson?: Lesson; disabled?: boolean }) {
  const { openModal, closeModal } = useModal();

  function openExportModal() {
    KyronEvents.sendEvent(KyronEvents.names.CLICK_EXPORT, { lesson_id: lesson?.id });
    openModal({
      id: 'export-modal',
      title: 'Export course',
      content: <ExportCourseDialog lessonId={lesson?.id} onClose={() => closeModal()} isProcessingCourse={disabled} />,
    });
  }

  return (
    <Button onClick={openExportModal} data-testid='export-course' disabled={disabled || !lesson?.id}>
      Export Course
    </Button>
  );
}
