/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import TextIcon from '@mui/icons-material/Chat';
import SubmitIcon from '../../../assets/submit_arrow.svg';

export const TextInput = props => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '55px',
      columnGap: '15px',
    },
    textWrapper: {
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '36vw',
      marginLeft: '20px',
    },
    icon: {
      boxShadow: '0px 0.8px 1.5px rgba(0, 0, 0, 0.1), 0px 6px 12px rgba(0, 0, 0, 0.2)',
      borderRadius: '20px',
      zindex: 100,
      padding: 10,
    },
    iconSelected: {
      color: '#FFFFFF',
      background: '#235E83',
    },
    iconNotSelected: {
      color: '#235E83',
      background: '#FFFFFF',
    },
  };
  const buttonRef = useRef(null);
  const [textInput, setTextInput] = useState('');

  const handleInputChange = e => {
    setTextInput(e.target.value);
  };

  const handleSelectedChange = () => {
    props.handleSelectedChange();
    buttonRef.current.blur();
  };

  return (
    <div style={styles.container}>
      <Tooltip
        key='text'
        placement='bottom'
        title={props.textSelected ? 'Click to collapse text box' : 'Click to expand text box'}
      >
        <IconButton
          data-testid='student-text-icon'
          aria-label='student input button'
          style={{
            ...styles.icon,
            ...(props.textSelected ? styles.iconSelected : styles.iconNotSelected),
          }}
          onClick={handleSelectedChange}
          ref={buttonRef}
        >
          <TextIcon fontSize='large' />
        </IconButton>
      </Tooltip>
      {props.textSelected && (
        <form
          data-testid='student-text-form'
          style={styles.textWrapper}
          autoComplete='off'
          onSubmit={e => {
            e.preventDefault();
            props.handleText(textInput);
            e.target.reset();
          }}
        >
          <TextField
            name='textInput'
            sx={{
              '& .MuiOutlinedInput-root .MuiInputBase-input': {
                border: '4px solid #235E83',
                borderRadius: '30px',
              },
              '& .MuiOutlinedInput-root': {
                '& > fieldset': { border: 'none' },
              },
              '& .MuiOutlinedInput-input': {
                backgroundColor: 'background.default',
              },
            }}
            variant='outlined'
            autoFocus
            type='text'
            fullWidth
            onChange={handleInputChange}
            required
            placeholder='Enter Text Response'
            inputProps={{
              'aria-label': 'student response',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    data-testid='student-input-button'
                    aria-label='submit input button'
                    type='submit'
                    fontSize='large'
                    edge='end'
                  >
                    <img alt='Submit' src={SubmitIcon} style={{ width: '30px', height: 'auto' }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </form>
      )}
    </div>
  );
};

TextInput.propTypes = {
  handleText: PropTypes.func.isRequired,
  textSelected: PropTypes.bool.isRequired,
  handleSelectedChange: PropTypes.func.isRequired,
};
