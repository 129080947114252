import React from 'react';
import { Chip, ChipProps, Stack } from '@mui/material';
import { Lesson } from 'controllers/types';
import { getLessonCollectionURL } from 'components/utils/urlUtils';
import { Link as RouterLink } from 'react-router-dom';

enum LABELS {
  unpublished = 'Unpublished Changes',
  live = 'Live',
}

type StatusChipProps = {
  label: LABELS;
  bgcolor?: string;
  to?: string;
} & ChipProps;
const StatusChip = ({ label, bgcolor, ...props }: StatusChipProps) => (
  <Chip label={label} sx={{ bgcolor, boxShadow: 1, borderRadius: 1, fontWeight: 'bold' }} {...props} />
);

export function LessonStatusTags({ lesson }: { lesson?: Lesson }) {
  if (!lesson) {
    return null;
  }

  const publishedLessonCollection = lesson.published_lesson_collection;
  const hasUnpublishedChanges = Boolean(lesson.has_unpublished_changes);

  return (
    <Stack direction='row' gap={1}>
      {publishedLessonCollection && (
        <StatusChip
          label={LABELS.live}
          bgcolor='successContainer.main'
          component={RouterLink}
          to={getLessonCollectionURL(publishedLessonCollection.id)}
          clickable
        />
      )}
      {hasUnpublishedChanges && <StatusChip label={LABELS.unpublished} />}
    </Stack>
  );
}
