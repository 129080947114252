import { Misconception, MisconceptionPayload } from 'controllers/types';
import { KyronClient } from '../components/utils/KyronClient';

export async function getMisconceptionPage(
  page: number,
  perPage: number,
): Promise<{
  nextPage: number;
  totalPages: number;
  totalCount: number;
  misconceptions: Misconception[];
}> {
  const client = new KyronClient();
  const {
    misconceptions,
    meta: { total_pages: totalPages, next_page: nextPage, total_count: totalCount },
  } = (await client.getDataWithError(`/api/v1/lesson_misconceptions?page=${page}&per_page=${perPage}`)) as {
    misconceptions: Misconception[];
    meta: {
      total_pages: number;
      next_page: number;
      total_count: number;
    };
  };

  return { misconceptions, totalPages, nextPage, totalCount };
}

export async function uploadMisconceptionCsv(signedId: number): Promise<{
  message: string;
}> {
  const formData = new FormData();
  formData.append('signed_id', signedId as unknown as Blob);

  const client = new KyronClient();
  return client.submitJsonDataWithError('api/v1/lesson_misconceptions/upload_csv', 'POST', formData);
}

export async function updateMisconception(misconception: Misconception): Promise<Misconception> {
  const client = new KyronClient();
  const formData = client.convertJsonToFormData(misconception);
  return client.submitJsonDataWithError(`/api/v1/lesson_misconceptions/${misconception.id}`, 'PUT', formData);
}

export async function deleteMisconception(misconceptionId: number): Promise<Misconception> {
  const client = new KyronClient();
  return client.deleteDataWithError(`/api/v1/lesson_misconceptions/${misconceptionId}`);
}

export async function createMisconception(misconception: MisconceptionPayload): Promise<Misconception> {
  const client = new KyronClient();
  const formData = client.convertJsonToFormData(misconception);
  return client.submitJsonDataWithError('/api/v1/lesson_misconceptions', 'POST', formData);
}

export async function getAllMisconceptions(): Promise<Misconception[]> {
  const PER_PAGE = 25;
  const responseData = await getMisconceptionPage(1, PER_PAGE);
  let { nextPage } = responseData;
  const { totalPages, misconceptions } = responseData;
  let allMisconceptions = misconceptions;
  while (nextPage && nextPage <= totalPages) {
    // eslint-disable-next-line no-await-in-loop
    const next = await getMisconceptionPage(nextPage, PER_PAGE);
    nextPage = next.nextPage;
    allMisconceptions = allMisconceptions.concat(next.misconceptions);
  }

  return allMisconceptions;
}
