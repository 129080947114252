// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`TypographyWithEllipsis applies the ellipsis styles with custom line count 1`] = `
<DocumentFragment>
  .emotion-0 {
  margin: 0;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

<p
    class="MuiTypography-root MuiTypography-body1 emotion-0"
  >
    This is a long text with multiple lines that should be truncated after the third line.
  </p>
</DocumentFragment>
`;

exports[`TypographyWithEllipsis applies the ellipsis styles with default line count 1`] = `
<DocumentFragment>
  .emotion-0 {
  margin: 0;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

<div
    style="max-width: 40px; width: 40px;"
  >
    <p
      class="MuiTypography-root MuiTypography-body1 emotion-0"
    >
      This is a long text that should be truncated with an ellipsis.
    </p>
    ,
  </div>
</DocumentFragment>
`;
