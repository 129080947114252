import React from 'react';
import { EditableInputField } from 'components/EditableInputField';
import { useUpdateLessonAndLessonCollection } from 'controllers/react-query';
import { LessonPayload } from 'controllers/types';

export function LessonNameField({ lesson }: { lesson: LessonPayload }) {
  const { mutate, isPending } = useUpdateLessonAndLessonCollection({
    lessonId: lesson.id,
    collectionId: lesson.lesson_collection?.id,
  });

  return (
    <EditableInputField
      value={lesson.name}
      onSave={name => mutate({ name })}
      label='Lesson Name'
      isLoading={isPending}
      disabled={!lesson?.name}
      isRequired
      textVariant='titleLarge'
    />
  );
}
