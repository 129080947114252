import { Stack } from '@mui/material';
import { Row } from 'components/Row/Row';
import { FileUploader, UploadButton } from 'components/Uploaders/FileUploader';
import { RemoveFileButton } from 'components/Uploaders/RemoveFileButton';
import { ActiveStorageBlob } from 'components/Uploaders/types';
import React from 'react';

type ImageInputProps = {
  image: ActiveStorageBlob | null;
  setImage: React.Dispatch<React.SetStateAction<ActiveStorageBlob | null>>;
  setFormImage: (image: string) => void;
};

export function ImageInput({ image, setImage, setFormImage }: ImageInputProps) {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        const newImageUrl = e.target?.result as string;
        setImage({ url: newImageUrl });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const uploadImage = (blobs: ActiveStorageBlob[]) => {
    const blob = blobs[0];
    if (!blob.signed_id) return;
    setFormImage(blob.signed_id);
  };

  const removeImage = () => {
    setFormImage('');
    setImage(null);
  };

  return (
    <Row gap={2}>
      {image?.url ? <img src={image.url} alt='preview' style={{ display: 'block', height: '100px' }} /> : null}
      <Stack gap={1} alignItems='flex-start'>
        <FileUploader afterUpload={uploadImage} acceptTypes='image/*' existingFile={image}>
          <UploadButton buttonLabel={image ? 'Replace image' : 'Choose image'} onInputChange={handleInputChange} />
          <RemoveFileButton onRemove={removeImage} />
        </FileUploader>
      </Stack>
    </Row>
  );
}
