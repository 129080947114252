import { Typography } from '@mui/material';
import styled from '@emotion/styled';

// Clips the text to the specified number of lines
export const ClippedTypography = styled(Typography)<{ lines: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
