import { Link, ListItem, ListItemText } from '@mui/material';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { Tutor } from 'controllers/types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

type TutorListItemProps = {
  tutor: Tutor;
};

export const TutorListItem = ({ tutor }: TutorListItemProps) => (
  <ListItem sx={{ height: 56 }} divider key={tutor.id} data-testid={`tutor-row-${tutor.id}`}>
    <KyronAvatar image={tutor.picture} label={tutor.display_name} sx={{ mr: 2 }} />
    <Link component={RouterLink} to={`${tutor.id}`}>
      <ListItemText primary={tutor.display_name} />
    </Link>
  </ListItem>
);
