import { Box, Stack, Typography } from '@mui/material';
import { LessonCollection } from 'controllers/types';
import React from 'react';
import { Link } from 'react-router-dom';
import { KyronEvents } from 'components/utils/KyronEvents';

type HomepageCourseCardProps = {
  course: LessonCollection;
};

export function HomepageCourseCard({ course }: HomepageCourseCardProps) {
  return (
    <Box
      data-testid='homepage-course-card'
      component={Link}
      sx={{
        display: 'block',
        aspectRatio: '16/9',
        bgcolor: 'primary.main',
        borderRadius: 3,
        cursor: 'pointer',
        position: 'relative',
        background: `url(${course.collection_thumbnail}) no-repeat center center / cover`,
        transition: 'box-shadow 0.1s ease-in-out, transform 0.1s ease-in-out',
        transform: 'translateY(0)',
        '&:hover, &:focus': {
          boxShadow: 3,
          transform: 'translateY(-2px)',
          '& .course-card-content': {
            opacity: 1,
          },
          '& .course-card-title': {
            transform: 'translateY(0)',
          },
        },
      }}
      onClick={() => KyronEvents.sendEvent(KyronEvents.names.PREVIEW_COURSE, { lesson_id: course.id })}
      to={`/courses/${course.id}`}
    >
      <Box
        className='course-card-content'
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          px: 3,
          py: '12px',
          bgcolor: 'rgba(0, 0, 0, 0.7)',
          borderRadius: '0 0 24px 24px',
          color: 'white',
          opacity: 0,
          transition: 'opacity 0.1s ease-in-out',
        }}
      >
        <Stack
          className='course-card-title'
          sx={{ transform: 'translateY(4px)', transition: 'transform 0.1s ease-in-out' }}
        >
          <Typography variant='labelMedium'>Course</Typography>
          <Typography variant='bodyLarge'>{course.name}</Typography>
        </Stack>
      </Box>
    </Box>
  );
}
