import { PaymentSession } from 'controllers/types';
import { useKyronQuery } from './kyronQuery';
import { useKyronMutationV2 } from './kyronMutation';

export const usePaymentSessionQuery = (sessionId?: string) =>
  useKyronQuery<PaymentSession>(`/payments/payment_session?session_id=${sessionId}`, {
    enabled: !!sessionId,
  });

export const usePublishableKeyQuery = () => useKyronQuery<{ publishable_key: string }>('/payments/publishable_key');

type CreateCheckoutSessionPayload = {
  subscription: string;
  redirectUrl: string;
};
export const useCreateCheckoutSession = () =>
  useKyronMutationV2<{ payload: CreateCheckoutSessionPayload }, { url: string }>(`/payments/create_checkout_session`, {
    method: 'POST',
  });

export const useCreateCustomerPortalSession = () =>
  useKyronMutationV2<Record<string, never>, { url: string }>(`/payments/create_customer_portal_session`, {
    method: 'POST',
  });
