import { Alert, Box, Typography } from '@mui/material';
import React from 'react';
import { useRouteError, useSearchParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import plane1bw from '../../assets/plane-1-bw.svg';
import { CopyButton } from '../CopyButton';

const styles = {
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: 'calc(100vh - 180px)',
    justifyContent: 'center',
    padding: '24px',
    textAlign: 'center',
  },
};

type RouteError = { data: string } & Record<string, string | number | boolean>;

export function ErrorPage({ customError }: { customError?: string }) {
  const routeError = useRouteError() as RouteError;
  const [search] = useSearchParams();
  const returnUrl = search.get('return_url');
  const ltiSessionId = search.get('lti_session_id');
  const isLTIFailure = Boolean(ltiSessionId);
  const errorString = customError || routeError?.data?.toString();
  console.error(errorString);

  const renderGenericError = () =>
    !routeError ? null : (
      <Typography variant='bodyLarge'>
        {'statusText' in routeError ? routeError.statusText : routeError?.message}
      </Typography>
    );

  return (
    <Box sx={styles.wrapper}>
      <img src={plane1bw} height='64' alt='' />
      <Typography variant='headlineLarge'>Oops! Something went wrong.</Typography>
      {isLTIFailure ? renderLTIError(errorString, returnUrl) : renderGenericError()}
    </Box>
  );
}

export function renderLTIError(errorString: string, returnUrl?: string | null) {
  return (
    <Box sx={{ maxWidth: 600, mt: 2 }}>
      <Typography>We could not start your lesson.</Typography>
      <Typography>Please reach out to your administrator and share the error below.</Typography>
      {!errorString ? null : (
        <Alert
          severity='error'
          action={<CopyButton value={errorString} />}
          sx={{ textAlign: 'left', mt: 2, alignItems: 'center', '& .MuiAlert-action': { pt: 0 } }}
        >
          {errorString}
        </Alert>
      )}

      {!returnUrl ? null : (
        <Button
          sx={{ mt: 2 }}
          onClick={() => {
            window.location.href = returnUrl;
          }}
        >
          Return to Platform
        </Button>
      )}
    </Box>
  );
}
