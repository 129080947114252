import React from 'react';
import { Box, Typography, IconButton, InputBase } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Close, Check, RadioButtonChecked } from '@mui/icons-material';
import { AudioMetadata } from 'controllers/transcription/types';
import dayjs from 'dayjs';
import { Row } from 'components/Row/Row';

const MAX_SCALE = 1.5;
const AUDIO_THRESHOLD = 10;
const calculateScale = (audioLevel: number) =>
  audioLevel > AUDIO_THRESHOLD ? Math.min(MAX_SCALE, 1 + audioLevel / 100) : 1;
const RecordingIndicator = ({ audioLevel = 0, durationInSeconds }: AudioMetadata) => (
  <Row gap={2} alignItems='center' data-testid='recording-indicator'>
    <RadioButtonChecked
      sx={{
        fontSize: 25,
        margin: '0 2px',
        borderRadius: '50%',
        color: 'primary.contrastText',
        transform: `scale(${calculateScale(audioLevel)})`,
        transition: 'transform 0.2s ease-in-out',
      }}
    />
    <Typography
      variant='labelSmall'
      sx={{
        color: 'primary.contrastText',
        width: 30, // fixed width to keep the text from shifting while it counts
      }}
    >
      {dayjs.duration(durationInSeconds, 'seconds').format('mm:ss')}
    </Typography>
  </Row>
);

type VoiceInputFieldProps = {
  audioMetadata: AudioMetadata;
  saveRecording: () => void;
  cancelRecording: () => void;
};
export function VoiceInputField({ audioMetadata, saveRecording, cancelRecording }: VoiceInputFieldProps) {
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      saveRecording();
    } else if (event.key === 'Escape') {
      cancelRecording();
    }
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      sx={{ width: '100%', padding: 1.5, borderRadius: 4, backgroundColor: 'primary.main' }}
      justifyContent='space-between'
    >
      {/* Hidden input to handle key events */}
      <InputBase
        autoFocus
        onKeyDown={handleKeyDown}
        sx={visuallyHidden}
        inputProps={{ 'aria-hidden': true, 'data-testid': 'keypress-handler' }}
      />
      <Row>
        <IconButton
          onClick={cancelRecording}
          aria-label='Cancel Speech-to-text'
          size='small'
          sx={{ color: 'primary.contrastText' }}
        >
          <Close />
        </IconButton>
      </Row>
      <Row gap={2}>
        <RecordingIndicator {...audioMetadata} />
        <IconButton
          onClick={saveRecording}
          aria-label='Save Speech-to-text'
          size='small'
          sx={{
            color: 'none',
            bgcolor: 'primary.contrastText',
            '&:hover': {
              bgcolor: 'primaryContainer.main',
            },
          }}
        >
          <Check />
        </IconButton>
      </Row>
    </Box>
  );
}
