import React from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { ValueFormatterParams } from 'ag-grid-community';
import { useGetMisconceptions } from '../../controllers/react-query/classroomMisconceptionReportHooks';
import { ClassroomMisconceptionReport } from '../../controllers/types';
import { DataGrid } from '../common/DataGrid';

const styles = {
  classroomMisconceptionReport: {
    width: '100%',
    height: '75vh',
    marginTop: '10px',
  },
};

export const MisconceptionsView = () => {
  const { classroomId, lessonCollectionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { error, data } = useGetMisconceptions(classroomId, lessonCollectionId);

  const studentListFormatter = (params: ValueFormatterParams) => {
    const list = params.value as ClassroomMisconceptionReport['students'];
    let result = '';
    list.forEach(student => {
      result += `${student.user_name} (${student.frequency} time${student.frequency > 1 ? 's' : ''})\n`;
    });
    return result;
  };

  const dataGridColumns = [
    {
      field: 'name',
      headerName: 'Misconception',
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: { whiteSpace: 'normal', wordBreak: 'normal' },
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: { whiteSpace: 'normal', wordBreak: 'normal' },
    },
    {
      field: 'number_of_students',
      headerName: 'Number of Students',
      minWidth: 100,
      cellStyle: { whiteSpace: 'normal', wordBreak: 'normal' },
    },
    {
      field: 'students',
      headerName: 'Students',
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: { whiteSpace: 'pre', wordBreak: 'normal' },
      valueFormatter: studentListFormatter,
    },
  ];

  const createDataGridRows = () => {
    if (error) {
      enqueueSnackbar('There was an error loading the misconceptions table', { variant: 'error' });
      return []; // return empty array so the dataGrid renders an empty table instead of continuing the loading state
    }
    return data?.map((misconception: ClassroomMisconceptionReport) => ({
      name: misconception.name,
      description: misconception.description,
      number_of_students: misconception.students.length,
      students: misconception.students,
    }));
  };

  return (
    <Box sx={styles.classroomMisconceptionReport} data-testid='misconceptions-table'>
      <DataGrid
        rows={createDataGridRows()}
        columns={dataGridColumns}
        noRowsText='No students have started this lesson yet'
      />
    </Box>
  );
};
