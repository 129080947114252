import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { Grid, ButtonGroup, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

type ShortcutItem = {
  label: string;
  startDate: Dayjs;
  endDate: Dayjs;
};

const today = dayjs();
const shortcutItems: ShortcutItem[] = [
  {
    label: 'Last 24 Hours',
    startDate: today.subtract(1, 'day'),
    endDate: today,
  },
  {
    label: 'Last 7 Days',
    startDate: today.subtract(7, 'day'),
    endDate: today,
  },
  {
    label: 'This Month',
    startDate: today.startOf('month'),
    endDate: today,
  },
  {
    label: 'Last Month',
    startDate: today.subtract(1, 'month').startOf('month'),
    endDate: today.subtract(1, 'month').endOf('month'),
  },
];

type Props = {
  timestampStart?: Dayjs;
  timestampEnd?: Dayjs;
  handleDateRange: (startDate: Dayjs, endDate: Dayjs) => void;
};

export const DateRangePicker = ({
  timestampStart = shortcutItems[0].startDate,
  timestampEnd = shortcutItems[0].endDate,
  handleDateRange,
}: Props) => {
  const handleSetStart = (startValue: Dayjs | null) => {
    if (startValue !== null) handleDateRange(startValue, timestampEnd);
  };
  const handleSetEnd = (endValue: Dayjs | null) => {
    if (endValue !== null) handleDateRange(timestampStart, endValue);
  };

  return (
    <Grid container direction='row'>
      <Grid item xs={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker label='Start Date' value={timestampStart} onChange={handleSetStart} />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={4}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker label='End Date' value={timestampEnd} onChange={handleSetEnd} />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={4}>
        <ButtonGroup data-testid='shortcut-buttons' disableElevation color='primary' variant='outlined'>
          {shortcutItems.map(shortcut => (
            <Button
              key={shortcut.label}
              size='small'
              name={shortcut.label}
              onClick={() => handleDateRange(shortcut.startDate, shortcut.endDate)}
            >
              {shortcut.label}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};
