import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Paper, Stack, Tooltip, Menu, MenuItem, Typography } from '@mui/material';
import { Assignment, LanguageOutlined, PlayCircleOutline, SchoolOutlined, MoreHoriz } from '@mui/icons-material';
import { KyronImage } from 'components/KyronImage/KyronImage';
import { UserContext } from 'components/UserContext';
import { LessonCollection } from 'controllers/types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ClassroomSelector } from './ClassroomSelector';
import { Locales } from '../utils/locales';
import KyronIcon from '../../assets/kyronicon-small.svg';
import GoogleClassroomLogo from '../../assets/google-classroom-svg.svg';
import { ReauthenticateDialog } from '../ReauthenticateDialog';
import { getLessonCollectionURL, getLTILink } from '../utils/urlUtils';

export function CatalogLessonCard({
  lessonCollection,
  pausedLessonId,
  openLessonSelectionDialog,
}: CatalogLessonCardProps) {
  const navigate = useNavigate();
  const styles = {
    container: {
      display: 'flex',
      minHeight: '260px',
      padding: '10px',
    },
    lessonCollectionCard: {
      bgcolor: 'surfaceContainer.main',
    },
  };

  const userContext = React.useContext(UserContext);
  const [showClassroomSelector, setShowClassroomSelector] = React.useState<boolean>(false);
  const [showReauthenticateDialog, setShowReauthenticateDialog] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & HTMLButtonElement) | null>(null);
  const restartOpen = Boolean(anchorEl);

  function handleMore(e: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
  }

  function handleRestartClose() {
    setAnchorEl(null);
  }

  function handleStartLessonClick() {
    openLessonSelectionDialog?.(lessonCollection);
  }

  function handleResumeLessonClick(lessonId: number) {
    const lessonLink = `/video_player/${lessonId}`;
    navigate(lessonLink);
  }

  function renderLessonResources(): JSX.Element {
    return (
      <Tooltip title='Teacher Resources'>
        <IconButton
          aria-label='Teacher Resources'
          data-testid='teacher-resources'
          href={lessonCollection.resource_link}
          target='_blank'
        >
          <Assignment />
        </IconButton>
      </Tooltip>
    );
  }

  function renderLessonAttributes(): JSX.Element {
    return (
      <>
        <Stack direction='row' gap={3}>
          <Stack direction='row' gap={0.5} alignItems='center' data-testid='lesson-tutors'>
            <SchoolOutlined fontSize='small' />
            <Typography variant='labelMedium'>Choose from {lessonCollection.lessons?.length} tutors</Typography>
          </Stack>
          <Stack direction='row' gap={0.5} alignItems='center' data-testid='lesson-locales'>
            <LanguageOutlined fontSize='small' />
            <Typography variant='labelMedium'>
              Available in {[...new Set(lessonCollection.lessons?.map(lesson => Locales[lesson.locale]))].join(', ')}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction='row' justifyContent='flex-end' spacing={2}>
          {userContext.isTeacher && (
            <Button
              variant='outlined'
              startIcon={<img src={GoogleClassroomLogo} alt='Google Classroom' width='24px' />}
              data-testid='lesson-assign-button'
              onClick={() => setShowClassroomSelector(true)}
            >
              Assign Lesson
            </Button>
          )}

          {pausedLessonId === 0 && (
            <Button
              variant='outlined'
              startIcon={<PlayCircleOutline />}
              data-testid='lesson-start-button'
              aria-label='start lesson'
              onClick={handleStartLessonClick}
            >
              Start Lesson
            </Button>
          )}

          {pausedLessonId > 0 && (
            <Button
              variant='outlined'
              startIcon={<PlayCircleOutline />}
              data-testid='lesson-resume-button'
              aria-label='resume lesson'
              onClick={() => handleResumeLessonClick(pausedLessonId)}
            >
              Resume Lesson
            </Button>
          )}

          <IconButton
            onClick={handleMore}
            aria-haspopup='true'
            aria-expanded={restartOpen ? 'true' : undefined}
            aria-controls={restartOpen ? 'restart-lesson' : undefined}
          >
            <MoreHoriz color='primary' fontSize='large' />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            id='restart-menu'
            open={restartOpen}
            onClose={handleRestartClose}
            onClick={handleRestartClose}
            data-testid='restart-menu'
          >
            {pausedLessonId > 0 && (
              <MenuItem onClick={handleStartLessonClick} data-testid='restart-lesson-menu'>
                Restart Lesson
              </MenuItem>
            )}
            {userContext.isActiveOrgKyronDemos && (
              <CopyToClipboard text={getLessonCollectionURL(lessonCollection.id)}>
                <MenuItem>Copy Lesson Link</MenuItem>
              </CopyToClipboard>
            )}
            <CopyToClipboard text={getLTILink(lessonCollection)}>
              <MenuItem>Copy LTI Link</MenuItem>
            </CopyToClipboard>
          </Menu>
        </Stack>
      </>
    );
  }

  function reauthenticate() {
    setShowClassroomSelector(false);
    setShowReauthenticateDialog(true);
  }

  return (
    <Paper sx={styles.lessonCollectionCard}>
      <Stack direction='row' gap={0} sx={styles.container}>
        <KyronImage
          size={240}
          src={lessonCollection.collection_thumbnail}
          fallbackSrc={KyronIcon}
          alt='lesson collection thumbnail'
          dataTestID='thumbnail'
        />
        <Stack gap={2} padding={2}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography variant='titleLarge' data-testid='lesson-name'>
              {lessonCollection.name}
            </Typography>
            {lessonCollection.resource_link && renderLessonResources()}
          </Stack>
          <Typography variant='bodyMedium' data-testid='lesson-description'>
            {lessonCollection.description}
          </Typography>
          {!!lessonCollection.lessons?.length && renderLessonAttributes()}
          <ClassroomSelector
            open={showClassroomSelector}
            close={() => setShowClassroomSelector(false)}
            lessonCollection={lessonCollection}
            reauthenticate={reauthenticate}
          />
          <ReauthenticateDialog open={showReauthenticateDialog} onClose={() => setShowReauthenticateDialog(false)} />
        </Stack>
      </Stack>
    </Paper>
  );
}

type CatalogLessonCardProps = {
  lessonCollection: LessonCollection;
  pausedLessonId: number;
  openLessonSelectionDialog?: (lesson: LessonCollection) => void;
};
