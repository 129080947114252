// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LtiPlatformCard renders with action buttons 1`] = `
<DocumentFragment>
  .emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid #D6D5DD;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  margin-right: 8px;
  height: 100%;
  display: grid;
  grid-template-rows: auto 64px;
}

.emotion-1 {
  padding: 16px;
}

.emotion-1:last-child {
  padding-bottom: 24px;
}

.emotion-2 {
  margin: 0;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
}

.emotion-3 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
}

.emotion-4 {
  margin: 0;
  overflow-wrap: anywhere;
}

.emotion-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.emotion-9>:not(style)~:not(style) {
  margin-left: 8px;
}

.emotion-10 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-10::-moz-focus-inner {
  border-style: none;
}

.emotion-10.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-10 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-10:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-10:hover {
    background-color: transparent;
  }
}

.emotion-10.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-11 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-12 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-13 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #BA1A1A;
}

.emotion-13::-moz-focus-inner {
  border-style: none;
}

.emotion-13.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-13 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-13:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-13:hover {
    background-color: transparent;
  }
}

.emotion-13:hover {
  background-color: rgba(186, 26, 26, 0.04);
}

@media (hover: none) {
  .emotion-13:hover {
    background-color: transparent;
  }
}

.emotion-13.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

<div
    class="MuiPaper-root MuiPaper-outlined MuiPaper-rounded MuiCard-root emotion-0"
  >
    <div
      class="MuiCardContent-root emotion-1"
    >
      <h4
        class="MuiTypography-root MuiTypography-headlineSmall emotion-2"
        data-testid="lti-platform-name"
      >
        Test platform
      </h4>
      <p
        class="MuiTypography-root MuiTypography-bodyMedium emotion-3"
      >
        Test description
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          Issuer: 
        </b>
        https://example.com/lti
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          Client ID: 
        </b>
        123456
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          Keyset URL: 
        </b>
        https://example.com/jwks.json
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          OIDC Auth URL: 
        </b>
        https://example.com/oidc_redirect
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          Access Token URL: 
        </b>
        https://example.com/access_token
      </p>
    </div>
    <div
      class="MuiCardActions-root MuiCardActions-spacing emotion-9"
    >
      <button
        aria-label="Edit Platform"
        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-10"
        data-mui-internal-clone-element="true"
        data-testid="edit-platform"
        tabindex="0"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
          data-testid="EditIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-12"
        />
      </button>
      <button
        aria-label="Delete Platform"
        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorError MuiIconButton-sizeMedium emotion-13"
        data-mui-internal-clone-element="true"
        data-testid="delete-platform"
        tabindex="0"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
          data-testid="DeleteIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-12"
        />
      </button>
    </div>
  </div>
</DocumentFragment>
`;

exports[`LtiPlatformCard renders without edit button 1`] = `
<DocumentFragment>
  .emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid #D6D5DD;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  margin-right: 8px;
  height: 100%;
  display: grid;
  grid-template-rows: auto 64px;
}

.emotion-1 {
  padding: 16px;
}

.emotion-1:last-child {
  padding-bottom: 24px;
}

.emotion-2 {
  margin: 0;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
}

.emotion-3 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 16px;
}

.emotion-4 {
  margin: 0;
  overflow-wrap: anywhere;
}

.emotion-9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.emotion-9>:not(style)~:not(style) {
  margin-left: 8px;
}

.emotion-10 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-10::-moz-focus-inner {
  border-style: none;
}

.emotion-10.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-10 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-10:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-10:hover {
    background-color: transparent;
  }
}

.emotion-10.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-11 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-12 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-13 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #BA1A1A;
}

.emotion-13::-moz-focus-inner {
  border-style: none;
}

.emotion-13.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-13 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-13:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-13:hover {
    background-color: transparent;
  }
}

.emotion-13:hover {
  background-color: rgba(186, 26, 26, 0.04);
}

@media (hover: none) {
  .emotion-13:hover {
    background-color: transparent;
  }
}

.emotion-13.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

<div
    class="MuiPaper-root MuiPaper-outlined MuiPaper-rounded MuiCard-root emotion-0"
  >
    <div
      class="MuiCardContent-root emotion-1"
    >
      <h4
        class="MuiTypography-root MuiTypography-headlineSmall emotion-2"
        data-testid="lti-platform-name"
      >
        Test platform
      </h4>
      <p
        class="MuiTypography-root MuiTypography-bodyMedium emotion-3"
      >
        Test description
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          Issuer: 
        </b>
        https://example.com/lti
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          Client ID: 
        </b>
        123456
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          Keyset URL: 
        </b>
        https://example.com/jwks.json
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          OIDC Auth URL: 
        </b>
        https://example.com/oidc_redirect
      </p>
      <p
        class="MuiTypography-root MuiTypography-body1 emotion-4"
      >
        <b>
          Access Token URL: 
        </b>
        https://example.com/access_token
      </p>
    </div>
    <div
      class="MuiCardActions-root MuiCardActions-spacing emotion-9"
    >
      <button
        aria-label="Edit Platform"
        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-10"
        data-mui-internal-clone-element="true"
        data-testid="edit-platform"
        tabindex="0"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
          data-testid="EditIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-12"
        />
      </button>
      <button
        aria-label="Delete Platform"
        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorError MuiIconButton-sizeMedium emotion-13"
        data-mui-internal-clone-element="true"
        data-testid="delete-platform"
        tabindex="0"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-11"
          data-testid="DeleteIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6zM19 4h-3.5l-1-1h-5l-1 1H5v2h14z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-12"
        />
      </button>
    </div>
  </div>
</DocumentFragment>
`;
