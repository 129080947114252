import React, { useImperativeHandle } from 'react';
import { isEmpty } from 'lodash';
import { styled } from '@mui/material/styles';
import { BoxText } from 'components/Equation/BoxText';
import { getFontSize } from 'components/Equation/util';
import { BoxSingle } from './BoxSingle';
import { BoxOverBox } from './BoxOverBox';
import { CaptureData, EquationData, Size } from './types';
import { DisplayType } from './constants';
import { BoxOperator } from './BoxOperator';

export type SimpleFractionProps = {
  data: EquationData[];
  size?: Size;
  className?: string;
  builderMode?: boolean;
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  flexWrap: 'wrap',
}));

const WholeNumberWrapper = styled('div')<{ size?: Size }>`
  display: inline-block;
  font-size: ${props => getFontSize(props.size)};
  font-weight: 400;
`;

const isBox = (displayType: DisplayType) => displayType === DisplayType.BOX;
const isOperator = (displayType: DisplayType) => displayType === DisplayType.OPERATOR;
const isText = (displayType: DisplayType) => displayType === DisplayType.TEXT;

// This method is used to generate a unique key for each box component
const generateKey = (prefix: string) => `${prefix}`;

// This method is used to update the newValue attribute of the binded item
function updateValueFn(this: EquationData, updatedValue: string) {
  this.newValue = updatedValue;
}

// This method should be used to recursively reconcile the data from the input with the data that is used to display the input
function reconcileEquationInput(items: EquationData[]): EquationData[] {
  if (isEmpty(items)) {
    return [];
  }
  return items.reduce((accum: EquationData[], item: EquationData) => {
    const newItem: EquationData = {
      displayType: item.displayType,
      value: item.newValue !== undefined ? item.newValue : item.value,
    };

    if (item.over) {
      newItem.over = reconcileEquationInput(item.over);
    }

    return accum.concat(newItem);
  }, []);
}

// This method would recursively go through the fractions data object and generate components based on BoxOperator, BoxOverBox and BoxSingle.
// It would also check if we have nested overs, which is not allowed.
/*
    This method should generate react rendered dom with the following components based on the order of the data in the array:
    1. BoxOperator - if we have an operator e.g. +, -, *, /, =, >, >=, <, <=
    2. BoxOverBox - if we have a fraction
    3. BoxSingle wrapped in a WholeNumberWrapper - if we have just a number as the item.
    4. BoxSingle - if we have a box, that is entered recursive from a over block
 */
const generateFractions = (items: EquationData[], size: Size, builderMode = false, isRecursionStep = false) => (
  <>
    {items.map(item => {
      const { displayType, value, over } = item;
      // Using bind to pass the item as the context for the function, this way we can update the value of the item latter.
      const onValueChange = updateValueFn.bind(item);
      /*
         We can't have nested recursive overs, as it wouldn't represent fractions.
         Examples of allowed:
           1/2
         Examples of not allowed:
           1/2/3 or 1/2/3/4
        */
      if (over && isRecursionStep) {
        throw new Error('Invalid fractions data. Nested overs are not allowed.');
      }
      const fractions = [];
      if (isText(displayType)) {
        fractions.push(
          <BoxText
            builderMode={builderMode}
            key={generateKey(value)}
            value={value}
            size={size}
            onChange={onValueChange}
          />,
        );
      }
      if (isOperator(displayType)) {
        fractions.push(<BoxOperator key={generateKey(value)} value={value} size={size} onChange={onValueChange} />);
      }

      // If we have a box and a over object, we need to wrap it in a BoxOverBox component
      if (isBox(displayType) && over !== undefined && !isEmpty(over)) {
        fractions.push(
          <BoxOverBox
            key={generateKey(value)}
            Box1={<BoxSingle builderMode={builderMode} size={size} value={value} onChange={onValueChange} />}
            Box2={generateFractions(over, size, builderMode, true)}
          />,
        );
      }
      // If we have a box, that is not entered recursive from a over block, we need to wrap it in a whole number wrapper
      else if (isBox(displayType) && !isRecursionStep) {
        fractions.push(
          <WholeNumberWrapper key={generateKey(value)} size={size}>
            <BoxSingle builderMode={builderMode} value={value} size={size} onChange={onValueChange} />
          </WholeNumberWrapper>,
        );
      }
      // If we have a box, that is entered recursive from a over block, we don't need to wrap it in a whole number wrapper
      else if (isBox(displayType) && isRecursionStep) {
        fractions.push(
          <BoxSingle
            builderMode={builderMode}
            key={generateKey(value)}
            value={value}
            size={size}
            onChange={onValueChange}
          />,
        );
      }
      return fractions;
    })}
  </>
);

/*
    This component should show user the fractions with any input boxes that are needed.
    Below example of fractions data should show []/3, where [] is an input box.
    To look at more examples of fractions data, please look at https://docs.google.com/document/d/1zJ_bzkO_R7_Y-B7l5fZ3VTGLb-TkJOIiu8cdpeE1_PQ/edit?usp=sharing.:
    [
      {
        "displayType": "box",
        "value": "",
        "over": [
          {
              "displayType": "box"
              "value": "3",
          }
        ]
       }
    ]

    NOTE:
    While using this component, we can pass in ref to this component to get the reconciled data that was updated by the user.
    forwardRef is used to pass the ref to the underlying component, and useImperativeHandle react hook is used to expose the getData method.
    The getData method would return the reconciled data from the input.
 */
export const SimpleEquation = React.forwardRef<CaptureData, SimpleFractionProps>(
  ({ className, data, size = 'large', builderMode }, ref) => {
    useImperativeHandle(
      ref,
      () => ({
        getData() {
          return reconcileEquationInput(data);
        },
      }),
      [data],
    );
    return <Wrapper className={className}>{generateFractions(data, size, builderMode)}</Wrapper>;
  },
);
