/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Cancel';
import { Typography } from '@mui/material';
import { DirectUploader } from '../Uploaders/DirectUploader';

const styles = {
  TutorCard: {
    borderRadius: '20px',
    margin: '10px 0px',
    justifyContent: 'center',
  },
};

export const TutorEditForm = props => {
  const [tutor, setTutor] = useState({
    id: props.tutor?.id || 0,
    display_name: props.tutor?.display_name || '',
    description: props.tutor?.description || '',
    profile_picture: null,
  });

  const handleInputChange = e => {
    setTutor(prevTutor => ({
      ...prevTutor,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDirectUploadProfilePicture = signedIDs => {
    setTutor(prevTutor => ({
      ...prevTutor,
      profile_picture: signedIDs[0],
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.handleFormSubmit(tutor);
    props.handleExpand();
    e.target.reset();
  };

  return (
    <Card sx={styles.TutorCard}>
      <CardHeader title='Add Tutor' />
      <CardContent>
        <form data-testid='tutor-form' onSubmit={handleSubmit}>
          <TextField
            data-testid='tutor-name'
            variant='outlined'
            name='display_name'
            fullWidth
            margin='normal'
            label='Tutor Name'
            type='text'
            onChange={handleInputChange}
            required
            value={tutor.display_name}
          />

          <TextField
            data-testid='tutor-description'
            variant='outlined'
            name='description'
            fullWidth
            margin='normal'
            label='Description'
            type='text'
            onChange={handleInputChange}
            required
            value={tutor.description}
            helperText='Enter a description'
          />

          <Typography>Upload Profile Picure</Typography>
          <DirectUploader afterUpload={handleDirectUploadProfilePicture} multiple={false} acceptTypes={'image/*'} />

          <CardActions>
            <IconButton aria-label='submit tutor form' type='submit'>
              <DoneIcon />
            </IconButton>
            <IconButton aria-label='cancel edit tutor form' onClick={props.handleExpand}>
              <CancelIcon />
            </IconButton>
          </CardActions>
        </form>
      </CardContent>
    </Card>
  );
};

TutorEditForm.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleExpand: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tutor: PropTypes.object,
};
