import React, { useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { Stack, IconButton, InputAdornment, TextFieldProps, CircularProgress } from '@mui/material';
import { Clear } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { RoundedTextField } from './RoundedTextField';

export const debouncedSearch = debounce((value, fn) => fn(value), 500);
const opacityAnim = 'opacity 0.1s ease-in-out';
const getStartAdornment = (loading?: boolean) => (
  <InputAdornment position='start' sx={{ position: 'relative', height: '100%' }}>
    <>
      <SearchIcon />
      {loading ? (
        <CircularProgress
          size={32}
          sx={{
            position: 'absolute',
            top: -2,
            left: -4,
            opacity: loading ? 0.5 : 0,
            transition: opacityAnim,
          }}
        />
      ) : null}
    </>
  </InputAdornment>
);
/**
 * A clear button that appears when there is text in the input
 * @param value text input value
 * @param clearInput a callback that clears the input state (will be called when the clear button is clicked)
 */
export const getEndAdornment = (value: string, clearInput: () => void) => (
  <IconButton
    size='small'
    tabIndex={value ? 0 : -1} // make clear button tabbable only when there is text in the input
    sx={{ opacity: value ? 1 : 0, transition: opacityAnim }}
    aria-label='Clear'
    onClick={clearInput}
  >
    <Clear fontSize='small' />
  </IconButton>
);

export type Props = {
  searchTerm?: string;
  onSearch: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void);
  isLoading?: boolean;
  searchError?: string | null;
  dataTestID?: string;
  disableAutoComplete?: boolean;
} & TextFieldProps;

export const SearchField = ({
  searchTerm = '',
  onSearch,
  isLoading,
  InputProps,
  searchError,
  fullWidth = true,
  dataTestID,
  disableAutoComplete = false,
  ...props
}: Props) => {
  const [value, setValue] = useState(searchTerm);

  const startAdornment = useMemo(() => getStartAdornment(isLoading), [isLoading]);
  const endAdornment = useMemo(() => {
    const clearInput = () => {
      setValue('');
      onSearch('');
    };
    return getEndAdornment(value, clearInput);
  }, [onSearch, value]);

  return (
    <Stack direction='row' spacing={2} alignItems='center'>
      <RoundedTextField
        data-testid={dataTestID}
        fullWidth={fullWidth}
        value={value}
        error={!!searchError}
        helperText={searchError}
        inputProps={{ autoComplete: disableAutoComplete ? 'off' : undefined }}
        InputProps={{
          startAdornment,
          endAdornment,
          ...InputProps,
        }}
        onChange={event => {
          setValue(event.target.value);
          debouncedSearch(event.target.value, onSearch);
        }}
        {...props}
      />
    </Stack>
  );
};
