// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`UnitEdtior renders the component 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.emotion-1 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-1::-moz-focus-inner {
  border-style: none;
}

.emotion-1.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-1 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-1:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-1:hover {
    background-color: #4B35FF;
  }
}

.emotion-1:active {
  box-shadow: none;
}

.emotion-1.Mui-focusVisible {
  box-shadow: none;
}

.emotion-1.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-1:hover {
  box-shadow: none;
}

.emotion-1.Mui-focusVisible {
  box-shadow: none;
}

.emotion-1:active {
  box-shadow: none;
}

.emotion-1.Mui-disabled {
  box-shadow: none;
}

.emotion-2 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<div
    class="MuiBox-root emotion-0"
  >
    <button
      class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-1"
      style="margin: 0px 5px;"
      tabindex="0"
      type="button"
    >
      Save Positions
      <span
        class="MuiTouchRipple-root emotion-2"
      />
    </button>
  </div>
  <div
    style="margin: 10px 0px;"
  >
    <div
      style="display: flex; justify-content: center;"
    />
  </div>
  <div
    data-testid="all-units"
  />
</DocumentFragment>
`;
