import React, { useRef, useEffect } from 'react';
import { Stack } from '@mui/material';
import { ConversationRole } from 'enums/conversationRole';
import { ConversationItem } from 'controllers/types';
import { ConversationTurn } from '../StudentInputs/ConversationTurn';

type ChatMessageListProps = {
  messages: ConversationItem[];
  chatId: number;
  question: string | null;
  replaceLastMessage: (text: string) => void;
  isAssistantResponding: boolean;
};
export function ChatMessageList({
  messages,
  chatId,
  question,
  replaceLastMessage,
  isAssistantResponding,
}: ChatMessageListProps) {
  const scrollRef = useRef<HTMLDivElement>(null);
  const isLastTurn = (idx: number) => idx === messages.length - 1;
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <Stack gap={2}>
      {/* Treat the question as if it were the first turn in the conversation */}
      <ConversationTurn turn={{ role: ConversationRole.AI, text: question || '' }} />

      {messages.map((turn: ConversationItem, idx: number) => (
        <ConversationTurn
          // eslint-disable-next-line react/no-array-index-key
          key={`turn-${idx}`}
          turn={turn}
          isLastTurn={idx === messages.length - 1}
          ref={isLastTurn(idx) ? scrollRef : null}
          chatId={chatId}
          replaceLastMessage={replaceLastMessage}
          question={question}
          conversation={messages}
          isAssistantResponding={isAssistantResponding}
        />
      ))}
    </Stack>
  );
}
