import React from 'react';
import { Alert, List, ListItem, Stack, Tooltip, Typography, IconButton } from '@mui/material';
import { LessonCollection, Classroom } from 'controllers/types';
import { Edit, Publish } from '@mui/icons-material';
import GoogleClassroomLogo from 'assets/google_classroom_logo.png';
import { useCreateGoogleAssignment } from 'controllers/react-query/assignmentHooks';
import { useSnackbar } from 'notistack';
import { LoadingIndicator } from '../LoadingIndicator';
import { Row } from '../Row/Row';

type Props = {
  lessonCollection?: LessonCollection;
  reauthenticate: () => void;
  classrooms?: Classroom[];
  isFetching: boolean;
  isError: boolean;
};

const GoogleClassroomIcon = (
  <img src={GoogleClassroomLogo} alt='google classroom icon' style={{ width: '20px', height: '20px' }} />
);

export const AssignableClassroomsList = ({
  lessonCollection,
  reauthenticate,
  classrooms,
  isFetching,
  isError,
}: Props) => {
  const disabled = !lessonCollection;
  const { isPending: isCreating, mutate: createGoogleAssignment } = useCreateGoogleAssignment();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateGoogleAssignment = (classroomId: number, externalClassroomId: string, draft: boolean) => {
    if (!lessonCollection) return;

    createGoogleAssignment(
      {
        payload: {
          classroomId,
          externalClassroomId,
          lessonId: lessonCollection.id,
          draft,
        },
      },
      {
        onSuccess: () => {
          const handled = draft ? 'created as a draft assignment' : 'assigned';
          enqueueSnackbar(`${lessonCollection.name} was successfully ${handled}`, { variant: 'success' });
        },
        onError: () => {
          enqueueSnackbar('Error: Assignment was not created. Please ensure your classroom is properly synced.', {
            variant: 'error',
          });
          reauthenticate();
        },
      },
    );
  };

  const renderClassrooms = (classroom: Classroom) => (
    <ListItem key={classroom.id} divider sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {classroom.external_classroom_provider === 'google_classroom' && (
        <>
          <Row sx={{ ml: 1 }} gap={1}>
            <Typography variant='labelLarge' color={disabled ? 'text.disabled' : 'text.primary'}>
              {classroom.name}
            </Typography>
            <Tooltip title='Google Classroom' placement='top'>
              {GoogleClassroomIcon}
            </Tooltip>
          </Row>
          <Stack direction='row' spacing={1}>
            <Tooltip title='Publish Assignment' placement='top'>
              <IconButton
                disabled={disabled || isCreating}
                aria-label='Publish Assignment to Google Classroom'
                onClick={() => handleCreateGoogleAssignment(classroom.id, classroom.external_classroom_id, false)}
                data-testid='publish-to-google-classroom-button'
              >
                <Publish />
              </IconButton>
            </Tooltip>
            <Tooltip title='Create Draft Assignment in Google Classroom' placement='top'>
              <IconButton
                disabled={disabled || isCreating}
                aria-label='Draft Assignment to Google Classroom'
                onClick={() => handleCreateGoogleAssignment(classroom.id, classroom.external_classroom_id, true)}
                data-testid='draft-to-google-classroom-button'
              >
                <Edit />
              </IconButton>
            </Tooltip>
          </Stack>
        </>
      )}
    </ListItem>
  );

  return (
    <>
      {isFetching && <LoadingIndicator message='Loading classes...' />}
      {isError && <Alert severity='error'>Something went wrong. Could not load classrooms.</Alert>}
      {classrooms?.length ? (
        <List>{classrooms.map(renderClassrooms)}</List>
      ) : (
        <Typography sx={{ width: '100%', textAlign: 'center' }}>There are no classes found.</Typography>
      )}
    </>
  );
};
