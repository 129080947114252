import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Delete, Done, Cancel } from '@mui/icons-material';
import { Card, CardHeader, CardContent, IconButton, Stack } from '@mui/material';
import { ACCEPT_VIDEO_TYPES, FileUploader, UploadButton } from 'components/Uploaders/FileUploader';
import Box from '@mui/material/Box';
import { CommonSegmentForm } from './CommonSegmentForm';
import { WaitSegmentForm } from './WaitSegmentForm';
import { StepSegmentForm } from './StepSegmentForm';
import { AdvancedOptionsForm } from './AdvancedOptionsForm';
import { SegmentEdgeEditor } from './SegmentEdgeEditor';
import { LoadingIndicator } from '../LoadingIndicator';

export const LessonSegmentEditor = ({
  isLoading,
  segment,
  lessonId,
  allSegmentNames,
  allMisconceptions,
  handleLessonSegmentUpdate,
  handleLessonSegmentDelete,
  handleExpand,
}) => {
  const styles = {
    editorStyles: {
      width: '100%',
      padding: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    videoPreviewStyles: {
      height: '300px',
      backgroundColor: 'grey',
      borderRadius: '4px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    deleteButtonStyles: {
      color: 'red',
      cursor: 'pointer',
      position: 'relative',
      right: '20px',
      paddingLeft: '20px',
    },
    saveButtonStyles: {
      color: 'green',
      cursor: 'pointer',
    },
    oneThirdWidth: {
      width: '33%',
    },
    twoThirdsWidth: {
      width: '66%',
    },
    halfWidth: {
      width: '50%',
    },
    fullWidth: {
      width: '100%',
    },
    fortyWidth: {
      width: '40%',
    },
    sixtyWidth: {
      width: '60%',
    },
  };

  const initializeEdgeRows = (seg, segmentNames) => {
    if (seg.wait_edges_as_source.length > 0) {
      return seg.wait_edges_as_source;
    }
    return [
      {
        id: shortid.generate(),
        target_segment_name: segmentNames[0],
        answer: '',
        training_phrases: [],
        position: 1,
      },
    ];
  };

  const [state, setState] = useState({
    id: segment.id,
    name: segment.name,
    description: segment.description || '',
    segment_type: segment.segment_type || 'step',
    start_time: segment.start_time || 0,
    end_time: segment.end_time || 9999,
    next_segment: segment.next_segment || '',
    acceptable_inputs: segment.acceptable_inputs || [],
    misconceptions: segment.lesson_misconceptions.map(concept => concept.code) || [],
    event_to_send: segment.event_to_send || '',
    wait_timeout: segment.wait_timeout,
    video: null,
    video_url: segment.video_url,
    video_filename: segment.video_filename,
    video_duration: segment.video_duration,
    dialogflow_page: segment.dialogflow_page || '',
    question: segment.question || '',
    question_json: segment.question_json || [],
    prompt_selection: segment.prompt?.dict,
    transcript: segment.transcript || '',
    allAcceptableInputs: ['audio', 'text', 'multiple_choice', 'open_ended', 'equation', 'equation_multiple_choice'],
    edgeRows: initializeEdgeRows(segment, allSegmentNames),
    allSegmentNames,
    allMisconceptions,
    equation_display_input: segment.equation_display_input,
  });

  const deleteLessonSegment = e => {
    e.preventDefault();
    handleLessonSegmentDelete(state.id);
  };

  const submitLessonSegment = e => {
    e.preventDefault();
    const lessonSegment = {
      id: state.id,
      name: state.name,
      description: state.description,
      segment_type: state.segment_type,
      start_time: state.start_time,
      end_time: state.end_time,
      next_segment: state.next_segment,
      acceptable_inputs: state.acceptable_inputs,
      misconceptions: state.misconceptions,
      event_to_send: state.event_to_send,
      wait_timeout: state.wait_timeout,
      video: state.video,
      dialogflow_page: state.dialogflow_page,
      question: state.question,
      prompt_selection: state.prompt_selection || null,
      transcript: state.transcript,
      equation_display_input: state.equation_display_input,
      question_json: state.question_json,
    };
    handleLessonSegmentUpdate(lessonSegment, state.segment_type !== 'wait' ? [] : state.edgeRows);
  };

  const handleInputChange = e => {
    const {
      target: { name, value, type, checked },
    } = e;
    setState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleTime = e => {
    const {
      target: { name, value },
    } = e;
    setState(prevState => ({
      ...prevState,
      [name]: value.replace(/[^\d.-]+/g, ''),
    }));
  };

  const handleDirectUploadFile = blobs => {
    setState(prevState => ({
      ...prevState,
      video: blobs[0].signed_id,
    }));
  };

  const setEdgeRows = v => {
    setState(prevState => ({
      ...prevState,
      edgeRows: v,
    }));
  };

  const cancelLessonSegment = () => {
    handleExpand(false);
  };

  return (
    <Card>
      <CardHeader sx={styles.LessonSegmentCardHeader} title='Edit Lesson Segment' />
      <CardContent>
        <CommonSegmentForm
          state={state}
          lessonId={lessonId}
          handleInputChange={handleInputChange}
          handleTime={handleTime}
          editing
        />
        <Stack direction='column' spacing={2}>
          {state.segment_type !== 'wait' && <StepSegmentForm state={state} handleInputChange={handleInputChange} />}
          {state.segment_type === 'wait' && (
            <>
              <WaitSegmentForm state={state} handleInputChange={handleInputChange} />
              <Card>
                <CardHeader title='Edge Editing' titleTypographyProps={{ variant: 'h8' }} />
                <CardContent>
                  <Stack direction='row'>
                    <SegmentEdgeEditor
                      allSegmentNames={state.allSegmentNames}
                      edgeRows={state.edgeRows}
                      setEdgeRows={setEdgeRows}
                      acceptableInputs={state.acceptable_inputs}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </>
          )}
        </Stack>
        <Stack direction='column' spacing={2}>
          <AdvancedOptionsForm state={state} handleInputChange={handleInputChange} handleTime={handleTime} />
        </Stack>
        <Stack direction='row'>
          <FileUploader
            fileTypeName='video'
            afterUpload={handleDirectUploadFile}
            acceptTypes={ACCEPT_VIDEO_TYPES}
            existingFile={state.video}
          >
            <UploadButton buttonLabel='Upload File' />
          </FileUploader>
        </Stack>
        <Box display='flex' gap={1}>
          <IconButton aria-label='submit lesson segment' onClick={submitLessonSegment} disabled={isLoading}>
            <Done />
          </IconButton>
          <IconButton aria-label='delete lesson segment' onClick={deleteLessonSegment} disabled={isLoading}>
            <Delete />
          </IconButton>
          <IconButton aria-label='cancel edit lesson segment' onClick={cancelLessonSegment} disabled={isLoading}>
            <Cancel />
          </IconButton>
          {isLoading && <LoadingIndicator spinnerSize={20} sx={{ px: 1 }} message='Processing...' />}
        </Box>
      </CardContent>
    </Card>
  );
};

/* eslint-disable react/forbid-prop-types */
LessonSegmentEditor.propTypes = {
  isLoading: PropTypes.bool,
  segment: PropTypes.object.isRequired,
  lessonId: PropTypes.number,
  allSegmentNames: PropTypes.array.isRequired,
  allMisconceptions: PropTypes.array.isRequired,
  handleLessonSegmentDelete: PropTypes.func.isRequired,
  handleLessonSegmentUpdate: PropTypes.func.isRequired,
  handleExpand: PropTypes.func,
};
