import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StatCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.surfaceContainer.main,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',
  '&:hover': {
    backgroundColor: theme.palette.secondaryContainer.main,
  },
}));

const StatNumber = styled(Typography)({
  fontSize: '4rem',
  fontWeight: 'bold',
  marginBottom: '1.25rem',
});

type AnalyticsCardProps = {
  number?: number | null;
  label: string | null;
};

export const AnalyticsCard: React.FC<AnalyticsCardProps> = ({ number, label }) => (
  <StatCard>
    <CardContent>
      <Box>
        <StatNumber>{number}</StatNumber>
        <Typography>{label}</Typography>
      </Box>
    </CardContent>
  </StatCard>
);
