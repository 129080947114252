import React from 'react';
import { useLessonMisconceptionsQuery } from 'controllers/react-query/lessonMisconceptionHooks';
import { useParams } from 'react-router-dom';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { Typography, Divider, TextField, Stack } from '@mui/material';
import { useSettingsTitle } from '../LessonSettingsDialog/LessonSettingsDialog';

export const MisconceptionDialog = () => {
  const { lessonId } = useParams();
  const { isFetching, error, data } = useLessonMisconceptionsQuery(Number(lessonId));
  const misconceptions = data?.misconceptions;
  useSettingsTitle('Misconceptions');

  return (
    <Stack spacing={2} sx={{ px: 4, pt: 2 }}>
      <Typography variant='bodySmall' color='textSecondary'>
        A misconception is a mix-up or misunderstanding of a concept. Based on the content from your course, we&apos;ve
        identified the following potential misconceptions that students may have. These insights will be available in
        reports and analytics to help teachers and students understand areas of difficulty.
      </Typography>
      {isFetching && <LoadingIndicator message='Loading misconceptions...' />}
      {error && <Typography variant='bodySmall'>Error loading misconceptions</Typography>}
      {data &&
        misconceptions?.map(m => (
          <Stack spacing={2} key={m.id}>
            <Stack direction='row' spacing={1} justifyContent='space-between' alignItems='flex-start'>
              <Stack spacing={1} sx={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  size='small'
                  fullWidth
                  multiline
                  defaultValue={m.name}
                  sx={{ backgroundColor: 'background.default', fontWeight: 'bold' }}
                  inputProps={{ readOnly: true }}
                />
                <TextField
                  variant='outlined'
                  size='small'
                  fullWidth
                  multiline
                  defaultValue={m.description}
                  sx={{ backgroundColor: 'background.default' }}
                  inputProps={{ readOnly: true }}
                />
              </Stack>
              {/*
              Hiding the delete button until it's functional

              <IconButton disabled onClick={() => {}}>
                <DeleteOutline color='error' />
              </IconButton>
              */}
            </Stack>
            <Divider />
          </Stack>
        ))}
    </Stack>
  );
};
