/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { CardContent, CardHeader, Typography, IconButton } from '@mui/material/';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';

const styles = {
  unitCardField: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export const Unit = props => {
  const { unit } = props;

  const findCourseName = id => {
    const course = props.courses.find(c => c.id === id);
    if (course) {
      return course.name;
    }
    return 'Not Associated with a Course';
  };

  return (
    <>
      <CardHeader
        action={
          <>
            <IconButton aria-label='edit unit' data-testid='edit-unit' onClick={props.handleUnitEdit}>
              <EditIcon />
            </IconButton>
            <IconButton aria-label='delete unit' data-testid='delete-unit' onClick={props.openDeleteModal}>
              <DeleteIcon />
            </IconButton>
          </>
        }
      />
      <CardContent style={styles.unitCardField}>
        <Typography variant='h5' color='text.primary' data-testid='unit-title'>
          {unit.name}
        </Typography>
        <Typography color='text.secondary' data-testid='course-title'>
          {`Course: ${findCourseName(unit.parent_id)}`}
        </Typography>
      </CardContent>
    </>
  );
};

/* eslint-disable react/forbid-prop-types */

Unit.propTypes = {
  unit: PropTypes.object,
  courses: PropTypes.array,
  handleUnitEdit: PropTypes.func,
  openDeleteModal: PropTypes.func,
};
