import { useKyronQuery } from './kyronQuery';
import { ClassroomMisconceptionReport, StudentWork, StudentsInClassroom } from '../types';

export const useGetMisconceptions = (classroomId?: string, lessonCollectionId?: string) =>
  useKyronQuery<ClassroomMisconceptionReport[]>(
    `/teacher_dashboards/misconceptions?classroom_id=${classroomId}&lesson_collection_id=${lessonCollectionId}`,
    {
      enabled: !!classroomId && !!lessonCollectionId,
    },
  );

export const useStudentWorkBreakdown = (lessonCollectionId?: string, classroomId?: string) =>
  useKyronQuery<StudentWork[]>(
    `/teacher_dashboards/student_work_breakdown?lesson_collection_id=${lessonCollectionId}${classroomId ? `&classroom_id=${classroomId}` : ''}`,
    {
      enabled: !!lessonCollectionId,
    },
  );

export const useStudentsInClassroom = (classroomId?: string) =>
  useKyronQuery<StudentsInClassroom[]>(`/teacher_dashboards/students_in_classroom?classroom_id=${classroomId}`, {
    enabled: !!classroomId,
  });
