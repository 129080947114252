// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`SingleMisconception Component SingleMisconception renders correctly renders form when edit=true 1`] = `
<DocumentFragment>
  .emotion-0 {
  padding: 16px;
}

.emotion-0:last-child {
  padding-bottom: 24px;
}

.emotion-1 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
}

.emotion-2 {
  color: #1C1B1FB3;
  line-height: 1.4375em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.emotion-2.Mui-focused {
  color: #4B35FF;
}

.emotion-2.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-2.Mui-error {
  color: #BA1A1A;
}

.emotion-3.Mui-error {
  color: #BA1A1A;
}

.emotion-4 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.emotion-4.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-4:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-4:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-4.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-4.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-4.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-5 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.emotion-5::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:focus {
  outline: 0;
}

.emotion-5:invalid {
  box-shadow: none;
}

.emotion-5::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-5.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-5:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-5:-webkit-autofill {
  border-radius: inherit;
}

.emotion-6 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-7 {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
}

.emotion-7>span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}

.emotion-8 {
  color: #1C1B1FB3;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.emotion-8.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-8.Mui-error {
  color: #BA1A1A;
}

.emotion-25 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
}

.emotion-25>:not(style)~:not(style) {
  margin-left: 8px;
}

.emotion-26 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-26::-moz-focus-inner {
  border-style: none;
}

.emotion-26.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-26 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-26:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-26:hover {
    background-color: transparent;
  }
}

.emotion-26.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-27 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-28 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<div
    class="MuiCardContent-root emotion-0"
  >
    <form
      data-testid="misconception-form"
    >
      <div
        class="MuiFormControl-root MuiFormControl-marginNormal MuiFormControl-fullWidth MuiTextField-root emotion-1"
        data-testid="misconception-name"
      >
        <label
          class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined emotion-2"
          data-shrink="true"
          for=":r1:"
          id=":r1:-label"
        >
          Misconception Name
          <span
            aria-hidden="true"
            class="MuiFormLabel-asterisk MuiInputLabel-asterisk emotion-3"
          >
             *
          </span>
        </label>
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl emotion-4"
        >
          <input
            aria-describedby=":r1:-helper-text"
            aria-invalid="false"
            class="MuiInputBase-input MuiOutlinedInput-input emotion-5"
            id=":r1:"
            name="name"
            required=""
            type="text"
            value="miss1"
          />
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-6"
          >
            <legend
              class="emotion-7"
            >
              <span>
                Misconception Name *
              </span>
            </legend>
          </fieldset>
        </div>
        <p
          class="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled Mui-required emotion-8"
          id=":r1:-helper-text"
        >
          Enter the name of the misconception
        </p>
      </div>
      <div
        class="MuiFormControl-root MuiFormControl-marginNormal MuiFormControl-fullWidth MuiTextField-root emotion-1"
        data-testid="misconception-code"
      >
        <label
          class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined emotion-2"
          data-shrink="true"
          for=":r2:"
          id=":r2:-label"
        >
          Misconception Code
          <span
            aria-hidden="true"
            class="MuiFormLabel-asterisk MuiInputLabel-asterisk emotion-3"
          >
             *
          </span>
        </label>
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl emotion-4"
        >
          <input
            aria-describedby=":r2:-helper-text"
            aria-invalid="false"
            class="MuiInputBase-input MuiOutlinedInput-input emotion-5"
            id=":r2:"
            name="code"
            required=""
            type="text"
            value="0001"
          />
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-6"
          >
            <legend
              class="emotion-7"
            >
              <span>
                Misconception Code *
              </span>
            </legend>
          </fieldset>
        </div>
        <p
          class="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled Mui-required emotion-8"
          id=":r2:-helper-text"
        >
          Enter the misconception code
        </p>
      </div>
      <div
        class="MuiFormControl-root MuiFormControl-marginNormal MuiFormControl-fullWidth MuiTextField-root emotion-1"
        data-testid="misconception-description"
      >
        <label
          class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined emotion-2"
          data-shrink="true"
          for=":r3:"
          id=":r3:-label"
        >
          Description
          <span
            aria-hidden="true"
            class="MuiFormLabel-asterisk MuiInputLabel-asterisk emotion-3"
          >
             *
          </span>
        </label>
        <div
          class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-formControl emotion-4"
        >
          <input
            aria-describedby=":r3:-helper-text"
            aria-invalid="false"
            class="MuiInputBase-input MuiOutlinedInput-input emotion-5"
            id=":r3:"
            name="description"
            required=""
            type="text"
            value="miss1 misconception"
          />
          <fieldset
            aria-hidden="true"
            class="MuiOutlinedInput-notchedOutline emotion-6"
          >
            <legend
              class="emotion-7"
            >
              <span>
                Description *
              </span>
            </legend>
          </fieldset>
        </div>
        <p
          class="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained MuiFormHelperText-filled Mui-required emotion-8"
          id=":r3:-helper-text"
        >
          Enter a description
        </p>
      </div>
      <div
        class="MuiCardActions-root MuiCardActions-spacing emotion-25"
      >
        <button
          aria-label="submit misconception"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-26"
          tabindex="0"
          type="submit"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-27"
            data-testid="DoneIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-28"
          />
        </button>
        <button
          aria-label="cancel edit misconception"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-26"
          tabindex="0"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-27"
            data-testid="CancelIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-28"
          />
        </button>
      </div>
    </form>
  </div>
</DocumentFragment>
`;
