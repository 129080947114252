import { DeleteOutline } from '@mui/icons-material';
import { Button, ButtonProps } from '@mui/material';
import React, { useContext } from 'react';
import { FileUploaderContext, UploaderElementProps } from './FileUploader';

type RemoveFileButtonProps = {
  onRemove: () => void;
  buttonLabel?: string | null;
  variant?: ButtonProps['variant'];
  sx?: ButtonProps['sx'];
};

export function RemoveFileButton({
  onRemove,
  shouldBeDisabled,
  buttonLabel = null,
  variant = 'outlined',
  sx,
}: UploaderElementProps & RemoveFileButtonProps) {
  const uploaderCtx = useContext(FileUploaderContext);
  const label = buttonLabel || `Remove ${uploaderCtx?.fileTypeName}`;

  return !uploaderCtx?.isAttachmentBeingProcessed && uploaderCtx?.isThereAnExistingFile ? (
    <Button
      data-testid='file-uploader-remove-button'
      startIcon={<DeleteOutline />}
      color='error'
      variant={variant}
      onClick={() => onRemove?.()}
      disabled={shouldBeDisabled}
      sx={{ maxWidth: '156px', ...sx }}
    >
      {label}
    </Button>
  ) : null;
}
