import React, { ChangeEvent } from 'react';
import Button from '@mui/material/Button';
import { DirectUploadProvider } from '@quorak/react-activestorage-provider';
import { UploadFile } from '@mui/icons-material';
import { VisuallyHiddenInput } from '../VisuallyHiddenInput';
import { DirectUploaderRenderProps } from './types';

type DirectUploaderProps = {
  multiple?: boolean;
  afterUpload: (signedIds: string[]) => void;
  acceptTypes?: string;
  onInputChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  showStatus?: boolean;
  buttonLabel?: string;
};

/**
 * DirectUploader will be deprecated in the future in favor of FileUploader once
 * it supports multiple files, which then will be named FileUploader.
 * Prefer using FileUploader when possible.
 *
 * @deprecated
 */
export const DirectUploader = ({
  multiple = false,
  afterUpload,
  acceptTypes = '*',
  onInputChange,
  showStatus = false,
  buttonLabel = 'Upload File',
}: DirectUploaderProps) => {
  const handleAttachment = (signedIds: string[]) => {
    afterUpload(signedIds);
  };

  const renderField = ({ handleUpload, uploads, ready }: DirectUploaderRenderProps) => {
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (onInputChange) onInputChange(e);
      handleUpload(e.currentTarget.files!);
    };

    return (
      <>
        <Button component='label' startIcon={<UploadFile />} disabled={!ready} variant='outlined'>
          {buttonLabel}
          <VisuallyHiddenInput
            type='file'
            multiple={multiple}
            accept={acceptTypes}
            disabled={!ready}
            onChange={handleInputChange}
          />
        </Button>

        {showStatus
          ? uploads.map(upload => {
              switch (upload.state) {
                case 'waiting':
                  return <p key={upload.id}>Waiting to upload {upload.file.name}</p>;
                case 'uploading':
                  return (
                    <p key={upload.id}>
                      Uploading {upload.file.name}: {Math.round((upload?.progress || 0) * 100) / 100}%
                    </p>
                  );
                case 'error':
                  return (
                    <p key={upload.id}>
                      Error uploading {upload.file.name}: {upload.error}
                    </p>
                  );
                case 'finished':
                  return <p key={upload.id}>Finished uploading {upload.file.name}</p>;
                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };

  return <DirectUploadProvider multiple={multiple} onSuccess={handleAttachment} render={renderField} />;
};
