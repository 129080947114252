import { useBreadcrumbs, useTitle } from 'components/StudioLayout/StudioLayout';
import { TutorPayload, useTutorMutation } from 'controllers/react-query/tutorHooks';
import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TutorForm } from './TutorForm';

export const TutorNew = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { mutate: createTutor } = useTutorMutation();

  useTitle('New Tutor');
  useBreadcrumbs(useMemo(() => [], []));

  const handleSubmit = (t: TutorPayload) => {
    createTutor(t, {
      onSuccess: newTutor => {
        enqueueSnackbar(`${newTutor.display_name} was successfully created`, { variant: 'success' });
        navigate(`/studio/tutors`);
      },
      onError: error => {
        enqueueSnackbar(`Failed to create tutor: ${error.message}`, { variant: 'error' });
      },
    });
  };

  return <TutorForm onSubmit={handleSubmit} />;
};
