import React from 'react';
import { Button, Stack, Typography } from '@mui/material';
import GoogleClassroomLogoLarge from '../../assets/google_classroom_logo_large.svg';

export const ClassroomLogin = () => {
  const styles = {
    stack: {
      maxWidth: '400px',
      margin: 'auto',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '20vh',
      marginBottom: '20vh',
    },
    text: {
      textAlign: 'center',
      variant: 'body',
    },
    button: {
      margin: 'auto',
      color: 'primary',
      variant: 'contained',
    },
  };

  return (
    <Stack sx={styles.stack} spacing={2}>
      <img src={GoogleClassroomLogoLarge} alt='Google Classroom Logo' width='200' data-testid='google-classroom-logo' />
      <Button
        sx={styles.button}
        href='/users/auth/google_classroom'
        data-method='post'
        data-testid='google-classroom-login-button'
      >
        Link Google Classroom
      </Button>
      <Typography sx={styles.text}>
        Link Google Classroom to your Kyron Learning account to create Kyron Learning lesson assignments and view
        student activity dashboards.
      </Typography>
    </Stack>
  );
};
