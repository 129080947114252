/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { CourseEdit } from './CourseEdit';

const styles = {
  Coursediv: {
    display: 'flex',
    justifyContent: 'center',
  },
  Coursegrid: {
    width: '82%',
  },
};

export const AllCourses = props => (
  <div style={styles.Coursediv}>
    <Grid style={styles.Coursegrid} container spacing={2}>
      {props.courses.map(course => (
        <Grid xs={12} item key={course.id}>
          <CourseEdit
            course={course}
            handleCourseDelete={props.handleCourseDelete}
            handleCourseUpdate={props.handleCourseUpdate}
            updateCourse={props.updateCourse}
            courses={props.courses}
            reorderCourses={props.reorderCourses}
            reorderUnits={props.reorderUnits}
          />
        </Grid>
      ))}
    </Grid>
  </div>
);

AllCourses.propTypes = {
  handleCourseDelete: PropTypes.func,
  handleCourseUpdate: PropTypes.func,
  updateCourse: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  courses: PropTypes.array.isRequired,
  reorderCourses: PropTypes.func,
  reorderUnits: PropTypes.func,
};
