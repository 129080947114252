// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`MarketPlaceSearch snapshots only search bar 1`] = `
<DocumentFragment>
  .emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  padding: 0 8px;
  overflow: hidden;
  -webkit-transition: box-shadow 0.2s,height 50ms;
  transition: box-shadow 0.2s,height 50ms;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
  height: 40px;
}

.emotion-0 '&:focus-within': {
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
}

@media (min-width:0px) {
  .emotion-0 {
    width: 280px;
  }
}

@media (min-width:600px) {
  .emotion-0 {
    width: 400px;
  }
}

.emotion-1 {
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-2 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
}

.emotion-2.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0.01em;
  max-height: 2em;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
  position: relative;
  height: 100%;
  margin-left: 4px;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-5 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.emotion-5::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:focus {
  outline: 0;
}

.emotion-5:invalid {
  box-shadow: none;
}

.emotion-5::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-5.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-5:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-6 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.emotion-6::-moz-focus-inner {
  border-style: none;
}

.emotion-6.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-6 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-6:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-6:hover {
    background-color: transparent;
  }
}

.emotion-6.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-8 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
  opacity: 0;
  height: 100%;
  max-height: 360px;
}

.emotion-13 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-14 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: -8px;
  margin-right: -8px;
  height: calc(100% - 40);
  visibility: hidden;
  overflow-y: scroll;
  margin-bottom: 8px;
  padding-top: 0px;
}

.emotion-15 {
  box-sizing: border-box;
  line-height: 48px;
  list-style: none;
  color: #1C1B1FB3;
  font-family: Open Sans;
  font-weight: 500;
  font-size: 0.875rem;
  padding-left: 16px;
  padding-right: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #FCFAFD;
  line-height: 36px;
}

<div
    class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 emotion-0"
    data-testid="marketplace-search-paper"
  >
    <div
      class="MuiBox-root emotion-1"
      role="presentation"
    >
      <div
        class="MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-adornedStart MuiInputBase-adornedEnd emotion-2"
        data-testid="marketplace-search-input"
      >
        <div
          class="MuiInputAdornment-root MuiInputAdornment-positionStart emotion-3"
        >
          <span
            class="notranslate"
          >
            ​
          </span>
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="SearchIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
            />
          </svg>
        </div>
        <input
          aria-label="search marketplace"
          autocomplete="off"
          class="MuiInputBase-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd emotion-5"
          placeholder="Search for courses"
          type="text"
          value=""
        />
        <button
          aria-label="Clear"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-6"
          data-testid="marketplace-search-clear"
          tabindex="-1"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="ClearIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-8"
          />
        </button>
        <button
          aria-label="Clear"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-6"
          data-testid="marketplace-search-submit"
          tabindex="-1"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="KeyboardReturnIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-8"
          />
        </button>
      </div>
    </div>
    <div
      aria-hidden="true"
      class="MuiStack-root emotion-12"
      data-testid="marketplace-search-dropdown-content"
    >
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-13"
      />
      <ul
        aria-hidden="true"
        class="MuiList-root MuiList-padding emotion-14"
        data-testid="marketplace-search-list"
        tabindex="-1"
      >
        <li
          class="MuiListSubheader-root MuiListSubheader-gutters MuiListSubheader-sticky emotion-15"
        >
          Recent Searches
        </li>
      </ul>
    </div>
  </div>
</DocumentFragment>
`;

exports[`MarketPlaceSearch snapshots only search bar with loading 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes animation-1 {
  0% {
    stroke-dasharray: 1px,200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -125px;
  }
}

.emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  padding: 0 8px;
  overflow: hidden;
  -webkit-transition: box-shadow 0.2s,height 50ms;
  transition: box-shadow 0.2s,height 50ms;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
  height: 40px;
}

.emotion-0 '&:focus-within': {
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
}

@media (min-width:0px) {
  .emotion-0 {
    width: 280px;
  }
}

@media (min-width:600px) {
  .emotion-0 {
    width: 400px;
  }
}

.emotion-1 {
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-2 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
}

.emotion-2.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0.01em;
  max-height: 2em;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
  position: relative;
  height: 100%;
  margin-left: 4px;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-5 {
  display: inline-block;
  color: #4B35FF;
  -webkit-animation: animation-0 1.4s linear infinite;
  animation: animation-0 1.4s linear infinite;
  position: absolute;
  top: -3px;
  left: -4px;
  opacity: 0.5;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.emotion-6 {
  display: block;
}

.emotion-7 {
  stroke: currentColor;
  stroke-dasharray: 80px,200px;
  stroke-dashoffset: 0;
  -webkit-animation: animation-1 1.4s ease-in-out infinite;
  animation: animation-1 1.4s ease-in-out infinite;
}

.emotion-8 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.emotion-8::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8:focus {
  outline: 0;
}

.emotion-8:invalid {
  box-shadow: none;
}

.emotion-8::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-8:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-8.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-8:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-9 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.emotion-9::-moz-focus-inner {
  border-style: none;
}

.emotion-9.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-9 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-9:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-9:hover {
    background-color: transparent;
  }
}

.emotion-9.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-11 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
  opacity: 0;
  height: 100%;
  max-height: 360px;
}

.emotion-16 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-17 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: -8px;
  margin-right: -8px;
  height: calc(100% - 40);
  visibility: hidden;
  overflow-y: scroll;
  margin-bottom: 8px;
  padding-top: 0px;
}

.emotion-18 {
  box-sizing: border-box;
  line-height: 48px;
  list-style: none;
  color: #1C1B1FB3;
  font-family: Open Sans;
  font-weight: 500;
  font-size: 0.875rem;
  padding-left: 16px;
  padding-right: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #FCFAFD;
  line-height: 36px;
}

<div
    class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 emotion-0"
    data-testid="marketplace-search-paper"
  >
    <div
      class="MuiBox-root emotion-1"
      role="presentation"
    >
      <div
        class="MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-adornedStart MuiInputBase-adornedEnd emotion-2"
        data-testid="marketplace-search-input"
      >
        <div
          class="MuiInputAdornment-root MuiInputAdornment-positionStart emotion-3"
        >
          <span
            class="notranslate"
          >
            ​
          </span>
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="SearchIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
            />
          </svg>
          <span
            class="MuiCircularProgress-root MuiCircularProgress-indeterminate MuiCircularProgress-colorPrimary emotion-5"
            data-testid="marketplace-search-loading"
            role="progressbar"
            style="width: 28px; height: 28px;"
          >
            <svg
              class="MuiCircularProgress-svg emotion-6"
              viewBox="22 22 44 44"
            >
              <circle
                class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate emotion-7"
                cx="44"
                cy="44"
                fill="none"
                r="20.2"
                stroke-width="3.6"
              />
            </svg>
          </span>
        </div>
        <input
          aria-label="search marketplace"
          autocomplete="off"
          class="MuiInputBase-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd emotion-8"
          placeholder="Search for courses"
          type="text"
          value=""
        />
        <button
          aria-label="Clear"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-9"
          data-testid="marketplace-search-clear"
          tabindex="-1"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="ClearIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-11"
          />
        </button>
        <button
          aria-label="Clear"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-9"
          data-testid="marketplace-search-submit"
          tabindex="-1"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="KeyboardReturnIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-11"
          />
        </button>
      </div>
    </div>
    <div
      aria-hidden="true"
      class="MuiStack-root emotion-15"
      data-testid="marketplace-search-dropdown-content"
    >
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-16"
      />
      <ul
        aria-hidden="true"
        class="MuiList-root MuiList-padding emotion-17"
        data-testid="marketplace-search-list"
        tabindex="-1"
      >
        <li
          class="MuiListSubheader-root MuiListSubheader-gutters MuiListSubheader-sticky emotion-18"
        >
          Recent Searches
        </li>
      </ul>
    </div>
  </div>
</DocumentFragment>
`;

exports[`MarketPlaceSearch snapshots search bar with dropdown open and text in input 1`] = `
<DocumentFragment>
  .emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  padding: 0 8px;
  overflow: hidden;
  -webkit-transition: box-shadow 0.2s,height 50ms;
  transition: box-shadow 0.2s,height 50ms;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.emotion-0 '&:focus-within': {
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
}

@media (min-width:0px) {
  .emotion-0 {
    width: 280px;
  }
}

@media (min-width:600px) {
  .emotion-0 {
    width: 400px;
  }
}

.emotion-1 {
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-2 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
}

.emotion-2.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0.01em;
  max-height: 2em;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
  position: relative;
  height: 100%;
  margin-left: 4px;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-5 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.emotion-5::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:focus {
  outline: 0;
}

.emotion-5:invalid {
  box-shadow: none;
}

.emotion-5::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-5.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-5:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-6 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
  opacity: 1;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.emotion-6::-moz-focus-inner {
  border-style: none;
}

.emotion-6.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-6 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-6:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-6:hover {
    background-color: transparent;
  }
}

.emotion-6.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-8 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-12 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
  opacity: 1;
  height: 100%;
  max-height: 360px;
}

.emotion-13 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-14 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: -8px;
  margin-right: -8px;
  height: calc(100% - 40);
  visibility: visible;
  overflow-y: scroll;
  margin-bottom: 8px;
  padding-top: 0px;
}

.emotion-15 {
  box-sizing: border-box;
  line-height: 48px;
  list-style: none;
  color: #1C1B1FB3;
  font-family: Open Sans;
  font-weight: 500;
  font-size: 0.875rem;
  padding-left: 16px;
  padding-right: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #FCFAFD;
  line-height: 36px;
}

.emotion-16 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -webkit-text-decoration: none;
  text-decoration: none;
  min-width: 0;
  box-sizing: border-box;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-left: 16px;
  padding-right: 16px;
  padding: 0px;
  padding-left: 16px;
  padding-right: 16px;
}

.emotion-16::-moz-focus-inner {
  border-style: none;
}

.emotion-16.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-16 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-16:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-16:hover {
    background-color: transparent;
  }
}

.emotion-16.Mui-selected {
  background-color: rgba(75, 53, 255, 0.08);
}

.emotion-16.Mui-selected.Mui-focusVisible {
  background-color: rgba(75, 53, 255, 0.2);
}

.emotion-16.Mui-selected:hover {
  background-color: rgba(75, 53, 255, 0.12);
}

@media (hover: none) {
  .emotion-16.Mui-selected:hover {
    background-color: rgba(75, 53, 255, 0.08);
  }
}

.emotion-16.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-16.Mui-disabled {
  opacity: 0.38;
}

.emotion-17 {
  min-width: 56px;
  color: rgba(0, 0, 0, 0.54);
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  color: inherit;
  margin-right: 8px;
  min-width: 0px;
}

.emotion-18 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  color: #1C1B1FB3;
}

.emotion-19 {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.emotion-19 .MuiListItemText-secondary {
  color: inherit;
}

.emotion-20 {
  margin: 0;
  display: block;
}

<div
    class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 emotion-0"
    data-testid="marketplace-search-paper"
  >
    <div
      class="MuiBox-root emotion-1"
      role="presentation"
    >
      <div
        class="MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-fullWidth Mui-focused MuiInputBase-adornedStart MuiInputBase-adornedEnd emotion-2"
        data-testid="marketplace-search-input"
      >
        <div
          class="MuiInputAdornment-root MuiInputAdornment-positionStart emotion-3"
        >
          <span
            class="notranslate"
          >
            ​
          </span>
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="SearchIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
            />
          </svg>
        </div>
        <input
          aria-label="search marketplace"
          autocomplete="off"
          class="MuiInputBase-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd emotion-5"
          placeholder="Search for courses"
          type="text"
          value="e"
        />
        <button
          aria-label="Clear"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-6"
          data-testid="marketplace-search-clear"
          tabindex="0"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="ClearIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-8"
          />
        </button>
        <button
          aria-label="Clear"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-6"
          data-testid="marketplace-search-submit"
          tabindex="0"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="KeyboardReturnIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-8"
          />
        </button>
      </div>
    </div>
    <div
      aria-hidden="false"
      class="MuiStack-root emotion-12"
      data-testid="marketplace-search-dropdown-content"
    >
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-13"
      />
      <ul
        aria-hidden="false"
        class="MuiList-root MuiList-padding emotion-14"
        data-testid="marketplace-search-list"
        tabindex="-1"
      >
        <li
          class="MuiListSubheader-root MuiListSubheader-gutters MuiListSubheader-sticky emotion-15"
        >
          Recent Searches
        </li>
        <div
          class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-16"
          data-testid="marketplace-search-history-item"
          role="button"
          tabindex="-1"
        >
          <div
            class="MuiListItemIcon-root emotion-17"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-18"
              data-testid="SearchIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
              />
            </svg>
          </div>
          <div
            class="MuiListItemText-root emotion-19"
          >
            <span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary emotion-20"
            >
              science
            </span>
          </div>
          <span
            class="MuiTouchRipple-root emotion-8"
          />
        </div>
        <div
          class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters MuiListItemButton-root MuiListItemButton-gutters emotion-16"
          data-testid="marketplace-search-history-item"
          role="button"
          tabindex="-1"
        >
          <div
            class="MuiListItemIcon-root emotion-17"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-18"
              data-testid="SearchIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
              />
            </svg>
          </div>
          <div
            class="MuiListItemText-root emotion-19"
          >
            <span
              class="MuiTypography-root MuiTypography-body1 MuiListItemText-primary emotion-20"
            >
              english
            </span>
          </div>
          <span
            class="MuiTouchRipple-root emotion-8"
          />
        </div>
      </ul>
    </div>
  </div>
</DocumentFragment>
`;

exports[`MarketPlaceSearch snapshots search bar with extra actions 1`] = `
<DocumentFragment>
  .emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  padding: 0 8px;
  overflow: hidden;
  -webkit-transition: box-shadow 0.2s,height 50ms;
  transition: box-shadow 0.2s,height 50ms;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34),1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34);
  height: 40px;
}

.emotion-0 '&:focus-within': {
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
}

@media (min-width:0px) {
  .emotion-0 {
    width: 280px;
  }
}

@media (min-width:600px) {
  .emotion-0 {
    width: 400px;
  }
}

.emotion-1 {
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-2 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  min-height: 40px;
}

.emotion-2.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0.01em;
  max-height: 2em;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
  position: relative;
  height: 100%;
  margin-left: 4px;
}

.emotion-4 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-5 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
}

.emotion-5::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5:focus {
  outline: 0;
}

.emotion-5:invalid {
  box-shadow: none;
}

.emotion-5::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-5:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-5.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-5:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-6 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.emotion-6::-moz-focus-inner {
  border-style: none;
}

.emotion-6.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-6 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-6:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-6:hover {
    background-color: transparent;
  }
}

.emotion-6.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-8 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-12 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: 0;
  height: 100%;
  border-right-width: thin;
  height: 28px;
  margin: 4px;
}

.emotion-13 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-13::-moz-focus-inner {
  border-style: none;
}

.emotion-13.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-13 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-13:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-13:hover {
    background-color: transparent;
  }
}

.emotion-13.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-14 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-19 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
  opacity: 0;
  height: 100%;
  max-height: 360px;
}

.emotion-20 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

.emotion-21 {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: -8px;
  margin-right: -8px;
  height: calc(100% - 40);
  visibility: hidden;
  overflow-y: scroll;
  margin-bottom: 8px;
  padding-top: 0px;
}

.emotion-22 {
  box-sizing: border-box;
  line-height: 48px;
  list-style: none;
  color: #1C1B1FB3;
  font-family: Open Sans;
  font-weight: 500;
  font-size: 0.875rem;
  padding-left: 16px;
  padding-right: 16px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #FCFAFD;
  line-height: 36px;
}

<div
    class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 emotion-0"
    data-testid="marketplace-search-paper"
  >
    <div
      class="MuiBox-root emotion-1"
      role="presentation"
    >
      <div
        class="MuiInputBase-root MuiInputBase-colorPrimary MuiInputBase-fullWidth MuiInputBase-adornedStart MuiInputBase-adornedEnd emotion-2"
        data-testid="marketplace-search-input"
      >
        <div
          class="MuiInputAdornment-root MuiInputAdornment-positionStart emotion-3"
        >
          <span
            class="notranslate"
          >
            ​
          </span>
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="SearchIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
            />
          </svg>
        </div>
        <input
          aria-label="search marketplace"
          autocomplete="off"
          class="MuiInputBase-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd emotion-5"
          placeholder="Search for courses"
          type="text"
          value=""
        />
        <button
          aria-label="Clear"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-6"
          data-testid="marketplace-search-clear"
          tabindex="-1"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="ClearIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-8"
          />
        </button>
        <button
          aria-label="Clear"
          class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-6"
          data-testid="marketplace-search-submit"
          tabindex="-1"
          type="button"
        >
          <svg
            aria-hidden="true"
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-4"
            data-testid="KeyboardReturnIcon"
            focusable="false"
            viewBox="0 0 24 24"
          >
            <path
              d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7z"
            />
          </svg>
          <span
            class="MuiTouchRipple-root emotion-8"
          />
        </button>
      </div>
      <hr
        class="MuiDivider-root MuiDivider-fullWidth MuiDivider-vertical emotion-12"
      />
      <button
        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-13"
        data-testid="extra-action"
        tabindex="0"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-14"
          data-testid="ClearIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-8"
        />
      </button>
      <button
        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-13"
        data-testid="extra-action"
        tabindex="0"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-14"
          data-testid="ClearIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-8"
        />
      </button>
    </div>
    <div
      aria-hidden="true"
      class="MuiStack-root emotion-19"
      data-testid="marketplace-search-dropdown-content"
    >
      <hr
        class="MuiDivider-root MuiDivider-fullWidth emotion-20"
      />
      <ul
        aria-hidden="true"
        class="MuiList-root MuiList-padding emotion-21"
        data-testid="marketplace-search-list"
        tabindex="-1"
      >
        <li
          class="MuiListSubheader-root MuiListSubheader-gutters MuiListSubheader-sticky emotion-22"
        >
          Recent Searches
        </li>
      </ul>
    </div>
  </div>
</DocumentFragment>
`;
