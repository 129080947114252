import { Box, Stack, Typography } from '@mui/material';
import { Row } from 'components/Row/Row';
import React from 'react';

export type LeadershipBlockProps = {
  name: string;
  title: string;
  bio: string;
  image: React.ReactNode;
};

export function LeadershipBlock({ name, title, bio, image }: LeadershipBlockProps) {
  return (
    <Row sx={{ gap: 3, alignItems: 'flex-start' }}>
      <Box
        sx={{ width: '240px', height: '240px', flex: '0 0 240px', background: `url(${image}) center center/cover` }}
      />
      <Stack gap={0}>
        <Typography variant='bodyLarge' fontWeight='bold'>
          {name}
        </Typography>
        <Typography variant='bodyLarge' fontWeight='bold'>
          {title}
        </Typography>
        <Typography variant='bodyLarge'>{bio}</Typography>
      </Stack>
    </Row>
  );
}
