import React from 'react';
import { Avatar, SxProps } from '@mui/material';
import { stringToColor } from '../utils/stringToColor';

type KyronAvatarProps = {
  image?: string;
  label: string;
  size?: number;
  sx?: SxProps;
};

export function KyronAvatar({ image, label, size, sx }: KyronAvatarProps) {
  let style = sx;

  if (size) {
    style = { ...style, width: size, height: size };
  }

  return image ? (
    <Avatar alt={label} src={image} sx={style} data-testid='image-avatar' slotProps={{ img: { loading: 'lazy' } }} />
  ) : (
    <Avatar sx={{ ...style, bgcolor: stringToColor(label) }} data-testid='initials-avatar'>
      {label[0]}
    </Avatar>
  );
}
