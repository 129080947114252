/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import Draggable, { DraggableEvent, DraggableEventHandler } from 'react-draggable';
import { Button, Collapse, IconButton, Paper, Stack, Typography } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import Box from '@mui/material/Box';

type Position = {
  x: number;
  y: number;
};

const transparentBlack = 'rgba(0, 0, 0, 0.7)';
const transparentWhite = 'rgba(255, 255, 255, 0.8)';

type DebugWindowProps = {
  open?: boolean;
  hide: boolean;
  print: Array<unknown>;
  parentName?: string;
};

export const DebugWindow: React.FC<DebugWindowProps> = ({ open: defaultOpen = false, hide, print, parentName }) => {
  const [open, setOpen] = useState<boolean>(defaultOpen);
  const [position, setPosition] = useState<Position>({ x: 10, y: 10 });

  const handleDrag: DraggableEventHandler = (e: DraggableEvent, data: { x: number; y: number }) => {
    setPosition({ x: data.x, y: data.y });
  };

  const prettyPrintState = (state: unknown) => {
    if (state && typeof state === 'object') {
      return (
        <Box>
          {Object.entries(state).map(([k, v]) => (
            <Fragment key={k}>
              <Typography component='pre' variant='bodySmall'>
                <Typography component='span' variant='bodySmall' fontWeight='bolder' sx={{ color: 'yellow' }}>
                  {JSON.stringify(k, null, 2)}
                </Typography>
                {': '}
                {JSON.stringify(v, null, 2)}
              </Typography>
            </Fragment>
          ))}
        </Box>
      );
    }
    return (
      <Typography component='pre' variant='bodySmall'>
        {JSON.stringify(state, null, 2)}
      </Typography>
    );
  };

  if (hide) {
    return null;
  }

  return (
    <Draggable handle='.dragHandle' position={position} grid={[5, 5]} scale={1} onDrag={handleDrag}>
      <Stack sx={{ position: 'fixed', zIndex: 1500, top: 10, left: 400 }}>
        <Box sx={{ mb: 0.5 }}>
          <IconButton
            size='small'
            className='dragHandle'
            sx={{
              color: transparentWhite,
              backgroundColor: transparentBlack,
              '&:hover': {
                backgroundColor: transparentBlack,
              },
              cursor: 'move',
              mr: 0.5,
            }}
          >
            <DragHandleIcon />
          </IconButton>
          <Button
            size='small'
            className='handle'
            onClick={() => setOpen(!open)}
            sx={{
              backgroundColor: transparentBlack,
              '&:hover': {
                backgroundColor: transparentBlack,
              },
              color: transparentWhite,
              cursor: 'move',
              p: 1,
            }}
          >
            Debug {parentName ? `in ${parentName}` : ''}
          </Button>
        </Box>

        <Collapse in={open}>
          <Paper
            elevation={4}
            sx={{
              padding: 2,
              backgroundColor: transparentBlack,
              minWidth: 400,
              minHeight: 600,
              maxHeight: '80vh',
              maxWidth: 600,
              overflow: 'auto',
              color: transparentWhite,
            }}
          >
            {!print.length
              ? 'Nothing to show'
              : print.map((state, index) => (
                  <Typography component='pre' key={index} variant='bodySmall'>
                    {prettyPrintState(state)}
                  </Typography>
                ))}
          </Paper>
        </Collapse>
      </Stack>
    </Draggable>
  );
};
