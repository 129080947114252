import { Stack, Typography } from '@mui/material';
import React from 'react';

type HomepageHeadlineProps = {
  children?: React.ReactNode;
  subhead?: string;
  color?: string;
};

export function HomepageHeadline({ children, subhead, color = 'text.primary' }: HomepageHeadlineProps) {
  return (
    <Stack gap={2} textAlign='center'>
      {subhead && (
        <Typography
          variant='headlineLarge'
          sx={{ fontSize: { sm: '32px', xs: '24px' }, fontWeight: 'normal', color }}
          component='p'
        >
          {subhead}
        </Typography>
      )}
      <Typography
        variant='displayLarge'
        whiteSpace='pre-line'
        sx={{ fontSize: { sm: '64px', xs: '40px' }, lineHeight: { sm: '72px', xs: '48px' }, color }}
        component='h2'
      >
        {children}
      </Typography>
    </Stack>
  );
}
