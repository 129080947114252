// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`<KyronImage /> matches snapshot 1`] = `
<DocumentFragment>
  .emotion-0 {
  min-width: 240px;
  max-width: 240px;
  height: 240px;
  overflow: hidden;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: white;
}

<div
    class="MuiBox-root emotion-0"
    h="240"
    w="240"
  >
    <img
      alt="Snapshot Test"
      src="test-image.jpg"
      style="object-fit: cover; height: inherit; width: 100%;"
    />
  </div>
</DocumentFragment>
`;
