import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';

type ImageBoxProps = { w: number | string; h: number | string };
const ImageBox = styled(Box)<ImageBoxProps>`
  min-width: ${p => (Number(p.w) ? `${p.w}px` : p.w)};
  max-width: ${p => (Number(p.w) ? `${p.w}px` : p.w)};
  height: ${p => (Number(p.h) ? `${p.h}px` : p.h)};
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`;

type BaseProps = {
  alt: string;
  dataTestID?: string;
  src: string | undefined;
  hide?: boolean;
  fallbackSrc?: string;
  loading?: HTMLImageElement['loading'];
  objectFit?: 'cover' | 'fill';
};

type Props = BaseProps &
  (
    | {
        // Either set size only
        size: number | string; // prop to set both height and width to the same value
        height?: never;
        width?: never;
        stretch?: never;
      }
    | {
        // Or, set height and width
        height: number | string;
        width: number | string;
        stretch?: never;
        size?: never;
      }
    | {
        // Or, set only stretch to cover all the space in parent container
        stretch: boolean;
        height?: never;
        width?: never;
        size?: never;
      }
  );

/**
 *
 * KyronImage is a wrapper component around the img tag to establish a consistent image handling for Kyron
 *
 * @param src The main image source that's going to be displayed
 * @param fallbackSrc The fallback image source that's going to be displayed if the main image source is not available
 *
 * You must use either size, height and width, or stretch. Otherwise, you will get TS yelling at you.
 * If you use size, it will set both height and width to the same value.
 */
export function KyronImage({ src, dataTestID, alt, height, width, size, stretch, objectFit, ...rest }: Props) {
  const DEFAULT_SIZE = stretch ? '100%' : 240;
  const h = size || height || DEFAULT_SIZE;
  const w = size || width || DEFAULT_SIZE;
  const [shouldHide, setShouldHide] = useState(false);

  const hideExists = 'hide' in rest && rest.hide;
  useEffect(updateHideState, [rest, hideExists]);
  function updateHideState() {
    if ('hide' in rest && rest.hide !== undefined) {
      setShouldHide(rest.hide);
    }
  }

  const fallback = 'fallbackSrc' in rest ? rest.fallbackSrc : undefined;
  return shouldHide ? null : (
    <ImageBox w={w} h={h}>
      <img
        loading={rest.loading}
        src={src || fallback}
        data-testid={dataTestID}
        alt={alt}
        style={{
          objectFit: objectFit || (stretch ? 'cover' : 'fill'),
          height: 'inherit',
          width: '100%',
        }}
      />
    </ImageBox>
  );
}
