import { useNavigate } from 'react-router-dom';

export function getLessonRoute(lessonId: number, restart = false, forceSeparate = false): string {
  const params = new URLSearchParams({
    restart: restart.toString(),
    force_separate: forceSeparate.toString(),
  });

  let route = `/video_player/${lessonId}?${params.toString()}`;
  if (globalThis.location.pathname === '/admin') {
    route = `/admin${route}`;
  }

  return route;
}

function canNavigateBack(): boolean {
  /**
   * window.history.state.idx: In some web frameworks (like React Router), idx is used to
   * keep track of the position in the history stack. A value greater than 0 implies that
   * there are previous entries in the history stack, meaning the user has navigated at
   * least once since opening the page. Hence, we are understanding whether the user has
   * navigated withing the Kyron app.
   */
  const hasInAppHistory = window.history.state.idx > 0;

  /**
   * After a redirect, if user wants to go back to the previous page by clicking the back button, they will immediately
   * be redirected to back they were. Because, whatever the page they were previously is redirecting them here. So, we
   * shouldn't show the back button in this case and leave it to the browser's back button.
   */
  // This rule blocks me from getting `_isRedirect` from `window.history.state.usr`
  const isInitialRedirection = window.history.state.usr?._isRedirect;

  return hasInAppHistory && !isInitialRedirection;
}

export const useNavigateBack = () => {
  const navigate = useNavigate();

  function navigateBack({ fallback }: { fallback: string } = { fallback: '/' }) {
    if (canNavigateBack()) {
      return navigate(-1);
    }

    return navigate(fallback);
  }

  return navigateBack;
};
