import { Subscription, SubscriptionPayload } from 'controllers/types';
import { useKyronQuery } from './kyronQuery';
import { useKyronMutationV2 } from './kyronMutation';

export const useSubscriptionsQuery = (status?: 'active' | 'failed') =>
  useKyronQuery<Subscription[]>(`/subscriptions?status=${status}`, {
    queryKey: ['/subscriptions'],
  });

// Creating or updating a subscription means we need to re-check whether the user
// has an active subscription or not, so we need to invalidate the current user
export const useCreateSubscription = () =>
  useKyronMutationV2<{ payload: SubscriptionPayload }, Subscription>(`/subscriptions`, {
    invalidatesMultiple: [['/subscriptions'], ['/users/current']],
  });

export const useUpdateSubscription = () =>
  useKyronMutationV2<{ subscriptionId: number; payload: SubscriptionPayload }, Subscription>(
    `/subscriptions/:subscriptionId`,
    {
      method: 'PATCH',
      invalidatesMultiple: [['/subscriptions'], ['/users/current']],
    },
  );
