import { Box, Drawer, SxProps } from '@mui/material';
import { AppBar } from 'components/AppBar/AppBar';
import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { NavDrawerButton } from 'components/NavDrawerButton/NavDrawerButton';
import { MarketplaceNav } from '../MarketplaceNav/MarketplaceNav';
import { MarketplaceSearch } from '../MarketplaceSearch/MarketplaceSearch';
import { KyronEvents } from '../../utils/KyronEvents';

const NAV_WIDTH = '200px';

const styles: Record<string, SxProps> = {
  desktopNav: {
    width: NAV_WIDTH,
    flexShrink: 0,
    '& .MuiDrawer-paper': { width: NAV_WIDTH, bgcolor: 'background.default', borderRight: 'none', p: 0 },
    display: { xs: 'none', sm: 'block' },
  },
  mobileNav: { display: { xs: 'block', sm: 'none' } },
  header: { left: { xs: 0, sm: NAV_WIDTH } },
  main: { paddingLeft: { xs: 0, sm: NAV_WIDTH } },
  wrapper: { padding: { xs: '8px 16px 16px 16px' } },
};

export function MarketplaceLayout() {
  const location = useLocation();
  // to stop search bar from rendering on Channel page (or others that use MarketplaceLayout)
  const isMarketplaceHomePage = location.pathname === '/marketplace';
  const [mobileNavDrawerOpen, setMobileNavDrawerOpen] = React.useState(false);
  const [dataLoading, setDataLoading] = useState(false);

  function toggleMobileNavDrawer() {
    setMobileNavDrawerOpen(!mobileNavDrawerOpen);
  }

  const navButton = (
    <NavDrawerButton
      setDrawerOpen={setMobileNavDrawerOpen}
      drawerOpen={mobileNavDrawerOpen}
      sx={{ display: { xs: 'flex', sm: 'none' } }}
    />
  );

  return (
    <>
      <Drawer variant='permanent' sx={styles.desktopNav}>
        <MarketplaceNav />
      </Drawer>

      <Drawer open={mobileNavDrawerOpen} onClose={toggleMobileNavDrawer} sx={styles.mobileNav}>
        <MarketplaceNav />
      </Drawer>

      <AppBar
        showLogo={false}
        navButton={navButton}
        sx={styles.header}
        mainToolbarSearch={
          isMarketplaceHomePage && (
            <MarketplaceSearch
              placeholder='Search for courses'
              isLoading={dataLoading}
              onSearch={(searchTerm: string) => {
                const eventName = KyronEvents.names.SEARCHED_MARKETPLACE;
                KyronEvents.sendEvent(eventName, { search: searchTerm }).catch(() => {
                  console.error(`Failed to send ${eventName} event. Search: ${searchTerm}`);
                });
              }}
            />
          )
        }
      />

      <Box component='main' sx={styles.main}>
        <Box sx={styles.wrapper}>
          {/* search and setDataLoading are currently being used in useGetMarketplaceLessonCollections hook */}
          <Outlet context={{ setDataLoading }} />
        </Box>
      </Box>
    </>
  );
}
