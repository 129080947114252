import { Channel, LessonCollection } from 'controllers/types';
import { useKyronQuery } from './kyronQuery';

export const useChannelQuery = (channelId: number) =>
  useKyronQuery<Channel>(`/channels/${channelId}`, {
    queryKey: ['/channels', channelId],
    enabled: !!channelId,
  });

export const useChannelLessonCollectionsQuery = (channelId: number) =>
  useKyronQuery<LessonCollection[]>(`/channels/${channelId}/lesson_collections`, {
    queryKey: ['/channels', channelId, 'lesson_collections'],
    enabled: !!channelId,
  });
