import { Box, Skeleton } from '@mui/material';
import { Row } from 'components/Row/Row';
import React from 'react';

export function MarketplaceLessonCardSkeleton() {
  return (
    <Box>
      <Skeleton variant='rounded' sx={{ aspectRatio: '16/9', height: 'auto' }} />
      <Row sx={{ alignItems: 'flex-start', gap: '8px', mt: 1 }}>
        <Skeleton variant='circular' width={40} height={40} sx={{ flexShrink: 0 }} />
        <Box sx={{ flex: 1 }}>
          <Skeleton variant='text' />
          <Skeleton variant='text' width='60%' />
        </Box>
      </Row>
    </Box>
  );
}
