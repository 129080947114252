import { keepPreviousData, useKyronQuery } from './kyronQuery';
import { Pagination, QueryPaginationProps, Tutor } from '../types';
import { useKyronMutation } from './kyronMutation';

export type TutorPayload = Omit<Tutor, 'id'> & { id?: number; profile_picture?: string };

export const useTutorsQuery = (opts: QueryPaginationProps = { page: 1, perPage: 25 }) =>
  useKyronQuery<{ tutors: Tutor[]; meta: Pagination }>(`/tutors?page=${opts.page}&per_page=${opts.perPage}`, {
    queryKey: ['/tutors', opts.page, opts.perPage],
    placeholderData: keepPreviousData,
  });

export const useTutorQuery = (tutorId?: number) =>
  useKyronQuery<Tutor>(`/tutors/${tutorId}`, {
    queryKey: ['/tutors', tutorId],
    enabled: !!tutorId,
  });

export const useTutorMutation = (tutorId?: number) =>
  useKyronMutation<TutorPayload, Tutor>(tutorId ? `/tutors/${tutorId}` : '/tutors', {
    method: tutorId ? 'PATCH' : 'POST',
    invalidates: ['/tutors'],
  });
