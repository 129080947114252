import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  ListItem,
  ListItemText,
  Checkbox,
  Alert,
} from '@mui/material';
import { Classroom } from 'controllers/types';
import { LoadingIndicator } from 'components/LoadingIndicator';

type Props = {
  open: boolean;
  onClose: () => void;
  handleCreateGoogleClassroom: (ids: number[]) => void;
  googleClassrooms: Classroom[];
  isFetching: boolean;
};

export function SyncGoogleClassroom({
  handleCreateGoogleClassroom,
  open,
  onClose,
  googleClassrooms,
  isFetching,
}: Props) {
  const [selectedClassroomIds, setSelectedClassroomIds] = useState<number[]>([]);

  const isAllSelected = googleClassrooms?.length === selectedClassroomIds.length;

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedClassroomIds([]);
    } else {
      setSelectedClassroomIds(googleClassrooms?.map(classroom => classroom.id) || []);
    }
  };

  const addGoogleClassrooms = (classrooms: number[]) => {
    try {
      const data = handleCreateGoogleClassroom(classrooms);
      return data;
    } catch (error) {
      console.error('Error adding Google classrooms', error);
    }
  };

  const handleToggle = (classroomId: number) => {
    setSelectedClassroomIds(prevSelected => {
      if (prevSelected.includes(classroomId)) {
        return prevSelected.filter(id => id !== classroomId);
      }
      return [...prevSelected, classroomId];
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    addGoogleClassrooms(selectedClassroomIds);
    setSelectedClassroomIds([]);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='dialog-title'>
      <DialogTitle id='dialog-title'>Connect Google Classroom</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent style={{ maxHeight: '530px', overflowY: 'auto' }}>
          <DialogContentText>
            Please select the classrooms you would like to sync to your Kyron account.
          </DialogContentText>
          {isFetching && <LoadingIndicator sx={{ justifyContent: 'center', alignItems: 'center' }} />}
          {!isFetching && googleClassrooms && googleClassrooms.length > 0 && (
            <>
              <Button sx={{ marginTop: '8px' }} variant='text' onClick={handleSelectAll}>
                {isAllSelected ? 'Deselect All' : 'Select All'}
              </Button>
              {googleClassrooms.map(classroom => (
                <ListItem
                  sx={{ cursor: 'pointer' }}
                  key={classroom.id}
                  dense
                  onClick={() => handleToggle(classroom.id)}
                >
                  <Checkbox checked={selectedClassroomIds.includes(classroom.id)} />
                  <ListItemText primary={classroom.name} />
                </ListItem>
              ))}
            </>
          )}
          {!isFetching && (!googleClassrooms || googleClassrooms.length === 0) && (
            <Alert severity='error' style={{ marginTop: '20px' }}>
              You have no Google Classrooms available to sync.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={onClose}>
            Cancel
          </Button>
          <Button disabled={!selectedClassroomIds.length} type='submit' variant='text'>
            Sync Classrooms ({selectedClassroomIds.length})
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
