import React from 'react';
import { Link } from 'react-router-dom';
import { Box, SxProps, Typography } from '@mui/material';

const styles: Record<string, SxProps> = {
  footer: {
    alignItems: 'left',
    backgroundColor: 'background.default',
    display: 'flex',
    height: '40px',
    padding: '0 24px',
    zIndex: '10',
  },
  navigation: {
    alignItems: 'center',
    display: 'flex',
    gap: '24px',
    a: {
      display: 'flex',
    },
  },
};

export function AppFooter() {
  return (
    <Box component='footer' sx={styles.footer} data-testid='app-footer'>
      <Box sx={styles.navigation}>
        <Link to='/terms_of_service' data-testid='terms-link'>
          <Typography variant='bodySmall' color='textSecondary'>
            Terms
          </Typography>
        </Link>
        <Link to='/privacy_policy' data-testid='privacy-link'>
          <Typography variant='bodySmall' color='textSecondary'>
            Privacy
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}
