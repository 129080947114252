import React from 'react';
import { Card, CardContent, Stack, LinearProgress, Skeleton } from '@mui/material';

export function LessonsLoadingIndicator() {
  return (
    <Card sx={{ bgcolor: 'inherit', minWidth: 300 }} data-testid='loading-indicator'>
      <CardContent>
        <Stack gap={4} sx={{ opacity: 0.8 }}>
          <Stack gap={1}>
            <LinearProgress sx={{ height: 40, width: '60%' }} color='inherit' />
            <LinearProgress sx={{ height: 30, width: '50%' }} color='inherit' />
          </Stack>
          <Stack gap={1}>
            <LinearProgress sx={{ height: 20, width: '85%' }} color='inherit' />
            <LinearProgress sx={{ height: 20, width: '80%' }} color='inherit' />
            <LinearProgress sx={{ height: 20, width: '85%' }} color='inherit' />
            <LinearProgress sx={{ height: 20, width: '75%' }} color='inherit' />
            <LinearProgress sx={{ height: 20, width: '85%' }} color='inherit' />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export function SectionLoadingIndicator() {
  return (
    <Card sx={{ bgcolor: 'inherit' }} data-testid='loading-indicator'>
      <CardContent>
        <Stack gap={1} sx={{ opacity: 0.8 }}>
          <Stack gap={2}>
            <Skeleton variant='rectangular' height={18} />
            <Skeleton variant='rectangular' height={18} />
            <Skeleton variant='rectangular' height={18} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
