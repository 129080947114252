import React from 'react';
import { Skeleton, Box, Grid } from '@mui/material';

export function GraphSkeleton() {
  return (
    <Grid>
      <Box sx={{ width: '100%', height: 425, mt: 2 }}>
        <Skeleton variant='rounded' width='100%' height='100%' />
      </Box>
      <Box sx={{ width: '100%', height: 425, mt: 2 }}>
        <Skeleton variant='rounded' width='100%' height='100%' />
      </Box>
    </Grid>
  );
}
