import React from 'react';
import { Alert, AlertTitle, Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { useRegenerateAllLessonVideos } from 'controllers/react-query';
import { LessonValidation } from 'controllers/types';
import { useModal } from 'components/utils/ModalContext';

// defined in LessonsController#validate
export enum ValidationErrors {
  MISSING_VIDEOS = 'Some segment videos are missing',
  UNNORMALIZED_VIDEOS = 'Some segment videos are not normalized',
  NO_START_SEGMENTS = 'No Start Segment exists',
  MULTIPLE_START_SEGMENTS = 'Multiple Start Segments exist',
  NO_END_SEGMENTS = 'At least 1 End Segment exists',
  UNUSED_SEGMENTS = 'Some segments are not used',
}

function GenerateMissingVideosButton({ lessonId }: { lessonId?: number }) {
  const { openModal } = useModal();
  const regenerateAllLessonVideos = useRegenerateAllLessonVideos();
  if (!lessonId) return null;

  async function regenerateMissingSegmentVideos() {
    if (!lessonId) return;
    await regenerateAllLessonVideos.mutateAsync({
      lessonId,
    });
  }

  return (
    <Button
      color='error'
      variant='outlined'
      startIcon={<Refresh />}
      onClick={() => {
        openModal({
          id: 'generate-missing-videos-modal',
          title: 'Generate Missing Videos',
          content:
            'Are you sure you want to generate the missing videos for this course? This may take a few minutes and cannot be undone.',
          onConfirm: regenerateMissingSegmentVideos,
        });
      }}
    >
      Generate Missing Videos
    </Button>
  );
}

type ValidationsDisplayProps = {
  lessonId?: number;
  failingValidations: LessonValidation[];
  disabled?: boolean;
};
export function ValidationsDisplay({ lessonId, failingValidations, disabled }: ValidationsDisplayProps) {
  if (!lessonId) return null;
  if (!failingValidations.length) return null;
  if (disabled) return null;

  return (
    <Stack gap={1} data-testid='publish-validations'>
      <Divider sx={{ my: 2 }} />
      <Typography variant='titleMedium'>Validation Errors:</Typography>
      <Typography variant='bodyMedium'>
        We have detected the following errors in your course. Please fix them in order to publish.
      </Typography>
      {failingValidations?.map(validation => (
        <Tooltip key={validation.name} title={validation.message}>
          <Alert key={validation.name} severity='error'>
            <Stack gap={1}>
              <AlertTitle sx={{ m: 0 }}>{validation.name}</AlertTitle>
              {validation.name === ValidationErrors.MISSING_VIDEOS ? (
                <GenerateMissingVideosButton lessonId={lessonId} />
              ) : null}
            </Stack>
          </Alert>
        </Tooltip>
      ))}
    </Stack>
  );
}
