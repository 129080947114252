import React, { useState, useEffect, useRef, CSSProperties } from 'react';

import ReactPlayer from 'react-player';
import { PlayArrow as PlayArrowIcon, Pause as PauseIcon } from '@mui/icons-material';
import { Box, GlobalStyles, IconButton, SxProps } from '@mui/material';

import { styled } from '@mui/material/styles';
import { OnProgressProps } from 'react-player/base';
import { LessonSegment } from 'controllers/types';
import { BaseReactPlayerProps } from 'react-player/types/base';
import { getCaptionsConfig } from 'components/utils/videoUtils';
import { KyronEvents } from 'components/utils/KyronEvents';
import { VIDEO_PLAYBACK_RATE_KEY } from '../../../constants';

type StylesProps = { ProgressContainer: CSSProperties; PlayButton: CSSProperties };

const styles: StylesProps = {
  ProgressContainer: {
    position: 'fixed',
    left: '0px',
    top: '0px',
    width: '100%',
    height: '100%',
    zIndex: '9999',
    justifyContent: 'center',
    alignItems: 'center',
  },
  PlayButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10,
  },
};

const PlayIconButton = styled(IconButton)({
  color: 'white',
  fontSize: '10vw',
  backgroundColor: 'rgba(0, 0, 0, 0.30)',
});

type LessonPlayer2Props = {
  runningLessonSegment: LessonSegment;
  segmentId: number;
  sessionId: number;
  setWaitTimeout: (waitTimeout: () => void) => void;
  cancelWaitTimeout: () => void;
  handleNextSegment: () => void;
  videoUrl: string;
  sx?: SxProps;
};

export function VideoPlayer({
  runningLessonSegment,
  segmentId,
  sessionId,
  setWaitTimeout,
  cancelWaitTimeout,
  handleNextSegment,
  videoUrl,
  sx,
}: LessonPlayer2Props) {
  // refs
  const playerRef = useRef<ReactPlayer>(null);
  const progressInterval = 100;

  // states
  const [playing, setPlaying] = useState(true);
  const [pauseVisible, setPauseVisible] = useState(true);
  const [played, setPlayed] = useState(runningLessonSegment.start_time);
  const [muted, setMuted] = useState(false);

  // USER PREFERENCE STATES - should be in sync with session storage ------------------------
  const desiredPlaybackRateFromStorage = sessionStorage.getItem(VIDEO_PLAYBACK_RATE_KEY);
  const [playbackRate, setPlaybackRate] = useState(Number(desiredPlaybackRateFromStorage) || 1.0);

  // END - USER PREFERENCE STATES -----------------------------------------------------------

  useEffect(() => {
    const timeoutId = setTimeout(() => setPauseVisible(false), 600);
    return () => clearTimeout(timeoutId);
  }, []);

  // This function handles the automatic pause timeout. If a student sits on a
  // wait_segment for more than time time_out value the lesson gets paused.
  //
  function handleWaitTimeout() {
    console.debug('handleWaitTimeout');
    KyronEvents.sendEvent(KyronEvents.names.WAIT_TIMEOUT, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
      played,
    });
    setPlaying(false);
    setInternalPlayerPlayPause(false);
  }

  // This function works in conjunction w/ handleWaitTimeout to set and reset
  // timeout between step and wait segments. Our timeouts only make sense when
  // we are looping on a wait_segment.
  //
  function handleTimeoutSetting() {
    if (!playing) return;
    if (runningLessonSegment.segment_type === 'wait') {
      setWaitTimeout(handleWaitTimeout);
    } else {
      cancelWaitTimeout();
    }
  }

  function setInternalPlayerPlayPause(play: boolean) {
    if (play) {
      console.debug('playing', playerRef.current);
      if (played < runningLessonSegment.start_time) {
        playerRef.current?.seekTo(runningLessonSegment.start_time, 'seconds');
      }
      playerRef.current
        ?.getInternalPlayer()
        ?.play()
        .catch((e: DOMException) => {
          console.warn(`Cannot start video before user interacts the DOM. Error: ${e}`);
          setPlaying(false); // setting playing to false so state is correct
        });
    } else {
      console.debug('pausing');
      playerRef.current?.getInternalPlayer()?.pause();
    }
  }

  function setVideoPlayer(lessonSegment: LessonSegment) {
    KyronEvents.sendEvent(KyronEvents.names.PLAYING_SEGMENT, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: lessonSegment.name,
    });

    playerRef.current?.seekTo(lessonSegment.start_time, 'seconds');
    setPlayed(lessonSegment.start_time);
    setPlaying(true);
    setInternalPlayerPlayPause(true);
    console.debug(
      'setVideoPlayer: ',
      lessonSegment.segment_type,
      '--',
      lessonSegment.start_time,
      '::',
      lessonSegment.end_time,
    );

    if (lessonSegment.segment_type === 'wait') {
      setMuted(true);
    } else {
      setMuted(false);
    }
  }

  // handleEnd and handleProgress functionally do the same thing. The reason
  // we need both is because there are two types of lessons - compiled (single
  // video) lessons and uncompiled (multiple video files) lessons. When we are
  // running a compiled lesson handleProgress handles moving from one segment
  // to another and looping on wait_segments and when we are running an
  // uncompiled lesson handleEnd does the same.
  //
  function handleEnd() {
    handleTimeoutSetting();
    if (runningLessonSegment.segment_type === 'wait') {
      console.debug('handleEnd - wait loop -- ', played, '---', runningLessonSegment.end_time);
      setPlayed(runningLessonSegment.start_time);
      if (playerRef.current) {
        playerRef.current.getInternalPlayer().controls = false; // should be already false
        playerRef.current.seekTo(runningLessonSegment.start_time, 'seconds');
      }
    } else {
      console.debug('handleEnd: going to next segment');
      setPlaying(false);
      setInternalPlayerPlayPause(false);
      handleNextSegment();
    }
  }

  const handleProgress = (state: OnProgressProps) => {
    // handleProgress gets called even if the ReactPlayer is not playing
    // the video. so we don't want to do anything if nothing is playing
    if (!playing) return;
    if (playerRef.current && state.playedSeconds < runningLessonSegment.start_time) {
      playerRef.current.seekTo(runningLessonSegment.start_time, 'seconds');
    }
    if (playing && state.playedSeconds + progressInterval / 2000.0 >= runningLessonSegment.end_time) handleEnd();
  };

  const handleSeek = (e: number) => {
    console.debug('handleSeek: ', e);
    KyronEvents.sendEvent(KyronEvents.names.USE_SCRUBBER, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
      seek_to: e + runningLessonSegment.start_time,
    });
  };

  // The internal state of the playing is not handled properly by the ReactPlayer,
  // so we are using event.player.isPlaying to check if the player is playing or not
  // before we start the video.
  const handleReady = (event: BaseReactPlayerProps) => {
    if (playerRef.current) {
      if (runningLessonSegment.segment_type === 'wait') {
        playerRef.current.getInternalPlayer().controls = false;
      } else {
        playerRef.current.getInternalPlayer().controls = true;
      }
    }
    if (!event.player.isPlaying) {
      console.debug('handleReady when player is not playing');
      setInternalPlayerPlayPause(true);
    }
  };

  //----------------------------------------------------------------------------
  // The next group of functions all handle video control states.
  //
  const handleSetPlaybackRate = (v: number) => {
    KyronEvents.sendEvent(KyronEvents.names.CHANGE_PLAYBACK_RATE, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
      rate: v,
    });
    setPlaybackRate(v);
    // set the playback rate in session storage for user preferences
    sessionStorage.setItem(VIDEO_PLAYBACK_RATE_KEY, v.toString());
  };

  const handlePlayPause = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    KyronEvents.sendEvent(KyronEvents.names.CLICK_PLAY_PAUSE, {
      session_id: sessionId,
      segment_id: segmentId,
      segment_name: runningLessonSegment.name,
      playing,
      played,
    });
    setPauseVisible(true);
    setTimeout(() => setPauseVisible(false), 600);
    setInternalPlayerPlayPause(!playing);
    setPlaying(!playing);
  };

  //---------------------------------------------------------------------------

  const handlePlay = () => {
    handleTimeoutSetting();
  };

  useEffect(() => {
    setVideoPlayer(runningLessonSegment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runningLessonSegment.id]);

  //* *************************************************************************&
  if (runningLessonSegment.video_url === null) {
    return <div />;
  }
  return (
    <>
      <GlobalStyles
        styles={{
          'video::-webkit-media-controls-enclosure': { opacity: 0, transition: 'opacity 0.2s !important' },
          'video:hover::-webkit-media-controls-enclosure': { opacity: 1 },
        }}
      />
      {/* video screen */}
      <Box onClick={handlePlayPause} sx={{ position: 'relative', ...sx }}>
        <ReactPlayer
          key={sessionId}
          ref={playerRef}
          url={videoUrl}
          controls
          // Never pass a truthy value here! See a `useEffect` earlier in this
          // file for more details.
          playing={false}
          playbackRate={playbackRate}
          onPlaybackRateChange={handleSetPlaybackRate}
          onSeek={handleSeek}
          progressInterval={progressInterval}
          playsinline
          muted={muted}
          loop={false}
          onPlay={handlePlay}
          onReady={handleReady}
          onProgress={handleProgress}
          onEnded={handleEnd}
          width='100%'
          height='100%'
          config={getCaptionsConfig(runningLessonSegment.video_vtt_url || '', 'en')}
        />
        {/* Play/Pause button in center of video */}
        <div style={styles.PlayButton}>
          {pauseVisible && (
            <PlayIconButton aria-label='play or pause' onClick={handlePlayPause}>
              {!playing ? <PlayArrowIcon fontSize='inherit' /> : <PauseIcon fontSize='inherit' />}
            </PlayIconButton>
          )}
        </div>
      </Box>
      {/* This is for mabl testing */}
      <span hidden id='forMABL'>
        {runningLessonSegment.name}
      </span>
    </>
  );
}
