import { Box, Stack, Typography } from '@mui/material';
import circleDrawing from 'assets/circleDrawing.svg';
import lineDrawing from 'assets/lineDrawing.svg';
import React from 'react';

type FlowchartStepProps = {
  number: number;
  title: string;
  subtitle: string;
};

export function FlowchartStep({ number, title, subtitle }: FlowchartStepProps) {
  function circledNumber() {
    return (
      <Box
        sx={{
          position: 'relative',
          width: '48px',
          height: '48px',
        }}
      >
        <Box
          component='img'
          src={circleDrawing}
          alt='circle drawing'
          sx={{
            width: '100%',
            height: '100%',
          }}
        />
        <Typography
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            fontFamily: 'Permanent Marker, Arial, sans-serif',
            fontSize: '32px',
          }}
        >
          {number}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack alignItems='center' gap={1} maxWidth='400px'>
      {circledNumber()}
      <Typography variant='titleLarge'>{title}</Typography>
      <Typography variant='bodyLarge' textAlign='center'>
        {subtitle}
      </Typography>
      <Box component='img' alt='line drawing' src={lineDrawing} sx={{ width: '100%' }} />
    </Stack>
  );
}
