/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import IconButton from '@mui/material/IconButton';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useSnackbar } from 'notistack';
import { KyronClient } from '../utils/KyronClient';

export const LessonImport = () => {
  const { enqueueSnackbar } = useSnackbar();
  const client = new KyronClient();

  const handleLessonImport = e => {
    e.preventDefault();
    /* At the moment I cannot find a way to get all the files in an array
    sent to Rails, so each file is going to be in its own parameter. But
    this also has the problem of not being able to handle multiple videos
    ATM. Probably OK for the first pass */
    const { files } = e.target;
    const lessonImport = {};
    for (let i = 0; i < e.target.files.length; i++) {
      if (files[i].type === 'video/mp4') {
        lessonImport.import_video = files[i];
      } else {
        lessonImport.import_lesson = files[i];
      }
    }
    const formData = new FormData();
    for (const property in lessonImport) {
      formData.append(property, lessonImport[property]);
    }

    /* It is not currently possible to JSON'ify a File object so
     * we're using the form submission
     */
    client
      .submitDataWithError('/api/v1/lessons/import', 'PUT', formData)
      .then(() => {
        enqueueSnackbar('Import Completed');
      })
      .catch(error => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <IconButton data-testid='import-button' aria-label='import lesson' size='large' component='label'>
      <DriveFolderUploadIcon size='large' />
      <input data-testid='file-upload' webkitdirectory='' type='file' hidden onChange={e => handleLessonImport(e)} />
    </IconButton>
  );
};
