import React from 'react';
import { Card, CardContent, CardMedia, Typography, Stack } from '@mui/material';
import { SchoolOutlined, Translate, Update } from '@mui/icons-material';
import dayjs from 'dayjs';

import { Locales } from 'components/utils/locales';
import { IconText } from 'components/IconText';
import { MinimalLesson, LessonCollection } from 'controllers/types';
import backupThumbnail from 'assets/defaultThumbnail.svg';
import { suffixForPlural } from 'components/utils/stringUtils';
import { AdminLessonVariants } from './AdminLessonVariants';
import { AdminLessonUnits } from './AdminLessonUnits';

const buildTutorString = (count: number) => (count > 0 ? `${count} tutor${suffixForPlural(count)}` : '');
const buildLocaleString = (lessons: MinimalLesson[]) => {
  const dedupedLocales = [...new Set(lessons.map(lesson => lesson.locale))];
  return dedupedLocales.reduce((acc, locale) => `${acc ? `${acc}, ` : ''}${Locales[locale]}`, '');
};

function AdminCollectionDetail({ collection }: { collection: LessonCollection }) {
  const lessons = collection.lessons || [];
  const localeString = buildLocaleString(lessons);
  const tutorString = buildTutorString(lessons.length);
  const lastUpdate = dayjs(collection.updated_at);

  return (
    <Stack gap={3}>
      <Typography variant='headlineMedium'>{collection.name}</Typography>
      <Typography>{collection.description}</Typography>
      <Stack direction='row' gap={4}>
        {tutorString ? (
          <IconText Icon={SchoolOutlined}>
            <Typography variant='labelLarge'>{tutorString}</Typography>
          </IconText>
        ) : null}
        {lastUpdate ? (
          <IconText Icon={Update}>
            <Typography variant='labelLarge'>Updated {lastUpdate.fromNow()}</Typography>
          </IconText>
        ) : null}
        {localeString ? (
          <IconText Icon={Translate}>
            <Typography variant='labelLarge'>{localeString}</Typography>
          </IconText>
        ) : null}
      </Stack>
    </Stack>
  );
}

export function AdminCollection({ collection }: { collection?: LessonCollection }) {
  return (
    <Card sx={{ bgcolor: 'surfaceContainer.lowest', p: 4 }}>
      <CardContent>
        <Stack gap={2} sx={{ maxWidth: 880 }}>
          <CardMedia
            component='img'
            sx={{ height: 120, width: 120, borderRadius: 2, opacity: !collection ? 0.3 : 1 }}
            image={collection?.collection_thumbnail || backupThumbnail}
            alt='lesson container thumbnail'
          />
          {!collection ? <Typography variant='headlineMedium'>Loading Lessons...</Typography> : null}
          {collection ? (
            <Stack gap={8}>
              <AdminCollectionDetail collection={collection} />
              <AdminLessonVariants lessons={collection.lessons} />
              <AdminLessonUnits collectionId={collection.parent_id} />
            </Stack>
          ) : null}
        </Stack>
      </CardContent>
    </Card>
  );
}

export { buildTutorString, buildLocaleString }; // export for testing
