import { TypographyOptions } from '@mui/material/styles/createTypography';

export const kyronTypography: TypographyOptions = {
  fontFamily: 'Open Sans',
  displayLarge: {
    fontFamily: 'Montserrat',
    fontSize: '57px',
    fontWeight: 700,
    lineHeight: '64px',
  },
  displayMedium: {
    fontFamily: 'Montserrat',
    fontSize: '45px',
    fontWeight: 700,
    lineHeight: '52px',
  },
  displaySmall: {
    fontFamily: 'Montserrat',
    fontSize: '36px',
    fontWeight: 700,
    lineHeight: '44px',
  },
  headlineLarge: {
    fontFamily: 'Montserrat',
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  headlineMedium: {
    fontFamily: 'Montserrat',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '36px',
  },
  headlineSmall: {
    fontFamily: 'Montserrat',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
  },
  titleLarge: {
    fontFamily: 'Open Sans',
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '26px',
  },
  titleMedium: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  titleSmall: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  bodyLarge: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  bodyMedium: {
    fontFamily: 'Open Sans',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  bodySmall: {
    fontFamily: 'Open Sans',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  labelLarge: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  labelMedium: {
    fontFamily: 'Montserrat',
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  labelSmall: {
    fontFamily: 'Montserrat',
    fontSize: '11px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  button: {
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    textTransform: 'none',
  },
  h1: undefined,
  h2: undefined,
  h3: undefined,
  h4: undefined,
  h5: undefined,
  h6: undefined,
  subtitle1: undefined,
  subtitle2: undefined,
  body1: undefined,
  body2: undefined,
  caption: undefined,
  overline: undefined,
};
