/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import { CardHeader, CardContent, IconButton } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { TutorEditForm } from './TutorEditForm';

export const TutorEditorDetails = props => {
  const [newTutorExpanded, setNewTutorExpanded] = useState(false);
  const handleEditTutorExpandClick = () => {
    setNewTutorExpanded(!newTutorExpanded);
  };

  return (
    <>
      <CardHeader
        action={
          <>
            <IconButton data-testid='edit-tutor' aria-label='edit tutor' onClick={handleEditTutorExpandClick}>
              <Edit />
            </IconButton>
            <IconButton data-testid='delete-tutor' aria-label='delete tutor' onClick={props.handleTutorDelete}>
              <Delete />
            </IconButton>
          </>
        }
      />

      <CardContent>
        <Collapse in={newTutorExpanded} timeout='auto' mountOnEnter>
          <TutorEditForm
            handleFormSubmit={props.handleTutorEdit}
            handleExpand={handleEditTutorExpandClick}
            tutor={props.tutor}
          />
        </Collapse>
      </CardContent>
    </>
  );
};

TutorEditorDetails.propTypes = {
  tutor: PropTypes.object.isRequired,
  handleTutorEdit: PropTypes.func.isRequired,
};
