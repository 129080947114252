import { Box, SxProps } from '@mui/material';
import React from 'react';
import texture from 'assets/texture.png';

type HomepageSectionProps = {
  pt?: string;
  pb?: string;
  bgcolor?: string;
  children: React.ReactNode;
  sx?: SxProps;
  id?: string;
};

export function HomepageSection({
  pt = '160px',
  pb = '160px',
  bgcolor = 'white',
  children,
  sx,
  id,
}: HomepageSectionProps) {
  return (
    <Box
      sx={{ pt, pb, px: { sm: '48px', xs: '16px' }, position: 'relative', bgcolor, overflow: 'hidden', ...sx }}
      id={id}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${texture})`,
          mixBlendMode: 'overlay',
          zIndex: 1,
        }}
      />
      {children}
    </Box>
  );
}
