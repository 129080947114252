// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`KyronLayout should match DOM snapshot 1`] = `
.emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
}

.emotion-1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: fixed;
  top: 0;
  z-index: 10;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  height: 64px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 64px;
  padding: 0 16px;
  background-color: #FFFFFF;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  margin-right: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
}

.emotion-4 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}

.emotion-5 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-5::-moz-focus-inner {
  border-style: none;
}

.emotion-5.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-5 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-5:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-5:hover {
    background-color: transparent;
  }
}

.emotion-5.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-6 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-7 {
  margin-top: 12px;
}

.emotion-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
  margin-left: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-9 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 5px 15px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid rgba(75, 53, 255, 0.5);
  color: #4B35FF;
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-9::-moz-focus-inner {
  border-style: none;
}

.emotion-9.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-9 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-9:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgba(75, 53, 255, 0.04);
  border: 1px solid #4B35FF;
}

@media (hover: none) {
  .emotion-9:hover {
    background-color: transparent;
  }
}

.emotion-9.Mui-disabled {
  color: #1C1B1F61;
  border: 1px solid #1C1B1F1F;
}

.emotion-9:hover {
  box-shadow: none;
}

.emotion-9.Mui-focusVisible {
  box-shadow: none;
}

.emotion-9:active {
  box-shadow: none;
}

.emotion-9.Mui-disabled {
  box-shadow: none;
}

.emotion-11 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #FFFFFF;
  background-color: #bdbdbd;
  width: 48px;
  height: 48px;
  background-color: #193615;
}

.emotion-12 {
  height: 64px;
}

.emotion-13 {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.emotion-14 {
  -webkit-align-items: left;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  padding: 0 24px;
  z-index: 10;
}

.emotion-15 {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 24px;
}

.emotion-15 a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.emotion-16 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #1C1B1FB3;
}

<div
  className="emotion-0"
  data-testid="kyron-layout"
>
  <header
    className="MuiBox-root emotion-1"
    data-testid="app-bar"
  >
    <div
      className="MuiStack-root emotion-2"
    >
      <div
        className="MuiStack-root emotion-3"
      >
        <div
          className="MuiBox-root emotion-4"
        >
          <button
            aria-haspopup="true"
            aria-label="Toggle navigation drawer"
            className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-5"
            data-testid="toggle-nav-drawer-button"
            disabled={false}
            onBlur={[Function]}
            onClick={[Function]}
            onContextMenu={[Function]}
            onDragLeave={[Function]}
            onFocus={[Function]}
            onKeyDown={[Function]}
            onKeyUp={[Function]}
            onMouseDown={[Function]}
            onMouseLeave={[Function]}
            onMouseUp={[Function]}
            onTouchEnd={[Function]}
            onTouchMove={[Function]}
            onTouchStart={[Function]}
            tabIndex={0}
            type="button"
          >
            <svg
              aria-hidden={true}
              className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-6"
              data-testid="MenuIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M3 18h18v-2H3zm0-5h18v-2H3zm0-7v2h18V6z"
              />
            </svg>
          </button>
        </div>
        <a
          data-testid="app-bar-logo"
          href="/"
          onClick={[Function]}
          style={
            {
              "display": "flex",
            }
          }
        >
          <svg
            data-testid="logo"
            fill="#4B35FF"
            height="24px"
            viewBox="0 0 780.29 106.49"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <g>
                <g>
                  <path
                    d="m128.66,11.77c0-6.5-5.27-11.77-11.77-11.77s-11.77,5.27-11.77,11.77c0,4.34,2.35,8.11,5.84,10.16v58.43h-29.09c-6.95,0-13.2,3.05-17.5,7.87-4.3-4.82-10.55-7.87-17.5-7.87h-18.64v11.88h18.64c6.34,0,11.5,5.13,11.56,11.46,0,.04,0,.07,0,.11h11.88s0-.07,0-.11c.06-6.33,5.22-11.46,11.56-11.46h40.97V21.9c3.47-2.05,5.81-5.81,5.81-10.14Z"
                  />
                  <path
                    d="m17.79,76.24V17.57h29.09c6.34,0,11.5,5.13,11.56,11.46,0,.04,0,.07,0,.11h11.88s0-.07,0-.11c.06-6.33,5.22-11.46,11.56-11.46h17.33V5.69h-17.33c-6.95,0-13.2,3.04-17.5,7.87-4.3-4.82-10.55-7.87-17.5-7.87H5.92v70.43c-3.53,2.03-5.92,5.83-5.92,10.2,0,6.5,5.27,11.77,11.77,11.77s11.77-5.27,11.77-11.77c0-4.29-2.31-8.04-5.74-10.09Z"
                  />
                </g>
                <g>
                  <path
                    d="m161.27,54.5h-8.35v31.21h-11.67V14.6h11.67v29.05h8.28l20.25-29.05h14.37l-24.3,34.3,26.52,36.8h-14.5l-22.26-31.21Z"
                  />
                  <path
                    d="m247.92,30.86l-25.2,55.85c-6.2,13.79-11.65,18.67-22.83,18.67h-5.57v-10.16h4.7c7.25,0,9.49-2.12,13.5-10.89l1.23-2.58-23.88-50.88h12.26l17.43,38.25,16.36-38.25h12Z"
                  />
                  <path
                    d="m284.01,30.55v10.65h-5.95c-9.33,0-13.98,5.32-13.98,15.25v29.25h-11.28V30.86h7.63l1.91,7.49c3.92-5.2,9.31-7.79,16.8-7.79h4.88Z"
                  />
                  <path
                    d="m313.38,87.04c-16.78,0-29.33-12.28-29.33-28.76s12.55-28.76,29.33-28.76,29.33,12.25,29.33,28.76-12.58,28.76-29.33,28.76h0Zm17.85-28.76c0-10.5-7.47-18-17.85-18s-17.85,7.5-17.85,18,7.47,18,17.85,18,17.85-7.5,17.85-18Z"
                  />
                  <path
                    d="m377.54,29.61c12.83,0,20.84,9.56,20.84,23.07v33.03h-11.27v-30.73c0-9.96-4.33-15.25-12.58-15.25s-14.73,6.93-14.73,16.62v29.37h-11.28V30.86h7.86l2.19,7.65c4.15-5.53,10.92-8.9,18.98-8.9h0Z"
                  />
                  <path
                    d="m445.34,77.35v8.36h-40.15V14.6h8.91v62.75h31.24Z"
                  />
                  <path
                    d="m499.74,61.63h-46.76c1.36,10.16,8.92,16.83,19.31,16.83,7.59,0,13.69-3.55,17.08-9.47h9.11c-4.64,11.31-14.29,17.92-26.18,17.92-15.91,0-27.97-12.29-27.97-28.64s12.06-28.62,27.97-28.62,27.64,12.83,27.64,28.85c0,1.04-.09,2.08-.19,3.12Zm-27.46-23.8c-9.96,0-17.32,6.27-19.11,15.82h38.09c-1.85-9.76-9.28-15.82-18.99-15.82h0Z"
                  />
                  <path
                    d="m562.26,30.86v54.85h-7.15l-.91-8.31c-5.06,5.9-12.62,9.51-21.52,9.51-16.22,0-28.39-12.29-28.39-28.63s12.17-28.63,28.39-28.63c8.97,0,16.58,3.63,21.63,9.61l1.08-8.41h6.87Zm-8.82,27.43c0-11.67-8.49-20.27-20.12-20.27s-20.22,8.72-20.22,20.27,8.6,20.27,20.22,20.27,20.12-8.6,20.12-20.27Z"
                  />
                  <path
                    d="m597.34,30.57v8.17h-5.6c-10.04,0-15.69,6.51-15.69,17.17v29.8h-8.63V30.86h6.13l1.4,8.35c3.88-5.61,9.63-8.64,17.53-8.64h4.86Z"
                  />
                  <path
                    d="m650.04,52.85v32.86h-8.63v-30.82c0-11.13-5.23-17.34-14.76-17.34s-16.6,7.94-16.6,18.23v29.93h-8.63V30.86h6.13l1.68,8.6c4.14-6.11,11.15-9.8,19.65-9.8,13.03,0,21.17,9.79,21.17,23.2Z"
                  />
                  <path
                    d="m656.05,11.46h9.35v9.9h-9.35v-9.9Zm.36,19.4h8.63v54.85h-8.63V30.86Z"
                  />
                  <path
                    d="m719.83,52.85v32.86h-8.63v-30.82c0-11.13-5.23-17.34-14.76-17.34s-16.6,7.94-16.6,18.23v29.93h-8.63V30.86h6.13l1.68,8.6c4.14-6.11,11.15-9.8,19.65-9.8,13.03,0,21.17,9.79,21.17,23.2Z"
                  />
                  <path
                    d="m780.29,30.86v47.18c0,17.68-10.21,28.45-26.89,28.45-13.45,0-22.99-7-25.99-18.64h8.63c2.44,6.86,9.36,10.75,17.35,10.75,10.97,0,18.45-7.28,18.45-19.45v-3.35c-4.92,5.08-11.93,8.16-19.98,8.16-15.23,0-26.98-11.77-26.98-27.1s11.77-27.2,26.98-27.2c8.77,0,16.35,3.73,21.3,9.73l1.37-8.53h5.76Zm-27.5,44.83c10.99,0,18.96-8,18.96-18.84s-7.98-18.93-18.96-18.93-19.09,8.11-19.09,18.93,8.1,18.84,19.09,18.84h0Z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </a>
      </div>
      <div
        className="MuiBox-root emotion-7"
      />
      <div
        className="MuiStack-root emotion-8"
      >
        <a
          className="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeMedium MuiButton-outlinedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-9"
          href="/studio"
          onBlur={[Function]}
          onClick={[Function]}
          onContextMenu={[Function]}
          onDragLeave={[Function]}
          onFocus={[Function]}
          onKeyDown={[Function]}
          onKeyUp={[Function]}
          onMouseDown={[Function]}
          onMouseLeave={[Function]}
          onMouseUp={[Function]}
          onTouchEnd={[Function]}
          onTouchMove={[Function]}
          onTouchStart={[Function]}
          tabIndex={0}
        >
          Kyron Studio
        </a>
        <button
          aria-haspopup="true"
          className="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-5"
          data-testid="account-menu-button"
          disabled={false}
          onBlur={[Function]}
          onClick={[Function]}
          onContextMenu={[Function]}
          onDragLeave={[Function]}
          onFocus={[Function]}
          onKeyDown={[Function]}
          onKeyUp={[Function]}
          onMouseDown={[Function]}
          onMouseLeave={[Function]}
          onMouseUp={[Function]}
          onTouchEnd={[Function]}
          onTouchMove={[Function]}
          onTouchStart={[Function]}
          tabIndex={0}
          type="button"
        >
          <div
            className="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault emotion-11"
            data-testid="initials-avatar"
          >
            T
          </div>
        </button>
      </div>
    </div>
  </header>
  <div
    className="MuiBox-root emotion-12"
    data-comment="header padding"
  />
  <main
    className="emotion-13"
  />
  <footer
    className="MuiBox-root emotion-14"
    data-testid="app-footer"
  >
    <div
      className="MuiBox-root emotion-15"
    >
      <a
        data-testid="terms-link"
        href="/terms_of_service"
        onClick={[Function]}
      >
        <p
          className="MuiTypography-root MuiTypography-bodySmall emotion-16"
        >
          Terms
        </p>
      </a>
      <a
        data-testid="privacy-link"
        href="/privacy_policy"
        onClick={[Function]}
      >
        <p
          className="MuiTypography-root MuiTypography-bodySmall emotion-16"
        >
          Privacy
        </p>
      </a>
    </div>
  </footer>
</div>
`;
