// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AssignmentCard should render the assignment card 1`] = `
<DocumentFragment>
  .emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid #D6D5DD;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  height: 142px;
  background-color: #FFFFFF;
}

.emotion-1 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: block;
  text-align: inherit;
  border-radius: inherit;
  width: 100%;
  height: 100%;
}

.emotion-1::-moz-focus-inner {
  border-style: none;
}

.emotion-1.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-1 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-1:hover .MuiCardActionArea-focusHighlight {
  opacity: 0.04;
}

@media (hover: none) {
  .emotion-1:hover .MuiCardActionArea-focusHighlight {
    opacity: 0;
  }
}

.emotion-1.Mui-focusVisible .MuiCardActionArea-focusHighlight {
  opacity: 0.12;
}

.emotion-2 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

.emotion-3 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 33.333333%;
  -ms-flex-preferred-size: 33.333333%;
  flex-basis: 33.333333%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 33.333333%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width:600px) {
  .emotion-3 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:900px) {
  .emotion-3 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1200px) {
  .emotion-3 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-3 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

.emotion-4 {
  display: block;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-position: center;
  background-position: center;
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.emotion-5 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 66.666667%;
  -ms-flex-preferred-size: 66.666667%;
  flex-basis: 66.666667%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 66.666667%;
}

@media (min-width:600px) {
  .emotion-5 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

@media (min-width:900px) {
  .emotion-5 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

@media (min-width:1200px) {
  .emotion-5 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

@media (min-width:1536px) {
  .emotion-5 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

.emotion-6 {
  padding: 16px;
}

.emotion-6:last-child {
  padding-bottom: 24px;
}

.emotion-7 {
  margin: 0;
  color: #1C1B1FB3;
}

.emotion-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.emotion-9 {
  margin: 0;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.emotion-10 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 8px;
  margin-right: 8px;
  margin-left: 4px;
  margin-right: 4px;
}

.emotion-11 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: inherit;
}

.emotion-13 {
  margin: 0;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.35em;
  color: #4B35FF;
}

.emotion-14 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.emotion-14>:not(style):not(style) {
  margin: 0;
}

.emotion-14>:not(style)~:not(style) {
  margin-top: 4px;
}

.emotion-15 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-15>:not(style):not(style) {
  margin: 0;
}

.emotion-15>:not(style)~:not(style) {
  margin-left: 8px;
}

.emotion-16 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-17 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1C1B1FB3;
}

.emotion-22 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
  color: #BA1A1A;
}

.emotion-23 {
  margin: 0;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #BA1A1A;
}

.emotion-24 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
  opacity: 0;
  background-color: currentcolor;
  -webkit-transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-25 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<div
    class="MuiPaper-root MuiPaper-outlined MuiPaper-rounded MuiCard-root emotion-0"
  >
    <a
      class="MuiButtonBase-root MuiCardActionArea-root emotion-1"
      href="/studio/classrooms/1/assignments/1"
      tabindex="0"
    >
      <div
        class="MuiGrid-root MuiGrid-container emotion-2"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-4 emotion-3"
        >
          <img
            class="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img emotion-4"
            src="SvgrURL"
          />
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-8 emotion-5"
        >
          <div
            class="MuiCardContent-root emotion-6"
          >
            <nav
              aria-label="breadcrumb"
              class="MuiTypography-root MuiTypography-body1 MuiBreadcrumbs-root emotion-7"
            >
              <ol
                class="MuiBreadcrumbs-ol emotion-8"
              >
                <li
                  class="MuiBreadcrumbs-li"
                >
                  <p
                    class="MuiTypography-root MuiTypography-bodySmall emotion-9"
                  >
                    Course 1
                  </p>
                </li>
                <li
                  aria-hidden="true"
                  class="MuiBreadcrumbs-separator emotion-10"
                >
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit emotion-11"
                    data-testid="NavigateNextIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
                    />
                  </svg>
                </li>
                <li
                  class="MuiBreadcrumbs-li"
                >
                  <p
                    class="MuiTypography-root MuiTypography-bodySmall emotion-9"
                  >
                    Unit 1
                  </p>
                </li>
              </ol>
            </nav>
            <h3
              class="MuiTypography-root MuiTypography-titleMedium MuiTypography-gutterBottom MuiTypography-noWrap emotion-13"
            >
              Assignment 1
            </h3>
            <div
              class="MuiStack-root emotion-14"
            >
              <div
                class="MuiStack-root emotion-15"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-16"
                  data-testid="HistoryIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9m-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8z"
                  />
                </svg>
                <p
                  class="MuiTypography-root MuiTypography-bodyMedium MuiTypography-noWrap emotion-17"
                >
                  Assigned 1 day ago
                </p>
              </div>
              <div
                class="MuiStack-root emotion-15"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-16"
                  data-testid="AccountCircleIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6m0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20"
                  />
                </svg>
                <p
                  class="MuiTypography-root MuiTypography-bodyMedium MuiTypography-noWrap emotion-17"
                >
                  2 students attempted
                </p>
              </div>
              <div
                class="MuiStack-root emotion-15"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-colorError MuiSvgIcon-fontSizeSmall emotion-22"
                  data-testid="WarningAmberIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 5.99 19.53 19H4.47zM12 2 1 21h22z"
                  />
                  <path
                    d="M13 16h-2v2h2zm0-6h-2v5h2z"
                  />
                </svg>
                <p
                  class="MuiTypography-root MuiTypography-bodyMedium MuiTypography-noWrap emotion-23"
                >
                  1 student with misconceptions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        class="MuiCardActionArea-focusHighlight emotion-24"
      />
      <span
        class="MuiTouchRipple-root emotion-25"
      />
    </a>
  </div>
</DocumentFragment>
`;
