import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Button, Stack, Typography } from '@mui/material';
import { FiberManualRecord, Stop, ZoomIn, ZoomOut } from '@mui/icons-material';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { useParams } from 'react-router-dom';
import { Row } from './Row/Row';
import { VideoPreview } from './LessonSegmentEditor/VideoPreview';
import { useUserContext } from './UserContext';
import { KyronEvents } from './utils/KyronEvents';

type VideoMonitorProps = {
  videoRef: React.RefObject<HTMLVideoElement>;
  startRecording: () => void;
  stopRecording: () => void;
  isRecording: boolean;
};
const VideoMonitor = ({ videoRef, startRecording, stopRecording, isRecording }: VideoMonitorProps) => (
  <Stack gap={2} alignItems='center' data-testid='video-monitor'>
    <video
      autoPlay
      muted
      ref={videoRef}
      style={{ transform: 'scaleX(-1)', height: '100%', width: '100%', maxHeight: 500 }}
    />
    <Row gap={2}>
      <Button
        variant='outlined'
        onClick={startRecording}
        disabled={isRecording}
        color='error'
        data-testid='start-recording-button'
      >
        <FiberManualRecord />
      </Button>
      <Button variant='outlined' onClick={stopRecording} disabled={!isRecording} data-testid='stop-recording-button'>
        <Stop />
      </Button>
    </Row>
  </Stack>
);

const ResizeableTextBox = ({ text }: { text?: string }) => {
  const [fontSize, setFontSize] = useState(24);
  const minFontSize = 18;
  const maxFontSize = 36;
  const lineHeight = fontSize / 16;

  return text ? (
    <Stack position='relative' height={150}>
      <Stack flex={1} p={3} sx={{ bgcolor: 'surfaceContainer.main', borderRadius: 1, overflowY: 'auto' }}>
        <Typography fontSize={fontSize} lineHeight={lineHeight}>
          {text}
        </Typography>
      </Stack>
      <Row position='absolute' top={2} right={2} justifyContent='flex-end'>
        <IconButton onClick={() => setFontSize(oldSize => oldSize - 2)} disabled={fontSize <= minFontSize}>
          <ZoomOut />
        </IconButton>
        <IconButton onClick={() => setFontSize(oldSize => oldSize + 2)} disabled={fontSize >= maxFontSize}>
          <ZoomIn />
        </IconButton>
      </Row>
    </Stack>
  ) : null;
};

type RecorderState = {
  stream: MediaStream | null;
  recorder: RecordRTCPromisesHandler | null;
  videoBlob: Blob | null;
  isRecording: boolean;
};
const initialRecorderState = {
  stream: null,
  recorder: null,
  videoBlob: null,
  isRecording: false,
};
const AUDIO_VIDEO_MEDIA_SETTINGS = {
  audio: true,
  video: {
    facingMode: 'user', // use device's front camera
    width: {
      ideal: 1280,
    },
    height: {
      ideal: 720,
    },
  },
};

type VideoRecorderProps = {
  isOpen: boolean;
  onSave: (blob: Blob) => void;
  onCancel: () => void;
  recordingScript?: string;
};
export function VideoRecorder({ isOpen, onSave, onCancel, recordingScript }: VideoRecorderProps) {
  const [recorderState, setRecorderState] = useState<RecorderState>(initialRecorderState);
  const [showPreview, setShowPreview] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const { stream, recorder, videoBlob, isRecording } = recorderState;
  const { user } = useUserContext();
  const { lessonId } = useParams<{ lessonId: string }>();

  // Setup the mediaStream and recorder
  useEffect(() => {
    if (isOpen) {
      navigator.mediaDevices.getUserMedia(AUDIO_VIDEO_MEDIA_SETTINGS).then(mediaStream => {
        setRecorderState({
          stream: mediaStream,
          recorder: new RecordRTCPromisesHandler(mediaStream, { type: 'video' }),
          videoBlob: null,
          isRecording: false,
        });
      });
    }
  }, [isOpen, setRecorderState]);

  // Reset recorderState on close
  useEffect(() => {
    if (!isOpen) {
      stream?.getTracks().forEach(track => track.stop());
      recorder?.stopRecording().then(() => {
        setRecorderState(initialRecorderState);
      });
    }
  }, [isOpen, stream, recorder, setRecorderState]);

  // Attach the mediaStream to the video element
  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [videoRef, stream, showPreview]);

  const startRecording = () => {
    if (recorder) {
      recorder.startRecording();
      setRecorderState(oldState => ({ ...oldState, isRecording: true }));
    }
  };

  const stopRecording = async () => {
    if (recorder) {
      await recorder.stopRecording();
      const blob = await recorder.getBlob();
      setRecorderState(oldState => ({ ...oldState, isRecording: false, videoBlob: blob }));
    }
  };

  const handleSave = () => {
    KyronEvents.sendEvent(KyronEvents.names.SAVED_VIDEO_RECORDING, {
      user_id: user?.id || 'anonymous',
      lesson_id: Number(lessonId),
      recording_script: recordingScript,
    });
    if (videoBlob) {
      onSave(videoBlob);
    }
  };

  return (
    <Stack gap={2} m={4} data-testid='video-recorder'>
      {showPreview ? (
        <>
          <Typography variant='headlineSmall'>Preview Video</Typography>
          <Stack justifyContent='center' alignItems='center'>
            <Box maxHeight={720}>
              <VideoPreview url={videoBlob ? URL.createObjectURL(videoBlob) : undefined} />
            </Box>
          </Stack>
          <Row gap={2} justifyContent='flex-end'>
            <Button onClick={() => setShowPreview(false)} variant='outlined'>
              Re-record
            </Button>
            <Button onClick={handleSave} disabled={!videoBlob || isRecording}>
              Save
            </Button>
          </Row>
        </>
      ) : (
        <>
          <Typography variant='headlineSmall'>Record Video</Typography>
          <Stack gap={2}>
            <Stack justifyContent='center' alignItems='center'>
              <Box height={150} width='75%'>
                <ResizeableTextBox text={recordingScript} />
              </Box>
            </Stack>
            <VideoMonitor
              videoRef={videoRef}
              startRecording={startRecording}
              stopRecording={stopRecording}
              isRecording={isRecording}
            />
          </Stack>
          <Row gap={2} justifyContent='flex-end'>
            <Button onClick={onCancel} variant='outlined'>
              Cancel
            </Button>
            <Button onClick={() => setShowPreview(true)} disabled={!videoBlob || isRecording}>
              Preview
            </Button>
          </Row>
        </>
      )}
    </Stack>
  );
}
