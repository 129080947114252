import React, { useState } from 'react';
import { Fab, Box, Zoom } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { DebugStreamsModalButton } from './DebugStreamsModalButton';
import { LessonJSONModalButton } from './LessonJSONModalButton';
import { useFeatures } from '../../../FeaturesContext';

const index = [
  { name: 'Debug Streams', component: <DebugStreamsModalButton /> },
  { name: 'Lesson Content JSON', component: <LessonJSONModalButton /> },
];

const FAB_HEIGHT = 68;
const TOOL_BUTTON_HEIGHT = 34;
const GAP = 16;

export function DevTools() {
  const { showEditorDevtools } = useFeatures();
  const [isOpen, setIsOpen] = useState(false);

  if (!showEditorDevtools) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 64,
        left: 16,
        // This logic is needed to maintain "mouseover" state when the user moves the mouse from the FAB to the tools
        height: isOpen ? `${index.length * TOOL_BUTTON_HEIGHT + FAB_HEIGHT + GAP}px` : 'auto',
      }}
      onMouseOver={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Box sx={{ position: 'relative' }}>
        <Fab size='medium' sx={{ transform: isOpen ? 'rotate(45deg)' : 'none', transition: 'transform 0.3s' }}>
          <ConstructionIcon />
        </Fab>

        {index.map((tool, i) => (
          <Zoom
            key={tool.name}
            in={isOpen}
            style={{
              position: 'absolute',
              left: 8,
              top: (i + 1) * 58 - i * GAP,
              transitionDelay: isOpen ? `${i * 100}ms` : '0ms',
            }}
          >
            <Box onClick={() => setIsOpen(false)}>{tool.component}</Box>
          </Zoom>
        ))}
      </Box>
    </Box>
  );
}
