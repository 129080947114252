import { Autocomplete, Box, TextField } from '@mui/material';
import { KyronAvatar } from 'components/KyronAvatar/KyronAvatar';
import { useTutorsQuery } from 'controllers/react-query';
import { Tutor } from 'controllers/types';
import React from 'react';

export function TutorSelect({ getSelected }: { getSelected: (tutor: Tutor | null) => void }) {
  const { data, isLoading } = useTutorsQuery({ page: 1, perPage: 200 });
  const { tutors } = data || { tutors: [] };

  return (
    <Autocomplete
      id='tutor-select'
      data-testid='tutor-select'
      sx={{ width: 300 }}
      slotProps={{ paper: { elevation: 1 } }}
      options={tutors}
      autoHighlight
      loading={isLoading}
      getOptionLabel={tutor => tutor.display_name}
      onChange={(_, tutor) => getSelected(tutor)}
      renderOption={(props, tutor) => (
        <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <KyronAvatar image={tutor.picture} label={tutor.display_name} sx={{ mr: 2 }} />
          {tutor.display_name}
        </Box>
      )}
      renderInput={params => (
        <TextField
          {...params}
          label='Filter by tutor'
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
