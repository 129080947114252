/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from '@mui/material';
import { CollectionForm } from './CollectionForm';

export const NewCollection = props => (
  <Card>
    <CardHeader title='New Collection' />
    <CollectionForm
      unit={props.unit}
      units={props.units}
      handleFormSubmit={props.handleFormSubmit}
      handleEditCollectionCancel={props.handleEditCollectionCancel}
    />
  </Card>
);

/* eslint-disable react/forbid-prop-types */

NewCollection.propTypes = {
  unit: PropTypes.object,
  units: PropTypes.array,
  handleFormSubmit: PropTypes.func,
  handleEditCollectionCancel: PropTypes.func,
};
