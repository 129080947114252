import { Box, Paper, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { AppBar } from 'components/AppBar/AppBar';
import React from 'react';
import { Check, Close } from '@mui/icons-material';
import { useUserContext } from 'components/UserContext';
import { MarketingHeader } from 'components/MarketingHome/components/MarketingHeader';
import { useFeatures } from 'components/FeaturesContext';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { ToggleSwitch } from '../ToggleSwitch/ToggleSwitch';
import { SubscribeButton } from './PaymentContext';

export function Pricing() {
  const [showAnnual, setShowAnnual] = React.useState(false);
  const { user } = useUserContext();
  const { show_stripe_payments: showStripePayments } = useFeatures();
  const shouldShowSubscribeButton = showStripePayments && user;

  const annualLabel = (
    <>
      Annual
      <Typography variant='bodySmall' color='textSecondary'>
        Save up to 17%
      </Typography>
    </>
  );

  const features = [
    {
      field: 'courses',
      label: 'Create courses',
    },
    {
      field: 'collaborators',
      label: 'Invite team members to collaborate',
    },
    {
      field: 'learners',
      label: 'Deploy courses to learners',
    },
    {
      field: 'courseAnalytics',
      label: 'Gain insights with course analytics',
    },
    {
      field: 'lti',
      label: 'LMS integration via LTI or SCORM',
    },
  ] as const;

  type FeatureFields = (typeof features)[number]['field'];
  type MappedLevelFields = {
    [key in FeatureFields]: string | boolean | number | React.ReactNode;
  };

  type Level = MappedLevelFields & {
    label: string;
    price: string;
    priceDetails?: string | React.ReactNode;
    subscribeButton: boolean;
  };

  const levels: Level[] = [
    {
      label: 'Demo',
      price: 'Free',
      learners: false,
      courses: 'Up to 3',
      collaborators: 'Unlimited',
      courseAnalytics: false,
      lti: false,
      subscribeButton: false,
    },
    {
      label: 'Organization',
      price: showAnnual ? '$200' : '$20',
      priceDetails: showAnnual ? 'per learner / year' : 'per learner / month',
      learners: 'Unlimited',
      courses: 'Unlimited',
      collaborators: 'Unlimited',
      courseAnalytics: true,
      lti: true,
      subscribeButton: true,
    },
  ];

  const renderBooleanIcon = (value: boolean) => (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {value ? <Check color='success' /> : <Close color='error' />}
    </Box>
  );

  return (
    <>
      <Helmet>
        <link rel='canonical' href='https://www.kyronlearning.com/pricing' />
      </Helmet>
      <Box
        sx={{
          bgcolor: 'surface.main',
          backgroundImage: `radial-gradient(circle 80vh at center bottom, #F1ECF4FF, transparent)`,
          minHeight: '100vh',
        }}
      >
        {user ? <AppBar mainToolBarSx={{ bgcolor: 'transparent' }} /> : <MarketingHeader />}

        <Stack gap={6} alignItems='center' py={10}>
          <Stack gap={2} alignItems='center'>
            <Typography variant='displayMedium'>Pricing</Typography>
            <Typography variant='bodyLarge' color='text.secondary'>
              Create AI-powered interactive courses.
            </Typography>
          </Stack>

          <ToggleSwitch
            offLabel='Monthly'
            onLabel={annualLabel}
            handleOff={() => setShowAnnual(false)}
            handleOn={() => setShowAnnual(true)}
          />

          <Paper elevation={3} sx={{ p: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  {levels.map(level => (
                    <TableCell key={level.label} align='center' sx={{ verticalAlign: 'top', paddingBottom: '16px' }}>
                      <Stack alignItems='center' px={5} gap={1}>
                        <Typography variant='titleMedium' color='secondary.main'>
                          {level.label}
                        </Typography>
                        <Stack alignItems='center'>
                          <Typography variant='headlineLarge' color='textPrimary'>
                            {level.price}
                          </Typography>
                          <Typography variant='bodyMedium' color='textSecondary'>
                            {level.priceDetails}
                          </Typography>
                        </Stack>

                        {level.subscribeButton && shouldShowSubscribeButton && (
                          <SubscribeButton subscription={showAnnual ? 'annual' : 'monthly'} />
                        )}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {features.map(({ field, label }) => (
                  <TableRow key={field}>
                    <TableCell>{label}</TableCell>
                    {levels.map(level => (
                      <TableCell key={level.label} align='center'>
                        {typeof level[field] === 'boolean' ? renderBooleanIcon(level[field] as boolean) : level[field]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>

          <Typography variant='bodyLarge' color='text.secondary'>
            Special use case or K-12? <Link to='/contact-us'>Contact Sales</Link>
          </Typography>
        </Stack>
      </Box>
    </>
  );
}
