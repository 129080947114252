/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import { Card, IconButton, Typography, Stack } from '@mui/material';

const styles = {
  unitCardField: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 2,
    p: 1,
    m: 1,
    shadow: 0,
  },
};

export const ShowUnit = props => (
  <Card direction='row' sx={styles.unitCardField}>
    <Typography variant='body1' color='text.secondary'>
      {props.unit.name}
    </Typography>
    <Stack direction='row' spacing={2}>
      <IconButton
        aria-label='move unit up'
        data-testid='move-unit-up'
        onClick={() => {
          props.reorderUnits(props.unit, 'up');
        }}
      >
        <KeyboardArrowUpIcon />
      </IconButton>
      <IconButton
        aria-label='move unit down'
        data-testid='move-unit-down'
        onClick={() => {
          props.reorderUnits(props.unit, 'down');
        }}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
    </Stack>
  </Card>
);

ShowUnit.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  unit: PropTypes.object,
  reorderUnits: PropTypes.func,
};
