import { useBreadcrumbs, useTitle } from 'components/StudioLayout/StudioLayout';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PlaylistPayload, usePlaylistQuery, useUpdatePlaylist } from 'controllers/react-query/playlistsHooks';
import { useSnackbar } from 'notistack';
import { PlaylistForm } from './PlaylistForm';

export const PlaylistEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { playlistId } = useParams();
  const navigate = useNavigate();
  const { data: playlist, isError, isLoading } = usePlaylistQuery(Number(playlistId));
  const { mutate: updatePlaylist } = useUpdatePlaylist();

  useTitle(playlist ? playlist.name : 'Loading playlist...');
  useBreadcrumbs(useMemo(() => [{ to: '/studio/playlists', label: 'Playlists' }], []));

  const onSubmit = (playlistPayload: PlaylistPayload) => {
    updatePlaylist(
      { playlistId: Number(playlistId), payload: playlistPayload },
      {
        onSuccess: updatedPlaylist => {
          enqueueSnackbar(`${updatedPlaylist.name} was successfully updated`, { variant: 'success' });
          navigate(`/studio/playlists`);
        },
        onError: error => {
          enqueueSnackbar(`Failed to update playlist: ${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    throw new Error('Error fetching playlist');
  }

  if (!playlist) {
    throw new Error('Playlist not found');
  }

  return <PlaylistForm playlistEdit={playlist} onSubmit={onSubmit} />;
};
