import { isPlainObject, snakeCase } from 'lodash';

export type Error = {
  resource: string;
  field: string;
  messages: string[];
};

type KyronErrorResponsePayload = {
  message: string;
  request_id: string;
};

type ResponseType = 'json' | 'blob';

type RequestOptions = { excludeSearchParams?: boolean; responseType?: ResponseType; headers?: Record<string, string> };

export class KyronClient {
  token: string;

  constructor() {
    this.token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '';
  }

  /**
   * @deprecated Please use submitJsonDataWithError instead
   */
  async submitDataWithError<T>(path: string, method: string, body: BodyInit): Promise<T> {
    const response = await this.submitData(path, method, body);
    if (response.status === 404 || response.status === 500) {
      let message: Error[];
      try {
        message = (await response.json()).errors;
      } catch {
        message = [{ resource: '', field: '', messages: [response.statusText] }];
      }
      throw new Error(this.generateErrorMessage(response.status, message));
    } else if (!response.ok) {
      const fullResponse = await response.json();
      throw new Error(this.generateErrorMessage(response.status, fullResponse.errors));
    }
    return response.json();
  }

  /**
   * @deprecated Please use submitJsonDataWithError instead
   */
  submitData(path: string, method: string, body: BodyInit) {
    return fetch(this.pathToUrl(path), {
      method,
      headers: { 'X-CSRF-Token': this.token },
      body,
    });
  }

  /**
   * @deprecated Please use deleteDataWithError instead
   */
  deleteData(path: string) {
    return fetch(this.pathToUrl(path), {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json',
      },
    });
  }

  //----------------------------------------------------------------------------
  async getDataWithError<T>(path: string, options: RequestOptions = {}) {
    const response = await this.#getData(path, options);
    return this.handleResponseWithError<T>(response, options.responseType);
  }

  #getData(path: string, options: RequestOptions = {}) {
    return fetch(this.pathToUrl(path, undefined, options), {
      headers: { 'X-CSRF-Token': this.token, ...options.headers },
    });
  }

  async submitJsonDataWithError<T>(path: string, method: string, body: FormData) {
    const response = await this.#submitJsonData(path, method, body);
    return this.handleResponseWithError<T>(response);
  }

  convertToJson(formData: FormData) {
    // convert body from formData to Object and handle nested (using JSON)
    // formData entries correctly.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const plainBody = Object.fromEntries(formData as unknown as any); // Typecast reason: For some reason TS says entries is not on FormData. But it works.

    // Remove this eslint disable at first chance
    // eslint-disable-next-line no-restricted-syntax
    for (const k of Object.keys(plainBody)) {
      // remove any key that has [] array indicator
      if (k.includes('[]')) delete plainBody[`${k}`];

      let valHashOrArray = null;
      const values = formData.getAll(k);

      // Remove this eslint disable at first chance
      // eslint-disable-next-line no-restricted-syntax
      for (const val of values) {
        let newVal = val as unknown; // I have to typescast to unknown here because we are assigning different types of values to newVal

        // handle booleans - a "true" or "false" should be converted to boolean corresponding value
        if (val === 'true' || val === 'false') newVal = val === 'true';

        // handle nulls - a "null" should be converted to null
        if (val === 'null') newVal = null;

        // handle numbers- a number string should be converted to a number only if it is less than MAX_SAFE_INTEGER
        const numberVal = Number(val);
        if (
          val !== '' && // Number("") is 0, which is not what we want
          typeof val === 'string' &&
          Number.MAX_SAFE_INTEGER > numberVal // if it was a number string that is less than MAX_SAFE_INTEGER, convert to number
        ) {
          newVal = numberVal;
        }
        const jsonObj = this.isJson(val);
        if (jsonObj) newVal = jsonObj;

        if (k.includes('[]')) newVal = [newVal];

        // TODO: Below here is old code that doesn't make sense to me, but I won't touch as this affect too much
        if (valHashOrArray === null) {
          valHashOrArray = newVal;
        } else {
          valHashOrArray = [valHashOrArray, newVal].flat().filter(e => e);
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      plainBody[`${k.replace('[]', '')}`] = valHashOrArray as any;
    }
    return plainBody;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  convertJsonToFormData(jsonObject?: Record<string, any>, { convertToSnakeCase } = { convertToSnakeCase: false }) {
    const formData = new FormData();

    if (!jsonObject) return formData;

    Object.entries(jsonObject).forEach(([key, value]) => {
      if (!Object.prototype.hasOwnProperty.call(jsonObject, key)) return;
      const newFieldName = convertToSnakeCase ? snakeCase(key) : key;
      if (Array.isArray(value) || isPlainObject(value)) {
        formData.append(newFieldName, JSON.stringify(value));
      } else {
        formData.append(newFieldName, value);
      }
    });

    return formData;
  }

  #submitJsonData(path: string, method: string, body: FormData) {
    const plainBody = this.convertToJson(body);
    // TODO: (ek) We should eventually have the { excludeSearchParams: true }
    // as the default
    return fetch(this.pathToUrl(path, Object.keys(plainBody)), {
      method,
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(plainBody, (k, v) => {
        if (
          v === null ||
          v === undefined ||
          // I don't understand the logic perfectly but this is a legit check. If changed behaviour will be changed. FYI
          // However, this is illegal in TS so I have to typecast to any.

          // eslint-disable-next-line @typescript-eslint/no-explicit-any, eqeqeq
          v == ({} as any) ||
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          v === ([] as any) ||
          v === 'null' ||
          v === 'undefined'
        ) {
          return undefined;
        }
        return v;
      }),
    });
  }

  async deleteDataWithError<T>(path: string) {
    const response = await this.#deleteData(path);
    return this.handleResponseWithError<T>(response);
  }

  #deleteData(path: string) {
    return fetch(this.pathToUrl(path), {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json',
      },
    });
  }

  async handleResponseWithError<T>(response: Response, responseType: ResponseType = 'json'): Promise<T> {
    if (!response.ok) {
      let responsePayload;
      try {
        responsePayload = await response.json();
      } catch (e) {
        // TODO(ege): 500 Internal Server Error will fall in here because Rails returns a HTML page for those errors.
        // At some point I'd like to handle this better as this might shadow the actual error message return in response.
        console.error('Failed to parse response', e);
        const requestId = response.headers.get('X-Request-Id');
        let errMsg = `${response.status} ${response.statusText}`;
        if (requestId) errMsg += ` [${requestId}]`;
        throw new Error(errMsg);
      }

      // A new version of error response will have message and request_id. If those exist, use generateErrorMessageV2.
      const errMessage = this.generateErrorMessageV2(response, responsePayload);

      throw new Error(errMessage);
    }

    if (responseType === 'blob') {
      return (await response.blob()) as unknown as T;
    }

    return response.json();
  }

  /**
   * @deprecated Please use generateErrorMessageV2 instead
   */
  generateErrorMessage(responseStatus: Response['status'], errors: Error[]) {
    if (errors.length === 0) return `Error: Server returned ${responseStatus}`;
    const resource = errors[0]?.resource;
    const field = errors[0]?.field;
    const message = errors[0]?.messages?.[0];
    return `Error: ${responseStatus} ${resource} ${field || ''} ${message}`;
  }

  generateErrorMessageV2(response: Response, responsePayload: KyronErrorResponsePayload) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const message = responsePayload.message || (responsePayload as any)?.errors?.[0]?.messages?.[0];
    return `${response.status} ${response.statusText}: ${message} (${responsePayload.request_id})`;
  }

  isJson(str: unknown) {
    try {
      const parsed = JSON.parse(str as string);
      // JSON.parse will parse non JSON values, like a number. i.e. JSON.parse("2") === 2
      // And this is not what we are trying to do here. What we wanna do here is to understand if the parsed
      // value is a valid JSON "object" or not. So we need to check if the parsed value is an object or not.
      if (!parsed || typeof parsed !== 'object') {
        return null;
      }
      return parsed;
    } catch {
      return null;
    }
  }

  pathToUrl(path: string, bodyKeys: string[] = [], options: RequestOptions = {}) {
    const url = new URL(path, window.location.origin);

    if (options.excludeSearchParams) {
      return url.href;
    }

    // Include URL params on the current page with all ajax requests.
    // This is the behavior most people want without really thinking about
    // passing URL params explicitly to every single fetch call.
    const params = new URLSearchParams(window.location.search);
    // If a parameter is included in the URL and the body, we want the value
    // in the body to take precedence. For example, if the current page is
    // filtering on tutors and we have `tutor_id` in the URL, if we update a
    // lesson and the body has `tutor_id`, we want to use the value in the
    // body. Unfortunately, Rails prefers the value in the URL. As a work
    // around, we delete any URL params that are also in the body so Rails
    // only sees the body version.

    // eslint-disable-next-line no-restricted-syntax
    for (const key of params.keys()) {
      if (bodyKeys.includes(key)) {
        params.delete(key);
      }
    }

    const extraParams = new URLSearchParams(url.search);

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of extraParams.entries()) {
      params.set(key, value);
    }

    url.search = params.toString();
    return url.href;
  }
}
