import React, { useState } from 'react';
import { AddCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
  TextField,
  Typography,
} from '@mui/material';
import { Organization } from 'controllers/types';
import { createOrganization } from 'controllers/organizations';
import { useSnackbar } from 'notistack';

export function OrganizationSelectNewOrg() {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <ListItemButton
        sx={{
          height: 64,
          px: 2,
          gap: 1,
          borderBottom: 'none',
          justifyContent: 'center',
          color: 'primary.main',
          '&:hover, &.Mui-selected:hover': { bgcolor: 'surface.dark' },
          boxShadow: theme => `inset 0px 1px 0px 0px ${theme.palette.divider}`,
        }}
        onClick={() => setOpenDialog(true)}
        data-testid='create-new-org-button'
      >
        <AddCircleOutline />
        <Typography variant='labelLarge'>Create new organization</Typography>
      </ListItemButton>
      <CreateOrganizationDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </>
  );
}

function CreateOrganizationDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const [organization, setOrganization] = useState<Organization>({
    id: 0,
    name: '',
    slug: '',
    member_count: 0,
    access_control: {},
  });
  const { enqueueSnackbar } = useSnackbar();

  const generateSlug = (name: string) => name.toLowerCase().replace(/[^a-z0-9-]+/g, '-');

  return (
    <Dialog open={open} onClose={onClose} data-testid='create-organization-dialog'>
      <Box
        sx={{
          display: 'flex',
          width: '400px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DialogTitle variant='titleLarge'>Create new organization</DialogTitle>
        <DialogContent sx={{ width: '100%' }}>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Organization name'
            fullWidth
            required
            data-testid='organization-name-input'
            value={organization.name}
            onChange={e =>
              setOrganization({ ...organization, name: e.target.value, slug: generateSlug(e.target.value) })
            }
          />
          <TextField
            margin='dense'
            id='slug'
            label='Organization slug'
            fullWidth
            required
            value={organization.slug}
            onChange={e => setOrganization({ ...organization, slug: e.target.value })}
          />
        </DialogContent>
        <DialogActions sx={{ width: '100%' }}>
          <Button
            onClick={() => {
              // Creating a new organization also makes the current user an
              // admin and sets it as the active organization. Changing a
              // user's active org requires us to re-fetch all data so we
              // reload the whole page here.
              createOrganization(organization)
                .then(() => {
                  enqueueSnackbar(`Created organization ${organization.name} and set as active`, {
                    variant: 'success',
                  });
                  window.location.reload();
                })
                .catch(e => {
                  enqueueSnackbar(`Could not create organization: ${e}`, { variant: 'error' });
                });
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
