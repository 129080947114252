import { PaletteMode, ThemeOptions, createTheme } from '@mui/material';
import { kyronPaletteLight, kyronPaletteDark } from './kyronPalette';
import { kyronComponents } from './kyronComponents';
import { kyronTypography } from './kyronTypography';

export const getTheme = (mode?: PaletteMode) =>
  createTheme({
    components: kyronComponents,
    palette: mode === 'dark' ? kyronPaletteDark : kyronPaletteLight,
    typography: kyronTypography,
    shape: {
      borderRadius: 8,
    },
    shadows: [
      'none',
      '0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34), 0.8px 0.8px 3px 0px rgba(162, 147, 169, 0.34), 1.3px 1.3px 2.1px -2.5px rgba(162, 147, 169, 0.34)',
      '0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34), 1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34), 2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34), 6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34)',
      '0px 155px 43px 0px rgba(0, 0, 0, 0.00), 0px 99px 40px 0px rgba(0, 0, 0, 0.02), 0px 56px 34px 0px rgba(0, 0, 0, 0.07), 0px 25px 25px 0px rgba(0, 0, 0, 0.13), 0px 6px 14px 0px rgba(0, 0, 0, 0.15)',
      ...Array(21).fill('none'),
    ] as ThemeOptions['shadows'],
  });
