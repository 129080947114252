import React, { useState, useRef, CSSProperties } from 'react';
import { Collapse, Button, Typography } from '@mui/material';

import { useSnackbar } from 'notistack';
import { LoaderFunctionArgs, useLoaderData, useSearchParams } from 'react-router-dom';

import { useActions, useTitle } from 'components/StudioLayout/StudioLayout';
import { Misconception } from 'controllers/types';
import { DirectUploader } from '../Uploaders/DirectUploader';
import { AllLessonMisconceptions } from './AllLessonMisconceptions';
import { NewLessonMisconception } from './NewLessonMisconception';
import { sortById } from '../utils/arrayUtils';
import {
  getMisconceptionPage,
  deleteMisconception,
  updateMisconception,
  createMisconception,
  uploadMisconceptionCsv,
} from '../../controllers/misconceptions';

const styles: Record<string, CSSProperties> = {
  misconceptionDiv: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

export async function loader({ request }: LoaderFunctionArgs): Promise<{
  misconceptions: Misconception[];
  totalCount: number;
}> {
  const url = new URL(request.url);
  const page = Number(url.searchParams.get('page')) || 1;
  const perPage = Number(url.searchParams.get('perPage')) || 25;
  const { misconceptions, totalCount } = await getMisconceptionPage(page, perPage);

  return { misconceptions, totalCount };
}

export function MisconceptionEditor() {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const page = useRef(Number(searchParams.get('page')) || 1);
  const perPage = Number(searchParams.get('perPage')) || 25;
  const { misconceptions, totalCount } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const [misconceptionsPage, setMisconceptionsPage] = useState<Misconception[]>(misconceptions);
  const noOfMisconceptions = useRef<number>(totalCount);
  const [newMisconceptionExpanded, setNewMisconceptionExpanded] = useState<boolean>(false);

  const handleNewMisconceptionExpandClick = () => {
    setNewMisconceptionExpanded(!newMisconceptionExpanded);
  };

  const handleDirectUploadCsv = async (signedIds: string[]) => {
    try {
      const { message } = await uploadMisconceptionCsv(Number(signedIds[0]));
      enqueueSnackbar(message);
    } catch (error: unknown) {
      if (error instanceof Error) enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleNewMisconceptionFormSubmit = async (misconception: Misconception) => {
    try {
      const newMisconception = await createMisconception(misconception);
      const allMisconceptions = misconceptionsPage.concat(newMisconception);
      sortById(allMisconceptions);
      setMisconceptionsPage(allMisconceptions);
      enqueueSnackbar('Misconception Created');
    } catch (error: unknown) {
      if (error instanceof Error) enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleMisconceptionDelete = async (id: number) => {
    try {
      await deleteMisconception(id);
      const newMisconceptions = misconceptionsPage.filter(misconception => misconception.id !== id);
      setMisconceptionsPage(newMisconceptions);
      enqueueSnackbar('Misconception Deleted');
    } catch (error: unknown) {
      if (error instanceof Error) enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleMisconceptionUpdate = async (misconception: Misconception) => {
    try {
      const updatedMisconception = await updateMisconception(misconception);
      const newMisconceptions = misconceptionsPage.filter(mis => mis.id !== updatedMisconception.id);
      newMisconceptions.push(updatedMisconception);
      sortById(newMisconceptions);
      setMisconceptionsPage(newMisconceptions);
    } catch (error: unknown) {
      if (error instanceof Error) enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  async function fetchMisconceptionsPage(pg: number, perPg: number) {
    const fetchResp = await getMisconceptionPage(pg, perPg);
    sortById(fetchResp.misconceptions);
    setMisconceptionsPage(fetchResp.misconceptions);
    noOfMisconceptions.current = fetchResp.totalCount;
    page.current = pg;
  }

  useTitle('Misconceptions');
  useActions(<Button onClick={handleNewMisconceptionExpandClick}>New Misconception</Button>);

  return (
    <div>
      <div style={{ margin: '10px 0px' }}>
        <div style={styles.misconceptionDiv}>
          <div>
            <Typography>Upload CSV File:</Typography>
            <DirectUploader afterUpload={handleDirectUploadCsv} multiple={false} acceptTypes='text/csv' />
          </div>
        </div>
        <div style={styles.misconceptionDiv}>
          <Collapse in={newMisconceptionExpanded} style={{ width: '50%' }} timeout='auto' unmountOnExit>
            <NewLessonMisconception
              handleFormSubmit={handleNewMisconceptionFormSubmit}
              handleFormCancel={handleNewMisconceptionExpandClick}
            />
          </Collapse>
        </div>
      </div>
      <AllLessonMisconceptions
        misconceptions={misconceptionsPage}
        noOfMisconceptions={noOfMisconceptions.current}
        page={page.current}
        perPage={perPage}
        getMisconceptionsPage={fetchMisconceptionsPage}
        handleMisconceptionDelete={handleMisconceptionDelete}
        handleMisconceptionUpdate={handleMisconceptionUpdate}
      />
    </div>
  );
}
