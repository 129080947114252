// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LessonEdit renders lesson editor 1`] = `
<DocumentFragment>
  .emotion-0 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
}

.emotion-1 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  border-radius: 20px;
  margin: 10px 0px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 16px;
}

.emotion-4 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #FFFFFF;
  background-color: #bdbdbd;
  color: #7DB8DD;
  background-color: #235E83;
}

.emotion-5 {
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.emotion-6 {
  margin: 0;
  display: block;
}

.emotion-7 {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: flex-start;
  align-self: flex-start;
  margin-top: -4px;
  margin-right: -8px;
  margin-bottom: -4px;
}

.emotion-8 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-8::-moz-focus-inner {
  border-style: none;
}

.emotion-8.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-8 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-8:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-8:hover {
    background-color: transparent;
  }
}

.emotion-8.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-9 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-10 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-23 {
  display: block;
  -webkit-background-size: cover;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-background-position: center;
  background-position: center;
  width: 100%;
  object-fit: cover;
  padding-left: 2vh;
  max-height: 12vh;
  width: auto;
}

.emotion-24 {
  padding: 16px;
}

.emotion-24:last-child {
  padding-bottom: 24px;
}

.emotion-25 {
  margin: 0;
}

.emotion-26 {
  margin: 0;
  color: #1C1B1FB3;
}

.emotion-27 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
}

.emotion-27>:not(style)~:not(style) {
  margin-left: 8px;
}

.emotion-28 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-28::-moz-focus-inner {
  border-style: none;
}

.emotion-28.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-28 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-28:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-28:hover {
    background-color: #4B35FF;
  }
}

.emotion-28:active {
  box-shadow: none;
}

.emotion-28.Mui-focusVisible {
  box-shadow: none;
}

.emotion-28.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-28:hover {
  box-shadow: none;
}

.emotion-28.Mui-focusVisible {
  box-shadow: none;
}

.emotion-28:active {
  box-shadow: none;
}

.emotion-28.Mui-disabled {
  box-shadow: none;
}

.emotion-29 {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.emotion-29>*:nth-of-type(1) {
  font-size: 20px;
}

.emotion-33 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-33>:not(style):not(style) {
  margin: 0;
}

.emotion-33>:not(style)~:not(style) {
  margin-left: 16px;
}

.emotion-34 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
  width: 100%;
  width: 400px;
  font-size: 14px;
  line-height: 20px;
  width: 400px;
}

.emotion-34 .MuiOutlinedInput-root {
  border-radius: 30px;
  padding: 0 8px 0 12px;
  height: 40px;
}

.emotion-34 .MuiInputLabel-root {
  line-height: 1.3em;
}

.emotion-34 .MuiOutlinedInput-input:-webkit-autofill {
  padding-left: 1em;
  height: 0.3em;
}

.emotion-34 .MuiFormHelperText-root {
  font-size: 11px;
  margin-top: 0;
}

.emotion-35 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding-left: 14px;
  padding-right: 14px;
}

.emotion-35.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-35:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-35:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-35.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-35.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-35.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-36 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0.01em;
  max-height: 2em;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
  margin-right: 8px;
  position: relative;
  height: 100%;
}

.emotion-38 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-left: 0;
  padding-right: 0;
}

.emotion-38::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-38::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-38:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-38::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-38:focus {
  outline: 0;
}

.emotion-38:invalid {
  box-shadow: none;
}

.emotion-38::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-38::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-38::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-38:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-38::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-38:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-38:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-38:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-38:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-38.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-38:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-38:-webkit-autofill {
  border-radius: inherit;
}

.emotion-39 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding: 5px;
  font-size: 1.125rem;
  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;
}

.emotion-39::-moz-focus-inner {
  border-style: none;
}

.emotion-39.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-39 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-39:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-39:hover {
    background-color: transparent;
  }
}

.emotion-39.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-40 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-42 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-43 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-44 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  position: relative;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow-anchor: none;
  border-radius: 0;
}

.emotion-44::before {
  position: absolute;
  left: 0;
  top: -1px;
  right: 0;
  height: 1px;
  content: "";
  opacity: 1;
  background-color: #D6D5DD;
  -webkit-transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-44:first-of-type::before {
  display: none;
}

.emotion-44.Mui-expanded::before {
  opacity: 0;
}

.emotion-44.Mui-expanded:first-of-type {
  margin-top: 0;
}

.emotion-44.Mui-expanded:last-of-type {
  margin-bottom: 0;
}

.emotion-44.Mui-expanded+.emotion-44.Mui-expanded::before {
  display: none;
}

.emotion-44.Mui-disabled {
  background-color: #1C1B1F1F;
}

.emotion-44:first-of-type {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.emotion-44:last-of-type {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@supports (-ms-ime-align: auto) {
  .emotion-44:last-of-type {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.emotion-44.Mui-expanded {
  margin: 16px 0;
}

.emotion-45 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 48px;
  padding: 0px 16px;
  -webkit-transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-45::-moz-focus-inner {
  border-style: none;
}

.emotion-45.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-45 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-45.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-45.Mui-disabled {
  opacity: 0.38;
}

.emotion-45:hover:not(.Mui-disabled) {
  cursor: pointer;
}

.emotion-45.Mui-expanded {
  min-height: 64px;
}

.emotion-46 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin: 12px 0;
  -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-46.Mui-expanded {
  margin: 20px 0;
}

.emotion-48 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-48.Mui-expanded {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.emotion-50 {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  visibility: hidden;
}

.emotion-51 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.emotion-52 {
  width: 100%;
}

.emotion-53 {
  padding: 8px 16px 16px;
}

.emotion-75 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.emotion-76 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  min-width: 32px;
  height: 32px;
  padding: 0 6px;
  margin: 0 3px;
  color: #1C1B1F;
  -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-76::-moz-focus-inner {
  border-style: none;
}

.emotion-76.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-76 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-76.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-76.Mui-disabled {
  opacity: 0.38;
}

.emotion-76:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-76:hover {
    background-color: transparent;
  }
}

.emotion-76.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-76.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

@media (hover: none) {
  .emotion-76.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.emotion-76.Mui-selected.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.2);
}

.emotion-76.Mui-selected.Mui-disabled {
  opacity: 1;
  color: #1C1B1F61;
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-76.Mui-selected.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-77 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 1.25rem;
  margin: 0 -8px;
}

<div
    data-testid="lesson-edit"
  >
    <div
      class="MuiStack-root emotion-0"
    />
    <div
      class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-1"
    >
      <div
        class="MuiCardHeader-root emotion-2"
      >
        <div
          class="MuiCardHeader-avatar emotion-3"
        >
          <div
            aria-label="lesson teacher"
            class="MuiAvatar-root MuiAvatar-circular MuiAvatar-colorDefault emotion-4"
          >
            FB
          </div>
        </div>
        <div
          class="MuiCardHeader-content emotion-5"
        >
          <span
            class="MuiTypography-root MuiTypography-body2 MuiCardHeader-title emotion-6"
          >
            Algebra-02 [undefined]
          </span>
        </div>
        <div
          class="MuiCardHeader-action emotion-7"
        >
          <span
            aria-label="Preview unpublished lesson"
            class=""
            data-mui-internal-clone-element="true"
          >
            <a
              aria-label="preview lesson that is about to get published"
              class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-8"
              data-testid="start-lesson"
              href="/video_player/123?restart=false&force_separate=false"
              tabindex="0"
            >
              <svg
                aria-hidden="true"
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                data-testid="PreviewIcon"
                focusable="false"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.89-2-2-2m0 16H5V7h14zm-5.5-6c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5M12 9c-2.73 0-5.06 1.66-6 4 .94 2.34 3.27 4 6 4s5.06-1.66 6-4c-.94-2.34-3.27-4-6-4m0 6.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5"
                />
              </svg>
              <span
                class="MuiTouchRipple-root emotion-10"
              />
            </a>
          </span>
          <button
            aria-label="edit lesson"
            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-8"
            data-mui-internal-clone-element="true"
            data-testid="edit-lesson"
            tabindex="0"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
              data-testid="EditIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 0 0-1.41 0l-1.83 1.83 3.75 3.75z"
              />
            </svg>
            <span
              class="MuiTouchRipple-root emotion-10"
            />
          </button>
          <button
            aria-label="validate lesson"
            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-8"
            data-mui-internal-clone-element="true"
            data-testid="validate-lesson"
            tabindex="0"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
              data-testid="FactCheckIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M20 3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2M10 17H5v-2h5zm0-4H5v-2h5zm0-4H5V7h5zm4.82 6L12 12.16l1.41-1.41 1.41 1.42L17.99 9l1.42 1.42z"
                fill-rule="evenodd"
              />
            </svg>
            <span
              class="MuiTouchRipple-root emotion-10"
            />
          </button>
          <button
            aria-label="publish lesson"
            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-8"
            data-mui-internal-clone-element="true"
            data-testid="publish-lesson"
            tabindex="0"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
              data-testid="PublishIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M5 4v2h14V4zm0 10h4v6h6v-6h4l-7-7z"
              />
            </svg>
            <span
              class="MuiTouchRipple-root emotion-10"
            />
          </button>
          <button
            aria-haspopup="menu"
            aria-label="select version of lesson to play"
            class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-8"
            data-testid="toggle-menu"
            tabindex="0"
            type="button"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
              data-testid="MoreVertIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"
              />
            </svg>
            <span
              class="MuiTouchRipple-root emotion-10"
            />
          </button>
        </div>
      </div>
      <img
        alt="lesson thumbnail"
        class="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img emotion-23"
      />
      <div
        class="MuiCardContent-root emotion-24"
      >
        <p
          class="MuiTypography-root MuiTypography-body2 emotion-25"
        >
          [] 
        </p>
        <p
          class="MuiTypography-root MuiTypography-body2 emotion-26"
        >
          This is a great lesson in Algebra-02
        </p>
      </div>
      <div
        class="MuiCardActions-root MuiCardActions-spacing emotion-27"
      >
        <button
          class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-28"
          tabindex="0"
          type="button"
        >
          <span
            class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeMedium emotion-29"
          >
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
              data-testid="AddIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"
              />
            </svg>
          </span>
          Add Segment
          <span
            class="MuiTouchRipple-root emotion-10"
          />
        </button>
      </div>
      <div
        class="MuiCardContent-root emotion-24"
      >
        <div
          class="MuiStack-root emotion-33"
        >
          <div
            class="MuiFormControl-root MuiFormControl-fullWidth MuiTextField-root emotion-34"
          >
            <div
              class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth Mui-focused MuiInputBase-formControl MuiInputBase-adornedStart MuiInputBase-adornedEnd emotion-35"
            >
              <div
                class="MuiInputAdornment-root MuiInputAdornment-positionStart MuiInputAdornment-outlined MuiInputAdornment-sizeMedium emotion-36"
              >
                <span
                  class="notranslate"
                >
                  ​
                </span>
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                  data-testid="SearchIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14"
                  />
                </svg>
              </div>
              <input
                aria-invalid="false"
                class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedStart MuiInputBase-inputAdornedEnd emotion-38"
                id=":r6:"
                placeholder="Search by segment name"
                type="text"
                value=""
              />
              <button
                aria-label="Clear"
                class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeSmall emotion-39"
                tabindex="-1"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-40"
                  data-testid="ClearIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  />
                </svg>
                <span
                  class="MuiTouchRipple-root emotion-10"
                />
              </button>
              <fieldset
                aria-hidden="true"
                class="MuiOutlinedInput-notchedOutline emotion-42"
              >
                <legend
                  class="emotion-43"
                >
                  <span
                    class="notranslate"
                  >
                    ​
                  </span>
                </legend>
              </fieldset>
            </div>
          </div>
        </div>
        <div>
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters emotion-44"
          >
            <div
              aria-controls="first-segment"
              aria-expanded="false"
              class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters emotion-45"
              id="1"
              role="button"
              tabindex="0"
            >
              <div
                class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters emotion-46"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-25"
                >
                  first-segment
                </p>
              </div>
              <div
                class="MuiAccordionSummary-expandIconWrapper emotion-48"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                  data-testid="ExpandMoreIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"
                  />
                </svg>
              </div>
            </div>
            <div
              class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden emotion-50"
              style="min-height: 0px;"
            >
              <div
                class="MuiCollapse-wrapper MuiCollapse-vertical emotion-51"
              >
                <div
                  class="MuiCollapse-wrapperInner MuiCollapse-vertical emotion-52"
                >
                  <div
                    aria-labelledby="1"
                    class="MuiAccordion-region"
                    id="first-segment"
                    role="region"
                  >
                    <div
                      class="MuiAccordionDetails-root emotion-53"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters emotion-44"
          >
            <div
              aria-controls="second-segment-question"
              aria-expanded="false"
              class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters emotion-45"
              id="2"
              role="button"
              tabindex="0"
            >
              <div
                class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters emotion-46"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-25"
                >
                  second-segment-question
                </p>
              </div>
              <div
                class="MuiAccordionSummary-expandIconWrapper emotion-48"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                  data-testid="ExpandMoreIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"
                  />
                </svg>
              </div>
            </div>
            <div
              class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden emotion-50"
              style="min-height: 0px;"
            >
              <div
                class="MuiCollapse-wrapper MuiCollapse-vertical emotion-51"
              >
                <div
                  class="MuiCollapse-wrapperInner MuiCollapse-vertical emotion-52"
                >
                  <div
                    aria-labelledby="2"
                    class="MuiAccordion-region"
                    id="second-segment-question"
                    role="region"
                  >
                    <div
                      class="MuiAccordionDetails-root emotion-53"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiAccordion-root MuiAccordion-rounded MuiAccordion-gutters emotion-44"
          >
            <div
              aria-controls="second-segment-wait"
              aria-expanded="false"
              class="MuiButtonBase-root MuiAccordionSummary-root MuiAccordionSummary-gutters emotion-45"
              id="3"
              role="button"
              tabindex="0"
            >
              <div
                class="MuiAccordionSummary-content MuiAccordionSummary-contentGutters emotion-46"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-25"
                >
                  second-segment-wait
                </p>
              </div>
              <div
                class="MuiAccordionSummary-expandIconWrapper emotion-48"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-9"
                  data-testid="ExpandMoreIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z"
                  />
                </svg>
              </div>
            </div>
            <div
              class="MuiCollapse-root MuiCollapse-vertical MuiCollapse-hidden emotion-50"
              style="min-height: 0px;"
            >
              <div
                class="MuiCollapse-wrapper MuiCollapse-vertical emotion-51"
              >
                <div
                  class="MuiCollapse-wrapperInner MuiCollapse-vertical emotion-52"
                >
                  <div
                    aria-labelledby="3"
                    class="MuiAccordion-region"
                    id="second-segment-wait"
                    role="region"
                  >
                    <div
                      class="MuiAccordionDetails-root emotion-53"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav
          aria-label="pagination navigation"
          class="MuiPagination-root MuiPagination-text emotion-74"
          style="display: flex; justify-content: center; align-items: center; margin-top: 25px;"
        >
          <ul
            class="MuiPagination-ul emotion-75"
          >
            <li>
              <button
                aria-label="Go to previous page"
                class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-76"
                disabled=""
                tabindex="-1"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-77"
                  data-testid="NavigateBeforeIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
            </li>
            <li>
              <button
                aria-current="true"
                aria-label="page 1"
                class="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-selected MuiPaginationItem-page emotion-76"
                tabindex="0"
                type="button"
              >
                1
                <span
                  class="MuiTouchRipple-root emotion-10"
                />
              </button>
            </li>
            <li>
              <button
                aria-label="Go to next page"
                class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-76"
                disabled=""
                tabindex="-1"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-77"
                  data-testid="NavigateNextIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
