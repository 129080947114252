import React, { useState, FormEvent } from 'react';

import { Card, CardHeader, CardContent, CardActions, Typography, IconButton } from '@mui/material';

import { Edit, Done, Cancel } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { Role, User } from 'controllers/types';
import { RolesFormControl } from './RolesFormControl';

const styles = {
  Card: {
    flexGrow: 1,
  },
};

export function UserCard({
  user,
  allRoles,
  handleFormSubmit,
  startEditing = false,
}: {
  user: User;
  allRoles: Role[];
  startEditing?: boolean;
  handleFormSubmit: (usr: User) => Promise<void>;
}) {
  const [editedUser, setEditedUser] = useState(user);
  const [editing, setEditing] = useState(startEditing);
  const { enqueueSnackbar } = useSnackbar();

  const handleFormCancel = () => {
    setEditing(false);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    handleFormSubmit(editedUser)
      .then(handleFormCancel)
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  return (
    <Card style={styles.Card}>
      {editing && user ? (
        <>
          <CardHeader
            action={
              <IconButton aria-label='cancel edit user' onClick={handleFormCancel}>
                <Cancel />
              </IconButton>
            }
            title={user.name}
          />
          <CardContent>
            <Typography data-testid='user-email' color='text.secondary'>
              {editedUser.email}
            </Typography>
            <form data-testid='user-form' onSubmit={handleSubmit}>
              <RolesFormControl
                allRoles={allRoles}
                roles={editedUser.roles}
                setRoles={roles => setEditedUser({ ...editedUser, roles })}
              />
              <CardActions>
                <IconButton aria-label='submit user' type='submit' data-testid='submit-button'>
                  <Done />
                </IconButton>
                <IconButton aria-label='cancel edit user' onClick={handleFormCancel}>
                  <Cancel />
                </IconButton>
              </CardActions>
            </form>
          </CardContent>
        </>
      ) : null}
      {!editing && user ? (
        <>
          <CardHeader
            action={
              <IconButton aria-label='edit user' onClick={() => setEditing(true)}>
                <Edit />
              </IconButton>
            }
            title={user.name}
          />
          <CardContent>
            <Typography data-testid='user-email' color='text.secondary'>
              {user.email}
            </Typography>
            <Typography data-testid='user-roles' color='text.secondary'>
              Roles: {user.roles?.map(r => r.name)?.join(', ')}
            </Typography>
          </CardContent>
        </>
      ) : null}
    </Card>
  );
}
