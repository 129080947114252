import React, { useState, FormEvent } from 'react';

import { Card, CardHeader, CardContent, CardActions, IconButton, TextField } from '@mui/material';

import { Done, Cancel } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { Role } from 'controllers/types';
import { RolesFormControl } from './RolesFormControl';

const styles = {
  Card: {
    flexGrow: 1,
  },
};

// This is a separate component from `UserCard` because adding users to an
// organization relies on their email address but updating existing users
// relies on their ID or their whole User record.
export function AddUserCard({
  allRoles,
  handleFormSubmit,
  handleFormCancel,
}: {
  allRoles: Role[];
  handleFormSubmit: (email: string, roles: Role[]) => Promise<void>;
  handleFormCancel: () => void;
}) {
  const [email, setEmail] = useState('');
  const [roles, setRoles] = useState<Role[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    handleFormSubmit(email, roles).catch(error => {
      enqueueSnackbar(error.message, { variant: 'error' });
    });
  };

  return (
    <Card style={styles.Card}>
      <CardHeader
        action={
          <IconButton aria-label='cancel add user' onClick={handleFormCancel}>
            <Cancel />
          </IconButton>
        }
        title='Add a user'
      />
      <CardContent>
        <form data-testid='user-form' onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            name='email'
            fullWidth
            margin='normal'
            label='User email'
            type='text'
            onChange={e => setEmail(e.target.value)}
            required
            helperText='Enter the email of the user to add'
            data-testid='user-email-input'
          />
          <RolesFormControl allRoles={allRoles} roles={roles} setRoles={setRoles} />
          <CardActions>
            <IconButton aria-label='submit user' type='submit' data-testid='submit-button'>
              <Done />
            </IconButton>
            <IconButton aria-label='cancel edit user' onClick={handleFormCancel}>
              <Cancel />
            </IconButton>
          </CardActions>
        </form>
      </CardContent>
    </Card>
  );
}
