import React from 'react';
import { Stack, Link, TableCell, Typography, Box, TableRow } from '@mui/material';
import KyronIcon from 'assets/kyronicon-small.svg';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { ErrorIndicator } from 'components/ErrorIndicator';
import { getLessonCollectionURL } from 'components/utils/urlUtils';
import { LessonCollection } from 'controllers/types';
import { ShareDialog } from 'components/ShareDialog';
import { ClassroomSelector } from 'components/CatalogLessonCard/ClassroomSelector';
import { ReauthenticateDialog } from 'components/ReauthenticateDialog';
import { KyronImage } from 'components/KyronImage/KyronImage';
import { isEmpty } from 'lodash';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { Row } from '../../../Row/Row';
import { useLessonBTWatcher } from '../../../BackgroundTasks/backgroundTaskWatchers';

type LibraryListItemProps = {
  lessonCollection: LessonCollection;
  variant?: 'card' | 'row';
};
export function LibraryListItem({ lessonCollection, variant = 'row' }: LibraryListItemProps) {
  const draftLesson = lessonCollection.draft_lessons?.[0];
  const publishedLesson = lessonCollection.lessons?.find(lesson => lesson.locale === 'en');
  const hasPublishedLesson = !isEmpty(lessonCollection.lessons);
  const publishedLessonLink = hasPublishedLesson ? getLessonCollectionURL(lessonCollection.id) : '';

  const { failed, ...btStatus } = useLessonBTWatcher(publishedLesson?.id, { additionalLessonIds: [draftLesson?.id] });

  const [openShareDialog, setOpenShareDialog] = React.useState(false);
  const [openAssignDialog, setOpenAssignDialog] = React.useState(false);
  const [showReauthenticateDialog, setShowReauthenticateDialog] = React.useState(false);

  const editPath = draftLesson?.use_df
    ? `/studio/internal_lesson_editor/${draftLesson.id}/edit`
    : `/studio/courses/${draftLesson?.id}`;

  const shareDialog = (
    <ShareDialog
      open={openShareDialog}
      onClose={() => setOpenShareDialog(false)}
      quote={`Check out this lesson I created on Kyron Learning, ${lessonCollection.name}!`}
      emailMessage={`I made a lesson on Kyron Learning called ${lessonCollection.name}, and I wanted to share it with you. Click the link to check it out!`}
      link={publishedLessonLink}
      eventResource={{ lesson_collection_id: lessonCollection.id, lesson_name: lessonCollection.name }}
    />
  );

  const assignDialog = hasPublishedLesson && (
    <>
      <ClassroomSelector
        open={openAssignDialog}
        close={() => setOpenAssignDialog(false)}
        lessonCollection={lessonCollection}
        reauthenticate={() => {
          setShowReauthenticateDialog(true);
          setOpenAssignDialog(false);
        }}
      />
      <ReauthenticateDialog open={showReauthenticateDialog} onClose={() => setShowReauthenticateDialog(false)} />
    </>
  );

  const getStatusIcon = () => {
    if (btStatus.isAnyFailed) {
      const message = failed.all.map(task => task.message).join('\n -');
      const isOnlyTranslationFailure = failed.all.length === 1 && failed.lessonTranslation?.length > 0;
      return <ErrorIndicator error={new Error(message)} severity={isOnlyTranslationFailure ? 'warning' : 'error'} />;
    }
    return <LoadingIndicator loading={btStatus.isAnyInProgress} />;
  };

  const updatedAt = dayjs(draftLesson?.updated_at).fromNow();

  return (
    <>
      {variant === 'card' ? (
        <ListItemCard
          lessonCollection={lessonCollection}
          updatedAt={updatedAt}
          editPath={editPath}
          statusIcon={getStatusIcon()}
        />
      ) : (
        <ListItemRow
          lessonCollection={lessonCollection}
          updatedAt={updatedAt}
          editPath={editPath}
          statusIcon={getStatusIcon()}
          hasError={btStatus.isAnyFailed}
        />
      )}

      {shareDialog}

      {assignDialog}
    </>
  );
}

type ListItemCardProps = {
  lessonCollection: LessonCollection;
  updatedAt: string;
  editPath: string;
  statusIcon: React.ReactNode;
};

function ListItemCard({ lessonCollection, updatedAt, editPath, statusIcon }: ListItemCardProps) {
  return (
    <Stack>
      <Box sx={{ position: 'relative' }}>
        <RouterLink to={editPath} style={{ textDecoration: 'none' }}>
          <KyronImage
            stretch
            src={lessonCollection.collection_thumbnail}
            fallbackSrc={KyronIcon}
            alt='lesson collection thumbnail'
            dataTestID='lesson-thumbnail'
          />
        </RouterLink>
      </Box>
      <Row spacing={2} alignItems='center' justifyContent='space-between'>
        <Stack flex={1}>
          <Typography
            variant='titleSmall'
            component={RouterLink}
            to={editPath}
            sx={{ textDecoration: 'none', color: 'inherit' }}
          >
            {lessonCollection.name}
          </Typography>
          <Typography variant='bodySmall'>Updated {updatedAt}</Typography>
        </Stack>
        <Row spacing={1}>{statusIcon}</Row>
      </Row>
    </Stack>
  );
}

type ListItemRowProps = {
  lessonCollection: LessonCollection;
  updatedAt: string;
  editPath: string;
  statusIcon: React.ReactNode;
  hasError: boolean;
};

function ListItemRow({ lessonCollection, updatedAt, editPath, statusIcon, hasError }: ListItemRowProps) {
  return (
    <TableRow>
      <TableCell width={56}>
        <Box sx={{ maxWidth: 56 }}>{statusIcon}</Box>
      </TableCell>

      <TableCell sx={{ maxWidth: '240px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        <Link
          component={RouterLink}
          to={editPath}
          sx={{ color: `${hasError ? 'error.main' : 'primary.main'}` }}
          data-testid='lesson-edit-link'
        >
          {lessonCollection.name}
        </Link>
      </TableCell>

      <TableCell width={140} sx={{ maxWidth: '140px' }}>
        {updatedAt}
      </TableCell>
    </TableRow>
  );
}
