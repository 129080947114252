import { useEffect, useState } from 'react';
import consumer from 'channels/consumer';
import { BackgroundTask } from 'controllers/types';

enum ChannelName {
  BackgroundTaskChannel = 'BackgroundTaskChannel',
  UnauthenticatedBtChannel = 'UnauthenticatedBtChannel',
}

type UseChannelOptions = {
  taskListener?: (task?: BackgroundTask) => void;
};

type ChannelHookArgs = [taskId?: number, options?: UseChannelOptions];

// Use this channel to watch task process individually.
// Caution: This is going to create a new channel subscription. Use UserNotifications.backgroundTasks if you can.
export function useBackgroundTaskChannel(...args: ChannelHookArgs) {
  return useChannel(ChannelName.BackgroundTaskChannel, ...args);
}

// If client won't be authenticated (i.e. no logged-in user in UI), use this channel to watch task process
// Currently, this will only subscribe if the task is a task_entity_type: LessonInstance. If you want to change this,
// see BackgroundTask::ANONYMOUS_BROADCAST_ALLOWED_BT_ENTITIES
export function useUnauthenticatedBTChannel(...args: ChannelHookArgs) {
  return useChannel(ChannelName.UnauthenticatedBtChannel, ...args);
}

// Base hook to create channel subscriptions
export function useChannel(channelName: ChannelName, ...args: ChannelHookArgs) {
  const [taskId, { taskListener } = {}] = args;
  const [taskData, setTaskData] = useState<BackgroundTask | undefined | null>();

  useEffect(() => {
    // background_tasks with these statuses are monitored through a websocket
    if (taskId) {
      const channel = consumer.subscriptions.create(
        {
          channel: channelName,
          task_id: taskId,
        },
        {
          received(data: BackgroundTask) {
            setTaskData(data);
            taskListener?.(data); // when there is new data, let listener know
          },
        },
      );
      return () => {
        channel.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, taskData]);

  return taskData;
}
