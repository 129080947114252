// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`DurationBox should render the DurationBox component 1`] = `
<DocumentFragment>
  .emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  border-radius: 8px;
  bottom: 8px;
  right: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #1C1B1FB3;
  position: absolute;
}

.emotion-1 {
  margin: 0;
  font-family: Montserrat;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  color: #FFFFFF;
}

<div
    class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 emotion-0"
    data-testid="duration-box"
  >
    <span
      class="MuiTypography-root MuiTypography-labelSmall emotion-1"
    >
      5 min
    </span>
  </div>
</DocumentFragment>
`;
