import React from 'react';
import { useParams } from 'react-router-dom';
import { useClassroomQuery } from 'controllers/react-query/classroomHooks';
import { useCreateGoogleAssignment, useCreateKyronAssignment } from 'controllers/react-query/assignmentHooks';
import { getLessonCollectionURL } from 'components/utils/urlUtils';
import { LessonCollection } from 'controllers/types';
import { useSnackbar } from 'notistack';
import { LessonAssignmentListBase } from './LessonAssignmentListBase';

type Props = {
  closeDrawer: () => void;
  setShowReauthenticateDialog: (show: boolean) => void;
};

export const LessonAssignmentList = ({ closeDrawer, setShowReauthenticateDialog }: Props) => {
  const { classroomId } = useParams();
  const { data: classroomData } = useClassroomQuery(classroomId);
  const isGoogleClassroom = classroomData?.external_classroom_provider === 'google_classroom';
  const classroomIdInt = classroomId ? parseInt(classroomId, 10) : 0;
  const { mutate: assignToGoogleClassroom, isPending } = useCreateGoogleAssignment();
  const { mutate: assignToKyronClassroom, isPending: isPendingKyron } = useCreateKyronAssignment(classroomIdInt);
  const { enqueueSnackbar } = useSnackbar();

  const assignToClassroom = (lessonCollection: LessonCollection) => {
    if (!classroomId) {
      enqueueSnackbar('Error assigning to classroom', { variant: 'error' });
      return;
    }
    if (isGoogleClassroom) {
      // TODO: Handle draft assignments & ability to reauthenticate if token is expired
      assignToGoogleClassroom(
        {
          payload: {
            classroomId: parseInt(classroomId, 10),
            externalClassroomId: classroomData?.external_classroom_id,
            lessonId: lessonCollection.id,
            draft: false,
          },
        },
        {
          onSuccess: () => {
            closeDrawer();
            enqueueSnackbar('Course assigned successfully', { variant: 'success' });
          },
          onError: () => {
            closeDrawer();
            setShowReauthenticateDialog(true);
            enqueueSnackbar(`Failed to assign course.`, { variant: 'error' });
          },
        },
      );
      return;
    }
    if (classroomData?.external_classroom_provider === 'kyron_learning') {
      assignToKyronClassroom(
        {
          classroomId: parseInt(classroomId, 10),
          lessonId: lessonCollection.id,
        },
        {
          onSuccess: () => {
            closeDrawer();
            const url = getLessonCollectionURL(lessonCollection.id, classroomData);
            navigator.clipboard.writeText(url);
            enqueueSnackbar('Course assigned successfully and the link has been copied to your clipboard', {
              variant: 'success',
            });
          },
          onError: () => {
            closeDrawer();
            enqueueSnackbar(`Failed to assign course.`, { variant: 'error' });
          },
        },
      );
    }
  };

  return (
    <LessonAssignmentListBase
      closeDrawer={closeDrawer}
      assignLesson={assignToClassroom}
      isPending={isPending || isPendingKyron}
      title='Assign a Course'
      toolTipText='Assign to Classroom'
    />
  );
};
