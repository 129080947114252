import { AppBar } from 'components/AppBar/AppBar';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { HelpButton } from 'components/HelpButton/HelpButton';
import { GlobalStyles } from '@mui/material';
import { BackButton } from 'components/BackButton/BackButton';

export function CourseCreationLayout() {
  return (
    <>
      <GlobalStyles styles={theme => ({ body: { backgroundColor: theme.palette.background.paper } })} />
      <>
        <AppBar showLogo={false} navButton={<BackButton />} mainToolBarSx={{ bgcolor: 'background.paper' }} />
        <Outlet />
      </>
      <HelpButton />
    </>
  );
}
