import * as React from 'react';
import { List, ListItem, Stack, Typography } from '@mui/material';
import plane8 from '../../assets/plane-8-color.svg';
import plane10 from '../../assets/plane-10-color.svg';
import plane12 from '../../assets/plane-12-color.svg';

export function Home() {
  const styles = {
    instruction: {
      display: 'list-item',
      margin: '48px 0',
      maxWidth: '780px',
    },
  };

  return (
    <>
      <Typography variant='displayMedium' component='h1'>
        Let&apos;s start learning!
      </Typography>
      <List component='ol' data-testid='instructions'>
        <ListItem sx={styles.instruction}>
          <Stack direction='row' justifyContent='space-between'>
            <Stack gap={1} maxWidth={480}>
              <Typography variant='titleLarge'>1. Choose one of the units on the left.</Typography>
              <Typography variant='bodyLarge'>
                What do you want to learn about today? We&apos;ve got lots of units covering many different areas of
                math right now. But we&apos;re always adding more, so come back often.
              </Typography>
            </Stack>
            <img src={plane10} alt='' width='260' />
          </Stack>
        </ListItem>
        <ListItem sx={styles.instruction}>
          <Stack direction='row' flexDirection='row-reverse' justifyContent='space-between'>
            <Stack gap={1} maxWidth={480}>
              <Typography variant='titleLarge'>2. Select a specific lesson.</Typography>
              <Typography variant='bodyLarge'>
                Inside each unit there are several lessons, each covering a specific area. Not sure where to start?
                Start at the beginning! You&apos;ll even get to choose which teacher you want to learn with.
              </Typography>
            </Stack>
            <img src={plane12} alt='' width='210' />
          </Stack>
        </ListItem>
        <ListItem sx={styles.instruction}>
          <Stack direction='row' justifyContent='space-between'>
            <Stack gap={1} maxWidth={480}>
              <Typography variant='titleLarge'>3. Interact with your teacher out loud.</Typography>
              <Typography variant='bodyLarge'>
                During your lesson, the teacher in the video will ask you questions. Say your answer out loud. Your
                teacher will understand!
              </Typography>
            </Stack>
            <img src={plane8} alt='' width='260' />
          </Stack>
        </ListItem>
      </List>
    </>
  );
}
