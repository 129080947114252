import React, { useState, useRef } from 'react';
import { ArrowDropDown, Check } from '@mui/icons-material';
import { Menu, MenuItem, MenuList, ButtonGroup, Button, ListItemIcon, ListItemText } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

type SplitButtonProps = {
  options: {
    label: string;
    onSelect: (index: number) => void;
  }[];
  disabled?: boolean;
  submitOnClick?: boolean;
};

export function SplitButton({ options, disabled, submitOnClick }: SplitButtonProps) {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    options[selectedIndex].onSelect(selectedIndex);
  };

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
    if (submitOnClick) {
      options[index].onSelect(index);
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function isSelected(index: number) {
    return index === selectedIndex;
  }

  return (
    <>
      <ButtonGroup aria-label='Button group with a nested menu' disabled={disabled} ref={dropdownRef}>
        <Button onClick={handleClick}>{options[selectedIndex].label}</Button>
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select sub option'
          aria-haspopup='menu'
          onClick={handleToggle}
          sx={{ padding: 0 }}
        >
          <ArrowDropDown fontSize='small' />
        </Button>
      </ButtonGroup>
      <Menu
        id='split-button-menu'
        anchorEl={dropdownRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
      >
        <MenuList id='split-button-menu' autoFocusItem dense>
          {options.map(({ label }, index) => (
            <MenuItem key={label} selected={isSelected(index)} onClick={() => handleMenuItemClick(index)}>
              {!submitOnClick && (
                <ListItemIcon>
                  <Check fontSize='small' sx={isSelected(index) ? null : visuallyHidden} />
                </ListItemIcon>
              )}
              <ListItemText>{label}</ListItemText>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </>
  );
}
