// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AllLessonMisconceptions Component AllLessonMisconceptions display correctly render all misconceptions 1`] = `
<DocumentFragment>
  .emotion-0 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
}

.emotion-0>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-0>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-1 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-1 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-1 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1200px) {
  .emotion-1 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:1536px) {
  .emotion-1 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

.emotion-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.emotion-6 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  min-width: 32px;
  height: 32px;
  padding: 0 6px;
  margin: 0 3px;
  color: #1C1B1F;
  -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6::-moz-focus-inner {
  border-style: none;
}

.emotion-6.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-6 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-6.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-6.Mui-disabled {
  opacity: 0.38;
}

.emotion-6:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-6:hover {
    background-color: transparent;
  }
}

.emotion-6.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-6.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

@media (hover: none) {
  .emotion-6.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.emotion-6.Mui-selected.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.2);
}

.emotion-6.Mui-selected.Mui-disabled {
  opacity: 1;
  color: #1C1B1F61;
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-6.Mui-selected.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-7 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 1.25rem;
  margin: 0 -8px;
}

.emotion-9 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<div
    style="display: flex; justify-content: center;"
  >
    <div
      class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-0"
      style="width: 82%;"
    >
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 emotion-1"
      >
        <span>
          LessonMisconception
        </span>
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 emotion-1"
      >
        <span>
          LessonMisconception
        </span>
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 emotion-1"
      >
        <nav
          aria-label="pagination navigation"
          class="MuiPagination-root MuiPagination-text emotion-4"
          data-testid="misconception-pagination"
          style="display: flex; justify-content: center; align-items: center; margin-top: 25px;"
        >
          <ul
            class="MuiPagination-ul emotion-5"
          >
            <li>
              <button
                aria-label="Go to previous page"
                class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-6"
                disabled=""
                tabindex="-1"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-7"
                  data-testid="NavigateBeforeIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
            </li>
            <li>
              <button
                aria-current="true"
                aria-label="page 1"
                class="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-selected MuiPaginationItem-page emotion-6"
                tabindex="0"
                type="button"
              >
                1
                <span
                  class="MuiTouchRipple-root emotion-9"
                />
              </button>
            </li>
            <li>
              <button
                aria-label="Go to next page"
                class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-6"
                disabled=""
                tabindex="-1"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-7"
                  data-testid="NavigateNextIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
