import { MutateOptions } from '@tanstack/react-query';
import { useKyronQuery } from './kyronQuery';
import { useKyronMutation } from './kyronMutation';
import { User, Role, Pagination, Organization, StudentOverallAnalyticsType, LessonInstancesCard } from '../types';

type Options = MutateOptions<unknown, Error, User, unknown>;

export const useUsersQuery = ({ page = 1, perPage = 25 }: { page?: number; perPage?: number } = {}) =>
  useKyronQuery<{ users: User[]; meta: Pagination }>(`/users?page=${page}&per_page=${perPage}`, {
    queryKey: ['/users', page, perPage],
  });

export const useUpdateUser = (user: User, organization?: Organization) => {
  const { mutate, ...rest } = useKyronMutation<User>(`/users/${user.id}`, {
    method: 'PUT',
    invalidates: [`/organizations/${organization?.id}/invitations`],
  });

  return {
    mutate,
    updateUserRole: (role: Role, opts?: Options) => {
      mutate({ ...user, roles: [role] }, opts);
    },
    ...rest,
  };
};

export const useAddUserToOrganization = (organization?: Organization) =>
  useKyronMutation<{
    email: string;
  }>(`/users/add_to_organization`, {
    method: 'PUT',
    invalidates: [`/organizations/${organization?.id}/invitations`],
  });

export const useCurrentUser = () => useKyronQuery<User>('/users/current', { refetchOnWindowFocus: false });

export const useUserOverallAnalytics = (userId?: number) =>
  useKyronQuery<StudentOverallAnalyticsType | null>(`/users/${userId}/overall_analytics`, {
    enabled: !!userId,
    queryKey: ['/users', userId, 'overall_analytics'],
  });

export const useUserLessonInstances = (
  userId?: number,
  completed?: boolean,
  lessonId?: number | null,
  { page = 1, perPage = 15 } = {},
) => {
  const queryStr = new URLSearchParams();
  queryStr.set('page', page.toString());
  queryStr.set('per_page', perPage.toString());

  if (typeof completed === 'boolean') {
    queryStr.set('completed', completed.toString());
  }

  if (lessonId) queryStr.set('lesson_id', lessonId.toString());

  return useKyronQuery<{ lesson_instances: LessonInstancesCard[]; meta: Pagination }>(
    `/users/${userId}/lesson_instances?${queryStr.toString()}`,
    {
      enabled: !!userId,
      queryKey: ['/users', userId, 'lesson_instances', completed, lessonId, { page, perPage }],
    },
  );
};
