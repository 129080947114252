import { Link } from 'react-router-dom';
import { Box, SxProps } from '@mui/material';
import React from 'react';
import KyronLogo from '../../assets/kyron_logo.svg';
import { NavDrawerButton } from '../NavDrawerButton/NavDrawerButton';

type Props = {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sx?: SxProps;
};

export const NavDrawerButtonAndLogo = ({ drawerOpen, setDrawerOpen, sx }: Props) => (
  <Box gap={1} display='flex' alignItems='center' justifyItems='center' sx={sx}>
    {/* Hardcoding isAdmin to true as this Drawer will only be visible to admins */}
    <NavDrawerButton setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />
    <Link to='/' onClick={() => setDrawerOpen(false)} style={{ height: 24 }}>
      <img alt='Kyron Learning' src={KyronLogo} height='100%' />
    </Link>
  </Box>
);
