import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { BackgroundTask } from 'controllers/types';
import { KyronClient } from './utils/KyronClient';
import { JobProgressBar } from './BackgroundTasks/JobProgressBar';

export const BackgroundTaskProgress = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [tasks, setTasks] = useState<BackgroundTask[]>([]);
  const client = new KyronClient();

  useEffect(() => {
    client
      .getDataWithError<BackgroundTask[]>('/api/v1/background_task.json')
      .then(data => {
        setTasks(data);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {Array.isArray(tasks) &&
        tasks.map(task => (
          <div key={task.id}>
            <JobProgressBar task={task} />
          </div>
        ))}
    </div>
  );
};
