/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import { UnitEdit } from './UnitEdit';

const styles = {
  Unitdiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  Unitgrid: {
    width: '82%',
  },
};

export const AllUnits = props => {
  const updateUnitWithCollections = unit => {
    // necessary to ensure all the lesson information is included in the collection
    let collections = props.lessonCollections?.filter(collection => collection.parent_id === unit.id);
    collections = collections?.sort((a, b) => a.position - b.position);
    const updatedUnit = {
      ...unit,
      children: collections,
    };
    return updatedUnit;
  };

  return (
    <div style={styles.Unitdiv}>
      <Grid style={styles.Unitgrid} container spacing={2}>
        {props.units.map(unit => (
          <Grid xs={12} item key={unit.id}>
            <UnitEdit
              unit={updateUnitWithCollections(unit)}
              handleUnitDelete={props.handleUnitDelete}
              handleUnitUpdate={props.handleUnitUpdate}
              updateUnit={props.updateUnit}
              courses={props.courses}
              units={props.units}
              lessons={props.lessons}
              handleLessonAdd={props.handleLessonAdd}
              handleLessonUpdate={props.handleLessonUpdate}
              handleLessonRemove={props.handleLessonRemove}
              handleAddCollection={props.handleAddCollection}
              handleCollectionDelete={props.handleCollectionDelete}
              handleCollectionUpdate={props.handleCollectionUpdate}
              reorderCollection={props.reorderCollection}
              reorderLesson={props.reorderLesson}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

/* eslint-disable react/forbid-prop-types */
AllUnits.propTypes = {
  handleUnitDelete: PropTypes.func,
  handleUnitUpdate: PropTypes.func,
  updateUnit: PropTypes.func,
  units: PropTypes.array.isRequired,
  courses: PropTypes.array.isRequired,
  lessons: PropTypes.array,
  lessonCollections: PropTypes.array,
  handleLessonAdd: PropTypes.func,
  handleLessonUpdate: PropTypes.func,
  handleLessonRemove: PropTypes.func,
  handleAddCollection: PropTypes.func,
  handleCollectionDelete: PropTypes.func,
  handleCollectionUpdate: PropTypes.func,
  reorderCollection: PropTypes.func,
  reorderLesson: PropTypes.func,
};
