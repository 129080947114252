import { KeyboardArrowRight } from '@mui/icons-material';
import { Breadcrumbs, Link } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface Breadcrumb {
  to: string;
  label: string;
}

type StudioBreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

export function StudioBreadcrumbs({ breadcrumbs }: StudioBreadcrumbsProps) {
  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      separator={<KeyboardArrowRight fontSize='inherit' />}
      sx={{ typography: 'labelMedium', color: 'text.secondary' }}
    >
      {breadcrumbs.map(breadcrumb => (
        <Link component={RouterLink} to={breadcrumb.to} color='inherit' key={breadcrumb.label}>
          {breadcrumb.label}
        </Link>
      ))}
    </Breadcrumbs>
  );
}
