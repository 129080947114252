import React from 'react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

// Add more ControllerProps in this list to have direct access to them in the HookSelect props
// rather than inside the otherControllerProps prop
type PickedControllerProps = 'name' | 'control' | 'rules';

type HookTextFieldProps<T extends FieldValues> = TextFieldProps &
  Pick<ControllerProps<T>, PickedControllerProps> & {
    controllerProps?: Omit<ControllerProps<T>, PickedControllerProps>;
  };

/**
 * This is a TextField component from MUI v5 wrapped in a Controller component from react-hook-form
 * Ready to use with useForm hook!
 * example usage:
 * ```jsx
 * const { control } = useForm();
 *
 * <HookTextField
 *  name='lessonObjective'
 *  control={control}
 *  rules={{ required: 'This field is required' }}
 *  controllerProps={{ defaultValue: 'This is a lesson objective' }}
 *  label='Lesson objective'
 *  id='lesson-objective'
 *  multiline
 *  minRows={4}
 *  placeholder='Write a lesson objective here'
 * />
 * ```
 */
export function HookTextField<T extends FieldValues>({
  name,
  control,
  rules,
  controllerProps,
  ...textFieldProps
}: HookTextFieldProps<T>) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      {...controllerProps}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          {...textFieldProps}
          variant='outlined'
          fullWidth
          value={value}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
}
