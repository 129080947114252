/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

import { useSnackbar } from 'notistack';
import { useCreateLessonSegment } from 'controllers/react-query';
import { NewLessonSegment } from './LessonSegmentEditor/NewLessonSegment';

import { KyronClient } from './utils/KyronClient';
import { ExpandMore } from './utils/ExpandMore';
import { withDeleteModal } from './utils/DeleteModalHOC';
import { LessonEditorDetails } from './LessonEditor/LessonEditorDetails';
import { LessonForm } from './LessonEditor/LessonForm';
import { LessonSegmentsShow } from './LessonSegmentEditor/LessonSegmentsShow';

const LessonEditorDetailsDM = withDeleteModal(LessonEditorDetails, props => props.handleLessonDelete(props.lesson.id));

const styles = {
  lessonCard: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export const Lesson = props => {
  const { enqueueSnackbar } = useSnackbar();
  const [segmentExpanded, setSegmentExpanded] = useState(false);
  const [newSegmentExpanded, setNewSegmentExpanded] = useState(false);
  const [editable, setEditable] = useState(false);
  const [allSegmentNames, setAllSegmentNames] = useState([]);
  const [segmentNamesIds, setSegmentNamesIds] = useState([]);
  const [tableOfContents, setTableOfContents] = useState([]);
  const { mutate: createLessonSegment } = useCreateLessonSegment();

  const lessonState = lesson => ({
    id: lesson.id,
    name: lesson.name,
    teacher: lesson.tutor,
    tutor_id: lesson.tutor_id,
    description: lesson.description,
    miro_url: lesson.miro_url,
    gcp_project: lesson.gcp_project,
    gcp_df_agent: lesson.gcp_df_agent,
    active: lesson.active,
    video: null,
    custom_thumbnail: null,
    video_thumbnail: lesson.video_thumbnail,
    lesson_collection: lesson.lesson_collection,
    locale: lesson.locale,
    table_of_contents: tableOfContents,
    editors: lesson.editors,
    finalized_at: lesson.finalized_at,
    video_updated_at: lesson.video_updated_at,
    published_lesson_id: lesson.published_lesson_id,
    nlp_type: lesson.nlp_type,
    requires_finalization: lesson.requires_finalization,
  });
  const [lesson, setLesson] = useState(lessonState(props.lesson));
  const client = new KyronClient();

  useEffect(() => {
    setLesson(lessonState(props.lesson));
    getDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lesson]);

  const setLessonTOC = newTOC => {
    setLesson(prevState => ({
      ...prevState,
      table_of_contents: newTOC,
    }));
    setTableOfContents(newTOC);
  };

  function getDetails() {
    client
      .getDataWithError(`/api/v1/lessons/${props.lesson.id}/lesson_details.json`)
      .then(data => {
        setAllSegmentNames(data.lesson_segment_names || []);
        setSegmentNamesIds(data.lesson_segment_names_ids || []);
        setLessonTOC(data.table_of_contents || []);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  }

  const handleLessonExport = () => {
    client
      .getDataWithError(`/api/v1/lessons/${props.lesson.id}/export`)
      .then(data => {
        const blob = new Blob([JSON.stringify(data)], {
          type: 'application/json',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${data.name}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleNewSegmentExpandClick = () => {
    setSegmentExpanded(false);
    setNewSegmentExpanded(!newSegmentExpanded);
  };

  const handleSegmentExpandClick = () => {
    setNewSegmentExpanded(false);
    setSegmentExpanded(!segmentExpanded);
  };

  const handleLessonEdit = () => {
    setEditable(true);
  };

  const handleLessonEditCancel = () => {
    setEditable(false);
  };

  const handleLessonFinalize = () => {
    client
      .submitJsonDataWithError(`/api/v1/lessons/${props.lesson.id}/finalize`, 'POST', new FormData())
      .then(() => {
        enqueueSnackbar('Finalizing lesson video. This may take a few minutes.');
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  const handleNewLessonSegmentFormSubmit = (handleNewSegmentClick, lessonSegment, edgeRows) => {
    createLessonSegment(
      { lessonId: lesson.id, payload: { ...lessonSegment, lesson_id: lesson.id, edges: edgeRows } },
      {
        onSuccess: () => {
          handleNewSegmentClick();
        },
        onError: err => {
          enqueueSnackbar(err?.message, { variant: 'error' });
        },
      },
    );
  };

  const handleLessonEditFormSubmit = locallyUpdatedLesson => {
    props.handleLessonUpdate(locallyUpdatedLesson).then(serverUpdatedLesson => {
      setLesson({ ...locallyUpdatedLesson, ...serverUpdatedLesson });
    });
    setEditable(false);
  };

  const expandLessonSegments = (
    <>
      <Button icon={AddIcon} variant='contained' onClick={handleNewSegmentExpandClick}>
        Add Segment
      </Button>
      <ExpandMore
        expand={segmentExpanded}
        onClick={handleSegmentExpandClick}
        aria-expanded={segmentExpanded}
        aria-label='show lesson segments'
      >
        <ExpandMoreIcon />
      </ExpandMore>
    </>
  );
  const cardContent = editable ? (
    <>
      <LessonForm
        handleFormSubmit={handleLessonEditFormSubmit}
        handleExpand={handleLessonEditCancel}
        lesson={lesson}
        showTOC
        segmentNamesIds={segmentNamesIds}
        allTutors={props.allTutors}
      />
      <CardActions>{expandLessonSegments}</CardActions>
    </>
  ) : (
    <>
      <LessonEditorDetailsDM
        lesson={lesson}
        handleLessonEdit={handleLessonEdit}
        handleLessonExport={handleLessonExport}
        handleLessonDelete={props.handleLessonDelete}
        handleLessonFinalize={handleLessonFinalize}
        handleLessonPublish={() => props.handleLessonPublish(lesson)}
      />
      <CardActions>{expandLessonSegments}</CardActions>
    </>
  );

  const lessonSegmentsView = (
    <>
      <Collapse in={newSegmentExpanded} timeout='auto' mountOnEnter>
        <NewLessonSegment
          lessonId={props.lesson.id}
          handleExpand={handleNewSegmentExpandClick}
          handleFormSubmit={handleNewLessonSegmentFormSubmit}
          allMisconceptions={props.allMisconceptions}
          allSegmentNames={allSegmentNames}
        />
      </Collapse>
      <Collapse in={segmentExpanded} timeout='auto' mountOnEnter>
        <CardContent>
          <LessonSegmentsShow lessonId={props.lesson.id} allMisconceptions={props.allMisconceptions} />
        </CardContent>
      </Collapse>
    </>
  );

  const lessonEditor = (
    <>
      {cardContent}
      {lessonSegmentsView}
    </>
  );

  return (
    <div>
      <Card sx={styles.lessonCard}>{lessonEditor}</Card>
    </div>
  );
};

/* eslint-disable react/forbid-prop-types */
Lesson.propTypes = {
  handleLessonUpdate: PropTypes.func,
  handleLessonDelete: PropTypes.func,
  handleLessonPublish: PropTypes.func.isRequired,
  lesson: PropTypes.shape({
    name: PropTypes.string.isRequired,
    teacher: PropTypes.object,
    description: PropTypes.string.isRequired,
    gcp_project: PropTypes.string,
    gcp_df_agent: PropTypes.string,
    active: PropTypes.bool,
    video_thumbnail: PropTypes.string,
    video_url: PropTypes.string,
    video_duration: PropTypes.number,
    id: PropTypes.number.isRequired,
    table_of_contents: PropTypes.array,
    finalized_at: PropTypes.string,
    video_updated_at: PropTypes.string,
    published_lesson_id: PropTypes.number,
    nlp_type: PropTypes.string,
    requires_finalization: PropTypes.bool,
    use_df: PropTypes.bool,
  }),
  allMisconceptions: PropTypes.array,
  allTutors: PropTypes.array,
};
