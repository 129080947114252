import React, { useState } from 'react';
import { Card, CardActions, CardContent, IconButton, TextField, TextFieldProps } from '@mui/material';
import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { LtiPlatform, LtiPlatformPayload } from '../../controllers/ltiPlatforms';
import { useCreateLtiPlatform, useUpdateLtiPlatform } from '../../controllers/react-query/ltiPlatformHooks';

const getInitialForm = (activeOrgId: number, platformToEdit?: LtiPlatform | null): LtiPlatformPayload => ({
  name: platformToEdit?.name || '',
  description: platformToEdit?.description || '',
  issuer: platformToEdit?.issuer || '',
  clientId: platformToEdit?.client_id || '',
  keysetUrl: platformToEdit?.keyset_url || '',
  oidcAuthUrl: platformToEdit?.oidc_auth_url || '',
  accessTokenUrl: platformToEdit?.access_token_url || '',
  organizationId: platformToEdit?.organization_id || activeOrgId,
  ltiVersion: platformToEdit?.lti_version || 'v1p3',
});

type Props = {
  onClose: () => void;
  platformToEdit?: LtiPlatform | null;
  activeOrgId: number;
};

export function LtiPlatformForm({ onClose, platformToEdit, activeOrgId }: Props) {
  const initialForm = getInitialForm(activeOrgId, platformToEdit);
  const { mutate: createLtiPlatform } = useCreateLtiPlatform();
  const { mutate: updateLtiPlatform } = useUpdateLtiPlatform();
  const [ltiPlatform, setLtiPlatform] = useState(initialForm);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLtiPlatform(prevLtiPlatform => ({
      ...prevLtiPlatform,
      [e.target.name]: e.target.value,
    }));
  };

  const resetForm = () => setLtiPlatform(initialForm);

  const handleSubmitCreate = (ltiPlatformPayload: LtiPlatformPayload) => {
    createLtiPlatform(
      { payload: ltiPlatformPayload },
      {
        onSuccess: newLtiPlatform => {
          enqueueSnackbar(`${newLtiPlatform.name} was successfully created`, { variant: 'success' });
          resetForm();
          onClose();
        },
        onError: error => {
          enqueueSnackbar(`Failed to create LTI platform: ${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  const handleUpdate = (ltiPlatformPayload: LtiPlatformPayload) => {
    if (!platformToEdit?.id) return console.error('Platform ID is missing in update request');
    updateLtiPlatform(
      { platformId: platformToEdit?.id, payload: ltiPlatformPayload },
      {
        onSuccess: updatedLtiPlatform => {
          enqueueSnackbar(`${updatedLtiPlatform.name} was successfully updated`, { variant: 'success' });
          resetForm();
          onClose();
        },
        onError: error => {
          enqueueSnackbar(`Failed to update LTI platform: ${error.message}`, { variant: 'error' });
        },
      },
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const ltiPlatformPayload = {
      ...ltiPlatform,
      organizationId: Number(ltiPlatform.organizationId),
      description: ltiPlatform.description || null,
      accessTokenUrl: ltiPlatform.accessTokenUrl || null,
    };

    if (platformToEdit) {
      handleUpdate(ltiPlatformPayload);
    } else {
      handleSubmitCreate(ltiPlatformPayload);
    }
  };

  type FormEntry = TextFieldProps & { pattern?: string };
  const form: FormEntry[] = [
    {
      name: 'name',
      label: 'Name',
      required: true,
      value: ltiPlatform.name,
    },
    {
      name: 'description',
      label: 'Description',
      value: ltiPlatform.description,
    },
    {
      name: 'issuer',
      helperText: 'The URL that uniquely identifies an LTI platform. Must be HTTPS.',
      label: 'Issuer',
      required: true,
      pattern: 'https://.*',
      value: ltiPlatform.issuer,
    },
    {
      name: 'clientId',
      helperText: 'The client ID for the given issuer',
      label: 'Client ID',
      required: true,
      value: ltiPlatform.clientId,
    },
    {
      name: 'keysetUrl',
      helperText: 'The URL to the JWK Set for the given issuer',
      label: 'Keyset URL',
      type: 'url',
      required: true,
      value: ltiPlatform.keysetUrl,
    },
    {
      name: 'oidcAuthUrl',
      helperText: 'The platform URL to the OIDC auth endpoint to redirect initial LTI request',
      label: 'OIDC Auth URL',
      type: 'url',
      required: true,
      value: ltiPlatform.oidcAuthUrl,
    },
    {
      name: 'accessTokenUrl',
      helperText: 'The platform URL to the access token endpoint',
      label: 'Access Token URL',
      type: 'url',
      value: ltiPlatform.accessTokenUrl,
    },
  ];

  const handleFormCancel = () => {
    onClose();
    resetForm();
  };

  return (
    <Card variant='outlined'>
      <form data-testid='lti-platform-form' onSubmit={handleSubmit}>
        <CardContent>
          {form.map(({ name, required, helperText, label, type, value, pattern }: FormEntry) => (
            <TextField
              fullWidth
              margin='normal'
              key={name}
              required={required}
              data-testid={`lti-platform-${name}`}
              name={name}
              helperText={helperText}
              label={label}
              type={type}
              value={value}
              onChange={handleInputChange}
              inputProps={{ pattern }}
            />
          ))}
        </CardContent>
        <CardActions>
          <IconButton aria-label='submit lti platform' type='submit' data-testid='submit-button'>
            <DoneIcon />
          </IconButton>
          <IconButton aria-label='cancel lti platform' data-testid='cancel-button' onClick={handleFormCancel}>
            <CancelIcon />
          </IconButton>
        </CardActions>
      </form>
    </Card>
  );
}
