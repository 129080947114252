import React from 'react';
import { usePublishableKeyQuery } from 'controllers/react-query/paymentHooks';
import { UserContext } from './UserContext';

// used the recommended documentation from https://stripe.com/docs/payments/checkout/pricing-table

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export const StripePricingTable = () => {
  const { user } = React.useContext(UserContext);
  const { data } = usePublishableKeyQuery();

  return (
    <stripe-pricing-table
      data-testid='stripe-pricing-table'
      pricing-table-id='prctbl_1OI0sEDObcM3oM4hCkoSuKH8'
      publishable-key={data?.publishable_key}
      client-reference-id={user?.id}
    />
  );
};
