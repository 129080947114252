import { KyronClient } from '../components/utils/KyronClient';
import { Tutor } from './types';

export async function getAllTutors(): Promise<Tutor[]> {
  const PER_PAGE = 25;
  const responseData = await getTutorPage(1, PER_PAGE);
  let { nextPage } = responseData;
  const { totalPages, tutors } = responseData;
  let allTutors = tutors;
  while (nextPage && nextPage <= totalPages) {
    // eslint-disable-next-line no-await-in-loop
    const next = await getTutorPage(nextPage, PER_PAGE);
    nextPage = next.nextPage;
    allTutors = allTutors.concat(next.tutors);
  }

  return allTutors;
}

export async function getTutorPage(
  page: number,
  perPage: number,
): Promise<{
  nextPage: number;
  totalPages: number;
  totalCount: number;
  tutors: Tutor[];
}> {
  const client = new KyronClient();
  const {
    tutors,
    meta: { total_pages: totalPages, next_page: nextPage, total_count: totalCount },
  } = (await client.getDataWithError(`/api/v1/tutors?page=${page}&per_page=${perPage}`)) as {
    tutors: Tutor[];
    meta: {
      total_pages: number;
      next_page: number;
      total_count: number;
    };
  };

  return { tutors, totalPages, nextPage, totalCount };
}

export function createTutor(tutor: Tutor): Promise<Tutor> {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const property in tutor) {
    const key = property as keyof Tutor;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val = tutor[key] as any;
    formData.append(property, val);
  }

  const client = new KyronClient();
  return client.submitJsonDataWithError(`/api/v1/tutors.json`, 'POST', formData);
}

export function updateTutor(tutor: Tutor): Promise<Tutor> {
  const formData = new FormData();
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const property in tutor) {
    const key = property as keyof Tutor;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const val = tutor[key] as any;
    formData.append(property, val);
  }

  const client = new KyronClient();
  return client.submitJsonDataWithError(`/api/v1/tutors/${tutor.id}.json`, 'PUT', formData);
}

export function deleteTutor(tutorId: number): Promise<Tutor> {
  const client = new KyronClient();
  return client.deleteDataWithError(`/api/v1/tutors/${tutorId}`);
}
