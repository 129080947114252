/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader } from '@mui/material';
import { CourseForm } from './CourseForm';

const styles = {
  Coursecardfield: {
    borderRadius: '20px',
    margin: '10px 0px',
  },
};

export const NewCourse = props => (
  <Card sx={styles.Coursecardfield}>
    <CardHeader title='New Course' />
    <CourseForm
      handleFormSubmit={props.handleFormSubmit}
      handleFormCancel={props.handleFormCancel}
      addOrUpdateCourse={props.addCourse}
      handleCourseUpdate={props.handleCourseUpdate}
    />
  </Card>
);

NewCourse.propTypes = {
  handleFormSubmit: PropTypes.func.isRequired,
  handleFormCancel: PropTypes.func.isRequired,
  addCourse: PropTypes.func.isRequired,
  handleCourseUpdate: PropTypes.func.isRequired,
};
