import React, { useState, useEffect } from 'react';
import { useLoaderData, useSearchParams } from 'react-router-dom';

import { Grid, Pagination, IconButton, Collapse, Tooltip } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

import { KyronLayoutContent } from 'components/KyronLayout/KyronLayout';
import {
  getOrganizations,
  updateOrganization,
  createOrganization,
  setActiveOrganization,
} from 'controllers/organizations';
import { Organization } from 'controllers/types';
import { OrganizationCard } from './OrganizationCard';

export const loader = getOrganizations;

const styles = {
  PaginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '25px',
  },
  ListWrapper: {
    listStyle: 'none',
  },
  ListItem: {
    display: 'flex',
    margin: '10px 0',
  },
};

export function AdminOrganizations(): React.JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const { organizations: originalOrganizations, meta } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const [organizations, setOrganizations] = useState(originalOrganizations);
  const [showNewOrganization, setShowNewOrganization] = useState(false);

  useEffect(() => setOrganizations(originalOrganizations), [originalOrganizations]);

  const handleUpdateOrganization = async (organization: Organization) => {
    const updatedOrg = await updateOrganization(organization);
    setOrganizations(orgs => orgs.map(o => (o.id === updatedOrg.id ? updatedOrg : o)));
    enqueueSnackbar('Organization updated');
  };

  const handleCreateOrganization = async (organization: Organization) => {
    const newOrg = await createOrganization(organization);
    setOrganizations([newOrg, ...organizations]);
    enqueueSnackbar('Created a new organization');
    setShowNewOrganization(false);
  };

  const handleChangeActiveOrganization = (organization: Organization) => () => {
    setActiveOrganization(organization.id)
      .then(() => {
        // Changing organizations fundamentally changes the data we load on
        // most non-student pages. Rather than having all components that fetch
        // data listen to a hypothetical "organization change" event, we just
        // reload the page.
        window.location.reload();
        enqueueSnackbar(`Set active organization to ${organization.name}`, { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`Failed to set active organization: ${e}`, { variant: 'error' });
      });
  };

  const pg = searchParams.get('page');

  return (
    <KyronLayoutContent>
      <h1>
        Organizations
        <Tooltip title='Create a new organization'>
          <IconButton onClick={() => setShowNewOrganization(!showNewOrganization)}>
            <Add />
          </IconButton>
        </Tooltip>
      </h1>
      <div>
        <Collapse in={showNewOrganization} timeout='auto' unmountOnExit>
          <OrganizationCard
            handleFormSubmit={newOrganization => handleCreateOrganization(newOrganization)}
            startEditing
          />
        </Collapse>
      </div>

      <ul data-testid='organization-list' style={styles.ListWrapper}>
        {organizations.map(organization => (
          <li key={organization.id} style={styles.ListItem}>
            <OrganizationCard
              organization={organization}
              handleFormSubmit={editedOrganization => handleUpdateOrganization(editedOrganization)}
              handleChangeActiveOrganization={handleChangeActiveOrganization(organization)}
            />
          </li>
        ))}
      </ul>

      <Grid xs={12} item>
        <Pagination
          style={styles.PaginationWrapper}
          count={meta.total_pages || 1}
          siblingCount={5}
          shape='rounded'
          page={pg ? parseInt(pg, 10) : 1}
          onChange={(_e, page) => setSearchParams({ page: page.toString() })}
        />
      </Grid>
    </KyronLayoutContent>
  );
}
