import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { getLoginURLWithRedirect } from 'components/utils/urlUtils';
import { User } from 'controllers/types';

export const ProtectedRoute = ({ user }: { user: User | null }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (!user?.id) {
      setIsLoggedIn(false);
      return window.location.replace(`${window.location.origin}${getLoginURLWithRedirect()}`);
    }

    setIsLoggedIn(true);
  }, [user, isLoggedIn]);

  return isLoggedIn ? <Outlet /> : null;
};
