import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { ThumbDownOutlined, ThumbDown } from '@mui/icons-material';
import { KyronEvents } from 'components/utils/KyronEvents';
import { UserContext } from 'components/UserContext';
import { useModal } from 'components/utils/ModalContext';
import { ChatFeedbackModalContent } from './ChatFeedbackModalContent';

type ConversationItem = {
  role: string;
  text: string;
};

type ChatFeedbackTypes = {
  aiText: string;
  chatId: number;
  rejectedMessage: string;
  replaceLastMessage: (text: string) => void;
  question: string | null;
  conversation: Array<ConversationItem>;
};

export const ChatFeedback = ({
  aiText,
  chatId,
  rejectedMessage,
  replaceLastMessage,
  question,
  conversation,
}: ChatFeedbackTypes) => {
  const [isThumbsDown, setIsThumbsDown] = useState(false);
  const { isPlatformAdmin } = React.useContext(UserContext);
  const { openModal } = useModal();

  function handleThumbsDown(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();

    // Only show the feedback modal if the user is admin and thumbsdown is not true already
    if (isPlatformAdmin && !isThumbsDown) {
      openModal({
        id: 'ai-feedback-modal',
        title: null,
        content: (
          <ChatFeedbackModalContent
            chatId={chatId}
            rejectedMessage={rejectedMessage}
            replaceLastMessage={replaceLastMessage}
            question={question}
          />
        ),
        fullWidth: true,
        dismissible: false,
      });
    }
    setIsThumbsDown(!isThumbsDown);
    KyronEvents.sendEvent(isThumbsDown ? KyronEvents.names.UNTHUMBS_DOWN : KyronEvents.names.THUMBS_DOWN, {
      question,
      conversation,
      text: aiText,
    });
  }

  return (
    <IconButton
      aria-label='bad-response'
      data-testid='thumbsdown'
      size='small'
      onClick={handleThumbsDown}
      sx={{ color: 'text.secondary' }}
    >
      {isThumbsDown ? (
        <ThumbDown fontSize='inherit' aria-pressed='true' />
      ) : (
        <ThumbDownOutlined fontSize='inherit' aria-pressed='false' />
      )}
    </IconButton>
  );
};
