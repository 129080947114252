import { MutationFunction, MutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { KyronClient } from 'components/utils/KyronClient';
import { API_V1 } from './constants';
import { replaceURLPathParams, URLParamValue } from '../../components/utils/urlUtils';
import { BackgroundTask } from '../types';
import { queryClient as queryClientFromConfig } from './config/queryClient';

export const PENDING_ASYNC_INVALIDATIONS_KEY = 'pending_async_invalidations';

const defaultOptions = {
  method: 'POST',
};

interface KyronMutationOptions<TResp = unknown, TError = Error, TPayload = Record<string, unknown>, TContext = unknown>
  extends Partial<MutationOptions<TResp, TError, TPayload, TContext>> {
  method?: Request['method'];
  invalidates?: ReadonlyArray<unknown>; // Single path invalidation
  invalidatesMultiple?: ReadonlyArray<unknown>[]; // Multiple paths invalidation
  customMutationFn?: MutationFunction<TResp, TPayload>; // Custom mutation function (e.g. if you need to define endpoint based on payload)
}

/**
 * @deprecated use useKyronMutationV2 instead
 */
export const useKyronMutation = <TPayload extends Record<string, unknown>, TResp = unknown, TError = Error>(
  endpoint: string,
  {
    invalidates,
    invalidatesMultiple,
    customMutationFn,
    ...options
  }: KyronMutationOptions<TResp, TError, TPayload> = {},
) => {
  if (endpoint.includes(API_V1)) throw new Error(`endpoint should not include API prefix (${API_V1})`);

  const client = new KyronClient();
  const queryClient = useQueryClient();

  const url = API_V1 + endpoint;
  const opts = { ...defaultOptions, ...options };
  const mutationFn =
    customMutationFn ||
    (async (payload: TPayload) => {
      const form = client.convertJsonToFormData(payload, { convertToSnakeCase: true });
      return client.submitJsonDataWithError<TResp>(url, opts.method, form);
    });

  const wrappedConfig = {
    ...opts, // This is to ensure that if you have other react-query options in the future, they are spread into the config
    onSuccess: (data: TResp, variables: TPayload, context: unknown) => {
      // Following code is to define some endpoints to invalidate as a successful mutation side effect
      // when there is an invalidates array in options, this logic will invalidate the endpoint data in cache
      const queryKeyToInvalidate = invalidates;
      // Invalidate single query key
      if (queryKeyToInvalidate) {
        return queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate, exact: false });
      }
      const multipleQueryKeysToInvalidate = invalidatesMultiple;
      // Invalidate multiple query keys
      if (multipleQueryKeysToInvalidate) {
        multipleQueryKeysToInvalidate.map(queryKey => queryClient.invalidateQueries({ queryKey, exact: false }));
      }

      // Call any onSuccess callbacks passed in from the caller of "mutate" function in the component
      opts.onSuccess?.(data, variables, context);
    },
  };

  return useMutation<TResp, TError, TPayload>({ mutationFn, ...wrappedConfig });
};

type RequestPayload = Record<string, unknown>;
export type PayloadAndRequestParams = Record<string, URLParamValue | RequestPayload> & { payload?: RequestPayload };
interface KyronMutationV2Options<
  TResp = unknown,
  TError = Error,
  TPayloadAndRequestParams = PayloadAndRequestParams,
  TContext = unknown,
> extends Partial<MutationOptions<TResp, TError, TPayloadAndRequestParams, TContext>> {
  method?: Request['method'];
  invalidates?: ReadonlyArray<unknown> | ((params: TPayloadAndRequestParams) => ReadonlyArray<unknown>); // Single path invalidation
  invalidatesMultiple?: ReadonlyArray<unknown[]> | ((params: TPayloadAndRequestParams) => ReadonlyArray<unknown[]>); // Multiple paths invalidation
}

/**
 * This is an improved version of useKyronMutation that allows for more flexibility in defining the endpoint and
 * assigning params in the endpoint.
 *
 * Using useKyronMutationV2, developer will be able to do the following:
 *
 * ```tsx
 * const useUpdateResource = () => useKyronMutationV2<{ id: number, payload: Resource }>('/resources/:id')
 *
 * const { mutate } = useUpdateResource();
 *
 * // id is going to be placed in the matching URL param placeholder in the endpoint path
 * // resulting in a request to "/resources/1" with the payload "{ name: 'new name' }"
 * mutate({ id: 1, payload: { name: 'new name' } });
 * ```
 *
 * Another example can be:
 *
 * ```tsx
 * const useUpdateResource = () => useKyronMutationV2<{ page: number, perPage: number }>('/resources?page=:page&per_page=:per_page', {
 *  invalidates: ({ page, perPage }) => [`/resources?page=${page}&per_page=${perPage}`]
 * })
 * ```
 *
 * @param endpoint
 * @param kyronMutationV2Options
 */
export const useKyronMutationV2 = <
  TPayloadAndRequestParams extends PayloadAndRequestParams,
  TResp = unknown,
  TError = Error,
>(
  endpoint: string,
  kyronMutationV2Options?: KyronMutationV2Options<TResp, TError, TPayloadAndRequestParams>,
) => {
  const { invalidates, invalidatesMultiple, ...options } = kyronMutationV2Options || {};
  if (endpoint.includes(API_V1)) throw new Error(`endpoint should not include API prefix (${API_V1})`);
  if (endpoint.includes(':payload')) {
    throw new Error('endpoint parameter must not include ":payload" as it is a reserved keyword');
  }

  const client = new KyronClient();
  const queryClient = useQueryClient();

  const opts = { ...defaultOptions, ...options };
  const mutationFn = async (urlParamsAndPayload?: TPayloadAndRequestParams) => {
    if (endpoint.includes(':') && !urlParamsAndPayload) {
      throw new Error(`endpoint requires params: ${endpoint} but none provided to mutation function`);
    }
    const { payload, ...rest } = urlParamsAndPayload || {};
    const urlParamsOnly = rest as Record<string, URLParamValue>;
    const payloadOnly = payload as TPayloadAndRequestParams;

    const url = API_V1 + replaceURLPathParams(endpoint, urlParamsOnly);
    // An engineer may want to use this method as a GET request if they don't want the request to run on
    // initial render. Typically, this is only the case for state changing non-GET requests but occasionally this is
    // useful for GET requests too.
    if (opts.method === 'GET') {
      if (payload) {
        throw new Error('GET requests cannot have a payload');
      }
      return client.getDataWithError<TResp>(url);
    }

    const form = client.convertJsonToFormData(payloadOnly, { convertToSnakeCase: true });
    const queryKeysToInvalidate = getQueriesToInvalidate(urlParamsAndPayload);
    return client
      .submitJsonDataWithError<TResp>(url, opts.method, form)
      .then(handleImmediateInvalidations(queryKeysToInvalidate))
      .then(enqueueAsyncInvalidations(queryKeysToInvalidate));
  };

  // Invalidates the queries after getting 2xx response from server
  function handleImmediateInvalidations(queryKeysToInvalidate: string[][]) {
    return async (resp: TResp) => {
      await Promise.all(
        queryKeysToInvalidate.map(queryKey => queryClient.invalidateQueries({ queryKey, exact: false })) || [],
      );
      return resp;
    };
  }

  // Takes note of started background tasks related to the mutation and runs invalidations once they are done
  function enqueueAsyncInvalidations(queryKeysToInvalidate: string[][]) {
    return (resp: TResp) => {
      const response = resp as { background_task_ids?: number[] };
      enqueueAsyncInvalidation(response.background_task_ids, queryKeysToInvalidate);
      return resp;
    };
  }

  // Returns the query key array to invalidate on a successful mutation
  function getQueriesToInvalidate(params = {} as TPayloadAndRequestParams) {
    let queryKeysToInvalidate = [];
    if (invalidates) {
      const queryKeyToInvalidate =
        typeof invalidates === 'function'
          ? invalidates(params) // If a function, call it with the params to get the query key array to invalidate
          : invalidates;
      queryKeysToInvalidate.push(queryKeyToInvalidate);
    }

    if (invalidatesMultiple) {
      const multipleKeys =
        typeof invalidatesMultiple === 'function'
          ? invalidatesMultiple(params) // If a function, call it with the params to get the query key array to invalidate
          : invalidatesMultiple;
      queryKeysToInvalidate = [...multipleKeys];
    }

    return queryKeysToInvalidate as string[][];
  }

  return useMutation<TResp, TError, TPayloadAndRequestParams>({ mutationFn, ...opts });
};

export type AsyncInvalidations = Record<`background_task_${number}`, string[][]>; // { background_task1: [['queryKey', '1'], ['queryKey2']] }
// Key for the pending async invalidations item in the local storage
const getKey = (taskId: number) => `background_task_${taskId}` as const;

// Saves async invalidations in local storage to be processed after the background tasks are done
// Processing of these async invalidations are happening in AppContext when a notification with state: done is received
// for a watched background task
export function enqueueAsyncInvalidation(
  backgroundTaskIds: number[] | undefined,
  queryKeysToInvalidate: string[][] = [],
) {
  if (!backgroundTaskIds || !backgroundTaskIds.length) return;

  // Get existing invalidations map from localStorage
  const previousPendingAsyncInvalidations = JSON.parse(
    localStorage.getItem(PENDING_ASYNC_INVALIDATIONS_KEY) || '{}',
  ) as AsyncInvalidations;

  const newPendingAsyncInvalidations: AsyncInvalidations = Object.fromEntries(
    backgroundTaskIds.map(taskId => [getKey(taskId), queryKeysToInvalidate]),
  );

  // Create a new map with the additional entries
  const updatedInvalidations = {
    ...previousPendingAsyncInvalidations,
    // Create an entry for each background task ID
    ...newPendingAsyncInvalidations,
  };

  // Save the updated map back to localStorage
  localStorage.setItem(PENDING_ASYNC_INVALIDATIONS_KEY, JSON.stringify(updatedInvalidations));
}

// Runs async invalidations for the finished tasks and removes them from the local storage
export function runAsyncInvalidations(finishedTasks: BackgroundTask[]) {
  // Get the persisted async invalidations that UI is supposed to watch
  const pendingAsyncInvalidations: AsyncInvalidations = JSON.parse(
    localStorage.getItem(PENDING_ASYNC_INVALIDATIONS_KEY) || '{}',
  );

  // Find all finished tasks that have pending invalidations
  const tasksWithInvalidations = finishedTasks.filter(task => pendingAsyncInvalidations[getKey(task.id)]);

  // run invalidations
  if (tasksWithInvalidations.length > 0) {
    tasksWithInvalidations.forEach(task => {
      const queryKeysToInvalidate = pendingAsyncInvalidations[getKey(task.id)];
      queryKeysToInvalidate.forEach(queryKey => queryClientFromConfig.invalidateQueries({ queryKey, exact: false }));
      // delete the pending invalidation
      delete pendingAsyncInvalidations[getKey(task.id)];
    });
  }

  // Set the remaining invalidations back to the local storage
  localStorage.setItem(PENDING_ASYNC_INVALIDATIONS_KEY, JSON.stringify(pendingAsyncInvalidations));
}
