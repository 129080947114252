import { useKyronMutationV2 } from './kyronMutation';

export type FileError = { signed_id: string; error: string; filename: string };

export const useDeleteAttachment = () => useKyronMutationV2('/attachments/:signedId', { method: 'DELETE' });

export const useAuxContentValidation = () =>
  useKyronMutationV2<{ payload: { signedIds: string[] } }, { errors: FileError[] }>(
    '/attachments/validate_aux_contents',
    { method: 'POST' },
  );
