// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`AdminBillingForm renders the admin billing form 1`] = `
<DocumentFragment>
  .emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border: 1px solid #D6D5DD;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  margin-top: 24px;
  margin-bottom: 24px;
}

.emotion-1 {
  padding: 16px;
}

.emotion-1:last-child {
  padding-bottom: 24px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.emotion-2>:not(style):not(style) {
  margin: 0;
}

.emotion-2>:not(style)~:not(style) {
  margin-top: 16px;
}

.emotion-3 {
  margin: 0;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.emotion-4 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

.emotion-4 .clearButton {
  opacity: 1;
}

@media (pointer: fine) {
  .emotion-4 .clearButton {
    opacity: 0;
  }

  .emotion-4:hover .clearButton,
  .emotion-4:focus-within .clearButton {
    opacity: 1;
  }
}

.emotion-5 {
  color: #1C1B1FB3;
  line-height: 1.4375em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(14px, -9px) scale(0.75);
  -moz-transform: translate(14px, -9px) scale(0.75);
  -ms-transform: translate(14px, -9px) scale(0.75);
  transform: translate(14px, -9px) scale(0.75);
  -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.emotion-5.Mui-focused {
  color: #4B35FF;
}

.emotion-5.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-5.Mui-error {
  color: #BA1A1A;
}

.emotion-6 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
  padding-right: 14px;
}

.emotion-6.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-6:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-6:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-6.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-6.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-6.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-7 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  padding-right: 0;
}

.emotion-7::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-7:focus {
  outline: 0;
}

.emotion-7:invalid {
  box-shadow: none;
}

.emotion-7::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-7:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-7.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-7:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-7:-webkit-autofill {
  border-radius: inherit;
}

.emotion-8 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 0.01em;
  max-height: 2em;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.54);
  margin-left: 8px;
}

.emotion-9 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  margin-right: -12px;
}

.emotion-9::-moz-focus-inner {
  border-style: none;
}

.emotion-9.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-9 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-9:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-9:hover {
    background-color: transparent;
  }
}

.emotion-9.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-10 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-11 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-12 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-13 {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 100%;
  -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
  white-space: nowrap;
}

.emotion-13>span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}

.emotion-14 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  vertical-align: top;
}

.emotion-16.Mui-error {
  color: #BA1A1A;
}

.emotion-17 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
}

.emotion-17.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-17:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-17:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-17.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-17.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-17.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-18 {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.emotion-18::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-18::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-18:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-18::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-18:focus {
  outline: 0;
}

.emotion-18:invalid {
  box-shadow: none;
}

.emotion-18::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-18::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-18::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-18:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-18::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-18:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-18:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-18:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-18:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-18.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-18:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-18:-webkit-autofill {
  border-radius: inherit;
}

.emotion-21 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 8px;
}

.emotion-21>:not(style)~:not(style) {
  margin-left: 8px;
}

.emotion-22 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-transform: none;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 8px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #FFFFFF;
  background-color: #4B35FF;
  box-shadow: 0.3px 0.3px 0.5px 0px rgba(162, 147, 169, 0.34),1.1px 1.1px 2.2px 1px rgba(162, 147, 169, 0.34),2.7px 2.7px 4.3px -1.7px rgba(162, 147, 169, 0.34),6.5px 6.6px 10.4px -2.5px rgba(162, 147, 169, 0.34);
  box-shadow: none;
  border-radius: 100px;
  text-transform: none;
  padding: 10px 24px;
  white-space: nowrap;
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.emotion-22::-moz-focus-inner {
  border-style: none;
}

.emotion-22.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-22 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-22:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: rgb(52, 37, 178);
  box-shadow: none;
}

@media (hover: none) {
  .emotion-22:hover {
    background-color: #4B35FF;
  }
}

.emotion-22:active {
  box-shadow: none;
}

.emotion-22.Mui-focusVisible {
  box-shadow: none;
}

.emotion-22.Mui-disabled {
  color: #1C1B1F61;
  box-shadow: none;
  background-color: #1C1B1F1F;
}

.emotion-22:hover {
  box-shadow: none;
}

.emotion-22.Mui-focusVisible {
  box-shadow: none;
}

.emotion-22:active {
  box-shadow: none;
}

.emotion-22.Mui-disabled {
  box-shadow: none;
}

<div
    class="MuiPaper-root MuiPaper-outlined MuiPaper-rounded MuiCard-root emotion-0"
    data-testid="admin-billing-form"
  >
    <form>
      <div
        class="MuiCardContent-root emotion-1"
      >
        <div
          class="MuiStack-root emotion-2"
        >
          <h3
            class="MuiTypography-root MuiTypography-titleMedium emotion-3"
          >
            Create Subscription
          </h3>
          <div
            class="MuiFormControl-root MuiTextField-root emotion-4"
          >
            <label
              class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined emotion-5"
              data-shrink="true"
              for=":r0:"
              id=":r0:-label"
            >
              Subscription End Date
            </label>
            <div
              class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl MuiInputBase-adornedEnd emotion-6"
            >
              <input
                aria-invalid="false"
                autocomplete="off"
                class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd emotion-7"
                id=":r0:"
                inputmode="text"
                placeholder="MM/DD/YYYY"
                type="text"
                value="12/11/2011"
              />
              <div
                class="MuiInputAdornment-root MuiInputAdornment-positionEnd MuiInputAdornment-outlined MuiInputAdornment-sizeMedium emotion-8"
              >
                <button
                  aria-label="Choose date, selected date is Dec 11, 2011"
                  class="MuiButtonBase-root MuiIconButton-root MuiIconButton-edgeEnd MuiIconButton-sizeMedium emotion-9"
                  tabindex="0"
                  type="button"
                >
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-10"
                    data-testid="CalendarIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
                    />
                  </svg>
                  <span
                    class="MuiTouchRipple-root emotion-11"
                  />
                </button>
              </div>
              <fieldset
                aria-hidden="true"
                class="MuiOutlinedInput-notchedOutline emotion-12"
              >
                <legend
                  class="emotion-13"
                >
                  <span>
                    Subscription End Date
                  </span>
                </legend>
              </fieldset>
            </div>
          </div>
          <div
            class="MuiFormControl-root MuiTextField-root emotion-14"
            data-testid="number-of-seats-input"
          >
            <label
              class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled Mui-required MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-sizeMedium MuiInputLabel-outlined emotion-5"
              data-shrink="true"
              for=":r2:"
              id=":r2:-label"
            >
              Number of Seats
              <span
                aria-hidden="true"
                class="MuiFormLabel-asterisk MuiInputLabel-asterisk emotion-16"
              >
                 *
              </span>
            </label>
            <div
              class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-formControl emotion-17"
            >
              <input
                aria-invalid="false"
                class="MuiInputBase-input MuiOutlinedInput-input emotion-18"
                id=":r2:"
                required=""
                type="number"
                value="1"
              />
              <fieldset
                aria-hidden="true"
                class="MuiOutlinedInput-notchedOutline emotion-12"
              >
                <legend
                  class="emotion-13"
                >
                  <span>
                    Number of Seats *
                  </span>
                </legend>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiCardActions-root MuiCardActions-spacing emotion-21"
      >
        <button
          class="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-sizeMedium MuiButton-containedSizeMedium MuiButton-colorPrimary MuiButton-disableElevation emotion-22"
          data-testid="submit-button"
          tabindex="0"
          type="submit"
        >
          Save
          <span
            class="MuiTouchRipple-root emotion-11"
          />
        </button>
      </div>
    </form>
  </div>
</DocumentFragment>
`;
