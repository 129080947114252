import React from 'react';
import { LtiPlatformSection } from 'components/LtiPlatform/LtiPlatformSection';
import { useFeatures } from 'components/FeaturesContext';
import { SettingsNavigation } from './SettingsNavigation';
import { OauthSection } from '../CanvasOauth/OauthSection';

export function IntegrationsPage(): React.JSX.Element {
  const { show_canvas_courses: showCanvasCourses } = useFeatures();

  return (
    <>
      <SettingsNavigation />
      <LtiPlatformSection />
      {showCanvasCourses && <OauthSection />}
    </>
  );
}
