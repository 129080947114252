/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/Done';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

export const UnitForm = props => {
  const [unit, setUnit] = useState(
    props.unit
      ? {
          id: props.unit.id,
          name: props.unit.name,
          description: props.unit.description,
          parent_id: props.unit.parent_id,
          lessons: props.unit.lessons,
        }
      : {
          id: null,
          name: '',
          description: '',
          parent_id: null,
          lessons: [],
        },
  );

  const handleInputChange = e => {
    if (e.target.name === 'course') {
      e.target.value = props.courses.find(course => course.id === parseInt(e.target.value, 10));
    }
    setUnit(prevUnit => ({
      ...prevUnit,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <CardContent>
      <form
        data-testid='unit-form'
        onSubmit={e => {
          e.preventDefault();
          props.handleFormSubmit(props.handleUnitUpdate, props.addOrUpdateUnit, unit);
          props.handleFormCancel();
          e.target.reset();
        }}
      >
        <TextField
          variant='outlined'
          name='name'
          fullWidth
          margin='normal'
          label='Unit Name'
          type='text'
          onChange={handleInputChange}
          defaultValue={unit.name ? unit.name : ''}
          required
          helperText='Enter the name of the unit'
        />
        <TextField
          variant='outlined'
          name='description'
          fullWidth
          margin='normal'
          label='Unit Description'
          type='text'
          onChange={handleInputChange}
          defaultValue={unit.description ? unit.description : ''}
          helperText='Enter a description of the unit'
        />
        <InputLabel id='course-menu-dropdown'>Course</InputLabel>
        <Select
          labelId='course-select-label'
          id='course-select'
          name='parent_id'
          label='Course'
          displayEmpty
          required
          value={unit.parent_id ? unit.parent_id : ''}
          onChange={handleInputChange}
        >
          {props.courses.map(course => (
            <MenuItem key={course.id} value={course.id}>
              {course.name}
            </MenuItem>
          ))}
        </Select>
        <CardActions>
          <IconButton aria-label='submit unit' type='submit' data-testid='done-button'>
            <DoneIcon />
          </IconButton>
          <IconButton aria-label='cancel edit unit' onClick={props.handleFormCancel}>
            <CancelIcon />
          </IconButton>
        </CardActions>
      </form>
    </CardContent>
  );
};

/* eslint-disable react/forbid-prop-types */
UnitForm.propTypes = {
  unit: PropTypes.object,
  handleFormSubmit: PropTypes.func,
  handleUnitUpdate: PropTypes.func,
  addOrUpdateUnit: PropTypes.func,
  handleFormCancel: PropTypes.func,
  courses: PropTypes.array,
};
