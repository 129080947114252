import React, { useState } from 'react';
import {
  ListItem,
  ListItemText,
  Stack,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { Invitation, Organization } from 'controllers/types';
import { useRemoveInvitationFromOrganization } from 'controllers/react-query';
import { useSnackbar } from 'notistack';

export function MemberListItem({
  avatar,
  displayName,
  roleSelect,
  invitation,
  organization,
  deletable = true,
}: {
  avatar: JSX.Element | null;
  displayName: string;
  roleSelect: JSX.Element;
  invitation: Invitation;
  organization?: Organization;
  deletable?: boolean;
}): React.JSX.Element {
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const { mutate } = useRemoveInvitationFromOrganization(invitation, organization);
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteMember = () => {
    mutate({
      onSuccess: () => {
        enqueueSnackbar(`${displayName} has been removed from this organization.`, { variant: 'success' });
      },
      onError: (error: Error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    });
  };

  return (
    <>
      <ListItem sx={{ display: 'flex', height: 56, alignItems: 'center', justifyContent: 'space-between' }} divider>
        <Stack direction='row' gap={1} sx={{ width: '30%' }}>
          {avatar}
          <ListItemText>{displayName}</ListItemText>
        </Stack>
        {roleSelect}
        <IconButton
          data-testid='delete-member-button'
          sx={{ color: 'error.main' }}
          onClick={() => setOpenConfirmDelete(true)}
          disabled={!deletable}
        >
          <DeleteOutline />
        </IconButton>
      </ListItem>

      <DeleteModal
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        displayName={displayName}
        handleDeleteMember={handleDeleteMember}
      />
    </>
  );
}

function DeleteModal({
  open,
  onClose,
  displayName,
  handleDeleteMember,
}: {
  open: boolean;
  onClose: () => void;
  displayName: string;
  handleDeleteMember: () => void;
}) {
  return (
    <Dialog open={open} onClose={onClose} data-testid='confirm-delete-member-dialog'>
      <DialogTitle>Delete Member</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete {displayName} from this organization? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            handleDeleteMember();
            onClose();
          }}
          color='error'
          data-testid='confirm-delete-member-button'
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
