// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`KyronLayout KyronLayoutContent should render the children in layout 1`] = `
<DocumentFragment>
  <div
    style="padding: 24px; background: rgb(255, 255, 255);"
  >
    Test
  </div>
</DocumentFragment>
`;
