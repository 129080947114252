import { useActions, useBreadcrumbs, useTitle } from 'components/StudioLayout/StudioLayout';
import { useTutorsQuery } from 'controllers/react-query';
import React, { useMemo, useState } from 'react';
import { Button, List, Pagination } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { NoResources } from 'components/NoResources';
import { ListItemSkeleton } from '../components/ListItemSkeleton';
import { TutorListItem } from './TutorListItem';

export const Tutors = () => {
  const [page, setPage] = useState(1);
  const perPage = 25;
  const { data: tutorsData, isError, isLoading } = useTutorsQuery({ page, perPage });
  const tutors = tutorsData?.tutors || [];
  const totalPages = tutorsData?.meta?.total_pages;

  const newTutorButton = useMemo(
    () => (
      <Button component={Link} startIcon={<Add />} to='/studio/tutors/new'>
        Add Tutor
      </Button>
    ),
    [],
  );

  useTitle('Tutors');
  useActions(newTutorButton);
  useBreadcrumbs(useMemo(() => [], []));

  if (isLoading) {
    return <ListItemSkeleton rows={3} height={56} loading />;
  }

  if (isError) {
    throw new Error('Error fetching tutors');
  }

  if (!tutors.length) {
    return <NoResources message='Get started by adding a tutor' action={newTutorButton} />;
  }

  return (
    <>
      <List disablePadding>
        {tutors.map(tutor => (
          <TutorListItem key={tutor.id} tutor={tutor} />
        ))}
      </List>
      {totalPages && totalPages > 1 ? (
        <Pagination
          sx={{ mt: 3, alignSelf: 'center' }}
          count={totalPages}
          page={page}
          onChange={(_, p) => setPage(p)}
        />
      ) : null}
    </>
  );
};
