import { KyronClient } from '../components/utils/KyronClient';

export interface Prompt {
  id: number;
  chat_type: string;
  dict: string;
  temperature: number;
  prompt: string;
  style_prompts: string;
  solved_proplem_prompt: string;
  created_at: string;
  updated_at: string;
}

export async function getPrompts(): Promise<Prompt[]> {
  const client = new KyronClient();
  // TODO: Handle multiple pages here
  const data = (await client.getDataWithError('/api/v1/prompts')) as { prompts: Prompt[] };
  return data.prompts;
}
