/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Fab } from '@mui/material';

import { Add, Delete, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';

export const AddDeleteFab = props =>
  props.lastSegment ? (
    <>
      <Tooltip title='Move up Target Segment'>
        <Fab data-testid='moveup-fab' color='primary' size='small' onClick={() => props.moveHandle(props.dataId, 'up')}>
          <KeyboardArrowUp />
        </Fab>
      </Tooltip>
      <Tooltip title='Delete Target Segment'>
        <Fab data-testid='delete-fab' name={props.dataId} color='primary' size='small' onClick={props.deleteHandle}>
          <Delete />
        </Fab>
      </Tooltip>
      <Tooltip title='Add Target Segment'>
        <Fab data-testid='add-fab' color='primary' size='small' onClick={props.addHandle}>
          <Add />
        </Fab>
      </Tooltip>
    </>
  ) : (
    <>
      <Tooltip title='Move Down Target Segment'>
        <Fab
          data-testid='movedown-fab'
          color='primary'
          size='small'
          onClick={() => props.moveHandle(props.dataId, 'down')}
        >
          <KeyboardArrowDown />
        </Fab>
      </Tooltip>
      {props.firstSegment ? null : (
        <Tooltip title='Move Up Target Segment'>
          <Fab
            data-testid='moveup-fab'
            color='primary'
            size='small'
            onClick={() => props.moveHandle(props.dataId, 'up')}
          >
            <KeyboardArrowUp />
          </Fab>
        </Tooltip>
      )}
      <Tooltip title='Delete Target Segment'>
        <Fab data-testid='delete-fab' name={props.dataId} color='primary' size='small' onClick={props.deleteHandle}>
          <Delete />
        </Fab>
      </Tooltip>
    </>
  );

AddDeleteFab.propTypes = {
  lastSegment: PropTypes.bool,
  firstSegment: PropTypes.bool,
  addHandle: PropTypes.func,
  deleteHandle: PropTypes.func,
  moveHandle: PropTypes.func,
  dataId: PropTypes.string,
};
