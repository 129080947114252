import React, { useContext, useMemo, useState } from 'react';

export enum Panels {
  INFO = 'info',
  TRANSCRIPT = 'transcript',
}

type PanelAreaContextProps = {
  panelAreaOpen: boolean;
  setPanelAreaOpen: (s: boolean) => void;
  selectedPanelAreaButton: Panels | null;
  setSelectedPanelAreaButton: (s: Panels | null) => void;
  selectedPanel: Panels;
  setSelectedPanel: (s: Panels) => void;
};

type PanelAreaProviderProps = {
  children: React.ReactNode;
};

export const PanelAreaContext = React.createContext<PanelAreaContextProps | null>(null);

export const PanelAreaProvider = ({ children }: PanelAreaProviderProps) => {
  const [isPanelAreaOpen, setIsPanelAreaOpen] = React.useState(false);
  const [selectedPanelAreaButton, setSelectedPanelAreaButton] = useState<Panels | null>(null);
  const [selectedPanel, setSelectedPanel] = useState<Panels>(Panels.INFO);

  const setPanelAreaOpen = (s: boolean) => {
    setIsPanelAreaOpen(s);
    // Need to remove the selected toggle button if the panel is closed.
    if (!s) {
      setSelectedPanelAreaButton(null);
    }
  };

  const value: PanelAreaContextProps = useMemo(
    () => ({
      panelAreaOpen: isPanelAreaOpen,
      setPanelAreaOpen,
      selectedPanelAreaButton,
      setSelectedPanelAreaButton,
      selectedPanel,
      setSelectedPanel,
    }),
    [isPanelAreaOpen, selectedPanelAreaButton, selectedPanel],
  );
  return <PanelAreaContext.Provider value={value}>{children}</PanelAreaContext.Provider>;
};

export const usePanelAreaContext = () => {
  const context = useContext(PanelAreaContext);
  if (!context) {
    throw new Error('usePanelAreaContext must be used within a PanelAreaProvider');
  }
  return context;
};
