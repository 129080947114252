import React from 'react';
import { styled } from '@mui/material/styles';

const Wrapper = styled('span')(({ theme }) => ({
  padding: theme.spacing(0, 0.5),
}));
const BoxWrapper = styled('div')(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  fontWeight: 400,
  lineHeight: 'normal',
  '> div': {
    input: {
      minWidth: '25px',
      color: theme.palette.text.primary,
    },
  },
}));

const FractionLine = styled('div')`
  border-bottom: 0.25rem solid;
`;

type BoxOverBoxProps = {
  Box1: JSX.Element;
  Box2: JSX.Element;
};

export const BoxOverBox = ({ Box1, Box2 }: BoxOverBoxProps) => (
  <Wrapper>
    <BoxWrapper>{Box1}</BoxWrapper>
    <FractionLine className='fraction-line' />
    <BoxWrapper>{Box2}</BoxWrapper>
  </Wrapper>
);
