import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Modal, Box, Table, TableRow, TableBody, TableCell, Tooltip } from '@mui/material';

import { Check, Error } from '@mui/icons-material';
import { validateLesson } from '../../controllers/lessons';
import { LessonValidation } from '../../controllers/types';

const styles = {
  validateModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
};

export const LessonValidate = ({
  lessonId,
  validateModalOpen,
  setValidateModalOpen,
}: {
  lessonId: number | null;
  validateModalOpen: boolean;
  setValidateModalOpen: (open: boolean) => void;
}) => {
  const [validateResults, setValidateResults] = useState<LessonValidation[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (!validateModalOpen || !lessonId) return;

    validateLesson(lessonId)
      .then(setValidateResults)
      .catch(error => {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  }, [enqueueSnackbar, lessonId, validateModalOpen]);

  return (
    <Modal
      data-testid='validate-modal'
      open={validateModalOpen}
      onClose={() => setValidateModalOpen(false)}
      aria-labelledby='modal-validate-lesson'
    >
      <Box sx={styles.validateModal}>
        <Table size='small'>
          <TableBody>
            {validateResults?.map(result => (
              <TableRow key={result.name}>
                <TableCell key='name'>{result.name}</TableCell>
                <TableCell key='result'>
                  {result.pass && <Check data-testid='check-icon' />}
                  {!result.pass && (
                    <Tooltip title={result.message}>
                      <Error data-testid='error-icon' />
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Modal>
  );
};
