import React from 'react';
import { Box, IconButton, ScopedCssBaseline, Stack, Typography } from '@mui/material';
import Close from '@mui/icons-material/Close';
import { usePanelAreaContext } from 'components/Player/PanelAreaContext';
import { ThemeProvider } from '@emotion/react';
import { getTheme } from 'components/theme/kyronTheme';

type PanelAreaProps = {
  title: string;
  Body: JSX.Element | null;
  Footer?: JSX.Element | null;
};

export const PanelAreaContainer = ({ title, Body, Footer }: PanelAreaProps) => {
  const { setPanelAreaOpen } = usePanelAreaContext();

  return (
    <ThemeProvider theme={getTheme('light')}>
      <ScopedCssBaseline enableColorScheme sx={{ height: '100%', bgcolor: 'transparent' }}>
        <Stack
          sx={{ bgcolor: 'surface.main', borderRadius: 2, height: '100%', overflow: 'hidden' }}
          data-testid='panel-area-container-box'
        >
          <Stack direction='row' spacing={1} justifyContent='space-between' alignItems='center' p={2}>
            <Typography variant='titleMedium'>{title}</Typography>
            <IconButton onClick={() => setPanelAreaOpen(false)} aria-label='Close Panel'>
              <Close fontSize='small' />
            </IconButton>
          </Stack>
          <Box sx={{ flex: 1, overflowY: 'auto', p: 2 }}>{Body}</Box>
          <Box>{Footer}</Box>
        </Stack>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
};
