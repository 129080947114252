// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`TutorSwitcher renders when all required props exist 1`] = `
<DocumentFragment>
  .emotion-0 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
}

<hr
    class="MuiDivider-root MuiDivider-fullWidth emotion-0"
  />
  .emotion-0 {
  --Grid-columns: 12;
  --Grid-columnSpacing: 8px;
  --Grid-rowSpacing: 8px;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  min-width: 0;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: calc(var(--Grid-rowSpacing) / -2) calc(var(--Grid-columnSpacing) / -2);
  margin: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.emotion-1 {
  min-width: 0;
  box-sizing: border-box;
  padding: calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-2 {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-family: Open Sans;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 8px;
}

.emotion-3 {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

.emotion-4 {
  margin: 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.emotion-5 {
  min-width: 0;
  box-sizing: border-box;
  padding: calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2);
}

.emotion-6 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  text-align: center;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-6::-moz-focus-inner {
  border-style: none;
}

.emotion-6.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-6 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-6:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-6:hover {
    background-color: transparent;
  }
}

.emotion-6.Mui-disabled {
  background-color: transparent;
  color: #1C1B1F61;
}

.emotion-7 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-8 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

<div
    class="MuiGrid2-root MuiGrid2-container MuiGrid2-direction-xs-row MuiGrid2-spacing-xs-1 emotion-0"
  >
    <div
      class="MuiGrid2-root MuiGrid2-direction-xs-row emotion-1"
    >
      <div
        class="MuiAvatar-root MuiAvatar-circular emotion-2"
      >
        <img
          alt="John Doe"
          class="MuiAvatar-img emotion-3"
          src="https://example.com/profile-picture.png"
        />
      </div>
      <span
        class="MuiTypography-root MuiTypography-labelLarge MuiTypography-noWrap emotion-4"
      >
        John Doe
      </span>
    </div>
    <div
      class="MuiGrid2-root MuiGrid2-direction-xs-row emotion-5"
    >
      <button
        aria-label="Change Tutor"
        class="MuiButtonBase-root MuiIconButton-root MuiIconButton-sizeMedium emotion-6"
        data-mui-internal-clone-element="true"
        data-testid="switch-tutor-button"
        tabindex="0"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-7"
          data-testid="SwapHorizIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path
            d="M6.99 11 3 15l3.99 4v-3H14v-2H6.99zM21 9l-3.99-4v3H10v2h7.01v3z"
          />
        </svg>
        <span
          class="MuiTouchRipple-root emotion-8"
        />
      </button>
    </div>
  </div>
</DocumentFragment>
`;

exports[`TutorSwitcher will not render without changeLesson fn 1`] = `<DocumentFragment />`;

exports[`TutorSwitcher will not render without more than one lesson 1`] = `<DocumentFragment />`;
