import React, { useState, ChangeEvent, FormEvent } from 'react';
import { CardContent, CardActions, TextField, Typography, IconButton } from '@mui/material';
import { Done, Cancel } from '@mui/icons-material';

import { Misconception } from 'controllers/types';

export function SingleMisconception({
  misconception,
  handleFormSubmit,
  handleFormCancel,
  edit,
}: {
  misconception?: Misconception;
  handleFormSubmit?: (misconception: Misconception) => void;
  handleFormCancel?: () => void;
  edit: boolean;
}) {
  const [newMisconception, setNewMisconception] = useState(
    misconception
      ? {
          id: misconception.id,
          name: misconception.name,
          code: misconception.code,
          description: misconception.description,
          lesson_id: misconception.lesson_id,
        }
      : {
          id: 0,
          name: '',
          code: '',
          description: '',
          lesson_id: 0,
        },
  );

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    setNewMisconception(prevMisconception => ({
      ...prevMisconception,
      [e.target.name]: e.target.value,
    }));
  }

  return (
    <>
      {edit && (
        <CardContent>
          <form
            data-testid='misconception-form'
            onSubmit={(e: FormEvent) => {
              e.preventDefault();
              if (handleFormSubmit !== undefined) handleFormSubmit(newMisconception);
              if (handleFormCancel !== undefined) handleFormCancel();
              setNewMisconception({
                id: 0,
                name: '',
                code: '',
                description: '',
                lesson_id: 0,
              });
            }}
          >
            <TextField
              data-testid='misconception-name'
              variant='outlined'
              name='name'
              fullWidth
              margin='normal'
              label='Misconception Name'
              type='text'
              onChange={handleInputChange}
              defaultValue={newMisconception.name}
              required
              helperText='Enter the name of the misconception'
            />
            <TextField
              data-testid='misconception-code'
              variant='outlined'
              name='code'
              fullWidth
              margin='normal'
              label='Misconception Code'
              type='text'
              onChange={handleInputChange}
              defaultValue={newMisconception.code}
              required
              helperText='Enter the misconception code'
            />
            <TextField
              data-testid='misconception-description'
              variant='outlined'
              name='description'
              fullWidth
              margin='normal'
              label='Description'
              type='text'
              onChange={handleInputChange}
              defaultValue={newMisconception.description}
              required
              helperText='Enter a description'
            />
            <CardActions>
              <IconButton aria-label='submit misconception' type='submit'>
                <Done />
              </IconButton>
              <IconButton aria-label='cancel edit misconception' onClick={handleFormCancel}>
                <Cancel />
              </IconButton>
            </CardActions>
          </form>
        </CardContent>
      )}
      {!edit && (
        <CardContent>
          <Typography data-testid='misconception-name' variant='headlineMedium' color='text.secondary'>
            {newMisconception.name}
          </Typography>
          <Typography data-testid='misconception-code' variant='bodySmall' color='text.secondary'>
            Code: {newMisconception.code}
          </Typography>
          <Typography data-testid='misconception-description' variant='bodySmall' color='text.secondary'>
            {newMisconception.description}
          </Typography>
        </CardContent>
      )}
    </>
  );
}
