import React from 'react';
import { SegmentMedia } from 'controllers/types';
import { LoadingIndicator } from '../LoadingIndicator';

export const isUsingSegmentDescription = (mediaSelection: string | null) =>
  mediaSelection === SegmentMedia.KYRON_VIDEO ||
  mediaSelection === SegmentMedia.USER_IMAGE_WITH_AUDIO ||
  mediaSelection === SegmentMedia.USER_IMAGE_NO_AVATAR;

export const getTooltipNote = (
  mediaSelection: SegmentMedia | null,
  isPending: boolean,
  providesVideoDescription: boolean,
) => {
  if (isPending) return <LoadingIndicator message='Running validations on the input' />;
  if (providesVideoDescription && !isUsingSegmentDescription(mediaSelection)) {
    return 'Unavailable when using custom media';
  }
};
