import { MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

interface Size {
  width: number;
  height: number;
}

export function useMediaContainerResize<T extends HTMLElement = HTMLDivElement>(): [MutableRefObject<T | null>, Size] {
  const target = useRef<T | null>(null);
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  const setRoundedSize = ({ width, height }: Size) => {
    setSize({ width: Math.round(width), height: Math.round(height) });
  };

  // Leveraging useLayoutEffect as this would run synchronously immediately after React has performed all DOM mutations.
  // This is important as we need to get the size of the containter as soon as it is rendered.
  useLayoutEffect(() => {
    if (target.current) {
      setRoundedSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, entry => {
    const { inlineSize: width, blockSize: height } = entry.contentBoxSize[0];
    setRoundedSize({ width, height });
  });

  return [target, size];
}
