import { Button, Stack, TextField } from '@mui/material';
import { Row } from 'components/Row/Row';
import { ActiveStorageBlob } from 'components/Uploaders/types';
import { Organization, OrganizationPayload } from 'controllers/types';
import React from 'react';
import { ImageInput } from './ImageInput';

function getFormOrganization(organization?: Organization): OrganizationPayload {
  return {
    name: organization?.name || '',
    slug: organization?.slug || '',
    channel_attributes: {
      name: organization?.channel?.name || '',
      description: organization?.channel?.description || '',
    },
  };
}

type SettingsFormProps = {
  organization?: Organization;
  onSubmit: (organization: OrganizationPayload) => void;
};

export function SettingsForm({ organization, onSubmit }: SettingsFormProps) {
  const [formOrganization, setFormOrganization] = React.useState(getFormOrganization(organization));

  const orgImageUrl = organization?.logo;
  const [orgImage, setOrgImage] = React.useState<ActiveStorageBlob | null>(orgImageUrl ? { url: orgImageUrl } : null);

  // TODO: Marketplace and related features (including channels) are in limbo for now.
  // If we decide to bring them back, we can uncomment the following code and make necessary changes.
  // const channelImageUrl = organization?.channel?.image_url;
  // const [channelImage, setChannelImage] = React.useState<ActiveStorageBlob | null>(
  //   channelImageUrl ? { url: channelImageUrl } : null,
  // );

  // const updateFormChannel = ({ name, value }: { name: string; value: string }) => {
  //   setFormOrganization(prevFormOrganization => ({
  //     ...prevFormOrganization,
  //     channel_attributes: {
  //       ...prevFormOrganization.channel_attributes,
  //       [name]: value,
  //     },
  //   }));
  // };

  const generateSlug = (name: string) => name.toLowerCase().replace(/[^a-z0-9-]+/g, '-');

  const updateFormOrganization = ({ name, value }: { name: string; value: string }) => {
    setFormOrganization(prevFormOrganization => ({
      ...prevFormOrganization,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(formOrganization);
  };

  return (
    <form data-testid='settings-form' onSubmit={handleSubmit}>
      <Stack gap={2}>
        <TextField
          data-testid='organization-name'
          name='name'
          label='Organization Name'
          value={formOrganization.name}
          onChange={e => {
            updateFormOrganization(e.target);
            updateFormOrganization({ name: 'slug', value: generateSlug(e.target.value) });
          }}
          fullWidth
          margin='normal'
          type='text'
          inputProps={{ 'data-testid': 'organization-name-input' }}
          required
        />

        {/*
        // TODO: Currently, we are not allowing users to change the organization slug. If we decide to allow it,
        // we can uncomment the following code and make necessary changes.
        <TextField
          data-testid='organization-slug'
          name='slug'
          label='Organization Handle'
          value={formOrganization.slug}
          onChange={e => updateFormOrganization(e.target)}
          fullWidth
          margin='normal'
          type='text'
          inputProps={{ 'data-testid': 'organization-slug-input', pattern: '^[a-z0-9]+(?:-[a-z0-9]+)*$' }}
          required
        /> */}

        <ImageInput
          image={orgImage}
          setImage={setOrgImage}
          setFormImage={value => updateFormOrganization({ name: 'logo_image', value })}
        />

        {/*
        // TODO: Marketplace and related features (including channels) are in limbo for now.
        // If we decide to bring them back, we can uncomment the following code and make necessary changes.
        <Typography variant='headlineSmall' mt={3}>
          Channel Settings
        </Typography>

        <TextField
          data-testid='channel-name'
          name='name'
          label='Channel Name'
          value={formOrganization.channel_attributes?.name}
          onChange={e => updateFormChannel(e.target)}
          fullWidth
          margin='normal'
          type='text'
          inputProps={{ 'data-testid': 'channel-name-input' }}
          required
        />

        <TextField
          data-testid='channel-slug'
          name='slug'
          label='Channel Handle'
          value={formOrganization.channel_attributes?.slug}
          onChange={e => updateFormChannel(e.target)}
          fullWidth
          margin='normal'
          type='text'
          inputProps={{ 'data-testid': 'channel-slug-input', pattern: '^[a-z0-9]+(?:-[a-z0-9]+)*$' }}
          required
        />

        <TextField
          data-testid='channel-description'
          name='description'
          label='Channel Description'
          value={formOrganization.channel_attributes?.description}
          onChange={e => updateFormChannel(e.target)}
          fullWidth
          margin='normal'
          type='text'
          inputProps={{ 'data-testid': 'channel-description-input' }}
        />

        <ImageInput
          image={channelImage}
          setImage={setChannelImage}
          setFormImage={value => updateFormChannel({ name: 'image', value })}
        /> */}

        <Row gap={1}>
          <Button type='submit'>Save</Button>
        </Row>
      </Stack>
    </form>
  );
}
