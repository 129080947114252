import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { CreateLessonForm, Props as CreateLessonFormProps } from './CreateLessonForm';

type CreateLessonDialogProps = {
  onClose: () => void;
  dialogTitle: string;
  initialFormValues: CreateLessonFormProps['initialFormValues'];
};

export function CreateLessonDialog({ onClose, dialogTitle, initialFormValues }: CreateLessonDialogProps) {
  return (
    <Dialog open onClose={onClose} fullWidth maxWidth='md'>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <CreateLessonForm
          onCancel={onClose}
          hideAuxContentUpload
          hideLuckyButton
          initialFormValues={initialFormValues}
        />
      </DialogContent>
    </Dialog>
  );
}
