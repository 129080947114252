import { Box, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React from 'react';
import { KyronTooltip } from '../../KyronTooltip';

export const AuxContentIngestionDetailTooltip = () => (
  <KyronTooltip
    htmlContent
    placement='top'
    title={
      <Box>
        <Typography variant='labelMedium'>Do I need to upload materials?</Typography>
        <Typography variant='bodySmall'>
          No. Simply explaining your audience and objectives is sufficient for generating quality courses. However,
          uploading materials that reflect your style and approach can enhance the course&apos;s relevance.
        </Typography>

        <Typography variant='labelMedium'>What happens when I upload materials?</Typography>
        <Typography variant='bodySmall'>
          Our course generator will generate lessons that are more aligned with your teaching style, incorporating
          specific vocabulary and examples from your materials. Without uploaded content, our course generator relies on
          general subject knowledge to determine vocabulary, examples, and teaching approaches.
        </Typography>

        <Typography variant='labelMedium'>How do we handle uploaded content?</Typography>
        <Typography variant='bodySmall'>
          We analyze your materials&apos; terminology, specific content, and teaching approaches to create courses
          tailored to your materials&apos; context. Your materials are only used to customize course generation.
        </Typography>

        <Typography variant='labelMedium'>Recommended Materials</Typography>
        <Typography variant='bodySmall'>
          To improve course relevance, consider uploading text-based teaching materials that are relevant to the course
          objectives. The best type of files to upload are:
          <ul>
            <li>Educational or professional development textbook chapters</li>
            <li>Lecture notes</li>
            <li>Summary sheets</li>
            <li>Handouts</li>
            <li>Reading passages</li>
            <li>Study guides</li>
            <li>Case studies</li>
          </ul>
          (Files should be in either PDF, DOCX, or TXT format.)
        </Typography>
        <Typography variant='labelMedium'>Important Guidelines:</Typography>
        <Typography variant='bodySmall'>
          <ul>
            <li>
              <Typography variant='labelMedium'>
                Upload only materials that directly relate to your course objectives
              </Typography>{' '}
              Upload only relevant content - irrelevant materials may reduce the quality of generated courses.
            </li>
            <li>
              <Typography variant='labelMedium'>Use primarily text-based materials</Typography> Our system cannot yet
              process image content effectively. Ensure your key information is in text format.
            </li>
          </ul>
        </Typography>
      </Box>
    }
  >
    <HelpOutlineIcon />
  </KyronTooltip>
);
