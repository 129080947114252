// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`StudentDashboard renders error state for lesson instances 1`] = `
<DocumentFragment>
  .emotion-0 {
  padding: 24px;
}

.emotion-1 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.emotion-1>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-1>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-2 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:900px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:1200px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:1536px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

.emotion-3 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  padding: 24px;
  text-align: center;
  color: #1C1B1FB3;
  background-color: #F1ECF4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: auto;
}

.emotion-3:hover {
  background-color: #F3DBF8;
}

.emotion-4 {
  padding: 16px;
}

.emotion-4:last-child {
  padding-bottom: 24px;
}

.emotion-6 {
  margin: 0;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}

.emotion-7 {
  margin: 0;
}

.emotion-26 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
  margin-top: 32px;
  margin-bottom: 32px;
}

.emotion-27 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 32px;
}

.emotion-28 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.emotion-29 {
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;
}

.emotion-30 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 200px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  width: 144px;
  height: 144px;
  background-color: #F1ECF4;
}

.emotion-33 {
  margin-top: 8px;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-3"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-6"
                >
                  2
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  Lessons Started
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-3"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-6"
                >
                  1
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  Lessons Completed
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-3"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-6"
                >
                  50
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  Lesson Completion Rate
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-3"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-6"
                >
                  2
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  Unique Lessons Attempted
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr
      class="MuiDivider-root MuiDivider-fullWidth emotion-26"
    />
    <div
      class="MuiGrid-root MuiGrid-container emotion-27"
    >
      <h2
        class="MuiTypography-root MuiTypography-titleLarge emotion-28"
      >
         Lesson Instance History 
      </h2>
    </div>
    <div
      class="MuiBox-root emotion-29"
    >
      <div
        class="MuiStack-root emotion-30"
      >
        <div
          class="MuiStack-root emotion-31"
        >
          <img
            alt=""
            src="SvgrURL"
            width="167"
          />
        </div>
        <span
          class="MuiTypography-root MuiTypography-body1 emotion-7"
        >
          There was an error fetching the student lesson instances.
        </span>
        <div
          class="MuiBox-root emotion-33"
        />
      </div>
    </div>
  </div>
</DocumentFragment>
`;

exports[`StudentDashboard renders error state for overall analytics 1`] = `
<DocumentFragment>
  .emotion-0 {
  padding: 24px;
}

.emotion-1 {
  width: 100%;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 48px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 200px;
  gap: 16px;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  width: 144px;
  height: 144px;
  background-color: #F1ECF4;
}

.emotion-4 {
  margin: 0;
}

.emotion-5 {
  margin-top: 8px;
}

.emotion-6 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
  margin-top: 32px;
  margin-bottom: 32px;
}

.emotion-7 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 32px;
}

.emotion-8 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.emotion-9 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 32px;
}

.emotion-9>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-9>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-10 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-10 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-10 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

@media (min-width:1200px) {
  .emotion-10 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

@media (min-width:1536px) {
  .emotion-10 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

.emotion-11 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.emotion-11.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-11:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-11:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-11.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-11.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-11.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-12 {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 8px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.emotion-12:focus {
  border-radius: 8px;
}

.emotion-12::-ms-expand {
  display: none;
}

.emotion-12.Mui-disabled {
  cursor: default;
}

.emotion-12[multiple] {
  height: auto;
}

.emotion-12:not([multiple]) option,
.emotion-12:not([multiple]) optgroup {
  background-color: #FCFAFD;
}

.emotion-12.emotion-12.emotion-12 {
  padding-right: 32px;
}

.emotion-12.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.emotion-12::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-12::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-12:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-12::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-12:focus {
  outline: 0;
}

.emotion-12:invalid {
  box-shadow: none;
}

.emotion-12::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-12::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-12::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-12:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-12::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-12:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-12:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-12:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-12:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-12.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-12:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-12:-webkit-autofill {
  border-radius: inherit;
}

.emotion-13 {
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
}

.emotion-14 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 7px;
  top: calc(50% - .5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}

.emotion-14.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-15 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-16 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-17 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (min-width:600px) {
  .emotion-17 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-17 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1200px) {
  .emotion-17 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-17 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

.emotion-18 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin-left: -11px;
  margin-right: 16px;
}

.emotion-18.Mui-disabled {
  cursor: default;
}

.emotion-18 .MuiFormControlLabel-label.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-19 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  padding: 9px;
  border-radius: 50%;
  color: #1C1B1FB3;
}

.emotion-19::-moz-focus-inner {
  border-style: none;
}

.emotion-19.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-19 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-19:hover {
  background-color: rgba(75, 53, 255, 0.04);
}

@media (hover: none) {
  .emotion-19:hover {
    background-color: transparent;
  }
}

.emotion-19.Mui-checked,
.emotion-19.MuiCheckbox-indeterminate {
  color: #4B35FF;
}

.emotion-19.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-20 {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.emotion-21 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-22 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-24 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.emotion-24>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-24>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-26 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  padding: 16px;
  color: #1C1B1FB3;
  background-color: #C2ECC1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
}

.emotion-27 {
  padding: 16px;
}

.emotion-27:last-child {
  padding-bottom: 24px;
}

.emotion-29 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
}

.emotion-30 {
  height: 10px;
}

.emotion-31 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
}

.emotion-33 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-38 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  padding: 16px;
  color: #1C1B1FB3;
  background-color: #F1ECF4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
}

.emotion-49 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 32px;
}

.emotion-50 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  bottom: 0;
}

.emotion-51 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.emotion-52 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  min-width: 32px;
  height: 32px;
  padding: 0 6px;
  margin: 0 3px;
  color: #1C1B1F;
  -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-52::-moz-focus-inner {
  border-style: none;
}

.emotion-52.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-52 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-52.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-52.Mui-disabled {
  opacity: 0.38;
}

.emotion-52:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-52:hover {
    background-color: transparent;
  }
}

.emotion-52.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-52.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

@media (hover: none) {
  .emotion-52.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.emotion-52.Mui-selected.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.2);
}

.emotion-52.Mui-selected.Mui-disabled {
  opacity: 1;
  color: #1C1B1F61;
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-52.Mui-selected.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-53 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 1.25rem;
  margin: 0 -8px;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiBox-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <div
          class="MuiStack-root emotion-3"
        >
          <img
            alt=""
            src="SvgrURL"
            width="167"
          />
        </div>
        <span
          class="MuiTypography-root MuiTypography-body1 emotion-4"
        >
          There was an error fetching the student lesson instances.
        </span>
        <div
          class="MuiBox-root emotion-5"
        />
      </div>
    </div>
    <hr
      class="MuiDivider-root MuiDivider-fullWidth emotion-6"
    />
    <div
      class="MuiGrid-root MuiGrid-container emotion-7"
    >
      <h2
        class="MuiTypography-root MuiTypography-titleLarge emotion-8"
      >
         Lesson Instance History 
      </h2>
    </div>
    <div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-9"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-8 emotion-10"
        >
          <div
            class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth emotion-11"
          >
            <div
              aria-controls=":r1:"
              aria-expanded="false"
              aria-haspopup="listbox"
              class="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input emotion-12"
              role="combobox"
              tabindex="0"
            >
              All lessons
            </div>
            <input
              aria-hidden="true"
              aria-invalid="false"
              class="MuiSelect-nativeInput emotion-13"
              tabindex="-1"
              value=""
            />
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined emotion-14"
              data-testid="ArrowDropDownIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M7 10l5 5 5-5z"
              />
            </svg>
            <fieldset
              aria-hidden="true"
              class="MuiOutlinedInput-notchedOutline emotion-15"
            >
              <legend
                class="emotion-16"
              >
                <span
                  class="notranslate"
                >
                  ​
                </span>
              </legend>
            </fieldset>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-17"
        >
          <label
            class="MuiFormControlLabel-root MuiFormControlLabel-labelPlacementEnd emotion-18"
          >
            <span
              class="MuiButtonBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium PrivateSwitchBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium emotion-19"
            >
              <input
                class="PrivateSwitchBase-input emotion-20"
                data-indeterminate="false"
                type="checkbox"
              />
              <svg
                aria-hidden="true"
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-21"
                data-testid="CheckBoxOutlineBlankIcon"
                focusable="false"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
                />
              </svg>
              <span
                class="MuiTouchRipple-root emotion-22"
              />
            </span>
            <span
              class="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label emotion-4"
            >
              Show Only Completed Lessons
            </span>
          </label>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-24"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-17"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-26"
            data-testid="student-dashboard-card"
          >
            <div
              class="MuiCardContent-root emotion-27"
            >
              <div
                class="MuiBox-root emotion-28"
              >
                <h2
                  class="MuiTypography-root MuiTypography-titleLarge MuiTypography-alignCenter emotion-29"
                >
                  Test Lesson 1
                </h2>
                <div
                  class="MuiBox-root emotion-30"
                />
                <div
                  class="MuiBox-root emotion-31"
                >
                  <p
                    class="MuiTypography-root MuiTypography-body1 emotion-4"
                  >
                    <b>
                       Completed: 
                    </b>
                  </p>
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-33"
                    data-testid="CheckIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                    />
                  </svg>
                </div>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-4"
                >
                  <b>
                    Start Date: 
                  </b>
                   01/01/2024
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-4"
                >
                  <b>
                    Misconception Count: 
                  </b>
                   0
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-4"
                >
                  <b>
                    Misconceptions: 
                  </b>
                  N/A
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-17"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-38"
            data-testid="student-dashboard-card"
          >
            <div
              class="MuiCardContent-root emotion-27"
            >
              <div
                class="MuiBox-root emotion-28"
              >
                <h2
                  class="MuiTypography-root MuiTypography-titleLarge MuiTypography-alignCenter emotion-29"
                >
                  Test Lesson 2
                </h2>
                <div
                  class="MuiBox-root emotion-30"
                />
                <div
                  class="MuiBox-root emotion-31"
                >
                  <p
                    class="MuiTypography-root MuiTypography-body1 emotion-4"
                  >
                    <b>
                       Completed: 
                    </b>
                  </p>
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-33"
                    data-testid="CloseIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                    />
                  </svg>
                </div>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-4"
                >
                  <b>
                    Start Date: 
                  </b>
                   01/02/2024
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-4"
                >
                  <b>
                    Misconception Count: 
                  </b>
                   1
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-4"
                >
                  <b>
                    Misconceptions: 
                  </b>
                  <i />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-container emotion-49"
      >
        <nav
          aria-label="pagination navigation"
          class="MuiPagination-root MuiPagination-text emotion-50"
        >
          <ul
            class="MuiPagination-ul emotion-51"
          >
            <li>
              <button
                aria-label="Go to previous page"
                class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-52"
                disabled=""
                tabindex="-1"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-53"
                  data-testid="NavigateBeforeIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
            </li>
            <li>
              <button
                aria-current="true"
                aria-label="page 1"
                class="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-selected MuiPaginationItem-page emotion-52"
                tabindex="0"
                type="button"
              >
                1
                <span
                  class="MuiTouchRipple-root emotion-22"
                />
              </button>
            </li>
            <li>
              <button
                aria-label="Go to next page"
                class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-52"
                disabled=""
                tabindex="-1"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-53"
                  data-testid="NavigateNextIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</DocumentFragment>
`;

exports[`StudentDashboard renders loading state for overall analytics and lesson instances 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.emotion-0 {
  padding: 24px;
}

.emotion-1 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.emotion-1>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-1>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-2 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:900px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:1200px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:1536px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

.emotion-3 {
  display: block;
  background-color: rgba(28, 27, 31, 0.11);
  height: 1.2em;
  border-radius: 8px;
  -webkit-animation: animation-0 2s ease-in-out 0.5s infinite;
  animation: animation-0 2s ease-in-out 0.5s infinite;
}

.emotion-10 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
  margin-top: 32px;
  margin-bottom: 32px;
}

.emotion-11 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 32px;
}

.emotion-12 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.emotion-14 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (min-width:600px) {
  .emotion-14 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-14 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1200px) {
  .emotion-14 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-14 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

<div
    class="MuiBox-root emotion-0"
  >
    <div
      class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
    >
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
      <div
        class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
      >
        <span
          class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
          style="width: 100%; height: 150px;"
        />
      </div>
    </div>
    <hr
      class="MuiDivider-root MuiDivider-fullWidth emotion-10"
    />
    <div
      class="MuiGrid-root MuiGrid-container emotion-11"
    >
      <h2
        class="MuiTypography-root MuiTypography-titleLarge emotion-12"
      >
         Lesson Instance History 
      </h2>
    </div>
    <div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-14"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
            style="width: 100%; height: 250px;"
          />
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-14"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
            style="width: 100%; height: 250px;"
          />
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-14"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
            style="width: 100%; height: 250px;"
          />
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-14"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
            style="width: 100%; height: 250px;"
          />
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-14"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
            style="width: 100%; height: 250px;"
          />
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-14"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
            style="width: 100%; height: 250px;"
          />
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-14"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
            style="width: 100%; height: 250px;"
          />
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-14"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
            style="width: 100%; height: 250px;"
          />
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-14"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rounded MuiSkeleton-pulse emotion-3"
            style="width: 100%; height: 250px;"
          />
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;

exports[`StudentDashboard renders student dashboard data 1`] = `
<DocumentFragment>
  .emotion-0 {
  padding: 24px;
}

.emotion-1 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
}

.emotion-1>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-1>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-2 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:900px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:1200px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width:1536px) {
  .emotion-2 {
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

.emotion-3 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  padding: 24px;
  text-align: center;
  color: #1C1B1FB3;
  background-color: #F1ECF4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: auto;
}

.emotion-3:hover {
  background-color: #F3DBF8;
}

.emotion-4 {
  padding: 16px;
}

.emotion-4:last-child {
  padding-bottom: 24px;
}

.emotion-6 {
  margin: 0;
  font-size: 4rem;
  font-weight: bold;
  margin-bottom: 1.25rem;
}

.emotion-7 {
  margin: 0;
}

.emotion-26 {
  margin: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: #D6D5DD;
  border-bottom-width: thin;
  margin-top: 32px;
  margin-bottom: 32px;
}

.emotion-27 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin: 32px;
}

.emotion-28 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
}

.emotion-29 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 32px;
}

.emotion-29>.MuiGrid-item {
  padding-top: 16px;
}

.emotion-29>.MuiGrid-item {
  padding-left: 16px;
}

.emotion-30 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  max-width: 100%;
}

@media (min-width:600px) {
  .emotion-30 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-30 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

@media (min-width:1200px) {
  .emotion-30 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

@media (min-width:1536px) {
  .emotion-30 {
    -webkit-flex-basis: 66.666667%;
    -ms-flex-preferred-size: 66.666667%;
    flex-basis: 66.666667%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 66.666667%;
  }
}

.emotion-31 {
  color: #1C1B1F;
  line-height: 1.4375em;
  box-sizing: border-box;
  position: relative;
  cursor: text;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.emotion-31.Mui-disabled {
  color: #1C1B1F61;
  cursor: default;
}

.emotion-31:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F;
}

@media (hover: none) {
  .emotion-31:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0, 0, 0, 0.23);
  }
}

.emotion-31.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #4B35FF;
  border-width: 2px;
}

.emotion-31.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #BA1A1A;
}

.emotion-31.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: #1C1B1F61;
}

.emotion-32 {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 8px;
  cursor: pointer;
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 16.5px 14px;
}

.emotion-32:focus {
  border-radius: 8px;
}

.emotion-32::-ms-expand {
  display: none;
}

.emotion-32.Mui-disabled {
  cursor: default;
}

.emotion-32[multiple] {
  height: auto;
}

.emotion-32:not([multiple]) option,
.emotion-32:not([multiple]) optgroup {
  background-color: #FCFAFD;
}

.emotion-32.emotion-32.emotion-32 {
  padding-right: 32px;
}

.emotion-32.MuiSelect-select {
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.emotion-32::-webkit-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-32::-moz-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-32:-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-32::-ms-input-placeholder {
  color: currentColor;
  opacity: 0.42;
  -webkit-transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-32:focus {
  outline: 0;
}

.emotion-32:invalid {
  box-shadow: none;
}

.emotion-32::-webkit-search-decoration {
  -webkit-appearance: none;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-32::-webkit-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-32::-moz-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-32:-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-32::-ms-input-placeholder {
  opacity: 0!important;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-32:focus::-webkit-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-32:focus::-moz-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-32:focus:-ms-input-placeholder {
  opacity: 0.42;
}

label[data-shrink=false]+.MuiInputBase-formControl .emotion-32:focus::-ms-input-placeholder {
  opacity: 0.42;
}

.emotion-32.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: #1C1B1F61;
}

.emotion-32:-webkit-autofill {
  -webkit-animation-duration: 5000s;
  animation-duration: 5000s;
  -webkit-animation-name: mui-auto-fill;
  animation-name: mui-auto-fill;
}

.emotion-32:-webkit-autofill {
  border-radius: inherit;
}

.emotion-33 {
  bottom: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  width: 100%;
  box-sizing: border-box;
}

.emotion-34 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  position: absolute;
  right: 7px;
  top: calc(50% - .5em);
  pointer-events: none;
  color: rgba(0, 0, 0, 0.54);
}

.emotion-34.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-35 {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgba(0, 0, 0, 0.23);
}

.emotion-36 {
  float: unset;
  width: auto;
  overflow: hidden;
  padding: 0;
  line-height: 11px;
  -webkit-transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transition: width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.emotion-37 {
  box-sizing: border-box;
  margin: 0;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
}

@media (min-width:600px) {
  .emotion-37 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
}

@media (min-width:900px) {
  .emotion-37 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1200px) {
  .emotion-37 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

@media (min-width:1536px) {
  .emotion-37 {
    -webkit-flex-basis: 33.333333%;
    -ms-flex-preferred-size: 33.333333%;
    flex-basis: 33.333333%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 33.333333%;
  }
}

.emotion-38 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  margin-left: -11px;
  margin-right: 16px;
}

.emotion-38.Mui-disabled {
  cursor: default;
}

.emotion-38 .MuiFormControlLabel-label.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-39 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  padding: 9px;
  border-radius: 50%;
  color: #1C1B1FB3;
}

.emotion-39::-moz-focus-inner {
  border-style: none;
}

.emotion-39.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-39 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-39:hover {
  background-color: rgba(75, 53, 255, 0.04);
}

@media (hover: none) {
  .emotion-39:hover {
    background-color: transparent;
  }
}

.emotion-39.Mui-checked,
.emotion-39.MuiCheckbox-indeterminate {
  color: #4B35FF;
}

.emotion-39.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-40 {
  cursor: inherit;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.emotion-41 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.emotion-42 {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit;
}

.emotion-46 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  padding: 16px;
  color: #1C1B1FB3;
  background-color: #C2ECC1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
}

.emotion-49 {
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 26px;
  text-align: center;
}

.emotion-50 {
  height: 10px;
}

.emotion-51 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 4px;
}

.emotion-53 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.25rem;
}

.emotion-58 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  padding: 16px;
  color: #1C1B1FB3;
  background-color: #F1ECF4;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  width: auto;
  height: auto;
}

.emotion-69 {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 32px;
}

.emotion-70 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  bottom: 0;
}

.emotion-71 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.emotion-72 {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  min-width: 32px;
  height: 32px;
  padding: 0 6px;
  margin: 0 3px;
  color: #1C1B1F;
  -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.emotion-72::-moz-focus-inner {
  border-style: none;
}

.emotion-72.Mui-disabled {
  pointer-events: none;
  cursor: default;
}

@media print {
  .emotion-72 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-72.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.12);
}

.emotion-72.Mui-disabled {
  opacity: 0.38;
}

.emotion-72:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .emotion-72:hover {
    background-color: transparent;
  }
}

.emotion-72.Mui-selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-72.Mui-selected:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

@media (hover: none) {
  .emotion-72.Mui-selected:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
}

.emotion-72.Mui-selected.Mui-focusVisible {
  background-color: rgba(0, 0, 0, 0.2);
}

.emotion-72.Mui-selected.Mui-disabled {
  opacity: 1;
  color: #1C1B1F61;
  background-color: rgba(0, 0, 0, 0.08);
}

.emotion-72.Mui-selected.Mui-disabled {
  color: #1C1B1F61;
}

.emotion-73 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
  font-size: 1.25rem;
  margin: 0 -8px;
}

<div
    class="MuiBox-root emotion-0"
  >
    <div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-3"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-6"
                >
                  2
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  Lessons Started
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-3"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-6"
                >
                  1
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  Lessons Completed
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-3"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-6"
                >
                  50
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  Lesson Completion Rate
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 emotion-2"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-3"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-6"
                >
                  2
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  Unique Lessons Attempted
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr
      class="MuiDivider-root MuiDivider-fullWidth emotion-26"
    />
    <div
      class="MuiGrid-root MuiGrid-container emotion-27"
    >
      <h2
        class="MuiTypography-root MuiTypography-titleLarge emotion-28"
      >
         Lesson Instance History 
      </h2>
    </div>
    <div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-29"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-md-8 emotion-30"
        >
          <div
            class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary MuiInputBase-fullWidth emotion-31"
          >
            <div
              aria-controls=":r4:"
              aria-expanded="false"
              aria-haspopup="listbox"
              class="MuiSelect-select MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input emotion-32"
              role="combobox"
              tabindex="0"
            >
              All lessons
            </div>
            <input
              aria-hidden="true"
              aria-invalid="false"
              class="MuiSelect-nativeInput emotion-33"
              tabindex="-1"
              value=""
            />
            <svg
              aria-hidden="true"
              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined emotion-34"
              data-testid="ArrowDropDownIcon"
              focusable="false"
              viewBox="0 0 24 24"
            >
              <path
                d="M7 10l5 5 5-5z"
              />
            </svg>
            <fieldset
              aria-hidden="true"
              class="MuiOutlinedInput-notchedOutline emotion-35"
            >
              <legend
                class="emotion-36"
              >
                <span
                  class="notranslate"
                >
                  ​
                </span>
              </legend>
            </fieldset>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-37"
        >
          <label
            class="MuiFormControlLabel-root MuiFormControlLabel-labelPlacementEnd emotion-38"
          >
            <span
              class="MuiButtonBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium PrivateSwitchBase-root MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium MuiCheckbox-root MuiCheckbox-colorPrimary MuiCheckbox-sizeMedium emotion-39"
            >
              <input
                class="PrivateSwitchBase-input emotion-40"
                data-indeterminate="false"
                type="checkbox"
              />
              <svg
                aria-hidden="true"
                class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium emotion-41"
                data-testid="CheckBoxOutlineBlankIcon"
                focusable="false"
                viewBox="0 0 24 24"
              >
                <path
                  d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
                />
              </svg>
              <span
                class="MuiTouchRipple-root emotion-42"
              />
            </span>
            <span
              class="MuiTypography-root MuiTypography-body1 MuiFormControlLabel-label emotion-7"
            >
              Show Only Completed Lessons
            </span>
          </label>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 emotion-1"
      >
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-37"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-46"
            data-testid="student-dashboard-card"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <h2
                  class="MuiTypography-root MuiTypography-titleLarge MuiTypography-alignCenter emotion-49"
                >
                  Test Lesson 1
                </h2>
                <div
                  class="MuiBox-root emotion-50"
                />
                <div
                  class="MuiBox-root emotion-51"
                >
                  <p
                    class="MuiTypography-root MuiTypography-body1 emotion-7"
                  >
                    <b>
                       Completed: 
                    </b>
                  </p>
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-53"
                    data-testid="CheckIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                    />
                  </svg>
                </div>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  <b>
                    Start Date: 
                  </b>
                   01/01/2024
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  <b>
                    Misconception Count: 
                  </b>
                   0
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  <b>
                    Misconceptions: 
                  </b>
                  N/A
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="MuiGrid-root MuiGrid-item MuiGrid-grid-sm-12 MuiGrid-grid-md-4 emotion-37"
        >
          <div
            class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-58"
            data-testid="student-dashboard-card"
          >
            <div
              class="MuiCardContent-root emotion-4"
            >
              <div
                class="MuiBox-root emotion-5"
              >
                <h2
                  class="MuiTypography-root MuiTypography-titleLarge MuiTypography-alignCenter emotion-49"
                >
                  Test Lesson 2
                </h2>
                <div
                  class="MuiBox-root emotion-50"
                />
                <div
                  class="MuiBox-root emotion-51"
                >
                  <p
                    class="MuiTypography-root MuiTypography-body1 emotion-7"
                  >
                    <b>
                       Completed: 
                    </b>
                  </p>
                  <svg
                    aria-hidden="true"
                    class="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall emotion-53"
                    data-testid="CloseIcon"
                    focusable="false"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                    />
                  </svg>
                </div>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  <b>
                    Start Date: 
                  </b>
                   01/02/2024
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  <b>
                    Misconception Count: 
                  </b>
                   1
                </p>
                <p
                  class="MuiTypography-root MuiTypography-body1 emotion-7"
                >
                  <b>
                    Misconceptions: 
                  </b>
                  <i />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="MuiGrid-root MuiGrid-container emotion-69"
      >
        <nav
          aria-label="pagination navigation"
          class="MuiPagination-root MuiPagination-text emotion-70"
        >
          <ul
            class="MuiPagination-ul emotion-71"
          >
            <li>
              <button
                aria-label="Go to previous page"
                class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-72"
                disabled=""
                tabindex="-1"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-73"
                  data-testid="NavigateBeforeIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"
                  />
                </svg>
              </button>
            </li>
            <li>
              <button
                aria-current="true"
                aria-label="page 1"
                class="MuiButtonBase-root MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-selected MuiPaginationItem-page emotion-72"
                tabindex="0"
                type="button"
              >
                1
                <span
                  class="MuiTouchRipple-root emotion-42"
                />
              </button>
            </li>
            <li>
              <button
                aria-label="Go to next page"
                class="MuiButtonBase-root Mui-disabled MuiPaginationItem-root MuiPaginationItem-sizeMedium MuiPaginationItem-text MuiPaginationItem-rounded Mui-disabled MuiPaginationItem-previousNext emotion-72"
                disabled=""
                tabindex="-1"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiPaginationItem-icon emotion-73"
                  data-testid="NavigateNextIcon"
                  focusable="false"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
