import React, { useState } from 'react';
import { Typography, Stack, Divider, TextField, Box, Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LoadingIndicator } from 'components/LoadingIndicator';
import { KyronImage } from 'components/KyronImage/KyronImage';
import { ActiveStorageBlob } from 'components/Uploaders/types';
import { useModal } from 'components/utils/ModalContext';
import { FileUploader, UploadButton, ACCEPT_IMAGE_TYPES } from 'components/Uploaders/FileUploader';
import { useLessonFromParams, useDeleteLessonCollection } from 'controllers/react-query';
import { UpdateBar } from './UpdateBar';
import { useSettingsTitle, useLessonSettingsDialogContext } from './LessonSettingsDialog';

export const GeneralSettings = () => {
  useSettingsTitle('General');

  const { isFetching, isRefetching, isError, data } = useLessonFromParams();
  const [title, setTitle] = useState(data?.name);
  const [description, setDescription] = useState(data?.description);
  const [thumbnailUrl, setThumbnailUrl] = useState(data?.lesson_collection.custom_thumbnail);
  const [signedId, setSignedId] = useState<string | null>(null);

  const { updateLesson, isUpdatePending } = useLessonSettingsDialogContext();
  const { mutateAsync: deleteLessonCollection, isPending: isDeleting } = useDeleteLessonCollection();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { openModal, closeModal } = useModal();

  const lessonCollection = data?.lesson_collection;
  const isTitleChanged = title && title !== data?.name;
  const isDescriptionChanged = description && description !== data?.description;
  const isThumbnailChanged = signedId;
  const anyUnsavedChanges = Boolean(isTitleChanged || isDescriptionChanged || isThumbnailChanged);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = e => {
        const newImageUrl = e.target?.result as string;
        setThumbnailUrl(newImageUrl);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const afterUpload = (blobs: ActiveStorageBlob[]) => {
    const blob = blobs[0];
    if (!blob.signed_id) return;
    setSignedId(blob.signed_id);
  };

  const onUpdate = () => {
    updateLesson({
      name: title,
      description,
      lesson_collection: {
        custom_thumbnail: signedId || undefined,
      },
    });
    setSignedId(null);
  };

  const openDeleteModal = () => {
    const handleDelete = async () => {
      closeModal();
      if (lessonCollection?.id) {
        try {
          await deleteLessonCollection({ lessonCollectionId: lessonCollection.id });
          enqueueSnackbar(`Deleted course: ${lessonCollection.name}.`);
          navigate('/studio/courses'); // redirect to Library
        } catch (err) {
          console.error(err);
          enqueueSnackbar(`Error deleting course ${lessonCollection.name}`, { variant: 'error' });
        }
      }
    };

    openModal({
      id: 'delete-course-modal',
      title: 'Delete Course',
      content: 'Are you sure you want to delete this course?',
      action: (
        <Stack direction='row' spacing={2}>
          <Button variant='outlined' onClick={closeModal}>
            Cancel
          </Button>
          <Button color='error' onClick={handleDelete}>
            Delete
          </Button>
        </Stack>
      ),
    });
  };

  return (
    <>
      <Box sx={{ px: 4, pb: 10, pt: 2 }}>
        {isFetching && !isRefetching && <LoadingIndicator message='Loading course settings...' />}
        {isError && <Typography variant='bodySmall'>Error loading course</Typography>}
        {data && (
          <Stack gap={2}>
            <Stack gap={1}>
              <Typography variant='labelMedium' component='label' htmlFor='lesson-title'>
                Title
              </Typography>
              <TextField
                variant='outlined'
                id='lesson-title'
                size='small'
                fullWidth
                data-testid='lesson-title'
                multiline
                defaultValue={data.name}
                onChange={e => setTitle(e.target.value)}
                sx={{ backgroundColor: 'background.default' }}
              />
            </Stack>
            <Divider />
            <Stack gap={1}>
              <Typography variant='labelMedium' component='label' htmlFor='lesson-description'>
                Description
              </Typography>
              <TextField
                variant='outlined'
                id='lesson-description'
                size='small'
                fullWidth
                data-testid='lesson-description'
                multiline
                defaultValue={data.description}
                onChange={e => setDescription(e.target.value)}
                sx={{ backgroundColor: 'background.default' }}
                inputProps={{ maxLength: 255 }}
              />
            </Stack>
            <Divider />
            <Stack gap={1}>
              <Typography variant='labelMedium'>Cover Image</Typography>
              <Typography variant='bodySmall'>Upload an image. It will show up on your assignments.</Typography>
              <Box
                sx={{
                  width: '320px',
                  height: '180px',
                  position: 'relative',
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  '&:hover .upload-button': {
                    opacity: 1,
                  },
                }}
                border={1}
                borderColor='divider'
                borderRadius={1}
              >
                <KyronImage
                  stretch
                  src={thumbnailUrl || data.lesson_collection?.custom_thumbnail}
                  alt='course collection thumbnail'
                  dataTestID='lesson-thumbnail'
                />
                <Stack sx={{ position: 'absolute', alignItems: 'center' }} gap={1}>
                  <FileUploader
                    afterUpload={afterUpload}
                    acceptTypes={ACCEPT_IMAGE_TYPES}
                    existingFile={{ url: thumbnailUrl }}
                  >
                    <UploadButton
                      onInputChange={handleInputChange}
                      buttonLabel='Replace file'
                      variant='raised'
                      sx={{ opacity: 0 }}
                    />
                    {/* TODO: Add remove functionality
                      <RemoveFileButton onRemove={removeImage} variant='raised' />
                    */}
                  </FileUploader>
                </Stack>
              </Box>
            </Stack>
            <Divider />
            <Box>
              <Button
                data-testid='delete-button'
                color='error'
                variant='outlined'
                onClick={openDeleteModal}
                startIcon={isDeleting ? <LoadingIndicator /> : <Delete />}
                disabled={isDeleting}
              >
                Delete Course
              </Button>
            </Box>
          </Stack>
        )}
      </Box>

      <UpdateBar isPending={isUpdatePending} onUpdate={onUpdate} unsavedChanges={anyUnsavedChanges} />
    </>
  );
};
