import React from 'react';
import { Box, Link, Pagination, PaginationItem } from '@mui/material';
import { useGetMarketplaceLessonCollections } from 'controllers/react-query/lessonCollectionHooks';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { MarketplaceLessonCard } from '../MarketplaceLessonCard/MarketplaceLessonCard';
import { MarketplaceLessonCardSkeleton } from '../MarketplaceLessonCard/MarketplaceLessonCardSkeleton';
import { NoResources } from '../../NoResources';

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(360px, 1fr))',
    gap: '16px',
  },
};

export function MarketplaceHome() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);
  const perPage = 24;

  const { data, isError, isLoading, search } = useGetMarketplaceLessonCollections({ page, perPage });
  const lessonCollections = data?.lesson_collections || [];
  const totalPages = data?.meta?.total_pages;

  if (isLoading) {
    return (
      <Box sx={styles.wrapper}>
        {[...Array(16)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <MarketplaceLessonCardSkeleton key={i} />
        ))}
      </Box>
    );
  }

  if (isError) {
    throw new Error('Error fetching lessons');
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: 'primaryContainer.main',
          py: 1,
          px: 2,
          borderRadius: 1,
          color: 'primaryContainer.contrastText',
          textAlign: 'center',
          mb: 1,
        }}
      >
        Looking for our 4th grade math content? Find it at{' '}
        <Link component={RouterLink} to='/k12'>{`${window.location.origin}/k12`}</Link>
      </Box>
      {!lessonCollections.length ? (
        <NoResources message={`No courses found${search ? ` with search term "${search}"` : ''}`} />
      ) : (
        <Box sx={styles.wrapper} data-testid='marketplace-lesson-list'>
          {lessonCollections.map(lessonCollection => (
            <MarketplaceLessonCard key={lessonCollection.id} lessonCollection={lessonCollection} />
          ))}
        </Box>
      )}
      {totalPages && totalPages > 1 ? (
        <Pagination
          sx={{ mt: 3, '.MuiPagination-ul': { justifyContent: 'center' } }}
          count={totalPages}
          page={page}
          renderItem={item => (
            <PaginationItem component={RouterLink} to={item.page === 1 ? '' : `?page=${item.page}`} {...item} />
          )}
        />
      ) : null}
    </>
  );
}
