/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

import {
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Box,
  Chip,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
  Autocomplete,
} from '@mui/material';

const styles = {
  LessonSegmentAcceptableInputs: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 0.5,
  },
};

export const StepSegmentForm = props => {
  // This method is creating a pseudo target object for handleInputChange function to be used globally
  const handleInputChange = (property, value) => {
    props.handleInputChange({
      target: {
        name: property,
        value,
        type: 'autocomplete',
      },
    });
  };
  return (
    <>
      <Autocomplete
        id='readOnly'
        disablePortal
        data-testid='next-segment-select'
        name='next_segment'
        value={props.state.next_segment ? props.state.next_segment : null}
        onChange={(e, value) => handleInputChange('next_segment', value)}
        options={props.state.allSegmentNames}
        renderInput={params => <TextField {...params} margin='normal' label='Next Segment' />}
      />
      <FormControl>
        <InputLabel id='misconceptions-label'>Misconceptions</InputLabel>
        <Select
          data-testid='misconceptions-select'
          name='misconceptions'
          multiple
          labelId='misconceptions-label'
          label='Misconceptions'
          input={<OutlinedInput label='Misconceptions' />}
          renderValue={selected => (
            <Box sx={styles.LessonSegmentAcceptableInputs}>
              {selected.map(value => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          value={props.state.misconceptions}
          onChange={props.handleInputChange}
        >
          {props.state.allMisconceptions.map(concept => (
            <MenuItem key={concept.code} value={concept.code}>
              <Checkbox checked={props.state.misconceptions.indexOf(concept.code) > -1} />
              <ListItemText primary={concept.code} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText id='misconceptions-label'>Enter misconception codes for this segment</FormHelperText>
      </FormControl>

      <TextField
        data-testid='transcript-input'
        name='transcript'
        label='Video Transcript'
        fullWidth
        margin='normal'
        type='text'
        onChange={props.handleInputChange}
        helperText='Video transcript for this segment'
        value={props.state.transcript}
      />
    </>
  );
};

StepSegmentForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object,
  handleInputChange: PropTypes.func,
};
