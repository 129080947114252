import React from 'react';

export const MARKETPLACE_SEARCH_HISTORY_KEY = 'marketplace-search-history';
export const MARKETPLACE_HISTORY_LIMIT = 200; // kept in local storage

export type MarketPlaceSearchHistoryItem = { text: string; timestamp: number };

export function getMarketPlaceSearchHistory(): MarketPlaceSearchHistoryItem[] {
  const historyStr = localStorage.getItem(MARKETPLACE_SEARCH_HISTORY_KEY);
  let history: MarketPlaceSearchHistoryItem[] = [];
  if (historyStr) {
    try {
      history = JSON.parse(historyStr) as MarketPlaceSearchHistoryItem[];
    } catch (e) {
      console.error('Failed to parse search history', e);
    }
  }
  return history;
}

export function saveMarketplaceSearchHistory(
  newSearchTerm: string,
  setSearchHistory: React.Dispatch<React.SetStateAction<MarketPlaceSearchHistoryItem[]>>,
) {
  // Get and Parse the history
  let history = getMarketPlaceSearchHistory();

  // Add new entry
  history = history.filter(h => h.text !== newSearchTerm);
  history.unshift({ text: newSearchTerm, timestamp: Date.now() });
  if (history.length > MARKETPLACE_HISTORY_LIMIT) history.pop();

  localStorage.setItem(MARKETPLACE_SEARCH_HISTORY_KEY, JSON.stringify(history));
  setSearchHistory(getMarketPlaceSearchHistory());
}
