import { PaletteOptions } from '@mui/material';

export const kyronPaletteLight: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#4B35FF',
    contrastText: '#FFFFFF',
  },
  primaryContainer: {
    main: '#E3DFFF',
    dark: '#D6D2F1',
    contrastText: '#110068',
  },
  secondary: {
    main: '#9F00C2',
    contrastText: '#FFFFFF',
  },
  secondaryContainer: {
    main: '#F3DBF8',
    dark: '#E1CDE6',
    contrastText: '#340042',
  },
  tertiary: {
    main: '#7A5367',
    contrastText: '#FFFFFF',
  },
  tertiaryContainer: {
    main: '#F0E2E9',
    dark: '#D9CBD2',
    contrastText: '#2F1123',
  },
  error: {
    main: '#BA1A1A',
    contrastText: '#FFFFFF',
  },
  errorContainer: {
    main: '#FFDAD6',
    dark: '#ECC9C5',
    contrastText: '#410002',
  },
  warning: {
    main: '#E6D119',
    contrastText: '#403A07',
  },
  warningContainer: {
    main: '#FFFBD5',
    dark: '#EFE9B4',
    contrastText: '#5F5D4B',
  },
  info: {
    main: '#49474B',
    contrastText: '#FFFFFF',
  },
  infoContainer: {
    main: '#CECAD1',
    dark: '#B9B6BC',
    contrastText: '#434145',
  },
  success: {
    main: '#1A7918',
    contrastText: '#FFFFFF',
  },
  successContainer: {
    main: '#C2ECC1',
    dark: '#A6D3A5',
    contrastText: '#234122',
  },
  surface: {
    main: '#FCFAFD',
    dark: '#EDEAEF',
    contrastText: '#1C1B1F',
  },
  surfaceContainer: {
    main: '#F1ECF4',
    dark: '#D9D3DD',
    contrastText: '#47464F',
  },
  background: {
    paper: '#FCFAFD',
    default: '#FFFFFF',
  },
  divider: '#D6D5DD',
  scrim: '#1C1B1FB3',
  text: {
    primary: '#1C1B1F',
    secondary: '#1C1B1FB3',
    disabled: '#1C1B1F61',
  },
  action: {
    disabled: '#1C1B1F61',
    disabledBackground: '#1C1B1F1F',
  },
  // TODO(steve): Remove the following. The components that are using
  // the following colors should be updated to use the theme colors above.
  lessonMultipleChoiceToggleButton: {
    main: '#225e83',
    contrastText: '#FFFFFF',
    hovered: '#397AA2',
    selected: '#114566',
  },
  fractionBorder: {
    main: '#000000',
    multipleChoice: '#FFFFFF',
  },
  simpleEquationBuilder: {
    labelBackground: '#F0E6DE',
    labelFontSize: '11px',
  },
};

export const kyronPaletteDark: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#C4C0FF',
    contrastText: '#2000A4',
  },
  primaryContainer: {
    main: '#3100E4',
    dark: '#3600FA',
    contrastText: '#E3DFFF',
  },
  secondary: {
    main: '#F4AEFF',
    contrastText: '#55006A',
  },
  secondaryContainer: {
    main: '#790095',
    dark: '#8E00AF',
    contrastText: '#F3DBF8',
  },
  tertiary: {
    main: '#EAB9D1',
    contrastText: '#472639',
  },
  tertiaryContainer: {
    main: '#603B4F',
    dark: '#7D4C66',
    contrastText: '#F0E2E9',
  },
  error: {
    main: '#FFB4AB',
    contrastText: '#690005',
  },
  errorContainer: {
    main: '#93000A',
    dark: '#AD000C',
    contrastText: '#FFDAD6',
  },
  warning: {
    main: '#FFF6A3',
    contrastText: '#3A392F',
  },
  warningContainer: {
    main: '#C7B515',
    dark: '#FEE81D',
    contrastText: '#292503',
  },
  info: {
    main: '#CAC3CF',
    contrastText: '#373439',
  },
  infoContainer: {
    main: '#5F5D61',
    dark: '#918E94',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#C7FFC6',
    contrastText: '#163415',
  },
  successContainer: {
    main: '#126110',
    dark: '#1A8A17',
    contrastText: '#E5FFE5',
  },
  surface: {
    main: '#33313E',
    dark: '#484557',
    contrastText: '#E5E1E6',
  },
  surfaceContainer: {
    main: '#383547',
    dark: '#544F6A',
    contrastText: '#F6F2F8',
  },
  background: {
    paper: '#33313E',
    default: '#27252F',
  },
  divider: '#575663',
  scrim: '#1C1B1FB3',
  text: {
    primary: '#E5E1E6',
    secondary: '#E5E1E6B3',
    disabled: '#E5E1E661',
  },
  action: {
    disabled: '#E5E1E661',
    disabledBackground: '#E5E1E61F',
  },
  // TODO(steve): Remove the following. The components that are using
  // the following colors should be updated to use the theme colors above.
  lessonMultipleChoiceToggleButton: {
    main: '#225e83',
    contrastText: '#FFFFFF',
    hovered: '#397AA2',
    selected: '#114566',
  },
  fractionBorder: {
    main: '#000000',
    multipleChoice: '#FFFFFF',
  },
  simpleEquationBuilder: {
    labelBackground: '#F0E6DE',
    labelFontSize: '11px',
  },
};
