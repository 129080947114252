// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`The BoxOverBox component is displayed When 2 BoxSingle components are passed 1`] = `
{
  "asFragment": [Function],
  "baseElement": .emotion-0 {
  padding: 0px 4px;
}

.emotion-1 {
  display: inline-block;
  width: 100%;
  font-weight: 400;
  line-height: normal;
}

.emotion-1>div input {
  min-width: 25px;
  color: #1C1B1F;
}

.emotion-2 {
  font-size: 3rem;
  text-align: center;
}

.emotion-3 {
  border-bottom: 0.25rem solid;
}

<body>
    <div>
      <span
        class="emotion-0"
      >
        <div
          class="emotion-1"
        >
          <div
            class="emotion-2"
          >
            9
          </div>
        </div>
        <div
          class="fraction-line emotion-3"
        />
        <div
          class="emotion-1"
        >
          <div
            class="emotion-2"
          >
            12
          </div>
        </div>
      </span>
    </div>
  </body>,
  "container": .emotion-0 {
  padding: 0px 4px;
}

.emotion-1 {
  display: inline-block;
  width: 100%;
  font-weight: 400;
  line-height: normal;
}

.emotion-1>div input {
  min-width: 25px;
  color: #1C1B1F;
}

.emotion-2 {
  font-size: 3rem;
  text-align: center;
}

.emotion-3 {
  border-bottom: 0.25rem solid;
}

<div>
    <span
      class="emotion-0"
    >
      <div
        class="emotion-1"
      >
        <div
          class="emotion-2"
        >
          9
        </div>
      </div>
      <div
        class="fraction-line emotion-3"
      />
      <div
        class="emotion-1"
      >
        <div
          class="emotion-2"
        >
          12
        </div>
      </div>
    </span>
  </div>,
  "debug": [Function],
  "findAllByAltText": [Function],
  "findAllByDisplayValue": [Function],
  "findAllByLabelText": [Function],
  "findAllByPlaceholderText": [Function],
  "findAllByRole": [Function],
  "findAllByTestId": [Function],
  "findAllByText": [Function],
  "findAllByTitle": [Function],
  "findByAltText": [Function],
  "findByDisplayValue": [Function],
  "findByLabelText": [Function],
  "findByPlaceholderText": [Function],
  "findByRole": [Function],
  "findByTestId": [Function],
  "findByText": [Function],
  "findByTitle": [Function],
  "getAllByAltText": [Function],
  "getAllByDisplayValue": [Function],
  "getAllByLabelText": [Function],
  "getAllByPlaceholderText": [Function],
  "getAllByRole": [Function],
  "getAllByTestId": [Function],
  "getAllByText": [Function],
  "getAllByTitle": [Function],
  "getByAltText": [Function],
  "getByDisplayValue": [Function],
  "getByLabelText": [Function],
  "getByPlaceholderText": [Function],
  "getByRole": [Function],
  "getByTestId": [Function],
  "getByText": [Function],
  "getByTitle": [Function],
  "queryAllByAltText": [Function],
  "queryAllByDisplayValue": [Function],
  "queryAllByLabelText": [Function],
  "queryAllByPlaceholderText": [Function],
  "queryAllByRole": [Function],
  "queryAllByTestId": [Function],
  "queryAllByText": [Function],
  "queryAllByTitle": [Function],
  "queryByAltText": [Function],
  "queryByDisplayValue": [Function],
  "queryByLabelText": [Function],
  "queryByPlaceholderText": [Function],
  "queryByRole": [Function],
  "queryByTestId": [Function],
  "queryByText": [Function],
  "queryByTitle": [Function],
  "rerender": [Function],
  "testAccessibility": [Function],
  "unmount": [Function],
}
`;
