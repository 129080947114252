import { Button, ButtonProps } from '@mui/material';
import React, { useContext } from 'react';
import { FileUploaderContext, UploaderElementProps } from './FileUploader';

type UploadedFileButtonProps = {
  getLabel: (fileName?: string) => string;
  buttonProps: ButtonProps;
};

/**
 * A simple wrapper around Button that is aware of FileUploaderContext.
 */
export function UploadedFileButton({
  getLabel,
  buttonProps,
  shouldBeDisabled,
}: UploaderElementProps & UploadedFileButtonProps) {
  const uploaderCtx = useContext(FileUploaderContext);

  return !uploaderCtx?.isAttachmentBeingProcessed && uploaderCtx?.isThereAnExistingFile ? (
    <Button
      data-testid='file-uploader-remove-button'
      disabled={shouldBeDisabled}
      {...buttonProps}
      sx={{ maxWidth: '156px' }}
    >
      {getLabel(uploaderCtx?.fileTypeName)}
    </Button>
  ) : null;
}
