import React from 'react';
import { Drawer, List, Divider } from '@mui/material';
import { NavDrawerItem, NavDrawerItemProps } from '../NavDrawerItem/NavDrawerItem';
import { NavDrawerButtonAndLogo } from './NavDrawerButtonAndLogo';

export type NavDrawerProps = {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  navDrawerItems?: NavDrawerItemProps[][];
};

export function NavDrawer({ drawerOpen, setDrawerOpen, navDrawerItems = [] }: NavDrawerProps) {
  return (
    <Drawer
      anchor='left'
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      id='nav-drawer'
      data-testid='nav-drawer'
    >
      <NavDrawerButtonAndLogo
        sx={{ ml: '12px', mt: '6px' }}
        setDrawerOpen={() => setDrawerOpen(false)}
        drawerOpen={drawerOpen}
      />
      <nav aria-label='Admin navigation'>
        <List>
          {navDrawerItems.map(section => (
            <React.Fragment key={section[0].label}>
              {section.map(item => (
                <NavDrawerItem
                  key={item.label}
                  icon={item.icon}
                  label={item.label}
                  url={item.url}
                  autoFocus={item.autoFocus}
                  setDrawerOpen={setDrawerOpen}
                />
              ))}

              <Divider variant='middle' />
            </React.Fragment>
          ))}
        </List>
      </nav>
    </Drawer>
  );
}
