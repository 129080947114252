import React from 'react';
import debounce from 'lodash/debounce';
import { Stack, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import { Sort } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { SetURLSearchParams } from 'react-router-dom';

import { useTutorsQuery } from 'controllers/react-query';
import { Locales } from 'components/utils/locales';

export const PARAMS = {
  q: 'q',
  locale: 'locale',
  tutorId: 'tutorId',
  sort: 'sort',
  ASC: 'asc',
  DESC: 'desc',
  BLANK: '-',
};

// export for testing
export const setSearchParamsForField = (
  setFunction: SetURLSearchParams,
  key: string,
  value: string,
  emptyValue?: string,
) => {
  setFunction(
    params => {
      if (value === emptyValue) {
        params.delete(key);
      } else if (value) {
        params.set(key, value);
      }
      return params;
    },
    { replace: true },
  );
};

const LOCALE_LABELS = [
  { value: PARAMS.BLANK, label: 'All Locales' },
  ...Object.entries(Locales).map(([value, label]) => ({ value, label })),
];

const SortAsc = styled(Sort)`
  transform: scaleY(-1);
`;

const SearchFilterStack = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: '64px',
  backgroundColor: theme.palette.background.default,
}));

type AdminCollectionSearchFilterProps = {
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
};

export function AdminCollectionSearchFilter({
  searchParams,
  setSearchParams,
}: AdminCollectionSearchFilterProps): React.JSX.Element {
  const { data: tutorData } = useTutorsQuery();

  const tutorLabels = [
    { value: PARAMS.BLANK, label: 'All Tutors' },
    ...(tutorData?.tutors?.map(tutor => ({ value: tutor.id, label: tutor.display_name })) || []),
  ];

  return (
    <SearchFilterStack direction='column' gap={2} marginBottom={2}>
      <TextField
        id='search'
        label='Search'
        variant='outlined'
        fullWidth
        defaultValue={searchParams.get(PARAMS.q)}
        onChange={event =>
          debounce(
            (value: string) => setSearchParamsForField(setSearchParams, PARAMS.q, value, ''),
            500,
          )(event.target.value)
        }
      />
      <Stack direction='row' gap={2}>
        <Stack direction='row' gap={1} flexGrow={1} marginRight={4}>
          <FormControl fullWidth aria-required>
            <InputLabel id='locale'>Locale</InputLabel>
            <Select
              id='locale'
              label='Locale'
              variant='outlined'
              value={searchParams.get(PARAMS.locale) || ''}
              onChange={e => setSearchParamsForField(setSearchParams, PARAMS.locale, e.target.value, PARAMS.BLANK)}
            >
              {LOCALE_LABELS?.map(locale => (
                <MenuItem key={locale.value} value={locale.value}>
                  {locale.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth aria-required>
            <InputLabel id='tutor'>Tutor</InputLabel>
            <Select
              id='tutor'
              label='Tutor'
              variant='outlined'
              value={searchParams.get(PARAMS.tutorId) || ''}
              onChange={e => setSearchParamsForField(setSearchParams, PARAMS.tutorId, e.target.value, PARAMS.BLANK)}
            >
              {tutorLabels.map(tutor => (
                <MenuItem key={tutor.value} value={tutor.value}>
                  {tutor.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Button
          aria-label='toggle-sort'
          variant='text'
          onClick={() =>
            setSearchParamsForField(
              setSearchParams,
              PARAMS.sort,
              searchParams.get(PARAMS.sort) === PARAMS.ASC ? PARAMS.DESC : PARAMS.ASC,
            )
          }
        >
          {searchParams.get(PARAMS.sort) === PARAMS.ASC ? (
            <SortAsc aria-label='sort-asc' />
          ) : (
            <Sort aria-label='sort-desc' />
          )}
        </Button>
      </Stack>
    </SearchFilterStack>
  );
}
