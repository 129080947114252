import { useBreadcrumbs, useTitle } from 'components/StudioLayout/StudioLayout';
import { useTutorQuery } from 'controllers/react-query';
import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TutorPayload, useTutorMutation } from 'controllers/react-query/tutorHooks';
import { useSnackbar } from 'notistack';
import { TutorForm } from './TutorForm';

export const TutorEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { tutorId } = useParams();
  const navigate = useNavigate();
  const { data: tutor, isError, isLoading } = useTutorQuery(Number(tutorId));
  const { mutate: updateTutor } = useTutorMutation(Number(tutorId));

  useTitle(tutor ? tutor.display_name : 'Loading tutor...');
  useBreadcrumbs(useMemo(() => [{ to: '/studio/tutors', label: 'Tutors' }], []));

  const handleSubmit = (t: TutorPayload) => {
    updateTutor(t, {
      onSuccess: newTutor => {
        enqueueSnackbar(`${newTutor.display_name} was successfully updated`, { variant: 'success' });
        navigate(`/studio/tutors`);
      },
      onError: error => {
        enqueueSnackbar(`Failed to update tutor: ${error.message}`, { variant: 'error' });
      },
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    throw new Error('Error fetching tutor');
  }

  if (!tutor) {
    throw new Error('Tutor not found');
  }

  return <TutorForm tutor={tutor} onSubmit={handleSubmit} />;
};
