import { Delete } from '@mui/icons-material';
import { List, ListItem, IconButton, ListItemText } from '@mui/material';
import React, { useContext } from 'react';
import { FileUploaderContext } from './FileUploader';
import { KyronTooltip } from '../KyronTooltip';

export function FileList({ onRemove }: { onRemove: (fileName: string) => void }) {
  const uploaderCtx = useContext(FileUploaderContext);

  const removeFile = (signedId?: string) => {
    if (!signedId) {
      console.warn("Can't remove file from state. signedId is undefined");
      return;
    }
    // update FileUploader internal state
    uploaderCtx?.removeFile(signedId);
    // Invoke on remove callback to reflect file list changes to parent
    onRemove(signedId);
  };

  return (
    <List>
      {uploaderCtx?.files?.map(file => {
        const fileError = uploaderCtx.fileErrors.find(fileWithError => fileWithError.signed_id === file.signed_id);
        return (
          <ListItem key={file.signed_id}>
            <KyronTooltip title='Remove' placement='left'>
              <IconButton onClick={() => removeFile(file.signed_id)} sx={{ mr: 1 }} data-testid='delete-file'>
                <Delete />
              </IconButton>
            </KyronTooltip>
            <ListItemText
              primary={file.filename}
              secondary={fileError?.error}
              sx={{ color: fileError ? theme => theme.palette.error.main : undefined }}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
