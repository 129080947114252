import React from 'react';
import { Stack, Typography, Container, Button, Radio, MenuItem, FormControlLabel } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { HookTextField } from 'components/FormWrappers/HookTextField';
import { HookSelect } from 'components/FormWrappers/HookSelect';
import { HookRadioGroup } from '../../FormWrappers/HookRadioGroup';

type FormValues = {
  activityTemplate: string;
  auxContentSignedIds: string[];
  lessonObjective: string;
  videoIntroduction: string;
};

const activityTemplates = [
  { label: 'Guided discussion', value: 'exploratory' },
  { label: 'Scenario-based discussion', value: 'scenario' },
  { label: 'Reflective conversation', value: 'reflection' },
];

const defaultValues: FormValues = {
  activityTemplate: activityTemplates[0].value,
  auxContentSignedIds: [],
  lessonObjective: '',
  videoIntroduction: '',
};

const videoIntroductionOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

export function DiscussionBuilder() {
  const { control, handleSubmit } = useForm({ defaultValues });

  // This function is not yet implemented
  const onSubmit: SubmitHandler<FormValues> = () => {};

  return (
    <Container maxWidth='sm' sx={{ mb: 2 }}>
      <Stack gap={10}>
        <Typography variant='titleLarge' alignSelf='center'>
          Create a discussion
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)} data-testid='discussion-builder-form'>
          <Stack gap={10}>
            <Stack gap={2}>
              <Typography variant='bodyLarge' component='label' id='activity-template-label'>
                1. What kind of discussion would you like?
              </Typography>
              <HookSelect
                name='activityTemplate'
                control={control}
                id='activity-template'
                labelId='activity-template-label'
              >
                {activityTemplates.map(template => (
                  <MenuItem key={template.value} value={template.value}>
                    {template.label}
                  </MenuItem>
                ))}
              </HookSelect>
            </Stack>

            <Stack gap={2}>
              <Typography variant='bodyLarge'>
                2. Upload any existing learning materials and we&apos;ll generate a scenario-based discussion for you
                based on that material.
              </Typography>
              <Stack direction={{ xs: 'column', sm: 'row' }} gap={3} alignSelf='center'>
                {/* Note: These buttons are not yet a part of the form */}

                <Button variant='contained' disabled>
                  Upload learning materials
                </Button>
                <Button variant='outlined' disabled>
                  Choose from Canvas
                </Button>
              </Stack>
              <Typography variant='bodyLarge' component='label' htmlFor='lesson-objective'>
                Or tell us what you&apos;d like your learners to discuss.
              </Typography>
              <HookTextField
                name='lessonObjective'
                control={control}
                multiline
                minRows={4}
                maxRows={4}
                id='lesson-objective'
              />
            </Stack>

            <Stack gap={2}>
              <Typography variant='bodyLarge' component='label' htmlFor='video-introduction'>
                3. Include a short video introduction before the discussion to provide context?
              </Typography>
              <HookRadioGroup name='videoIntroduction' control={control} id='video-introduction'>
                {videoIntroductionOptions.map(option => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<Radio sx={{ mr: 1 }} />}
                    label={option.label}
                    sx={{ mt: 1 }}
                  />
                ))}
              </HookRadioGroup>
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Container>
  );
}
