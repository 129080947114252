import React, { useState } from 'react';
import { Box, Stack, TextField, Typography, Select, MenuItem, Button } from '@mui/material';
import { School, UploadFile } from '@mui/icons-material';
import { useGenerateEntireLesson, useStructureLearningObjectives } from 'controllers/react-query';
import { usePaywall, NavigateToPricingButton } from 'components/Pricing/PaymentContext';
import { Row } from 'components/Row/Row';
import { useFeatures } from 'components/FeaturesContext';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { selectOptions } from 'components/Studio/Editor/AddToLesson/SegmentTypeSelect';
import { CanvasCourseSelector } from 'components/Canvas/CanvasCourseSelector';
import { DetailedSegmentType } from 'controllers/types';
import { useRecommendedLessonValues } from 'controllers/react-query/canvasHooks';
import { ActiveStorageBlob } from '../../Uploaders/types';
import { ACCEPT_ALL_TEXT_FILES, ACCEPT_IMAGE_TYPES, FileUploader, UploadButton } from '../../Uploaders/FileUploader';
import { FileList } from '../../Uploaders/FileList';
import { FeelingLuckyButton } from '../../Studio/components/FeelingLuckyButton';
import OpenBook from '../../../assets/open-book.svg';
import Test from '../../../assets/test.svg';
import { LoadingIndicator } from '../../LoadingIndicator';
import { KyronEvents } from '../../utils/KyronEvents';
import { ValidationModal } from './ValidationModal';
import { useUserContext } from '../../UserContext';
import { GenerateQuestionsPayload } from '../../../controllers/react-query/lessonGenerationHooks';
import { AuxContentIngestionDetailTooltip } from './AuxContentIngestionDetailTooltip';
import { SlideImageIngestionDetailTooltip } from './SlideImageIngestionDetailTooltip';
import { FileError, useAuxContentValidation } from '../../../controllers/react-query/attachmentHooks';

const emptyForm: GenerateQuestionsPayload = {
  lessonTitle: '',
  lessonObjective: '',
  audience: '',
  structuredLearningObjectives: [],
  auxContentSignedIds: [],
  signedSlideImageIds: [],
  questionType: null,
  activityTemplate: 'lesson',
  ttsVoiceId: 'alloy',
  intendedTime: 3600,
};

export type Props = {
  onCancel: () => void;
  hideAuxContentUpload?: boolean;
  hideLuckyButton?: boolean;
  initialFormValues?: { lessonTitle: string; lessonObjective: string; audience: string };
};

enum CourseLength {
  FIVE_MINUTES = 300,
  ONE_HOUR = 3600,
  ONE_WEEK = 36000,
  EIGHT_WEEKS = 288000,
  SIXTEEN_WEEKS = 576000,
}

export function CreateLessonForm({ hideAuxContentUpload, hideLuckyButton, initialFormValues, onCancel }: Props) {
  const { isNewPlgFlow, auxContentInjection, showDurationOptions, showCanvasCourses } = useFeatures();
  const shouldDisableContentIngestion = isNewPlgFlow && !auxContentInjection; // TODO: remove when content ingestion is ready
  const { canCreateCourse } = usePaywall();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useUserContext();

  const [structuredObjectives, setStructuredObjectives] = useState<string[]>([]);
  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const initialLessonPayload = { ...emptyForm, ...(initialFormValues || {}) };
  const [createLessonPayload, setCreateLessonPayload] = useState(initialLessonPayload);
  const [isUploadingAuxContent, setIsUploadingAuxContent] = useState(false);
  const [isUploadingSlideImages, setIsUploadingSlideImages] = useState(false);
  const [fileErrors, setFileErrors] = useState<FileError[]>([]);
  const [showCanvasIntakeForm, setShowCanvasIntakeForm] = useState(false);
  const { mutateAsync: getRecommendedLessonValues, isPending: canvasLoading } = useRecommendedLessonValues();

  const {
    isPending: isStructuringObjectives,
    error: objectivesError,
    mutate: structureObjectives,
  } = useStructureLearningObjectives();
  const { isPending: isCreatingLesson, mutate: createLesson } = useGenerateEntireLesson();

  const { mutateAsync: validateAuxContent, isPending: isValidatingAuxContent } = useAuxContentValidation();

  const handleAuxContentRemove = (signedIdToRemove: string) => {
    setCreateLessonPayload(prevParams => ({
      ...prevParams,
      auxContentSignedIds: prevParams.auxContentSignedIds.filter(signedId => signedId !== signedIdToRemove),
    }));
    // remove file errors if any
    setFileErrors(prevErrors => prevErrors.filter(error => error.signed_id !== signedIdToRemove));
  };

  const handleSlideImageRemove = (signedIdToRemove: string) => {
    setCreateLessonPayload(prevParams => ({
      ...prevParams,
      signedSlideImageIds: prevParams.signedSlideImageIds.filter(signedId => signedId !== signedIdToRemove),
    }));
  };

  const handleAdditionalMaterialAttach = (blobs: ActiveStorageBlob[]) => {
    // create an array of signed_ids from the blobs, skip if signed_id is not present
    const newMaterialIds = blobs.reduce(
      (acc, curr) => (curr?.signed_id ? [...acc, curr.signed_id] : acc),
      [] as string[],
    );
    if (!newMaterialIds.length) return;

    blobs.forEach(blob => {
      KyronEvents.sendEvent(KyronEvents.names.AUX_CONTENT_UPLOAD, {
        lesson_name: createLessonPayload.lessonTitle,
        aux_content_name: blob.filename,
        aux_content_type: blob.content_type,
        aux_content_size: blob.byte_size,
        aux_content_signed_id: blob.signed_id,
      });
    });

    // new materials IDs should be appended to the existing ones
    setCreateLessonPayload(p => ({ ...p, auxContentSignedIds: [...p.auxContentSignedIds, ...newMaterialIds] }));
  };

  const handleAttachSlideImages = (blobs: ActiveStorageBlob[]) => {
    // create an array of signed_ids from the blobs, skip if signed_id is not present
    const newMaterialIds = blobs.reduce(
      (acc, curr) => (curr?.signed_id ? [...acc, curr.signed_id] : acc),
      [] as string[],
    );
    if (!newMaterialIds.length) return;

    KyronEvents.sendEvent(KyronEvents.names.UPLOAD_SLIDE_IMAGES_IN_CREATE_LESSON_FORM, {
      lesson_name: createLessonPayload.lessonTitle,
    });
    setCreateLessonPayload(p => ({ ...p, signedSlideImageIds: [...p.signedSlideImageIds, ...newMaterialIds] }));
  };

  function handleCreateLesson() {
    // This guard clause is not accessible UNLESS the user tries to mess with us and enables the create course button
    // from the browser console. So, it's not a big deal if we don't show a UI message here.
    if (fileErrors.length > 0) return;

    createLesson(
      { payload: { ...createLessonPayload, structuredLearningObjectives: structuredObjectives } },
      {
        onSuccess: lesson => {
          navigate(`/studio/courses/${lesson.id}`);
          enqueueSnackbar(`Your course ${lesson.name} is being created. This may take a few minutes.`, {
            variant: 'success',
          });
        },
        onError: () => {
          enqueueSnackbar(`Failed to create course ${createLessonPayload.lessonTitle}`, { variant: 'error' });
        },
      },
    );
  }

  function getStructuredObjectives() {
    structureObjectives(
      { payload: createLessonPayload },
      {
        onSuccess: objectives => {
          setStructuredObjectives(objectives);
        },
        onError: () => {
          enqueueSnackbar(`Failed to structure learning objectives for ${createLessonPayload.lessonTitle}`, {
            variant: 'error',
          });
        },
      },
    );
  }

  async function runFileValidation(blobs: ActiveStorageBlob[]) {
    // reset errors
    setFileErrors([]);
    try {
      const resp = await validateAuxContent({ payload: { signedIds: blobs.map(b => b.signed_id!) } });
      setFileErrors(resp.errors); // to block lesson creation with problematic files
      if (resp.errors.length > 0) {
        resp.errors.forEach(e => {
          KyronEvents.sendEvent(KyronEvents.names.AUX_CONTENT_VALIDATION_ERROR, {
            lesson_name: createLessonPayload.lessonTitle,
            aux_content_error: e.error,
            aux_content_name: e.filename,
            aux_content_signed_id: e.signed_id,
          });
        });
      }
      return resp.errors;
    } catch (error) {
      KyronEvents.sendEvent(KyronEvents.names.AUX_CONTENT_VALIDATION_FAILURE, {
        lesson_name: createLessonPayload.lessonTitle,
        aux_content_signed_ids: blobs.map(b => b.signed_id).join(','),
        aux_content_validation_failure: (error as Error).toString(),
      });
      enqueueSnackbar(`Failed to validate: ${error}`, { variant: 'error' });
    }
  }

  const approveButton = (
    <Button
      startIcon={isCreatingLesson ? <LoadingIndicator spinnerSize={20} /> : null}
      onClick={() => {
        handleCreateLesson();
        KyronEvents.sendEvent(KyronEvents.names.APPROVE_LEARNING_OBJECTIVES, {
          lesson_name: createLessonPayload.lessonTitle,
          user_id: user?.id || 'anonymous',
        });
      }}
      disabled={
        isStructuringObjectives ||
        isCreatingLesson ||
        isUploadingAuxContent ||
        isUploadingSlideImages ||
        isValidatingAuxContent
      }
    >
      Approve
    </Button>
  );

  function onCanvasSelect(courseId: string, moduleId: string) {
    getRecommendedLessonValues({ courseId, moduleId })
      .then((data: Partial<GenerateQuestionsPayload>) => {
        setCreateLessonPayload(prevParams => ({ ...prevParams, ...data }));
        setShowCanvasIntakeForm(false);
      })
      .catch((error: Error) => {
        console.error('Failed to fill in lesson create form from Canvas course', error);
        enqueueSnackbar('Could not read Canvas Course. Please try again later.', { variant: 'error' });
      });
  }

  if (canvasLoading) {
    return <LoadingIndicator message='Reading course info from Canvas...' />;
  }

  if (showCanvasIntakeForm) {
    return <CanvasCourseSelector onSelect={onCanvasSelect} />;
  }

  return (
    <Stack sx={{ gap: 3, position: 'relative' }}>
      {(!hideLuckyButton || showCanvasCourses) && (
        <Row sx={{ justifyContent: 'center' }}>
          {!hideLuckyButton && <FeelingLuckyButton setCreateLessonPayload={setCreateLessonPayload} />}
          {showCanvasCourses && (
            <Button
              startIcon={<School />}
              variant='outlined'
              onClick={() => setShowCanvasIntakeForm(true)}
              sx={{ ml: 2 }}
            >
              Fill from Canvas
            </Button>
          )}
        </Row>
      )}

      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='activity-template'>
          Activity Template
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          What type of activity would you like to create?
        </Typography>
        <Select
          variant='outlined'
          value={createLessonPayload.activityTemplate}
          onChange={e => {
            // Single interactions work best with a very short duration while whole courses need
            // more time. Help the creator out by setting the duration to a reasonable default
            // based on the activity type they just selected.
            const newTime = e.target.value === 'lesson' ? CourseLength.ONE_HOUR : CourseLength.FIVE_MINUTES;
            setCreateLessonPayload(prevParams => ({
              ...prevParams,
              activityTemplate: e.target.value as string,
              intendedTime: newTime,
            }));
          }}
          fullWidth
          required
          renderValue={value => selectOptions[value as DetailedSegmentType]?.label || 'Course'}
          id='activity-template'
        >
          <MenuItem value='lesson' sx={{ whiteSpace: 'wrap' }}>
            <Row gap={2} py={1} maxWidth={780}>
              <img src={OpenBook} alt='Course' width={40} />
              <Stack>
                <Typography variant='labelLarge'>Course</Typography>
                <Typography variant='bodyMedium' color='text.secondary'>
                  Teach learners new concepts through lectures, questions and discussions. Our AI-facilitated course
                  discussions encourage learners to think through open-ended questions.
                </Typography>
              </Stack>
            </Row>
          </MenuItem>
          <MenuItem value='exploratory' sx={{ whiteSpace: 'wrap' }}>
            <Row gap={2} py={1} maxWidth={780}>
              <img src={Test} alt={selectOptions.exploratory!.label} width={40} />
              <Stack>
                <Typography variant='labelLarge'>{selectOptions.exploratory!.label}</Typography>
                <Typography variant='bodyMedium' color='text.secondary'>
                  {selectOptions.exploratory!.description}
                </Typography>
              </Stack>
            </Row>
          </MenuItem>
          <MenuItem value='reflection' sx={{ whiteSpace: 'wrap' }}>
            <Row gap={2} py={1} maxWidth={780}>
              <img src={Test} alt={selectOptions.reflection!.label} width={40} />
              <Stack>
                <Typography variant='labelLarge'>{selectOptions.reflection!.label}</Typography>
                <Typography variant='bodyMedium' color='text.secondary'>
                  {selectOptions.reflection!.description}
                </Typography>
              </Stack>
            </Row>
          </MenuItem>
        </Select>
      </Stack>
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-duration'>
          Duration
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          How long should this course take to complete?
        </Typography>
        <Select
          variant='outlined'
          value={createLessonPayload.intendedTime}
          onChange={e =>
            setCreateLessonPayload(prevParams => ({ ...prevParams, intendedTime: e.target.value as number }))
          }
          fullWidth
          required
          id='intended-time'
        >
          <MenuItem value={CourseLength.FIVE_MINUTES}>5 minutes - Great for single interactions</MenuItem>
          <MenuItem value={CourseLength.ONE_HOUR} disabled={createLessonPayload.activityTemplate !== 'lesson'}>
            1 hour
          </MenuItem>
          <MenuItem value={CourseLength.ONE_WEEK} disabled={!showDurationOptions}>
            1 week (10 hours) - Coming soon!
          </MenuItem>
          <MenuItem value={CourseLength.EIGHT_WEEKS} disabled={!showDurationOptions}>
            8 weeks (80 hours) - Coming soon!
          </MenuItem>
          <MenuItem value={CourseLength.SIXTEEN_WEEKS} disabled={!showDurationOptions}>
            16 weeks (160 hours) - Coming soon!
          </MenuItem>
        </Select>
      </Stack>
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-title'>
          Title
        </Typography>
        <TextField
          value={createLessonPayload.lessonTitle}
          onChange={e => setCreateLessonPayload(prevParams => ({ ...prevParams, lessonTitle: e.target.value }))}
          fullWidth
          placeholder='How to make the perfect cappuccino.'
          autoFocus
          required
          id='lesson-title'
        />
      </Stack>
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-audience'>
          Audience
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          Who is this course intended for?
        </Typography>
        <TextField
          value={createLessonPayload.audience}
          multiline
          minRows={3}
          onChange={e => setCreateLessonPayload(prevParams => ({ ...prevParams, audience: e.target.value }))}
          fullWidth
          required
          placeholder='Intelligent cats who live in single family homes, own an espresso machine, have opposable thumbs, and know the basics of making coffee.'
          id='lesson-audience'
        />
      </Stack>
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-objectives'>
          Learning Objectives
        </Typography>
        <Typography variant='bodyMedium' color='text.secondary'>
          What should learners be able to do by the end of the course?
        </Typography>
        <TextField
          value={createLessonPayload.lessonObjective}
          multiline
          minRows={3}
          onChange={e => setCreateLessonPayload(prevParams => ({ ...prevParams, lessonObjective: e.target.value }))}
          fullWidth
          required
          placeholder='Students will know the best approaches to making a cappuccino, including coffee grind, type of milk, steaming techniques, and pouring techniques.'
          id='lesson-objectives'
        />
      </Stack>
      {!hideAuxContentUpload && (
        <Stack spacing='2px'>
          <Typography variant='labelLarge' component='label' htmlFor='lesson-objectives'>
            Upload files
          </Typography>
          <Row gap={1}>
            <Typography variant='bodyMedium' color='text.secondary'>
              Upload supplementary text materials to align generated content to your curriculum.
            </Typography>
            <AuxContentIngestionDetailTooltip />
          </Row>
          {shouldDisableContentIngestion ? (
            <Box pt={2}>
              <Button variant='outlined' disabled startIcon={<UploadFile />}>
                Coming Soon!
              </Button>
            </Box>
          ) : (
            <FileUploader
              fileSizeLimit={15}
              multiple={3}
              acceptTypes={ACCEPT_ALL_TEXT_FILES}
              afterUpload={handleAdditionalMaterialAttach}
              onUploadStateChange={setIsUploadingAuxContent}
              fileValidation={runFileValidation}
            >
              <FileList onRemove={handleAuxContentRemove} />
              <UploadButton />
            </FileUploader>
          )}
        </Stack>
      )}
      <Stack spacing='2px'>
        <Typography variant='labelLarge' component='label' htmlFor='lesson-objectives'>
          Upload images to accompany lecture content
        </Typography>
        <Row gap={1}>
          <Typography variant='bodyMedium' color='text.secondary'>
            Upload supplementary image materials to be placed into slides accompanying lectures. Our AI will look at
            your image to determine where it fits best in the course
          </Typography>
          <SlideImageIngestionDetailTooltip />
        </Row>
        {shouldDisableContentIngestion ? (
          <Box pt={2}>
            <Button variant='outlined' disabled startIcon={<UploadFile />}>
              Coming Soon!
            </Button>
          </Box>
        ) : (
          <FileUploader
            multiple={50}
            acceptTypes={ACCEPT_IMAGE_TYPES}
            afterUpload={handleAttachSlideImages}
            onUploadStateChange={setIsUploadingSlideImages}
          >
            <FileList onRemove={handleSlideImageRemove} />
            <UploadButton />
          </FileUploader>
        )}
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Button variant='text' aria-label='cancel' onClick={onCancel}>
          Cancel
        </Button>
        {canCreateCourse ? (
          <Button
            aria-label='Create Course Button'
            startIcon={isCreatingLesson ? <LoadingIndicator spinnerSize={20} /> : null}
            onClick={() => {
              getStructuredObjectives();
              setValidationModalOpen(true);
              KyronEvents.sendEvent(KyronEvents.names.SUBMIT_CREATE_FORM, {
                lesson_name: createLessonPayload.lessonTitle,
                user_id: user?.id || 'anonymous',
              });
            }}
            disabled={
              !createLessonPayload.audience ||
              !createLessonPayload.lessonObjective ||
              !createLessonPayload.lessonTitle ||
              isCreatingLesson ||
              isUploadingAuxContent ||
              isValidatingAuxContent ||
              fileErrors.length > 0
            }
          >
            Create course
          </Button>
        ) : (
          <NavigateToPricingButton>Subscribe to create course</NavigateToPricingButton>
        )}
      </Stack>
      <ValidationModal
        open={validationModalOpen}
        onClose={() => setValidationModalOpen(false)}
        approveButton={approveButton}
        loading={isStructuringObjectives}
        error={objectivesError}
        objectives={structuredObjectives}
      />
    </Stack>
  );
}
