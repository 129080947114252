import { ListItemButton, ListItemIcon, ListItemText, SxProps } from '@mui/material';
import React from 'react';
import { NavLink, NavLinkProps, To } from 'react-router-dom';
import { KyronTooltip } from '../KyronTooltip';

export type NavItemButtonProps = {
  onClick?: () => void;
  icon?: React.ReactElement;
  label?: string;
  additionalText?: string;
  dense?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  exactPathMatch?: boolean;
  sx?: SxProps;
  collapsed?: boolean;
  vertical?: boolean;
  to?: To;
} & Omit<NavLinkProps, 'to'>;

export const navItemButtonColorStyle = {
  color: 'inherit',
  '&:focus, &:hover': {
    bgcolor: 'surfaceContainer.main',
    color: 'surfaceContainer.contrastText',
  },
  '&.active': {
    bgcolor: 'secondaryContainer.main',
    color: 'secondaryContainer.contrastText',
    '&:focus, &:hover': {
      bgcolor: 'secondaryContainer.dark',
      color: 'secondaryContainer.contrastText',
    },
  },
};

export function NavItemButton({
  to,
  onClick,
  icon,
  label,
  additionalText,
  dense = false,
  disabled = false,
  children,
  exactPathMatch = false,
  sx,
  collapsed = false,
  vertical = false,
  replace = false,
  state = null,
}: NavItemButtonProps) {
  let iconMargin;
  if (vertical) {
    iconMargin = 0;
  } else if (dense) {
    iconMargin = 1;
  } else {
    iconMargin = 2;
  }

  return (
    <KyronTooltip inactive={!collapsed} title={label} placement='right' arrow>
      <ListItemButton
        component={to ? NavLink : ListItemButton}
        {...(to && { to })}
        onClick={onClick}
        disabled={disabled}
        replace={replace}
        state={state}
        sx={{
          borderRadius: '100px',
          py: 1,
          px: 2,
          textWrap: 'nowrap',
          flexDirection: vertical ? 'column' : 'row',
          ...navItemButtonColorStyle,
          ...sx,
        }}
        {...(exactPathMatch && { end: true })}
      >
        {icon && <ListItemIcon sx={{ mr: iconMargin }}>{icon}</ListItemIcon>}
        {label && (
          <ListItemText
            primary={label}
            secondary={additionalText}
            primaryTypographyProps={{ variant: 'inherit' }}
            sx={{ my: 0 }}
          />
        )}
        {children}
      </ListItemButton>
    </KyronTooltip>
  );
}
