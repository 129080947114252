import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';

mermaid.initialize({
  startOnLoad: true,
  theme: 'default',
  securityLevel: 'loose',
  fontFamily: 'monospace',
});

/*
 * Utility wrapper for a Mermaidjs chart.
 * Simplifies the process of rendering a chart to placing it within this component.
 */
export const Mermaid: React.FC<{ chart: string }> = ({ chart }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    mermaid.contentLoaded();
    mermaid.parse(chart).catch(err => console.error(`Error parsing merdmaid chart: \n${chart}\n${err}`));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='mermaid' ref={containerRef}>
      {chart}
    </div>
  );
};
