import { styled, Tooltip as MuiTooltip, tooltipClasses, TooltipProps } from '@mui/material';
import React from 'react';

type Props = { inactive?: boolean; children: React.ReactNode; htmlContent?: boolean } & TooltipProps;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 500,
    border: `1px solid ${theme.palette.divider}`,
  },
}));

/**
 * A wrapper around MUI Tooltip that allows inactivating the tooltip.
 * @param inactive - If true, the tooltip will not be displayed.
 */
export function KyronTooltip({ inactive, children, htmlContent, ...props }: Props) {
  const Tooltip = htmlContent ? HtmlTooltip : MuiTooltip;
  return inactive ? children : <Tooltip {...props}>{children}</Tooltip>;
}
