import React from 'react';
import { Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import { Misconception } from 'controllers/types';
import { useLessonQuery } from 'controllers/react-query';
import { CreateLessonDialog } from 'components/admin/LessonBuilder/CreateLessonDialog';
import { devConsole } from 'components/utils/logUtils';
import { useParams } from 'react-router-dom';

const buildPrompt = (title: string, audience: string, misconception: Misconception) => ({
  lessonTitle: `Understanding "${misconception?.name}"`,
  audience: `${audience} who have taken the lesson: "${title}" and want additional practice.`,
  lessonObjective: `Students should get extra practice on:\n- "${misconception?.name}": ${misconception?.description}`,
});

export function CreateMisconceptionLessonButton({ misconception }: { misconception: Misconception }) {
  const { lessonId } = useParams<{ lessonId: string }>();
  const [isCreateLessonOpen, setIsCreateLessonOpen] = React.useState(false);
  const { data: lesson, isFetching, error } = useLessonQuery(lessonId);
  const { lessonTitle, audience, lessonObjective } = buildPrompt(
    lesson?.name || '',
    lesson?.audience || '',
    misconception,
  );

  if (error) {
    devConsole.error('Error fetching lesson', error);
    return null; // hide the misconception button if there's an error fetching the lesson
  }

  return (
    <>
      <Button
        aria-label='Create a Follow-up lesson'
        variant='text'
        onClick={() => setIsCreateLessonOpen(true)}
        startIcon={<Edit />}
        disabled={isFetching}
      >
        Create a Follow-up Lesson
      </Button>
      {isCreateLessonOpen && lesson && (
        <CreateLessonDialog
          dialogTitle='Create a Follow-up Lesson'
          onClose={() => setIsCreateLessonOpen(false)}
          initialFormValues={{
            lessonTitle,
            lessonObjective,
            audience,
          }}
        />
      )}
    </>
  );
}
