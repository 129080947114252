import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { LessonCollection } from 'controllers/types';
import { Row } from 'components/Row/Row';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useClassrooms } from 'controllers/react-query';
import { AssignableClassroomsList } from './AssignableClassroomsList';

const styles = {
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
};

type Props = {
  open: boolean;
  close: () => void;
  lessonCollection: LessonCollection;
  reauthenticate: () => void;
};

export const ClassroomSelector = ({ open, close, lessonCollection, reauthenticate }: Props) => {
  const { isFetching, isError, data: classroomList } = useClassrooms();

  return (
    <Dialog open={open} onClose={close} aria-label='Assign Lesson to Classroom' data-testid='assign-lesson-dialog'>
      <DialogTitle>
        {`Assign ${lessonCollection.name}`}
        <IconButton
          aria-label='Close'
          data-testid='close-assign-lesson-dialog-button'
          sx={styles.closeButton}
          onClick={close}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ height: 320, minWidth: 480, minHeight: 40 }}>
        <Typography variant='titleLarge'>Assign to a classroom</Typography>
        <Row gap={1.5} mt={1}>
          <ErrorOutlineIcon fontSize='small' />
          <Typography variant='bodyMedium'>
            Link your Google Classroom account to assign lessons to your class.
          </Typography>
        </Row>
        <AssignableClassroomsList
          lessonCollection={lessonCollection}
          reauthenticate={reauthenticate}
          classrooms={classroomList}
          isFetching={isFetching}
          isError={isError}
        />
      </DialogContent>
    </Dialog>
  );
};
