import React from 'react';
import { Grid, Skeleton } from '@mui/material';

export function LessonInstanceCardSkeleton() {
  return (
    <Grid container spacing={2} justifyContent='flex-start' sx={{ width: '100%' }}>
      <Grid item sm={12} md={4}>
        <Skeleton variant='rounded' width='100%' height='250px' />
      </Grid>
      <Grid item sm={12} md={4}>
        <Skeleton variant='rounded' width='100%' height='250px' />
      </Grid>
      <Grid item sm={12} md={4}>
        <Skeleton variant='rounded' width='100%' height='250px' />
      </Grid>
    </Grid>
  );
}
