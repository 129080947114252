import { DetailedSegmentType } from 'controllers/types';

// These are defined in LessonSegment#label
export const SEGMENT_TYPE_LABEL: Record<DetailedSegmentType, string> = {
  step: 'Lecture',
  question: 'Question',
  wait: 'Interaction',
  multiple_choice: 'Multiple Choice',
  multiple_choice_assessment: 'Multiple Choice Assessment',
  conversation: 'Conversation',
  exploratory: 'Guided Conversation',
  reflection: 'Reflective Conversation',
  assessment: 'Graded Assessment',
  formative: 'Assessment Conversation',
};
