import { Assignment, GoogleClassroomAssignmentPayload } from 'controllers/types';
import { useKyronQuery } from './kyronQuery';
import { useKyronMutation, useKyronMutationV2 } from './kyronMutation';

export const useCreateGoogleAssignment = () =>
  useKyronMutationV2<{ payload: GoogleClassroomAssignmentPayload }>(`/teacher_dashboards/create_google_assignment`, {
    method: 'POST',
    invalidates: ({ payload }) => [`/teacher_dashboards/assignment_details?classroom_id=${payload.classroomId}`],
  });

export const useCreateKyronAssignment = (classroomId?: number) =>
  useKyronMutationV2<{
    classroomId: number;
    lessonId: number;
  }>(`/teacher_dashboards/create_kyron_assignment?classroom_id=:classroomId&lesson_id=:lessonId`, {
    method: 'POST',
    invalidates: [`/teacher_dashboards/assignment_details?classroom_id=${classroomId}`],
  });

export const useGetLessonCollectionAssignments = (classroomId?: string, lessonCollectionId?: string) =>
  useKyronQuery<Assignment[]>(
    `/teacher_dashboards/assignment_details?classroom_id=${classroomId}${
      lessonCollectionId ? `&lesson_collection_id=${lessonCollectionId}` : ''
    }`,
    {
      enabled: !!classroomId,
    },
  );

export const useDeleteLessonCollectionAssignment = (classroomId?: string) =>
  useKyronMutation<{
    classroomId?: string;
    lessonCollectionId?: string;
  }>(`/teacher_dashboards/lesson_collection_assignment`, {
    method: 'DELETE',
    invalidates: [`/teacher_dashboards/assignment_details?classroom_id=${classroomId}`],
  });
