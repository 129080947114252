/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';

type Props = {
  segmentRows: any;
  nextSegmentName: any;
};

export const DataTable = ({ segmentRows, nextSegmentName }: Props) => {
  const rows = segmentRows;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const handleChangePage = (_: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [open, setOpen] = useState(false);
  return (
    <div key={segmentRows.length}>
      <TableContainer sx={{ maxHeight: 540 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell align='left' colSpan={1}>
                Segment Name
              </TableCell>
              <TableCell align='left' colSpan={1}>
                Input Types
              </TableCell>
              <TableCell align='left' colSpan={1}>
                Question
              </TableCell>
              <TableCell align='left' colSpan={1}>
                Response Count
              </TableCell>
              <TableCell align='left' colSpan={1} />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => (
              <React.Fragment key={row.id}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell align='left'>{row.name}</TableCell>
                  <TableCell align='left'>{row.input_type}</TableCell>
                  <TableCell align='left'>{row.question}</TableCell>
                  <TableCell align='left'>{row.response_count}</TableCell>
                  <TableCell align='right'>
                    <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                      {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 1, paddingTop: 0 }} colSpan={5} align='right'>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align='left' colSpan={1}>
                              Session ID
                            </TableCell>
                            <TableCell align='left' colSpan={1}>
                              Input Type
                            </TableCell>
                            <TableCell align='left' colSpan={1}>
                              Input
                            </TableCell>
                            <TableCell align='left' colSpan={1}>
                              Match Confidence
                            </TableCell>
                            <TableCell align='left' colSpan={1}>
                              Next Segment{' '}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {row.segment_instance.map((instance: any) => (
                          <TableBody key={instance.lesson_instance?.session_id}>
                            <TableRow>
                              <TableCell component='th' scope='row' align='left'>
                                {instance.lesson_instance?.session_id}
                              </TableCell>
                              <TableCell align='left'>{instance.input_type}</TableCell>
                              <TableCell align='left'>{instance.student_text_input}</TableCell>
                              <TableCell align='left'>{instance.match_confidence}</TableCell>
                              <TableCell align='left'>{nextSegmentName[instance.id]}</TableCell>
                            </TableRow>
                          </TableBody>
                        ))}
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};
