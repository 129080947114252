import React from 'react';
import { Alert, Box, Card, CardContent, CardMedia, Typography, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { ClippedTypography } from 'components/ClippedTypography';
import { LoadingIndicator } from 'components/LoadingIndicator';
import backupThumbnail from 'assets/defaultThumbnail.svg';
import { useLessonCollectionsSearchQuery } from 'controllers/react-query';
import { LessonCollection } from 'controllers/types';
import { AdminCollectionSearchFilter, PARAMS } from './AdminCollectionSearchFilter';

function AdminLessonListCard({
  collection,
  isSelected,
  onSelectCollection,
}: {
  collection: LessonCollection;
  isSelected: boolean;
  onSelectCollection: () => void;
}) {
  const { lessons } = collection;
  const tutorCount = lessons?.length || 0;
  return (
    <Card
      onClick={onSelectCollection}
      sx={{
        display: 'flex',
        borderRadius: 2,
        bgcolor: isSelected ? 'secondaryContainer.main' : 'primaryContainer.light',
        cursor: 'pointer',
      }}
    >
      <CardMedia
        component='img'
        image={collection.collection_thumbnail || backupThumbnail}
        sx={{ maxWidth: 116 }}
        alt='lesson thumbnail'
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          maxHeight: 116,
          p: 1,
        }}
      >
        <ClippedTypography lines={1}>{collection.name}</ClippedTypography>
        <ClippedTypography lines={2} gutterBottom variant='bodySmall' color='text.secondary'>
          {collection.description}
        </ClippedTypography>
        {tutorCount ? (
          <Typography variant='bodySmall' color='text.secondary'>
            {`${tutorCount} tutors`}
          </Typography>
        ) : (
          <Typography variant='bodySmall' color='text.secondary'>
            No Lessons
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export function AdminCollectionList({
  selectedCollection,
  setSelectedCollection,
}: {
  selectedCollection?: LessonCollection;
  setSelectedCollection: (collection?: LessonCollection) => void;
}): React.JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams({
    sort: PARAMS.ASC,
  });

  const { isLoading, isError, data } = useLessonCollectionsSearchQuery(new URLSearchParams(searchParams));
  const collections = data?.lesson_collections;
  if (!isLoading && !selectedCollection) {
    setSelectedCollection(collections?.[0]);
  }
  // const selectedCollection = collections?.[selectedCollection];

  const noResults = !isLoading && (!selectedCollection || collections?.length === 0);

  return (
    <>
      <AdminCollectionSearchFilter searchParams={searchParams} setSearchParams={setSearchParams} />
      <Box sx={{ maxHeight: '85vh', overflowY: 'auto' }}>
        {isLoading ? <LoadingIndicator /> : null}
        {isError ? <Alert severity='error'>Error loading lessons</Alert> : null}
        {noResults ? <Typography>No Results</Typography> : null}
        {collections ? (
          <Stack gap={1}>
            {collections.map(collection => (
              <AdminLessonListCard
                key={collection.id}
                collection={collection}
                isSelected={collection.id === selectedCollection?.id}
                onSelectCollection={() => setSelectedCollection(collection)}
              />
            ))}
          </Stack>
        ) : null}
      </Box>
    </>
  );
}
