import React, { useContext, useState } from 'react';
import { UserContext } from 'components/UserContext';
import { useTitle } from 'components/StudioLayout/StudioLayout';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useUserLessonInstances, useUserOverallAnalytics } from 'controllers/react-query/userHooks';
import { NoResources } from 'components/NoResources';
import { AnalyticsCard } from '../OrganizationAnalytics/OrganizationAnalyticsCard/AnalyticsCard';
import { LessonInstanceCard } from './LessonInstanceAnalyticsCard/LessonInstanceAnalyticsCard';
import { StudentDashboardAnalyticsCardSkeleton } from './StudentDashboardAnalyticsCardSkeleton';
import { LessonInstanceCardSkeleton } from './LessonInstanceCardSkeleton';

export const StudentDashboard = () => {
  const { user } = useContext(UserContext);
  const [page, setPage] = useState(1);
  const [completed, setCompleted] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState<number | null>(null);
  const perPage = 15;

  const {
    data: overallAnalyticsData,
    isFetching: isFetchingOverallAnalytics,
    isError: isErrorOverallAnalytics,
  } = useUserOverallAnalytics(user?.id);

  const {
    data: lessonInstancesData,
    isFetching: isFetchingLessonInstances,
    isError: isErrorLessonInstances,
  } = useUserLessonInstances(user?.id, completed, selectedLesson, { page, perPage });

  const { lesson_instances: lessonInstances, meta } = lessonInstancesData || {};
  const totalPages = meta?.total_pages;
  useTitle(`${user?.name}'s Dashboard`);

  const lessonNames = completed
    ? overallAnalyticsData?.distinct_lesson_names_completed
    : overallAnalyticsData?.distinct_lesson_names;

  const handleLessonChange = (event: SelectChangeEvent<number | ''>) => {
    const { value } = event.target;
    setSelectedLesson(value === '' ? null : Number(value));
  };

  const handleCompletedChange = () => {
    setCompleted(prevCompleted => !prevCompleted);
    setSelectedLesson(null);
  };

  const overallAnalyticsCards = () => {
    if (isFetchingOverallAnalytics) {
      return <StudentDashboardAnalyticsCardSkeleton />;
    }
    if (isErrorOverallAnalytics || overallAnalyticsData === undefined) {
      return <NoResources message='There was an error fetching the student lesson instances.' />;
    }
    return (
      <div>
        <Grid container spacing={2} justifyContent='flex-start' sx={{ width: '100%' }}>
          <Grid item xs={12} sm={6}>
            <AnalyticsCard number={overallAnalyticsData?.lessons_attempted} label='Lessons Started' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AnalyticsCard number={overallAnalyticsData?.lessons_completed} label='Lessons Completed' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AnalyticsCard number={overallAnalyticsData?.lesson_completion_rate} label='Lesson Completion Rate' />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AnalyticsCard number={overallAnalyticsData?.unique_lessons_attempted} label='Unique Lessons Attempted' />
          </Grid>
        </Grid>
      </div>
    );
  };

  const lessonInstanceCards = () => {
    if (isFetchingLessonInstances) {
      return (
        <div>
          <LessonInstanceCardSkeleton />
          <LessonInstanceCardSkeleton />
          <LessonInstanceCardSkeleton />
        </div>
      );
    }
    if (isErrorLessonInstances || lessonInstances === undefined) {
      return <NoResources message='There was an error fetching the student lesson instances.' />;
    }

    return (
      <div>
        <Grid container spacing={2} justifyContent='flex-start' sx={{ width: '100%', mb: 4 }}>
          <Grid item md={8} xs={12}>
            <Select value={selectedLesson ?? ''} onChange={handleLessonChange} displayEmpty fullWidth>
              <MenuItem value=''>All lessons</MenuItem>
              {lessonNames?.map(lesson => (
                <MenuItem key={`${lesson.id}`} value={lesson.id}>
                  {lesson.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item sm={12} md={4}>
            <FormControlLabel
              control={<Checkbox checked={completed} onChange={handleCompletedChange} />}
              label='Show Only Completed Lessons'
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent='flex-start' sx={{ width: '100%' }}>
          {lessonInstances?.map(lessonInstance => (
            <Grid item sm={12} md={4} key={lessonInstance.id}>
              <LessonInstanceCard
                lessonTitle={lessonInstance.lesson_title}
                completed={lessonInstance.completed}
                dayStarted={lessonInstance.day_started}
                misconceptionCount={lessonInstance.misconception_count}
                misconceptionInstances={lessonInstance.misconception_instances}
              />
            </Grid>
          ))}
        </Grid>
        <Grid container justifyContent='center' sx={{ mt: 4 }}>
          <Pagination
            sx={{ alignSelf: 'center', bottom: 0 }}
            count={totalPages}
            shape='rounded'
            page={page}
            onChange={(_, newPage) => setPage(newPage)}
          />
        </Grid>
      </div>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      {overallAnalyticsCards()}
      <Divider sx={{ my: 4 }} />
      <Grid container justifyContent='center' sx={{ m: 4 }}>
        <Typography variant='titleLarge'> Lesson Instance History </Typography>
      </Grid>
      {lessonInstanceCards()}
    </Box>
  );
};
