import React, { useState } from 'react';

export function useDraggableList<T>(
  setList: React.Dispatch<React.SetStateAction<T[]>>,
  onDragEnd: (item: T, newIndex: number, newList: T[]) => void = () => {},
) {
  const [dragIndex, setDragIndex] = useState<number | null>(null);
  const [dropIndex, setDropIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDragIndex(index);
  };
  const handleDragEnter = (index: number) => {
    if (dragIndex === null) return; // skip update if nothing is being dragged from this list
    setDropIndex(index);
  };

  const handleDragEnd = () => {
    if (dragIndex === null || dropIndex === null) return; // skip update if dragIndex or dropIndex is null
    if (dragIndex === dropIndex) {
      // skip update an reset when you drag/drop to the same index
      setDragIndex(null);
      setDropIndex(null);
      return;
    }
    setList(prevList => {
      const newList = [...prevList];
      newList.splice(dropIndex, 0, newList.splice(dragIndex, 1)[0]);
      onDragEnd(prevList[dragIndex], dropIndex, newList);
      return newList;
    });
    setDragIndex(null);
    setDropIndex(null);
  };
  return { dragIndex, dropIndex, handleDragStart, handleDragEnd, handleDragEnter };
}

type DraggableListResult = {
  dragIndex: number | null;
  dropIndex: number | null;
  handleDragStart: (index: number) => void;
  handleDragEnd: () => void;
  handleDragEnter: (index: number) => void;
};

export function getDraggableProps(idx: number, draggableListResults: DraggableListResult) {
  return {
    draggable: true,
    onDragStart: () => draggableListResults.handleDragStart(idx),
    onDragEnter: () => draggableListResults.handleDragEnter(idx),
    onDragEnd: draggableListResults.handleDragEnd,
    onDragOver: (e: React.DragEvent) => {
      e.dataTransfer.dropEffect = 'move'; // set the cursor to move
      e.preventDefault();
    },
  };
}

export function getDraggableStyles(idx: number, draggableListResults: DraggableListResult) {
  return {
    cursor: 'grab',
    opacity: 1,
    ...(draggableListResults.dragIndex === idx && { opacity: 0.3 }),
    ...(draggableListResults.dropIndex === idx && { opacity: 0.1 }),
  };
}
