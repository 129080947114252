import { Box } from '@mui/material';
import { MarketplaceLessonCard } from 'components/Marketplace/MarketplaceLessonCard/MarketplaceLessonCard';
import { MarketplaceLessonCardSkeleton } from 'components/Marketplace/MarketplaceLessonCard/MarketplaceLessonCardSkeleton';
import { useChannelLessonCollectionsQuery } from 'controllers/react-query/channelHooks';
import * as React from 'react';
import { useParams } from 'react-router-dom';

const styles = {
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '16px',
  },
};

export function ChannelHome() {
  const { channelId } = useParams();
  const { data: lessonCollections, isLoading, isError } = useChannelLessonCollectionsQuery(Number(channelId));

  if (isLoading) {
    return (
      <Box sx={styles.wrapper}>
        {[...Array(8)].map(_ => (
          <MarketplaceLessonCardSkeleton key={Math.random()} />
        ))}
      </Box>
    );
  }

  if (isError) {
    throw new Error('Error fetching lessons.');
  }

  if (!lessonCollections?.length) {
    return <div>No courses found.</div>;
  }

  return (
    <Box sx={styles.wrapper}>
      {lessonCollections.map(lessonCollection => (
        <MarketplaceLessonCard key={lessonCollection.id} lessonCollection={lessonCollection} showChannel={false} />
      ))}
    </Box>
  );
}
