import React from 'react';
import { Box } from '@mui/material';
import { LessonSection } from 'controllers/types';
import { useSnackbar } from 'notistack';
import { useUpdateSectionOrSegmentPosition } from 'controllers/react-query';
import { SectionListItem } from './SectionListItem';
import { AddSectionDialog } from './AddToLesson/AddSectionDialog';
import { SectionListSkeleton } from './EditorSkeleton';

export function SectionList({ lessonSections }: { lessonSections: LessonSection[] }) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: updateSectionPosition, isPending } = useUpdateSectionOrSegmentPosition();

  const reposition = (section: LessonSection, options: { direction: number }) => {
    const newPosition = section.position + options.direction;
    updateSectionPosition(
      {
        lessonSectionId: section.id,
        lessonId: section.lesson_id,
        payload: { lessonSection: { position: newPosition } },
      },
      {
        onError: err => {
          const errorTitle = `Failed to reposition section`;
          console.error(errorTitle, section.id, err.message);
          enqueueSnackbar(errorTitle, { variant: 'error' });
        },
      },
    );
  };

  if (isPending) {
    return <SectionListSkeleton />;
  }

  return (
    <>
      {lessonSections?.map(section => (
        <SectionListItem
          key={section.id}
          isLast={section.position === lessonSections.length}
          reposition={reposition}
          section={section}
        />
      ))}
      <Box>
        <AddSectionDialog disabled={isPending} />
      </Box>
    </>
  );
}
