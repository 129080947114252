// Jest Snapshot v1, https://goo.gl/fbAQLP

exports[`LessonsLoadingIndicator should render 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    left: -35%;
    right: 100%;
  }

  60% {
    left: 100%;
    right: -90%;
  }

  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes animation-1 {
  0% {
    left: -200%;
    right: 100%;
  }

  60% {
    left: 107%;
    right: -8%;
  }

  100% {
    left: 107%;
    right: -8%;
  }
}

.emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  background-color: inherit;
  min-width: 300px;
}

.emotion-1 {
  padding: 16px;
}

.emotion-1:last-child {
  padding-bottom: 24px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 32px;
  opacity: 0.8;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
}

.emotion-4 {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: none;
  height: 40px;
  width: 60%;
}

@media print {
  .emotion-4 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-4::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;
  opacity: 0.3;
}

.emotion-5 {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transform-origin: left;
  background-color: currentColor;
  width: auto;
  -webkit-animation: animation-0 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: animation-0 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.emotion-6 {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transform-origin: left;
  background-color: currentColor;
  opacity: 0.3;
  width: auto;
  -webkit-animation: animation-1 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
  animation: animation-1 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
}

.emotion-7 {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: none;
  height: 30px;
  width: 50%;
}

@media print {
  .emotion-7 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-7::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;
  opacity: 0.3;
}

.emotion-11 {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: none;
  height: 20px;
  width: 85%;
}

@media print {
  .emotion-11 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-11::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;
  opacity: 0.3;
}

.emotion-14 {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: none;
  height: 20px;
  width: 80%;
}

@media print {
  .emotion-14 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-14::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;
  opacity: 0.3;
}

.emotion-20 {
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  z-index: 0;
  background-color: none;
  height: 20px;
  width: 75%;
}

@media print {
  .emotion-20 {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

.emotion-20::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;
  opacity: 0.3;
}

<div
    class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-0"
    data-testid="loading-indicator"
  >
    <div
      class="MuiCardContent-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <div
          class="MuiStack-root emotion-3"
        >
          <span
            class="MuiLinearProgress-root MuiLinearProgress-colorInherit MuiLinearProgress-indeterminate emotion-4"
            role="progressbar"
          >
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar1Indeterminate emotion-5"
            />
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar2Indeterminate emotion-6"
            />
          </span>
          <span
            class="MuiLinearProgress-root MuiLinearProgress-colorInherit MuiLinearProgress-indeterminate emotion-7"
            role="progressbar"
          >
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar1Indeterminate emotion-5"
            />
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar2Indeterminate emotion-6"
            />
          </span>
        </div>
        <div
          class="MuiStack-root emotion-3"
        >
          <span
            class="MuiLinearProgress-root MuiLinearProgress-colorInherit MuiLinearProgress-indeterminate emotion-11"
            role="progressbar"
          >
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar1Indeterminate emotion-5"
            />
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar2Indeterminate emotion-6"
            />
          </span>
          <span
            class="MuiLinearProgress-root MuiLinearProgress-colorInherit MuiLinearProgress-indeterminate emotion-14"
            role="progressbar"
          >
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar1Indeterminate emotion-5"
            />
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar2Indeterminate emotion-6"
            />
          </span>
          <span
            class="MuiLinearProgress-root MuiLinearProgress-colorInherit MuiLinearProgress-indeterminate emotion-11"
            role="progressbar"
          >
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar1Indeterminate emotion-5"
            />
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar2Indeterminate emotion-6"
            />
          </span>
          <span
            class="MuiLinearProgress-root MuiLinearProgress-colorInherit MuiLinearProgress-indeterminate emotion-20"
            role="progressbar"
          >
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar1Indeterminate emotion-5"
            />
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar2Indeterminate emotion-6"
            />
          </span>
          <span
            class="MuiLinearProgress-root MuiLinearProgress-colorInherit MuiLinearProgress-indeterminate emotion-11"
            role="progressbar"
          >
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar1Indeterminate emotion-5"
            />
            <span
              class="MuiLinearProgress-bar MuiLinearProgress-barColorInherit MuiLinearProgress-bar2Indeterminate emotion-6"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;

exports[`SectionLoadingIndicator should render 1`] = `
<DocumentFragment>
  @keyframes animation-0 {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}

.emotion-0 {
  background-color: #FCFAFD;
  color: #1C1B1F;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: none;
  background-image: none;
  border-radius: 24px;
  overflow: hidden;
  background-color: inherit;
}

.emotion-1 {
  padding: 16px;
}

.emotion-1:last-child {
  padding-bottom: 24px;
}

.emotion-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 8px;
  opacity: 0.8;
}

.emotion-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 16px;
}

.emotion-4 {
  display: block;
  background-color: rgba(28, 27, 31, 0.11);
  height: 1.2em;
  -webkit-animation: animation-0 2s ease-in-out 0.5s infinite;
  animation: animation-0 2s ease-in-out 0.5s infinite;
}

<div
    class="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation0 MuiCard-root emotion-0"
    data-testid="loading-indicator"
  >
    <div
      class="MuiCardContent-root emotion-1"
    >
      <div
        class="MuiStack-root emotion-2"
      >
        <div
          class="MuiStack-root emotion-3"
        >
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-4"
            style="height: 18px;"
          />
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-4"
            style="height: 18px;"
          />
          <span
            class="MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse emotion-4"
            style="height: 18px;"
          />
        </div>
      </div>
    </div>
  </div>
</DocumentFragment>
`;
