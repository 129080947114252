/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

type Props = {
  rows: any; // TODO: Fix this type. Left it any as a part of large refactor effort.
};

export const HeaderTable = ({ rows }: Props) => (
  <div key={rows.length}>
    <TableContainer sx={{ maxHeight: 540 }}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            <TableCell align='left' colSpan={0}>
              LessonId
            </TableCell>
            <TableCell align='left' colSpan={0}>
              Lesson Title
            </TableCell>
            <TableCell align='left' colSpan={0}>
              Creator
            </TableCell>
            <TableCell align='left' colSpan={0}>
              Locale
            </TableCell>
            <TableCell align='left' colSpan={0}>
              Session Count
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: any) => (
            <TableRow key={row.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell align='left'>{row.id}</TableCell>
              <TableCell align='left'>{row.current_lesson_title}</TableCell>
              <TableCell align='left'>{row.current_creator}</TableCell>
              <TableCell align='left'>{row.current_locale}</TableCell>
              <TableCell align='left'>{row.current_session_count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);
