/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Grid, TextField, MenuItem } from '@mui/material';

import { VideoPreview } from './VideoPreview';

export const CommonSegmentForm = props => (
  <Grid container spacing={2}>
    <Grid item xs={8}>
      <VideoPreview url={props.state.video_url} start={props.state.start_time} end={props.state.end_time} />
    </Grid>
    <Grid item xs={8}>
      <Link
        style={{ textDecoration: 'none' }}
        to={`/video_player/${props.lessonId}/segments/${props.state.id}`}
        key={props.state.id}
      >
        Play Segment
      </Link>
    </Grid>
    <Grid item xs={8}>
      <TextField
        data-testid='name-input'
        name='name'
        type='text'
        fullWidth
        margin='normal'
        label='Segment Name'
        onChange={props.handleInputChange}
        required
        value={props.state.name}
        helperText='Enter the name for the new lesson segment'
      />
    </Grid>
    <Grid item xs={4}>
      {props.editing && (
        <TextField
          data-testid='segment-type-readonly'
          label='Segment Type'
          fullWidth
          margin='normal'
          readOnly
          value={props.state.segment_type}
          helperText='Read Only'
        />
      )}
      {!props.editing && (
        <TextField
          data-testid='segment-type-select'
          name='segment_type'
          select
          fullWidth
          margin='normal'
          label='Segment Type'
          onChange={props.handleInputChange}
          required
          value={props.state.segment_type}
          helperText='Select wait or step'
        >
          <MenuItem value='wait'>wait</MenuItem>
          <MenuItem value='step'>step</MenuItem>
        </TextField>
      )}
    </Grid>
    <Grid item xs={8}>
      <TextField
        data-testid='description-input'
        name='description'
        label='Description'
        fullWidth
        margin='normal'
        type='text'
        onChange={props.handleInputChange}
        helperText='Enter a description'
        value={props.state.description}
      />
    </Grid>
    <Grid item xs={4}>
      <TextField
        data-testid='start-time-input'
        name='start_time'
        type='text'
        fullWidth
        margin='normal'
        label='Video Start'
        onChange={props.handleTime}
        value={props.state.start_time}
        helperText='Start time in secs'
      />
    </Grid>
    <Grid container spacing={2} justifyContent='flex-end'>
      <Grid item xs={4}>
        <TextField
          data-testid='end-time-input'
          name='end_time'
          type='text'
          fullWidth
          margin='normal'
          label='Video End'
          onChange={props.handleTime}
          value={props.state.end_time}
          helperText='End time in secs'
        />
      </Grid>
    </Grid>
  </Grid>
);

CommonSegmentForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object,
  lessonId: PropTypes.number,
  handleInputChange: PropTypes.func,
  handleTime: PropTypes.func,
  editing: PropTypes.bool,
};
