/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useSnackbar } from 'notistack';
import { Dayjs } from 'dayjs';
import { KyronLayoutContent } from 'components/KyronLayout/KyronLayout';
import { DataGrid } from '../common/DataGrid';
import { KyronClient } from '../utils/KyronClient';
import { DataTable } from './DataTable';
import { HeaderTable } from './HeaderTable';
import { DateRangePicker } from '../Reports/DateRangePicker';

const styles = {
  ReportTable: {
    width: '100%',
    height: '100vh',
  },
  CurrentLessonTable: {
    width: '100%',
    height: '18vh',
    marginBottom: '30px',
  },
};

type LessonRow = {
  id: number;
  lesson_title: string;
  lesson_id: number;
  locale: string;
  creator: string;
  session_count: number;
  created_at: string;
};

type CurrentLessonRow = {
  id: number;
  current_lesson_title: string;
  current_lesson_id: number;
  current_locale: string;
  current_creator: string;
  current_session_count: number;
};

type SegmentRow = {
  id: number;
  name: string;
  input_type: string;
  question: string;
  response_count: number;

  segment_instance: any;
};

export const TrainingLogs = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentLessonId, setCurrentLessonId] = useState<number | null>(null);
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const lessonColumns = [
    {
      field: 'lesson_id',
      headerName: 'LessonID',
    },
    {
      field: 'lesson_title',
      headerName: 'Lesson Title',
      minWidth: 200,
    },
    {
      field: 'creator',
      headerName: 'Creator',
    },
    {
      field: 'locale',
      headerName: 'Locale',
    },
    {
      field: 'session_count',
      headerName: 'Session Count',
    },
  ];

  const [lessonRows, setLessonRows] = useState<LessonRow[]>([]);
  const [segmentRows, setSegmentRows] = useState<SegmentRow[]>([]);
  const [nextSegmentName, setNextSegmentName] = useState([]);
  const [currentLessonRow, setCurrentLessonRow] = useState<CurrentLessonRow[]>([]);
  const [segmentVisible, setSegmentVisible] = useState(false);
  const [lessonsId, setLessonsId] = useState(0);
  const client = new KyronClient();

  type Segment = {
    lesson_segments: any[];
    response_count: any[];
    lesson_segment_instances: any[];
    next_segment_name: any;
  };

  const fetchWaitSegment = (lessonId: number, currentLesson: any = null) => {
    // TODO: move client call into /controllers
    client
      .getDataWithError(segmentUrl(lessonId))
      .then(data => {
        const segment = data as Segment;
        setNextSegmentName(segment.next_segment_name);
        setSegmentRows(
          segment?.lesson_segments.map(d => ({
            id: d.id,
            name: d.name,
            input_type: d.acceptable_inputs,
            question: d.question,
            response_count: segment.response_count[d.id],
            segment_instance: segment.lesson_segment_instances[d.id],
          })),
        );
        setCurrentLessonId(lessonId);
        if (currentLesson) {
          setCurrentLessonRow([
            {
              id: currentLesson?.id,
              current_lesson_title: currentLesson?.lesson_title,
              current_lesson_id: currentLesson?.id,
              current_locale: currentLesson?.locale,
              current_creator: currentLesson?.creator,
              current_session_count: currentLesson?.session_count,
            },
          ]);
        }
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
  };

  useEffect(() => {
    // TODO: move client call into /controllers
    const url = reportUrl('json', startDate, endDate);
    if (!url) return;
    client
      .getDataWithError(url)
      .then(data => {
        const lesson = data as { trainings: any[]; count: any[] };
        setLessonRows(
          lesson?.trainings.map(d => ({
            id: d.id,
            lesson_title: d.name,
            lesson_id: d.id,
            locale: d.locale,
            creator: d.tutor,
            session_count: lesson.count[d.id],
            created_at: d.created_at,
          })),
        );
      })
      .catch(error => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    if (currentLessonId) {
      fetchWaitSegment(currentLessonId);
    }
  }, [startDate, endDate]);

  const handleInstanceRowClick = (e: any) => {
    fetchWaitSegment(e.data.id, e.data);
    setSegmentVisible(true);
    setLessonsId(e.data.id);
  };

  const handleBackToLesson = () => {
    setSegmentVisible(false);
    setLessonsId(0);
    setCurrentLessonId(null);
  };

  const handleDateRange = (sDate: Dayjs, eDate: Dayjs) => {
    setStartDate(sDate);
    setEndDate(eDate);
  };

  function reportUrl(format: string, start?: Dayjs, end?: Dayjs) {
    if (!start || !end) return '';
    return `/api/v1/lesson_instances/training_logs.${format}?${new URLSearchParams({
      start_time: start.toISOString(),
      end_time: end.toISOString(),
    })}`;
  }

  function segmentUrl(id: number, start?: Dayjs, end?: Dayjs, format = 'json', sessionCount = 0) {
    if (!start || !end) return '';
    return `/api/v1/lesson_instances/training_segments.${format}?${new URLSearchParams({
      start_time: start.toISOString(),
      end_time: end.toISOString(),
      lesson_id: String(id),
      session_count: String(sessionCount),
    })}`;
  }

  const sessionCount = currentLessonRow[0]?.current_session_count;
  const segmentURL = segmentUrl(lessonsId, startDate, endDate, 'csv', sessionCount);
  const reportURL = reportUrl('csv', startDate, endDate);

  return (
    <KyronLayoutContent>
      <DateRangePicker timestampStart={startDate} timestampEnd={endDate} handleDateRange={handleDateRange} />
      <Button variant='outlined' startIcon={<DownloadIcon />}>
        <a href={segmentVisible ? segmentURL : reportURL}>Download Trainings CSV</a>
      </Button>

      <Box sx={styles.ReportTable}>
        {segmentVisible && (
          <IconButton data-testid='lesson-button' onClick={handleBackToLesson}>
            <KeyboardBackspaceIcon />
          </IconButton>
        )}
        {currentLessonId && (
          <div>
            <Box sx={styles.CurrentLessonTable}>
              <HeaderTable rows={currentLessonRow} />
            </Box>
            <Box>
              <DataTable segmentRows={segmentRows} nextSegmentName={nextSegmentName} />
            </Box>
          </div>
        )}
        {lessonRows.length > 0 && !currentLessonId && (
          <DataGrid rows={lessonRows} columns={lessonColumns} onRowClicked={handleInstanceRowClick} />
        )}
      </Box>
    </KyronLayoutContent>
  );
};
