import { useMemo } from 'react';
import { capitalize } from '@mui/material';
import { useAppContext } from '../AppContext';
import { BackgroundTask, LessonInstance, LessonSegment } from '../../controllers/types';
import { CategorizedTasks, emptyCategorizedTasks } from '../../channels/user_notifications';

type LessonBTWatcherOpts = { additionalLessonIds: (string | number | undefined)[] };
const defaultOptions: LessonBTWatcherOpts = { additionalLessonIds: [] };

// This hook is used to watch the background tasks for a lesson.
// Example return value:
// ```json
// {
//   active: { lessonPlanning: [task1, task2], ... },
//   failed: { lessonPlanning: [], ... },
//   isLessonPlanningInProgress: true,
//   isLessonPlanningFailed: false,
//   isAnyInProgress: true,
//   ...
// }
// ```
// Options:
// - additionalLessonIds: An array of lesson IDs to watch in addition to the main lessonId.
export function useLessonBTWatcher(
  lessonId: string | number | undefined,
  options: LessonBTWatcherOpts = defaultOptions,
) {
  const { notifications } = useAppContext();
  const btNotifications = notifications?.backgroundTasks || {};

  const lessonIdsToWatch = useMemo(
    () => [lessonId, ...options.additionalLessonIds],
    [lessonId, options.additionalLessonIds],
  );

  const active = useMemo<CategorizedTasks>(
    () => filterTasks(lessonIdsToWatch, 'lesson', btNotifications.active),
    [btNotifications?.active, lessonIdsToWatch],
  );

  const failed = useMemo<CategorizedTasks>(
    () => filterTasks(lessonIdsToWatch, 'lesson', btNotifications.failed),
    [btNotifications?.failed, lessonIdsToWatch],
  );

  return {
    active,
    failed,
    ...getProgressAndFailureFlags(active, failed),
  };
}

// This hook is used to watch the background tasks for a lesson segment.
// Example return value:
// ```json
// {
//   active: { lessonPlanning: [task1, task2], ... },
//   failed: { lessonPlanning: [], ... },
//   isVideoGenerationInProgress: true,
//   isVideoGenerationFailed: false,
//   isAnyInProgress: true,
//   ...
// }
// ```
//
export function useLessonSegmentBTWatcher(segmentId: string | number | undefined) {
  const { notifications } = useAppContext();

  const btNotifications = notifications?.backgroundTasks || {};

  const active = useMemo<CategorizedTasks>(
    () => filterTasks([segmentId], 'segment', btNotifications?.active),
    [btNotifications?.active, segmentId],
  );

  const failed = useMemo<CategorizedTasks>(
    () => filterTasks([segmentId], 'segment', btNotifications?.failed),
    [btNotifications?.failed, segmentId],
  );

  return {
    active,
    failed,
    ...getProgressAndFailureFlags(active, failed),
  };
}

// -------------------------------- HELPERS --------------------------------

// Takes in a lessonId and a categorizedTasks object and returns a new categorizedTasks object with only the tasks that
// belong to the lesson with the given lessonId.
// So, the output will look exactly like this input (CategorizedTask) but only with the specified lesson's tasks
export type FilterType = 'lesson' | 'segment';
/**
 * Filters tasks within categorized task groups based on either lesson or segment ID.
 *
 * This function processes a collection of categorized tasks and filters them according
 * to the specified filter type (lesson or segment) and ID. It preserves the original
 * category structure while only keeping tasks that match the filtering criteria.
 *
 * @returns {CategorizedTasks} A new object with the same category structure but only
 * containing tasks that match the filter criteria. Returns empty categorized tasks if
 * input is invalid, or the original tasks if no ID is provided.
 *
 * @example
 * // Filter tasks for a specific lesson
 * const lessonTasks = filterTasks('lesson123', 'lesson', categorizedTasks);
 *
 * // Filter tasks for a specific segment
 * const segmentTasks = filterTasks('segment456', 'segment', categorizedTasks);
 */
export function filterTasks(
  ids: (string | number | undefined)[],
  taskEntityType: FilterType,
  categorizedTasks: CategorizedTasks,
) {
  if (!categorizedTasks) return emptyCategorizedTasks;

  // Remove any falsy IDs and convert all IDs to strings
  const filteredIds = ids.filter(id => id !== undefined).map(id => id?.toString());

  // Return empty results if no valid IDs are provided
  if (!filteredIds.length) return categorizedTasks;

  const filterFn = taskEntityType === 'lesson' ? tasksOfLesson(filteredIds) : taskOfSegment(filteredIds);

  const filteredCategorizedTasks: CategorizedTasks = { ...categorizedTasks };

  Object.entries(categorizedTasks).forEach(([key, tasks]) => {
    filteredCategorizedTasks[key as keyof CategorizedTasks] = tasks.filter(filterFn);
  });

  return filteredCategorizedTasks;
}

// returns filtering callback function
export function tasksOfLesson(lessonIds: string[]) {
  return (task: BackgroundTask) => {
    // Return true if task_entity is a lesson and the lesson id matches the given lessonId
    if (task.task_entity_type === 'Lesson') {
      return !!task.task_entity_id && lessonIds.includes(task.task_entity_id.toString());
    }

    // Return true if task_entity is not a lesson and the task_entity.lesson_id matches the given lessonId
    if (task.task_entity_type === 'LessonSegment' || task.task_entity_type === 'LessonInstance') {
      const taskEntity = task.task_entity as LessonSegment | LessonInstance;
      return !!taskEntity?.lesson_id && lessonIds.includes(taskEntity.lesson_id.toString());
    }

    return false;
  };
}

// returns filtering callback function
export function taskOfSegment(segmentIds: string[]) {
  return (task: BackgroundTask) => {
    if (task.task_entity_type === 'LessonSegment') {
      return !!task.task_entity_id && segmentIds.includes(task.task_entity_id.toString());
    }

    return false;
  };
}

// Takes in the active and failed tasks and returns an object with flags to indicate if there are any active or
// failed tasks.
// Output will be a boolean flag for progress and failure for each category of tasks.
// For example, if active tasks have a category called 'lessonPlanning', this function will return an object with
// isLessonPlanningInProgress and isLessonPlanningFailed flags.
// Also, for the key 'all', it will return isAnyInProgress and isAnyFailed flags.
export function getProgressAndFailureFlags(active: CategorizedTasks, failed: CategorizedTasks) {
  const flags = {} as Record<`is${Capitalize<keyof CategorizedTasks | 'any'>}${'InProgress' | 'Failed'}`, boolean>;
  Object.keys(active).forEach(key => {
    const typedKey = key as keyof CategorizedTasks;
    const capitalizedTypedKey = capitalize(key === 'all' ? 'any' : key) as Capitalize<keyof CategorizedTasks | 'any'>;
    flags[`is${capitalizedTypedKey}InProgress`] = (active[typedKey]?.length || 0) > 0;
    flags[`is${capitalizedTypedKey}Failed`] = (failed[typedKey]?.length || 0) > 0;
  });

  return flags;
}
