export const SAMPLE_TITLES = [
  'The Mechanics of Bird Flight',
  'The Mystery of Black Holes',
  'Origins of Human Language',
  'How Solar Panels Convert Sunlight to Electricity',
  'Understanding Time Travel Theory',
  'How Plants Use Photosynthesis',
  'Why Volcanoes Erupt',
  'The Impact of Artificial Intelligence on Society',
  'Why Leaves Change Colors in Autumn',
  'The Importance of The Higgs Boson',
  'How the Human Brain Develops',
  'The Concept of Infinity in Mathematics',
  'The Science Behind Climate Change',
  'The History of The Universe',
  'What Makes Bridges Stable?',
  'Why Do We Have Dreams?',
  'What Fuels Hurricanes?',
  'The Science of Baking: Why Bread Rises',
  'How Does GPS Determine Location?',
  'The Chemistry of Smell',
  'What Triggers Earthquakes?',
  'How Are Fossils Formed?',
  'Exploring Dark Matter',
  'How Bees Communicate',
  'The Neuroscience of Memory',
  'Explaining the Doppler Effect',
  'How Lasers Cut Materials',
  'What is String Theory?',
  'How Do Muscles Grow?',
  'The Impact of Social Media on Mental Health',
  'Why Do Stars Twinkle?',
  'Why Is Mars Red?',
  'The Art and Science of Tattooing',
  'The Mechanisms of Human Sleep Cycles',
  'Decoding the Human Genome',
  'The Theory of Plate Tectonics',
  'What Prevents Aircraft from Falling?',
  'The Physics of Sound Waves',
  'How Do Plants Communicate?',
  'Why Nations Go to War',
  'The Mathematics of Chaos Theory',
  'The Evolution of Computers',
  'How is Coffee Decaffeinated?',
  'The Secrets of Coral Reefs',
  'How Robots Learn and Adapt',
  'The Physics of Basketball',
  'Why Do Cats Purr?',
  'How Does 3D Printing Work?',
  'Cultural Impacts of The Industrial Revolution',
  'Why Ancient Civilizations Collapsed',
  'How Nutrition Affects Brain Functionality',
  'The Art of Persuasion in Media',
  'What Causes Tides?',
  'Why Are Diamonds Valuable?',
  'How Does Biometric Security Work?',
  'How Are Rainbows Formed?',
  'The Origins of The Solar System',
  'Why Do Prisons Exist?',
  'How Does Ultrasonic Cleaning Work?',
  'Why Do Zebras Have Stripes?',
  'The Structure of The Internet',
  'How Do Clouds Form?',
  'The Phenomenon of Aurora Borealis',
  'Why is Caffeine Addictive?',
  'The Principles of Virtual Reality',
  'How Does Cryptocurrency Work?',
  'How Does Antimatter Work?',
  'Exploring the Art Market Economics',
  'How Do Bacteria Develop Resistance?',
  'The History of The Voting System',
  'How Batteries Store Energy',
  'The Physics of Roller Coasters',
  'The Influence of Music on the Brain',
  'How Does Blockchain Work?',
  'How Whales Navigate the Oceans',
  'Why Do We Blush?',
  'The Role of Enzymes in Digestion',
  'Understanding Cybersecurity Threats',
  'The Wonders of Bioluminescence',
  'The Dynamics of Population Growth',
  'The Chemistry of Fireworks',
  'Why Do Icebergs Float?',
  'Understanding The Stock Market',
  'The Power of Habit Formation',
  'The Role of Hormones in Mood Regulation',
  'The Economics of Carbon Trading',
  'The Role of Oceans in Weather Systems',
  'Why Lightning Strikes',
  'The Biology of Aging',
  'The Physics of Bouncing Balls',
  'How Vaccines Protect Populations',
  'Exploring The Psychology of Happiness',
  'How Computers Process Information',
  'The Physics of Bouncing Balls',
  'The Impact of Microgravity on The Human Body',
  'Understanding The Laws of Thermodynamics',
  'How Robots Learn and Adapt',
  'The Chemistry of Cooking: The Maillard Reaction',
  'The Science Behind Acupuncture',
  'How Are Rainbows Formed?',
  'The Origination of The Solar System',
  'Why is Water Wet?',
  'The Mechanics of Hydraulic Systems',
  'The Neurology of Addiction',
];
